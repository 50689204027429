import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { generateCompareFn } from '@/utils/arrays'
import { InlineModal } from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { PurchaseProductAction as PPA } from '~generated-types'
import { PrimaryColor } from '@/components/Colors'
import { SalesProduct } from '@/modules/Products/types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { usePublishContext } from '../../../PublishState'

type Props = {
  products: SalesProduct[]
}

export const PublishProductButton = ({ products }: Props) => {
  const { palette } = useTheme()

  const {
    readOnly,
    salesProductsError,
    salesProductsLoading,
    updatePublishedProduct,
  } = usePublishContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const handlePublishProduct = (salesProductId: string) =>
    updatePublishedProduct({
      id: salesProductId,
      settings: { published: true },
    }).then(() => setModalOpen(false))

  const filteredProducts = products.filter(
    (product) =>
      !product.purchases.find(
        ({ status }) =>
          !status.validatedActions.find((a) => a.action === PPA.Update)?.valid
      )
  )

  return (
    <>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <Modal>
            <ModalSection alignItems="center">
              {salesProductsLoading ? (
                <LoaderWrapper>
                  <ReactLoading
                    color={palette.smoke.main}
                    height={24}
                    type="spin"
                    width={24}
                  />
                </LoaderWrapper>
              ) : salesProductsError ? (
                <Placeholder>
                  – <T>common:error.common</T> –
                </Placeholder>
              ) : filteredProducts.length > 0 ? (
                [...filteredProducts]
                  .sort(generateCompareFn('name'))
                  .map(({ id, name }) => (
                    <Option key={id} onClick={() => handlePublishProduct(id)}>
                      {name}
                    </Option>
                  ))
              ) : (
                <Placeholder>
                  <T>Publish:Products.noProductsToPublish</T>
                </Placeholder>
              )}
            </ModalSection>
          </Modal>
        }
        onClose={() => setModalOpen(false)}
        placement="bottom-end"
        referenceElement={({ ref }) => (
          <FlexRow alignItems="center" justifyContent="center" ref={ref}>
            <InnocuousButton
              disabled={readOnly}
              noNudge
              compact
              size="small"
              onClick={() => (readOnly ? null : setModalOpen(true))}
            >
              <PrimaryColor>
                + <T>Publish:Products.publishProduct</T>
              </PrimaryColor>
            </InnocuousButton>
          </FlexRow>
        )}
      />
    </>
  )
}

///////

const Modal = styled(InlineModal)`
  min-width: 320px;
`

const ModalSection = styled(FlexColumn)`
  max-height: 250px;
  overflow: auto;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `};
`

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Option = styled.div`
  width: 100%;
  cursor: pointer;
  transition: all 0.1s ease-out;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const Placeholder = styled(FontWeight)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(0.5)}rem 0;
  `}
`
