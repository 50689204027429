import styled, { css } from 'styled-components/macro'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  markdown: string
}

export const Notes = ({ markdown }: Props) => (
  <Wrapper>
    <Title>
      <T>SalesDetails:PrintSale.label.NOTES</T>
    </Title>

    <NoteWrapper>
      <ReactMarkdown remarkPlugins={[remarkGfm]} children={markdown} />
    </NoteWrapper>
  </Wrapper>
)

////////////

const Title = styled.span`
  font-size: 1.375rem;
  font-weight: 600;
  page-break-after: avoid;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(0.75)}rem;
  `}
`

const NoteWrapper = styled.span`
  font-size: 1.1rem;

  & > p {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(0.5)}rem 0;
    `}
  }

  & > ul {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(1)}rem 0;
    `}
  }

  & > ul > li > p {
    ${({ theme }) => css`
      margin: ${theme.spacing.gu(1)}rem 0;
    `}
  }
`

const Wrapper = styled(FlexColumn)`
  font-size: 1.25rem;
`
