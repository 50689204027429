import { gql, useMutation } from '@apollo/client'

import {
  UpdateSalesAttributesMutation,
  UpdateSalesAttributesMutationVariables,
} from '~generated-types'

export type {
  UpdateSalesAttributesMutation,
  UpdateSalesAttributesMutationVariables,
}

export const UPDATE_SALES_ATTRIBUTES_MUTATION = gql`
  mutation UpdateSalesAttributes($input: SalesUpdateAttributesInput!) {
    salesUpdateAttributes(input: $input) {
      estimatedDates {
        end
        start
      }
      estimatedParticipants
      id
      language
      notes
      probabilityEstimate
      probabilitySource
      paymentAgreement {
        code
        id
        name
      }
    }
  }
`

export function useUpdateSalesAttributesMutation() {
  return useMutation<
    UpdateSalesAttributesMutation,
    UpdateSalesAttributesMutationVariables
  >(UPDATE_SALES_ATTRIBUTES_MUTATION)
}

export default useUpdateSalesAttributesMutation
