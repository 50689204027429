import styled, { css } from 'styled-components/macro'
import { ApolloError } from '@apollo/client'

import { DateRange } from '@/components/TimeControls'

import {
  AccommodationLevelAvailability,
  AvailabilityTableProps,
  EmphasisDetails,
  Mode,
} from './AvailabilityCalendar.types'
import { AvailabilityTable, ViewControls } from './components'

type Props = AvailabilityTableProps & {
  data: AccommodationLevelAvailability[]
  displayRange: DateRange
  emphasis: EmphasisDetails
  error?: ApolloError
  handleOnSelectRoom?: (arg0: {
    roomId: string
    roomNumber: string
    roomTypeName: string
  }) => void
  handleOnSelectRoomType?: (arg0: {
    roomTypeId: string
    roomTypeName: string
  }) => void
  loading: boolean
  mode: Mode
  setDisplayRange: (range: DateRange) => void
  setMode: (mode: Mode) => void
}

export const AvailabilityCalendar = ({
  data,
  displayRange,
  error,
  handleOnSelectRoom,
  handleOnSelectRoomType,
  loading,
  mode,
  setDisplayRange,
  setMode,
  ...availabilityTableProps
}: Props) => (
  <Content>
    <ViewControlsWrapper>
      <ViewControls
        displayRange={displayRange}
        mode={mode}
        setDisplayRange={setDisplayRange}
        setMode={setMode}
      />
    </ViewControlsWrapper>
    <TableWrapper>
      <AvailabilityTable
        data={data}
        displayRange={displayRange}
        error={error}
        loading={loading}
        handleOnSelectRoom={handleOnSelectRoom}
        handleOnSelectRoomType={handleOnSelectRoomType}
        mode={mode}
        {...availabilityTableProps}
      />
    </TableWrapper>
  </Content>
)

////////////

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow: hidden;
`

const TableWrapper = styled.div`
  display: flex;
  flex: 1;

  overflow: auto;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gutter};
  `}
`

const ViewControlsWrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`
