import styled, { css } from 'styled-components/macro'

import { Chip as BaseChip } from '@/components/Chip'
import { InlineModalLine } from '@/components/InlineModal'
import type { ProductMeal } from '@/modules/Products/types'
import { T } from '@/modules/Language'

import { DurationInput, RestaurantSelect, StartTimeInput } from './components'

type Props = {
  isLast: boolean
  meal: ProductMeal
  showBoundary: boolean
}

export const MealSection = ({
  isLast,
  meal: { boundary, duration, id, location, meal, startTime },
  showBoundary,
}: Props) => (
  <>
    <InlineModalLine dashed style={{ margin: 0 }} />

    <Wrapper isLast={isLast}>
      <ChipWrapper>
        <Chip size="sm" variant="outlined" color="secondary">
          <T>Products:ProductManager.pricing.meal.title</T>
        </Chip>
      </ChipWrapper>

      <MealName>{meal.name}</MealName>

      <StartTimeInput mealId={id} startTime={startTime} />

      <DurationInput duration={duration} mealId={id} />

      <RestaurantSelect location={location} mealId={id} />

      <Spacer />

      {showBoundary && (
        <Boundary>
          <T>{`Products:ProductManager.pricing.meal.boundary.${boundary}`}</T>
        </Boundary>
      )}
    </Wrapper>
  </>
)

/////////

const Boundary = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Chip = styled(BaseChip)`
  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const ChipWrapper = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(11)}rem;
  `}
`

const MealName = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1.25)}rem;
    margin-right: ${theme.spacing.gu(1.5)}rem;
    width: ${theme.spacing.gu(14)}rem;
  `}
`

const Spacer = styled.div`
  flex: 1;
`

const Wrapper = styled.div<{ isLast: boolean }>`
  display: flex;
  align-items: center;

  height: 100%;

  ${({ isLast }) =>
    isLast &&
    css`
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    `}

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
    background: ${theme.palette.smoke.extraLight};
  `}
`
