import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import {
  InlineModal,
  InlineModalContent,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { Payment, PaymentReferenceTransferDetails } from '@/modules/Order/types'
import { orderServices } from '@/modules/Order'
import { PaymentOperation } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  AmountInput,
  DateInput,
  NotesTextarea,
  ReferenceInput,
} from './components'
import { ReferencePaymentModalFooter } from './ReferencePaymentModalFooter'

type Props = {
  invoiceId: string
  onClose: () => void
  orderId: string
  payment?: Payment
}

export const ReferencePaymentModal = ({
  invoiceId,
  onClose,
  orderId,
  payment,
}: Props) => {
  const { spacing } = useTheme()

  const { addReferencePayment, removeManualPayment, updateManualPayment } =
    orderServices.invoiceService()

  const details = payment?.details as PaymentReferenceTransferDetails | null

  const [amount, setAmount] = useState<number>(payment ? payment.amount : 0)
  const [date, setDate] = useState<string>(payment ? payment.date : '')
  const [reference, setReference] = useState<string>(details?.reference ?? '')
  const [notes, setNotes] = useState<string>(details?.notes ?? '')
  const [processing, setProcessing] = useState<boolean>(false)

  const isAddingDisabled = !amount || !date
  const isRemovingDisabled = !payment?.lifecycle.validatedActions.find(
    ({ action }) => action === PaymentOperation.DeleteManualPayment
  )?.valid

  const paymentInput = {
    amount: Number(amount),
    date,
    invoiceId,
    notes,
    orderId,
  }

  const handleAddPayment = () => {
    setProcessing(true)
    addReferencePayment({ ...paymentInput, reference }).then(onClose)
  }

  const handleRemovePayment = () =>
    removeManualPayment(invoiceId, orderId, payment?.id ?? '')

  const handleUpdatePayment = () => {
    if (payment) {
      setProcessing(true)
      updateManualPayment({ id: payment.id, ...paymentInput }).then(onClose)
    }
  }

  return (
    <Modal>
      <InlineModalHeader
        onClose={onClose}
        title={
          <ShiftLeft>
            <T>Orders:Action.INVOICE.ADD_MANUAL_REFERENCE_TRANSFER_PAYMENT</T>
          </ShiftLeft>
        }
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      <InlineModalContent>
        <DateInput date={date} setDate={setDate} />

        <ReferenceInput
          disabled={!!payment}
          reference={reference}
          setReference={setReference}
        />

        <NotesTextarea notes={notes} setNotes={setNotes} />

        <AmountInput value={amount.toString()} setAmount={setAmount} />
      </InlineModalContent>

      <ReferencePaymentModalFooter
        handleAddPayment={handleAddPayment}
        handleRemovePayment={handleRemovePayment}
        handleUpdatePayment={handleUpdatePayment}
        isAddingDisabled={isAddingDisabled}
        isRemovingDisabled={isRemovingDisabled}
        isEditMode={!!payment}
        isProcessing={processing}
        onClose={onClose}
      />
    </Modal>
  )
}

////////

const Modal = styled(InlineModal)`
  max-height: 100vh;
  width: 420px;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(5)}rem;
  `}
`
