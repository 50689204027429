import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import type {
  SalesForDateQuery as QueryData,
  SalesForDateQueryVariables as QueryVariables,
} from '~generated-types'
import { OPEN_STATES } from '@/modules/Sales'

import { SalesForDate } from '../types'
import salesForDateSales from '../fragments/salesForDateSales'

const QUERY = gql`
  ${salesForDateSales}

  query SalesForDate($date: LocalDate!) {
    salesForDate(input: { date: $date }) {
      ...SalesForDateSales
    }
  }
`

type Params = {
  date: Moment
  forceRefetch?: boolean
}

export const useSalesForDate = ({ forceRefetch, date }: Params) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: forceRefetch ? 'cache-and-network' : 'cache-first',
    variables: {
      date: date.format('YYYY-MM-DD'),
    },
  })

  const sales: SalesForDate[] = data
    ? [...data.salesForDate].filter((sales) =>
        OPEN_STATES.includes(sales.lifecycle.state)
      )
    : []

  return {
    error,
    loading,
    sales,
  }
}
