import { translate, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'
import { OrderItem as OrderItemType } from '@/modules/Order/types'
import { OrderPacketSourceType } from '~generated-types'

import { OrderItem } from './OrderItem'
import { OrderItemsSpacer } from './OrderItemsSpacer'
import { OrderItemsTitle } from './OrderItemsTitle'

type ManagerIds = {
  productId: string
  purchaseId: string
}

type Props = {
  items: OrderItemType[]
  setManagerIds: (ids: ManagerIds | null) => void
  showProductCodes: boolean
  type: OrderPacketSourceType
}

export const OrderItemsSection = ({ items, type, ...props }: Props) => {
  const { language } = useLanguageContext()

  return !!items.length ? (
    <>
      <OrderItemsSpacer />

      <OrderItemsTitle
        title={translate(`Orders:OrderItems.section.${type}`, language)}
      />

      {items.sort(generateCompareFn('name')).map((item) => (
        <OrderItem item={item} key={`manual-item-${item.id}`} {...props} />
      ))}
    </>
  ) : null
}
