import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { DateRangePickerControl } from '@/components/TimeControls'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import ServiceItemRounded from '../../ParticipantRow/common/ServiceItemRounded'

type Props = {
  dates: {
    end: string
    start: string
  } | null
  readOnly?: boolean
  handleSetDates: (
    dateRange: {
      end: string
      start: string
    } | null
  ) => Promise<any>
}

const DateRangeSelector = ({ dates, readOnly, handleSetDates }: Props) => {
  const { palette, spacing } = useTheme()

  const [preflightDate, setDate] = useState<{
    end: string | null
    start: string | null
  }>({
    end: null,
    start: null,
  })

  const date = {
    end: preflightDate.end || dates?.end,
    start: preflightDate.start || dates?.start,
  }

  useEffect(() => {
    preflightDate.end === dates?.end &&
      preflightDate.start === dates?.start &&
      setDate({
        end: null,
        start: null,
      })
  }, [dates])

  const getDatesLabel = (startDate: string, endString: string) => {
    const start = moment(startDate)
    const end = moment(endString)

    return (
      <p style={{ margin: 0, whiteSpace: 'nowrap' }}>
        {start.isSame(end) ? (
          start.format('dd, D.M.YY')
        ) : (
          <>
            {start.format(`dd, D.M${start.isSame(end, 'year') ? '' : '.YY'}`)}
            {' – '}
            {end.format('dd, D.M.YY')}
          </>
        )}
      </p>
    )
  }

  return (
    <DateRangePickerControl
      placement="bottom-start"
      render={({ openModal, ref }: any) => (
        <ServiceItemRounded
          ref={ref}
          disabled={readOnly}
          width={'15rem'}
          renderContent={
            <DateRangePicker>
              <FontAwesomeIcon
                color={palette.primary.main}
                icon={['far', 'calendar']}
              />
              <FlexRow flex={1} justifyContent="center">
                {date.end && date.start ? (
                  getDatesLabel(date.start, date.end)
                ) : (
                  <FontWeight
                    style={{
                      color: palette.smoke.dark,
                      fontStyle: 'italic',
                      paddingLeft: `${spacing.gu(0.5)}rem`,
                    }}
                  >
                    <T>ParticipantsList:ParticipantFormFields.noDates</T>
                  </FontWeight>
                )}
              </FlexRow>
            </DateRangePicker>
          }
          onClick={() => !readOnly && openModal()}
        />
      )}
      setValue={(newRange) => {
        setDate({
          end: newRange?.to.format('YYYY-MM-DD') || null,
          start: newRange?.from.format('YYYY-MM-DD') || null,
        })

        handleSetDates(
          newRange
            ? {
                end: newRange?.to.format('YYYY-MM-DD'),
                start: newRange?.from.format('YYYY-MM-DD'),
              }
            : null
        )
      }}
      value={
        date.end && date.start
          ? {
              from: moment(date.start),
              to: moment(date.end),
            }
          : null
      }
    />
  )
}

export default DateRangeSelector

const DateRangePicker = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall};
  `}
`
