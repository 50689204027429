import { gql, useMutation } from '@apollo/client'

import type {
  AcceptInvoiceMutation,
  AcceptInvoiceMutationVariables,
} from '~generated-types'
import { orderFragments } from '@/modules/Order'

const ACCEPT_INVOICE_MUTATION = gql`
  ${orderFragments.invoice}

  mutation AcceptInvoice($input: OrderInvoiceAcceptInput!) {
    orderInvoiceAccept(input: $input) {
      invoice {
        ...Invoice
      }
    }
  }
`

export const useAcceptInvoiceMutation = () =>
  useMutation<AcceptInvoiceMutation, AcceptInvoiceMutationVariables>(
    ACCEPT_INVOICE_MUTATION
  )
