import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'
import styled from 'styled-components/macro'
import { useEffect } from 'react'

import { FlexColumn } from '@/components/Layout'
import { InlineModalSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'

import { Input, InputWrapper, Title } from './common'

type Props = HOCProps & {
  setAmount: (amount: number) => void
}

const AmountInputBase = ({ value, setAmount, onChange, ...props }: Props) => {
  useEffect(() => {
    setAmount(Number(value || 0))
  }, [value])

  return (
    <Wrapper>
      <FlexColumn flex={1} noPadding>
        <Title>
          <T>Orders:Payments.field.amount</T> *
        </Title>

        <InputWrapper>
          <Input
            {...props}
            endAdornment="€"
            onChange={onChange}
            onFocus={(e) => e.currentTarget.select()}
            showBorder
            type="text"
            value={value}
          />
        </InputWrapper>
      </FlexColumn>
    </Wrapper>
  )
}

export const AmountInput = withPriceInputLogic(AmountInputBase)

////////

const Wrapper = styled(InlineModalSection)`
  flex: 1;
  margin-top: 0;
`
