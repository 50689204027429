import { ChangeEvent, CSSProperties, KeyboardEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import { FlexRow } from '@/components/Layout'
import { Input } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  value: string
  placeholder: string
  style?: CSSProperties
  disabled?: boolean
  onSubmit?: (value: string) => Promise<any>
  onChange?: (value: string) => void
}

const TextInput = ({
  value,
  placeholder,
  style,
  disabled,
  onSubmit,
  onChange,
}: Props) => {
  const { palette } = useTheme()

  const [processing, setProcessing] = useState(false)
  const [valueState, setValue] = useState(value)

  const handleSubmit = (value: string) => {
    setProcessing(true)

    onSubmit && onSubmit(value).then(() => setProcessing(false))
  }

  return (
    <FlexRow justifyContent="flex-end" alignItems="center">
      <StyledInput
        disabled={disabled}
        processing={processing}
        onBlur={() => handleSubmit(onChange ? value : valueState)}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            handleSubmit(onChange ? value : valueState)
          }
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          onChange ? onChange(e.target.value) : setValue(e.target.value)
        }
        placeholder={placeholder}
        type="text"
        value={onChange ? value : valueState}
        style={style}
      />
      {processing && (
        <LoaderWrapper>
          <ReactLoading
            type={'spin'}
            height={18}
            width={18}
            color={palette.smoke.main}
          />
        </LoaderWrapper>
      )}
    </FlexRow>
  )
}

export default TextInput

const StyledInput = styled(Input)<any>`
  &&& {
    transition: background 0.1s ease-out;
    border: 0;
    height: 100%;
    border-radius: 0;
    ${({ theme, type }) =>
      type === 'number' &&
      `padding-right: calc(${theme.spacing.gu(2)}rem + 1px)`};
    font-weight: 500;
    text-align: ${({ type }) => (type === 'number' ? 'end' : 'start')};
    padding-right: ${({ processing, theme }) =>
      processing ? `calc(${theme.spacing.gu(1)}rem + 18px)` : '0'};

    ${({ theme }) => css`
      padding-left: ${theme.spacing.gu(1)}rem;
      width: ${theme.spacing.gu(12)}rem;
      font-size: ${theme.typography.fontSizeSmall};
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      font-weight: 500;
      font-style: italic;
      opacity: 1;

      ${({ theme }) => css`
        color: ${theme.palette.smoke.dark};
      `}
    }
  }
`

const LoaderWrapper = styled.div`
  position: absolute;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall};
  `}
`
