import { createContext, ReactNode, useContext, useRef } from 'react'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { defaultTheme, Theme, useDynamicTheme } from '@/theme'
import {
  NavDropdownExternalItem,
  NavDropdownInternalItem,
  NavDropdownItem,
  NavDropdownItemType,
  NavItem,
} from '@/components/MainNavbar'
import { NavInternalViewType } from '~generated-types'

import { defaultNavItems } from './defaultNavItems'
import { useWillbaConfig } from './useWillbaConfig'

const defaultTimezone = 'Europe/Helsinki'

type ContextType = {
  allNavDropdownExternalItems: NavDropdownExternalItem[]
  allNavDropdownInternalTypes: NavInternalViewType[]
  isCatalogInternalPriceEnabled: boolean
  isMarginalTaxEnabled: boolean
  isCommissionEnabled: boolean
  isSalesInternalPriceEnabled: boolean
  navItems: NavItem[]
  theme: Theme
  timezone: string
}

const WillbaConfigContext = createContext<ContextType>({
  allNavDropdownExternalItems: [],
  allNavDropdownInternalTypes: [],
  isCatalogInternalPriceEnabled: false,
  isCommissionEnabled: false,
  isMarginalTaxEnabled: false,
  isSalesInternalPriceEnabled: false,
  navItems: defaultNavItems,
  theme: defaultTheme,
  timezone: defaultTimezone,
})

type Props = {
  children: ReactNode
}

export const WillbaConfigContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { loading: themeLoading, theme } = useDynamicTheme()
  const {
    loading: configLoading,
    isCatalogInternalPriceEnabled,
    isMarginalTaxEnabled,
    isCommissionEnabled,
    isSalesInternalPriceEnabled,
    navItems,
    timezone,
  } = useWillbaConfig()

  const allNavDropdownItems = (navItems ?? defaultNavItems).reduce(
    (acc: NavDropdownItem[], item) => [...acc, ...item.items],
    []
  )

  const allNavDropdownExternalItems = allNavDropdownItems.filter(
    (item) => item.type === NavDropdownItemType.ExternalItem
  ) as NavDropdownExternalItem[]

  const allNavDropdownInternalItems = allNavDropdownItems.filter(
    (item) => item.type === NavDropdownItemType.InternalItem
  ) as NavDropdownInternalItem[]

  const allNavDropdownInternalTypes = [
    ...new Set(allNavDropdownInternalItems.map(({ key }) => key)),
  ]

  contextValueRef.current = {
    allNavDropdownExternalItems,
    allNavDropdownInternalTypes,
    isCatalogInternalPriceEnabled,
    isCommissionEnabled,
    isMarginalTaxEnabled,
    isSalesInternalPriceEnabled,
    navItems: navItems ?? defaultNavItems,
    theme: theme ?? defaultTheme,
    timezone: timezone ?? defaultTimezone,
  }

  return (
    <WillbaConfigContext.Provider value={contextValueRef.current}>
      {themeLoading || configLoading ? renderWillbaConfigLoader() : children}
    </WillbaConfigContext.Provider>
  )
}

export const useWillbaConfigContext = () => useContext(WillbaConfigContext)

//////

const renderWillbaConfigLoader = () => (
  <WillbaConfigLoaderWrapper>
    <ReactLoading
      color={defaultTheme.palette.smoke.lighter}
      height={72}
      type="spin"
      width={72}
    />
  </WillbaConfigLoaderWrapper>
)

const WillbaConfigLoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  position: fixed;
  width: 100%;
`
