import moment from 'moment'
import { useMemo } from 'react'

import {
  ParticipantForPrint,
  ParticipantsForPrintServiceBed,
} from '@/modules/ParticipantsList'
import { T, useLanguageContext } from '@/modules/Language'
import { ListingTable } from '@/modules/Listing/common'
import { useTheme } from '@/theme'

import { ParticipantService } from '../ParticipantsTable/ParticipantsTableRow'
import { ParticipantsGroupTableRow } from './ParticipantsGroupTableRow'

type Props = {
  pageBreak: boolean
  participants: ParticipantForPrint[]
}

type ParticipantServiceById = {
  [key: string]: ParticipantService
}

type Participant = Omit<ParticipantForPrint, 'services' | '__typename'> & {
  services: ParticipantService[]
}

export type Group = {
  group: string
  participants: Participant[]
}

export const ParticipantsGroupTable = ({ pageBreak, participants }: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const groupedParticipants = useMemo(() => {
    return participants.reduce((acc: Group[], p) => {
      const { __typename, services: allServices, group, ...rest } = p

      const accommodationServices = allServices.filter(
        (s) => s.__typename === 'ServiceParticipantBed'
      ) as ParticipantsForPrintServiceBed[]

      const servicesById = accommodationServices.reduce(
        (acc: ParticipantServiceById, s) => {
          const dates = {
            checkIn: s.dates?.checkIn.date ?? null,
            checkOut: s.dates?.checkOut.date ?? null,
          }
          const room = s.participantRoom?.roomReservation.request.room
          const roomId = room?.id ?? 'unassigned'
          const roomNumber = room?.number ?? null

          acc[roomId] = {
            dates: [...(acc[roomId]?.dates ?? []), dates],
            roomId,
            roomNumber,
          }

          return acc
        },
        {}
      )

      const services = Object.values(servicesById)
        .map((service) => ({
          ...service,
          dates: service.dates.sort(
            (a, b) => moment(a.checkIn).valueOf() - moment(b.checkIn).valueOf()
          ),
        }))
        .sort((a, b) =>
          (a.roomNumber ?? '').localeCompare(b.roomNumber ?? '', language, {
            numeric: true,
          })
        )

      const existingGroup = acc.find((g) => g.group === group)
      const unassignedGroup = acc.find((g) => g.group === 'unassigned')

      if (group && existingGroup) {
        existingGroup.participants.push({ group, services, ...rest })
      } else if (!group && unassignedGroup) {
        unassignedGroup.participants.push({ group, services, ...rest })
      } else {
        acc.push({
          group: group || 'unassigned',
          participants: [{ group, services, ...rest }],
        })
      }

      return acc
    }, [])
  }, [participants, language]).sort((a, b) => groupComapreFn(a, b, language))

  return (
    <ListingTable style={{ marginTop: spacing.gutter }}>
      <thead>
        <tr>
          <th>
            <T>ParticipantsList:excelHeader.group</T>
          </th>
          <th>
            <T>ParticipantsList:excelHeader.name</T>
          </th>
          <th>
            <T>ParticipantsList:ParticipantRooms.room</T>
          </th>
          <th>
            <T>ParticipantsList:excelHeader.accommodationDates</T>
          </th>
        </tr>
      </thead>
      <tbody>
        {groupedParticipants.map((g, idx) => (
          <ParticipantsGroupTableRow
            group={g}
            isFirst={idx === 0}
            key={g.group}
            pageBreak={pageBreak}
          />
        ))}
      </tbody>
    </ListingTable>
  )
}

/////////

const groupComapreFn = (a: Group, b: Group, language: string) => {
  return a.group === 'unassigned' && b.group !== 'unassigned'
    ? 1
    : b.group === 'unassigned' && a.group !== 'unassigned'
    ? -1
    : a.group.localeCompare(b.group, language, { numeric: true })
}
