import { ApolloQueryResult } from '@apollo/client'
import { useState } from 'react'

import styled, { css } from 'styled-components/macro'

import { Feature, OrderPacketSourceType as OPST } from '~generated-types'
import { Invoice, InvoiceByIdPayload, OrderSales } from '@/modules/Order/types'
import { Label, Toggle } from '@/components/FormControls'
import {
  SpreadsheetTable as Table,
  SpreadsheetTBody as TBody,
} from '@/components/Spreadsheet'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { SalesProductManager } from '@/modules/Products'

import {
  OrderItemsActions,
  OrderItemsHead,
  OrderItemsSection,
} from './components'
import { Section } from '../components'
import { T } from '@/modules/Language'

type Props = {
  invoice: Invoice
  readOnly: boolean
  refetch: () => Promise<ApolloQueryResult<InvoiceByIdPayload>>
  sales: OrderSales
  sellerId?: string
}

type ManagerIds = {
  productId: string
  purchaseId: string
}

export const OrderItems = ({
  invoice,
  readOnly,
  refetch,
  sales: { commissionRate, facet, id: salesId, type: salesType },
  sellerId,
}: Props) => {
  const { items } = invoice

  const [managerIds, setManagerIds] = useState<ManagerIds | null>(null)
  const [showProductCodes, setShowProductCodes] = useState<boolean>(false)

  const isCommissionEnabled = !!facet.features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const orderItems = items.filter((i) => i.source.type === OPST.SalesPurchase)
  const manualItems = items.filter((i) => i.source.type === OPST.Manual)
  const serviceFeeItems = items.filter((i) => i.source.type === OPST.ServiceFee)

  const handleCloseEditor = () => {
    setManagerIds(null)
    refetch()
  }

  return (
    <Section>
      <FlexRow>
        <OrderItemsActions
          invoice={invoice}
          refetch={refetch}
          salesId={salesId}
        />

        {!!items.length && (
          <FlexRow flex={1} alignItems="center" justifyContent="flex-end">
            <ToggleLabel>
              <T>Orders:OrderItems.showProductCodes</T>
            </ToggleLabel>
            <Toggle
              checked={showProductCodes}
              hideLabels
              noMargin
              onChange={() => setShowProductCodes(!showProductCodes)}
            />
          </FlexRow>
        )}
      </FlexRow>

      {!!items.length && (
        <StyledTable>
          <OrderItemsHead />

          <TBody>
            <OrderItemsSection
              items={manualItems}
              setManagerIds={setManagerIds}
              showProductCodes={showProductCodes}
              type={OPST.Manual}
            />
            <OrderItemsSection
              items={orderItems}
              setManagerIds={setManagerIds}
              showProductCodes={showProductCodes}
              type={OPST.SalesPurchase}
            />
            <OrderItemsSection
              items={serviceFeeItems}
              setManagerIds={setManagerIds}
              showProductCodes={showProductCodes}
              type={OPST.ServiceFee}
            />
          </TBody>
        </StyledTable>
      )}

      {managerIds && (
        <ModalContainer
          isOpen={!!managerIds}
          modal={
            <SalesProductManager
              commission={commission}
              context="SALES"
              onClose={handleCloseEditor}
              productId={managerIds.productId}
              readOnly={readOnly}
              salesId={salesId}
              salesType={salesType}
              sellerId={sellerId}
              targetPurchaseId={managerIds.purchaseId}
            />
          }
          onClose={handleCloseEditor}
          referenceElement={() => null}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />
      )}
    </Section>
  )
}

/////

const StyledTable = styled(Table)`
  max-width: none;

  ${({ theme }) => css`
    margin: ${theme.spacing.gu(2)}rem 0 0 0;
  `}
`

const ToggleLabel = styled(Label)`
  margin-bottom: 0;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(2)}rem;
  `}
`
