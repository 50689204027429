import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { Button } from '@/components/Button'
import { ModalContainer } from '@/components/Modal'
import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import {
  ActionSection,
  MealSection,
  NoteSection,
  PersonSection,
} from './components'
import { MealRowsByDate } from '../../types'
import { NewMealModal } from '../NewMealModal'
import { Separator } from '../common'
import { useMealsContext } from '../../MealsState'

type Props = {
  day: MealRowsByDate
}

export const MealsContent = ({ day }: Props) => {
  const { data } = useSalesDetailsContext()
  const { isEditMode } = useMealsContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const isEvent = data.type === SalesType.Event

  return (
    <Wrapper>
      <SectionsWrapper>
        <FirstSeparator isEditMode={isEditMode} />

        <MealSection day={day} />

        <Separator showDivider />

        <PersonSection day={day} />

        {!isEvent && (
          <>
            <Separator showDivider />

            <NoteSection day={day} />

            {isEditMode && (
              <>
                <Separator showDivider />
                <ActionSection day={day} />
              </>
            )}
          </>
        )}
      </SectionsWrapper>

      {isEditMode && !isEvent && (
        <ModalContainer
          placement="left"
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          modal={
            <NewMealModal
              defaultDate={day.date}
              isExistingDate
              onClose={() => setModalOpen(false)}
            />
          }
          referenceElement={({ ref }) => (
            <div ref={ref}>
              <Tooltip
                content={<T>SalesDetails:meals.action.addNew</T>}
                delay={300}
                placement="left"
                trigger={(triggerProps) => (
                  <span {...triggerProps}>
                    <PlusButton noNudge onClick={() => setModalOpen(true)}>
                      <FontAwesomeIcon fixedWidth icon="plus" />
                    </PlusButton>
                  </span>
                )}
              />
            </div>
          )}
        />
      )}
    </Wrapper>
  )
}

///////////

const FirstSeparator = styled(Separator)<{ isEditMode: boolean }>`
  ${({ isEditMode, theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
    margin-right: ${theme.spacing.gu(isEditMode ? 1 : 3)}rem;
  `}
`

const PlusButton = styled(Button)`
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  height: 100%;

  ${({ theme }) => css`
    border-left: solid 1px ${theme.palette.smoke.light};
    color: ${theme.palette.primary.main};
    padding: 0 ${theme.spacing.gu(2.5)}rem;
  `}

  &:not(:disabled):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
      border-left: solid 1px ${theme.palette.smoke.light};
    `}
  }

  &:not(:disabled):active,
  &:not(:disabled):focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
      border-left: solid 1px ${theme.palette.smoke.light};
    `}
  }
`

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem 0;
  `}
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  border-radius: 6px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.main};
  `}
`
