import { ReactNode, useMemo } from 'react'
import styled from 'styled-components/macro'

import { ListingSectionTitle, ListingTable, TD } from '@/modules/Listing/common'
import { T, useLanguageContext } from '@/modules/Language'

import {
  ShowReservationGroups,
  SortOption,
} from '../../CalendarResourceListingContainer'
import { ListingReservation } from '../useReservationList'
import { PrintSize } from '../../../types'
import { ReservationSectionRow } from './ReservationSectionRow'
import { resourceCompareFn } from '../utils'

type Props = {
  label: ReactNode
  printSize: PrintSize
  reservations: ListingReservation[]
  showAllTasks: boolean
  showExtendedTasks: boolean
  showReservationGroups: ShowReservationGroups
  sortBy: SortOption
  viewAssigneeSelection: string[]
}

type UniqueReservationsByGroups = {
  reservations: ListingReservation[]
}

export const ReservationSection = ({
  label,
  printSize,
  reservations: propsReservations,
  showAllTasks,
  showExtendedTasks,
  showReservationGroups,
  sortBy,
  viewAssigneeSelection,
}: Props) => {
  const { language } = useLanguageContext()

  const reservations = useMemo(() => {
    const sortedReservations = [...propsReservations].sort(
      resourceCompareFn(sortBy, language)
    )

    if (showReservationGroups === 'hidden') return sortedReservations

    return sortedReservations.reduce(
      (acc: UniqueReservationsByGroups, resrvation: ListingReservation) => {
        if (!resrvation.group) {
          acc.reservations.push(resrvation)
          return acc
        }

        if (
          showReservationGroups === 'show_once' &&
          !acc.reservations.find((r) => r.group?.id === resrvation.group?.id)
        ) {
          acc.reservations.push(resrvation)
          return acc
        }

        if (
          showReservationGroups === 'repeat' &&
          !acc.reservations.find((r) => {
            if (
              sortBy === 'time' &&
              !resrvation.group?.reservations.find(
                (r) => r.start !== resrvation.start
              )
            ) {
              return r.group?.id === resrvation.group?.id
            } else {
              return (
                r.resource?.id === resrvation.resource?.id &&
                r.group?.id === resrvation.group?.id
              )
            }
          })
        ) {
          acc.reservations.push(resrvation)
          return acc
        }

        return acc
      },
      {
        reservations: [],
      }
    ).reservations
  }, [JSON.stringify(propsReservations), showReservationGroups, sortBy])

  return (
    <div>
      <ListingSectionTitle>{label}</ListingSectionTitle>
      <ListingTable printSize={printSize}>
        <TableHead>
          <tr>
            <th colSpan={2}>
              <T>Listings:CalendarResourceListing.field.resource</T>
            </th>
            <th>
              <T>Listings:CalendarResourceListing.field.time</T>
            </th>
            <th>
              <T>Listings:CalendarResourceListing.field.sport</T> /{' '}
              <T>Listings:CalendarResourceListing.field.activity</T>
            </th>
            <th>
              <T>Listings:CalendarResourceListing.field.description</T>
            </th>
            <th>
              <T>Listings:CalendarResourceListing.field.internalNote</T>
            </th>
            <th>
              <T>Listings:CalendarResourceListing.field.seller</T>
            </th>
            <th>
              <T>Listings:CalendarResourceListing.field.customer</T>
            </th>
            <th colSpan={2}>
              <T>Listings:CalendarResourceListing.field.sales</T>
            </th>
            <th style={{ textAlign: 'center' }}>
              <T>Listings:CalendarResourceListing.field.people</T>
            </th>
            {!showExtendedTasks && (
              <th>
                <T>Listings:CalendarResourceListing.field.tasks</T>
              </th>
            )}
          </tr>
        </TableHead>
        <tbody>
          {reservations.map((reservation, index) => (
            <ReservationSectionRow
              isLast={index === reservations.length - 1}
              key={reservation.id}
              reservation={reservation}
              showAllTasks={showAllTasks}
              showExtendedTasks={showExtendedTasks}
              showReservationGroups={showReservationGroups}
              sortBy={sortBy}
              viewAssigneeSelection={viewAssigneeSelection}
            />
          ))}

          {!reservations.length && (
            <tr>
              <TD colSpan={showExtendedTasks ? 11 : 12} textAlign="center">
                – <T>Listings:CalendarResourceListing.emptyGroup</T> –
              </TD>
            </tr>
          )}
        </tbody>
      </ListingTable>
    </div>
  )
}

export default ReservationSection

////////////

const TableHead = styled.thead`
  z-index: 1;
`
