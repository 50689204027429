import { FlexColumn, FlexRow } from '@/components/Layout'
import { salesHooks } from '@/modules/Sales'
import { T } from '@/modules/Language'

import { Cell } from './Cell'
import { DaysAndParticipants } from './DaysAndParticipants'

export const Totals = () => {
  const {
    data: { vst },
  } = salesHooks.useSalesDetailsContext()

  const participants = vst?.participantVstSummary ?? []

  const totalVstParticipantsLength = participants
    .map((s) => (!!s.gender && !!s.ageCategory ? s.vstParticipantCount : 0))
    .reduce((a, b) => a + b, 0)

  const totalNonVstParticipantsLength = participants
    .map((s) => (!!s.gender && !!s.ageCategory ? s.nonVstParticipantCount : 0))
    .reduce((a, b) => a + b, 0)

  const totalVstDays = participants
    .map((s) => (!!s.gender && !!s.ageCategory ? s.vstDays : 0))
    .reduce((a, b) => a + b, 0)

  const totalNonVstDays = participants
    .map((s) => (!!s.gender && !!s.ageCategory ? s.nonVstDays : 0))
    .reduce((a, b) => a + b, 0)

  return (
    <FlexColumn
      alignItems="flex-end"
      justifyContent="flex-end"
      flex="1"
      noPadding
    >
      <FlexRow justifyContent="flex-end" alignItems="center">
        <T>SalesDetails:VST.vstTotals</T>:{' '}
        <Cell justifyContent="flex-end">
          <DaysAndParticipants
            days={totalVstDays}
            participants={totalVstParticipantsLength}
          />
        </Cell>
      </FlexRow>

      <FlexRow justifyContent="flex-end" alignItems="center">
        <T>SalesDetails:VST.noVstTotals</T>:{' '}
        <Cell justifyContent="flex-end">
          <DaysAndParticipants
            days={totalNonVstDays}
            participants={totalNonVstParticipantsLength}
          />
        </Cell>
      </FlexRow>
    </FlexColumn>
  )
}
