import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { get } from 'dot-prop'
import React from 'react'
import uniq from 'lodash.uniq'

import { translate, useLanguageContext } from '@/modules/Language'
import { ChangelogEntry } from '@/modules/Changelog'
import { FlexColumn } from '@/components/Layout'
import { IconButton } from '@/components/ExtraButtons'

import EntryRow from './ChangelogEntryRow'

export interface ChangelogEntryGroupProps {
  data: ChangelogEntry[]
}

export const ChangelogEntryGroup = ({ data }: ChangelogEntryGroupProps) => {
  const { language } = useLanguageContext()

  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const mainTimestamp: Moment = moment(data[0].timestamp)
  const names: string[] = uniq(
    data.map(({ actor }) => get(actor, 'name') || 'system')
  )

  return (
    <Wrapper>
      <Header onClick={() => setIsOpen((current) => !current)}>
        <FlexColumn alignItems="flex-start" noPadding>
          <strong>
            {mainTimestamp.format('LL')} {mainTimestamp.format('LT')}
          </strong>
          <small>
            {names[0]}
            {names.length > 1 &&
              ` + ${translate('Changelog:field.otherActors', language, {
                count: names.length - 1,
              })}`}
          </small>
        </FlexColumn>
        <IconButton
          color="transparent"
          icon={isOpen ? 'caret-down' : 'caret-left'}
        />
      </Header>
      {isOpen && (
        <RowsWrapper>
          {data.map((x) => (
            <EntryRow data={x} key={`changelog-entry-row-${x.id}`} />
          ))}
        </RowsWrapper>
      )}
    </Wrapper>
  )
}

export default ChangelogEntryGroup

////////////

const Header = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`

const RowsWrapper = styled.div`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.gutterBig};
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    border-bottom: solid 1px ${theme.palette.smoke.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
