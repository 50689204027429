import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'
import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { DataTableInput } from '@/components/DataTable'
import { EditButton } from '@/modules/Products/components/common'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  id: string
  price: number
}

type InternalProps = HOCProps & {
  disabled: boolean
  updateValue: (amount: number) => void
}

export const PriceSection = ({ id, price }: Props) => {
  const { readOnly, updateProductItem } = useSalesProductManagerContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const handleUpdatePrice = (price: number) =>
    updateProductItem({ id, unitPrice: { price } }).then(() =>
      setEditMode(false)
    )

  return (
    <FlexColumn alignItems="flex-end">
      <Title>
        <T>Products:ProductManager.pricing.unitPrice</T>
      </Title>
      <InputWrapper>
        {isEditMode ? (
          <InputWithPriceLogic
            disabled={readOnly}
            updateValue={handleUpdatePrice}
            value={`${price}`}
          />
        ) : (
          <Button disabled={readOnly} onClick={() => setEditMode(true)}>
            <Price>{price}</Price>
            <EuroSign>€</EuroSign>
          </Button>
        )}
      </InputWrapper>
    </FlexColumn>
  )
}

const InputBase = ({ updateValue, value, ...props }: InternalProps) => (
  <Input
    {...props}
    focusOnMount
    endAdornment="€"
    flex="none"
    onBlur={() => updateValue(Number(value || 0))}
    onFocus={(e) => e.currentTarget.select()}
    showBorder
    type="text"
    value={value}
  />
)

const InputWithPriceLogic = withPriceInputLogic(InputBase)

///////

const Button = styled(EditButton)`
  justify-content: flex-end;
  margin: 0;

  ${({ theme }) => css`
    line-height: ${theme.typography.fontSizeBase};
    padding: ${theme.spacing.gu(0.5)}rem;
    padding-right: 0;
    width: ${theme.spacing.gu(12)}rem;
  `}
`

const Price = styled.span`
  ${({ theme }) => css`
    margin-right: -${theme.spacing.gu(0.75)}rem;
  `}
`

const EuroSign = styled(FlexRow)`
  justify-content: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}
`

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: end;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0px ${theme.spacing.gu(3)}rem 0px ${theme.spacing.gu(1)}rem;
      width: ${theme.spacing.gu(12)}rem;
    `}
  }
`

const InputWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: -${theme.spacing.gu(1)}rem;
    margin-right: -${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`
