import { SalesState } from '~generated-types'

export const getSalesStateColor = (state: SalesState) => {
  switch (state) {
    case SalesState.Cancelled:
    case SalesState.CancelProcess:
      return 'danger'
    case SalesState.Confirmed:
      return 'success'
    case SalesState.Open:
      return 'primary'
    case SalesState.Closed:
    case SalesState.Draft:
    default:
      return 'default'
  }
}
