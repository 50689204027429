import styled, { css } from 'styled-components/macro'
import { UIEvent, useRef, useState } from 'react'
import { draftToMarkdown } from 'markdown-draft-js'

import { Feature } from '~generated-types'
import { SalesDetailsForPrintPayload } from '@/modules/Sales'

import { Customer, MainDetails, Participants, Program } from './components'
import { ScrollIndicator } from '../../components'

type Props = {
  data: SalesDetailsForPrintPayload
  showPrices: boolean
}

export const SalesModalContent = ({ data, showPrices }: Props) => {
  const { sales, scheduledMealsBySales } = data

  const {
    accommodation,
    customer,
    facet: { features },
    notes,
    participantConnection,
    resourceReservations,
    tasks,
  } = sales

  const scrollRef = useRef(null)

  const [scrollTop, setScrollTop] = useState<number>(0)

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    if (scrollRef && scrollRef.current === event.target) {
      setScrollTop(event.currentTarget.scrollTop)
    }
  }

  const notesMarkdown = notes ? draftToMarkdown(JSON.parse(notes)) : ''

  const participants = participantConnection?.nodes ?? []

  const roomReservations = accommodation
    ? [
        ...accommodation.roomReservations,
        ...accommodation.roomTypeReservations
          .map(({ roomReservations }) => roomReservations)
          .flat(),
      ]
    : []

  const showParticipants = features.find(
    (f) => f.feature === Feature.Participants
  )
  const showProgram =
    features.find((f) => f.feature === Feature.Meals) ||
    features.find((f) => f.feature === Feature.Reservations) ||
    features.find((f) => f.feature === Feature.Tasks)

  return (
    <>
      <ScrollIndicator hasScrolled={!!scrollTop} zIndex={502} />

      <Wrapper onScroll={onScroll} ref={scrollRef}>
        <MainDetails sales={sales} />

        <Divider />

        {(!!customer || !!notesMarkdown) && (
          <Customer customer={customer} notes={notesMarkdown} />
        )}

        {showParticipants && (
          <Participants
            participants={participants}
            roomReservations={roomReservations}
            showPrices={showPrices}
          />
        )}

        {showProgram && (
          <Program
            meals={scheduledMealsBySales ?? []}
            reservations={resourceReservations ?? []}
            showPrices={showPrices}
            tasks={tasks ?? []}
          />
        )}
      </Wrapper>
    </>
  )
}

/////

const Divider = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.light};
  `}
`

const Wrapper = styled.div`
  overflow: auto;

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(4)}rem;
  `}
`
