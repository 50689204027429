import CREATE_ADDRESS from './createAddress'
import CREATE_CUSTOMER_ORGANIZATION from './createCustomerOrganization'
import CREATE_CUSTOMER_PERSON from './createCustomerPerson'
import CREATE_ORGANIZATION_CONTACT from './createOrganizationContact'
import REMOVE_ADDRESS from './removeAddress'
import REMOVE_ORGANIZATION_CONTACT from './removeOrganizationContact'
import SET_DEFAULT_ADDRESS from './setDefaultAddress'
import SET_DEFAULT_ORGANIZATION_CONTACT from './setDefaultOrganizationContact'
import UPDATE_ADDRESS from './updateAddress'
import UPDATE_CUSTOMER_ORGANIZATION from './updateCustomerOrganization'
import UPDATE_CUSTOMER_PERSON from './updateCustomerPerson'
import UPDATE_ORGANIZATION_CONTACT from './updateOrganizationContact'
import UPDATE_ORGANIZATION_EINVOICING_ADDRESS from './updateOrganizationEInvoicingAddress'

export default Object.freeze({
  CREATE_ADDRESS,
  CREATE_CUSTOMER_ORGANIZATION,
  CREATE_CUSTOMER_PERSON,
  CREATE_ORGANIZATION_CONTACT,
  REMOVE_ADDRESS,
  REMOVE_ORGANIZATION_CONTACT,
  SET_DEFAULT_ADDRESS,
  SET_DEFAULT_ORGANIZATION_CONTACT,
  UPDATE_ADDRESS,
  UPDATE_CUSTOMER_ORGANIZATION,
  UPDATE_CUSTOMER_PERSON,
  UPDATE_ORGANIZATION_CONTACT,
  UPDATE_ORGANIZATION_EINVOICING_ADDRESS,
})

export type {
  CreateCustomerAddressMutation as CreateCustomerAddressPayload,
  CreateCustomerAddressMutationVariables as CreateCustomerAddressVariables,
  CreateCustomerOrganizationMutation as CreateCustomerOrganizationPayload,
  CreateCustomerOrganizationMutationVariables as CreateCustomerOrganizationVariables,
  CreateCustomerPersonMutation as CreateCustomerPersonPayload,
  CreateCustomerPersonMutationVariables as CreateCustomerPersonVariables,
  CreateOrganizationCustomerContactMutation as CreateOrganizationContactPayload,
  CreateOrganizationCustomerContactMutationVariables as CreateOrganizationContactVariables,
  RemoveCustomerAddressMutation as RemoveAddressPayload,
  RemoveCustomerAddressMutationVariables as RemoveAddressVariables,
  RemoveOrganizationCustomerContactMutation as RemoveOrganizationContactPayload,
  RemoveOrganizationCustomerContactMutationVariables as RemoveOrganizationContactVariables,
  SetCustomerDefaultAddressMutation as SetDefaultAddressPayload,
  SetCustomerDefaultAddressMutationVariables as SetDefaultAddressVariables,
  SetOrganizationCustomerDefaultContactMutation as SetDefaultOrganizationContactPayload,
  SetOrganizationCustomerDefaultContactMutationVariables as SetDefaultOrganizationContactVariables,
  UpdateCustomerAddressMutation as UpdateAddressPayload,
  UpdateCustomerAddressMutationVariables as UpdateAddressVariables,
  UpdateCustomerOrganizationMutation as UpdateCustomerOrganizationPayload,
  UpdateCustomerOrganizationMutationVariables as UpdateCustomerOrganizationVariables,
  UpdateCustomerPersonMutation as UpdateCustomerPersonPayload,
  UpdateCustomerPersonMutationVariables as UpdateCustomerPersonVariables,
  UpdateOrganizationCustomerContactMutation as UpdateOrganizationContactPayload,
  UpdateOrganizationCustomerContactMutationVariables as UpdateOrganizationContactVariables,
  UpdateOrganizationCustomerEInvoicingAddressMutation as UpdateOrganizationEInvoicingAddressPayload,
  UpdateOrganizationCustomerEInvoicingAddressMutationVariables as UpdateOrganizationEInvoicingAddressVariables,
} from '~generated-types'
