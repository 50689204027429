import { MouseEvent, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  OrderItem,
  OrderItemProduct as OrderItemProductType,
} from '@/modules/Order/types'
import {
  SpreadsheetTD as TD,
  SpreadsheetTR as TR,
} from '@/components/Spreadsheet'
import { FontWeight } from '@/components/Typography'
import { formatCurrency } from '@/utils/currency'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import { ItemName, ItemNameWrapper, LightLabel, Spacer } from './common'
import { OrderItemProduct } from './OrderItemProduct'

type Props = {
  openEditor: () => void
  packet: OrderItem
  participantName: string | ReactNode
  showProductCodes: boolean
}

export const OrderItemPacket = ({
  openEditor,
  packet,
  participantName,
  showProductCodes,
}: Props) => {
  const { name, packetTotal, products, quantity } = packet

  const [isHover, setHover] = useState<boolean>(false)
  const [isExpanded, setExpanded] = useState<boolean>(false)

  const issues = products.find((p) => !!p.validationStatus.issues.length)
    ?.validationStatus.issues

  const hoverProps = {
    isHover,
    onMouseEnter: () => setHover(true),
    onMouseLeave: () => setHover(false),
  }

  const expandPacketProduts = (e: MouseEvent) => {
    e.stopPropagation()
    setExpanded(!isExpanded)
  }

  return (
    <>
      <Row
        borderBottomStyle={isExpanded ? 'dashed' : 'solid'}
        onClick={openEditor}
        {...hoverProps}
      >
        <TD style={{ padding: 0 }}>
          <ExpandButton onClick={expandPacketProduts}>
            <ChevronIcon icon="chevron-right" open={isExpanded} />
          </ExpandButton>
        </TD>

        <TD style={{ paddingLeft: 0 }}>
          <ItemNameWrapper alignItems="center">
            <ItemName>{name}</ItemName>
            <Spacer />
            {issues?.map(({ code, key, level }) => (
              <ValidationIndicator
                key={`${key}-${code}`}
                code={code}
                level={level}
              />
            ))}
          </ItemNameWrapper>
        </TD>

        <TD>{participantName}</TD>

        <TD colSpan={3} />

        <TD align="right">
          {quantity} <T>common:unit.PIECE</T>
        </TD>

        <TD align="right">
          {formatCurrency(packetTotal.vatAmount || 0)} €{' '}
          <LightLabel>
            (<T>Orders:OrderItems.vat</T>)
          </LightLabel>
        </TD>

        <TD align="right">
          <FontWeight bold>
            {formatCurrency(packetTotal.amountVatIncluded || 0)} €
          </FontWeight>
        </TD>

        <TD />
      </Row>

      {isExpanded &&
        [...products]
          .sort(generateCompareFn('name'))
          .map((product, idx) => (
            <OrderItemProduct
              isLastNestedItem={idx + 1 >= products.length}
              isNested
              key={product.id}
              openEditor={openEditor}
              product={product as OrderItemProductType}
              showProductCodes={showProductCodes}
              {...hoverProps}
            />
          ))}
    </>
  )
}

/////

export const ChevronIcon = styled(FontAwesomeIcon)<{
  open: boolean
}>`
  transition: 0.25s;

  ${({ open }) =>
    open &&
    css`
      transform: rotate(90deg);
    `}
`

const Row = styled(TR)<{ isHover?: boolean }>`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  ${({ isHover, theme }) =>
    isHover && `background: ${theme.palette.primary.extraLighter};`}
`

const ExpandButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  width: 34px;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
    `}
  }
`
