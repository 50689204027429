import { CSSProperties, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import {
  Reservation,
  resourceReservationHooks,
  SalesResourceReservationForGroup,
} from '@/modules/Reservations/ResourceReservation'
import { Spacing, useTheme } from '@/theme'
import { FlexRow } from '@/components/Layout'
import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'

import { EditButton, IconWrapper, Placeholder } from '../../components'
import { getDateRange } from './utils'

type Props = {
  groupId: string
  readOnly: boolean
  singleReservations: SalesResourceReservationForGroup[]
  updateReservations: (reservations: Reservation[]) => void
  updateStateAfterAdding: (reservationId: string) => void
}

export const ReservationSelector = ({
  groupId,
  readOnly,
  singleReservations,
  updateReservations,
  updateStateAfterAdding,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { setToGroup } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  useEffect(() => {
    return () => setProcessing(false)
  }, [])

  const handleSetToGroup = (reservationId: string) => {
    setProcessing(true)

    setToGroup(reservationId, groupId).then(() => {
      setEditMode(false)
      setHover(false)
      setProcessing(false)
      updateStateAfterAdding(reservationId)
    })
  }

  return (
    <InlineModalIconSection icon={['far', 'calendar-plus']}>
      <FlexRow>
        {isEditMode ? (
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isCompact
            isLoading={isProcessing}
            menuIsOpen={isEditMode}
            name="single-reservation-selector"
            noOptionsMessage={() => <T>ReservationModal:group.noReservation</T>}
            onBlur={() => {
              setEditMode(false)
              setHover(false)
            }}
            onChange={(option?: Option | null) =>
              handleSetToGroup(option?.value || '')
            }
            options={singleReservations
              .sort(
                (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
              )
              .map((r) => ({
                label: renderReservationLabel(r),
                value: r.id,
              }))}
            placeholder={
              <Placeholder>
                <T>ReservationModal:group.addReservation</T>
              </Placeholder>
            }
            value={null}
          />
        ) : (
          <EditButton
            disabled={readOnly}
            onClick={() => setEditMode(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Placeholder>
              <T>ReservationModal:group.addReservation</T>
            </Placeholder>

            <IconWrapper>
              {isHover && (
                <FontAwesomeIcon
                  color={palette.primary.main}
                  icon="plus"
                  size="sm"
                />
              )}
            </IconWrapper>
          </EditButton>
        )}
      </FlexRow>
    </InlineModalIconSection>
  )
}

////////

const renderReservationLabel = ({
  end,
  resource,
  start,
}: SalesResourceReservationForGroup) => (
  <FlexRow alignItems="center" flex={1} justifyContent="space-between">
    <ResourceName>
      {resource?.name ?? <T>ResourceReservations:resource.program</T>}
    </ResourceName>
    <DateRange>{getDateRange(end, start)}</DateRange>
  </FlexRow>
)

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor:
      styles.backgroundColor === 'transparent' ? 'not-allowed' : 'pointer',
    lineHeight: '24px',
    padding: '4px 40px 4px 12px',
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    maxWidth: 'calc(100% - 18px)',
    width: '100%',
  }),
})

const DateRange = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const ResourceName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(20)}rem;
  `}
`
