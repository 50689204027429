import { useState } from 'react'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { ElasticCatalogProduct } from '@/modules/Products/types'
import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { SortOption } from '@/components/Reactivesearch'

import { ListFilters, ListHeader } from '../CatalogProductList/components'
import { ListItem } from './components'

const ComponentIds = Object.freeze({
  CATALOG: 'catalog',
  CATEGORY: 'category',
  LIST: 'page',
  SEARCH: 'catalog-product-picker',
})

type SelectedIds = {
  elasticItemId: string
  itemIds: string[]
}

type Props = {
  isMulti?: boolean
  onItemSelect: (id: string) => Promise<string[] | undefined | void>
  onItemUnselect?: (ids: string[]) => Promise<string | undefined>
  sellerId?: string
}

export const CatalogProductPickerList = ({
  isMulti = false,
  onItemSelect,
  onItemUnselect,
  sellerId,
}: Props) => {
  const { language } = useLanguageContext()

  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<SelectedIds[]>([])

  const sortOptions: SortOption[] = [
    {
      dataField: 'name.keyword',
      direction: 'asc',
      label: translate('Products:CatalogProductList.sort.nameAsc', language),
    },
    {
      dataField: 'name.keyword',
      direction: 'desc',
      label: translate('Products:CatalogProductList.sort.nameDesc', language),
    },
  ]

  const [sortProperty, setSortProperty] = useState<SortOption | null>(
    sortOptions[0]
  )

  const sort = sortProperty
    ? [
        { _score: 'asc' },
        { [sortProperty.dataField]: sortProperty.direction },
        { 'name.keyword': 'asc' },
      ]
    : [{ _score: 'asc' }, { 'name.keyword': 'asc' }]

  const defaultQueryFn = () => ({ sort })

  const onItemMultiSelect = (item: ElasticCatalogProduct) => {
    const isItemSelected = selectedIds
      .map(({ elasticItemId }) => elasticItemId)
      .includes(item.id)

    const selectedItemIds = selectedIds.find(
      ({ elasticItemId }) => elasticItemId === item.id
    )?.itemIds

    if (isItemSelected && onItemUnselect) {
      return onItemUnselect(selectedItemIds || [])
        .then(() =>
          setSelectedIds(selectedIds.filter((s) => item.id !== s.elasticItemId))
        )
        .finally(() => setProcessing(false))
    }

    return onItemSelect(item.id)
      .then((data) => {
        if (Array.isArray(data)) {
          setSelectedIds([
            ...selectedIds,
            { elasticItemId: item.id, itemIds: data },
          ])
        }
      })
      .finally(() => setProcessing(false))
  }

  const handleItemSelect = (item: ElasticCatalogProduct) => {
    if (isProcessing) {
      return new Promise<void>((reject) => reject())
    }

    setProcessing(true)

    return isMulti ? onItemMultiSelect(item) : onItemSelect(item.id)
  }

  return (
    <ElasticFilterSearchList
      columnCount={7}
      indexName="registry.products"
      reactiveListProps={{
        componentId: ComponentIds.LIST,
        dataField: 'name',
        defaultQuery: defaultQueryFn,
        react: {
          and: [
            ComponentIds.CATALOG,
            ComponentIds.CATEGORY,
            ComponentIds.SEARCH,
          ],
        },
        size: 50,
      }}
      renderListControls={() => null}
      renderListFilters={() => (
        <ListFilters
          componentIds={ComponentIds}
          getDefaultQuery={defaultQueryFn}
          sellerId={sellerId}
        />
      )}
      renderListHeader={() => (
        <ListHeader
          isMulti={isMulti}
          selectedItemsNumber={selectedIds.length}
        />
      )}
      renderListItem={(item: ElasticCatalogProduct) => (
        <ListItem
          data={item}
          isProcessing={isProcessing}
          isMulti={isMulti}
          isSelected={selectedIds
            .map(({ elasticItemId }) => elasticItemId)
            .includes(item.id)}
          key={`catalog-product-${item.id}`}
          onItemSelect={() => handleItemSelect(item)}
        />
      )}
      sortProps={{
        options: sortOptions,
        setValue: setSortProperty,
        value: sortProperty,
      }}
      title={<T>Products:CatalogProductList.title</T>}
    />
  )
}
