import { gql, useMutation } from '@apollo/client'

import {
  RefreshProductsVatMutation,
  RefreshProductsVatMutationVariables,
} from '~generated-types'

export type { RefreshProductsVatMutation, RefreshProductsVatMutationVariables }

export const REFRESH_PRODUCTS_VAT = gql`
  mutation RefreshProductsVat($input: SalesProductsRefreshVatInput!) {
    salesProductsRefreshVat(input: $input) {
      id
    }
  }
`

export function useRefreshProductsVat() {
  return useMutation<
    RefreshProductsVatMutation,
    RefreshProductsVatMutationVariables
  >(REFRESH_PRODUCTS_VAT)
}

export default useRefreshProductsVat
