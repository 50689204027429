import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  rowHeight?: string
}

export const TotalsHeaderRow = ({ children, rowHeight }: Props) => (
  <HeaderWrapper rowHeight={rowHeight ?? 'auto'}>
    <Header>
      <ExpandPlaceholder maxHeight={rowHeight ?? 'auto'} />
      {children}
    </Header>
  </HeaderWrapper>
)

////////////

const ExpandPlaceholder = styled.div<{ maxHeight: string }>`
  border-radius: 0;
  margin-right: 10px;
  max-height: ${({ maxHeight }) => maxHeight};
  width: 40px;
`

const HeaderWrapper = styled.section<{ rowHeight: string }>`
  height: ${({ rowHeight }) => rowHeight};
  display: inline-flex;
  align-items: center;
  z-index: 3002;

  ${({ theme }) => css`
    color: ${theme.palette.coal.main};
    border-bottom: 1px solid ${theme.palette.smoke.dark};
    border-top: 1px solid ${theme.palette.smoke.dark};
    background: ${theme.palette.smoke.lighter};
  `}
`

const Header = styled.div`
  position: sticky;
  left: 0px;
  font-weight: 600;
  font-variant: all-small-caps;
  display: inline-flex;
  align-items: center;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
