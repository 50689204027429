import { CSSProperties, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import { WebshopThemesQuery } from '~generated-types'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Spacing, useTheme } from '@/theme'
import { EditButton } from '@/components/ExtraButtons'
import { generateCompareFn } from '@/utils/arrays'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'

import { ContentPlaceholder, TruncateContent } from '../../common'
import { publishQueries } from '../../../queries'
import { usePublishContext } from '../../../PublishState'

type WebshopTheme = WebshopThemesQuery['registry']['webshopThemes'][0]

export const Theme = () => {
  const { spacing } = useTheme()
  const { readOnly, salesWebshopSettings, salesId, updateWebshopSettings } =
    usePublishContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [webshopThemes, setWebshopThemes] = useState<WebshopTheme[]>([])

  const { data, loading } = useQuery<WebshopThemesQuery>(
    publishQueries.webshopThemes,
    {
      skip: !isEditMode || !!webshopThemes.length,
    }
  )

  useEffect(() => {
    if (data) {
      setWebshopThemes(data.registry.webshopThemes)
    }
  }, [data])

  const options = [...(webshopThemes ?? [])]
    .sort(generateCompareFn('name'))
    .map((theme) => ({
      label: theme.name,
      value: theme.id,
    }))

  const handleChangeSaleTheme = (option?: Option | null) => {
    if (option && webshopThemes?.length) {
      updateWebshopSettings({ theme: { id: option.value, salesId } }).finally(
        () => {
          setEditMode(false)
        }
      )
    }
  }

  const selectedTheme = salesWebshopSettings?.theme

  return (
    <>
      <Label>{<T>Publish:Basic.theme.title</T>}</Label>

      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isLoading={loading}
          isDisabled={readOnly}
          menuIsOpen={isEditMode && !loading}
          name="webshop-theme-selector"
          noOptionsMessage={() => <T>Publish:Basic.theme.noThemesAvailable</T>}
          onChange={handleChangeSaleTheme}
          onBlur={() => setEditMode(false)}
          options={options}
          placeholder={<T>Publish:Basic.theme.noThemeSelected</T>}
          value={
            selectedTheme
              ? { label: selectedTheme.name, value: selectedTheme.id }
              : null
          }
        />
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          style={{ flex: 'unset' }}
        >
          <TruncateContent
            content={
              selectedTheme?.name || (
                <ContentPlaceholder>
                  <T>Publish:Basic.theme.noThemeSelected</T>
                </ContentPlaceholder>
              )
            }
          />
        </EditButton>
      )}
    </>
  )
}

///////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
})
