import styled, { css } from 'styled-components/macro'
import { set } from 'dot-prop'

import { AvailabilityCalendar, EmphasisDetails } from '@/modules/Accommodation'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { getDateRange } from '@/utils/time'

import {
  DateRange,
  GenericReservation,
  SalesDetails,
} from './SalesReservationManager.types'
import { SalesDetailsRow } from './components/SalesDetailsRow'
import { Sidebar } from './components/Sidebar'

type Props = {
  defaultRange: DateRange | null | undefined
  groupId: string
  focusedRoomId?: string
  handleAddPendingRoomReservation: (arg0: {
    roomId: string
    roomNumber: string
    roomTypeName: string
  }) => void
  handleAddPendingRoomTypeReservation?: (arg0: {
    roomTypeId: string
    roomTypeName: string
  }) => void
  openSectionsFromSidebar: {
    [sectionId: string]: boolean
  }
  refreshTicker: number
  reservations: GenericReservation[]
  salesDetails: SalesDetails
  setOpenSections: (arg0: { [sectionId: string]: boolean }) => void
}

export const SalesReservationManager = ({
  defaultRange,
  groupId,
  focusedRoomId,
  handleAddPendingRoomReservation,
  handleAddPendingRoomTypeReservation,
  openSectionsFromSidebar,
  refreshTicker,
  reservations,
  salesDetails,
  setOpenSections,
}: Props) => (
  <Wrapper flex={1}>
    <ContentWrapper flex={1}>
      <SalesDetailsRow salesDetails={salesDetails} />
      <Divider />
      <AvailabilityCalendar
        defaultRange={defaultRange}
        emphasis={getEmphasisDetails(reservations)}
        handleOnSelectRoom={handleAddPendingRoomReservation}
        handleOnSelectRoomType={handleAddPendingRoomTypeReservation}
        openSectionsFromSidebar={openSectionsFromSidebar}
        refreshTicker={refreshTicker}
      />
    </ContentWrapper>
    <Sidebar
      groupId={groupId}
      reservations={reservations}
      focusedRoomId={focusedRoomId}
      setOpenSections={setOpenSections}
    />
  </Wrapper>
)

////////////

const ContentWrapper = styled(FlexColumn)`
  margin-right: 5px;
  overflow: hidden;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.main};
  `}
`

const Divider = styled(FlexColumn)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.main};
  `}
`

const Wrapper = styled(FlexRow)`
  overflow: hidden;
`

const getEmphasisDetails = (
  reservations: GenericReservation[]
): EmphasisDetails =>
  reservations.reduce(
    (acc: any, val: any) => {
      let nestedRooms: any = {}
      let range: any = []
      let targetPath: any = null

      if (val.__typename === 'PendingRoomReservation' && val.range) {
        range = getDateRange(
          val.range.from.format('YYYY-MM-DD'),
          val.range.to.format('YYYY-MM-DD')
        )
        range.pop()

        targetPath = `rooms.${val.roomId}`
      }

      if (val.__typename === 'PendingRoomTypeReservation' && val.range) {
        range = getDateRange(
          val.range.from.format('YYYY-MM-DD'),
          val.range.to.format('YYYY-MM-DD')
        )
        range.pop()

        targetPath = `roomTypes.${val.roomTypeId}`
      }

      if (val.__typename === 'RoomReservation') {
        range = getDateRange(
          val.request.checkIn.date,
          val.request.checkOut.date
        )
        range.pop()

        targetPath = `rooms.${val.request.room.id}`
      }

      if (val.__typename === 'RoomTypeReservation') {
        range = getDateRange(
          val.request.checkIn.date,
          val.request.checkOut.date
        )
        range.pop()

        targetPath = `roomTypes.${val.request.roomType.id}`
      }

      range.forEach((dateKey: any, idx: number) => {
        if (targetPath) {
          if (range.length === 1) {
            set(acc, `${targetPath}.${dateKey}`, 'FULL')
          } else if (idx === 0) {
            set(acc, `${targetPath}.${dateKey}`, 'START')
          } else if (idx + 1 === range.length) {
            set(acc, `${targetPath}.${dateKey}`, 'END')
          } else {
            set(acc, `${targetPath}.${dateKey}`, 'MIDDLE')
          }
        }
      })

      if (val.__typename === 'RoomTypeReservation') {
        nestedRooms = val.roomReservations.reduce((acc: any, val: any) => {
          range = getDateRange(
            val.request.checkIn.date,
            val.request.checkOut.date
          )
          range.pop()
          targetPath = `${val.request.room.id}`

          range.forEach((dateKey: any, idx: number) => {
            if (targetPath) {
              if (range.length === 1) {
                set(acc, `${targetPath}.${dateKey}`, 'FULL')
              } else if (idx === 0) {
                set(acc, `${targetPath}.${dateKey}`, 'START')
              } else if (idx + 1 === range.length) {
                set(acc, `${targetPath}.${dateKey}`, 'END')
              } else {
                set(acc, `${targetPath}.${dateKey}`, 'MIDDLE')
              }
            }
          })

          return acc
        }, {})
      }

      return { ...acc, rooms: { ...acc.rooms, ...nestedRooms } }
    },
    { rooms: {}, roomTypes: {} }
  )
