import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash.isequal'
import React from 'react'

import {
  ActionDropdownButton,
  ActionDropdownList,
  ActionDropdownListItem,
} from '@/components/ActionDropdown'
import { Dropdown, DropdownRenderProps } from '@/components/Dropdown'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { buttonCss } from '../styles'

export type SortOption = {
  dataField: string
  direction: 'asc' | 'desc'
  label: string
}

type SortToggleProps = {
  options: SortOption[]
  setValue: (target: SortOption | null) => void
  value: SortOption | null
}

export const SortToggle = ({ options, setValue, value }: SortToggleProps) => {
  const renderOption = (
    option: SortOption,
    toggleDropdown: (...args: Array<any>) => any
  ) => (
    <ActionDropdownListItem
      key={`dropdown-item-${option.dataField}-${option.direction}`}
    >
      <OptionButton
        onClick={(e: React.MouseEvent) => {
          setValue(option)
          toggleDropdown(e)
        }}
        type="button"
      >
        <ActiveIndicator isActive={isEqual(option, value)} />
        <OptionLabel>{option.label}</OptionLabel>
      </OptionButton>
    </ActionDropdownListItem>
  )

  const hasOptions = options && options.length

  return hasOptions ? (
    <FlexRow alignItems="center" style={{ position: 'relative' }}>
      <DropdownLabel>
        <T>Reactivesearch:sort</T>:
      </DropdownLabel>
      <Dropdown
        noPadding
        positioning={{ right: '0', top: '32' }}
        renderControl={({ toggleDropdown }) => (
          <PickerButton onClick={toggleDropdown}>
            <PickerButtonLabel>{value && value.label}</PickerButtonLabel>
            <FontAwesomeIcon icon="caret-down" />
          </PickerButton>
        )}
        renderContent={({ toggleDropdown }: DropdownRenderProps) => (
          <ActionDropdownList>
            {options.map((o) => renderOption(o, toggleDropdown))}
          </ActionDropdownList>
        )}
      />
    </FlexRow>
  ) : null
}

////////////

type ActiveIndicatorProps = {
  isActive: boolean
}

const ActiveIndicator = ({ isActive }: ActiveIndicatorProps) => {
  const theme = useTheme()

  return (
    <span style={{ flex: '0 0 1.5rem' }}>
      {isActive && (
        <FontAwesomeIcon
          color={theme.palette.text.light}
          icon="check"
          size="xs"
        />
      )}
    </span>
  )
}

const DropdownLabel = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const OptionButton = styled(ActionDropdownButton)`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 300px;
`

const OptionLabel = styled.span`
  flex: 1;
`

const PickerButton = styled.button`
  ${buttonCss};

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const PickerButtonLabel = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
