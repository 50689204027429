import { MouseEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import {
  CustomerList,
  CustomerTarget,
  EditCustomer,
} from '@/modules/Registry/Customer'
import { BottomSheet } from '@/components/BottomSheet'
import { Button } from '@/components/Button'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'

import { CreateCustomer } from '../../CreateCustomer'

type Props = {
  focusTarget: CustomerTarget | null | undefined
  onClose: () => void
  onSetCustomer: (args: {
    addressId: string | null | undefined
    contactId: string | null | undefined
    customer: CustomerTarget
  }) => Promise<void | null | undefined>
}

export const RegistryModal = ({
  focusTarget,
  onClose,
  onSetCustomer,
}: Props) => {
  const [editTarget, setEditTarget] = useState<
    CustomerTarget | null | undefined
  >(null)
  const [isAdding, setIsAdding] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const showList = () => {
    setIsAdding(false)
    setEditTarget(null)
  }

  const handleSelectCustomer = (
    addressId: string | null | undefined,
    contactId: string | null | undefined
  ) =>
    !!editTarget
      ? onSetCustomer({
          addressId,
          contactId,
          customer: editTarget,
        }).then(() => onClose())
      : Promise.reject()

  return (
    <>
      <BottomSheet
        onClose={onClose}
        title={<T>Customers:CustomerManager.titleList</T>}
      >
        <Gutter type={[0, 5, 10]}>
          <CustomerList
            highlightTarget={focusTarget}
            onAdd={() => setIsAdding(true)}
            onOpen={(target) => setEditTarget(target)}
            renderItemControls={(target) =>
              target.customerNumber !== focusTarget?.customerNumber ? (
                <Button
                  color="primary"
                  disabled={processing}
                  onClick={(event: MouseEvent) => {
                    event.preventDefault()
                    event.stopPropagation()

                    setProcessing(true)
                    onSetCustomer({
                      addressId: null,
                      contactId: null,
                      customer: target,
                    })
                      .then(onClose)
                      .catch(() => undefined)
                  }}
                  size="small"
                >
                  <T>Customers:CustomerManager.select</T>
                </Button>
              ) : (
                <SelectionIndicator>
                  <T>Customers:CustomerManager.selected</T>
                </SelectionIndicator>
              )
            }
            URLParams={false}
          />
        </Gutter>
      </BottomSheet>
      {isAdding ? (
        <BottomSheet
          onBack={showList}
          onClose={onClose}
          title={<T>Customers:CustomerManager.titleCreate</T>}
        >
          <Gutter type={[3, 5, 10]}>
            <CreateCustomer
              hideHeader
              onCreated={({
                customerId,
                customerNumber,
                defaultAddressId,
                defaultContactId,
              }) => {
                const target = { customerNumber, id: customerId }
                onSetCustomer({
                  addressId: defaultAddressId,
                  contactId: defaultContactId,
                  customer: target,
                }).then(() => onClose())
              }}
            />
          </Gutter>
        </BottomSheet>
      ) : (
        !!editTarget && (
          <BottomSheet
            onBack={showList}
            onClose={onClose}
            title={<T>Customers:CustomerManager.titleEdit</T>}
          >
            <Gutter type={[0, 5, 10]}>
              <EditCustomer
                customerNumber={editTarget.customerNumber || '-1'}
                onSelectCustomer={handleSelectCustomer}
              />
            </Gutter>
          </BottomSheet>
        )
      )}
    </>
  )
}

const SelectionIndicator = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
