import moment, { Moment } from 'moment'
import React from 'react'

import { DateControl } from './DateControl'
import { WeekControl } from './WeekControl'

type Props = {
  date: Moment
  mode: 'DATE' | 'WEEK'
  setDate: (arg0: Moment) => void
}

export const DateWeekControl = ({ date, mode, setDate }: Props) => (
  <>
    {mode === 'DATE' && (
      <DateControl
        decrement={() => setDate(date.clone().subtract(1, 'day'))}
        increment={() => setDate(date.clone().add(1, 'day'))}
        setCurrentDay={() => setDate(moment())}
        value={date}
      />
    )}
    {mode === 'WEEK' && (
      <WeekControl
        decrement={() => setDate(date.clone().subtract(1, 'week'))}
        end={date.clone().endOf('isoWeek')}
        increment={() => setDate(date.clone().add(1, 'week'))}
        setCurrentWeek={() => setDate(moment())}
        start={date.clone().startOf('isoWeek')}
      />
    )}
  </>
)
