import { gql } from '@apollo/client'

import {
  paymentCashDetails,
  paymentCreditCardDetails,
  paymentGiftCardDetails,
  paymentOtherDetails,
  paymentReferenceTransferDetails,
  paymentRefundDetails,
  paymentReservationFeeDetails,
  paymentVoucherDetails,
} from './paymentDetails'

export default gql`
  ${paymentCashDetails}
  ${paymentCreditCardDetails}
  ${paymentGiftCardDetails}
  ${paymentOtherDetails}
  ${paymentReferenceTransferDetails}
  ${paymentRefundDetails}
  ${paymentReservationFeeDetails}
  ${paymentVoucherDetails}

  fragment Payment on Payment {
    amount
    auditLog {
      createdAt
    }
    externalPayment {
      errorCode
      errorMessage
      externalId
      referer
      sourceId
    }
    date
    details {
      ...PaymentCashDetails
      ...PaymentCreditCardDetails
      ...PaymentGiftCardDetails
      ...PaymentOtherDetails
      ...PaymentReferenceTransferDetails
      ...PaymentRefundDetails
      ...PaymentReservationFeeDetails
      ...PaymentVoucherDetails
    }
    id
    lifecycle {
      validatedActions {
        action
        issues {
          code
        }
        valid
      }
    }
    number
    source
    state
    type
  }
`
