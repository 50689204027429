import {
  ContentPlaceholder,
  LoadingPlaceholder,
} from '@/components/Placeholders'
import { Button } from '@/components/Button'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useChangelog } from '@/modules/Changelog'

import EntryGroup from './ChangelogEntryGroup'

export interface ChangelogListProps {
  /**
   * The ID of the target entity
   */
  target: string
}

/**
 * A paginated list of Changelog entries.
 */
export const ChangelogList = ({ target }: ChangelogListProps) => {
  const { data, error, groupedData, hasMore, loading, loadMore } = useChangelog(
    { target }
  )

  /* 1) Fetch error */
  if (error) {
    return (
      <ContentPlaceholder size="lg">
        <T>Changelog:error.fetch</T>
      </ContentPlaceholder>
    )
  }

  /* 2) Initial load */
  if (!data.length && loading) {
    return <LoadingPlaceholder size="xl" />
  }

  /* 3) Empty list */
  if (!data.length) {
    return (
      <ContentPlaceholder size="lg">
        <T>Changelog:error.empty</T>
      </ContentPlaceholder>
    )
  }

  /* 4) Actual list */
  return (
    <>
      {groupedData.map((group, idx) => (
        <EntryGroup
          data={group}
          key={`changelog-entry-group-${group?.[0]?.id}-${idx}`}
        />
      ))}
      {loading && <LoadingPlaceholder size="md" />}
      {hasMore && !loading && (
        <FlexRow justifyContent="center">
          <Button onClick={() => loadMore()} size="small" variant="outlined">
            <T>Changelog:action.loadMore</T>
          </Button>
        </FlexRow>
      )}
    </>
  )
}

export default ChangelogList
