import { MouseEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import {
  MobileSalesMeal as Meal,
  MobileSalesResourceReservation as Reservation,
  MobileSalesTask as Task,
} from '@/modules/Mobile/SalesList'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

import { getGroupedMeals, getProgramByDateKey } from './utils'
import { InnerContainer, InnerSection, Section } from '../components'
import { ProgramDay } from './ProgramDay'
import { SectionTitle } from './SectionTitle'
import { TaskRow } from './Rows'

type Props = {
  meals: Meal[]
  reservations: Reservation[]
  showPrices: boolean
  tasks: Task[]
}

export const Program = ({
  meals: inputMeals,
  reservations: inputReservations,
  showPrices,
  tasks: inputTasks,
}: Props) => {
  const meals = getGroupedMeals(inputMeals)
  const reservations = inputReservations
  const tasks = inputTasks.filter(({ dueDate }) => !!dueDate)
  const tasksWithNoDate = inputTasks.filter(({ dueDate }) => !dueDate)

  // TODO: Get from some config?
  const showAllResources = true

  const programByDate = getProgramByDateKey({
    meals,
    reservations,
    showAllResources,
    tasks,
  })

  const dates = Object.keys(programByDate).sort()
  const programDates = !!tasksWithNoDate.length ? [...dates, 'NO_DATE'] : dates

  const currentDate = programDates.find((date) =>
    moment(date).isSame(moment(), 'day')
  )

  const [expandedDates, setExpandedDates] = useState<string[]>(
    currentDate ? [currentDate] : []
  )

  const isAllDatesExpanded = expandedDates.length === programDates.length

  const toggleExpandDay = (date: string) => {
    setExpandedDates((prevDates) =>
      prevDates.includes(date)
        ? prevDates.filter((existingDate) => existingDate !== date)
        : [...prevDates, date]
    )
  }

  const expandAllDays = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    setExpandedDates(isAllDatesExpanded ? [] : programDates)
  }

  return (
    <Section
      extraAction={
        programDates.length ? (
          <ShowAllButton onClick={expandAllDays}>
            {isAllDatesExpanded ? (
              <T>Mobile:salesList.program.hideAll</T>
            ) : (
              <T>Mobile:salesList.program.showAll</T>
            )}
          </ShowAllButton>
        ) : null
      }
      isExpandable
      title={<T>SalesDetails:PrintSale.label.PROGRAM</T>}
    >
      <Wrapper>
        {!programDates.length ? (
          <Placeholder>
            – <T>SalesDetails:PrintSale.noProgram</T> –
          </Placeholder>
        ) : (
          <FlexColumn noPadding>
            {programDates.map((date) =>
              date !== 'NO_DATE' ? (
                <ProgramDay
                  date={date}
                  expand={() => toggleExpandDay(date)}
                  isExpanded={expandedDates.includes(date)}
                  key={date}
                  program={programByDate[date]}
                  showAllResources={showAllResources}
                  showPrices={showPrices}
                />
              ) : (
                <InnerContainer>
                  <InnerSection
                    expand={() => toggleExpandDay('NO_DATE')}
                    isExpanded={expandedDates.includes('NO_DATE')}
                    isExpandable
                    visibleContent={<SectionTitle />}
                  >
                    <SectionContent>
                      {tasksWithNoDate.map((task) => (
                        <TaskRow
                          key={task.id}
                          data={task}
                          showPrices={showPrices}
                        />
                      ))}
                    </SectionContent>
                  </InnerSection>
                </InnerContainer>
              )
            )}
          </FlexColumn>
        )}
      </Wrapper>
    </Section>
  )
}

/////

const Placeholder = styled.div`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(1.5)}rem ${theme.spacing.gu(1)}rem;
  `}
`

const SectionContent = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(0.5)}rem;
  `}
`

const ShowAllButton = styled.button`
  border: none;
  font-weight: 500;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeSmall};
    height: ${theme.spacing.gu(4)}rem;
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}
`
