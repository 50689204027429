import classNames from 'classnames'
import uniq from 'lodash.uniq'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { typeGuardFactory } from '@/utils/types'
import { useTheme } from '@/theme'

import { parseParticipantName, parseParticipantProduct } from '../../utils'
import { CheckInOutTimes } from '../../common'
import type { DeparturesParticipant } from '../useDeparturesForDate'

type Props = {
  guests: DeparturesParticipant[]
  hideFromPrint?: boolean
}

export const SalesTableUnassignedRow = ({ guests, hideFromPrint }: Props) => {
  const theme = useTheme()

  const sortedGuests = guests.sort(generateCompareFn(['lastName', 'firstName']))
  const accommodationTargets: string[] = uniq(
    guests.reduce((acc: string[], val) => {
      const participantBedService = val.services.find(
        typeGuardFactory('__typename', 'ServiceParticipantBed')
      )

      return !!participantBedService?.accommodationTarget?.name
        ? [...acc, participantBedService.accommodationTarget.name]
        : acc
    }, [])
  )

  const rowSpan = sortedGuests.length || 1

  const renderGuestCells = (guest: DeparturesParticipant) => {
    const participantBedService = guest.services.find(
      typeGuardFactory('__typename', 'ServiceParticipantBed')
    )
    const guestDates = participantBedService?.dates

    return (
      <>
        <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
          <FlexColumn noPadding>
            <span>{parseParticipantName(guest) || ''}</span>
            {!!guestDates && (
              <small style={{ marginTop: theme.spacing.gutterSmall }}>
                <CheckInOutTimes
                  checkIn={guestDates.checkIn}
                  checkOut={guestDates.checkOut}
                />
              </small>
            )}
          </FlexColumn>
        </td>
        <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
          {parseParticipantProduct(guest, theme)}
        </td>
        <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
          {guest.additionalInfo || '—'}
        </td>
      </>
    )
  }

  return (
    <>
      <tr
        className={hideFromPrint ? 'hide-from-print' : ''}
        key="arrivals-sales-room-participant-0"
      >
        <td rowSpan={rowSpan}>
          <FlexColumn noPadding>
            <FlexRow>
              <em>
                <T>Listings:ArrivalsAndDeparturesListing.roomUnassigned</T>
              </em>
            </FlexRow>
            {!!accommodationTargets.length && (
              <FlexRow>{accommodationTargets.join(', ')}</FlexRow>
            )}
          </FlexColumn>
        </td>
        <td rowSpan={rowSpan} />
        {!!sortedGuests.length ? (
          renderGuestCells(sortedGuests[0])
        ) : (
          <>
            <td colSpan={3}>
              <em>
                <T>Listings:ArrivalsAndDeparturesListing.noGuests</T>
              </em>
            </td>
          </>
        )}
        <td colSpan={2} rowSpan={rowSpan} />
      </tr>
      {sortedGuests.slice(1).map((x, idx) => (
        <tr
          className={classNames({
            'extra-row': true,
            'hide-from-print': hideFromPrint,
          })}
          key={`arrivals-sales-room-participant-${idx}`}
        >
          {renderGuestCells(x)}
        </tr>
      ))}
    </>
  )
}
