import moment, { Moment } from 'moment'
import React, { ReactElement } from 'react'

import BasePicker, { BasePickerProps } from './components/BasePicker'

export type DatePickerProps = BasePickerProps & {
  setValue: (target: Moment | null | undefined, modifier: any) => void
  showTodayButton?: boolean
  value: (Moment | null | undefined) | Moment[]
}

const DatePicker: React.FC<DatePickerProps> = ({
  setValue,
  showTodayButton,
  value,
  ...dayPickerProps
}): ReactElement => (
  <BasePicker // Find the earliest date, if "value" - array of dates
    initialMonth={(Array.isArray(value)
      ? value.sort((a, b) => a.diff(b))[0]
      : value
    )?.toDate()}
    // "modifier" is needed to check that the date has already been selected
    onDayClick={(day, modifier: any) =>
      setValue(day ? moment(day) : null, modifier)
    }
    // @ts-ignore
    selectedDays={
      Array.isArray(value)
        ? value.length
          ? value.map((date) => date.toDate())
          : null
        : value?.toDate() ?? null
    }
    selectToday={
      showTodayButton
        ? (modifier: any) => {
            setValue(moment(), modifier)
          }
        : undefined
    }
    {...dayPickerProps}
  />
)

export default DatePicker
