import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  icon: IconProp
  keyName: string
  color: string
}

export const SectionName = ({ icon, keyName, color }: Props) => {
  const { spacing } = useTheme()

  return (
    <SectionNameWrapper>
      <Name color={color}>
        <FontAwesomeIcon
          color={color}
          fixedWidth
          icon={icon}
          style={{ marginRight: `${spacing.gu(1)}rem` }}
        />
        <FontWeight>
          <T>{`SalesDetails:section.${keyName}`}</T>
        </FontWeight>
      </Name>
    </SectionNameWrapper>
  )
}

const SectionNameWrapper = styled.div`
  ${({ theme }) => css`
    border-bottom: 2px solid ${theme.palette.smoke.main};
    margin-bottom: ${theme.spacing.gu(4)}rem;
  `}
`

const Name = styled.p`
  border-bottom: 4px solid ${({ color }) => color};
  margin: 0px;
  margin-bottom: -3px;
  padding: 0.5rem 1rem;
  width: max-content;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
