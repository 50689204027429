import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

type Props = {
  centerAlignContent?: boolean
  children: ReactNode
  header?: ReactNode
  isNested?: boolean
}

export const ReservationDataRow = ({
  centerAlignContent,
  children,
  header,
  isNested,
}: Props) => (
  <RowWrapper>
    <RowHeader isNested={isNested}>{header}</RowHeader>
    <RowContent
      isNested={!header && isNested}
      centerAlignContent={centerAlignContent}
    >
      {children}
    </RowContent>
  </RowWrapper>
)

////////////

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const RowContent = styled.div<{
  centerAlignContent?: boolean
  isNested?: boolean
}>`
  flex: 1 1 auto;
  word-wrap: break-word !important;
  max-width: 100%;
  min-width: 170px;
  ${({ isNested, theme }) =>
    isNested && `margin-left: ${theme.spacing.gutter};`}
  ${({ centerAlignContent }) =>
    centerAlignContent &&
    css<any>`
      align-items: center;
      display: inline-flex;
    `}
`

const RowHeader = styled.div<{ isNested?: boolean }>`
  flex: 0 0
    ${({ isNested, theme }) =>
      isNested ? theme.spacing.gu(1) : theme.spacing.gu(11)}rem;
  display: flex;
  justify-content: flex-end;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
