import {
  ResourceReservationCustomerVisibility as CustomerVisibility,
  ResourceType,
} from '~generated-types'
import {
  Reservation,
  ResourceReservationResource,
} from '@/modules/Reservations/ResourceReservation'
import { resourceReservationHooks } from '@/modules/Reservations/ResourceReservation'

import { CustomerVisibilityChip } from './CustomerVisibilityChip'

interface CustomerVisibilityToggleProps {
  customerVisibility: CustomerVisibility
  disabled?: boolean
  reservationId: string
  resource: ResourceReservationResource | null
  setReservations: (reservations: Reservation[]) => void
}

export const CustomerVisibilityToggle = ({
  customerVisibility,
  disabled,
  reservationId,
  resource,
  setReservations,
}: CustomerVisibilityToggleProps) => {
  const { setVisibility } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations: setReservations,
    })

  return (
    <CustomerVisibilityChip
      onClick={() => {
        setVisibility(
          reservationId,
          getNextVisibility(resource, customerVisibility)
        )
      }}
      visibility={customerVisibility}
      disabled={disabled}
    />
  )
}

export default CustomerVisibilityToggle

////////////

const getNextVisibility = (
  resource: ResourceReservationResource | null,
  customerVisibility: CustomerVisibility
) => {
  // Equipment types can only be hidden or visible
  if (resource?.type === ResourceType.Equipment) {
    switch (customerVisibility) {
      case CustomerVisibility.Hidden:
        return CustomerVisibility.Visible
      case CustomerVisibility.Visible:
      case CustomerVisibility.ResourceNameHidden:
      default:
        return CustomerVisibility.Hidden
    }
  }

  switch (customerVisibility) {
    case CustomerVisibility.Hidden:
      return CustomerVisibility.Visible
    case CustomerVisibility.Visible:
      return CustomerVisibility.ResourceNameHidden
    case CustomerVisibility.ResourceNameHidden:
    default:
      return CustomerVisibility.Hidden
  }
}
