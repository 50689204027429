import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'
import { Label } from '@/components/FormControls'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

export const ShortcutKeysInfo = () => {
  const theme = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const shortcuts = [
    {
      id: 1,
      label: <T>ResourceReservationsCalendar:Shortcuts.createReservation</T>,
      shortcut: (
        <>
          <FontAwesomeIcon
            icon={['far', 'hand-back-fist']}
            style={{ margin: `0 ${theme.spacing.gu(1)}rem` }}
          />
          <T>ResourceReservationsCalendar:Shortcuts.drawBlock</T>
        </>
      ),
    },
    {
      id: 2,
      label: (
        <T>
          ResourceReservationsCalendar:Shortcuts.createReservationImmediately
        </T>
      ),
      shortcut: (
        <>
          <KeyboardButton>
            <FontAwesomeIcon
              icon="arrow-up"
              size="xs"
              style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
            />
            Shift
          </KeyboardButton>{' '}
          +
          <FontAwesomeIcon
            icon={['far', 'hand-back-fist']}
            style={{ margin: `0 ${theme.spacing.gu(1)}rem` }}
          />
          <T>ResourceReservationsCalendar:Shortcuts.drawBlock</T>
        </>
      ),
    },
  ]

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal style={{ width: `${theme.spacing.gu(75)}rem` }}>
          <FlexColumn style={{ padding: theme.spacing.gutter }}>
            <Title>
              <T>ResourceReservationsCalendar:Shortcuts.title</T>
            </Title>

            {shortcuts.map((item) => (
              <RowWrapper alignItems="center" key={item.id}>
                <ShortcutWrapper>{item.shortcut}</ShortcutWrapper>
                <LabelWrapper>{item.label}</LabelWrapper>
              </RowWrapper>
            ))}
          </FlexColumn>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Tooltip
          content={<T>ResourceReservationsCalendar:Shortcuts.tooltip</T>}
          delay={300}
          placement="top"
          trigger={(triggerProps) => (
            <InfoIconWrapper ref={ref} onClick={() => setModalOpen(true)}>
              <span {...triggerProps}>
                <InfoIcon icon={['far', 'circle-question']} />
              </span>
            </InfoIconWrapper>
          )}
        />
      )}
      styleOverrides={{ top: `${theme.spacing.gu(1)}rem` }}
    />
  )
}

////////

const InfoIconWrapper = styled.div`
  cursor: pointer;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterBig};
  `}
`

const InfoIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.smoke.dark};
    font-size: ${theme.typography.fontSizeBigger};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
    `}
  }
`
const KeyboardButton = styled.kbd`
  border-radius: 4px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.guPx(1) - 5}px ${theme.spacing.gu(1)}rem;
    box-shadow: 0 1px 0px ${theme.palette.smoke.dark},
      0 0 0 2px ${theme.palette.white} inset;
    background-color: ${theme.palette.smoke.lighter};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const LabelWrapper = styled.span`
  text-align: start;
  width: 50%;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `}
`

const RowWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const ShortcutWrapper = styled.span`
  text-align: end;
  width: 50%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gutter};
  `}
`

const Title = styled(Label)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
