import { Row, Worksheet } from 'exceljs'
import { Decimal } from 'decimal.js'
import moment from 'moment'

import { formatDateRangeWithoutRepetition } from '@/utils/time'
import { InvoiceCostingQuery } from '~generated-types'
import { Palette } from '@/theme'

import { extendCells, hexToArgb } from './utils'
import { ProductItem, SchemaItem } from './types'

type Input = {
  isMarginalTax: boolean
  items: InvoiceCostingQuery['invoice']['items']
  palette: Palette
  sheet: Worksheet
  translateFn: (keys: string | Array<string>) => string
}

export const addProducts = ({
  isMarginalTax,
  items,
  palette,
  sheet,
  translateFn,
}: Input) => {
  const marginalTaxColumnPosition = 15
  const upperHeaderColumnPosition = 8

  if (isMarginalTax) {
    addMarginalTaxHeader({
      columnPosition: marginalTaxColumnPosition,
      palette,
      sheet,
      translateFn,
    })
  }

  const upperHeader = addUpperHeader({
    columnPosition: upperHeaderColumnPosition,
    isMarginalTax,
    palette,
    sheet,
    translateFn,
  })

  const marginalTaxSchemaPart: Array<SchemaItem> = marginalTaxSchema(
    isMarginalTax,
    translateFn
  )
  const schema = productSchema(marginalTaxSchemaPart, translateFn)

  const headerRow = schema.map(({ column }) => column)

  const tableRows = items.flatMap((item) =>
    item.products.map((line) =>
      schema.map((it) =>
        it.value({
          item: line,
          product: item,
        })
      )
    )
  )

  const header = sheet.addRow(headerRow)
  formatHeaders({ header, isMarginalTax, palette, sheet, upperHeader })

  sheet.addRows(tableRows)

  const start = header.number // starting integer
  const end = start + tableRows.length // ending integer

  for (let i = start; i <= end; i++) {
    sheet.mergeCells(`A${i}:B${i}`)
  }

  sheet.mergeCells(`D${start}:E${start}`)
  sheet.mergeCells(`F${start}:G${start}`)

  // Format VAT rate
  extendCells(6, start + 1, 6, end).map((cellLocation) => {
    const cell = sheet.getCell(cellLocation)
    cell.value = ((cell.value || 0) as number) / 100
    cell.numFmt = '0.00 %'
  })

  extendCells(
    upperHeaderColumnPosition,
    start + 1,
    marginalTaxColumnPosition + 3,
    end
  ).map((cellLocation) => {
    const cell = sheet.getCell(cellLocation)
    cell.numFmt = '0.00 €'
  })
}

const formatTimes = (data: ProductItem): string => {
  return (
    (data.product.dates?.startTime &&
      data.product.dates.endTime &&
      ` ${formatTime(data.product.dates.startTime)} - ${formatTime(
        data.product.dates.endTime
      )}`) ||
    ''
  )
}

const formatTime = (time: string): string => {
  return moment(time, 'HH:mm:ss').format('HH:mm')
}

const productSchema = (
  marginalTaxSchema: Array<SchemaItem>,
  translateFn: (keys: string | Array<string>) => string
): Array<SchemaItem> => {
  return [
    {
      column: translateFn('Excel.productFields.product'),
      value: (data: ProductItem) => data.item.name,
    },
    {
      column: '',
      value: () => '',
    },
    {
      column: translateFn('Excel.productFields.description'),
      value: (data: ProductItem) => {
        return (
          (data.product.dates?.startDate &&
            `${data.product.quantity} x ${formatDateRangeWithoutRepetition(
              data.product.dates?.startDate,
              data.product.dates?.endDate,
              'short'
            )}${(data.product.dates.startTime && formatTimes(data)) || ''}`) ||
          ''
        )
      },
    },
    {
      column: translateFn('Excel.productFields.quantity'),
      value: (data: ProductItem) => data.item.quantity,
    },
    {
      column: '',
      value: () => 'kpl',
    },
    // VAT
    {
      column: translateFn('Excel.productFields.vatRate'),
      value: (data: ProductItem) =>
        data.item.marginalTax?.active
          ? data.item.marginalTax?.marginalTaxRate
          : data.item.unitPrice.vat.vatRate,
    },
    {
      column: '',
      value: (data: ProductItem) =>
        data.item.marginalTax?.active
          ? translateFn('Excel.productFields.marginal')
          : '',
    },
    // VAT excluded prices
    {
      column: translateFn('Excel.productFields.unitPrice'),
      value: (data: ProductItem) => data.item.unitPrice.priceVatExcluded,
    },
    {
      column: translateFn('Excel.productFields.totalPrice'),
      value: (data: ProductItem) => data.item.totalPrice.amount,
    },
    // VAT amounts
    {
      column: translateFn('Excel.productFields.unitPrice'),
      value: (data: ProductItem) =>
        Number(
          new Decimal(data.item.unitPrice.priceVatIncluded)
            .minus(new Decimal(data.item.unitPrice.priceVatExcluded))
            .toFixed(2)
        ),
    },
    {
      column: translateFn('Excel.productFields.totalPrice'),
      value: (data: ProductItem) => data.item.totalPrice.vatAmount,
    },
    // VAT included prices
    {
      column: translateFn('Excel.productFields.unitPrice'),
      value: (data: ProductItem) => data.item.unitPrice.priceVatIncluded,
    },
    {
      column: translateFn('Excel.productFields.totalPrice'),
      value: (data: ProductItem) => data.item.totalPrice.amountVatIncluded,
    },
    {
      column: '',
      value: () => '',
    },
    ...marginalTaxSchema,
  ]
}

const marginalTaxSchema = (
  isMarginalTax: boolean,
  translateFn: (keys: string | Array<string>) => string
): Array<SchemaItem> => {
  const totalPurchase = (data: ProductItem) =>
    new Decimal(data.item.internalPurchase || 0).mul(
      new Decimal(data.item.quantity)
    )

  return isMarginalTax
    ? [
        // Purchase
        {
          column: translateFn('Excel.productFields.unitPrice'),
          value: (data: ProductItem) =>
            data.item.marginalTax?.active
              ? data.item.internalPurchase || ''
              : '',
        },
        {
          column: translateFn('Excel.productFields.totalPrice'),
          value: (data: ProductItem) =>
            data.item.marginalTax?.active
              ? Number(totalPurchase(data).toFixed(2))
              : '',
        },
        // Profit
        {
          column: translateFn('Excel.productFields.vatExcluded'),
          value: (data: ProductItem) =>
            data.item.marginalTax?.active
              ? Number(
                  new Decimal(data.item.totalPrice.amount)
                    .minus(totalPurchase(data))
                    .toFixed(2)
                )
              : '',
        },
        {
          column: translateFn('Excel.productFields.vatIncluded'),
          value: (data: ProductItem) =>
            data.item.marginalTax?.active
              ? Number(
                  new Decimal(data.item.totalPrice.amountVatIncluded)
                    .minus(totalPurchase(data))
                    .toFixed(2)
                )
              : '',
        },
      ]
    : []
}

type AddMarginalTaxHeaderInput = {
  columnPosition: number
  palette: Palette
  sheet: Worksheet
  translateFn: (keys: string | Array<string>) => string
}

const addMarginalTaxHeader = ({
  columnPosition,
  palette,
  sheet,
  translateFn,
}: AddMarginalTaxHeaderInput) => {
  const marginalHeader = sheet.addRow([])
  const marginalCell = sheet.getCell(marginalHeader.number, columnPosition)

  marginalCell.value = translateFn('Excel.productFields.marginalTax')

  marginalCell.fill = {
    fgColor: {
      argb: hexToArgb(palette.primary.extraDark),
    },
    pattern: 'solid',
    type: 'pattern',
  }

  marginalCell.font = {
    bold: true,
    color: {
      argb: hexToArgb(palette.text.white),
    },
    size: 12,
  }

  marginalCell.alignment = {
    horizontal: 'center',
  }

  sheet.mergeCells(
    marginalHeader.number,
    columnPosition,
    marginalHeader.number,
    columnPosition + 3
  )
}

type AddUpperHeaderInput = {
  columnPosition: number
  isMarginalTax: boolean
  palette: Palette
  sheet: Worksheet
  translateFn: (keys: string | Array<string>) => string
}

const addUpperHeader = ({
  columnPosition,
  isMarginalTax,
  palette,
  sheet,
  translateFn,
}: AddUpperHeaderInput) => {
  const header = [
    translateFn('Excel.productFields.vatExcluded'),
    '',
    translateFn('Excel.productFields.vat'),
    '',
    translateFn('Excel.productFields.vatIncluded'),
    '',
    '',
    isMarginalTax ? translateFn('Excel.productFields.purchase') : '',
    '',
    isMarginalTax ? translateFn('Excel.productFields.profit') : '',
  ]
  const headerRow = sheet.addRow(
    new Array(columnPosition - 1).fill('').concat(header)
  )

  headerRow.alignment = {
    horizontal: 'center',
  }

  headerRow.font = {
    bold: true,
    color: {
      argb: hexToArgb(palette.text.dark),
    },
    size: 12,
  }

  sheet.mergeCells(
    headerRow.number,
    columnPosition,
    headerRow.number,
    columnPosition + 1
  )
  sheet.mergeCells(
    headerRow.number,
    columnPosition + 2,
    headerRow.number,
    columnPosition + 3
  )
  sheet.mergeCells(
    headerRow.number,
    columnPosition + 4,
    headerRow.number,
    columnPosition + 5
  )

  if (isMarginalTax) {
    sheet.mergeCells(
      headerRow.number,
      columnPosition + 7,
      headerRow.number,
      columnPosition + 8
    )
    sheet.mergeCells(
      headerRow.number,
      columnPosition + 9,
      headerRow.number,
      columnPosition + 10
    )
  }
  return headerRow
}

type FormatHeadersInput = {
  header: Row
  isMarginalTax: boolean
  palette: Palette
  sheet: Worksheet
  upperHeader: Row
}

const formatHeaders = ({
  header,
  isMarginalTax,
  palette,
  sheet,
  upperHeader,
}: FormatHeadersInput) => {
  header.font = {
    bold: true,
    color: {
      argb: hexToArgb(palette.text.dark),
    },
    size: 12,
  }

  const lightMarginalHeaderCells = isMarginalTax
    ? [`O${upperHeader.number}`, `O${header.number}`, `P${header.number}`]
    : []

  const lightHeaderCells = [
    `C${header.number}`,
    `F${header.number}`,
    `J${header.number}`,
    `J${upperHeader.number}`,
    `K${header.number}`,
    ...lightMarginalHeaderCells,
  ]

  lightHeaderCells.map(
    (cell) =>
      (sheet.getCell(cell).fill = {
        fgColor: {
          argb: hexToArgb(palette.primary.light),
        },
        pattern: 'solid',
        type: 'pattern',
      })
  )

  const extraLightMarginalHeaderCells = isMarginalTax
    ? [`Q${upperHeader.number}`, `Q${header.number}`, `R${header.number}`]
    : []

  const extraLightHeaderCells = [
    `A${header.number}`,
    `D${header.number}`,
    `H${header.number}`,
    `H${upperHeader.number}`,
    `I${header.number}`,
    `L${upperHeader.number}`,
    `L${header.number}`,
    `M${header.number}`,
    ...extraLightMarginalHeaderCells,
  ]

  extraLightHeaderCells.map(
    (cell) =>
      (sheet.getCell(cell).fill = {
        fgColor: {
          argb: hexToArgb(palette.primary.extraLight),
        },
        pattern: 'solid',
        type: 'pattern',
      })
  )
}
