import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ArrivalsParticipant } from '../useArrivalsForDate'
import { CheckInOutTimes } from '../../common'
import { parseParticipantName } from '../../utils'
import { typeGuardFactory } from '@/utils/types'

type Props = {
  participants: ArrivalsParticipant[]
  totalBeds: number
}

export const EventsTableBedChart = ({ participants, totalBeds }: Props) => {
  const { spacing } = useTheme()

  const sortedParticipants = [...participants].sort(
    generateCompareFn(['lname', 'fname'])
  )

  const bedParticipants = [
    ...Array(totalBeds > participants.length ? totalBeds : participants.length),
  ].map((x, idx) => {
    return sortedParticipants[idx]
  })

  return (
    <Wrapper>
      {bedParticipants.map((participant, idx) => {
        const dates = participant?.services
          .filter(typeGuardFactory('__typename', 'ServiceParticipantBed'))
          .find((service) => service.dates?.checkIn)

        return !!participant ? (
          <BedInUse
            key={`participant-bed-${
              participant.services.length
                ? participant.services[0].id
                : participant.id
            }`}
          >
            <FontAwesomeIcon
              icon={['far', 'user']}
              size="1x"
              style={{ marginRight: `${spacing.gu(1)}rem` }}
            />

            <span style={{ maxWidth: '20mm', wordBreak: 'break-word' }}>
              {parseParticipantName(participant) || (
                <em>
                  <T>Listings:ArrivalsAndDeparturesListing.unnamed.GUEST</T>
                </em>
              )}
            </span>

            {dates?.dates?.checkIn && (
              <DatesWrapper>
                <CheckInOutTimes
                  checkIn={dates.dates.checkIn}
                  checkOut={dates.dates.checkOut}
                />
              </DatesWrapper>
            )}
          </BedInUse>
        ) : (
          <BedVacant key={`vacant-bed-${idx}`}>
            <T>Listings:ArrivalsAndDeparturesListing.vacantBed</T>
          </BedVacant>
        )
      })}
    </Wrapper>
  )
}

////////////

const BedInUse = styled.div`
  ${({ theme }) => css`
    border: solid 1px ${theme.palette.text.light};
    color: ${theme.palette.text.main};
    margin-right: ${theme.spacing.gutterSmall};
    margin-bottom: ${theme.spacing.gutterSmall};
    padding: ${theme.spacing.gutter};
    width: calc(33% - ${theme.spacing.gutterSmall});
  `}

  @media print {
    margin-right: 1pt;
    margin-bottom: 1pt;
    padding: 2pt;
    width: calc(33% - 1pt);
  }
`

const BedVacant = styled(BedInUse)`
  font-style: italic;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(9.02)}rem;
    border: dashed 1px ${theme.palette.text.lighter};
    color: ${theme.palette.text.lighter};
  `}
`

const Wrapper = styled(FlexRow).attrs(() => ({
  wrap: 'wrap',
}))`
  ${({ theme }) => css`
    margin-bottom: -${theme.spacing.gutterSmall};
  `}

  @media print {
    margin-bottom: -1pt;
  }
`

const DatesWrapper = styled.small`
  display: block;
  margin-top: ${({ theme }) => `${theme.spacing.gu(1)}rem`};
`
