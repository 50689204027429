import { gql, useMutation } from '@apollo/client'

import { MealFragment } from '../fragments'

import type {
  RestoreMealMutation,
  RestoreMealMutationVariables,
} from '~generated-types'

const RESTORE_MEAL_MUTATION = gql`
  ${MealFragment}

  mutation RestoreMeal($id: ID!) {
    scheduledMealRestore(id: $id) {
      ...Meal
    }
  }
`

export const useRestoreMealMutation = () =>
  useMutation<RestoreMealMutation, RestoreMealMutationVariables>(
    RESTORE_MEAL_MUTATION
  )
