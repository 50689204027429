import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import { useState } from 'react'

import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout/Gutter'
import { InnocuousButton } from '@/components/ExtraButtons'
import { InvoiceState } from '~generated-types'
import { orderServices } from '@/modules/Order'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

enum AcceptType {
  Accept = 'ACCEPT',
  AcceptAndPrint = 'ACCEPT_AND_PRINT',
}

type Props = {
  closeModal: () => void
  handlePrint: (forceRefresh?: boolean) => Promise<void>
  invoiceId: string
  orderId: string
  state: InvoiceState
}

export const Actions = ({
  closeModal,
  handlePrint,
  invoiceId,
  orderId,
  state,
}: Props) => {
  const { palette } = useTheme()
  const { acceptInvoice } = orderServices.invoiceService()

  const [printBusy, setPrintBusy] = useState<boolean>(false)
  const [processingTarget, setProcessingTarget] = useState<AcceptType | null>(
    null
  )
  const [showPrintButton, setShowPrintButton] = useState<boolean>(false)

  const handleAcceptInvoice = (acceptType: AcceptType) => {
    setProcessingTarget(acceptType)

    acceptInvoice(invoiceId, orderId)
      .then(() => {
        acceptType === AcceptType.AcceptAndPrint
          ? printDocument(true)
          : closeModal()
        setShowPrintButton(acceptType === AcceptType.AcceptAndPrint)
      })
      .finally(() => setProcessingTarget(null))
  }

  const printDocument = (forceRefresh?: boolean) => {
    setPrintBusy(true)
    handlePrint(forceRefresh).finally(() => setPrintBusy(false))
  }

  return (
    <>
      <Gutter type="gutterBig">
        {state === InvoiceState.Accepted && showPrintButton ? (
          <Button disabled={printBusy} onClick={() => printDocument()}>
            <PrimaryColor>
              <Icon icon="print" />
              <T>Orders:Payments.actions.print</T>
            </PrimaryColor>
          </Button>
        ) : (
          <FlexRow alignItems="center">
            <Button
              disabled={processingTarget === AcceptType.AcceptAndPrint}
              noMargin
              onClick={() => handleAcceptInvoice(AcceptType.AcceptAndPrint)}
              style={{ flex: 3 }}
            >
              {processingTarget === AcceptType.AcceptAndPrint ? (
                <ReactLoading
                  type="spin"
                  color={palette.primary.main}
                  height={24}
                  width={24}
                />
              ) : (
                <PrimaryColor>
                  <Icon icon="check" />
                  <T>Orders:Payments.actions.acceptAndPrint</T>
                </PrimaryColor>
              )}
            </Button>

            <Spacer />

            <Button
              disabled={processingTarget === AcceptType.Accept}
              noMargin
              onClick={() => handleAcceptInvoice(AcceptType.Accept)}
              style={{ flex: 2 }}
            >
              {processingTarget === AcceptType.Accept ? (
                <ReactLoading
                  type="spin"
                  color={palette.primary.main}
                  height={24}
                  width={24}
                />
              ) : (
                <PrimaryColor>
                  <Icon icon="check" />
                  <T>Orders:Payments.actions.accept</T>
                </PrimaryColor>
              )}
            </Button>
          </FlexRow>
        )}
      </Gutter>
    </>
  )
}

///////

const Button = styled(InnocuousButton)`
  flex: 1;
  width: 100%;

  &:last-child {
    margin: 0;
  }
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Spacer = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
  `}
`
