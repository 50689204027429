import { gql } from '@apollo/client'

import { catalogFragments } from '@/modules/Registry'

import catalogProductItem from './catalogProductItem'
import productCategory from './productCategory'

export default gql`
  ${catalogFragments.catalog}
  ${catalogProductItem}
  ${productCategory}

  fragment CatalogProduct on CatalogProduct {
    catalog {
      ...Catalog
    }
    categories {
      ...ProductCategory
    }
    id
    items {
      ...CatalogProductItem
    }
    name
    notes
  }
`
