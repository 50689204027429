import { gql, useMutation } from '@apollo/client'

import {
  ConfirmSaleMutation,
  ConfirmSaleMutationVariables,
} from '~generated-types'

export type { ConfirmSaleMutation, ConfirmSaleMutationVariables }

export const CONFIRM_SALE_MUTATION = gql`
  mutation ConfirmSale($input: SalesConfirmInput!) {
    salesConfirm(input: $input) {
      id
      locked
      lifecycle {
        state
      }
    }
  }
`

export function useConfirmSaleMutation() {
  return useMutation<ConfirmSaleMutation, ConfirmSaleMutationVariables>(
    CONFIRM_SALE_MUTATION
  )
}

export default useConfirmSaleMutation
