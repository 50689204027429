import { RefObject, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import {
  InlineModalDescription,
  InlineModalIconSection,
} from '@/components/InlineModal'
import {
  OverlappingError,
  Reservation,
  ResourceReservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { DatePickerControl } from '@/components/TimeControls'
import { T } from '@/modules/Language'
import { useDialogService } from '@/components/DialogService'
import { useTheme } from '@/theme'

import { EditButton } from './common'

type Props = {
  end: string
  isEditable: boolean
  readOnly: boolean
  reservationId: string
  resourceId: string | null
  start: string
  updateReservations: (reservations: Reservation[]) => void
}

export const Date = ({
  end,
  isEditable,
  readOnly,
  reservationId,
  resourceId,
  start,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { confirm, warn } = useDialogService()
  const { moveReservation } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isHover, setHover] = useState<boolean>(false)

  const handleUpdateDate = (newDate: string) => {
    const doMoveReservation = (allowOverbooking: boolean) =>
      moveReservation(
        allowOverbooking,
        reservationId,
        `${newDate}${start.substring(10)}`,
        `${newDate}${end.substring(10)}`,
        resourceId
      )

    return doMoveReservation(false)
      .then((data: void | ResourceReservation | OverlappingError | null) => {
        const errorData = data as OverlappingError

        if (errorData.message) {
          if (errorData.type === 'WARNING') {
            return renderConfirmDialog()
              .then(() => doMoveReservation(true))
              .catch(() => undefined)
          }
          if (errorData.type === 'ERROR') {
            return renderWarningDialog().catch(() => undefined)
          }
        }
      })
      .catch(() => undefined)
  }

  const renderConfirmDialog = () =>
    confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: <T>common:action.continue</T>,
      description: (
        <T>ResourceReservationsCalendar:overlapWarning.description</T>
      ),
      title: <T>ResourceReservationsCalendar:overlapWarning.title</T>,
    })

  const renderWarningDialog = () =>
    warn({
      cancelLabel: <T>common:action.cancel</T>,
      description: <T>ResourceReservationsCalendar:overlapError.description</T>,
      title: <T>ResourceReservationsCalendar:overlapError.title</T>,
    })

  return (
    <InlineModalIconSection icon={['far', 'calendar']}>
      {isEditable ? (
        <DatePickerControl
          placement="bottom-start"
          render={({ date, ref, toggleModal }) => (
            <EditButton
              disabled={readOnly}
              onClick={toggleModal}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              ref={ref as RefObject<HTMLButtonElement>}
            >
              {date && date.format('dddd, MMMM DD, YYYY')}

              {isHover && (
                <FontAwesomeIcon
                  color={palette.text.lighter}
                  icon="pen"
                  size="sm"
                />
              )}
            </EditButton>
          )}
          setValue={(date) =>
            date && handleUpdateDate(date.format('YYYY-MM-DD'))
          }
          value={moment(start)}
        />
      ) : (
        <InlineModalDescription style={{ padding: `${spacing.gu(1)}rem 0` }}>
          {moment(start).format('dddd, MMMM DD, YYYY')}{' '}
        </InlineModalDescription>
      )}
    </InlineModalIconSection>
  )
}
