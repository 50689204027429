import type { ThemeOptions } from '../types'

import mapStrapiBrandingConfigToBrandingOptions from './mapStrapiBrandingConfigToBrandingOptions'
import mapStrapiPaletteConfigToPaletteOptions from './mapStrapiPaletteConfigToPaletteOptions'
import mapStrapiSpacingConfigToSpacingOptions from './mapStrapiSpacingConfigToSpacingOptions'
import mapStrapiTypographyConfigToTypographyOptions from './mapStrapiTypographyConfigToTypographyOptions'

export default function mapStrapiThemeConfigToThemeOptions(
  input: Record<string, unknown>
): ThemeOptions {
  const options: ThemeOptions = {}

  const branding = input.branding
    ? mapStrapiBrandingConfigToBrandingOptions(
        input.branding as Record<string, unknown>
      )
    : undefined

  const palette = input.palette
    ? mapStrapiPaletteConfigToPaletteOptions(
        input.palette as Record<string, unknown>
      )
    : undefined

  const spacing = input.spacing
    ? mapStrapiSpacingConfigToSpacingOptions(
        input.spacing as Record<string, unknown>
      )
    : undefined

  const typography = input.typography
    ? mapStrapiTypographyConfigToTypographyOptions(
        input.typography as Record<string, unknown>
      )
    : undefined

  if (branding) options.branding = branding
  if (palette) options.palette = palette
  if (spacing) options.spacing = spacing
  if (typography) options.typography = typography

  return options
}
