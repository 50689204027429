import styled, { css } from 'styled-components'
import Collapse from '@kunukn/react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'

import { FlexRow } from '@/components/Layout'

type Props = {
  children?: ReactNode
  expand?: (arg?: string[]) => void
  isExpandable?: boolean
  isExpanded?: boolean
  isNested?: boolean
  visibleContent: ReactNode
}

export const InnerSection = ({
  children,
  expand,
  isExpandable,
  isExpanded,
  isNested,
  visibleContent,
}: Props) => (
  <>
    <Wrapper
      flex={1}
      isNested={isNested}
      onClick={isExpandable && expand ? () => expand() : undefined}
    >
      <Content>{visibleContent}</Content>

      {isExpandable &&
        (children ? (
          <ChevronIcon icon="chevron-up" rotation={isExpanded ? 180 : 90} />
        ) : (
          <ChevronIconPlaceholder />
        ))}
    </Wrapper>

    <CollapseWrapper isOpen={isExpanded}>{children || ''}</CollapseWrapper>
  </>
)

/////

const ChevronIcon = styled(FontAwesomeIcon)`
  transition: 0.25s;
  width: 12px;
  height: 12px;
  margin-top: 1px;
  margin-left: 5px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `};
`

const ChevronIconPlaceholder = styled.span`
  width: 12px;
  margin-left: 5px;
`

const Content = styled.div`
  width: 100%;
`

const CollapseWrapper = styled(Collapse)`
  transition: height 0.25s;
  overflow: visible;
`

const Wrapper = styled(FlexRow)<{ isNested?: boolean }>`
  ${({ isNested }) =>
    isNested &&
    css`
      margin-left: 20px;
    `}
`
