import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { translate, useLanguageContext } from '@/modules/Language'
import { InlineModal } from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

type Props = {
  onUpdateWeeksAmount: (weeksAmount: number) => void
  weeksAmount: number
}

export const WeeksAmountSelector = ({
  onUpdateWeeksAmount,
  weeksAmount,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [isWeeksModalOpen, setWeeksModalOpen] = useState<boolean>(false)

  const weekOptions = Array(6)
    .fill(null)
    .map((_, idx) => ({
      label: `${translate('Accommodation:AvailabilityCalendar.week', language, {
        count: idx + 1,
      })}`,
      value: idx + 1,
    }))

  return (
    <ModalContainer
      isOpen={isWeeksModalOpen}
      modal={
        <InlineModal style={{ padding: `${theme.spacing.gu(1)}rem` }}>
          {weekOptions.map(({ label, value }) => (
            <WeekSeletorButton
              compact
              key={value}
              noMargin
              noNudge
              onClick={() => {
                setWeeksModalOpen(false)
                onUpdateWeeksAmount(value)
              }}
            >
              {label}
            </WeekSeletorButton>
          ))}
        </InlineModal>
      }
      onClose={() => setWeeksModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <WeekButton
          ref={ref as Ref<HTMLButtonElement>}
          onClick={() => setWeeksModalOpen(true)}
        >
          {translate('Accommodation:AvailabilityCalendar.week', language, {
            count: weeksAmount,
          })}
        </WeekButton>
      )}
    />
  )
}

////////////

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  padding: 0;
  outline: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
    height: ${theme.spacing.gu(5)}rem;
    width: ${theme.spacing.gu(30)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const WeekButton = styled(Button)`
  font-weight: 500;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(14)}rem;
    padding: 0 ${theme.spacing.gutter};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const WeekSeletorButton = styled(InnocuousButton)`
  font-weight: 500;
  text-transform: lowercase;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}
`
