import styled, { css } from 'styled-components/macro'

import type {
  ProductPurchaseDuration,
  ProductPurchaseTask,
} from '@/modules/Products/types'
import { FlexRow } from '@/components/Layout'
import { QuantityUnit } from '~generated-types'
import { UserGroup } from '@/modules/Registry'

import { DatesSelector } from '../DatesSelector'
import { QuantityInput } from '../QuantityInput'

type Props = {
  duration: ProductPurchaseDuration
  id: string
  quantity: number
  quantityUnit: QuantityUnit
  readOnly: boolean
  task: ProductPurchaseTask | null
}

export const TaskSection = ({
  duration,
  id,
  quantity,
  quantityUnit,
  readOnly,
  task,
}: Props) => {
  const renderTaskName = () => {
    if (task) {
      return <Name>{(task.assignee as UserGroup)?.name}</Name>
    }

    return null
  }

  return (
    <FlexRow flex={1}>
      {renderTaskName()}

      <Spacer />

      <QuantityInput id={id} quantity={quantity} readOnly={readOnly} />

      {quantityUnit !== QuantityUnit.Piece && (
        <DatesSelector
          id={id}
          duration={duration}
          quantityUnit={quantityUnit}
          readOnly={readOnly}
        />
      )}
    </FlexRow>
  )
}

/////////

const Name = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(14)}rem;
  `}
`

const Spacer = styled.div`
  flex: 1;
`
