import styled from 'styled-components/macro'

import { CheckboxInput } from '@/components/FormControls'
import { DocumentUserAttribute } from '@/modules/Order/types'
import { FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'

import { Section, SubTitle } from '../common'
import { AttributeRichEditor } from './AttributeRichEditor'

type Props = {
  attributes: DocumentUserAttribute[]
  onToggle: (attribute: DocumentUserAttribute) => void
  onUpdate: (attribute: DocumentUserAttribute) => void
}

export const Attributes = ({ attributes, onToggle, onUpdate }: Props) => (
  <Section>
    <SubTitle>
      <T>Orders:Documents.documentEditor.attributes</T>
    </SubTitle>

    {[...attributes]
      .sort(generateCompareFn('key'))
      .map((attribute: DocumentUserAttribute) => (
        <div key={attribute.key}>
          <CheckboxWrapper alignItems="center">
            <CheckboxInput
              checked={attribute.active}
              noMargin
              onChange={() => onToggle(attribute)}
            >
              <CheckboxLabel>{attribute.name}</CheckboxLabel>
            </CheckboxInput>
          </CheckboxWrapper>

          {attribute.active && (
            <AttributeRichEditor
              editorValue={attribute.editorValue}
              updateAttribute={(editorValue, templateValue) =>
                onUpdate({ ...attribute, editorValue, templateValue })
              }
            />
          )}
        </div>
      ))}
  </Section>
)

///////

const CheckboxLabel = styled(FlexRow)`
  ${({ theme }) => `
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`

const CheckboxWrapper = styled(FlexRow)`
  label {
    ${({ theme }) => `
      color: ${theme.palette.text.light};
      height: ${theme.spacing.guPx(4) + 4}px;
    `}

    &:hover {
      ${({ theme }) => `
        color: ${theme.palette.primary.dark};
      `}
    }
  }
`
