import { Moment } from 'moment'

import { CalendarResourceElastic } from '@/modules/Registry'
import { CalendarViewMode } from '@/modules/Reservations'

import { getGridGroupForWeek } from './getGridGroupForWeek'

type Props = {
  resource: CalendarResourceElastic
  targetDate: Moment
  viewMode: CalendarViewMode
}

export const getGridGroupForResourceByWeek = ({ resource, ...rest }: Props) => {
  if (!resource.nestedResources?.length) {
    return getGridGroupForWeek({ resource, ...rest })
  }

  const nestedGroups = resource.nestedResources.map((r) =>
    getGridGroupForWeek({
      parentResourceId: resource.id,
      resource: r,
      ...rest,
    })
  )

  return [
    getGridGroupForWeek({
      nestedResourcesIds: resource.nestedResources.map(({ id }) => id),
      resource,
      ...rest,
    }),
    ...nestedGroups,
  ]
}
