import styled, { css } from 'styled-components/macro'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { OrderState as OS, SalesType as ST } from '~generated-types'
import {
  ParticipantSaleSelector,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import type {
  DataRows,
  FetchedParticipant,
  ParticipantSkeletonData,
  Participant as ParticipantType,
  Rows,
} from './ParticipantsList.types'
import {
  ParticipantServices,
  ParticipantServicesLoading,
} from './components/ParticipantServices'
import { getDataRowColor } from './components/ListHeading/DataRowsSwitcher'
import { MainInfoPlaceholder } from './components/Placeholders'
import { ParticipantPersonalInfo } from './components'
import { ParticipantRow } from './components'
import { ParticipantVST } from './components/ParticipantVST'

type Props = {
  participant: FetchedParticipant | ParticipantSkeletonData
  isVisible: boolean
  notRemovable: boolean
  readOnly?: boolean
  dataRows: DataRows
  selectedIds: string[]
  isSelectable: boolean
  handleSelect?: (select: boolean, selectId: string) => void
}

export const Participant = ({
  participant: participantProps,
  isVisible,
  notRemovable,
  readOnly,
  dataRows,
  selectedIds,
  isSelectable,
  handleSelect,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const {
    salesId,
    saleType,
    handleMoveParticipant,
    handleAddRoom,
    handleAddSalesProduct,
    handleDeleteService,
    handleServiceSetDates,
    handleSetCheckIn,
    handleSetCheckOut,
    handleSetServiceTarget,
    handleRemovePurchase,
    handleRemoveRoom,
    handleSetVisitStatus,
    handleAddCatalogProduct,
  } = useParticipantsListContext()

  const [creatingNew, setCreatingNew] = useState<'BED' | 'PRODUCT' | null>(null)

  const participant = participantProps as FetchedParticipant
  const isEventParticipant =
    saleType === ST.Event && participant.sales.id === salesId
  const isDayVisitor = participant?.visitStatus?.isDayVisitor

  if (!isVisible) {
    return (
      <MainInfoPlaceholder
        firstName={participant.firstName}
        lastName={participant.lastName}
      />
    )
  } else if (!participant.services) {
    return <MainInfoPlaceholder />
  }

  const servicePlaceholderRemoveOptions: {
    key: string
    disabled: boolean
    icon: IconProp
    label: string
    onClick: () => Promise<any> | void
  }[] =
    creatingNew === 'BED'
      ? [
          {
            disabled: true,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantRooms.removeReservation',
              language
            ),
            onClick: () => null,
          },
          {
            disabled: true,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantProducts.removeProduct',
              language
            ),
            onClick: () => null,
          },
          {
            disabled: !participant?.services.length,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantRooms.removeService',
              language
            ),
            onClick: () => setCreatingNew(null),
          },
        ]
      : creatingNew === 'PRODUCT'
      ? [
          {
            disabled: true,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantProducts.removeProduct',
              language
            ),
            onClick: () => null,
          },
          {
            disabled: !participant?.services.length,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantRooms.removeService',
              language
            ),
            onClick: () => setCreatingNew(null),
          },
        ]
      : []

  const serviceRemoveOptions: {
    key: string
    disabled: boolean
    icon: IconProp
    label: string
    onClick: () => Promise<any> | void
  }[][] = participant?.services?.length
    ? (participant.services as ParticipantType['services']).map((service) =>
        service.__typename === 'ServiceParticipantBed'
          ? [
              {
                disabled:
                  service.__typename === 'ServiceParticipantBed' &&
                  !service.participantRoom,
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantRooms.removeReservation',
                  language
                ),
                onClick: () =>
                  handleRemoveRoom(
                    (service.__typename === 'ServiceParticipantBed' &&
                      service.participantRoom?.id) ||
                      ''
                  ),
              },
              {
                disabled:
                  (service.__typename === 'ServiceParticipantBed' &&
                    !service.purchaseProduct) ||
                  (service.purchaseProduct?.order?.lifecycle.state !==
                    OS.Open &&
                    service.purchaseProduct?.order?.lifecycle.state !==
                      OS.Correction),
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantProducts.removeProduct',
                  language
                ),
                onClick: () =>
                  handleRemovePurchase(
                    (service.__typename === 'ServiceParticipantBed' &&
                      service.purchaseProduct?.id) ||
                      '',
                    participant.id
                  ),
              },
              {
                disabled:
                  !participant.services.length ||
                  (!!service.purchaseProduct?.order?.lifecycle.state &&
                    service.purchaseProduct?.order?.lifecycle.state !==
                      OS.Open &&
                    service.purchaseProduct?.order?.lifecycle.state !==
                      OS.Correction),
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantRooms.removeService',
                  language
                ),
                onClick: () =>
                  service.id === ''
                    ? setCreatingNew(null)
                    : handleDeleteService(service.id || ''),
              },
            ]
          : service.__typename === 'ServicePurchase'
          ? [
              {
                disabled:
                  service.purchaseProduct?.order?.lifecycle.state !== OS.Open &&
                  service.purchaseProduct?.order?.lifecycle.state !==
                    OS.Correction,
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantProducts.removeProduct',
                  language
                ),
                onClick: () =>
                  handleRemovePurchase(
                    service.purchaseProduct?.id ?? '',
                    participant.id
                  ),
              },
            ]
          : []
      )
    : []

  const rows = {
    PERSONAL: (
      <ParticipantPersonalInfo participant={participant} readOnly={readOnly} />
    ),
    SERVICES: (
      <FlexColumn noPadding flex={1}>
        {saleType === ST.Event && isEventParticipant ? (
          <ParticipantSaleSelector
            participantSalesId={participant?.sales.id || participant.sales.id}
            readOnly={readOnly}
            handleSelectSale={(newSale) =>
              handleMoveParticipant(
                participant.id,
                newSale,
                participant.sales.id
              )
            }
          />
        ) : (participant as ParticipantType).services[0]?.purchaseProduct !==
            undefined || participant.services.length === 0 ? (
          <ParticipantServices
            participant={participant as ParticipantType}
            isEventParticipant={isEventParticipant}
            services={participant.services as ParticipantType['services']}
            allowAccommodation={!participant.visitStatus.isDayVisitor}
            isDayVisitor={isDayVisitor}
            actionsDisabled={!!readOnly}
            creatingNew={creatingNew}
            saleType={saleType}
            readOnly={readOnly}
            setCreatingNew={setCreatingNew}
            handleAddRoom={({ roomReservationId, serviceId }) =>
              handleAddRoom({
                id: participant.id,
                roomReservationId,
                serviceId,
              })
            }
            handleDeleteService={handleDeleteService}
            handleServiceSetDates={handleServiceSetDates}
            handleSetCheckIn={(roomReservationId, providedTime) =>
              handleSetCheckIn(roomReservationId, providedTime)
            }
            handleSetCheckOut={(roomReservationId, providedTime) =>
              handleSetCheckOut(roomReservationId, providedTime)
            }
            handleSetParticipantTarget={({ targetId, serviceId }) =>
              handleSetServiceTarget({
                id: participant.id,
                serviceId,
                targetId,
              })
            }
            handleRemoveRoom={(roomId) => handleRemoveRoom(roomId)}
            handleSetVisitStatus={(dayVisitorDates, isDayVisitor) =>
              handleSetVisitStatus({
                dayVisitorDates,
                id: participant.id,
                isDayVisitor,
              })
            }
            handleAddCatalogProduct={handleAddCatalogProduct}
            handleAddSalesProduct={handleAddSalesProduct}
            handleRemovePurchase={handleRemovePurchase}
          />
        ) : (
          <ParticipantServicesLoading
            services={participant.services}
            isDayVisitor={participant.visitStatus.isDayVisitor}
          />
        )}
      </FlexColumn>
    ),
    VST: <ParticipantVST participant={participant} readOnly={!!readOnly} />,
  }

  return (
    <ParticipantRow
      participantBasicData={participant as FetchedParticipant}
      extraRemoveOptions={
        dataRows['SERVICES']
          ? [...serviceRemoveOptions, servicePlaceholderRemoveOptions]
          : []
      }
      isVSTEnabled={dataRows['VST']}
      readOnly={readOnly}
      notRemovable={notRemovable}
      participantContextComponent={
        <FlexRow flex={1}>
          <FlexColumn flex={1}>
            {Object.keys(dataRows)
              .filter((row) => dataRows[row as Rows])
              .map((dataRow, index) => (
                <FlexColumn key={dataRow} flex={1}>
                  {index !== 0 && <RowsDivider />}
                  <FlexRow>
                    <ColoredLine
                      color={getDataRowColor(dataRow as Rows, theme)}
                    />
                    {rows[dataRow as Rows]}
                  </FlexRow>
                </FlexColumn>
              ))}
          </FlexColumn>
        </FlexRow>
      }
      isSelectable={!readOnly && isSelectable}
      isSelected={!!selectedIds.find((id) => id === participant.id)}
      hideAdditional
      select={(select) => handleSelect && handleSelect(select, participant.id)}
    />
  )
}

export default Participant

const RowsDivider = styled.div`
  width: 100%;
  border-radius: 2px;
  height: 0px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`

const ColoredLine = styled.div<{ color?: string }>`
  height: 100%;
  width: 4px;

  ${({ theme, color }) => css`
    background-color: ${color || theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
