import { Route, Switch, useRouteMatch } from 'react-router-dom'
import React from 'react'

import { Gutter } from '@/components/Layout'

import { BuildingList } from './components/BuildingList'

export const BuildingRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route component={WrappedBuildingList} exact path={path} />

      {/* TODO: Create / edit –views */}
    </Switch>
  )
}

export default BuildingRoutes

////////////

const WrappedBuildingList = () => (
  <Gutter type={[0, 5, 10]}>
    <BuildingList />
  </Gutter>
)
