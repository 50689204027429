import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'
import styled, { css } from 'styled-components/macro'

import { NumberInput } from './NumberInput'

type Props = HOCProps & {
  targetUnit: string
  handleUpdate?: (newValue: string | null | undefined) => Promise<any>
  withBorder?: boolean
  placeholder?: string
}

export const NumberInputWithLogic = ({
  value,
  targetUnit,
  onChange,
  handleUpdate,
  placeholder,
}: Props) => {
  return (
    <Wrapper>
      <NumberInput
        value={value}
        targetUnit={targetUnit}
        handleUpdate={handleUpdate}
        onChange={onChange}
        placeholder={placeholder}
      />
    </Wrapper>
  )
}

export const FormatNumberInput = withPriceInputLogic(NumberInputWithLogic)

const Wrapper = styled.div`
  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.main};
  `}
`
