import { MouseEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import {
  InvoiceAction as IA,
  InvoicePaymentType as IPT,
  PaymentStatus as PS,
} from '~generated-types'
import { Invoice, InvoiceInfo } from '@/modules/Order/types'
import { Palette, useTheme } from '@/theme'
import { Action } from '@/modules/Order/components'
import { FlexRow } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { orderServices } from '@/modules/Order'
import { T } from '@/modules/Language'

import {
  ChevronIcon,
  HeaderLabel,
  HeaderRow,
  PriceLabel,
  Spacer,
} from '../../common'

type Props = {
  isOpen: boolean
  isLastNested?: boolean
  isLoading: boolean
  isNested?: boolean
  invoice: Invoice | InvoiceInfo
  openPaymentsModal: () => void
  orderId: string
  toggleContent: () => void
}

export const Header = ({
  isOpen,
  isLastNested,
  isLoading,
  isNested,
  invoice: {
    auditLog: { invoiceAccepted },
    dueDate,
    dueDateDuration,
    id,
    invoiceNumber,
    lifecycle: { validatedActions: actions },
    type,
    paymentInfo: {
      paid: paidAmount,
      payableAmount,
      status: paymentStatus,
      totalPrice: { amountVatIncluded: totalAmount },
    },
    paymentType,
    refundDetails,
  },
  openPaymentsModal,
  orderId,
  toggleContent,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { setPaymentType } = orderServices.invoiceService()

  const [isHover, setHover] = useState<boolean>(false)

  const addPaymentAction = actions.find((a) => a.action === IA.AddManualPayment)
  const createRefundAction = actions.find((a) => a.action === IA.CreateRefund)

  const isPaymentExpired = dueDate
    ? moment(dueDate).isBefore(moment(), 'day')
    : dueDateDuration
    ? moment().add(dueDateDuration, 'd').isBefore(moment(), 'day')
    : false

  const handleAddPayment = (e: MouseEvent) => {
    isOpen && e.stopPropagation()
    openPaymentsModal()

    if (!createRefundAction && paymentType !== IPT.Cash) {
      setPaymentType(id, orderId, IPT.Cash)
    }
  }

  return (
    <HeaderRow
      alignItems="center"
      onClick={toggleContent}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ChevronIcon
        icon="chevron-right"
        hover={isHover ? 1 : undefined}
        loading={isLoading ? 1 : undefined}
        open={isOpen}
      />

      <HeaderLabel width={`${spacing.gu(26)}rem`} style={{ height: '100%' }}>
        <FlexRow alignItems="center" flex={1} style={{ height: '100%' }}>
          {isNested && <LineLeader fullHeight={!isLastNested} />}
          <T>
            {refundDetails
              ? `Orders:Header.title.${type}-${refundDetails.action}`
              : `Orders:Header.title.${type}`}
          </T>
        </FlexRow>
      </HeaderLabel>

      <HeaderLabel width={`${spacing.gu(20)}rem`}>
        {invoiceNumber ??
          (addPaymentAction && (
            <PayAction>
              <Action
                onClick={handleAddPayment}
                phase={type}
                validatedAction={addPaymentAction}
              />
            </PayAction>
          ))}
      </HeaderLabel>

      <HeaderLabel width={`${spacing.gu(24)}rem`}>
        {invoiceAccepted ? (
          moment(invoiceAccepted).format('dd, D.M.YYYY, HH:mm')
        ) : (
          <LightLabel>
            <T>Orders:State.DRAFT</T>
          </LightLabel>
        )}
      </HeaderLabel>

      <HeaderLabel
        width={`${spacing.gu(20)}rem`}
        color={getPaymentStatusColor(paymentStatus, isPaymentExpired, palette)}
      >
        {totalAmount !== 0 ? (
          <T>{`Orders:PaymentStatus.${paymentStatus}`}</T>
        ) : (
          <LightLabel>–</LightLabel>
        )}
      </HeaderLabel>

      <Spacer />

      <PriceLabel
        width={`${spacing.gu(18)}rem`}
        color={payableAmount === 0 ? palette.text.light : palette.danger.dark}
      >
        {formatCurrency(payableAmount)} €
      </PriceLabel>

      <PriceLabel
        width={`${spacing.gu(18)}rem`}
        color={paidAmount === 0 ? palette.text.light : palette.success.dark}
      >
        {formatCurrency(paidAmount)} €
      </PriceLabel>

      <PriceLabel width={`${spacing.gu(18)}rem`}>
        {formatCurrency(totalAmount)} €
      </PriceLabel>

      <HeaderLabel width={`${spacing.gu(6.5)}rem`} />
    </HeaderRow>
  )
}

//////

const getPaymentStatusColor = (
  paymentStatus: PS,
  isPaymentExpired: boolean,
  palette: Palette
) => {
  switch (paymentStatus) {
    case 'PAID':
      return palette.success.dark
    case 'OVERPAID':
    case 'UNPAID':
      return isPaymentExpired ? palette.danger.dark : palette.primary.dark
    case 'PARTIALLY_PAID':
    default:
      return palette.primary.dark
  }
}

/////

const LightLabel = styled.span`
  font-weight: 400;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const PayAction = styled.div`
  ${({ theme }) => css`
    margin-left: calc(-${theme.spacing.gu(1)}rem - 4px);
  `}
`

type LineLeaderProps = {
  fullHeight?: boolean
}

const LineLeader = ({ fullHeight }: LineLeaderProps) => (
  <LineLeaderWrapper fullHeight={fullHeight}>
    <LineLeaderDot />
  </LineLeaderWrapper>
)

const LineLeaderDot = styled.div`
  display: inline-block;
  position: absolute;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  right: -3px;
  top: calc(50% - 2px);

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
  `}
`

const LineLeaderWrapper = styled.div<LineLeaderProps>`
  align-self: stretch;
  margin-left: 1px;
  margin-right: 10px;
  position: relative;
  width: 18px;

  &::after {
    content: ' ';
    position: absolute;
    height: ${({ fullHeight }) => (fullHeight ? '100%' : '50%')};
    width: 2px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &::before {
    content: ' ';
    position: absolute;
    top: 50%;
    height: 2px;
    width: calc(100%);

    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`
