import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import {
  MomentDateRange,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { translate, useLanguageContext } from '@/modules/Language'
import { ActionDropdown } from '@/components/ActionDropdown'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

import { ControlRowButton, FilterWrapper } from './'
import { Week } from '../../../types'

type Option = {
  label: string
  onClick: () => Promise<any> | void
}

interface ControlRowProps {
  openPrintableVersion: (week: Week) => void
}

export const PrintButton = ({ openPrintableVersion }: ControlRowProps) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const { targetCalendarDateRange } = useRoomLayoutContext()

  const weeks = getWeekRange(targetCalendarDateRange)

  const weekOptions: Option[] = weeks.map(({ isoWeek, isoWeekYear }) => ({
    label: `Week: ${isoWeek}, ${isoWeekYear}`,
    onClick: () => openPrintableVersion({ isoWeek, isoWeekYear }),
  }))

  return (
    <FilterWrapper>
      <Tooltip
        content={translate('RoomLayout:action.openPrintableVersion', language)}
        delay={300}
        placement="bottom"
        trigger={(triggerProps) => (
          <ActionDropdown
            items={weekOptions}
            renderToggle={({ toggleDropdown }) => (
              <ControlRowButton {...triggerProps} onClick={toggleDropdown}>
                <FontAwesomeIcon
                  icon="print"
                  size="sm"
                  style={{ margin: `0 ${theme.spacing.gu(1)}rem` }}
                />
              </ControlRowButton>
            )}
          />
        )}
      />
    </FilterWrapper>
  )
}

////////////

interface IsoWeek {
  isoWeek: number
  isoWeekYear: number
}

const getWeekRange = (input: MomentDateRange): IsoWeek[] => {
  const range: IsoWeek[] = []
  const reference = moment(input.from)

  while (reference.isBefore(input.to)) {
    range.push({
      isoWeek: reference.isoWeek(),
      isoWeekYear: reference.isoWeekYear(),
    })
    reference.add(1, 'week')
  }

  return range
}
