import { useEffect, useState } from 'react'
import { HTML5_FMT } from 'moment'

import {
  ElasticRoomReservation,
  MomentDateRange,
  RoomLayoutService,
} from '@/modules/Reservations/components/RoomLayout'
import { FetchState, FetchStates } from '@/common/types'

interface UseRoomLayoutReservationsProps {
  accommodationLevelIds: string[]
  buildingIds: string[]
  dateRange: MomentDateRange
  updateTicker: number
}

interface UseRoomLayoutReservationsHook {
  onUpdate: (updated: ElasticRoomReservation) => void
  reservations: ElasticRoomReservation[]
  status: FetchState
}

export default function useRoomLayoutReservations({
  accommodationLevelIds,
  buildingIds,
  dateRange,
  updateTicker,
}: UseRoomLayoutReservationsProps): UseRoomLayoutReservationsHook {
  const [reservations, setReservations] = useState<ElasticRoomReservation[]>([])
  const [status, setStatus] = useState<FetchState>(FetchStates.LOADING)

  /**
   * Calculate primitive representations for all input props to know when to
   * actually run the update. We can't guarantee that the array and object
   * references are the same across renders and it is not up to this component.
   */
  //
  const accommodationLevelIdsHash = accommodationLevelIds.sort().join(';')
  const buildingIdsHash = buildingIds.sort().join(';')
  const dateRangeHash = `${dateRange.from.format(
    HTML5_FMT.DATE
  )}-${dateRange.to.format(HTML5_FMT.DATE)}`

  useEffect(() => {
    setStatus(FetchStates.LOADING)

    RoomLayoutService.fetchRoomReservations(
      accommodationLevelIds,
      buildingIds,
      dateRange
    ).then(({ data, ok }) => {
      if (ok) {
        setReservations(data)
        setStatus(data.length ? FetchStates.IDLE : FetchStates.EMPTY)
      } else {
        setStatus(FetchStates.ERROR)
      }
    })
    // Exhaustive deps intentionally disabled here. We cannot use the input prop
    // values here as they are not primitives.
    //
  }, [accommodationLevelIdsHash, buildingIdsHash, dateRangeHash, updateTicker])

  const onUpdate = (updated: ElasticRoomReservation) =>
    setReservations((current) =>
      current.map((x) => (x.id === updated.id ? updated : x))
    )

  return {
    onUpdate,
    reservations,
    status,
  }
}
