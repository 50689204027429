import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { FlexRow, Gutter } from '@/components/Layout'
import { P } from '@/components/Typography'
import { T } from '@/modules/Language'

import { CatalogProductPickerButton } from './CatalogProductPickerButton'
import { CatalogProductSearch } from './CatalogProductSearch'
import { ListViewModeToggle } from './ListViewModeToggle'

type Props = {
  isEmpty: boolean
}

export const ListControls = ({ isEmpty }: Props) => (
  <FlexRow flex={1}>
    {isEmpty ? (
      <Placeholder>
        – <T>Products:SalesProductList.noProducts</T> –
      </Placeholder>
    ) : (
      <ListViewModeToggle />
    )}

    <span style={{ flex: 1 }} />

    <CatalogProductSearch />
    <CatalogProductPickerButton />
  </FlexRow>
)

/////

type PlaceholderProps = {
  children: ReactNode
}

export const Placeholder = ({ children }: PlaceholderProps) => (
  <Gutter type={[1, 3]}>
    <Label>{children}</Label>
  </Gutter>
)

const Label = styled(P)`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`
