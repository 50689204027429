import styled, { css } from 'styled-components/macro'
import { PaymentStatus } from '~generated-types'
import { PropsWithChildren } from 'react'

import { Theme, useTheme } from '@/theme'
import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { formatCurrency } from '@/utils/currency'
import { T } from '@/modules/Language'

type PriceTotal = {
  amountVatIncluded: number
  vatAmount: number
}

interface Props<PriceTotalType> {
  priceTotal: PriceTotalType
  paymentStatus?: PaymentStatus
}

const ParticipantTotalPrice = <PriceTotalType extends PriceTotal>({
  priceTotal,
  paymentStatus,
}: PropsWithChildren<Props<PriceTotalType>>) => {
  const theme = useTheme()

  return (
    <FlexColumn
      alignItems="flex-end"
      justifyContent={'center'}
      noPadding
      style={{
        margin: `0 ${theme.spacing.gu(2)}rem 0 ${theme.spacing.gu(1)}rem`,
        minWidth: '6.5rem',
      }}
    >
      <FontWeight
        bold
        style={{
          color: getPriceColor(paymentStatus || PaymentStatus.Unpaid, theme),
          fontSize: '14px',
          whiteSpace: 'nowrap',
        }}
      >{`${formatCurrency(priceTotal.amountVatIncluded)} €`}</FontWeight>
      <VATAmount>
        <T>common:pricing.vatAmount</T> {formatCurrency(priceTotal.vatAmount)} €
      </VATAmount>
    </FlexColumn>
  )
}

export default ParticipantTotalPrice

const getPriceColor = (paymentStatus: PaymentStatus, theme: Theme) => {
  switch (paymentStatus) {
    case 'OVERPAID':
    case 'PARTIALLY_PAID':
      return `${theme.palette.danger.dark}`
    case 'PAID':
      return `${theme.palette.success.dark}`
    case 'UNPAID':
    default:
      return `${theme.palette.text.light}`
  }
}

const VATAmount = styled.small`
  font-size: 11px;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(0.5)}rem;
    color: ${theme.palette.text.lighter};
  `}
`
