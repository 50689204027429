import styled from 'styled-components/macro'

import {
  InlineModal,
  InlineModalContent,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { Theme, useTheme } from '@/theme'
import { GroupManager } from '@/modules/Reservations/components/ReservationCalendar'
import { Reservation } from '@/modules/Reservations/ResourceReservation'
import { T } from '@/modules/Language'

import { Context, ResourceReservationGroup } from '../../types'
import { GroupManagerModalFooter } from './GroupManagerModalFooter'

type Props = {
  context: Context
  group: ResourceReservationGroup
  onClose: () => void
  readOnly: boolean
  updateReservations: (reservations: Reservation[]) => void
}

export const GroupManagerModal = ({
  context,
  group,
  onClose,
  readOnly,
  updateReservations,
}: Props) => {
  const theme = useTheme()

  return (
    <Modal context={context}>
      <InlineModalHeader
        onClose={onClose}
        title={<T>ReservationModal:title.groupManager</T>}
        style={{ paddingTop: `${theme.spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      <InlineModalContent>
        <GroupManager
          currentReservationId={null}
          group={group}
          readOnly={readOnly}
          updateReservations={updateReservations}
        />
      </InlineModalContent>

      <GroupManagerModalFooter
        groupId={group.id}
        onCloseModal={onClose}
        readOnly={readOnly}
        updateReservations={updateReservations}
      />
    </Modal>
  )
}

/////////

const getModalTopOffset = (context: Context, theme: Theme) => {
  switch (context) {
    case 'SALES':
      return `${theme.spacing.guPx(19) + 3}px`
    case 'BOTTOM_SHEET':
      return `${theme.spacing.guPx(17) + 3}px`
    case 'GLOBAL':
      return `${theme.spacing.guPx(9) + 3}px`
    default:
      return 0
  }
}

const Modal = styled(InlineModal)<{ context: Context }>`
  height: ${({ context, theme }) =>
    `calc(100vh - ${getModalTopOffset(context, theme)} - 10px)`};
  max-height: 100vh;
  width: 500px;

  margin-top: ${({ context, theme }) => getModalTopOffset(context, theme)};
  margin-bottom: 10px;
  margin-right: 10px;
`
