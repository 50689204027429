import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ReactLoading from 'react-loading'
import { useState } from 'react'

import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { DangerColor } from '@/components/Colors'
import { DataField } from '@/components/DataField'
import { DisplayOption } from '~generated-types'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useDialogService } from '@/components/DialogService'
import { useTheme } from '@/theme'

import { IconButton, LoaderWrapper, Placeholder } from '../common'

export type FieldType = {
  displayOption: DisplayOption
  icon: IconProp
  isShown: boolean
  key: string
  value: string
}

type Props = {
  field: FieldType
  onRemoveField: (fieldKey: string) => void
  readOnly: boolean
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
}

export const Field = ({
  field: { displayOption, icon, key, value },
  onRemoveField,
  readOnly,
  reservationId,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { confirm } = useDialogService()

  const [processing, setProcessing] = useState<boolean>(false)

  const { setDisplayMessage } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const handleRemoveField = (field: string) => {
    setProcessing(true)

    const onDelete = () => {
      setDisplayMessage(reservationId, null).then(() => onRemoveField(field))
    }

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>ReservationModal:removalConfirmation.field</T>,
    })
      .then(onDelete)
      .catch(() => setProcessing(false))
  }

  return (
    <>
      <InlineModalLine />

      <InlineModalIconSection icon={icon}>
        <FlexRow alignItems="center">
          <DataFieldWrapper>
            <DataField
              disabled={readOnly}
              handleSubmit={(value: string | null) =>
                setDisplayMessage(reservationId, value)
              }
              inputType="text"
              maxLength={35}
              placeholder={
                <Placeholder>
                  <T>{`ReservationModal:fields.${key}.placeholder`}</T>
                </Placeholder>
              }
              style={{
                height: '30px',
                marginLeft: `-${spacing.gu(1)}rem`,
                width: `calc(100% + ${spacing.gu(1)}rem)`,
              }}
              value={value}
              valueColor={palette.text.light}
              valueStyle={{ height: '30px', padding: `${spacing.gu(1)}rem` }}
              variant="primary"
            />
          </DataFieldWrapper>

          {displayOption !== DisplayOption.ShowAlways && !readOnly && (
            <RemoveButtonWrapper>
              {processing ? (
                <LoaderWrapper>
                  <ReactLoading
                    color={palette.smoke.dark}
                    height={18}
                    type="spin"
                    width={18}
                  />
                </LoaderWrapper>
              ) : (
                <IconButton onClick={() => handleRemoveField(key)}>
                  <FontAwesomeIcon
                    color={palette.danger.main}
                    icon="trash"
                    size="sm"
                  />
                </IconButton>
              )}
            </RemoveButtonWrapper>
          )}
        </FlexRow>
      </InlineModalIconSection>
    </>
  )
}

///////////

// Tiny visual improvement
const DataFieldWrapper = styled.div`
  width: 100%;

  input {
    &:not([type='checkbox']):not([type='radio']) {
      height: 30px;
    }
  }
`

const RemoveButtonWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
