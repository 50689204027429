import moment, { Moment } from 'moment'
import React from 'react'

import BasePicker, { BasePickerProps } from './components/BasePicker'

type Mode = 'DATE' | 'WEEK'

type Props = BasePickerProps & {
  mode: Mode
  setValue: (target: Moment | null | undefined) => void
  value: Moment | null | undefined
}

const DateWeekComboPicker = ({
  mode,
  onDayClick,
  onWeekClick,
  selectedDays: propsSelectedDays,
  setValue,
  value,
  ...dayPickerProps
}: Props) => (
  <BasePicker
    initialMonth={value?.toDate()}
    modifiers={getModifiers(value, mode)}
    onDayClick={(day) => setValue(day ? moment(day) : null)}
    onWeekClick={(weekNumber, days) =>
      mode === 'WEEK' && setValue(days[0] ? moment(days[0]) : null)
    }
    // @ts-ignore
    selectedDays={getSelectedDays(value, mode)}
    {...dayPickerProps}
  />
)

export default DateWeekComboPicker

////////////

const getWeekForDate = (targetDate: Moment): { from: Date; to: Date } => ({
  from: moment(targetDate).startOf('isoWeek').toDate(),
  to: moment(targetDate).endOf('isoWeek').toDate(),
})

const getModifiers = (
  targetDate: Moment | null | undefined,
  mode: Mode
): {
  [key: string]: any
} => {
  if (mode === 'WEEK' && targetDate) {
    const { from, to } = getWeekForDate(targetDate)

    return { between: { after: from, before: to }, from, to }
  }

  return {}
}

const getSelectedDays = (
  targetDate: Moment | null | undefined,
  mode: Mode
):
  | (Date | null | undefined)
  | ({ from: Date; to: Date } | null | undefined) => {
  switch (mode) {
    case 'DATE':
      return targetDate?.toDate() ?? null
    case 'WEEK':
      return !!targetDate ? getWeekForDate(targetDate) : null
    default:
      return null
  }
}
