import moment, { Moment } from 'moment'

import { ListingLayout, PlaceholderLabel } from '@/modules/Listing/common'
import { DatePicker } from '@/components/TimeControls'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { RadioInput } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ReservationType, RoomReservation } from './types'
import { PrintSize } from '../types'
import RoomReservationsTable from './components/RoomReservationsTable'

interface Props {
  arriving: RoomReservation[]
  date: Moment
  departing: RoomReservation[]
  error: boolean
  loading: boolean
  setDate: (targetDate: Moment) => void
  setType: (targetType: ReservationType) => void
  type: ReservationType
}

export const RoomReservationsListing = ({
  arriving,
  date,
  departing,
  error,
  loading,
  setDate,
  setType,
  type,
}: Props) => {
  const theme = useTheme()

  const renderContent = (printSize: PrintSize) => {
    if (loading) {
      return <LoadingPlaceholder flex={1} size="xl" />
    }

    if (error) {
      return (
        <PlaceholderLabel>
          <T>Listings:RoomReservationsListing.error</T>
        </PlaceholderLabel>
      )
    }

    const tableData = type === ReservationType.ARRIVING ? arriving : departing

    if (!tableData.length) {
      return (
        <PlaceholderLabel>
          <T>Listings:RoomReservationsListing.empty</T>
        </PlaceholderLabel>
      )
    }

    return (
      <RoomReservationsTable
        data={tableData}
        printSize={printSize}
        type={type}
      />
    )
  }

  const renderSidebar = () => (
    <>
      <FilterSection
        label={<T>Listings:RoomReservationsListing.reservationType.label</T>}
        render={() => (
          <>
            <RadioInput
              checked={type === ReservationType.ARRIVING}
              onChange={() => setType(ReservationType.ARRIVING)}
            >
              <T>Listings:RoomReservationsListing.reservationType.ARRIVING</T>
            </RadioInput>
            <RadioInput
              checked={type === ReservationType.DEPARTING}
              onChange={() => setType(ReservationType.DEPARTING)}
            >
              <T>Listings:RoomReservationsListing.reservationType.DEPARTING</T>
            </RadioInput>
          </>
        )}
      />
      <FilterSection
        label={<T>Listings:RoomReservationsListing.filter.date</T>}
        render={() => (
          <div style={{ margin: `-${theme.spacing.gu(1)}rem` }}>
            <DatePicker
              setValue={(targetDate) => setDate(targetDate || moment())}
              value={date}
            />
          </div>
        )}
      />
    </>
  )

  return (
    <ListingLayout
      renderContent={renderContent}
      renderSidebar={renderSidebar}
      title={
        <>
          <T>Listings:RoomReservationsListing.title</T>
          {' – '}
          {type === ReservationType.ARRIVING ? (
            <T>Listings:RoomReservationsListing.reservationType.ARRIVING</T>
          ) : (
            <T>Listings:RoomReservationsListing.reservationType.DEPARTING</T>
          )}{' '}
          <T
            l10n="Listings:RoomReservationsListing.forDate"
            target={date.format('l')}
          />
        </>
      }
    />
  )
}

export default RoomReservationsListing
