import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { OrderPhaseType as OPT, RefundAction as RA } from '~generated-types'
import { orderContexts, orderServices } from '@/modules/Order'
import { ActionButton } from '@/modules/Order/components'
import { FlexColumn } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'
import { T } from '@/modules/Language'

type Props = {
  invoiceId: string
  onClose: () => void
  orderId: string
  type: OPT
}

export const RefundActionsModal = ({
  invoiceId,
  onClose,
  orderId,
  type,
}: Props) => {
  const { createRefund } = orderServices.invoiceService()
  const { setSelectedPhaseId } = orderContexts.useOrderView()

  const [processingTarget, setProcessingTarget] = useState<RA | null>(null)

  const actions = [RA.Cancel, RA.ReInvoice, RA.CorrectInvoice]

  const handleCreateRefund = (action: RA) => {
    setProcessingTarget(action)
    createRefund(invoiceId, orderId, action).then((data) => {
      setProcessingTarget(null)

      if (data) {
        setSelectedPhaseId(data.id)
        onClose()
      }
    })
  }

  return (
    <Modal>
      {actions.map((a) => (
        <Button
          action={a}
          isProcessing={processingTarget === a}
          key={`refund-action-${a}`}
          label={
            <FlexColumn alignItems="flex-start">
              <T>{`Orders:Action.REFUND.${type}.${a}`}</T>
              <Description>
                <T>{`Orders:Action.REFUND_DESCRIPTION.${type}.${a}`}</T>
              </Description>
            </FlexColumn>
          }
          onClick={() => handleCreateRefund(a)}
        />
      ))}
    </Modal>
  )
}

////////////

const Button = styled(ActionButton)`
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`

const Description = styled.span`
  font-weight: 400;
  margin-top: 4px;
  text-transform: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Modal = styled(InlineModal)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
    padding-bottom: ${theme.spacing.gutterSmall};
  `}
`
