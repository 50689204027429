import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import EndAdornmentWrapper from '@/components/Spreadsheet/elements/EndAdornmentWrapper'
import { FlexRow } from '@/components/Layout'
import { Input } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  value: number
  availableAmount: number
  readOnly?: boolean
  textColor: string | null
  icon: IconProp
  onUpdate: (value: number) => Promise<void>
}

export const BedsInput = ({
  value,
  availableAmount,
  readOnly,
  textColor,
  icon,
  onUpdate,
}: Props) => {
  const theme = useTheme()

  const [valueState, setValue] = useState(value)

  useEffect(() => {
    value !== valueState && setValue(value)
  }, [value])

  return (
    <FlexRow style={{ height: '100%', position: 'relative' }}>
      <IconWrapper>
        <FontAwesomeIcon
          icon={icon}
          size="sm"
          color={theme.palette.text.light}
        />
      </IconWrapper>

      <StyledInput
        disabled={readOnly}
        min={0}
        onBlur={() => value !== valueState && onUpdate(valueState)}
        onChange={(e) => setValue(Number(e.target.value))}
        onFocus={(e) => e.currentTarget.select()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onUpdate(valueState)
          }
        }}
        type="number"
        value={`${valueState}`}
        textColor={textColor}
      />

      <AvailableWrapper>{` / ${availableAmount}`}</AvailableWrapper>
    </FlexRow>
  )
}

/////

const IconWrapper = styled(EndAdornmentWrapper)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    left: 0;
  `}
`

const AvailableWrapper = styled(EndAdornmentWrapper)`
  ${({ theme }) => css`
    line-height: 1px;
    width: ${theme.spacing.gu(5)}rem;
    color: ${theme.palette.text.light};
  `}
`

const StyledInput = styled(Input)<{
  textColor: string | null
}>`
  &&& {
    background: transparent;
    border: 0;
    border-radius: 0;
    height: 100%;
    font-weight: 500;
    padding: 0;
    text-align: end;

    ${({ theme, textColor }) => css`
      width: ${theme.spacing.gu(12)}rem;

      padding-right: calc(${theme.spacing.gu(5)}rem + 1px);
      padding-left: ${theme.spacing.gu(4)}rem;

      font-size: ${theme.typography.fontSizeBase};
      color: ${textColor || theme.palette.primary.dark};
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
