import styled, { css } from 'styled-components/macro'

import { Building, RoomReservation } from '@/modules/FrontDesk'
import { ById } from '@/common/types'
import { FlexColumn } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { useLanguageContext } from '@/modules/Language'

import { Header, Row } from './components'

type RoomReservationsByBuilding = ById<{
  building: Building
  reservations: RoomReservation[]
}>

type Props = {
  reservations: RoomReservation[]
}

export const RoomReservations = ({ reservations }: Props) => {
  const { language } = useLanguageContext()

  const reservationsByBuilding = reservations.reduce(
    (acc: RoomReservationsByBuilding, val: RoomReservation) => {
      const building = val.room.building

      acc[building.id] = acc[building.id] || {
        building,
        reservations: [],
      }
      acc[building.id].reservations.push(val)

      return acc
    },
    {}
  )

  const sortRoomsByNumber = (a: RoomReservation, b: RoomReservation) =>
    a.room.number.localeCompare(b.room.number, language, { numeric: true })

  return (
    <>
      {Object.values(reservationsByBuilding)
        .sort(generateCompareFn('building.name'))
        .map(({ building, reservations }, idx, arr) => (
          <Wrapper isLast={idx === arr.length - 1} key={building.id} noPadding>
            <Header building={building} />

            {reservations.sort(sortRoomsByNumber).map((r, idx) => (
              <Row
                isLast={idx === reservations.length - 1}
                key={r.room.id}
                reservation={r}
              />
            ))}
          </Wrapper>
        ))}
    </>
  )
}

////////////

const Wrapper = styled(FlexColumn)<{ isLast: boolean }>`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(4)}rem;
  `}

  ${({ isLast, theme }) =>
    !isLast &&
    css`
      margin-bottom: calc(${theme.spacing.gu(4)}rem + 10px);
    `};
`
