import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModalContent, InlineModalLine } from '@/components/InlineModal'
import { Label, Toggle } from '@/components/FormControls'
import { CatalogProductPicker } from '@/modules/Products'
import { IconButton } from '@/modules/Products/components/common'
import { SalesProduct } from '@/modules/Products/types'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

import {
  PricingFooter,
  PricingHeader,
  ProductItemSection,
  ProductSection,
} from './components'

type Props = {
  product: SalesProduct
}

export const PricingSection = ({ product }: Props) => {
  const { addProductItem, commission, deleteProductItem, readOnly } =
    useSalesProductManagerContext()

  const [isAdding, setAdding] = useState<boolean>(false)
  const [showCosting, setShowCosting] = useState<boolean>(false)

  useEffect(() => {
    const withCommission = commission !== null && commission > 0
    const withMarginalTax = product.items.some((i) => !!i.marginalTax)

    if (withCommission || withMarginalTax) {
      setShowCosting(true)
    }
  }, [commission, product.items])

  const handleAddProductItem = (id: string) =>
    addProductItem(product.id, id).then((data) => data?.map(({ id }) => id))

  const handleDeleteProductItem = (ids: string[]) =>
    Promise.all(ids.map(async (id) => await deleteProductItem(id))).then(
      () => undefined
    )

  return (
    <>
      <FlexColumn flex={3}>
        <PricingHeader />

        <InlineModalLine style={{ margin: 0 }} />

        <InlineModalContent style={{ padding: 0 }}>
          <ProductSection product={product} />

          <InlineModalLine style={{ margin: 0 }} />

          <TitleWrapper alignItems="center" justifyContent="space-between">
            <Title>
              <T>Products:ProductManager.pricing.productLines</T>
            </Title>

            <ToggleWrapper flex={1} justifyContent="flex-end">
              <ToggleLabel>
                <T>Products:ProductManager.action.showCosting</T>
              </ToggleLabel>

              <Toggle
                checked={showCosting}
                hideLabels
                noMargin
                onChange={() => setShowCosting(!showCosting)}
              />
            </ToggleWrapper>

            {!readOnly && (
              <Button onClick={() => setAdding(true)}>
                <PlusIcon icon="plus" size="sm" />
                <T>Products:ProductManager.action.add</T>
              </Button>
            )}
          </TitleWrapper>

          {product.items.map((i, idx) => (
            <ProductItemSection
              key={`product-item-${i.id}`}
              orderNumber={idx + 1}
              productItem={i}
              showCosting={showCosting}
              setShowCosting={setShowCosting}
            />
          ))}
        </InlineModalContent>

        <PricingFooter productId={product.id} />
      </FlexColumn>

      {isAdding && (
        <CatalogProductPicker
          isMulti
          onClose={() => setAdding(false)}
          onItemSelect={handleAddProductItem}
          onItemUnselect={handleDeleteProductItem}
          sellerId={product.catalog.company.id}
        />
      )}
    </>
  )
}

/////////

const Button = styled(IconButton)`
  width: fit-content;
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    letter-spacing: ${theme.spacing.gu(0.05)}rem;
  `};
`

const PlusIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(0.75)}rem;
  `}
`

const ToggleLabel = styled(Label)`
  font-weight: 500;
  margin-bottom: 0;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const ToggleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const TitleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(7)}rem;
    padding-left: ${theme.spacing.gu(4)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
  `}
`
