import { gql, useMutation } from '@apollo/client'

import {
  ReOpenSaleMutation,
  ReOpenSaleMutationVariables,
} from '~generated-types'

export type { ReOpenSaleMutation, ReOpenSaleMutationVariables }

export const REOPEN_SALE_MUTATION = gql`
  mutation ReOpenSale($input: SalesReOpenInput!) {
    salesReOpen(input: $input) {
      id
      locked
      lifecycle {
        state
      }
    }
  }
`

export function useReOpenSaleMutation() {
  return useMutation<ReOpenSaleMutation, ReOpenSaleMutationVariables>(
    REOPEN_SALE_MUTATION
  )
}

export default useReOpenSaleMutation
