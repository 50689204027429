import styled, { css } from 'styled-components/macro'

import { TreeRow, TreeRowLabel } from '@/components/TreeSelector'
import {
  ReservationType as Type,
  useRoomReservationsContext,
} from '@/modules/FrontDesk'
import { CheckboxInput } from '@/components/FormControls'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { T } from '@/modules/Language'

const PATH = 'FrontDesk:DailyRoomReservations'

export const ReservationTypeFilter = () => {
  const { reservationTypes, setReservationTypes } = useRoomReservationsContext()

  const options = [Type.Arrivinng, Type.Departing]

  const toggleValue = (type: Type) => {
    if (reservationTypes.includes(type)) {
      const hideAll =
        type !== Type.Pending &&
        reservationTypes.includes(Type.Pending) &&
        reservationTypes.length === 2

      setReservationTypes(
        hideAll ? [] : reservationTypes.filter((x) => x !== type)
      )
    } else {
      const showAll = type === Type.Pending && !reservationTypes.length

      setReservationTypes(
        showAll
          ? [Type.Arrivinng, Type.Departing, Type.Pending]
          : [...reservationTypes, type]
      )
    }
  }

  return (
    <FilterSection
      label={<T l10n={`${PATH}.reservationType.title`} />}
      render={() => (
        <SelectorWrapper>
          {options.map((o) => (
            <CheckboxWrapper key={`reservation-type-option-${o}`}>
              <CheckboxInput
                checked={reservationTypes.includes(o)}
                noMargin
                onChange={() => toggleValue(o)}
              />
              <TreeRowLabel>
                <T l10n={`${PATH}.reservationType.${o}`} />
              </TreeRowLabel>
            </CheckboxWrapper>
          ))}

          <Separator />

          <CheckboxWrapper>
            <CheckboxInput
              checked={reservationTypes.includes(Type.Pending)}
              noMargin
              onChange={() => toggleValue(Type.Pending)}
            />
            <TreeRowLabel>
              <T l10n={`${PATH}.reservationType.PENDING`} />
            </TreeRowLabel>
          </CheckboxWrapper>
        </SelectorWrapper>
      )}
    />
  )
}

////////////

const CheckboxWrapper = styled(TreeRow)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem 0;
  `}

  &:first-child {
    ${({ theme }) => css`
      margin-top: ${theme.spacing.gu(1.5)}rem;
    `}
  }

  &:last-child {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gu(1.5)}rem;
    `}
  }
`

const Separator = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1.5)}rem 0;
    border-top: dashed 1px ${theme.palette.smoke.dark};
  `}
`

const SelectorWrapper = styled.div`
  border-radius: 4px;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
  `}
`
