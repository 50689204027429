import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { FlexRow } from '@/components/Layout'
import { useAuthContext } from '@/modules/Auth'
import { useTheme } from '@/theme'

import { IconButton, MenuSidebar, UserSidebar } from './components'
import { AppBarLogo } from '../components'

export const MainNavbarMobile = () => {
  const { branding } = useTheme()
  const { signOut, user } = useAuthContext()

  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const [isUserOpen, setUserOpen] = useState<boolean>(false)

  return (
    <Wrapper alignItems="center" justifyContent="space-between">
      <AppBarLogo alt="Willba Logo" href="/" src={branding.logoWideSrc} />

      <FlexRow>
        {user && (
          <AvatarWrapper onClick={() => setUserOpen(true)}>
            <Avatar
              alt="User avatar"
              src={user.photoURL ?? '/portrait_placeholder.png'}
            />
          </AvatarWrapper>
        )}

        <IconButton icon="bars" onClick={() => setMenuOpen(true)} />
      </FlexRow>

      <MenuOverlay isOpen={isMenuOpen || isUserOpen} />

      <MenuSidebar closeMenu={() => setMenuOpen(false)} isOpen={isMenuOpen} />

      {user && (
        <UserSidebar
          closeMenu={() => setUserOpen(false)}
          isOpen={isUserOpen}
          user={user}
          signOut={signOut}
        />
      )}
    </Wrapper>
  )
}

////////////

const Avatar = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 100%;
`

const AvatarWrapper = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const MenuOverlay = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(4, 14, 31, 0.4);
  opacity: 0;
  pointer-events: none;
  transition: opacity 300ms linear;

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      pointer-events: unset;
    `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(10)}rem;
    padding: 0 ${theme.spacing.gu(3)}rem;
    background: ${theme.palette.primary.extraLighter};
  `}

  @media print {
    display: none;
  }
`
