import { gql, useMutation } from '@apollo/client'

import type {
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables,
} from '~generated-types'
import { orderFragments } from '@/modules/Order'

const UPDATE_INVOICE_MUTATION = gql`
  ${orderFragments.invoice}

  mutation UpdateInvoice($input: OrderUpdateInvoiceInput!) {
    orderUpdateInvoice(input: $input) {
      invoice {
        ...Invoice
      }
    }
  }
`

export const useUpdateInvoiceMutation = () =>
  useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(
    UPDATE_INVOICE_MUTATION
  )
