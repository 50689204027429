import React, { useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { FeatureChip, PrintLink } from '@/modules/Listing/common'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { formatDateRangeWithoutRepetition } from '@/utils/time'
import { generateCompareFn } from '@/utils/arrays'
import { SalesTypeChip } from '@/modules/Sales'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  ParticipantReservation,
  RoomReservation,
  RoomReservationCheckIn,
  RoomReservationCheckOut,
  RoomReservationCustomer,
  RoomReservationSales,
} from '../types'
import CleaningStatus from './CleaningStatus'

interface Props {
  data: RoomReservation
}

export const DepartingRoomReservationsTableRow = ({
  data: {
    nextInRoom,
    participantRooms,
    request: { checkIn, checkOut, room },
  },
}: Props) => {
  const theme = useTheme()

  const sortedGuests = useMemo(
    () =>
      [...participantRooms].sort(
        generateCompareFn([
          'participant.lastName',
          'participant.firstName',
          'participant.id',
        ])
      ),
    [participantRooms]
  )
  const rowSpan = participantRooms.length || 1

  const customerIds = Array.from(
    new Set(
      sortedGuests.map(
        (x) => x.participant.sales.customer?.customer.id || 'missing-customer'
      )
    )
  )
  const salesIds = Array.from(
    new Set(sortedGuests.map((x) => x.participant.sales.id))
  )

  const isAllSameCustomer = customerIds.length === 1 || customerIds.length === 0
  const isAllSameSales = salesIds.length === 1 || salesIds.length === 0

  const durationCell = (
    <td rowSpan={rowSpan}>
      {checkIn.type === CheckInTypes.EARLY && (
        <FontAwesomeIcon
          color={theme.palette.primary.dark}
          icon="sun"
          size="xs"
          style={{ marginRight: `${theme.spacing.gu(0.5)}rem` }}
        />
      )}
      {formatDateRangeWithoutRepetition(checkIn.date, checkOut.date, 'short')}
      {checkOut.type === CheckOutTypes.LATE && (
        <FontAwesomeIcon
          color={theme.palette.primary.dark}
          icon="moon"
          size="sm"
          style={{ marginLeft: `${theme.spacing.gu(0.5)}rem` }}
        />
      )}
    </td>
  )

  const nextInRoomCustomer = nextInRoom
    ? nextInRoom.accommodationGroup.sales.customer?.customer
    : null
  const nextInRoomSales = nextInRoom
    ? nextInRoom.accommodationGroup.sales
    : null

  const roomCell = (
    <td rowSpan={rowSpan}>
      <FlexColumn noPadding>
        <strong>#{room.number}</strong>
        <small style={{ marginLeft: theme.spacing.gutterSmall }}>
          {room.beds} + {room.extraBeds}
        </small>
      </FlexColumn>
    </td>
  )

  const statusCell = (
    <td rowSpan={rowSpan} style={{ textAlign: 'center' }}>
      {room.status.cleaningStatus && (
        <CleaningStatus status={room.status.cleaningStatus} />
      )}
    </td>
  )

  const getCustomerLabel = (customer: RoomReservationCustomer | undefined) => {
    return customer ? (
      <PrintLink to={`/registry/customers/${customer?.customerNumber}`}>
        #{customer.customerNumber} {parseCustomerName(customer) || '—'}
      </PrintLink>
    ) : (
      '—'
    )
  }

  const getGuestDuration = (
    checkInReference: RoomReservationCheckIn,
    checkOutReference: RoomReservationCheckOut,
    target: ParticipantReservation
  ): React.ReactNode => {
    const checkInDateDiff =
      checkInReference.date !== target.request.checkIn.date
    const checkInTypeDiff =
      checkInReference.type !== target.request.checkIn.type

    const checkOutDateDiff =
      checkOutReference.date !== target.request.checkOut.date
    const checkOutTypeDiff =
      checkOutReference.type !== target.request.checkOut.type

    if (
      checkInDateDiff ||
      checkInTypeDiff ||
      checkOutDateDiff ||
      checkOutTypeDiff
    ) {
      return (
        <>
          {target.request.checkIn.type === CheckInTypes.EARLY && (
            <FontAwesomeIcon
              color={theme.palette.primary.dark}
              icon="sun"
              size="xs"
              style={{ marginRight: `${theme.spacing.gu(0.5)}rem` }}
            />
          )}
          {formatDateRangeWithoutRepetition(
            target.request.checkIn.date,
            target.request.checkOut.date,
            'short'
          )}
          {target.request.checkOut.type === CheckOutTypes.LATE && (
            <FontAwesomeIcon
              color={theme.palette.primary.dark}
              icon="moon"
              size="sm"
              style={{ marginLeft: `${theme.spacing.gu(0.5)}rem` }}
            />
          )}
        </>
      )
    }

    return null
  }

  const parseCustomerName = (customer: RoomReservationCustomer) =>
    customer.__typename === 'CustomerOrganization'
      ? customer.organization.name
      : parseName({
          firstName: customer?.person.firstName || '',
          lastName: customer?.person.lastName || '',
        })

  const parseName = ({
    firstName,
    lastName,
  }: {
    firstName: string
    lastName: string
  }): string => `${lastName}${lastName && firstName ? ', ' : ''}${firstName}`

  const getSalesLabel = (sales: RoomReservationSales) => (
    <FlexRow alignItems="flex-start" justifyContent="space-between">
      <PrintLink to={`/sales/details/${sales.id}`}>
        #{sales.orderNumber}{' '}
        {sales.name ? (
          sales.name
        ) : (
          <em>
            <T
              l10n={`Listings:AccommodationGuestListing.unnamed.sales.${sales.type}`}
            />
          </em>
        )}
      </PrintLink>
      <SalesTypeChip type={sales.type} />
    </FlexRow>
  )

  return (
    <>
      <tr>
        {roomCell}
        {statusCell}
        {durationCell}
        {sortedGuests[0] ? (
          <td>
            <FlexRow alignItems="center" justifyContent="space-between">
              <span>{parseName(sortedGuests[0].participant)}</span>
              <small>
                {getGuestDuration(checkIn, checkOut, sortedGuests[0])}
              </small>
            </FlexRow>
          </td>
        ) : (
          <td>–</td>
        )}
        {sortedGuests[0] ? (
          <td rowSpan={isAllSameCustomer ? rowSpan : 1}>
            {getCustomerLabel(
              sortedGuests[0].participant.sales.customer?.customer
            )}
          </td>
        ) : (
          <td>–</td>
        )}
        {sortedGuests[0] ? (
          <td rowSpan={isAllSameSales ? rowSpan : 1}>
            {getSalesLabel(sortedGuests[0].participant.sales)}
          </td>
        ) : (
          <td>–</td>
        )}
        <td
          className="horizontal-spacer"
          rowSpan={rowSpan}
          style={{ textAlign: 'center', verticalAlign: 'middle' }}
        >
          <FontAwesomeIcon
            color={theme.palette.text.light}
            icon="right-long"
            size="lg"
          />
        </td>
        <td colSpan={5} rowSpan={rowSpan}>
          {nextInRoom ? (
            <>
              <NextInRoomRow icon={['far', 'clock']}>
                {nextInRoom.request.checkIn.type === CheckInTypes.EARLY && (
                  <FontAwesomeIcon
                    color={theme.palette.primary.dark}
                    icon="sun"
                    size="xs"
                    style={{ marginRight: `${theme.spacing.gu(0.5)}rem` }}
                  />
                )}
                {formatDateRangeWithoutRepetition(
                  nextInRoom.request.checkIn.date,
                  nextInRoom.request.checkOut.date,
                  'short'
                )}
                {nextInRoom.request.checkOut.type === CheckOutTypes.LATE && (
                  <FontAwesomeIcon
                    color={theme.palette.primary.dark}
                    icon="moon"
                    size="sm"
                    style={{ marginLeft: `${theme.spacing.gu(0.5)}rem` }}
                  />
                )}
              </NextInRoomRow>
              <NextInRoomRow icon="bed">
                {nextInRoom.request.beds}+{nextInRoom.request.extraBeds}{' '}
                {[...nextInRoom.request.features]
                  .sort(generateCompareFn('name'))
                  .map((feature) => (
                    <FeatureChip
                      feature={feature}
                      key={`feature-${feature.id}`}
                    />
                  ))}
              </NextInRoomRow>
              <NextInRoomRow icon="user">
                {nextInRoomCustomer ? (
                  <PrintLink
                    to={`/registry/customers/${nextInRoomCustomer.customerNumber}`}
                  >
                    #{nextInRoomCustomer.customerNumber}{' '}
                    {parseCustomerName(nextInRoomCustomer) || '—'}
                  </PrintLink>
                ) : (
                  '—'
                )}
              </NextInRoomRow>
              <NextInRoomRow
                icon="dollar-sign"
                isLastItem={!nextInRoom.request.info}
              >
                {nextInRoomSales ? (
                  <PrintLink to={`/sales/details/${nextInRoomSales.id}`}>
                    #{nextInRoomSales.orderNumber}{' '}
                    {nextInRoomSales.name ? (
                      nextInRoomSales.name
                    ) : (
                      <em>
                        <T
                          l10n={`Listings:AccommodationGuestListing.unnamed.sales.${nextInRoomSales.type}`}
                        />
                      </em>
                    )}
                  </PrintLink>
                ) : (
                  '—'
                )}
              </NextInRoomRow>
              {nextInRoom.request.info && (
                <NextInRoomRow icon="info" isLastItem>
                  {nextInRoom.request.info}
                </NextInRoomRow>
              )}
            </>
          ) : (
            <em>
              – <T>Listings:RoomReservationsListing.noNextReservation</T> –
            </em>
          )}
        </td>
      </tr>
      {sortedGuests.map(({ participant }, idx) => {
        if (idx === 0) {
          // first guest is already rendered in the main row
          return null
        }

        return (
          <tr key={`arriving-room-reservation-guest-${participant.id}`}>
            <td>{parseName(participant)}</td>
            {!isAllSameCustomer && (
              <td>{getCustomerLabel(participant.sales.customer?.customer)}</td>
            )}
            {!isAllSameSales && <td>{getSalesLabel(participant.sales)}</td>}
          </tr>
        )
      })}
    </>
  )
}

export default DepartingRoomReservationsTableRow

////////////

const NextInRoomRow = ({
  children,
  icon,
  isLastItem,
}: {
  children: React.ReactNode
  icon: IconProp
  isLastItem?: boolean
}) => {
  const theme = useTheme()

  return (
    <FlexRow
      alignItems="baseline"
      style={{ marginBottom: isLastItem ? 0 : `${theme.spacing.gu(0.5)}rem` }}
    >
      <FontAwesomeIcon
        color={theme.palette.text.light}
        fixedWidth
        icon={icon}
        size="sm"
        style={{
          marginLeft: theme.spacing.gutterSmall,
          marginRight: theme.spacing.gutter,
        }}
      />
      <div style={{ maxWidth: 320 }}>{children}</div>
    </FlexRow>
  )
}
