import { gql, useMutation } from '@apollo/client'

import ResourceReservationFragment from '../fragments/resourceReservation'

import {
  SetResourceReservationVisibilityMutation,
  SetResourceReservationVisibilityMutationVariables,
} from '~generated-types'

export type {
  SetResourceReservationVisibilityMutation,
  SetResourceReservationVisibilityMutationVariables,
}

export const SET_RESOURCE_RESERVATION_VISIBILITY_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationVisibility(
    $input: ResourceReservationSetVisibilityInput!
  ) {
    resourceReservationSetVisibility(input: $input) {
      ...ResourceReservation
    }
  }
`

export function useSetResourceReservationVisibilityMutation() {
  return useMutation<
    SetResourceReservationVisibilityMutation,
    SetResourceReservationVisibilityMutationVariables
  >(SET_RESOURCE_RESERVATION_VISIBILITY_MUTATION)
}

export default useSetResourceReservationVisibilityMutation
