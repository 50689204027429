import { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { translate, useLanguageContext } from '@/modules/Language'
import { InlineModal } from '@/components/InlineModal'
import { Input } from '@/components/FormControls'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

type Props = {
  options: string[]
  onSelect: (description: string) => Promise<void>
}

export const DescriptionOptions = ({ options, onSelect }: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const handleSelect = (option: string) =>
    onSelect(option).then(() => {
      setSearchValue('')
      setModalOpen(false)
    })

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <SearchWrapper justifyContent="flex-start" alignItems="center">
            <SearchIcon>
              <FontAwesomeIcon
                color={palette.primary.main}
                icon="magnifying-glass"
              />
            </SearchIcon>

            <Input
              autoFocus
              value={searchValue}
              placeholder={translate('FilterSearchList:search', language)}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setSearchValue(e.target.value)
              }
            />
          </SearchWrapper>

          <Divider />

          <Options noPadding>
            {options
              .filter((option) =>
                option.toLowerCase().includes(searchValue.toLowerCase())
              )
              .map((option) => (
                <OptionWrapper
                  key={option}
                  onClick={() => handleSelect(option)}
                >
                  {option}
                </OptionWrapper>
              ))}
          </Options>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <DescriptionOptionsWrapper ref={ref} onClick={() => setModalOpen(true)}>
          <DescriptionOptionsIcon icon={['far', 'square-plus']} />
        </DescriptionOptionsWrapper>
      )}
      zIndex={10003}
    />
  )
}

/////////

const DescriptionOptionsWrapper = styled.div`
  cursor: pointer;
  position: absolute;

  ${({ theme }) => css`
    right: calc(${theme.spacing.gu(4.5)}rem);
    top: calc(${theme.spacing.gu(2.5)}rem + 2px);
  `}
`

const DescriptionOptionsIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }
`

const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.light};
  `}
`

const Options = styled(FlexColumn)`
  overflow: auto;
  max-height: 340px;

  ${({ theme }) => css`
    width: calc(500px - ${theme.spacing.gu(13)}rem);
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const OptionWrapper = styled.div`
  cursor: pointer;
  transition: 0.1s;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`

const SearchIcon = styled.div`
  position: absolute;
  margin-left: 10px;
  width: 30px;
`

const SearchWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}

  input {
    &:not([type='checkbox']):not([type='radio']) {
      border-radius: 4px;
      padding-left: 30px;

      ${({ theme }) => css`
        border: 1px solid ${theme.palette.smoke.main};
        font-size: ${theme.typography.fontSizeSmall};
      `}

      &::placeholder {
        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`
