import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import { useTheme } from '@/theme'

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type Props = {
  children?: ReactNode
  flex?: number | string
  size?: Size
  style?: CSSProperties
  transparent?: boolean
}

export const LoadingPlaceholder = ({
  children,
  flex,
  size = 'md',
  ...rest
}: Props) => {
  const theme = useTheme()

  const getSpinnerSize = (size: Size): number => {
    switch (size) {
      case 'xs':
        return 12
      case 'sm':
        return 16
      case 'lg':
        return 26
      case 'xl':
        return 48
      case 'md':
      default:
        return 20
    }
  }

  return (
    <StyledLoadingPlaceholder flex={flex} size={size} {...rest}>
      {children ?? (
        <ReactLoading
          color={theme.palette.coal.light}
          height={getSpinnerSize(size)}
          type={'spin'}
          width={getSpinnerSize(size)}
        />
      )}
    </StyledLoadingPlaceholder>
  )
}

export default LoadingPlaceholder

////////////

const StyledLoadingPlaceholder = styled.div<Props>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gutterSmall};
    `}
  }

  background-color: ${({ theme, transparent }) =>
    transparent ? 'transparent' : theme.palette.smoke.lighter};
  border-radius: 4px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  ${({ flex }) =>
    flex &&
    css<any>`
      flex: ${flex};
    `}
  ${({ size }) => sizeReconciler(size)}
`

const sizeReconciler = (size?: Size) => {
  switch (size) {
    case 'xs':
    case 'sm':
      return css`
        ${({ theme }) => css`
          padding: ${theme.spacing.gutterSmall} ${theme.spacing.gutter};
        `}
      `
    case 'xl':
      return css`
        ${({ theme }) => css`
          padding: ${theme.spacing.gutterBig} ${theme.spacing.gutterBig};
        `}
      `
    case 'lg':
    case 'md':
    default:
      return css`
        ${({ theme }) => css`
          padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig};
        `}
      `
  }
}
