import { generateCompareFn } from '@/utils/arrays'
import { ListingTable } from '@/modules/Listing/common'
import { PrintSize } from '@/modules/Listing/types'
import { T } from '@/modules/Language'

import {
  ListingMealType,
  ListingRestaurant,
  ListingScheduledMeal,
} from '../../usePacketMealsListing'
import { AllergyBy } from '../../usePacketMealListControls'
import { compareMealsFn } from '../../utils'
import { MealTypeSpecialAllegies } from './MealTypeSpecialAllegies'
import { SaleTypeSpecialAllergies } from './SaleTypeSpecialAllergies'

export type ParticipantMeal = {
  mealName: string
  mealStartDate: string
}

type Participants = ListingScheduledMeal['participants'][number] & {
  meals: ParticipantMeal[]
}

export type Sales = {
  participants: Participants[]
  sales: ListingScheduledMeal['sales']
}

type Props = {
  data: {
    [mealKey: string]: ListingScheduledMeal[]
  }
  mealTypes: ListingMealType[]
  restaurant: ListingRestaurant
  printSize: PrintSize
  allergyBy: AllergyBy
}

export const RestaurantSpecialAllergies = ({
  data,
  mealTypes,
  printSize,
  allergyBy,
}: Props) => {
  const dataCount = Object.keys(data).reduce(
    (acc, mKey) => acc + (data[mKey] || []).length,
    0
  )

  return dataCount ? (
    <ListingTable printSize={printSize}>
      {allergyBy === 'MEAL' ? (
        <>
          <thead>
            <tr>
              <th style={{ width: '5%' }} />
              <th style={{ width: '10%' }}>
                <T>Listings:PacketMealListing.field.time</T>
              </th>
              <th style={{ width: '18%' }}>
                <T>Listings:PacketMealListing.field.name</T>
              </th>
              <th style={{ width: '7%' }}>
                <T>Listings:PacketMealListing.field.age</T>
              </th>
              <th style={{ width: '30%' }}>
                <T>Listings:PacketMealListing.field.sales</T>
              </th>
              <th style={{ width: '30%' }}>
                <T>Listings:PacketMealListing.field.allergiesAndDiets</T>
              </th>
            </tr>
          </thead>
          <tbody>
            {compareMealsFn(data, mealTypes).map((m) => (
              <MealTypeSpecialAllegies
                data={data[m.id] || []}
                key={`meal-type-special-allergies-${m.id}`}
                mealType={m}
              />
            ))}
          </tbody>
        </>
      ) : (
        <>
          <thead>
            <tr>
              <th style={{ width: '5%' }} />
              <th style={{ width: '25%' }}>
                <T>Listings:PacketMealListing.field.name</T>
              </th>
              <th style={{ width: '10%' }}>
                <T>Listings:PacketMealListing.field.age</T>
              </th>
              <th style={{ width: '25%' }}>
                <T>Listings:PacketMealListing.field.meals</T>
              </th>
              <th style={{ width: '35%' }}>
                <T>Listings:PacketMealListing.field.allergiesAndDiets</T>
              </th>
            </tr>
          </thead>
          <tbody>
            {groupedAllergiesBySales(data).map((d) => (
              <SaleTypeSpecialAllergies key={d.sales.id} data={d} />
            ))}
          </tbody>
        </>
      )}
    </ListingTable>
  ) : null
}

////////////

const groupedAllergiesBySales = (data: Props['data']) =>
  Object.values(data)
    .flat()
    .reduce((acc: Sales[], meal) => {
      const participantCount = meal?.participants?.length ?? 0
      if (participantCount === 0) {
        // If a meal does not contain any allergy information, omit the meal totally
        return acc
      } else {
        const existingSale = acc.find((a) => a.sales.id === meal.sales.id)
        if (existingSale) {
          meal.participants.forEach((p) => {
            const existingParticipant = existingSale.participants.find(
              (sp) => sp.id === p.id
            )
            if (existingParticipant) {
              const existingMeal = existingParticipant.meals.find(
                (item) => item.mealName === meal.meal.name
              )
              if (!existingMeal) {
                existingParticipant.meals.push({
                  mealName: meal.meal.name,
                  mealStartDate: meal.start,
                })
              }
            } else {
              existingSale.participants.push({
                ...p,
                meals: [
                  { mealName: meal.meal.name, mealStartDate: meal.start },
                ],
              })
            }
          })
          return acc
        } else {
          return [
            ...acc,
            {
              participants: [
                ...meal.participants.map((p) => ({
                  ...p,
                  meals: [
                    { mealName: meal.meal.name, mealStartDate: meal.start },
                  ],
                })),
              ],
              sales: meal.sales,
            },
          ]
        }
      }
    }, [])
    .sort(generateCompareFn('sales.orderNumber'))
