import { useRouteDate, useRouteValue } from '@/utils/hooks'

import { RoomReservationFilter as Filter } from './types'
import RoomReservationsListing from './RoomReservationsListing'
import useRoomReservations from './useRoomReservations'

export const RoomReservationsListingContainer = () => {
  const { date, setDate } = useRouteDate()
  const { setValue: setFilters, value: filters } = useRouteValue({
    multi: true,
    routeKey: 'filter',
    whitelist: [Filter.HIDE_FREE, Filter.HIDE_LODGING],
  })
  const { error, loading, roomReservations } = useRoomReservations({ date })

  return (
    <RoomReservationsListing
      date={date}
      error={error}
      filters={(filters as Filter[]) || []}
      loading={loading}
      roomReservations={roomReservations}
      setDate={setDate}
      setFilters={setFilters}
    />
  )
}

export default RoomReservationsListingContainer
