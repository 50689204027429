import { gql, useMutation } from '@apollo/client'

import type {
  RemoveManualPaymentMutation,
  RemoveManualPaymentMutationVariables,
} from '~generated-types'

const REMOVE_MANUAL_PAYMENT_MUTATION = gql`
  mutation RemoveManualPayment($id: ID!) {
    manualPaymentDelete(id: $id) {
      id
      deleted
    }
  }
`

export const useRemoveManualPaymentMutation = () =>
  useMutation<
    RemoveManualPaymentMutation,
    RemoveManualPaymentMutationVariables
  >(REMOVE_MANUAL_PAYMENT_MUTATION)
