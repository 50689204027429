import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ReactLoading from 'react-loading'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ListItem as ListItemBase, ListItemSection } from '@/components/List'
import { Chip } from '@/components/Chip'
import { ElasticSales } from '@/modules/Sales'
import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type ListItemProps = {
  context: SalesType
  data: ElasticSales
  isFirst: boolean
  onClick?: ((...args: any[]) => void | Promise<void>) | null | undefined
}

const ListItem = ({
  context,
  data: {
    customerName,
    customerType,
    estimatedDates,
    facet,
    id,
    name,
    orderNumber,
    participants,
    reservationDates,
  },
  isFirst,
  onClick,
}: ListItemProps) => {
  const theme = useTheme()

  const [isProcessing, setProcessing] = useState<boolean>(false)

  const handleSelect = () => {
    setProcessing(true)

    return !!onClick ? onClick() : () => undefined
  }

  const formatDate = (date: { start: string; end: string }) => {
    const isSameDate = moment(date.start).isSame(moment(date.end), 'day')
    const isSameYear = moment(date.start).isSame(moment(date.end), 'year')

    return isSameDate
      ? moment(date.start).format('DD.MM.YY')
      : `${moment(date.start).format(
          isSameYear ? 'DD.MM' : 'DD.MM.YY'
        )} – ${moment(date.end).format('DD.MM.YY')}`
  }

  const dates = estimatedDates
    ? formatDate(estimatedDates)
    : reservationDates
    ? formatDate(reservationDates)
    : null

  switch (context) {
    case 'EVENT':
    case 'SALES':
      return (
        <ListItemBase onClick={handleSelect}>
          <ItemSection isFirst={isFirst}>
            {isProcessing ? (
              <Wrapper alignItems="center" justifyContent="center">
                <ReactLoading
                  color={theme.palette.smoke.dark}
                  height={24}
                  type="spin"
                  width={24}
                />
              </Wrapper>
            ) : (
              <FlexRow>
                <Wrapper noPadding justifyContent="space-between" flex={1}>
                  <FlexRow alignItems="center">
                    {customerName ? (
                      <>
                        <FontAwesomeIcon
                          size="xs"
                          color={theme.palette.text.lighter}
                          icon={
                            customerType === 'PERSON'
                              ? 'user'
                              : ['far', 'building']
                          }
                          style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
                        />
                        <CustomerName>{customerName}</CustomerName>
                      </>
                    ) : (
                      <Placeholder>
                        <T>SalesSearchList:ListItem.noCustomer</T>
                      </Placeholder>
                    )}

                    <Spacer />

                    {dates && (
                      <Dates>
                        <FontAwesomeIcon
                          color={theme.palette.text.lighter}
                          icon={['far', 'calendar']}
                          size="sm"
                          style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
                        />
                        {dates}
                      </Dates>
                    )}

                    <ParticipantsWrapper>
                      <FontAwesomeIcon
                        color={theme.palette.text.lighter}
                        icon="user-friends"
                        size="sm"
                      />
                      <Participants>{participants ?? 0}</Participants>
                    </ParticipantsWrapper>
                  </FlexRow>

                  <FlexRow alignItems="center" justifyContent="flex-start">
                    <SalesTypeChipWrapper>
                      <Chip
                        size="xs"
                        variant="outlined"
                        customColor={{
                          fill: facet.color,
                          stroke: facet.color,
                        }}
                      >
                        {facet.abbreviation}
                      </Chip>
                    </SalesTypeChipWrapper>

                    {name && <Title>{name}</Title>}

                    <SalesNumber>#{orderNumber}</SalesNumber>
                  </FlexRow>
                </Wrapper>

                <Link target={'__blank'} to={`/sales/details/${id}`}>
                  <ExternalLink
                    alignItems="center"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <FontAwesomeIcon
                      color={theme.palette.text.lighter}
                      icon="up-right-from-square"
                      size="sm"
                    />
                  </ExternalLink>
                </Link>
              </FlexRow>
            )}
          </ItemSection>
        </ListItemBase>
      )
    case 'ENROLLMENT':
    default:
      console.warn(`Sales context not supported [${context}]`)
      return null
  }
}

export default ListItem

const CustomerName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    line-height: ${theme.spacing.guPx(2) + 1}px;
    max-width: ${theme.spacing.gu(28)}rem;
  `}
`

const Dates = styled.span`
  text-align: end;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const ExternalLink = styled(FlexRow)`
  height: 100%;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.light};
    padding: 0 ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.primary.extraLighter};
    `}
  }
`

const ItemSection = styled(ListItemSection)<{ isFirst?: boolean }>`
  &&& {
    padding: 0;

    ${({ theme }) => css`
      color: ${theme.palette.text.light};
      padding-left: ${theme.spacing.gu(1)}rem;
    `}

    ${({ isFirst, theme }) =>
      isFirst &&
      `
        border-top: 1px solid ${theme.palette.smoke.dark};
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        :hover {
          border-color: ${theme.palette.primary.light};
        }
        :after {
          opacity: 0;
        }
    `}
  }
`

const Participants = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const ParticipantsWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
    width: ${theme.spacing.gu(7)}rem;
  `}
`

const Placeholder = styled.span`
  &&& {
    font-style: italic;

    ${({ theme }) => css`
      color: ${theme.palette.text.lighter};
    `}
  }
`

const SalesNumber = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const SalesTypeChipWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const Title = styled.a`
  &&& {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${({ theme }) => css`
      max-width: ${theme.spacing.gu(42)}rem;
      color: ${theme.palette.text.light};
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(8)}rem;
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.guPx(1) - 2}px
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1)}rem;
  `}
`
