import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import {
  ListItemSection as Section,
  ListItem as Wrapper,
} from '@/components/List'
import { Chip } from '@/components/Chip'
import { T } from '@/modules/Language'

import { ElasticCatalog } from '../CatalogList.types'

type Props = {
  data: ElasticCatalog
  isHighlighted?: boolean
  itemControls?: ReactNode | null | undefined
  onOpen?: (...args: Array<any>) => any
}

export const ListItem = ({
  data,
  isHighlighted,
  itemControls,
  onOpen,
}: Props) => (
  <Wrapper highlight={isHighlighted}>
    <Section>
      {data.active ? (
        <Chip color="primary" size="sm">
          <T>Catalog:CatalogList.catalogState.active</T>
        </Chip>
      ) : (
        <Chip color="default" size="sm">
          <T>Catalog:CatalogList.catalogState.inactive</T>
        </Chip>
      )}
    </Section>

    <Section>
      <Name onClick={() => onOpen && onOpen()}>{data.name}</Name>
    </Section>

    <Section>{data.companyName}</Section>

    {itemControls && <Section>{itemControls}</Section>}
  </Wrapper>
)

////////////

const Name = styled.span`
  cursor: pointer;
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
