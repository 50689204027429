import styled, { css } from 'styled-components/macro'

import { DangerColor } from '@/components/Colors'
import { InlineModalFooter } from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  productId: string
}

export const PricingFooter = ({ productId }: Props) => {
  const { deleteProductWithConfirm, readOnly } = useSalesProductManagerContext()

  return (
    <InlineModalFooter>
      {!readOnly ? (
        <InnocuousButton onClick={() => deleteProductWithConfirm(productId)}>
          <DangerColor>
            <T>Products:ProductManager.action.deleteProduct</T>
          </DangerColor>
        </InnocuousButton>
      ) : (
        <Placeholder />
      )}
    </InlineModalFooter>
  )
}

/////

const Placeholder = styled.div`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
  `}
`
