import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'

import { InnerContainer, InnerSection } from '../../../components'
import { Participant } from '../Participant'
import { ParticipantsByRoom } from '../../utils/groupByRoom'
import { RoomDetails } from './RoomDetails'
import { RoomRow } from './RoomRow'

type Props = {
  room: ParticipantsByRoom
  showPrices: boolean
}

export const Room = ({
  room: { participants, roomReservation, roomNumber },
  showPrices,
}: Props) => {
  const [expandedIds, setExpandedIds] = useState<string[]>([])

  const expandRoom = (id: string) => {
    setExpandedIds((prevIds) =>
      prevIds.includes(id)
        ? prevIds.filter((existingId) => existingId !== id)
        : [...prevIds, id]
    )
  }

  const roomDates = roomReservation && {
    checkIn: roomReservation.request.checkIn.date,
    checkOut: roomReservation.request.checkOut.date,
  }

  const showDetails =
    roomReservation &&
    (!!roomReservation.request.info ||
      !!roomReservation.request.features.length ||
      !!roomReservation.request.room.internalInfo)

  return (
    <>
      <InnerContainer>
        <InnerSection
          expand={() => expandRoom(roomNumber)}
          isExpanded={expandedIds.includes(roomNumber)}
          isExpandable={!!roomReservation}
          visibleContent={
            roomReservation ? (
              <RoomRow roomReservation={roomReservation} />
            ) : (
              <Placeholder>
                <T>SalesDetails:PrintSale.noRoom</T>
              </Placeholder>
            )
          }
        >
          {showDetails && <RoomDetails roomReservation={roomReservation} />}
        </InnerSection>
      </InnerContainer>

      {participants.length ? (
        [...participants]
          .sort(generateCompareFn('lastName'))
          .map((p) => (
            <Participant
              isRoomParticipant
              key={p.id}
              participant={p}
              roomDates={roomDates}
              showPrices={showPrices}
            />
          ))
      ) : (
        <InnerContainer compact style={{ paddingLeft: '20px' }}>
          <Placeholder>
            – <T>SalesDetails:PrintSale.noParticipants</T> –
          </Placeholder>
        </InnerContainer>
      )}
    </>
  )
}

//////

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
