import { ReactNode } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal, InlineModalContent } from '@/components/InlineModal'
import { Gutter } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

type Props = {
  children: ReactNode
  hasValue?: boolean
  onClear?: undefined | null | ((...args: Array<any>) => any)
}

const ControlModal = ({ children, hasValue, onClear }: Props) => (
  <InlineModal>
    <InlineModalContent>
      <FlexColumn>
        {children}
        {!!onClear && (
          <FlexRow justifyContent="center">
            <Gutter type={[0, 1, 1]}>
              <InnocuousButton compact disabled={!hasValue} onClick={onClear}>
                <T>TimeControls:clear</T>
              </InnocuousButton>
            </Gutter>
          </FlexRow>
        )}
      </FlexColumn>
    </InlineModalContent>
  </InlineModal>
)

export default ControlModal
