import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'
import styled from 'styled-components/macro'

import { useTheme } from '@/theme'

type Props = {
  children: ReactNode
  icon?: IconProp
  iconColor?: string
  sign?: string
}

export const Section = ({ children, icon, iconColor, sign }: Props) => {
  const { palette } = useTheme()

  return (
    <Wrapper>
      {(!!icon || !!sign) && (
        <IconWrapper>
          {icon && (
            <Icon color={iconColor ?? palette.text.lighter} icon={icon} />
          )}
          {sign && <SignWrapper>{sign}</SignWrapper>}
        </IconWrapper>
      )}

      {children}
    </Wrapper>
  )
}

////////////

const IconWrapper = styled.div`
  flex: 0 0 16px;
  display: flex;

  ${({ theme }) => `
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

type IconProps = { color: string; icon: IconProp }

const Icon = ({ color, icon }: IconProps) => (
  <FontAwesomeIcon color={color} fixedWidth icon={icon} size="xs" />
)

const SignWrapper = styled(IconWrapper)`
  justify-content: center;
  font-weight: 500;

  ${({ theme }) => `
    color: ${theme.palette.text.lighter};
  `}
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: baseline;
`
