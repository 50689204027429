import styled, { css } from 'styled-components/macro'

import { AgeCategoryFragment, SalesDetailsQuery } from '~generated-types'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'

import { Cell } from '../Cell'
import { VisitorsSection } from './VisitorsSection'

type VST = NonNullable<SalesDetailsQuery['sales']['vst']>
type VSTParticipant = VST['participantVstSummary'][0]

type Props = {
  ageCategories: AgeCategoryFragment[]
  dayVisitors: VSTParticipant[]
  lodging: VSTParticipant[]
}

export const VisitorsTable = ({
  ageCategories,
  dayVisitors,
  lodging,
}: Props) => (
  <FlexRow justifyContent="center">
    <AgeCategories>
      <Cell width={8} />

      {[...ageCategories]
        .sort(generateCompareFn('sortOrder'))
        .map(({ key, shortName }) => (
          <Cell justifyContent="flex-end" key={key} width={8}>
            {shortName}
          </Cell>
        ))}

      <Cell justifyContent="flex-end" width={8}>
        <T>ParticipantsList:ParticipantProducts.totalPrice</T>
      </Cell>
    </AgeCategories>

    <VisitorsSection
      ageCategories={ageCategories}
      source={lodging}
      sectionTitle={<T>ParticipantsList:VST.lodging</T>}
    />

    <VisitorsSection
      ageCategories={ageCategories}
      source={dayVisitors}
      sectionTitle={<T>ParticipantsList:VisitStatus.dayVisitor</T>}
    />
  </FlexRow>
)

/////////

const AgeCategories = styled(FlexColumn)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(8)}rem;
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`
