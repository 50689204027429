import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'
import { useState } from 'react'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { ServiceParticipantBedFragment } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import Label from '../../../ParticipantRow/common/ServiceItemEmptyLabel'
import RoomsOptions from './components/RoomSelector/RoomsOptions'
import ServiceItemRounded from '../../../ParticipantRow/common/ServiceItemRounded'

type Props = {
  participantSalesId: string
  serviceId: string | null
  room: ServiceParticipantBedFragment['participantRoom'] | null
  isDisabled: boolean
  handleAddRoom: (roomReservationId: string) => Promise<any>
  handleRemoveRoom: (roomId: string) => Promise<any>
}

export const ParticipantRoom = ({
  participantSalesId,
  serviceId,
  room,
  isDisabled,
  handleAddRoom,
  handleRemoveRoom,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const addRoom = (roomReservationId: string) => {
    setProcessing(true)
    setModalOpen(false)

    return handleAddRoom(roomReservationId).finally(() => setProcessing(false))
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal style={{ minWidth: '500px' }}>
          <InlineModalSection style={{ margin: 0, padding: 0 }}>
            <RoomsOptions
              salesId={participantSalesId}
              serviceId={serviceId}
              handleClose={() => setModalOpen(false)}
              handleSelect={addRoom}
            />
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <ServiceItemRounded
          ref={ref}
          isRemovable={!!room}
          disabled={isDisabled}
          width={`${spacing.gu(13)}rem`}
          contentTooltip={
            room
              ? `${room.roomReservation.request.room.building.name}, ${room.roomReservation.request.room.roomType.name}`
              : ''
          }
          onClick={() => (isDisabled || processing ? null : setModalOpen(true))}
          renderContent={
            processing ? (
              <FlexRow justifyContent="center">
                <ReactLoading
                  color={palette.smoke.main}
                  height={20}
                  type="spin"
                  width={20}
                />
              </FlexRow>
            ) : room ? (
              <ContentWrapper>
                <FontAwesomeIcon
                  color={palette.primary.main}
                  icon={'door-open'}
                  style={{ marginRight: `${spacing.gu(1)}rem` }}
                />
                <RoomName>
                  {`#${room.roomReservation.request.room.number}`}
                </RoomName>
              </ContentWrapper>
            ) : (
              <>
                <FontAwesomeIcon
                  color={palette.primary.main}
                  fixedWidth
                  size="xs"
                  icon="plus"
                />
                <Label>
                  <T>ParticipantsList:ParticipantRooms.addRoom</T>
                </Label>
              </>
            )
          }
          handleDelete={() => handleRemoveRoom(room?.id || '')}
        />
      )}
    />
  )
}

const RoomName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 0;
  width: 100%;
  text-align: center;
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`
