import styled, { css } from 'styled-components/macro'
import { ChangeEvent } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { Input } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  searchValue: string
  setSearchValue: (searchValue: string) => void
}

export const AssigneeSearchInput = ({ searchValue, setSearchValue }: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  return (
    <Wrapper justifyContent="flex-start" alignItems="center">
      <SearchIcon>
        <FontAwesomeIcon color={palette.primary.main} icon="magnifying-glass" />
      </SearchIcon>

      <Input
        autoFocus
        value={searchValue}
        placeholder={translate('FilterSearchList:search', language)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
      />
    </Wrapper>
  )
}

////////

const SearchIcon = styled.div`
  position: absolute;
  margin-left: 10px;
  width: 30px;
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}

  input {
    &:not([type='checkbox']):not([type='radio']) {
      border-radius: 4px;
      padding-left: 30px;

      ${({ theme }) => css`
        border: 1px solid ${theme.palette.smoke.main};
        font-size: ${theme.typography.fontSizeSmall};
      `}

      &::placeholder {
        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`
