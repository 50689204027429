import { ControlProps, OptionTypeBase } from 'react-select'
import styled, { css } from 'styled-components/macro'
import { CSSProperties } from 'react'

import { EducationLevel, ParticipantLifeStage } from '~generated-types'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { FlexColumn } from '@/components/Layout'
import { Label } from '@/components/FormControls'

import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  education: EducationLevel | null
  lifeStage: ParticipantLifeStage | null
  handleUpdateEducation: (
    education: EducationLevel | null
  ) => Promise<FetchedParticipant | undefined>
  handleUpdateLifeStage: (
    lifeStage: ParticipantLifeStage | null
  ) => Promise<FetchedParticipant | undefined>
}

export const EducationAndLifeStageModal = ({
  education,
  lifeStage,
  handleUpdateEducation,
  handleUpdateLifeStage,
}: Props) => {
  const { spacing, palette } = useTheme()
  const { language } = useLanguageContext()

  const extraStyles = {
    container: (styles: CSSProperties) => ({
      ...styles,
      fontWeight: 400,
      marginBottom: spacing.gutter,
      width: '100%',
    }),
    control: (
      styles: CSSProperties,
      { isFocused }: ControlProps<OptionTypeBase, false>
    ) => ({
      ...styles,
      borderColor: isFocused ? palette.primary.main : palette.smoke.dark,
      cursor: 'pointer',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
    menuList: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
  }

  const educationOptions = Object.values(EducationLevel).map(
    (education: EducationLevel) => ({
      label: translate(`enums:education.${education}`, language),
      value: education,
    })
  )

  const lifeStageOptions = Object.values(ParticipantLifeStage).map(
    (lifeStage: ParticipantLifeStage) => ({
      label: translate(`enums:lifeStage.${lifeStage}`, language),
      value: lifeStage,
    })
  )

  return (
    <ModalContainer>
      <Section>
        <FlexColumn noPadding flex={1}>
          <FlexColumn style={{ width: '100%' }}>
            <Title>
              {translate('ParticipantsList:PersonalFields.education', language)}
            </Title>
            <ThemedSelect
              isClearable
              extraStyles={extraStyles}
              name="education-selector"
              onChange={(selected: Option | null | undefined) =>
                handleUpdateEducation(
                  selected ? (selected.value as EducationLevel) : null
                )
              }
              options={educationOptions}
              placeholder=""
              value={
                education
                  ? educationOptions.find(({ value }) => value === education)
                  : null
              }
            />
          </FlexColumn>
          <FlexColumn style={{ width: '100%' }}>
            <Title>
              {translate('ParticipantsList:PersonalFields.lifeStage', language)}
            </Title>
            <ThemedSelect
              isClearable
              extraStyles={extraStyles}
              name="lifeStage-selector"
              onChange={(selected: Option | null | undefined) =>
                handleUpdateLifeStage(
                  selected ? (selected.value as ParticipantLifeStage) : null
                )
              }
              options={lifeStageOptions}
              placeholder=""
              value={
                lifeStage
                  ? lifeStageOptions.find(({ value }) => value === lifeStage)
                  : null
              }
            />
          </FlexColumn>
        </FlexColumn>
      </Section>
    </ModalContainer>
  )
}

const ModalContainer = styled(InlineModal)`
  max-height: 100vh;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(65)}rem;
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const Section = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter} 0;
  `}
`

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
