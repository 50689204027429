import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo } from 'react'

import {
  InlineModal,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexColumn } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { ProductPurchase } from '@/modules/Products/types'
import { PurchaseProductUpdateInput } from '~generated-types'
import { useTheme } from '@/theme'

import { RoomOptions } from './components'

type Props = {
  handleExtract: () => Promise<void>
  onClose: () => void
  purchaseId: string
  quantity: number
  salesId: string
  selectedRooms: { id: string; emptyBeds: number }[]
  setSelectedRooms: (rooms: { id: string; emptyBeds: number }[]) => void
  updatePurchase: (
    input: PurchaseProductUpdateInput
  ) => Promise<ProductPurchase | undefined>
}

export const RoomOptionsModal = ({
  handleExtract,
  onClose,
  purchaseId,
  quantity,
  salesId,
  selectedRooms,
  setSelectedRooms,
  updatePurchase,
}: Props) => {
  const { palette, spacing, typography } = useTheme()
  const { language } = useLanguageContext()

  const totalRoomsSelected = useMemo(
    () =>
      selectedRooms
        .map(({ emptyBeds }) => emptyBeds)
        .reduce((a, b) => a + b, 0),
    [selectedRooms]
  )

  const handleUpdatePurchaseQuantity = () =>
    updatePurchase({
      attributes: { quantity: totalRoomsSelected },
      purchaseId,
    })

  return (
    <Modal>
      <ModalHeader
        onClose={onClose}
        title={
          <ShiftLeft>
            <T>Products:ProductManager.purchase.roomSelector</T>
          </ShiftLeft>
        }
      />

      <InlineModalLine style={{ margin: 0 }} />

      <RoomOptions
        salesId={salesId}
        selectedRooms={selectedRooms}
        handleSetSelectedRooms={setSelectedRooms}
      />

      <InlineModalLine style={{ margin: 0 }} />

      <ButtonWrapper>
        {!!totalRoomsSelected && quantity < totalRoomsSelected && (
          <span style={{ margin: `${spacing.gu(1)}rem 0` }}>
            <FontAwesomeIcon
              color={palette.warning.dark}
              icon="triangle-exclamation"
              style={{ marginRight: `${spacing.gu(1)}rem` }}
            />

            {`${translate(
              'Products:ProductManager.action.extractWarning',
              language,
              {
                count: quantity,
              }
            )}, `}
            <LinkButton onClick={() => handleUpdatePurchaseQuantity()}>
              {translate(
                'Products:ProductManager.action.extractSuggestion',
                language,
                {
                  count: totalRoomsSelected - quantity,
                }
              )}
            </LinkButton>
          </span>
        )}

        <InnocuousButton
          noNudge
          disabled={!selectedRooms.length || quantity < totalRoomsSelected}
          size="small"
          onClick={handleExtract}
          style={{ margin: 0 }}
        >
          <PrimaryColor style={{ fontSize: typography.fontSizeSmall }}>
            <T>Products:ProductManager.purchase.extract</T>
            <FontAwesomeIcon
              color={palette.primary.dark}
              icon="bed"
              style={{ margin: `0 ${spacing.gu(1)}rem` }}
            />
            {quantity >= totalRoomsSelected
              ? totalRoomsSelected
              : `${quantity} / ${totalRoomsSelected}`}
          </PrimaryColor>
        </InnocuousButton>
      </ButtonWrapper>
    </Modal>
  )
}

/////////

const ButtonWrapper = styled(FlexColumn)`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}
`

const LinkButton = styled.span`
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`

const Modal = styled(InlineModal)`
  min-width: 550px;

  ${({ theme }) => css`
    max-height: calc(100vh - ${theme.spacing.gu(19)}rem - 25px);
  `};
`

const ModalHeader = styled(InlineModalHeader)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(7)}rem;
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(4)}rem;
  `}
`
