import styled, { css } from 'styled-components/macro'

import { translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'

import { useParticipantsListContext } from '../../ParticipantsListState'

export const ListTotals = () => {
  const { language } = useLanguageContext()
  const {
    pageSize,
    pagination: { currentPage, totalElements, totalPages },
  } = useParticipantsListContext()

  const rangeShown =
    totalPages <= 1
      ? totalElements
      : `${currentPage * pageSize + 1} – ${Math.min(
          (currentPage + 1) * pageSize,
          totalElements
        )}`

  return (
    <Wrapper justifyContent="flex-end" alignItems="center">
      <FontWeight light>
        {translate(`ParticipantsList:totals`, language, {
          range: rangeShown,
          total: totalElements,
        })}
      </FontWeight>
    </Wrapper>
  )
}

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(2)}rem;
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
