import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { lighten } from 'polished'

import { Building } from '@/modules/FrontDesk'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Separator } from './common'

type Props = {
  building: Building
}

export const Header = ({ building }: Props) => {
  const { spacing } = useTheme()

  return (
    <>
      <HiddenSection />
      <Wrapper alignItems="center">
        <Section flex={2} justifyContent="flex-start" isFirst>
          <BuildingName>{building.name}</BuildingName>
        </Section>

        <Separator />

        <Section flex={4} justifyContent="center">
          <FontAwesomeIcon
            icon="bed"
            size="xs"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>FrontDesk:DailyRoomReservations.field.ongoing</T>
          <Separator />
          /
          <Separator />
          <FontAwesomeIcon
            icon="plane-departure"
            size="xs"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>FrontDesk:DailyRoomReservations.field.departing</T>
        </Section>

        <Separator />

        <Section flex={4.675} justifyContent="center" isLast>
          <FontAwesomeIcon
            icon="plane-arrival"
            size="xs"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>FrontDesk:DailyRoomReservations.field.arriving</T>
        </Section>
      </Wrapper>
    </>
  )
}

////////////

const BuildingName = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeBig};
    margin-left: ${theme.spacing.gu(2)}rem;
  `};
`

const HiddenSection = styled(FlexRow)`
  position: sticky;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    top: -${theme.spacing.gu(4)}rem;
    height: calc(${theme.spacing.gu(4)}rem + 10px);
    margin-top: calc(-${theme.spacing.gu(4)}rem - 10px);
  `};

  z-index: 2;
`

const Section = styled(FlexRow)<{
  isFirst?: boolean
  isLast?: boolean
}>`
  font-weight: 500;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    height: 40px;
    background: ${lighten(0.0, theme.palette.smoke.lighter)};
    border: 1px solid ${theme.palette.smoke.main};
    font-size: ${theme.typography.fontSizeBase2};
  `};

  ${({ isFirst }) =>
    isFirst &&
    css`
      border-top-left-radius: 6px;
    `};

  ${({ isLast }) =>
    isLast &&
    css`
      border-top-right-radius: 6px;
    `};
`

const Wrapper = styled(FlexRow)`
  position: sticky;
  top: 0;

  z-index: 3;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `};
`
