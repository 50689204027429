import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useState } from 'react'

import {
  EventEnrollment,
  FetchedParticipant,
  Participant,
  ParticipantSaleSelector,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { OrderState as OS, SalesType as ST } from '~generated-types'
import { translate, useLanguageContext } from '@/modules/Language'
import { FlexColumn } from '@/components/Layout'

import {
  ParticipantServices,
  ParticipantServicesLoading,
} from '../ParticipantServices'
import { ParticipantRow } from '../ParticipantRow'

type Props = {
  participant: FetchedParticipant
  isEventParticipant: boolean
  participantSaleCustomer?:
    | NonNullable<EventEnrollment['customer']>['customer']
    | null
  rootRoomId?: string
  isSelectable?: boolean
  isSelected?: boolean | undefined
  readOnly?: boolean
  participantNotRemovable?: boolean
  select?: ((select: boolean) => void) | undefined
}

const ParticipantRowWithServices = ({
  participant,
  isEventParticipant,
  participantSaleCustomer,
  rootRoomId,
  isSelectable,
  isSelected,
  readOnly,
  participantNotRemovable,
  select,
}: Props) => {
  const { language } = useLanguageContext()
  const {
    saleType,
    handleMoveParticipant,
    handleAddRoom,
    handleAddCatalogProduct,
    handleAddSalesProduct,
    handleDeleteService,
    handleServiceSetDates,
    handleSetCheckIn,
    handleSetCheckOut,
    handleSetServiceTarget,
    handleRemovePurchase,
    handleRemoveRoom,
    handleSetVisitStatus,
  } = useParticipantsListContext()

  const [creatingNew, setCreatingNew] = useState<'BED' | 'PRODUCT' | null>(null)

  const servicePlaceholderRemoveOptions: {
    key: string
    disabled: boolean
    icon: IconProp
    label: string
    onClick: () => Promise<any> | void
  }[] =
    creatingNew === 'BED'
      ? [
          {
            disabled: true,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantRooms.removeReservation',
              language
            ),
            onClick: () => null,
          },
          {
            disabled: true,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantProducts.removeProduct',
              language
            ),
            onClick: () => null,
          },
          {
            disabled: !participant?.services.length,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantRooms.removeService',
              language
            ),
            onClick: () => setCreatingNew(null),
          },
        ]
      : creatingNew === 'PRODUCT'
      ? [
          {
            disabled: true,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantProducts.removeProduct',
              language
            ),
            onClick: () => null,
          },
          {
            disabled: !participant?.services.length,
            icon: 'trash',
            key: '',
            label: translate(
              'ParticipantsList:ParticipantRooms.removeService',
              language
            ),
            onClick: () => setCreatingNew(null),
          },
        ]
      : []

  const serviceRemoveOptions: {
    key: string
    disabled: boolean
    icon: IconProp
    label: string
    onClick: () => Promise<any> | void
  }[][] = participant?.services?.length
    ? (participant.services as Participant['services']).map((service) =>
        service.__typename === 'ServiceParticipantBed'
          ? [
              {
                disabled:
                  service.__typename === 'ServiceParticipantBed' &&
                  !service.participantRoom,
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantRooms.removeReservation',
                  language
                ),
                onClick: () =>
                  handleRemoveRoom(
                    (service.__typename === 'ServiceParticipantBed' &&
                      service.participantRoom?.id) ||
                      ''
                  ),
              },
              {
                disabled:
                  (service.__typename === 'ServiceParticipantBed' &&
                    !service.purchaseProduct) ||
                  (service.purchaseProduct?.order?.lifecycle.state !==
                    OS.Open &&
                    service.purchaseProduct?.order?.lifecycle.state !==
                      OS.Correction),
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantProducts.removeProduct',
                  language
                ),
                onClick: () =>
                  handleRemovePurchase(
                    (service.__typename === 'ServiceParticipantBed' &&
                      service.purchaseProduct?.id) ||
                      '',
                    participant.id
                  ),
              },
              {
                disabled:
                  !participant.services.length ||
                  (service.purchaseProduct?.order?.lifecycle.state !==
                    OS.Open &&
                    service.purchaseProduct?.order?.lifecycle.state !==
                      OS.Correction),
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantRooms.removeService',
                  language
                ),
                onClick: () =>
                  service.id === ''
                    ? setCreatingNew(null)
                    : handleDeleteService(service.id || ''),
              },
            ]
          : service.__typename === 'ServicePurchase'
          ? [
              {
                disabled:
                  service.purchaseProduct?.order?.lifecycle.state !== OS.Open &&
                  service.purchaseProduct?.order?.lifecycle.state !==
                    OS.Correction,
                icon: 'trash',
                key: service.id,
                label: translate(
                  'ParticipantsList:ParticipantProducts.removeProduct',
                  language
                ),
                onClick: () =>
                  handleRemovePurchase(
                    service.purchaseProduct?.id ?? '',
                    participant.id
                  ),
              },
            ]
          : []
      )
    : []

  const isDayVisitor = participant?.visitStatus.isDayVisitor

  return (
    <ParticipantRow
      rootRoomId={rootRoomId}
      extraRemoveOptions={[
        ...serviceRemoveOptions,
        servicePlaceholderRemoveOptions,
      ]}
      participantBasicData={participant}
      services={(participant?.services as Participant['services']) || []}
      readOnly={readOnly}
      notRemovable={participantNotRemovable}
      participantContextComponent={
        <FlexColumn noPadding flex={1}>
          {saleType === ST.Event && isEventParticipant ? (
            <ParticipantSaleSelector
              participantSalesId={participant?.sales.id || participant.sales.id}
              readOnly={readOnly}
              handleSelectSale={(newSale) =>
                handleMoveParticipant(
                  participant.id,
                  newSale,
                  participant.sales.id
                )
              }
            />
          ) : (participant as Participant).services[0]?.purchaseProduct !==
              undefined || participant.services.length === 0 ? (
            <ParticipantServices
              participant={participant as Participant}
              isEventParticipant={isEventParticipant}
              participantSaleCustomer={participantSaleCustomer || null}
              services={participant.services as Participant['services']}
              allowAccommodation={!participant.visitStatus.isDayVisitor}
              isDayVisitor={isDayVisitor}
              actionsDisabled={!!readOnly}
              creatingNew={creatingNew}
              saleType={saleType}
              readOnly={readOnly}
              setCreatingNew={setCreatingNew}
              handleAddRoom={({ roomReservationId, serviceId }) =>
                handleAddRoom({
                  id: participant.id,
                  roomReservationId,
                  serviceId,
                })
              }
              handleAddCatalogProduct={handleAddCatalogProduct}
              handleAddSalesProduct={handleAddSalesProduct}
              handleDeleteService={handleDeleteService}
              handleServiceSetDates={handleServiceSetDates}
              handleSetCheckIn={(roomReservationId, providedTime) =>
                handleSetCheckIn(roomReservationId, providedTime)
              }
              handleSetCheckOut={(roomReservationId, providedTime) =>
                handleSetCheckOut(roomReservationId, providedTime)
              }
              handleSetParticipantTarget={({ targetId, serviceId }) =>
                handleSetServiceTarget({
                  id: participant.id,
                  serviceId,
                  targetId,
                })
              }
              handleRemoveRoom={(roomId) => handleRemoveRoom(roomId)}
              handleRemovePurchase={handleRemovePurchase}
              handleSetVisitStatus={(dayVisitorDates, isDayVisitor) =>
                handleSetVisitStatus({
                  dayVisitorDates,
                  id: participant.id,
                  isDayVisitor,
                })
              }
            />
          ) : (
            <ParticipantServicesLoading
              services={participant.services}
              isDayVisitor={participant.visitStatus.isDayVisitor}
            />
          )}
        </FlexColumn>
      }
      isSelectable={isSelectable && !readOnly}
      isSelected={isSelected}
      select={select}
    />
  )
}

export default ParticipantRowWithServices
