import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { FlexColumn } from '@/components/Layout'

type Props = {
  label: ReactNode
  children: ReactNode
  width?: string
}

export const FilterWrapper = ({ children, label, width }: Props) => (
  <Wrapper width={width}>
    <Label>{label}</Label>
    {children}
  </Wrapper>
)

////////////

const Wrapper = styled(FlexColumn)<{ width?: string }>`
  ${({ width }) => width && `width: ${width}`};
  padding: 0;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutterBig};
  `}
`

const Label = styled.span`
  margin-bottom: 0.25rem;
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
