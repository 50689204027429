import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { SalesAssigneeFragment as SalesAssignee } from '~generated-types'
import { T } from '@/modules/Language'

import { AssigneeModal } from './AssigneeModal'
import { useSalesDetailsContext } from '../../../..'

export const Assignee = () => {
  const {
    data: { assignee },
  } = useSalesDetailsContext()

  const [isOpen, setOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={<AssigneeModal onClose={() => setOpen(false)} />}
      onClose={() => setOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Button
          ref={ref as Ref<HTMLButtonElement>}
          onClick={() => setOpen(true)}
        >
          {assignee ? (
            <FlexRow>
              <AvatarWrapper>
                <Avatar
                  alt="User avatar"
                  src={assignee.avatarUrl || '/portrait_placeholder.png'}
                />
              </AvatarWrapper>

              <Name>{getFullName(assignee)}</Name>
            </FlexRow>
          ) : (
            <Placeholder>
              <T>SalesDetails:assignee.setAssignee</T>
            </Placeholder>
          )}

          <DropdownIcon icon="angle-down" />
        </Button>
      )}
    />
  )
}

/////////

const getFullName = ({ firstName, lastName }: SalesAssignee) =>
  `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`

const Avatar = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 100%;
`

const AvatarWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.main};
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Button = styled.button`
  display: flex;
  align-items: center;

  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  height: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: 0 ${theme.spacing.gutter};
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const DropdownIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Name = styled.span`
  font-weight: 500;
`

const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
