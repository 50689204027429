import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Label = 'ALL' | 'DEFAULT'

type Props = {
  description?: ReactNode
  selectedLabel: Label
  setSelectedLabel: (label: Label) => void
  loadDataForLabel: () => void
}

export const ContextSwitcher = ({
  description,
  selectedLabel,
  setSelectedLabel,
  loadDataForLabel,
}: Props) => {
  const { spacing, typography } = useTheme()

  return (
    <Wrapper alignItems="center">
      {description}

      <span style={{ flex: 1 }} />

      <FlexRow
        alignItems="stretch"
        justifyContent="flex-end"
        style={{ margin: `${spacing.gu(0.5)}rem 0` }}
      >
        <StyledInnocuousButton
          active={selectedLabel === 'DEFAULT'}
          disabled
          noNudge
          compact
          size="small"
          onClick={() => {
            setSelectedLabel('DEFAULT')
            loadDataForLabel()
          }}
        >
          <PrimaryColor style={{ fontSize: typography.fontSizeSmall }}>
            <T>ParticipantsList:ParticipantRooms.showDefault</T>
          </PrimaryColor>
        </StyledInnocuousButton>

        <Divider />

        <StyledInnocuousButton
          active={selectedLabel === 'ALL'}
          disabled
          noNudge
          compact
          size="small"
          onClick={() => {
            setSelectedLabel('ALL')
            loadDataForLabel()
          }}
        >
          <PrimaryColor style={{ fontSize: typography.fontSizeSmall }}>
            <T>ParticipantsList:ParticipantRooms.showAll</T>
          </PrimaryColor>
        </StyledInnocuousButton>
      </FlexRow>
    </Wrapper>
  )
}

////////

const Divider = styled.div`
  width: 2px;
  margin: 0 3px;

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.main};
  `}
`

const StyledInnocuousButton = styled(InnocuousButton)<{ active: boolean }>`
  ${({ active, theme }) =>
    active &&
    `border-bottom: 2px solid ${theme.palette.primary.main};
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;`}
  margin-right: 0;
`

const Wrapper = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}
`
