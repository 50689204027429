import styled, { css } from 'styled-components/macro'

import {
  SalesCopyComponent as C,
  Feature,
  SalesDetailsQuery,
} from '~generated-types'
import { FlexColumn, FlexRow } from '@/components/Layout'

import { Checkbox } from '../components'

const PRODUCT_COMPONENTS = [C.SalesProduct, C.PurchaseProducts]

type FacetFeature = SalesDetailsQuery['sales']['facet']['features'][0]

type Props = {
  components: C[]
  facetFeatures: FacetFeature[]
  setComponents: (components: C[]) => void
}

export const SingleComponentsSection = ({
  components,
  facetFeatures,
  setComponents,
}: Props) => {
  const toggleComponent = (target: C) =>
    setComponents(
      components.includes(target)
        ? target === C.SalesProduct
          ? components.filter((c) => !PRODUCT_COMPONENTS.includes(c))
          : components.filter((c) => c !== target)
        : [...components, target]
    )

  return (
    <Wrapper>
      <FlexRow wrap="wrap">
        <Checkbox
          checked={components.includes(C.Customer)}
          label={C.Customer}
          onChange={() => toggleComponent(C.Customer)}
        />

        {facetFeatures.find((f) => f.feature === Feature.Participants) && (
          <Checkbox
            checked={components.includes(C.Participants)}
            label={C.Participants}
            onChange={() => toggleComponent(C.Participants)}
          />
        )}

        {facetFeatures.find((f) => f.feature === Feature.Products) && (
          <Checkbox
            checked={components.includes(C.SalesProduct)}
            label={C.SalesProduct}
            onChange={() => toggleComponent(C.SalesProduct)}
          />
        )}

        {facetFeatures.find((f) => f.feature === Feature.Products) && (
          <Checkbox
            checked={components.includes(C.PurchaseProducts)}
            disabled={!components.includes(C.SalesProduct)}
            label={C.PurchaseProducts}
            onChange={() => toggleComponent(C.PurchaseProducts)}
          />
        )}

        {facetFeatures.find((f) => f.feature === Feature.Tasks) && (
          <Checkbox
            checked={components.includes(C.SalesTasks)}
            label={C.SalesTasks}
            onChange={() => toggleComponent(C.SalesTasks)}
          />
        )}
      </FlexRow>
    </Wrapper>
  )
}

/////

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`
