import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { EditButton, IconWrapper, Placeholder } from './common'
import { GroupType } from '../Group'
import { GroupValue } from './GroupValue'

type Props = {
  values: string[]
  setGroup: (groupFields: Partial<GroupType>) => Promise<any>
}

export const GroupValuesSection = ({ values, setGroup }: Props) => {
  const { palette } = useTheme()

  const [isHover, setHover] = useState<boolean>(false)
  const [editedValueIndex, setEditedValueIndex] = useState<number>(-1)
  const [groupValues, setGroupValues] = useState<string[]>([])

  useEffect(() => {
    setGroupValues(values)
  }, [values])

  const validateGroupValue = (value: string) =>
    groupValues.includes(value.trim())

  const handleAddValue = () => {
    setGroupValues([...groupValues, ''])
    setEditedValueIndex(groupValues.length)
  }

  const handleRemoveValue = (idx: number) =>
    setGroup({
      values: groupValues.filter((_, index) => index !== idx),
    })

  const handleSetValue = (newValue: string, idx: number) => {
    setEditedValueIndex(-1)

    return setGroup({
      values: groupValues.map((v, index) => (index === idx ? newValue : v)),
    })
  }

  return (
    <>
      {groupValues.map((value, idx) => (
        <GroupValue
          editModeByDefault={editedValueIndex === idx}
          key={`${value}-${idx}`}
          removeValue={() => handleRemoveValue(idx)}
          setValue={(newValue: string) => handleSetValue(newValue, idx)}
          validateValue={validateGroupValue}
          value={value}
        />
      ))}

      <InlineModalIconSection>
        <EditButton
          onClick={handleAddValue}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >
          <Placeholder>
            <T>SalesDetails:Settings.group.addItem</T>
          </Placeholder>

          <IconWrapper>
            {isHover && (
              <FontAwesomeIcon
                color={palette.primary.main}
                icon="plus"
                size="sm"
              />
            )}
          </IconWrapper>
        </EditButton>
      </InlineModalIconSection>
    </>
  )
}
