import styled, { css } from 'styled-components/macro'
import { useCallback, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import { useLazyQuery } from '@apollo/client'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  SalesType,
  ServiceSuitableTargetsQuery,
  ServiceSuitableTargetsQueryVariables,
  SuitableFilter,
} from '~generated-types'
import { translate, useLanguageContext } from '@/modules/Language'
import { FontWeight } from '@/components/Typography'
import { generateCompareFn } from '@/utils/arrays'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ParticipantsListQueries } from '@/modules/ParticipantsList'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useAccommodationTargetCreate } from '@/modules/Accommodation//SalesReservationList/mutations'
import { useTheme } from '@/theme'

import ContextSwitcher from '../ContextSwitcher'
import OptionsListWithHeading from '../OptionsListWithHeading'
import TargetLabel from './TargetLabel'

type Label = 'ALL' | 'DEFAULT'

type Props = {
  salesId: string
  newTargetNamePreset?: string
  serviceId: string | null
  saleType: SalesType | null
  handleSetTarget: (input: any) => Promise<any>
  onClose: () => void
  openTargetManager: (id: string) => void
}

const TargetsList = ({
  salesId,
  newTargetNamePreset,
  serviceId,
  saleType,
  handleSetTarget,
  onClose,
  openTargetManager,
}: Props) => {
  const [addTarget] = useAccommodationTargetCreate()

  const [groups, setGroups] = useState<any[]>([])
  const [selectedLabel, setSelectedLabel] = useState<Label>('DEFAULT')

  const { language } = useLanguageContext()
  const { palette, spacing, typography } = useTheme()

  const [loadTargets, { data, loading, error }] = useLazyQuery<
    ServiceSuitableTargetsQuery,
    ServiceSuitableTargetsQueryVariables
  >(ParticipantsListQueries.SERVICE_SUITABLE_TARGETS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  })

  const handleAddTarget = (input: any) =>
    addTarget({
      variables: {
        input,
      },
    })

  const loadDataForLabel = (filter: SuitableFilter) => [
    loadTargets({
      variables: {
        input: {
          filter,
          salesId,
          serviceId,
        },
      },
    }),
  ]

  useEffect(() => {
    loadDataForLabel(SuitableFilter.Default)
  }, [])

  useEffect(() => {
    setGroups(
      () =>
        (!!data?.serviceSuitableTargets &&
          data.serviceSuitableTargets
            .map(({ target }: any) => target.accommodationGroup)
            .sort(generateCompareFn('id'))
            .filter(
              (item: any, pos: number, ary: any) =>
                !pos || item?.id !== ary[pos - 1]?.id
            )
            .sort(generateCompareFn('sortOrder'))) ||
        []
    )
  }, [data])

  const getTargetOptionsForGroup = useCallback(
    (groupId: string) =>
      data?.serviceSuitableTargets
        ? data.serviceSuitableTargets
            .map(({ target }) => target)
            .filter((target) => target.accommodationGroup.id === groupId)
            .sort(generateCompareFn('sortOrder'))
            .map((target) => ({
              endAdornment: (
                <SettingsWrapper
                  onClick={() =>
                    target.default ? null : openTargetManager(target.id)
                  }
                >
                  <FontAwesomeIcon
                    color={
                      target.default ? palette.smoke.dark : palette.coal.dark
                    }
                    fixedWidth
                    size="sm"
                    icon="gear"
                    style={{ margin: `0 ${spacing.gu(1)}rem` }}
                  />
                </SettingsWrapper>
              ),
              label: <TargetLabel target={target} />,
              value: target.id,
            }))
        : [],

    [data?.serviceSuitableTargets]
  )

  return (
    <FlexColumn
      flex={1}
      alignItems={'center'}
      style={{ padding: `${spacing.gu(2)}rem` }}
    >
      {loading ? (
        <ReactLoading
          type={'bubbles'}
          height={18}
          width={18}
          color={palette.smoke.main}
        />
      ) : error ? (
        <T>common:error.common</T>
      ) : (
        <>
          <ContextSwitcher
            handleClose={onClose}
            loadDataForLabel={loadDataForLabel}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
          />
          {!groups?.length ? (
            <FontWeight style={{ marginTop: `${spacing.gu(1)}rem` }}>
              <T>ParticipantsList:ParticipantFormFields.noTargets</T>
            </FontWeight>
          ) : (
            groups.map((group) => (
              <OptionsListWithHeading
                key={group.id}
                groupTitle={
                  <FlexRow
                    justifyContent="space-between"
                    alignItems="center"
                    style={{
                      padding: `0 ${spacing.gu(3)}rem`,
                      width: '100%',
                    }}
                  >
                    <FontWeight>
                      <FontAwesomeIcon
                        color={palette.primary.main}
                        fixedWidth
                        size="sm"
                        icon="users"
                        style={{ marginRight: `${spacing.gu(1)}rem` }}
                      />
                      {saleType !== SalesType.Sales &&
                        (group
                          ? group?.name
                            ? group.name
                            : `${translate(
                                `ParticipantsList:ParticipantFormFields.group`,
                                language
                              )} #${group.sortOrder}`
                          : '')}
                    </FontWeight>
                    <InnocuousButton
                      noNudge
                      compact
                      size="small"
                      onClick={() => {
                        handleAddTarget({
                          accommodationGroupId: group.id,
                          name: newTargetNamePreset || '',
                          shareToSalesId:
                            saleType === SalesType.Enrollment ? salesId : null,
                        }).then(
                          ({ data }) =>
                            data &&
                            openTargetManager(
                              data.accommodationTargetCreate.target.id
                            )
                        )
                      }}
                    >
                      <PrimaryColor
                        style={{ fontSize: typography.fontSizeSmall }}
                      >
                        <T>Accommodation:TargetGroup.addTarget</T>
                      </PrimaryColor>
                    </InnocuousButton>
                  </FlexRow>
                }
                options={getTargetOptionsForGroup(group.id)}
                noOptionsLabel={
                  <T>ParticipantsList:ParticipantFormFields.noTargets</T>
                }
                handleSelect={(value) => handleSetTarget({ targetId: value })}
              />
            ))
          )}
        </>
      )}
    </FlexColumn>
  )
}

export default TargetsList

const SettingsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s ease-out;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
