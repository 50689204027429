import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client'
import moment from 'moment'

import {
  MealType,
  Restaurant,
  useMealsBySales,
  useMealsConfig,
} from '@/modules/Meals'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { MealsByDate, Quantity } from './types'

type ContextType = {
  defaultQuantities: Quantity[]
  error?: ApolloError
  isEditMode: boolean
  loading: boolean
  mealsByDate: MealsByDate[]
  mealTypes: MealType[]
  restaurants: Restaurant[]
  setEditMode: (isEditMode: boolean) => void
}

const MealsContext = createContext<ContextType>({
  defaultQuantities: [],
  error: undefined,
  isEditMode: false,
  loading: false,
  mealsByDate: [],
  mealTypes: [],
  restaurants: [],
  setEditMode: () => undefined,
})

type Props = {
  children: ReactNode
}

export const MealsContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { data } = useSalesDetailsContext()
  const { ageCategories, mealTypes, restaurants } = useMealsConfig()

  const {
    error,
    meals,
    loading: mealsLoading,
  } = useMealsBySales({
    salesId: data.id,
  })

  const [defaultQuantities, setDefaultQuantities] = useState<Quantity[]>([])
  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)

  const mealsByDate = useMemo(() => {
    const days = meals.map(({ start }) => moment(start).format('YYYY-MM-DD'))
    const uniqueDays = [...new Set(days)].sort(
      (a, b) => moment(a).valueOf() - moment(b).valueOf()
    )
    return uniqueDays.map((date) => ({
      date,
      meals: meals.filter(
        (m) => moment.utc(m.start).format('YYYY-MM-DD') === date
      ),
    }))
  }, [JSON.stringify(meals)])

  useEffect(() => {
    if (ageCategories.length) {
      setDefaultQuantities(
        ageCategories.map((ageCategory) => ({
          key: ageCategory.key,
          quantity: 0,
          shortName: ageCategory.shortName,
        }))
      )
    }
  }, [JSON.stringify(ageCategories)])

  useEffect(() => {
    if (!meals.length && isEditMode) {
      setEditMode(false)
    }
  }, [JSON.stringify(meals)])

  useEffect(() => {
    setLoading(mealsLoading)
  }, [mealsLoading])

  contextValueRef.current = {
    defaultQuantities,
    error,
    isEditMode,
    loading,
    mealsByDate,
    mealTypes,
    restaurants,
    setEditMode,
  }

  return (
    <MealsContext.Provider value={contextValueRef.current}>
      {children}
    </MealsContext.Provider>
  )
}

export const useMealsContext = () => useContext(MealsContext)
