import { gql, useMutation } from '@apollo/client'

import { ParticipantVst } from '../ParticipantsList.fragments'

import {
  ParticipantVstSetAttributesMutation,
  ParticipantVstSetAttributesMutationVariables,
} from '~generated-types'

export type {
  ParticipantVstSetAttributesMutation,
  ParticipantVstSetAttributesMutationVariables,
}

export const SET_VST_ATTRIBUTES = gql`
  ${ParticipantVst}
  mutation ParticipantVSTSetAttributes(
    $input: ParticipantVstSetAttributesInput!
  ) {
    participantVstSetAttributes(input: $input) {
      participantId
      vst {
        ...ParticipantVst
      }
    }
  }
`

export function useVSTSetAttributes() {
  return useMutation<
    ParticipantVstSetAttributesMutation,
    ParticipantVstSetAttributesMutationVariables
  >(SET_VST_ATTRIBUTES)
}

export default useVSTSetAttributes
