import React, { createContext, useContext, useRef } from 'react'
import { useQuery } from '@apollo/client'

import { useEnrollmentsContext } from '@/modules/Sales/components/SalesDetails/routes/Enrollments/EnrollmentsContext'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { AccommodationQueries } from '@/modules/Accommodation/SalesReservationList/ReservationList.queries'
import { EventEnrollmentsQueries } from '@/API/EventEnrollments'

type Props = {
  saleId: string
  children: React.ReactChild
}

type ContextType = {
  isCompact: boolean
  refetchEnrollments: any
  refetchAccommodation: any
  salesId: string
}

const CompactViewContext = createContext<ContextType>({
  isCompact: false,
  refetchAccommodation: null,
  refetchEnrollments: null,
  salesId: '',
})

const CompactViewContainer = ({ saleId, children }: Props) => {
  const contextValueRef = useRef<ContextType | null | undefined>(null)

  const { data } = useSalesDetailsContext()

  const { pagination } = useEnrollmentsContext()

  const { refetch: refetchEnrollments } = useQuery(
    EventEnrollmentsQueries.ENROLLMENTS,
    {
      fetchPolicy: 'cache-only',
      variables: {
        id: data.id,
        input: {
          page: pagination.currentPage || 0,
          size: 10,
        },
      },
    }
  )

  const { refetch: refetchAccommodation } = useQuery(
    AccommodationQueries.SALE_RESERVATIONS,
    {
      fetchPolicy: 'cache-only',
      variables: {
        id: saleId,
      },
    }
  )

  contextValueRef.current = {
    isCompact: true,
    refetchAccommodation,
    refetchEnrollments,
    salesId: saleId,
  }

  return (
    <CompactViewContext.Provider value={contextValueRef.current}>
      {children}
    </CompactViewContext.Provider>
  )
}

export default CompactViewContainer

export const useCompactViewContext = () => useContext(CompactViewContext)
