import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FontWeight } from '@/components/Typography'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

const PAGE_SIZES = [5, 10, 20, 50]

type Props = {
  goToPage: (page: number, pageSize: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
}

export const PageSizeSelector = ({
  goToPage,
  pageSize,
  setPageSize,
}: Props) => {
  const theme = useTheme()

  const [isOpen, setOpen] = useState(false)

  const options = PAGE_SIZES.map((size) => ({
    label: size,
    selected: size === pageSize,
    value: size,
  }))

  const handleSelectPageSize = (value: number) => {
    setPageSize(value)
    setOpen(false)
    goToPage(0, value)
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={
        <InlineModal style={{ minWidth: `${theme.spacing.gu(24)}rem` }}>
          <InlineModalSection
            style={{
              marginTop: `${theme.spacing.gu(1)}rem`,
              padding: `0 0 ${theme.spacing.gu(1)}rem`,
            }}
          >
            {
              <FlexColumn noPadding flex={1}>
                {options.map((option) => (
                  <OptionWrapper
                    key={option.value}
                    onClick={() => handleSelectPageSize(option.value)}
                  >
                    <CheckIconWrapper>
                      {option.selected && (
                        <FontAwesomeIcon
                          icon="check"
                          size="sm"
                          color={theme.palette.primary.main}
                        />
                      )}
                    </CheckIconWrapper>
                    {option.label}
                  </OptionWrapper>
                ))}
              </FlexColumn>
            }
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <Wrapper alignItems="center" onClick={() => setOpen(true)} ref={ref}>
          <FontWeight semiBold>
            <T>ElasticFilterSearchList:pageSize</T>
          </FontWeight>

          <PageSizeWrapper>{pageSize}</PageSizeWrapper>
        </Wrapper>
      )}
    />
  )
}

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding-left: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(20)}rem;
  `}
`

const OptionWrapper = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  cursor: pointer;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.primary.extraLight};
    `}
  }
`

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
`

const PageSizeWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: 0.2s;
  cursor: pointer;
  font-weight: 500;
  height: 35px;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`
