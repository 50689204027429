import { gql } from '@apollo/client'

import { attachmentFragments } from '@/modules/Attachments'

export default gql`
  ${attachmentFragments.attachment}

  fragment Document on Document {
    attachments {
      ...Attachment
    }
    attributes {
      key
      value
    }
    file {
      objectName
      fileName
      mimeType
      created
    }
    fileStatus
    id
    language
    name
    status
    template {
      id
      name
      documentName
      documentType
      filesAllowed
    }
    templateOptions {
      id
      name
      documentName
      documentType
      filesAllowed
    }
    userAttributes {
      key
      name
      editorValue
      templateValue
      active
    }
  }
`
