import confirmation from './confirmation'
import confirmationInfo from './confirmationInfo'
import document from './document'
import invoice from './invoice'
import invoiceInfo from './invoiceInfo'
import offer from './offer'
import offerInfo from './offerInfo'
import order from './order'
import orderCustomer from './orderCustomer'
import orderInfo from './orderInfo'
import orderShortInfo from './orderShortInfo'
import payment from './payment'

export default Object.freeze({
  confirmation,
  confirmationInfo,
  document,
  invoice,
  invoiceInfo,
  offer,
  offerInfo,
  order,
  orderCustomer,
  orderInfo,
  orderShortInfo,
  payment,
})
