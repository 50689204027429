import moment from 'moment'

import {
  MobileSalesParticipant,
  MobileSalesRoomReservation,
} from '@/modules/Mobile/SalesList'

export type ParticipantsByRoom = {
  participants: MobileSalesParticipant[]
  roomNumber: string
  roomReservation?: MobileSalesRoomReservation
}

export const groupByRoom = (
  participants: MobileSalesParticipant[],
  roomReservations: MobileSalesRoomReservation[]
) => {
  const participantsByRoom: ParticipantsByRoom[] = roomReservations.map(
    (roomReservation) => {
      const roomParticipants = participants.reduce(
        (acc: MobileSalesParticipant[], p) => {
          const purchaseServices = p.services.filter(
            (s) => s.__typename === 'ServicePurchase'
          )
          const roomService = p.services.find(
            (s) =>
              s.__typename === 'ServiceParticipantBed' &&
              s.participantRoom?.roomReservation.id === roomReservation.id
          )

          if (roomService) {
            return [
              ...acc,
              {
                ...p,
                services: [roomService, ...purchaseServices],
              },
            ]
          }
          return acc
        },
        []
      )

      return {
        participants: roomParticipants,
        roomNumber: roomReservation.request.room.number,
        roomReservation,
      }
    }
  )

  const noRoomParticipants = participants.filter(
    (p) =>
      !p.services.length ||
      p.services.some(
        (s) => s.__typename !== 'ServiceParticipantBed' || !s.participantRoom
      )
  )

  if (noRoomParticipants.length) {
    participantsByRoom.push({
      participants: noRoomParticipants,
      roomNumber: 'NO_ROOM',
    })
  }

  return participantsByRoom.sort((a, b) => {
    if (a.roomNumber === 'NO_ROOM') return 1
    if (b.roomNumber === 'NO_ROOM') return -1

    if (a.roomNumber > b.roomNumber) return 1
    if (a.roomNumber < b.roomNumber) return -1

    if (
      moment(a.roomReservation?.request.checkIn.date).valueOf() >
      moment(b.roomReservation?.request.checkIn.date).valueOf()
    ) {
      return 1
    }

    if (
      moment(a.roomReservation?.request.checkIn.date).valueOf() <
      moment(b.roomReservation?.request.checkIn.date).valueOf()
    ) {
      return -1
    }

    return 0
  })
}
