import { ReactNode } from 'react'

import { CalendarResourceElastic } from '@/modules/Registry'
import { CalendarViewMode } from '@/modules/Reservations'

import { getGridGroupSection } from './getGridGroupSection'

type Props = {
  emptyPlaceholder: ReactNode | null
  error: Error | null
  fetching: boolean
  groupId: string
  isFocused: boolean
  resources: CalendarResourceElastic[]
  targetDate: string
  title: ReactNode
  viewMode: CalendarViewMode
}

export const getGridGroupForResources = ({
  groupId,
  resources,
  targetDate,
  viewMode,
  ...rest
}: Props) => ({
  id: groupId,
  sections: resources.map((resource, idx, arr) =>
    getGridGroupSection({
      isLast: idx === arr.length - 1,
      isNested: false,
      resource,
      targetDate,
      viewMode,
    })
  ),
  titlePlaceholder: null,
  ...rest,
})
