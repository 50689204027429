import { Fragment, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'
import VisibilitySensor from 'react-visibility-sensor'

import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useCalendarState } from '@/modules/Reservations'
import { useTheme } from '@/theme'

import {
  GridGroupSection,
  useReservationsGridState,
} from '../../ReservationsGridState'
import {
  GridGroupSectionPlaceholder as Placeholder,
  GridGroupSection as Section,
} from './GridGroupSection'

type Props = {
  emptyPlaceholder: ReactNode | null
  hasError: boolean
  isFetching: boolean
  isFirst: boolean
  isFocused: boolean
  isLast: boolean
  sections: GridGroupSection[]
  title: ReactNode | null | undefined
  titlePlaceholder: ReactNode
}

const GridGroup = ({
  emptyPlaceholder,
  hasError,
  isFetching,
  isFirst,
  isFocused,
  isLast,
  sections,
  title,
  titlePlaceholder,
}: Props) => {
  const theme = useTheme()

  const {
    calendarVariables: { ROW_HEIGHT, COLUMN_WIDTH },
  } = useReservationsGridState()
  const { viewMode } = useCalendarState()

  const headerLabel = title ? title : titlePlaceholder

  const renderSectionForWeek = (s: GridGroupSection, sIdx: number) => (
    <Fragment key={`calendar-grid-group-section-${s.id}`}>
      <VisibilitySensor onChange={() => undefined} partialVisibility>
        {({ isVisible }) =>
          isVisible ? (
            <Section
              availabilityOffset={sIdx}
              data={s}
              isLast={sIdx === sections.length - 1}
              nestedResourcesIds={s.nestedResourcesIds}
              parentResourceId={s.parentResourceId}
            />
          ) : (
            <SectionPlaceholder height={ROW_HEIGHT} width={COLUMN_WIDTH * 96} />
          )
        }
      </VisibilitySensor>
    </Fragment>
  )

  const renderSectionForDate = (s: GridGroupSection, sIdx: number) => (
    <Fragment key={`calendar-grid-group-section-${s.id}`}>
      <VisibilitySensor onChange={() => undefined} partialVisibility>
        {({ isVisible }) =>
          isVisible ? (
            <Section
              availabilityOffset={sIdx}
              data={s}
              isLast={sIdx === sections.length - 1}
              nestedResourcesIds={s.nestedSections?.map(
                ({ resourceId }) => resourceId
              )}
            />
          ) : (
            <SectionPlaceholder height={ROW_HEIGHT} width={COLUMN_WIDTH * 96} />
          )
        }
      </VisibilitySensor>
      {s.nestedSections?.map((ns, nsIdx) => (
        <VisibilitySensor
          key={`calendar-grid-group-sub-section-${ns.id}`}
          onChange={() => undefined}
          partialVisibility
        >
          {({ isVisible }) =>
            isVisible ? (
              <Section
                availabilityOffset={nsIdx}
                data={ns}
                isLast={nsIdx === (s.nestedSections?.length || 1) - 1}
                isLastParent={sIdx === sections.length - 1}
                parentResourceId={s.resourceId}
              />
            ) : (
              <SectionPlaceholder
                height={ROW_HEIGHT}
                width={COLUMN_WIDTH * 96}
              />
            )
          }
        </VisibilitySensor>
      ))}
    </Fragment>
  )

  return (
    <Wrapper>
      <GroupHeader height={ROW_HEIGHT} isFirst={isFirst}>
        <HeaderLabel>
          {isFocused ? (
            <PrimaryColor style={{ alignItems: 'center', display: 'flex' }}>
              {headerLabel}
            </PrimaryColor>
          ) : (
            <>{headerLabel}</>
          )}
          {isFetching && (
            <LoaderWrapper>
              <ReactLoading
                color={theme.palette.text.lighter}
                height={16}
                type={'spin'}
                width={16}
              />
            </LoaderWrapper>
          )}
        </HeaderLabel>
      </GroupHeader>
      <GroupContent isLast={isLast}>
        {hasError ? (
          <Placeholder>
            <PlaceholderLabel>
              <T>ResourceReservationsCalendar:GridGroup.error</T>
            </PlaceholderLabel>
          </Placeholder>
        ) : !!sections.length ? (
          sections.map(
            viewMode === 'DATE' ? renderSectionForDate : renderSectionForWeek
          )
        ) : isFetching ? (
          <Placeholder>
            <ReactLoading
              color={theme.palette.text.lighter}
              height={20}
              type={'bubbles'}
              width={20}
            />
          </Placeholder>
        ) : (
          <Placeholder>
            <PlaceholderLabel>{emptyPlaceholder}</PlaceholderLabel>
          </Placeholder>
        )}
      </GroupContent>
    </Wrapper>
  )
}

export default GridGroup

////////////

const GroupContent = styled.div<{ isLast: boolean }>`
  display: inline-flex;
  flex-direction: column;
  border-bottom: ${({ isLast, theme }) =>
    isLast ? `1px solid ${theme.palette.smoke.dark}` : 0};
`

const GroupHeader = styled.div<{ height: number; isFirst: boolean }>`
  position: sticky;
  display: flex;
  align-items: center;
  top: ${({ isFirst, theme }) =>
    `calc(${theme.spacing.gu(8)}rem + ${isFirst ? 1 : 0}px)`};
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  border-top: ${({ isFirst, theme }) =>
    isFirst ? 0 : `1px solid ${theme.palette.smoke.dark}`};

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    border-bottom: 1px solid ${theme.palette.smoke.dark};
    background: ${theme.palette.smoke.extraLight};
  `}

  z-index: 3045;
`

const HeaderLabel = styled.div`
  position: sticky;
  display: inline-flex;
  align-items: center;
  left: 0;
  max-width: 100%;
  font-weight: 500;
  font-variant: all-small-caps;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    padding-left: ${theme.spacing.gu(2)}rem;
  `}
`

const PlaceholderLabel = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    color: ${theme.palette.text.lighter};
  `}
`

const LoaderWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const SectionPlaceholder = styled.div<{ height: number; width: number }>`
  ${({ height, width }) => css`
    height: ${height}px;
    width: ${width}px;
  `}
`

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
`
