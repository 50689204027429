import styled, { css } from 'styled-components/macro'

import {
  Customer,
  parseCustomerName,
  Sales,
  useRoomReservationsContext,
} from '@/modules/FrontDesk'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { FlexColumn } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'

export const SalesFilter = () => {
  const { availableSales, loading, selectedSale, setSelectedSale } =
    useRoomReservationsContext()

  const getSalesOption = (sales: Sales) => {
    const { id, name, orderNumber } = sales

    const customer = sales.customer?.customer
    const parsedCustomer = customer ? parseCustomerName(customer) : ''

    return {
      label: (
        <FlexColumn alignItems="flex-start" noPadding>
          {getSalesLabel({ sales })}
          {getCustomerLabel({
            customer,
            isSelected: id === selectedSale?.id,
          })}
        </FlexColumn>
      ),
      searchValue: `${orderNumber}-${name}-${parsedCustomer}`,
      value: id,
    }
  }

  const options = [...availableSales]
    .sort(generateCompareFn('orderNumber'))
    .map(getSalesOption)

  const selectedValue = selectedSale
    ? {
        ...getSalesOption(selectedSale),
        label: getSalesLabel({ isCompact: true, sales: selectedSale }),
      }
    : null

  return (
    <FilterSection
      label={<T>FrontDesk:DailyRoomReservations.sales.title</T>}
      render={() => (
        <ThemedSelect
          isLoading={loading}
          isClearable
          isSearchable
          name="room-reservations-sale-selector"
          noOptionsMessage={() => (
            <T>FrontDesk:DailyRoomReservations.sales.noOptions</T>
          )}
          onChange={(selected?: Option | null) =>
            setSelectedSale(
              availableSales?.find(({ id }) => id === selected?.value) ?? null
            )
          }
          options={options}
          placeholder={<T>FrontDesk:DailyRoomReservations.all</T>}
          value={selectedValue}
        />
      )}
    />
  )
}

///////

const getCustomerLabel = ({
  customer,
  isSelected,
}: {
  customer?: Customer
  isSelected: boolean
}) => (
  <CustomerWrapper isSelected={isSelected}>
    <NoWrapSpan>
      {customer ? parseCustomerName(customer) || '—' : '–'}
    </NoWrapSpan>
  </CustomerWrapper>
)

const getSalesLabel = ({
  isCompact,
  sales: { name, orderNumber, type },
}: {
  isCompact?: boolean
  sales: Sales
}) => (
  <NoWrapSpan isCompact={isCompact}>
    #{orderNumber}{' '}
    {name || (
      <em>
        <T l10n={`FrontDesk:DailyRoomReservations.unnamed.${type}`} />
      </em>
    )}
  </NoWrapSpan>
)

const CustomerWrapper = styled.span<{ isSelected: boolean }>`
  display: block;
  margin-top: 2px;

  ${({ isSelected, theme }) => css`
    color: ${isSelected ? theme.palette.white : theme.palette.text.lighter};
  `};
`

const NoWrapSpan = styled.span<{ isCompact?: boolean }>`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ isCompact, theme }) => css`
    width: ${theme.spacing.gu(isCompact ? 24 : 32)}rem;
  `};
`
