import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

type Props = {
  selectedParticipantsLength: number
  isAllSelected: boolean
  selectAll: (selectAll: boolean) => void
}

export const CheckBox = ({
  selectedParticipantsLength,
  isAllSelected,
  selectAll,
}: Props) => {
  const { palette, spacing } = useTheme()

  return (
    <FlexRow style={{ maxHeight: '35px' }}>
      <FlexRow alignItems="center">
        <CheckBoxPlaceholder onClick={() => selectAll(!isAllSelected)}>
          {isAllSelected && (
            <FontAwesomeIcon
              color={palette.primary.main}
              fixedWidth
              size="sm"
              icon={'check'}
            />
          )}
        </CheckBoxPlaceholder>
      </FlexRow>

      <FlexRow
        alignItems="center"
        justifyContent="flex-start"
        style={{ width: `${spacing.gu(24)}rem` }}
      >
        <FontAwesomeIcon
          color={palette.primary.main}
          fixedWidth
          size="1x"
          icon="user-group"
          style={{ margin: `${spacing.gu(1)}rem` }}
        />
        {selectedParticipantsLength}
      </FlexRow>
    </FlexRow>
  )
}

const CheckBoxPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`
