import { FlexColumn, FlexRow } from '@/components/Layout'
import { formatDateRangeWithoutRepetition as formatDate } from '@/utils/time'
import { T } from '@/modules/Language'
import { useLanguageContext } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { formatRoom, getFullName, getTargetName } from '../../../utils'
import {
  SalesPrintConfigKey as Key,
  SalesForPrintParticipantServiceBed,
} from '../../../../../types'
import { ParticipantWrapper, Placeholder, Section, Wrapper } from './common'
import { checkSalesPrintConfigVisibility } from '../../../../../utils'
import { GuestDetails } from '../GuestDetails'
import { ParticipantsByGroup } from '../utils'

type Props = {
  data: ParticipantsByGroup
}

export const GroupRow = ({ data: { group, participants } }: Props) => {
  const { language } = useLanguageContext()
  const { salesPrintConfig: config } = useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(config, `${Key.Participants}.${key}`)

  return (
    <Wrapper>
      <FlexRow>
        <Section style={{ fontWeight: 600, width: '8rem' }}>
          {group === 'NO_GROUP' ? (
            <Placeholder>
              <T>SalesDetails:PrintSale.noGroup</T>
            </Placeholder>
          ) : (
            group
          )}
        </Section>

        <FlexColumn flex={1}>
          {participants.map((participant, idx) => {
            const { age, ageCategory, firstName, id, lastName, services } =
              participant

            const [firstService, ...nextServices] = services.filter(
              (s) => s.__typename === 'ServiceParticipantBed'
            ) as SalesForPrintParticipantServiceBed[]

            const dates = firstService?.dates
            const room =
              firstService?.participantRoom?.roomReservation.request.room.number
            const target = firstService?.accommodationTarget

            return (
              <FlexColumn key={id}>
                <ParticipantWrapper isFirst={idx === 0}>
                  {checkVisibility(Key.Name) && (
                    <Section style={{ fontWeight: 600, width: '12rem' }}>
                      {getFullName(firstName, lastName) || '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Age) && (
                    <Section flex={0.5}>
                      {age ?? ageCategory?.abbreviation ?? '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Target) && (
                    <Section flex={1.5}>
                      {target ? getTargetName(target, language) : '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Dates) && (
                    <Section flex={1.5}>
                      {dates
                        ? formatDate(
                            dates.checkIn.date,
                            dates.checkOut.date,
                            'short'
                          )
                        : '–'}
                    </Section>
                  )}

                  {checkVisibility(Key.Room) && (
                    <Section flex={1.5}>
                      {room ? formatRoom(room) : '–'}
                    </Section>
                  )}
                </ParticipantWrapper>

                {nextServices.map(
                  ({
                    accommodationTarget: target,
                    dates,
                    id,
                    participantRoom,
                  }) => {
                    const room =
                      participantRoom?.roomReservation.request.room.number

                    return (
                      <FlexRow key={id} style={{ marginTop: '0.5rem' }}>
                        {checkVisibility(Key.Name) && (
                          <Section style={{ width: '12rem' }} />
                        )}

                        {checkVisibility(Key.Age) && <Section flex={0.5} />}

                        {checkVisibility(Key.Target) && (
                          <Section flex={1.5}>
                            {target ? getTargetName(target, language) : '–'}
                          </Section>
                        )}

                        {checkVisibility(Key.Dates) && (
                          <Section flex={1.5}>
                            {dates
                              ? formatDate(
                                  dates.checkIn.date,
                                  dates.checkOut.date,
                                  'short'
                                )
                              : '–'}
                          </Section>
                        )}

                        {checkVisibility(Key.Room) && (
                          <Section flex={1.5}>
                            {room ? formatRoom(room) : '–'}
                          </Section>
                        )}
                      </FlexRow>
                    )
                  }
                )}

                <GuestDetails data={participant} />
              </FlexColumn>
            )
          })}
        </FlexColumn>
      </FlexRow>
    </Wrapper>
  )
}
