import { Route, Switch, useRouteMatch } from 'react-router-dom'

import {
  useCreateCatalogProductMutation,
  useDeleteCatalogProductMutation,
} from '@/modules/Products/mutations'
import { CatalogProductList } from '@/modules/Products'
import { ElasticProductTitle } from '@/modules/Products/types'
import { Gutter } from '@/components/Layout'

export const ProductRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <Route component={WrappedProductList} exact path={path} />
    </Switch>
  )
}

const WrappedProductList = () => {
  const [createProductMutation] = useCreateCatalogProductMutation()
  const [deleteProductMutation] = useDeleteCatalogProductMutation()

  const handleCreateProduct = (
    product: ElasticProductTitle,
    catalogId: string
  ) => {
    const { id, name } = product

    return createProductMutation({
      variables: {
        input: {
          catalogId,
          categoryIds: [],
          name,
          productTitleIds: [id],
        },
      },
    })
      .then(({ data }) => data?.catalogProductCreate)
      .catch(() => undefined)
  }

  const handleDeleteProduct = (id: string) =>
    deleteProductMutation({ variables: { id } })
      .then(({ data }) => data?.catalogProductDelete.id)
      .catch(() => undefined)

  return (
    <Gutter type={[0, 5, 10]}>
      <CatalogProductList
        onItemCreate={handleCreateProduct}
        onItemRemove={handleDeleteProduct}
      />
    </Gutter>
  )
}
