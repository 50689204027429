import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'
import { useState } from 'react'

import { IconButton, LoaderWrapper } from '@/modules/Products/components'
import { DropdownButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { TargetType as TT } from '~generated-types'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

import { RoomOptionsModal } from './RoomOptionsModal'

type Props = {
  purchaseId: string
  quantity: number
  targetType: TT
}

export const ActionsDropdown = ({
  purchaseId,
  quantity,
  targetType,
}: Props) => {
  const { palette } = useTheme()
  const {
    deletePurchaseWithConfirm,
    extractPurchase,
    salesId,
    updatePurchase,
  } = useSalesProductManagerContext()

  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [isExtractToParticipantsOptions, setExtractToParticipantsOptions] =
    useState<boolean>(false)
  const [isRoomOptionsModalOpen, setRoomOptionsModalOpen] =
    useState<boolean>(false)
  const [selectedRooms, setSelectedRooms] = useState<
    { id: string; emptyBeds: number }[]
  >([])

  const handleDeletePurchase = () => {
    setProcessing(true)

    deletePurchaseWithConfirm(purchaseId).then(
      (res) => !res && setProcessing(false)
    )
  }

  const handleExtractPurcaseToRooms = () => {
    setRoomOptionsModalOpen(false)
    setProcessing(true)

    return extractPurchase({
      purchaseId,
      rooms: selectedRooms.map(({ id, emptyBeds }) => ({
        quantity: emptyBeds,
        roomReservationId: id,
      })),
    }).then((res) => {
      res && !res.source ? null : setProcessing(false)
      setSelectedRooms([])
    })
  }

  const handleExtractPurcaseToParticipants = (quantityAmount: number) => {
    setProcessing(true)

    extractPurchase({ purchaseId, quantity: quantityAmount }).then((res) => {
      res && !res.source ? null : setProcessing(false)
      setExtractToParticipantsOptions(false)
    })
  }

  const extractToParticipantsOptions = {
    label: (
      <>
        <Icon icon="user" size="xs" />
        <T>Products:ProductManager.action.extractOne</T>
      </>
    ),
    onClick: () => handleExtractPurcaseToParticipants(1),
    optional: {
      label: (
        <>
          <Icon icon="user-group" size="xs" />
          <T>Products:ProductManager.action.extractAll</T> ({quantity})
        </>
      ),
      onClick: () => handleExtractPurcaseToParticipants(quantity),
    },
  }

  const extractToParticipantsOption = {
    keepOpen: true,
    label: (
      <>
        <Icon icon="user" size="xs" />
        <T>Products:ProductManager.action.extractToParticipants</T>
      </>
    ),
    onClick: () => setExtractToParticipantsOptions(true),
  }

  const extractToRoomsOption = {
    label: (
      <>
        <Icon icon="door-open" size="xs" />
        <T>Products:ProductManager.action.extractToRooms</T>
      </>
    ),
    onClick: () => setRoomOptionsModalOpen(true),
  }

  const removeOption = {
    label: (
      <>
        <Icon icon="trash" size="xs" />
        <T>Products:ProductManager.action.deletePurchase</T>
      </>
    ),
    onClick: handleDeletePurchase,
  }

  const options =
    targetType === TT.PurchaseOrder
      ? [
          isExtractToParticipantsOptions
            ? extractToParticipantsOptions
            : extractToParticipantsOption,
          extractToRoomsOption,
          removeOption,
        ]
      : [removeOption]

  return (
    <ModalContainer
      isOpen={isRoomOptionsModalOpen}
      modal={
        <RoomOptionsModal
          handleExtract={handleExtractPurcaseToRooms}
          onClose={() => {
            setSelectedRooms([])
            setRoomOptionsModalOpen(false)
          }}
          purchaseId={purchaseId}
          quantity={quantity}
          salesId={salesId}
          selectedRooms={selectedRooms}
          setSelectedRooms={setSelectedRooms}
          updatePurchase={updatePurchase}
        />
      }
      onClose={() => {
        setSelectedRooms([])
        setRoomOptionsModalOpen(false)
      }}
      placement="bottom"
      referenceElement={({ ref }) => (
        <div ref={ref}>
          <DropdownButton
            onClose={() => setExtractToParticipantsOptions(false)}
            options={options}
            renderCustomButton={({ onClick }) =>
              isProcessing ? (
                <LoaderWrapper>
                  <ReactLoading
                    color={palette.smoke.dark}
                    height={18}
                    type="spin"
                    width={18}
                  />
                </LoaderWrapper>
              ) : (
                <IconButton onClick={onClick}>
                  <FontAwesomeIcon
                    color={palette.text.light}
                    icon="ellipsis-vertical"
                    size="lg"
                  />
                </IconButton>
              )
            }
            zIndex={10006}
          />
        </div>
      )}
      zIndex={10006}
    />
  )
}

/////////

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 12px;
    margin-right: 10px;
  }
`
