import { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTheme } from '@/theme'

import { SalesForDate, SalesForDateStats } from '../../../types'
import { CustomerInfo } from './CustomerInfo'
import { ListItemColumn } from './ListItemColumn'

interface Props {
  data: SalesForDate
  salesStats?: SalesForDateStats['visitStats']
  customerData?: NonNullable<SalesForDateStats['customer']>['customer']
  onOpen: () => void
}

export const ListItem: FC<Props> = ({
  data,
  salesStats,
  customerData,
  onOpen,
}): ReactElement => {
  const theme = useTheme()

  return (
    <Wrapper onClick={() => onOpen()}>
      <ListItemColumn
        noGrow
        noShrink
        style={{
          padding: `0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gutter}`,
        }}
      >
        <ExpansionButton
          onClick={(event) => {
            event.stopPropagation()
            onOpen()
          }}
        >
          <FontAwesomeIcon icon="angle-down" />
        </ExpansionButton>
      </ListItemColumn>
      <CustomerInfo
        data={data}
        salesStats={salesStats}
        customerData={customerData}
      />
    </Wrapper>
  )
}

////////////

const ExpansionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  outline: none;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1.5)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.main};
      border-color: ${theme.palette.primary.main};
    `}
  }
`

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
  `}

  border-bottom-width: 0;

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-width: 1px;
  }

  &:hover {
    border-bottom-width: 1px;
    margin-bottom: -1px;
    z-index: 500;

    ${({ theme }) => css`
      background-color: ${theme.palette.primary.extraLighter};
      border-color: ${theme.palette.primary.light};
    `}
  }
`
