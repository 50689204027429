import { gql } from '@apollo/client'

import { attachmentFragments } from '@/modules/Attachments'
import { customerFragments } from '@/modules/Registry'
import { salesFragments } from '@/modules/Sales'

export default gql`
  ${attachmentFragments.attachment}
  ${customerFragments.customerReference}
  ${salesFragments.salesAssignee}
  ${salesFragments.salesLifecycle}
  ${salesFragments.salesVst}

  query SalesDetails($id: ID!) {
    sales(id: $id) {
      accommodation {
        accommodationGroups {
          id
          status {
            reservedDetailed {
              beds
              extraBeds
              rooms
            }
          }
        }
        id
      }
      assignee {
        ...SalesAssignee
      }
      attachments {
        ...Attachment
      }
      auditLog {
        createdAt
      }
      commissionRate
      customer {
        ...CustomerReference
      }
      estimatedDates {
        end
        start
      }
      estimatedParticipants
      event {
        estimatedDates {
          start
          end
        }
        estimatedParticipants
        id
        name
        orderNumber
        probabilityEstimate
      }
      webshopSettings {
        id
        eventSettings {
          enrollEnd
          enrollStart
          visibility
          link
        }
      }
      facet {
        abbreviation
        color
        dimensionTarget
        features {
          defaultVisibility
          feature
        }
        id
        name
        participantsDefaults {
          groupedBy
          pageSize
          sort {
            field
            order
          }
        }
        probabilityEstimate
      }
      groups {
        label
        values
      }
      id
      language
      lifecycle {
        ...SalesLifecycle
      }
      locked
      name
      notes
      orderNumber
      paymentAgreement {
        code
        id
        name
      }
      probabilityEstimate
      probabilitySource
      reservationDates {
        end
        start
      }
      seller {
        id
      }
      tasks(input: { types: [SALES_READY_FOR_INVOICING] }) {
        id
        isOpen
        type
      }
      type
      vst {
        ...SalesVst
      }
    }
  }
`
