import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { CustomerDetails } from '@/modules/Order/types'
import { T } from '@/modules/Language'

type Props = {
  customer?: CustomerDetails
}

export const Customer = ({ customer }: Props) => (
  <FlexRow alignItems="flex-start" style={{ overflow: 'hidden' }}>
    {customer ? (
      <Column noPadding>
        <Row alignItems="center">
          {customer.type === 'ORGANIZATION' ? (
            <Icon icon={['far', 'building']} size="sm" />
          ) : (
            <Icon icon="user" size="xs" />
          )}
          <Label title={customer.name ?? ''}>{customer.name ?? '–'}</Label>
        </Row>

        {customer.type === 'ORGANIZATION' && customer.contactPerson && (
          <LightLabel title={customer.contactPerson}>
            {customer.contactPerson}
          </LightLabel>
        )}
      </Column>
    ) : (
      <Placeholder>
        <T>Orders:Customer.empty</T>
      </Placeholder>
    )}
  </FlexRow>
)

////////////

const Column = styled(FlexColumn)`
  font-weight: 500;
  overflow: hidden;
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    width: ${theme.spacing.gutter};
  `}
`

const Label = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${({ theme }) => css`
    padding-left: ${theme.spacing.gu(1)}rem;
  `}
`

const LightLabel = styled(Label)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding-left: ${theme.spacing.gu(3)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Row = styled(FlexRow)`
  line-height: 15px;
  overflow: hidden;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
