import styled, { css } from 'styled-components/macro'

import {
  ResourceReservationGroup as NullableResourceReservationGroup,
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { DataField } from '@/components/DataField'
import { FlexRow } from '@/components/Layout'
import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type ResourceReservationGroup = Exclude<NullableResourceReservationGroup, null>

type Props = {
  group: ResourceReservationGroup
  readOnly: boolean
  updateReservations: (reservations: Reservation[]) => void
}

export const Name = ({
  group: { id, name, number },
  readOnly,
  updateReservations,
}: Props) => {
  const { spacing, typography } = useTheme()

  const { setGroupName } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  return (
    <InlineModalIconSection icon="user-group">
      <FlexRow
        alignItems="baseline"
        style={{ fontSize: typography.fontSizeBase2 }}
      >
        #{number}
        <DataFieldWrapper>
          <DataField
            disabled={readOnly}
            handleSubmit={(value) => setGroupName(id, value || '')}
            hideEditIcon
            inputType="text"
            lineHeight="1.3"
            placeholder={
              <NamePlaceholder>
                <T>ReservationModal:group.title</T>
              </NamePlaceholder>
            }
            value={name || ''}
            variant="primary"
            size={typography.fontSizeBase2}
            style={{ width: '100%' }}
            valueStyle={{
              height: '30px',
              padding: `${spacing.gutterSmall} ${spacing.gu(1)}rem`,
            }}
          />
        </DataFieldWrapper>
      </FlexRow>
    </InlineModalIconSection>
  )
}

////////

const NamePlaceholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const DataFieldWrapper = styled.div`
  width: 100%;

  input {
    &:not([type='checkbox']):not([type='radio']) {
      height: 30px;

      ${({ theme }) => css`
        font-size: ${theme.typography.fontSizeBase2};
      `}
    }
  }
`
