import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import uniqBy from 'lodash.uniqby'

import {
  ParticipantRoom,
  useRoomReservationsContext,
} from '@/modules/FrontDesk'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

import { Button } from '../common'
import { ParticipantsModal } from './ParticipantsModal'

type Props = {
  participantRooms: ParticipantRoom[]
  id: string
}

export const Participants = ({ participantRooms, id }: Props) => {
  const { palette } = useTheme()
  const { setTargetId } = useRoomReservationsContext()

  const participants = uniqBy(
    participantRooms.map(({ participant }) => participant),
    'id'
  )

  const [isOpen, setOpen] = useState<boolean>(false)

  const handleOpenModal = () => {
    setOpen(true)
    setTargetId(id)
  }

  const handleCloseModal = () => {
    setOpen(false)
    setTargetId(null)
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={
        <ParticipantsModal
          onClose={handleCloseModal}
          participantRooms={participantRooms}
        />
      }
      onClose={handleCloseModal}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <Button
          ref={ref as Ref<HTMLButtonElement> | undefined}
          onClick={handleOpenModal}
          disabled={!participants.length}
          width="40px"
        >
          <ParticipantCount>{participants.length}</ParticipantCount>
          <FontAwesomeIcon color={palette.text.light} icon="user" size="sm" />
        </Button>
      )}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}

////////////

const ParticipantCount = styled.span`
  margin-right: 5px;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `};
`
