import { DateRange, Owner } from '@/common/types'
import { Feature, SalesState, SalesType } from '~generated-types'
import { ElasticEmbeddedCustomer } from '@/modules/Registry/Customer'

export const OPEN_STATES = [
  SalesState.Closed,
  SalesState.Confirmed,
  SalesState.Open,
]

export type Facet = {
  id: string
  name: string
  abbreviation: string
  color: string
  dimensionTarget: string
  features: Feature[]
}

type EventExtension = {
  enrollSettings: {
    enrollActive: boolean
    enrollEnd: string | null | undefined
    enrollStart: string | null | undefined
  }
}

type ContextEvent = {
  customerId: string
  customerName: string
  customerNumber: number
  customerType: string
  estimatedDates: DateRange | null | undefined
  id: string
  name: string
  number: number
  type: string
}

type ElasticSalesPaymentAgreement = {
  id: string
  code: string
  name: string
}

export type ElasticSales = ElasticEmbeddedCustomer & {
  contextEvent: ContextEvent | null | undefined
  estimatedDates: DateRange | null | undefined
  reservationDates: DateRange | null | undefined
  event: EventExtension | null | undefined
  facet: Facet
  id: string
  name: string | null | undefined
  number: number
  orderNumber: number
  owner: Owner | null | undefined
  participants: number | null | undefined
  participantNames: string[]
  seller: string | null | undefined
  sellerShortName: string | null | undefined
  state: SalesState
  type: SalesType
  paymentAgreement: ElasticSalesPaymentAgreement | null
  _type: 'salesdocument'
}

export type ElasticSalesContext = ElasticEmbeddedCustomer & {
  estimatedDates: DateRange | null | undefined
  facet: Facet
  id: string
  name: string | null | undefined
  number: number | null | undefined
  searchDates: DateRange | null | undefined
  state: SalesState
  type: SalesType
}

export enum SearchListContext {
  SALES = 'SALES',
  ENROLLMENT = 'ENROLLMENT',
  INVOICE = 'INVOICE',
}
