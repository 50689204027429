import { ReactNode, Ref } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

import { getActionColor, getActionIcon, IconWrapper, Label } from './utils'
import { ActionType } from './types'

type ActionPlaceholderProps = {
  action: ActionType
  innerRef: Ref<HTMLDivElement>
  label: ReactNode
}

export const ActionPlaceholder = ({
  action,
  label,
  innerRef,
  ...rest
}: ActionPlaceholderProps) => {
  const { palette } = useTheme()

  return (
    <Placeholder
      alignItems="center"
      color={getActionColor(action, palette)}
      ref={innerRef}
      {...rest}
    >
      <IconWrapper>
        <FontAwesomeIcon
          fixedWidth
          icon={getActionIcon(action) as IconProp}
          size={action === 'DELETE' ? 'xs' : 'sm'}
        />
      </IconWrapper>
      <Label>{label}</Label>
    </Placeholder>
  )
}

//////

const Placeholder = styled(FlexRow)<{ color: string }>`
  color: ${({ color }) => color};
  cursor: not-allowed;
  font-weight: 600;
  opacity: 0.5;
  text-transform: uppercase;
  width: fit-content;
  white-space: nowrap;

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    letter-spacing: ${0.05 * theme.spacing.gu(1)}rem;
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
