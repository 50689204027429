import { gql, useMutation } from '@apollo/client'

import {
  SetSalesCustomerContactMutation,
  SetSalesCustomerContactMutationVariables,
} from '~generated-types'

export type {
  SetSalesCustomerContactMutation,
  SetSalesCustomerContactMutationVariables,
}

export const SET_SALES_CUSTOMER_CONTACT_MUTATION = gql`
  mutation SetSalesCustomerContact($input: SalesSetCustomerContactInput!) {
    salesSetCustomerContact(input: $input) {
      sales {
        id
      }
    }
  }
`

export function useSetSalesCustomerContactMutation() {
  return useMutation<
    SetSalesCustomerContactMutation,
    SetSalesCustomerContactMutationVariables
  >(SET_SALES_CUSTOMER_CONTACT_MUTATION)
}
