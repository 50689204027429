import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ReactLoading from 'react-loading'
import { useApolloClient } from '@apollo/client'

import { DropdownButton, InnocuousButton } from '@/components/ExtraButtons'
import { T, translate } from '@/modules/Language'
import { documentHooks } from '@/modules/Document'
import { notify } from '@/components/NotificationService'
import { salesHooks } from '@/modules/Sales'
import { useTheme } from '@/theme'

import { ExcelImport } from './ExcelImport'
import generateParticipantExcel from './generateParticipantExcel'
import { PrintableParticipantsList } from './PrintableParticipantsList'

type Props = {
  ownerId: string
}

export const DocumentsButton = ({ ownerId }: Props) => {
  const client = useApolloClient()
  const { palette } = useTheme()
  const { documentsByTemplateType, loading: loadingDocuments } =
    documentHooks.useOwnerDocuments({
      ownerId,
    })
  const {
    data: { language },
  } = salesHooks.useSalesDetailsContext()

  const [importModalOpen, setImportModalOpen] = useState<boolean>(false)
  const [printModalOpen, setPrintModalOpen] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const documents = [...(documentsByTemplateType['PARTICIPANT_LIST'] || [])]

  const handleProcess = (processFn: () => Promise<void>) => {
    setProcessing(true)

    processFn()
      .catch(() =>
        notify({ content: <T>common:error.common</T>, type: 'ERROR' })
      )
      .finally(() => setProcessing(false))
  }

  const handleDownloadParticipantExcel = () =>
    handleProcess(() =>
      generateParticipantExcel({
        client,
        salesId: ownerId,
        translateFn: (keys) => translate(keys, language),
      })
    )

  const renderLabel = (label: string | ReactNode, icon: IconProp) => (
    <>
      <Icon icon={icon} />
      {label}
    </>
  )

  const documentOptions = [
    ...documents.map(({ document: doc, downloadPDF, printPDF }) => ({
      disabled: processing,
      label: renderLabel(
        <>
          {doc.name} <small>(PDF)</small>
        </>,
        'print'
      ),
      onClick: () => handleProcess(printPDF),
      optional: {
        disabled: processing,
        label: <FontAwesomeIcon icon="download" />,
        onClick: () => handleProcess(downloadPDF),
      },
    })),
  ]

  const options = [
    {
      disabled: processing,
      label: renderLabel(<T>ParticipantsList:PrintableList.action</T>, 'print'),
      onClick: () => setPrintModalOpen(true),
    },
    ...documentOptions,
    {
      disabled: processing,
      label: renderLabel('Excel', ['far', 'file-excel']),
      onClick: handleDownloadParticipantExcel,
    },
    {
      disabled: processing,
      label: renderLabel(
        <T>ParticipantsList:Import.importParticipants</T>,
        'file-import'
      ),
      onClick: () => setImportModalOpen(true),
    },
  ]

  return (
    <>
      <DropdownButton
        options={options}
        dropdownPlacement="bottom-start"
        renderCustomButton={({ onClick }) => (
          <IconButtonWrapper>
            {processing || loadingDocuments ? (
              <ReactLoading
                color={palette.smoke.dark}
                height={18}
                type="spin"
                width={18}
              />
            ) : (
              <IconButton noNudge onClick={onClick}>
                <FontAwesomeIcon icon="ellipsis-vertical" size="lg" />
              </IconButton>
            )}
          </IconButtonWrapper>
        )}
      />

      {importModalOpen && (
        <ExcelImport closeModal={() => setImportModalOpen(false)} />
      )}

      {printModalOpen && (
        <PrintableParticipantsList
          onClose={() => setPrintModalOpen(false)}
          salesId={ownerId}
        />
      )}
    </>
  )
}

//////

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 20px;
    margin-right: 10px;
  }
`

const IconButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(5)}rem;
  `}
`

const IconButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    width: ${theme.spacing.gu(5)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
