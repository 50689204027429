import { CategorySelector, DataSearch } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FilterSection } from '@/components/ElasticFilterSearchList'

type Props = {
  componentIds: { [key: string]: string }
  getDefaultQuery: () => Record<string, unknown>
}

export const ListFilters = ({ componentIds, getDefaultQuery }: Props) => {
  const { language } = useLanguageContext()

  return (
    <>
      <FilterSection
        label={<T>Products:CatalogProductList.filter.search</T>}
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH}
            dataField={['name']}
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'Products:CatalogProductList.filter.search',
              language
            )}
            placeholder={translate(
              `Products:CatalogProductList.filter.searchPlaceholder`,
              language
            )}
            queryFormat={'and'}
            showClear
            showIcon
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Products:CatalogProductList.filter.categories</T>}
        render={({ isCollapsed }) => (
          <CategorySelector
            categoryTargetKey="PRODUCT_LIST"
            componentId={componentIds.CATEGORY}
            dataField="category.path"
            defaultQuery={getDefaultQuery}
            filterLabel={translate(
              'Products:CatalogProductList.field.category',
              language
            )}
            hideEmpty
            isCollapsed={isCollapsed}
          />
        )}
      />
    </>
  )
}
