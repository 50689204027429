import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { CustomerDetails } from '@/modules/Order/types'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { parseExtraDetails, parseName } from '../utils'
import { Customer } from '../types'

type Props = {
  customer: Customer | CustomerDetails
  hideDetails?: boolean
  hidePublicName?: boolean
}

export const CustomerDisplayName = ({
  customer,
  hideDetails,
  hidePublicName,
}: Props) => {
  const { palette, spacing } = useTheme()

  const name = parseName(customer)
  const details = parseExtraDetails(customer)
  const isOrganization = customer.__typename === 'CustomerOrganization'

  return (
    <FlexColumn>
      <FlexRow>
        <CustomerIcon
          color={palette.text.lighter}
          icon={isOrganization ? ['far', 'building'] : 'user'}
          size="1x"
        />
        <FlexColumn>
          <FlexRow>
            {name ? (
              <>
                {name}
                <FontColor
                  lighter
                  style={{ marginLeft: `${spacing.gu(1)}rem` }}
                >
                  #{customer.customerNumber}
                </FontColor>
              </>
            ) : (
              <Placeholder>
                <T>Customers:unnamed</T>
                <FontColor
                  lighter
                  style={{ marginLeft: `${spacing.gu(1)}rem` }}
                >
                  #{customer.customerNumber}
                </FontColor>
              </Placeholder>
            )}
          </FlexRow>

          {!hideDetails && details && (
            <FontColor lighter style={{ marginTop: spacing.gutterSmall }}>
              {details}
            </FontColor>
          )}
        </FlexColumn>
      </FlexRow>

      {!hidePublicName &&
        customer.__typename !== 'ParsedCustomerDetails' &&
        customer.publicName && (
          <FlexRow style={{ marginTop: `${spacing.gu(1)}rem` }}>
            <CustomerIcon icon="user-secret" color={palette.danger.main} />
            {customer.publicName}
          </FlexRow>
        )}
    </FlexColumn>
  )
}

////////////

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const CustomerIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(0.5)}rem;
  `}
`
