import styled, { css } from 'styled-components/macro'
import uniqBy from 'lodash.uniqby'
import { useMemo } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'
import { Label } from '@/components/FormControls'
import { Reservation } from '@/modules/FrontDesk'
import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'

import { PaymentAgreementRow } from './PaymentAgreementRow'

type Props = {
  reservation: Reservation
}

export const PaymentAgreementModal = ({
  reservation: { participantRooms, sales },
}: Props) => {
  const participantsSales = useMemo(() => {
    if (sales.type === SalesType.Sales) {
      return []
    }

    const allSales = participantRooms.map((r) => r.participant.sales)

    return uniqBy(allSales, 'id')
  }, [participantRooms, sales])

  return (
    <Modal>
      <FlexColumn noPadding>
        <FlexRow>
          {sales.type !== SalesType.Sales && (
            <>
              <HeaderLabel width={36}>
                <T>FrontDesk:DailyRoomReservations.sales.title</T>
              </HeaderLabel>
              <Spacer />
            </>
          )}
          <HeaderLabel width={22}>
            <T>FrontDesk:DailyRoomReservations.payment.agreement</T>
          </HeaderLabel>
          <Spacer />
          <HeaderLabel>
            <T>FrontDesk:DailyRoomReservations.payment.status</T>
          </HeaderLabel>
        </FlexRow>

        {/* Sales or Event payment agreement */}
        <PaymentAgreementRow sales={sales} />

        {/* Enrollments payment agreements */}
        {participantsSales.map((sales) => (
          <PaymentAgreementRow key={sales.id} sales={sales} />
        ))}
      </FlexColumn>
    </Modal>
  )
}

////////////

const HeaderLabel = styled(Label)<{ width?: number }>`
  ${({ theme, width }) => css`
    ${width && `width: ${theme.spacing.gu(width)}rem`};
  `};
`

const Modal = styled(InlineModal)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `};
`

const Spacer = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `};
`
