import { gql, useQuery } from '@apollo/client'

import {
  UseTaskQuery as QueryData,
  UseTaskQueryVariables as QueryVariables,
} from '~generated-types'
import { taskFragments } from '@/modules/Task'

import { Task } from '../types'

const QUERY = gql`
  ${taskFragments.task}

  query UseTask($number: Int!) {
    taskByNumber(number: $number) {
      ...Task
    }
  }
`

type Params = {
  number: number
}

export function useTask({ number }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { number },
    }
  )

  const task: Task | null = data ? data.taskByNumber : null

  return {
    error,
    loading,
    refetch,
    task,
  }
}

export default useTask
