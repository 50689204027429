import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import { useState } from 'react'

import {
  Reservation as ReservationType,
  resourceReservationHooks,
  SalesResourceReservationForGroup,
} from '@/modules/Reservations/ResourceReservation'
import { FlexRow } from '@/components/Layout'
import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { IconButton, LoaderWrapper } from '../../components/common'
import { getDateRange } from './utils'

type Props = {
  readOnly: boolean
  reservation: SalesResourceReservationForGroup
  updateReservations: (reservations: ReservationType[]) => void
  updateStateAfterRemoving: (reservationId: string) => void
}

export const Reservation = ({
  readOnly,
  reservation: { end, id, resource, start },
  updateReservations,
  updateStateAfterRemoving,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { setToGroup } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isRemoving, setRemoving] = useState<boolean>(false)

  const handleRemoveReservationFromGroup = () => {
    setRemoving(true)

    return setToGroup(id, null).then(() => {
      updateStateAfterRemoving(id)
      setRemoving(false)
    })
  }

  return (
    <InlineModalIconSection icon={['far', 'calendar-check']} key={id}>
      <FlexRow
        alignItems="center"
        flex={1}
        style={{ height: spacing.gutterBig }}
      >
        <FlexRow alignItems="center" justifyContent="space-between" flex={1}>
          <ResourceName>
            {resource?.name ?? <T>ResourceReservations:resource.program</T>}
          </ResourceName>
          <DateRange>{getDateRange(end, start)}</DateRange>
        </FlexRow>
        {!readOnly && (
          <IconButtonWrapper>
            {isRemoving ? (
              <LoaderWrapper>
                <ReactLoading
                  color={palette.smoke.dark}
                  height={18}
                  type="spin"
                  width={18}
                />
              </LoaderWrapper>
            ) : (
              <IconButton
                disabled={readOnly}
                onClick={handleRemoveReservationFromGroup}
                style={{ height: spacing.gutterBig }}
              >
                <FontAwesomeIcon
                  color={palette.danger.main}
                  fixedWidth
                  icon="trash"
                  size="sm"
                />
              </IconButton>
            )}
          </IconButtonWrapper>
        )}
      </FlexRow>
    </InlineModalIconSection>
  )
}

////////

const DateRange = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const IconButtonWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    margin-left: ${theme.spacing.gutter};
  `}
`

const ResourceName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(20)}rem;
  `}
`
