import { ChangeEvent } from 'react'

import { FlexColumn } from '@/components/Layout'
import { InlineModalSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'

import { Input, InputWrapper, Title } from './common'

type Props = {
  disabled: boolean
  reference: string
  setReference: (reference: string) => void
}

export const ReferenceInput = ({
  disabled,
  reference,
  setReference,
}: Props) => (
  <InlineModalSection style={{ marginTop: 0 }}>
    <FlexColumn flex={1} noPadding>
      <Title>
        <T>Orders:Payments.field.reference</T>
      </Title>

      <InputWrapper>
        <Input
          disabled={disabled}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setReference(e.target.value)
          }
          showBorder
          value={reference}
        />
      </InputWrapper>
    </FlexColumn>
  </InlineModalSection>
)
