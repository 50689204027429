import styled, { css } from 'styled-components/macro'

import { Palette, useTheme } from '@/theme'
import { FlexColumn } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { T } from '@/modules/Language'

type PriceType = 'LEFT_TO_PAY' | 'RECEIVED' | 'TOTAL'

type Props = {
  price: number
  type: PriceType
}

export const Price = ({ price, type }: Props) => {
  const { palette } = useTheme()

  return (
    <Wrapper alignItems="flex-end" noPadding>
      <Title>
        <T>{`Orders:Header.section.${type}`}</T>
      </Title>

      <PriceLabel color={getPriceColor(price, type, palette)}>
        {formatCurrency(price)} €
      </PriceLabel>
    </Wrapper>
  )
}

////////////

const getPriceColor = (price: number, type: PriceType, palette: Palette) => {
  switch (type) {
    case 'LEFT_TO_PAY':
      return price === 0 ? palette.text.light : palette.danger.dark
    case 'RECEIVED':
      return price === 0 ? palette.text.light : palette.success.dark
    case 'TOTAL':
    default:
      return palette.text.main
  }
}

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(16)}rem;
  `}
`

const PriceLabel = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const Title = styled.small`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gutterSmall};
  `}
`
