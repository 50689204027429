import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { PaymentType as PaymentMethod } from '~generated-types'
import { T } from '@/modules/Language'

import { Button, Icon, Label } from './common'
import { CreditCardPaymentButton, VoucherPaymentButton } from './components'
import { PaymentType } from '../../types'

type Props = {
  paymentType: PaymentType | null
  pointOfSaleId: string | null
  setPaymentType: (paymentType: PaymentType | null) => void
}

export const PaymentTypesSection = ({
  paymentType,
  pointOfSaleId,
  setPaymentType,
}: Props) => {
  const handleSetPaymentType = (method: PaymentMethod) =>
    setPaymentType(paymentType?.method !== method ? { method } : null)

  return (
    <Wrapper>
      <FlexRow wrap="wrap">
        <Button
          isSelected={paymentType?.method === PaymentMethod.Cash}
          onClick={() => handleSetPaymentType(PaymentMethod.Cash)}
        >
          <Icon fixedWidth icon="wallet" />
          <Label>
            <T>Orders:Payments.type.CASH</T>
          </Label>
        </Button>

        <Spacer />

        <Button
          // Gift card payment method is currently unavailable
          disabled
          isSelected={paymentType?.method === PaymentMethod.GiftCard}
          onClick={() => handleSetPaymentType(PaymentMethod.GiftCard)}
        >
          <Icon fixedWidth icon="gift" />
          <Label>
            <T>Orders:Payments.type.GIFT_CARD</T>
          </Label>
        </Button>

        <CreditCardPaymentButton
          paymentType={paymentType}
          pointOfSaleId={pointOfSaleId}
          setPaymentType={setPaymentType}
        />

        <Spacer />

        <VoucherPaymentButton
          paymentType={paymentType}
          setPaymentType={setPaymentType}
        />
      </FlexRow>
    </Wrapper>
  )
}

///////

const Spacer = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}
`
