import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import styled, { css } from 'styled-components'
import { draftToMarkdown } from 'markdown-draft-js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { useState } from 'react'

import { FlexColumn, Gutter } from '@/components/Layout'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { MarkdownCss, RichTextEditor } from '@/components/RichTextEditor'
import { Button } from '@/components/Button'
import { FontColor } from '@/components/Typography'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { usePublishContext } from '../../../PublishState'

export const CustomerInformation = () => {
  const { palette } = useTheme()
  const { readOnly, salesWebshopSettings, salesId, updateWebshopSettings } =
    usePublishContext()

  const savedCustomerInformation = salesWebshopSettings?.customerInformation

  const parsedInformation: Record<string, unknown> | null = (() => {
    if (
      savedCustomerInformation !== null &&
      savedCustomerInformation !== undefined
    ) {
      return JSON.parse(savedCustomerInformation)
    }

    return null
  })()

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [showForm, setShowForm] = useState<boolean>(false)

  const editorContent = parsedInformation
    ? // @ts-ignore
      EditorState.createWithContent(convertFromRaw(parsedInformation))
    : EditorState.createEmpty()

  function cancelChanges() {
    setEditorState(editorContent)
  }

  // Set customer information
  const handleUpdateInformation = () => {
    const editorContentAsJSON = convertToRaw(editorState.getCurrentContent())

    updateWebshopSettings({
      customerInformation: {
        information: JSON.stringify(editorContentAsJSON),
        salesId,
      },
    })
  }

  const onCancel = () => {
    setShowForm(false)
    cancelChanges()
  }

  const onOpenEditor = () => {
    if (readOnly) {
      return
    }
    setEditorState(editorContent)
    setShowForm(true)
  }

  const onSave = () => {
    setShowForm(false)
    handleUpdateInformation()
  }

  const markdown: string = parsedInformation
    ? // @ts-ignore
      draftToMarkdown(parsedInformation)
    : ''

  return (
    <NoteWrapper>
      <MarkdownCss />

      <FlexColumn style={{ height: '100%' }} className="markdown-wrapper">
        <ModalContainer
          isOpen={showForm}
          modal={
            <InlineModal style={{ minWidth: '450px' }}>
              <RichTextEditorWrapper>
                <RichTextEditor
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
              </RichTextEditorWrapper>

              <InlineModalSection style={{ margin: 0, padding: 0 }}>
                <RichTextEditorActions flex={1} type={[1]}>
                  <Button color="primary" onClick={onSave} size="small">
                    <T>common:action.save</T>
                  </Button>
                  <Button onClick={onCancel} size="small">
                    <T>common:action.cancel</T>
                  </Button>
                </RichTextEditorActions>
              </InlineModalSection>
            </InlineModal>
          }
          onClose={() => setShowForm(false)}
          placement="bottom-end"
          referenceElement={({ ref }) => (
            <ClickableRegionWrapper ref={ref}>
              <ClickableRegion
                onClick={onOpenEditor}
                readOnly={readOnly}
                role="button"
              >
                {!readOnly && (
                  <EditButton className="edit-button">
                    <FontAwesomeIcon
                      icon="pen"
                      color={palette.text.light}
                      style={{ fontSize: '0.8em' }}
                    />
                  </EditButton>
                )}

                <Gutter type={[2, 6, 1, 2]}>
                  {markdown === '' ? (
                    <FontColor lighter>
                      <T>Publish:Basic.customerInformation.add</T>
                    </FontColor>
                  ) : (
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      children={markdown}
                    />
                  )}
                </Gutter>
              </ClickableRegion>
            </ClickableRegionWrapper>
          )}
          zIndex={10003}
        />
      </FlexColumn>
    </NoteWrapper>
  )
}

////////////

const ClickableRegion = styled.div<{ readOnly?: boolean }>`
  min-height: 100%;
  cursor: default;

  ${({ readOnly, theme }) =>
    !readOnly &&
    `
    cursor: pointer; 
    border-radius: 6px;

    &:hover {
      background: ${theme.palette.smoke.lighter};
    }

    & .edit-button-detail-label {
      display: none;
      margin-right: ${theme.spacing.gu(1)}rem;
    }

    &:hover .edit-button-detail-label {
      display: inline;
    }
  `}

  ${({ readOnly, theme }) =>
    readOnly &&
    `
    color: ${theme.palette.text.light};
    cursor: not-allowed; 
  `}
`

const ClickableRegionWrapper = styled.div`
  height: 100%;
  overflow: auto;
`

const EditButton = styled.button`
  background: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.8em;
  position: absolute;

  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
    right: ${theme.spacing.gu(1.5)}rem;
    top: ${theme.spacing.gu(1.5)}rem;
  `}
`

const NoteWrapper = styled.div`
  flex: 1;
  border-radius: 6px;
  position: relative;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background: ${theme.palette.white};
    max-height: ${theme.spacing.gu(40)}rem;
  `}
`

const RichTextEditorActions = styled(Gutter)`
  border-radius: 0 0 6px 6px;

  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.main};
    background: ${theme.palette.smoke.light};
  `}
`

const RichTextEditorWrapper = styled(InlineModalSection)`
  margin: 0;
  min-height: 220px;
  padding: 0;
`
