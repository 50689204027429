import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React from 'react'

import { RoomCleaningStatus } from '~generated-types'
import { useTheme } from '@/theme'

type Props = {
  checkInDate: string
  checkOutDate: string
  cleaningStatus: RoomCleaningStatus | null
}

export const CleaningStatus = ({
  checkInDate,
  checkOutDate,
  cleaningStatus,
}: Props) => {
  const theme = useTheme()

  return moment().isSame(checkInDate, 'day') ||
    moment().isSame(checkOutDate, 'day') ? (
    <FontAwesomeIcon
      color={
        cleaningStatus
          ? cleaningStatus === RoomCleaningStatus.Clean
            ? theme.palette.success.main
            : theme.palette.danger.main
          : theme.palette.smoke.dark
      }
      fixedWidth
      icon="broom"
      size="sm"
    />
  ) : moment().isAfter(checkInDate, 'day') &&
    moment().isBefore(checkOutDate, 'day') ? (
    <FontAwesomeIcon
      color={theme.palette.coal.light}
      fixedWidth
      icon="user"
      size="sm"
    />
  ) : null
}
