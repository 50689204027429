import { useRef, useState } from 'react'

import {
  ButtonCell,
  Spreadsheet,
  SpreadsheetContentPlaceholder,
  SpreadsheetTBody,
  SpreadsheetTH,
  SpreadsheetTR,
} from '@/components/Spreadsheet'
import {
  Customer,
  CustomerAddress,
  CustomerAddressInput,
  customerHooks,
} from '@/modules/Registry/Customer'
import { FormSectionTitle } from '@/components/FormWrappers'
import { generateCompareFn } from '@/utils/arrays'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

import { AddressRow } from './AddressRow'

type Props = {
  customer: Customer
  onSelect?: (id: string | null | undefined) => void
  selected: string | null | undefined
}

export const Addresses = ({
  customer: { addresses, customerNumber, defaultAddressId, id },
  onSelect,
  selected,
}: Props) => {
  const { createAddress, removeAddress, setDefaultAddress, updateAddress } =
    customerHooks.useCustomer({ customerNumber })

  const [adding, setAdding] = useState<boolean>(false)
  const [focusTarget, setFocusTarget] = useState<
    null | 'ADD_BUTTON' | [number, number]
  >(null)

  const buttonEl = useRef<HTMLButtonElement>(null)

  const onPressCellKey = (rowIdx: number, columnIdx: number, key: string) => {
    if (key === 'ArrowDown' || key === 'Enter') {
      setFocusTarget([rowIdx + 1, columnIdx])
    }

    if (key === 'ArrowUp') {
      setFocusTarget([rowIdx - 1, columnIdx])
    }
  }

  const handleAdd = () => {
    if (!adding) {
      setAdding(true)

      createAddress(id, customerNumber, { postalAddress: {} }).finally(() =>
        setAdding(false)
      )
    }
  }

  const handleRemove = (addressId: string) => removeAddress(id, addressId)

  const handleSetAsDefault = (addressId: string) =>
    setDefaultAddress(id, addressId)

  const handleUpdate = (
    { id: addressId, label, postalAddress: currentAddress }: CustomerAddress,
    attribute: string,
    value: string
  ) => {
    const { address1, address2, city, country, postcode } = currentAddress || {}
    const postalAddress = {
      address1,
      address2,
      city,
      country,
      postcode,
    }
    const address: CustomerAddressInput =
      attribute === 'label'
        ? {
            label: value,
            postalAddress,
          }
        : {
            label,
            postalAddress: {
              ...postalAddress,
              [attribute]: value,
            },
          }

    return updateAddress(id, addressId, address)
  }

  const headerCells = [
    <SpreadsheetTH key="address-list-label">
      <T>Customers:address.label</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="address-list-address1">
      <T>Customers:address.address1</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="address-list-address2">
      <T>Customers:address.address2</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="address-list-postcode" forcedWidth={110}>
      <T>Customers:address.postcode</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="address-list-city">
      <T>Customers:address.city</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="address-list-country" forcedWidth={120}>
      <T>Customers:address.country</T>
    </SpreadsheetTH>,
    <SpreadsheetTH key="address-list-actions" forcedWidth={60} />,
  ]

  return (
    <>
      <FormSectionTitle>
        <T>Customers:addresses.title</T>
      </FormSectionTitle>

      <Spreadsheet
        headerCells={
          !!onSelect
            ? [
                <SpreadsheetTH key="address-list-selector" forcedWidth={20} />,
                ...headerCells,
              ]
            : headerCells
        }
      >
        {addresses.length ? (
          <SpreadsheetTBody>
            {[...addresses]
              .sort(generateCompareFn(['label', 'id']))
              .map((i, rowIdx) => (
                <AddressRow
                  data={i}
                  key={i.id}
                  focusedColumn={
                    Array.isArray(focusTarget) && focusTarget[0] === rowIdx + 1
                      ? focusTarget[1]
                      : null
                  }
                  isDefault={defaultAddressId === i.id}
                  isSelected={selected === i.id}
                  onPressKey={(columnIdx, key) =>
                    onPressCellKey(rowIdx + 1, columnIdx, key)
                  }
                  onRemove={() => handleRemove(i.id)}
                  onSelect={onSelect ? () => onSelect(i.id) : undefined}
                  onSetAsDefault={() => handleSetAsDefault(i.id)}
                  onUpdate={(attribute: string, value: string) =>
                    handleUpdate(i, attribute, value)
                  }
                />
              ))}
            <SpreadsheetTR>
              <ButtonCell
                busy={adding}
                colSpan={
                  !!onSelect ? headerCells.length + 1 : headerCells.length
                }
                disabled={adding}
                innerRef={buttonEl}
                onClick={handleAdd}
                onPressKey={(key) => {
                  key === 'ArrowUp' && setFocusTarget([addresses.length - 1, 0])
                }}
              >
                + <T>Customers:addresses.add</T>
              </ButtonCell>
            </SpreadsheetTR>
          </SpreadsheetTBody>
        ) : (
          <SpreadsheetContentPlaceholder
            colSpan={!!onSelect ? headerCells.length + 1 : headerCells.length}
            content={<T>Customers:addresses.empty</T>}
            helper={
              <InnocuousButton onClick={handleAdd}>
                + <T>Customers:addresses.add</T>
              </InnocuousButton>
            }
            size="small"
          />
        )}
      </Spreadsheet>
    </>
  )
}
