import styled from 'styled-components/macro'

import { defaultTheme } from '@/theme'
import { T } from '@/modules/Language'

export const RefreshModal = () => (
  <Wrapper>
    <Modal>
      <Title>
        <T>common:error.auth.title</T>
      </Title>
      <span>
        <T>common:error.auth.description</T>
      </span>
      <ButtonWrapper>
        <Button onClick={() => window.location.reload()}>
          <T>common:error.auth.refresh</T>
        </Button>
      </ButtonWrapper>
    </Modal>
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: rgba(4, 14, 31, 0.4);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 20010;
`

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  background: white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
    rgba(0, 0, 0, 0.12) 0px 9px 46px 8px, rgba(0, 0, 0, 0.2) 0px 11px 15px -7px;
  color: ${defaultTheme.palette.text.main};
  font-size: 1.1rem;
  padding: 20px;
`

const Title = styled.span`
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 12px;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 12px;
`

const Button = styled.button`
  background: transparent;
  border: none;
  border-radius: 6px;
  color: ${defaultTheme.palette.primary.main};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: -12px;
  margin-right: -12px;
  padding: 8px 12px;
  text-transform: uppercase;

  &:hover {
    background: ${defaultTheme.palette.smoke.light};
  }

  &:focus {
    background: ${defaultTheme.palette.smoke.main};
  }
`
