import styled, { css } from 'styled-components/macro'
import moment from 'moment'
import ReactLoading from 'react-loading'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { getQuantityUnit } from '@/modules/Products/utils'
import { QuantityUnit as QU } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  ListControls,
  Placeholder,
  SalesProduct,
  SalesPurchase,
} from './components'
import { ListViewMode } from './types'
import { ProductPurchase } from '../../types'
import { useSalesProductListContext } from './SalesProductListState'

type SalesPurchaseWithQuantityUnit = {
  purchase: ProductPurchase
  quantityUnit: QU
}

export const SalesProductList = () => {
  const { palette } = useTheme()

  const { error, listViewMode, loading, products } =
    useSalesProductListContext()

  if (loading) {
    return (
      <ReactLoading
        color={palette.smoke.main}
        height={24}
        type="spin"
        width={24}
      />
    )
  }

  if (error) {
    return (
      <Placeholder>
        – <T>Products:SalesProductList.error</T> –
      </Placeholder>
    )
  }

  const sortedProducts = [...products].sort(
    (a, b) =>
      moment(a.auditLog.createdAt).valueOf() -
      moment(b.auditLog.createdAt).valueOf()
  )

  const sortedPurchases = [
    ...products.reduce(
      (acc: SalesPurchaseWithQuantityUnit[], { purchases, items }) => [
        ...acc,
        ...purchases.map((p) => ({
          purchase: p,
          quantityUnit: getQuantityUnit(items),
        })),
      ],
      []
    ),
  ].sort(
    (a, b) =>
      moment(a.purchase.auditLog.createdAt).valueOf() -
      moment(b.purchase.auditLog.createdAt).valueOf()
  )

  return (
    <FlexColumn>
      <ListControlsWrapper>
        <ListControls isEmpty={!products.length} />
      </ListControlsWrapper>

      {listViewMode === ListViewMode.Purchases &&
        (sortedPurchases.length ? (
          sortedPurchases.map(({ purchase, quantityUnit }, idx) => (
            <SalesPurchase
              isFirst={idx === 0}
              isLast={idx === sortedPurchases.length - 1}
              key={purchase.id}
              purchase={purchase}
              quantityUnit={quantityUnit}
            />
          ))
        ) : (
          <Placeholder>
            – <T>Products:SalesProductList.noPurchases</T> –
          </Placeholder>
        ))}

      {listViewMode === ListViewMode.Products &&
        sortedProducts.map((product, idx) => (
          <SalesProduct
            isFirst={idx === 0}
            isLast={idx === sortedProducts.length - 1}
            key={product.id}
            product={product}
          />
        ))}
    </FlexColumn>
  )
}

///////

const ListControlsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`
