import { gql } from '@apollo/client'

import { taskFragments } from '@/modules/Task'

export default gql`
  ${taskFragments.taskAssignee}

  query SaleTasks($id: ID!) {
    sales(id: $id) {
      id
      tasks(input: { types: [SALES_READY_FOR_INVOICING] }) {
        id
        name
        description
        isOpen
        type
        dueDate
        dueTime
        assignee {
          ...TaskAssignee
        }
      }
    }
  }
`
