import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { useTheme } from '@/theme'

type Props = {
  consumption: number
  hidden?: boolean
}

export const DefaultConsumption = ({ consumption, hidden }: Props) => {
  const theme = useTheme()

  return (
    <Wrapper>
      {!hidden && (
        <>
          <FontAwesomeIcon
            color={theme.palette.primary.main}
            fixedWidth
            icon="user-group"
            size="sm"
          />
          <FontWeight normal>{consumption}</FontWeight>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    align-items: center;
    justify-content: center;

    min-width: ${theme.spacing.gu(8)}rem;
    padding: 0 ${theme.spacing.gu(1)}rem;

    & svg {
      margin-right: ${theme.spacing.gu(1)}rem;
    }
  `}
`
