import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { FlexRow } from '@/components/Layout'

type Props = {
  children: ReactNode
}

export const Placeholder = ({ children }: Props) => (
  <FlexRow
    justifyContent="center"
    alignItems="center"
    style={{ height: '100%' }}
  >
    <PlaceholderLabel>{children}</PlaceholderLabel>
  </FlexRow>
)

const PlaceholderLabel = styled.div`
  font-style: italic;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
