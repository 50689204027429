import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { SuitableFilter } from '~generated-types'
import { T } from '@/modules/Language'

type Label = 'ALL' | 'DEFAULT'

type Props = {
  handleClose: () => void
  loadDataForLabel: (label: SuitableFilter) => void
  selectedLabel: Label
  setSelectedLabel: (label: Label) => void
}

const ContextSwitcher = ({
  handleClose,
  loadDataForLabel,
  selectedLabel,
  setSelectedLabel,
}: Props) => (
  <Wrapper flex={1} alignItems="stretch" justifyContent="flex-end">
    <Button
      active={selectedLabel === 'DEFAULT'}
      compact
      noMargin
      noNudge
      onClick={() => {
        setSelectedLabel('DEFAULT')
        loadDataForLabel(SuitableFilter.Default)
      }}
    >
      <ButtonLabel>
        <T>ParticipantsList:ParticipantRooms.showDefault</T>
      </ButtonLabel>
    </Button>

    <Divider />

    <Button
      active={selectedLabel === 'ALL'}
      compact
      noMargin
      noNudge
      onClick={() => {
        setSelectedLabel('ALL')
        loadDataForLabel(SuitableFilter.All)
      }}
    >
      <ButtonLabel>
        <T>ParticipantsList:ParticipantRooms.showAll</T>
      </ButtonLabel>
    </Button>

    {handleClose && (
      <CloseButton compact noMargin noNudge onClick={handleClose}>
        <FontAwesomeIcon icon="xmark" />
      </CloseButton>
    )}
  </Wrapper>
)

export default ContextSwitcher

///////

const CloseButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Divider = styled.div`
  width: 2px;
  margin: 0 5px;

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.main};
  `}
`

const ButtonLabel = styled(PrimaryColor)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Button = styled(InnocuousButton)<{ active: boolean }>`
  ${({ active, theme }) =>
    active &&
    css`
      border-bottom: 2px solid ${theme.palette.primary.main};
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    `}
`

const Wrapper = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
