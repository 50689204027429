import { SalesForIsoWeekQuery, SalesType } from '~generated-types'
import { SalesTypeChip } from '@/modules/Sales'

import { LabelTruncate } from '../../SalesListing/components/LabelTruncate'
import { PrintLink } from '../PrintLink'
import { TD } from '../TD'
import { useTranslation } from 'react-i18next'

interface GenericSales {
  id: string
  name: string | null
  orderNumber: number | null
  type: SalesType
  facet?: SalesForIsoWeekQuery['sales'][0]['facet']
  noWrap?: boolean
}

export const SalesCell = ({
  id,
  name,
  orderNumber,
  type,
  facet,
  noWrap,
}: GenericSales) => {
  const { t } = useTranslation('Listings')
  const nameFallback = t(`cells.sales.unnamed.${type}`)
  const label = `#${orderNumber} ${name ? name : nameFallback}`

  return (
    <>
      {noWrap ? (
        <LabelTruncate title={label}>
          <PrintLink to={`/sales/details/${id}`}>{label}</PrintLink>
        </LabelTruncate>
      ) : (
        <TD>
          <PrintLink to={`/sales/details/${id}`}>{label}</PrintLink>
        </TD>
      )}

      <TD>
        <SalesTypeChip type={type} facet={facet} />
      </TD>
    </>
  )
}

export default SalesCell
