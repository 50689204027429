import moment from 'moment'
import styled from 'styled-components'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { PrintLink } from '@/modules/Listing/common'

import { parseCustomerName, parseParticipantName } from '../../utils'
import { ParticipantMeal, Sales } from './RestaurantSpecialAllergies'

type Props = {
  data: Sales
}

export const SaleTypeSpecialAllergies = ({ data }: Props) => {
  const { sales, participants } = data

  return (
    <>
      <tr>
        <td colSpan={9}>
          <PrintLink to={`/sales/details/${sales.id}`}>
            {parseCustomerName(sales)}
          </PrintLink>
        </td>
      </tr>

      {participants
        .sort(generateCompareFn('firstName', false))
        .map((participant, idx) => (
          <tr key={`sale-participant-${participant.id}-${idx}`}>
            <td />
            <td>
              {parseParticipantName({
                firstName: participant.firstName,
                lastName: participant.lastName,
              })}
            </td>
            <td>{participant.ageCategory?.shortName}</td>
            <td>
              {participant.meals.sort(compareFn).map((meal, idx) => (
                <span key={`participant-meal-${meal.mealName}`}>
                  {participant.meals.length > 1 &&
                  idx + 1 !== participant.meals.length
                    ? `${meal.mealName}, `
                    : meal.mealName}
                </span>
              ))}
            </td>
            <td>
              <FlexColumn noPadding>
                <FlexRow>
                  {participant.allergies.length ? (
                    <>
                      {'• '}
                      {participant.allergies.map((allergy, idx) => (
                        <AllergyLabel key={`participant-allergy-${idx}`}>
                          {participant.allergies.length > 1 &&
                          idx + 1 !== participant.allergies.length
                            ? `${allergy}, `
                            : allergy}
                        </AllergyLabel>
                      ))}
                    </>
                  ) : null}
                </FlexRow>

                <FlexRow>
                  {participant.diets.length ? (
                    <>
                      {'• '}
                      {participant.diets.map((diet, idx) => (
                        <AllergyLabel key={`participant-diet-${idx}`}>
                          {participant.diets.length > 1 &&
                          idx + 1 !== participant.diets.length
                            ? `${diet}, `
                            : diet}
                        </AllergyLabel>
                      ))}
                    </>
                  ) : null}
                </FlexRow>

                <FlexRow>
                  {participant.allergyDescription ? (
                    <>
                      {'• '}
                      <AllergyLabel>
                        {participant.allergyDescription}
                      </AllergyLabel>
                    </>
                  ) : null}
                </FlexRow>
              </FlexColumn>
            </td>
          </tr>
        ))}
    </>
  )
}

///////////////

const compareFn = (a: ParticipantMeal, b: ParticipantMeal) => {
  return moment(a.mealStartDate).valueOf() - moment(b.mealStartDate).valueOf()
}

const AllergyLabel = styled.span`
  display: inline-block;
  margin-left: 5px;
`
