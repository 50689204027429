import { ControlProps, OptionTypeBase, SingleValueProps } from 'react-select'
import { CSSProperties, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { useQuery } from '@apollo/client'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Palette, useTheme } from '@/theme'
import { PointOfSale, PointsOfSalesQuery } from '@/modules/Order/types'
import { FlexColumn } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { Label } from '@/components/FormControls'
import { orderQueries } from '@/modules/Order'
import { T } from '@/modules/Language'

type Props = {
  disabled: boolean
  pointOfSale: Option | null
  setPointOfSale: (pointOfSale: Option | null) => void
}

export const PointOfSaleSelector = ({
  disabled,
  pointOfSale,
  setPointOfSale,
}: Props) => {
  const { palette } = useTheme()

  const [pointOfSaleOptions, setPointOfSaleOptions] = useState<Option[] | []>(
    []
  )

  const { data, loading } = useQuery<PointsOfSalesQuery>(
    orderQueries.POINTS_OF_SALES
  )

  useEffect(() => {
    if (data) {
      const { pointOfSales } = data.registry

      const newPointOfSaleOptions = [...pointOfSales]
        .sort(generateCompareFn('name'))
        .map((point: PointOfSale) => ({
          label: point.name,
          value: point.id,
        }))

      setPointOfSaleOptions(newPointOfSaleOptions)
      setPointOfSale(
        JSON.parse(localStorage.getItem('pointOfSale') || 'null') ||
          (newPointOfSaleOptions.length
            ? {
                label: newPointOfSaleOptions[0].label,
                value: newPointOfSaleOptions[0].value,
              }
            : null)
      )
    }
  }, [data])

  const handleSetPointOfSale = (option: Option | null | undefined) => {
    if (option) {
      setPointOfSale(option)
      localStorage.setItem('pointOfSale', JSON.stringify(option))
    }
  }

  return (
    <FlexColumn flex={2} noPadding>
      <SubTitle>
        <T>Orders:Payments.field.pointOfSale</T>
      </SubTitle>

      <ThemedSelect
        extraStyles={getExtraStyles(palette)}
        isDisabled={disabled}
        isLoading={loading}
        menuPortalTarget={document.body}
        name="point-of-sale-selector"
        noOptionsMessage={() => <T>Orders:Payments.modal.emptyPointOfSales</T>}
        onChange={(option?: Option | null) => handleSetPointOfSale(option)}
        options={pointOfSaleOptions}
        placeholder="–"
        value={pointOfSale}
      />
    </FlexColumn>
  )
}

///////

const getExtraStyles = (palette: Palette) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    width: '100%',
  }),
  control: (
    styles: CSSProperties,
    { isDisabled }: ControlProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    background: isDisabled ? palette.smoke.lighter : styles.background,
    borderColor: isDisabled ? palette.smoke.dark : styles.borderColor,
    borderRadius: '6px',
    cursor: isDisabled ? 'not-allowed' : 'pointer',
    height: '35px',
    minHeight: '35px',
    pointerEvents: isDisabled ? 'auto' : styles.pointerEvents,
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    padding: '6px',
  }),
  singleValue: (
    styles: CSSProperties,
    { isDisabled }: SingleValueProps<OptionTypeBase>
  ) => ({
    ...styles,
    color: isDisabled ? palette.text.lighter : palette.text.light,
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    padding: '2px 6px',
  }),
})

const SubTitle = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0 ${theme.spacing.gu(1)}rem;
  `}
`
