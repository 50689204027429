import { Ref, useState } from 'react'
import moment from 'moment'

import {
  DateRangePickerControl,
  DateRange as MomentDateRange,
} from '@/components/TimeControls'
import { EditButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

import { ContentPlaceholder } from '../../common'
import { usePublishContext } from '../../../PublishState'
import { WebshopSettingsToken } from '../../../types'

type Props = {
  token: WebshopSettingsToken
}

export const IntervalSelector = ({
  token: { scopes, token, validTo, validFrom, completedAt },
}: Props) => {
  const {
    readOnly: salesReadOnly,
    salesId,
    updateWebshopSettings,
  } = usePublishContext()
  const readOnly = !!(salesReadOnly || completedAt)

  const [dateRange, setDateRange] = useState<MomentDateRange | null>(
    validTo && validFrom
      ? {
          from: moment(validFrom),
          to: moment(validTo),
        }
      : null
  )

  const handleChangeDateRange = (range: MomentDateRange | null) =>
    updateWebshopSettings({
      salesSettings: {
        salesId,
        scopes,
        tokenId: token,
        validFrom: range ? range.from.format('YYYY-MM-DD') : null,
        validTo: range ? range.to.format('YYYY-MM-DD') : null,
      },
    }).catch(() => setDateRange(null))

  const renderValue = (value: MomentDateRange) => {
    const from = moment(value.from)
    const to = moment(value.to)

    return `${from.format(
      from.isSame(to, 'year') ? 'dd, D.M' : 'dd, D.M.YY'
    )} – ${to.format('dd, D.M.YY')}`
  }

  return (
    <DateRangePickerControl
      clearable
      placement="bottom-start"
      render={({ openModal, ref }) => (
        <EditButton
          disabled={readOnly}
          innerRef={ref as Ref<HTMLButtonElement>}
          onClick={openModal}
          style={{ flex: 'unset' }}
        >
          {dateRange ? (
            renderValue(dateRange)
          ) : (
            <ContentPlaceholder>
              <T>Publish:Permissions.validity.noDates</T>
            </ContentPlaceholder>
          )}
        </EditButton>
      )}
      setValue={(range) => {
        setDateRange(range || null)
        handleChangeDateRange(range || null)
      }}
      value={
        dateRange
          ? {
              from: moment(dateRange.from),
              to: moment(dateRange.to),
            }
          : null
      }
    />
  )
}
