import type { Color } from '../types'
import { ColorLightness } from '../enums'
import getColorVariant from './getColorVariant'

export default function generateColor(input: string): Color {
  return {
    dark: getColorVariant(input, ColorLightness.Dark),
    darker: getColorVariant(input, ColorLightness.Darker),
    light: getColorVariant(input, ColorLightness.Light),
    lighter: getColorVariant(input, ColorLightness.Lighter),
    main: input,
  }
}
