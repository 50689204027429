import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  disabled?: boolean
  onClick: () => void
}

export const OpenCalendarButton = ({ disabled, onClick }: Props) => {
  const { spacing } = useTheme()

  return (
    <InnocuousButton disabled={disabled} onClick={onClick}>
      <PrimaryColor>
        <FontAwesomeIcon
          icon={['far', 'calendar']}
          style={{ marginRight: `${spacing.gu(1)}rem` }}
        />
        <T>ResourceReservations:action.addReservation</T>
      </PrimaryColor>
    </InnocuousButton>
  )
}
