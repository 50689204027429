import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { DateRange, SalesReservationManager } from '@/modules/Accommodation'
import { BottomSheet } from '@/components/BottomSheet'
import { RoomLayout } from '@/modules/Reservations/components/RoomLayout'
import { T } from '@/modules/Language'
import { useDialogService } from '@/components/DialogService'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import {
  SalesAccommodationGroup,
  SalesDetailsBase,
} from './SalesReservationList.types'
import { ReservationListContent } from './components/ReservationListContent'
import { ReservationListHeader } from './components/ReservationListHeader'

type Props = {
  salesId: string
  group: SalesAccommodationGroup
  readOnly?: boolean
  defaultRange: DateRange | null | undefined
  salesDetails: SalesDetailsBase
  selectProcess: boolean
  targetIdsToMove: string[]
  setTargetIdsToMove: (ids: string[]) => void
}

export const GroupPanel = ({
  salesId,
  group,
  readOnly,
  salesDetails,
  defaultRange,
  selectProcess,
  targetIdsToMove,
  setTargetIdsToMove,
}: Props) => {
  const { confirm } = useDialogService()
  const { data } = useSalesDetailsContext()

  const [isManagerOpen, setManagerOpen] = useState<boolean>(false)
  const [isRoomLayoutOpen, setRoomLayoutOpen] = useState<boolean>(false)
  const [focusedTargetId, setFocusedTargetId] = useState<string>('')

  const handleCloseManager = () => {
    const showConfirmModal =
      !!group.roomTypeReservations.find(
        (r) => r.__typename === 'PendingRoomTypeReservation'
      ) ||
      !!group.roomReservations.find(
        (r) => r.__typename === 'PendingRoomReservation'
      )

    return showConfirmModal
      ? confirm({
          cancelLabel: <T>common:action.cancel</T>,
          confirmLabel: <T>common:action.close</T>,
          description: (
            <T>Accommodation:SalesReservationManager.confirmClosing</T>
          ),
        })
          .then(() => setManagerOpen(false))
          .catch(() => undefined)
      : setManagerOpen(false)
  }

  const handleOpenManager = (targetId?: string) => {
    setFocusedTargetId(targetId || '')
    setManagerOpen(true)
  }
  const handleOpenRoomLayout = (targetId?: string) => {
    setFocusedTargetId(targetId || '')
    setRoomLayoutOpen(true)
  }

  return (
    <>
      <ContentWrapper>
        <ReservationListHeader
          group={group}
          readOnly={readOnly}
          handleOpenManager={handleOpenManager}
          handleOpenRoomLayout={handleOpenRoomLayout}
          selectProcess={selectProcess}
          targetIdsToMove={targetIdsToMove}
          setTargetIdsToMove={setTargetIdsToMove}
        />
        <HorizontalDivider />
        <ReservationListContent
          group={group}
          readOnly={readOnly}
          salesId={salesId}
          handleOpenManager={handleOpenManager}
          handleOpenRoomLayout={handleOpenRoomLayout}
          selectProcess={selectProcess}
          targetIdsToMove={targetIdsToMove}
          setTargetIdsToMove={setTargetIdsToMove}
        />
      </ContentWrapper>
      {isManagerOpen && (
        <BottomSheet
          onClose={handleCloseManager}
          title={<T>SalesDetails:overlay.accommodation</T>}
        >
          <SalesReservationManager
            defaultRange={defaultRange}
            groupId={group.id}
            targetId={focusedTargetId}
            salesDetails={salesDetails}
          />
        </BottomSheet>
      )}
      {isRoomLayoutOpen && (
        <BottomSheet
          onClose={() => setRoomLayoutOpen(false)}
          title={<T>SalesDetails:overlay.roomLayout</T>}
        >
          <RoomLayout
            defaultRange={defaultRange}
            groupId={group.id}
            salesId={data.event ? data.event.id : data.id}
            targetId={focusedTargetId}
          />
        </BottomSheet>
      )}
    </>
  )
}

const ContentWrapper = styled.div`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(2)}rem;
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const HorizontalDivider = styled.div`
  width: 100%;
  height: 0px;

  ${({ theme }) => css`
    border-bottom: 1px dashed ${theme.palette.smoke.dark};
    margin: ${theme.spacing.gutter} 0;
  `}
`
