import {
  OrderItemProduct as OrderItemProductType,
  OrderItem as OrderItemType,
} from '@/modules/Order/types'

import { getParticipantName } from './utils'
import { OrderItemPacket } from './OrderItemPacket'
import { OrderItemProduct } from './OrderItemProduct'

type ManagerIds = {
  productId: string
  purchaseId: string
}

type Props = {
  item: OrderItemType
  setManagerIds: (ids: ManagerIds | null) => void
  showProductCodes: boolean
}

export const OrderItem = ({ item, setManagerIds, showProductCodes }: Props) => {
  const {
    products,
    source: { purchase },
  } = item

  const participant = purchase?.link.participant ?? null
  const productId = purchase?.product.id ?? null
  const purchaseId = purchase?.id ?? null

  const handleOpenEditor = () =>
    setManagerIds(productId && purchaseId ? { productId, purchaseId } : null)

  return products.length === 1 ? (
    <OrderItemProduct
      openEditor={handleOpenEditor}
      participantName={getParticipantName(participant)}
      product={products[0] as OrderItemProductType}
      showProductCodes={showProductCodes}
    />
  ) : (
    <OrderItemPacket
      openEditor={handleOpenEditor}
      packet={item}
      participantName={getParticipantName(participant)}
      showProductCodes={showProductCodes}
    />
  )
}
