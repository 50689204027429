import { Fragment, useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { InnocuousButton } from '@/components/ExtraButtons'
import { orderServices } from '@/modules/Order'
import { OrderType } from '@/modules/Order/types'
import { salesHooks } from '@/modules/Sales'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Order } from './Order'

type Props = {
  baseRoute: string
  readOnly?: boolean
}

export const OrderList = ({ baseRoute, readOnly }: Props) => {
  const { palette } = useTheme()

  const {
    data: { id: salesId },
    ordersById,
    ordersError,
    ordersLoading,
    navItemSelect,
    setFocusedOrderId,
  } = salesHooks.useSalesDetailsContext()

  const { createOrder } = orderServices.orderService()

  const [processing, setProcessing] = useState<boolean>(false)
  const [isShowAll, setShowAll] = useState<boolean>(false)

  const handleCreateOrder = () => {
    setProcessing(true)

    createOrder(salesId)
      .then((newOrder) => {
        navItemSelect('orders')
        setFocusedOrderId(newOrder?.id || null)
      })
      .finally(() => setProcessing(false))
  }

  const onOrderOpen = (id: string) => {
    if (readOnly) {
      return
    }

    navItemSelect('orders')
    setFocusedOrderId(id)
  }

  const sortedOrders = useMemo(
    () =>
      [...Object.values(ordersById)].sort(
        generateCompareFn('orderNumber', true)
      ),
    [ordersById]
  )
  const orderList = isShowAll ? sortedOrders : sortedOrders.slice(0, 3)

  return (
    <>
      <Header alignItems="center" justifyContent="space-between">
        <Title>
          <T>SalesDetails:section.orders</T>
        </Title>
        {processing ? (
          <ReactLoading
            color={palette.primary.main}
            height={16}
            type="spin"
            width={16}
          />
        ) : (
          <IconButton
            disabled={readOnly}
            compact
            noNudge
            onClick={handleCreateOrder}
          >
            <FontAwesomeIcon
              color={palette.primary.main}
              icon="plus"
              size="sm"
            />
          </IconButton>
        )}
      </Header>
      <FlexColumn noPadding>
        {ordersLoading ? (
          <LoaderWrapper justifyContent="center">
            <ReactLoading
              color={palette.smoke.main}
              height={24}
              type="spin"
              width={24}
            />
          </LoaderWrapper>
        ) : ordersError ? (
          <Placeholder>
            <T>Orders:OrdersForOwner.error</T>
          </Placeholder>
        ) : orderList?.length ? (
          orderList.map((order: OrderType, index: number) => (
            <Fragment key={order.id}>
              <Order
                baseRoute={baseRoute}
                order={order}
                readOnly={readOnly}
                showOrder={onOrderOpen}
              />
              {index !== orderList.length - 1 && <Separator />}
            </Fragment>
          ))
        ) : (
          <Placeholder>
            <T>Orders:OrdersForOwner.empty</T>
          </Placeholder>
        )}

        {Object.values(ordersById).length > 3 && (
          <ShowAllButton compact onClick={() => setShowAll(!isShowAll)}>
            <T
              l10n={`Orders:OrdersForOwner.${isShowAll ? 'hide' : 'showAll'}`}
            />
          </ShowAllButton>
        )}
      </FlexColumn>
    </>
  )
}

///////

const Header = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    margin-bottom: ${theme.spacing.gutterSmall};
    padding-left: ${theme.spacing.gu(1)}rem;
  `}
`

const IconButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    width: ${theme.spacing.gutterBig};
  `}
`

const LoaderWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;
  font-weight: 300;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Separator = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.lighter};
    margin: ${theme.spacing.gutterSmall};
  `}
`

const ShowAllButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    font-size: ${theme.typography.fontSizeSmall};
    margin-top: ${theme.spacing.gu(1)}rem;
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.coal.dark};
  `}
`
