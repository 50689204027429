import styled, { css } from 'styled-components'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { useLanguageContext } from '@/modules/Language'

import {
  ListingGroupReservation,
  ListingReservation,
} from '../useReservationList'
import { getFullResourceName } from '../utils'

type Props = {
  reservation: ListingReservation | ListingGroupReservation
  showProduct: boolean
}

export const Resource = ({
  reservation: { purchaseProducts, resource, resourceQuantity },
  showProduct,
}: Props) => {
  const { language } = useLanguageContext()

  const purchase =
    showProduct && purchaseProducts.length ? purchaseProducts[0] : null

  return (
    <FlexRow>
      <Quantity>{resourceQuantity > 1 ? `${resourceQuantity}` : ''}</Quantity>

      {resourceQuantity > 1 && <QuantitySeparator>x</QuantitySeparator>}

      <FlexColumn alignItems="flex-start" noPadding>
        <span>{getFullResourceName(resource, language)}</span>

        {purchase && (
          <small style={{ whiteSpace: 'normal' }}>
            {purchase.product.name} (
            {formatCurrency(purchase.totalPrice.amountVatIncluded || 0)} €)
          </small>
        )}
      </FlexColumn>
    </FlexRow>
  )
}

////////////

const Quantity = styled.span`
  display: inline-block;
  text-align: right;
`

const QuantitySeparator = styled.span`
  display: inline-block;
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
  `}
`
