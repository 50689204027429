import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'

export const PrintButton = () => (
  <Button onClick={() => window.print()}>
    <PrimaryColor>
      <Icon icon="print" />
      <T>common:action.print</T>
    </PrimaryColor>
  </Button>
)

////////////

const Button = styled(InnocuousButton)`
  height: 30px;
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
