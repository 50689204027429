import styled from 'styled-components/macro'

import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'

type SalesLabelProps = {
  name: string | null
  orderNumber: number | null
  type: SalesType
}

export const SalesLabel = ({ name, orderNumber, type }: SalesLabelProps) => (
  <span title={name ?? ''}>
    #{orderNumber}{' '}
    {name || (
      <em>
        <T l10n={`FrontDesk:DailyRoomReservations.unnamed.${type}`} />
      </em>
    )}
  </span>
)

export const Button = styled.button<{ width: string }>`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  padding: 0;
  width: ${({ width }) => width};

  &:hover:not([disabled]) {
    ${({ theme }) => `
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => `
      background: ${theme.palette.smoke.main};
    `}
  }

  &:disabled {
    cursor: default;
  }
`
