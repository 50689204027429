import { ChangeEvent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Textarea } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  note: string
  readOnly?: boolean
  updateNote: (note: string) => Promise<any>
}

export const Note = ({ note, readOnly, updateNote }: Props) => {
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)
  const [noteValue, setNoteValue] = useState(note)

  useEffect(() => {
    note !== noteValue && setNoteValue(note)
  }, [note])

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalSection style={{ margin: 0, padding: spacing.gutter }}>
            <StyledTextarea
              disabled={readOnly}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setNoteValue(e.target.value)
              }
              autoFocus
              value={noteValue || ''}
              onBlur={() => {
                !readOnly && updateNote(noteValue)
              }}
              style={{ height: 200, width: 360 }}
            />
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Wrapper ref={ref} onClick={() => setModalOpen(true)}>
          <FontAwesomeIcon
            color={noteValue ? palette.warning.dark : palette.text.light}
            icon={['far', 'note-sticky']}
            size="lg"
            style={{ margin: `${spacing.gu(0.5)}rem ${spacing.gu(1)}rem` }}
          />
        </Wrapper>
      )}
      zIndex={10008}
    />
  )
}

////////

const Wrapper = styled(FlexRow)`
  display: flex;
  align-items: center;
  align-self: stretch;

  cursor: pointer;
  transition: 0.2s;

  ${({ theme }) => css`
    &:hover {
      background-color: ${theme.palette.smoke.light};
    }
  `}
`

const StyledTextarea = styled(Textarea)`
  &:not([type='checkbox']):not([type='radio']) {
    padding: 10px;
  }
`
