import styled, { css } from 'styled-components/macro'
import { useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQuery } from '@apollo/client'

import { CheckboxInput, Input } from '@/components/FormControls'
import {
  DefaultFilterType,
  ParticipantsListQueries,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  SaleAccommodationBaseQuery as Query,
  SaleAccommodationBaseQueryVariables as QueryVariables,
  SuitableFilter,
} from '~generated-types'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { CheckboxLabel, CheckboxWrapper, Placeholder } from './common'
import { FilterContainer } from '../FilterContainer'

type Props = {
  filters: DefaultFilterType[] | null
  setFilters: (filters: DefaultFilterType[] | null) => void
}

export const RoomFilter = ({ filters, setFilters }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()
  const { salesId } = useParticipantsListContext()

  const [searchValue, setSearchValue] = useState<string>('')

  const { data, loading } = useQuery<Query, QueryVariables>(
    ParticipantsListQueries.SUITABLE_ROOMS_BASE,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        input: {
          filter: SuitableFilter.Default,
          salesId,
        },
      },
    }
  )

  const rooms = useMemo(
    () =>
      data?.suitableRooms
        .map((room) => room.roomReservation.request.room)
        .map((room) => ({ label: room.number, value: room.id })) ?? [],
    [data]
  )

  const filteredRooms = useMemo(
    () =>
      rooms.filter((o) =>
        o.label.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [rooms, searchValue]
  )

  const isEmptySelected = !!(filters?.length === 0)

  const handleSelect = (targetFilter: DefaultFilterType) => {
    if (!filters) {
      return setFilters([targetFilter])
    }

    const newFilters = !!filters.find((f) => f.value === targetFilter.value)
      ? filters.filter((f) => f.value !== targetFilter.value)
      : [...filters, targetFilter]

    return setFilters(newFilters.length ? newFilters : null)
  }
  return (
    <FilterContainer isLoading={loading}>
      {!!rooms.length ? (
        <FlexColumn>
          <CheckboxWrapper
            alignItems="center"
            style={{ marginBottom: `${spacing.gu(1)}rem` }}
          >
            <CheckboxInput
              checked={isEmptySelected}
              noMargin
              onChange={() => setFilters(isEmptySelected ? null : [])}
            >
              <CheckboxLabel>
                <T>ParticipantsList:filters.empty.roomIds</T>
              </CheckboxLabel>
            </CheckboxInput>
          </CheckboxWrapper>

          <SearchInput alignItems="center">
            <SearchIcon>
              <FontAwesomeIcon
                color={palette.primary.main}
                icon="magnifying-glass"
                size="sm"
              />
            </SearchIcon>
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(event.target.value)
              }
              placeholder={translate('ThemedSelect:search', language)}
              value={searchValue}
            />
          </SearchInput>

          {!!filteredRooms.length ? (
            <ListWrapper>
              {[...filteredRooms]
                .sort((a, b) =>
                  a.label.localeCompare(b.label, language, { numeric: true })
                )
                .map(({ label, value }) => (
                  <CheckboxWrapper alignItems="center" key={value}>
                    <CheckboxInput
                      checked={!!filters?.find((v) => v.value === value)}
                      noMargin
                      onChange={() => handleSelect({ label, value })}
                    >
                      <CheckboxLabel>{label}</CheckboxLabel>
                    </CheckboxInput>
                  </CheckboxWrapper>
                ))}
            </ListWrapper>
          ) : (
            <Placeholder>
              <T>ParticipantsList:ParticipantRooms.noRooms</T>
            </Placeholder>
          )}
        </FlexColumn>
      ) : (
        <Placeholder>
          <T>ParticipantsList:ParticipantRooms.noRooms</T>
        </Placeholder>
      )}
    </FilterContainer>
  )
}

//////

const ListWrapper = styled.div`
  max-height: calc(30px * 8.5);
  overflow: auto;
`

const SearchIcon = styled.div`
  position: absolute;
  padding-left: 10px;

  ${({ theme }) => css`
    width: calc(${theme.spacing.gu(1)}rem + 27px);
  `}
`

const SearchInput = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  input {
    &:not([type='checkbox']):not([type='radio']) {
      height: 32px;
      border-radius: 4px;

      ${({ theme }) => css`
        border: 1px solid ${theme.palette.smoke.main};
        padding-left: calc(${theme.spacing.gu(1)}rem + 27px);
      `}

      &::placeholder {
        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`
