import { CustomerTarget, EditCustomer } from '@/modules/Registry/Customer'
import { BottomSheet } from '@/components/BottomSheet'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  onClose: () => void
  target: CustomerTarget
}

export const DetailsModal = ({ onClose, target }: Props) => (
  <BottomSheet
    onClose={onClose}
    title={<T>Customers:CustomerManager.titleEdit</T>}
  >
    <Gutter type={[0, 5, 10]}>
      <EditCustomer customerNumber={target.customerNumber || '-1'} />
    </Gutter>
  </BottomSheet>
)
