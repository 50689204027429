import styled, { css } from 'styled-components/macro'
import React from 'react'

import {
  ElasticRoom,
  ElasticRoomReservation,
} from '@/modules/Reservations/components/RoomLayout'
import { useLanguageContext } from '@/modules/Language'

import DateHeaders from './components/DateHeaders'
import GroupHeader from './components/GroupHeader'
import RoomReservations from './components/RoomReservations'

interface PrintRoomGroupProps {
  isoWeek: number
  isoWeekYear: number
  reservations: ElasticRoomReservation[]
  rooms: ElasticRoom[]
  title: React.ReactNode
}

export const PrintRoomGroup = ({
  isoWeek,
  isoWeekYear,
  reservations,
  rooms,
  title,
}: PrintRoomGroupProps) => {
  const { language } = useLanguageContext()

  const getReservationsByRoom = (roomId: string) =>
    reservations.filter(
      (reservation: ElasticRoomReservation) => reservation.room.id === roomId
    )

  const sortRoomsByNumberFn = (a: ElasticRoom, b: ElasticRoom) =>
    a.number.localeCompare(b.number, language, { numeric: true })

  return (
    <Wrapper>
      <Timetable>
        <thead>
          <GroupHeader
            isoWeek={isoWeek}
            isoWeekYear={isoWeekYear}
            title={title}
          />
          <DateHeaders isoWeek={isoWeek} isoWeekYear={isoWeekYear} />
        </thead>
        {rooms.sort(sortRoomsByNumberFn).map((room) => (
          <RoomReservations
            key={`print-room-group-${room.id}`}
            isoWeek={isoWeek}
            isoWeekYear={isoWeekYear}
            reservations={getReservationsByRoom(room.id)}
            room={room}
          />
        ))}
      </Timetable>
    </Wrapper>
  )
}

/////////////

const Timetable = styled.table`
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;

  ${({ theme }) => css`
    border: solid 1px ${theme.palette.smoke.dark};
    margin-bottom: ${theme.spacing.gutterBig};
  `}

  @media print {
    ${({ theme }) => css`
      border-color: ${theme.palette.text.light};
      font-size: ${theme.typography.fontSizeSmall};
    `}
  }

  & > tbody {
    ${({ theme }) => css`
      border-top: solid 1px ${theme.palette.smoke.main};
      border-bottom: solid 1px ${theme.palette.smoke.main};
    `}

    @media print {
      ${({ theme }) => css`
        border-top-color: ${theme.palette.smoke.dark};
        border-bottom-color: ${theme.palette.smoke.dark};
      `}
    }
  }

  & td,
  & th {
    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
    `}

    @media print {
      ${({ theme }) => css`
        padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(0.5)}rem;
      `}
    }
  }

  & td.compact {
    ${({ theme }) => css`
      padding-top: ${theme.spacing.gu(0.5)}rem;
      padding-bottom: ${theme.spacing.gu(0.5)}rem;
    `}

    @media print {
      ${({ theme }) => css`
        padding-top: ${theme.spacing.gu(0.25)}rem;
        padding-bottom: ${theme.spacing.gu(0.25)}rem;
      `}
    }
  }

  & > thead > tr:first-child > th {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}

    @media print {
      -webkit-print-color-adjust: exact;

      ${({ theme }) => css`
        background-color: ${theme.palette.smoke.light};
      `}
    }
  }

  & > thead th {
    ${({ theme }) => css`
      border: solid 1px ${theme.palette.smoke.dark};
    `}

    @media print {
      ${({ theme }) => css`
        border-color: ${theme.palette.text.light};
      `}
    }
  }

  & > tbody > tr > td:first-child {
    ${({ theme }) => css`
      border-right: solid 1px ${theme.palette.smoke.dark};
    `}

    @media print {
      ${({ theme }) => css`
        border-right-color: ${theme.palette.text.light};
      `}
    }
  }
`

const Wrapper = styled.div`
  page-break-after: always;
`
