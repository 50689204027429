import styled, { css } from 'styled-components/macro'
import React from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'

type Props = {
  options: {
    value: string
    label: React.ReactNode | string
    disabled?: boolean
    startAdornment?: React.ReactNode | string
    endAdornment?: React.ReactNode | string
  }[]
  handleSelect: (label: string) => Promise<any> | void
}

const OptionsList = ({ options, handleSelect }: Props) => (
  <FlexColumn noPadding style={{ width: '100%' }}>
    {options.map((option) => (
      <FlexRow
        key={option.value}
        flex={1}
        style={{ width: '100%' }}
        alignItems="stretch"
      >
        {option.startAdornment}
        <OptionWrapper
          key={option.value}
          disabled={option.disabled}
          onClick={() => {
            if (option.disabled) {
              return
            }

            handleSelect(option.value)
          }}
        >
          {option.label}
        </OptionWrapper>
        {option.endAdornment}
      </FlexRow>
    ))}
  </FlexColumn>
)

export default OptionsList

const OptionWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  cursor: pointer;
  transition: all 0.1s ease-out;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : theme.palette.white};

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }

  &:not(:first-of-type) {
    ${({ theme }) => css`
      border-top: 1px solid ${theme.palette.smoke.light};
    `}
  }
`
