import { gql, useMutation } from '@apollo/client'

import ResourceReservationFragment from '../fragments/resourceReservation'

import {
  SetResourceReservationColorMutation,
  SetResourceReservationColorMutationVariables,
} from '~generated-types'

export type {
  SetResourceReservationColorMutation,
  SetResourceReservationColorMutationVariables,
}

export const SET_RESOURCE_RESERVATION_COLOR_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationColor(
    $input: ResourceReservationSetColorInput!
  ) {
    resourceReservationSetColor(input: $input) {
      ...ResourceReservation
    }
  }
`

export function useSetResourceReservationColorMutation() {
  return useMutation<
    SetResourceReservationColorMutation,
    SetResourceReservationColorMutationVariables
  >(SET_RESOURCE_RESERVATION_COLOR_MUTATION)
}

export default useSetResourceReservationColorMutation
