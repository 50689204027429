import { gql, useMutation } from '@apollo/client'

import {
  AcceptSaleMutation,
  AcceptSaleMutationVariables,
} from '~generated-types'

export type { AcceptSaleMutation, AcceptSaleMutationVariables }

export const ACCEPT_SALE_MUTATION = gql`
  mutation AcceptSale($input: SalesAcceptInput!) {
    salesAccept(input: $input) {
      id
      locked
      lifecycle {
        state
      }
    }
  }
`

export function useAcceptSaleMutation() {
  return useMutation<AcceptSaleMutation, AcceptSaleMutationVariables>(
    ACCEPT_SALE_MUTATION
  )
}

export default useAcceptSaleMutation
