import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import {
  InlineModal,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { CatalogProductManagerFooter } from './CatalogProductManagerFooter'
import { PricingSection } from './components'
import { useCatalogProductManagerContext } from './CatalogProductManagerState'

type Props = {
  onClose: () => void
}

export const CatalogProductManager = ({ onClose }: Props) => {
  const { palette } = useTheme()
  const { error, loading, product } = useCatalogProductManagerContext()

  const renderContent = () => {
    if (error) {
      return (
        <Placeholder alignItems="center" flex={1} justifyContent="center">
          <T>Products:ProductManager.error</T>
        </Placeholder>
      )
    }

    if (loading) {
      return (
        <FlexRow alignItems="center" flex={1} justifyContent="center">
          <ReactLoading
            color={palette.smoke.main}
            height={40}
            type="spin"
            width={40}
          />
        </FlexRow>
      )
    }

    if (product) {
      const isPacket = product.items.length > 1

      return (
        <>
          <ModalHeader
            onClose={onClose}
            title={
              <ShiftLeft>
                <T>{`Products:ProductType.${
                  isPacket ? 'PACKET' : 'PRODUCT'
                }`}</T>
              </ShiftLeft>
            }
          />

          <InlineModalLine style={{ margin: 0 }} />

          <FlexRow flex={1} style={{ overflow: 'hidden' }}>
            <PricingSection product={product} />
          </FlexRow>

          <CatalogProductManagerFooter
            onClose={onClose}
            productId={product.id}
          />
        </>
      )
    }
  }

  return <Modal>{renderContent()}</Modal>
}

/////////

const Modal = styled(InlineModal)`
  max-height: 100vh;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(100)}rem;
    height: calc(100vh - ${theme.spacing.guPx(9) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(9) + 3}px;
  `}
`

const ModalHeader = styled(InlineModalHeader)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(7)}rem;
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled(FlexRow)`
  font-weight: 300;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin-bottom: ${theme.spacing.gu(7)}rem;
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(4)}rem;
  `}
`
