import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'

import { translate, useLanguageContext } from '@/modules/Language'
import { DataTableInput } from '@/components/DataTable'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  id: string
  quantity: number
  readOnly: boolean
}

export const QuantityInput = ({
  id,
  quantity,
  readOnly: purchaseReadOnly,
}: Props) => {
  const { language } = useLanguageContext()
  const { salesReadOnly, updatePurchase } = useSalesProductManagerContext()

  const readOnly = purchaseReadOnly || salesReadOnly

  const [value, setValue] = useState<string>('')

  useEffect(() => {
    setValue(`${quantity}`)
  }, [quantity])

  const handleUpdateQuantity = () => {
    const quantityValue = Number(value) || 1

    setValue(`${quantityValue}`)

    return updatePurchase({
      attributes: { quantity: quantityValue },
      purchaseId: id,
    }).then((res) => !res && setValue(`${quantity}`))
  }

  return (
    <Wrapper>
      <Input
        disabled={readOnly}
        onBlur={handleUpdateQuantity}
        onChange={(e) => setValue(e.target.value)}
        onFocus={(e) => e.currentTarget.select()}
        endAdornment={`${translate(
          `enums:quantityUnit.abbrev.PIECE`,
          language
        )}`}
        showBorder
        flex="unset"
        type="number"
        value={value}
      />
    </Wrapper>
  )
}

/////////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: end;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0 ${theme.spacing.gu(4.5)}rem 0 ${theme.spacing.gu(0.5)}rem;
      width: ${theme.spacing.gu(9)}rem;
      margin-left: ${theme.spacing.gu(1)}rem;
    `}

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const Wrapper = styled.div`
  span {
    ${({ theme }) => css`
      width: ${theme.spacing.gu(5)}rem;
    `}
  }
`
