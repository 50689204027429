import { ApolloCache, gql, useMutation, useQuery } from '@apollo/client'

import {
  CreateCustomerAddressPayload,
  CreateCustomerAddressVariables,
  CreateOrganizationContactPayload,
  CreateOrganizationContactVariables,
  RemoveAddressPayload,
  RemoveAddressVariables,
  RemoveOrganizationContactPayload,
  RemoveOrganizationContactVariables,
  SetDefaultAddressPayload,
  SetDefaultAddressVariables,
  SetDefaultOrganizationContactPayload,
  SetDefaultOrganizationContactVariables,
  UpdateAddressPayload,
  UpdateAddressVariables,
  UpdateCustomerOrganizationPayload,
  UpdateCustomerOrganizationVariables,
  UpdateCustomerPersonPayload,
  UpdateCustomerPersonVariables,
  UpdateOrganizationContactPayload,
  UpdateOrganizationContactVariables,
  UpdateOrganizationEInvoicingAddressPayload,
  UpdateOrganizationEInvoicingAddressVariables,
} from '../mutations'
import { Customer, CustomerAddressInput, CustomerContactInput } from '../types'
import {
  UseCustomerQuery as QueryData,
  UseCustomerQueryVariables as QueryVariables,
} from '~generated-types'
import customerFragments from '../fragments'
import { default as customerMutations } from '../mutations'

export const USE_CUSTOMER_QUERY = gql`
  ${customerFragments.customer}

  query UseCustomer($customerNumber: String!) {
    registry {
      customer: customerByNumber(customerNumber: $customerNumber) {
        ...Customer
      }
    }
  }
`

type Params = {
  customerNumber?: string | null | undefined
  forceRefetch?: boolean
}

export default function useCustomer({ customerNumber, forceRefetch }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    USE_CUSTOMER_QUERY,
    {
      fetchPolicy: !!forceRefetch ? 'cache-and-network' : 'cache-first',
      skip: !customerNumber,
      variables: { customerNumber: customerNumber || 'invalid-id' },
    }
  )

  const customer: Customer | null | undefined = data
    ? data.registry.customer
    : null

  const [handleCreateAddress] = useMutation<
    CreateCustomerAddressPayload,
    CreateCustomerAddressVariables
  >(customerMutations.CREATE_ADDRESS)

  const [handleCreateOrganizationContact] = useMutation<
    CreateOrganizationContactPayload,
    CreateOrganizationContactVariables
  >(customerMutations.CREATE_ORGANIZATION_CONTACT)

  const [handleRemoveAddress] = useMutation<
    RemoveAddressPayload,
    RemoveAddressVariables
  >(customerMutations.REMOVE_ADDRESS)

  const [handleRemoveOrganizationContact] = useMutation<
    RemoveOrganizationContactPayload,
    RemoveOrganizationContactVariables
  >(customerMutations.REMOVE_ORGANIZATION_CONTACT)

  const [handleSetDefaultAddress] = useMutation<
    SetDefaultAddressPayload,
    SetDefaultAddressVariables
  >(customerMutations.SET_DEFAULT_ADDRESS)

  const [handleSetDefaultOrganizationContact] = useMutation<
    SetDefaultOrganizationContactPayload,
    SetDefaultOrganizationContactVariables
  >(customerMutations.SET_DEFAULT_ORGANIZATION_CONTACT)

  const [handleUpdateAddress] = useMutation<
    UpdateAddressPayload,
    UpdateAddressVariables
  >(customerMutations.UPDATE_ADDRESS)

  const [handleUpdateOrganizationContact] = useMutation<
    UpdateOrganizationContactPayload,
    UpdateOrganizationContactVariables
  >(customerMutations.UPDATE_ORGANIZATION_CONTACT)

  const [handleUpdateOrganizationCustomer] = useMutation<
    UpdateCustomerOrganizationPayload,
    UpdateCustomerOrganizationVariables
  >(customerMutations.UPDATE_CUSTOMER_ORGANIZATION)

  const [handleUpdateOrganizationEInvoicingAddress] = useMutation<
    UpdateOrganizationEInvoicingAddressPayload,
    UpdateOrganizationEInvoicingAddressVariables
  >(customerMutations.UPDATE_ORGANIZATION_EINVOICING_ADDRESS)

  const [handleUpdatePersonCustomer] = useMutation<
    UpdateCustomerPersonPayload,
    UpdateCustomerPersonVariables
  >(customerMutations.UPDATE_CUSTOMER_PERSON)

  const createAddress = (
    customerId: string,
    customerNumber: string,
    address: CustomerAddressInput
  ) =>
    handleCreateAddress({
      update(cache, { data }) {
        const cachedData = getCachedData(cache, customerNumber)
        const cachedCustomer = cachedData?.registry.customer

        if (cachedCustomer && data) {
          cache.writeQuery<QueryData>(
            updateCustomerCache(cachedData, {
              ...cachedCustomer,
              addresses: [
                ...cachedCustomer.addresses,
                data.customerCreateAddress.address,
              ],
            })
          )
        }
      },
      variables: { input: { address, customerId } },
    })
      .then(({ data }) => data?.customerCreateAddress.address)
      .catch(() => undefined)

  const createOrganizationContact = (
    customerId: string,
    customerNumber: string,
    contact: CustomerContactInput
  ) =>
    handleCreateOrganizationContact({
      update(cache, { data }) {
        const cachedData = getCachedData(cache, customerNumber)
        const cachedCustomer = cachedData?.registry.customer

        if (
          cachedCustomer &&
          cachedCustomer.__typename === 'CustomerOrganization' &&
          data
        ) {
          cache.writeQuery<QueryData>(
            updateCustomerCache(cachedData, {
              ...cachedCustomer,
              contacts: [
                ...cachedCustomer.contacts,
                data.customerOrganizationCreateContact.contact,
              ],
            })
          )
        }
      },
      variables: { input: { contact, customerId } },
    })
      .then(({ data }) => data?.customerOrganizationCreateContact.contact)
      .catch(() => undefined)

  const removeAddress = (customerId: string, addressId: string) =>
    handleRemoveAddress({
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'CustomerAddress',
            id: addressId,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { input: { addressId, customerId } },
    })
      .then(({ data }) => data?.customerRemoveAddress.removedAddressId)
      .catch(() => undefined)

  const removeOrganizationContact = (customerId: string, contactId: string) =>
    handleRemoveOrganizationContact({
      update(cache, { data }) {
        if (data) {
          const normalizedId = cache.identify({
            __typename: 'CustomerOrganizationContact',
            id: contactId,
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        }
      },
      variables: { input: { contactId, customerId } },
    })
      .then(
        ({ data }) => data?.customerOrganizationRemoveContact.removedContactId
      )
      .catch(() => undefined)

  const setDefaultAddress = (customerId: string, addressId: string) =>
    handleSetDefaultAddress({ variables: { input: { addressId, customerId } } })
      .then(({ data }) => data?.customerSetDefaultAddress.customer)
      .catch(() => undefined)

  const setDefaultOrganizationContact = (
    customerId: string,
    contactId: string
  ) =>
    handleSetDefaultOrganizationContact({
      variables: { input: { contactId, customerId } },
    })
      .then(({ data }) => data?.customerOrganizationSetDefaultContact.customer)
      .catch(() => undefined)

  const updateAddress = (
    customerId: string,
    addressId: string,
    address: CustomerAddressInput
  ) =>
    handleUpdateAddress({
      variables: { input: { address, addressId, customerId } },
    })
      .then(({ data }) => data?.customerUpdateAddress.address)
      .catch(() => undefined)

  const updateOrganizationContact = (
    customerId: string,
    contactId: string,
    contact: CustomerContactInput
  ) =>
    handleUpdateOrganizationContact({
      variables: { input: { contact, contactId, customerId } },
    })
      .then(({ data }) => data?.customerOrganizationUpdateContact.contact)
      .catch(() => undefined)

  const updateOrganizationCustomer = (
    variables: UpdateCustomerOrganizationVariables
  ) =>
    handleUpdateOrganizationCustomer({ variables })
      .then(({ data }) => data?.customerOrganizationUpdate.customer)
      .catch(() => undefined)

  const updateOrganizationEInvoicingAddress = (
    variables: UpdateOrganizationEInvoicingAddressVariables
  ) =>
    handleUpdateOrganizationEInvoicingAddress({ variables })
      .then(
        ({ data }) => data?.customerOrganizationUpdateEInvoicingAddress.customer
      )
      .catch(() => undefined)

  const updatePersonCustomer = (variables: UpdateCustomerPersonVariables) =>
    handleUpdatePersonCustomer({ variables })
      .then(({ data }) => data?.customerPersonUpdate.customer)
      .catch(() => undefined)

  return {
    createAddress,
    createOrganizationContact,
    customer,
    error: !!error,
    loading,
    refetch,
    removeAddress,
    removeOrganizationContact,
    setDefaultAddress,
    setDefaultOrganizationContact,
    updateAddress,
    updateOrganizationContact,
    updateOrganizationCustomer,
    updateOrganizationEInvoicingAddress,
    updatePersonCustomer,
  }
}

////////

const getCachedData = (cache: ApolloCache<any>, customerNumber: string) =>
  cache.readQuery<QueryData>({
    query: USE_CUSTOMER_QUERY,
    variables: { customerNumber },
  })

const updateCustomerCache = (cache: QueryData, customer: Customer) => ({
  data: {
    ...cache,
    registry: {
      ...cache.registry,
      customer,
    },
  },
  query: USE_CUSTOMER_QUERY,
})
