import styled from 'styled-components/macro'

import {
  ElasticRoom,
  ElasticRoomGroup,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { FetchStates } from '@/common/types'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'

import {
  CalendarHeader,
  CalendarLoader,
  CalendarPlaceholder,
  RoomGroup,
} from './components'

export const LayoutCalendar = () => {
  const {
    onUpdateReservation,
    reservations,
    reservationsStatus,
    roomGroups,
    roomGroupsStatus,
    roomsBySelectedRoomType,
    targetAccommodationLevelIds,
    targetBuildingIds,
    targetFeatureIds,
    targetRoomSize,
  } = useRoomLayoutContext()

  const getSuitableRoomsNumber = (buildingId: string) =>
    roomsBySelectedRoomType.filter(
      (room: ElasticRoom) => room.building.id === buildingId
    ).length

  const isFilterActive =
    !!targetAccommodationLevelIds.length ||
    !!targetBuildingIds.length ||
    !!targetFeatureIds.length ||
    !!targetRoomSize

  const isLoading =
    roomGroupsStatus === FetchStates.LOADING ||
    reservationsStatus === FetchStates.LOADING

  return (
    <Wrapper>
      {isLoading && <CalendarLoader />}
      <ScrollWrapper>
        <ContentWrapper>
          <CalendarHeader />

          {!isFilterActive && roomGroupsStatus === FetchStates.EMPTY && (
            <CalendarPlaceholder>
              <T>RoomLayout:calendar.default</T>
            </CalendarPlaceholder>
          )}

          {isFilterActive && roomGroupsStatus === FetchStates.EMPTY && (
            <CalendarPlaceholder>
              <T>RoomLayout:calendar.empty</T>
            </CalendarPlaceholder>
          )}

          {roomGroupsStatus === FetchStates.ERROR && (
            <CalendarPlaceholder>
              <T>RoomLayout:calendar.error</T>
            </CalendarPlaceholder>
          )}

          {roomGroups
            .sort(generateCompareFn('building'))
            .map((group: ElasticRoomGroup, idx: number) => (
              <RoomGroup
                groupId={group.groupId}
                isFirst={idx === 0}
                isLast={idx === roomGroups.length - 1}
                isLoading={isLoading}
                key={`calendar-grid-group-${group.groupId}`}
                reservations={reservations}
                sections={group.rooms}
                suitableRoomsNumber={getSuitableRoomsNumber(group.groupId)}
                title={group.building}
                updateRoomReservation={onUpdateReservation}
              />
            ))}
        </ContentWrapper>
      </ScrollWrapper>
    </Wrapper>
  )
}

////////////

const ContentWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-height: 100%;
`

const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
`

const ScrollWrapper = styled.div`
  overflow: auto;
  flex: 1;
  position: relative;
`
