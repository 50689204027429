import { gql, useQuery } from '@apollo/client'

import {
  UseProductLinesTableQuery as QueryData,
  UseProductLinesTableQueryVariables as QueryVariables,
  UseProductLinesTablePriceElementsFragment,
} from '~generated-types'

export const QUERY = gql`
  fragment UseProductLinesTablePriceElements on PriceElements {
    amount
    amountVatIncluded
    vatAmount
  }
  query UseProductLinesTable($input: InvoicesByIdsInput!) {
    invoicesByIds(input: $input) {
      invoices {
        items {
          id
          name
          packetTotal {
            ...UseProductLinesTablePriceElements
          }
          packetUnitPrice {
            ...UseProductLinesTablePriceElements
          }
          products {
            code
            id
            name
            pricesPerPacket {
              ...UseProductLinesTablePriceElements
            }
            totalPrice {
              ...UseProductLinesTablePriceElements
            }
          }
          quantity
        }
        paymentInfo {
          totalPrice {
            ...UseProductLinesTablePriceElements
          }
        }
      }
    }
  }
`

type InvoiceRow = QueryData['invoicesByIds']['invoices'][0]['items'][0]
type Prices = UseProductLinesTablePriceElementsFragment

export interface InvoiceDetails {
  rows: InvoiceRow[]
  total: Prices
}

export interface UseProductLinesTableParams {
  invoiceId: string
}

export interface UseProductLinesTableHook {
  data: InvoiceDetails | null
  error: boolean
  loading: boolean
}

export default function useProductLinesTable({
  invoiceId,
}: UseProductLinesTableParams): UseProductLinesTableHook {
  const {
    data: queryData,
    error,
    loading,
  } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        ids: [invoiceId],
      },
    },
  })

  const targetInvoice = queryData?.invoicesByIds.invoices[0]

  const data: InvoiceDetails | null = targetInvoice
    ? {
        rows: targetInvoice.items,
        total: targetInvoice.paymentInfo.totalPrice,
      }
    : null

  return {
    data,
    error: !!error,
    loading,
  }
}
