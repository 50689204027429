import styled, { css } from 'styled-components/macro'
import { NavLink } from 'react-router-dom'

type Props = {
  alt?: string
  href: string
  src: string
}

export const AppBarLogo = ({ alt, href, src }: Props) => (
  <StyledAppBarLogo to={href}>
    <img src={src} alt={alt} />
  </StyledAppBarLogo>
)

///////

const StyledAppBarLogo = styled(NavLink)`
  display: block;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} 0 0;
    margin: 0 0 ${theme.spacing.gutterSmall};
    height: ${theme.spacing.gu(5)}rem;
  `}

  & > img {
    height: 100%;
  }
`
