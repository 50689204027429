import { gql, useMutation } from '@apollo/client'

import {
  SetSalesCustomerAddressMutation,
  SetSalesCustomerAddressMutationVariables,
} from '~generated-types'

export type {
  SetSalesCustomerAddressMutation,
  SetSalesCustomerAddressMutationVariables,
}

export const SET_SALES_CUSTOMER_ADDRESS_MUTATION = gql`
  mutation SetSalesCustomerAddress($input: SalesSetCustomerAddressInput!) {
    salesSetCustomerAddress(input: $input) {
      sales {
        id
      }
    }
  }
`

export function useSetSalesCustomerAddressMutation() {
  return useMutation<
    SetSalesCustomerAddressMutation,
    SetSalesCustomerAddressMutationVariables
  >(SET_SALES_CUSTOMER_ADDRESS_MUTATION)
}

export default useSetSalesCustomerAddressMutation
