import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Chip } from '@/components/Chip'
import { ElasticSales } from '@/modules/Sales'
import { T } from '@/modules/Language'

import { getSalesStateColor } from '../../utils'

type Props = {
  item: ElasticSales
}

export const Name = ({ item: { facet, name, orderNumber, state } }: Props) => (
  <Wrapper
    alignItems="flex-start"
    flex={1}
    noPadding
    justifyContent="space-between"
  >
    <FlexColumn noPadding>
      <OrderNumberWrapper>
        #{orderNumber}
        {'  |  '}
        <Facet>{facet.abbreviation}</Facet>
      </OrderNumberWrapper>

      {name && <SalesName>{name}</SalesName>}
    </FlexColumn>

    <SalesStateChip
      color={getSalesStateColor(state)}
      size="xs"
      variant="outlined"
    >
      <T>{`SalesDetails:Lifecycle.state.${state}`}</T>
    </SalesStateChip>
  </Wrapper>
)

////////////

const Facet = styled.span`
  font-weight: 600;
`

const OrderNumberWrapper = styled(FlexRow)`
  white-space: pre;
`

const SalesName = styled.span`
  font-weight: 600;
  margin-top: 3px;
  text-align: start;
  overflow-wrap: anywhere;
`

const SalesStateChip = styled(Chip)`
  width: fit-content;
  margin-top: 5px;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`
