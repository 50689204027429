import moment, { HTML5_FMT } from 'moment'

import {
  ListingLayout,
  OnlyInPrintSpan,
  PlaceholderLabel,
} from '@/modules/Listing/common'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { formatDateRangeWithoutRepetition } from '@/utils/time'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { RadioInput } from '@/components/FormControls'
import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'
import { WeekPicker } from '@/components/TimeControls'

import { Mode, SalesForIsoWeekSales } from './types'
import { CellSizeProvider } from './CellSizeContext'
import { PrintSize } from '../types'
import SalesTable from './components/SalesTable'

interface SalesListingProps {
  error: boolean
  loading: boolean
  mode: Mode
  sales: SalesForIsoWeekSales[]
  setMode: (mode: Mode) => void
  setTargetIsoWeek: (week: number, year: number) => void
  targetIsoWeek: number
  targetIsoWeekYear: number
  typeFilter?: SalesType[]
}

export const SalesListing = ({
  error,
  loading,
  mode,
  sales,
  setMode,
  setTargetIsoWeek,
  targetIsoWeek: week,
  targetIsoWeekYear: year,
  typeFilter,
}: SalesListingProps) => {
  const theme = useTheme()

  const from = moment(`${year}W${week}`, 'YYYY[W]W').startOf('isoWeek')
  const to = moment(from).endOf('isoWeek')

  const renderContent = (printSize: PrintSize) => {
    if (loading) {
      return <LoadingPlaceholder flex={1} size="xl" />
    }

    if (error) {
      return (
        <PlaceholderLabel>
          <T>Listings:SalesListing.error</T>
        </PlaceholderLabel>
      )
    }

    if (!sales.length) {
      return (
        <PlaceholderLabel>
          <T>Listings:SalesListing.empty</T>
        </PlaceholderLabel>
      )
    }

    return (
      <CellSizeProvider>
        <SalesTable
          data={sales}
          isoWeek={week}
          isoWeekYear={year}
          mode={mode}
          printSize={printSize}
          typeFilter={typeFilter}
        />
      </CellSizeProvider>
    )
  }

  const renderSidebar = () => (
    <>
      <FilterSection
        label={<T>Listings:SalesListing.mode.label</T>}
        render={() => (
          <>
            <RadioInput
              checked={mode === Mode.FULL}
              onChange={() => setMode(Mode.FULL)}
            >
              <T>Listings:SalesListing.mode.FULL</T>
            </RadioInput>
            <RadioInput
              checked={mode === Mode.CONCISE}
              onChange={() => setMode(Mode.CONCISE)}
            >
              <T>Listings:SalesListing.mode.CONCISE</T>
            </RadioInput>
          </>
        )}
      />
      <FilterSection
        label={<T>Listings:SalesListing.filter.interval</T>}
        render={() => (
          <div style={{ margin: `-${theme.spacing.gu(1)}rem` }}>
            <WeekPicker
              numberOfWeeks={1}
              setValue={(selection) =>
                selection
                  ? setTargetIsoWeek(selection.week, selection.year)
                  : setTargetIsoWeek(-1, -1)
              }
              value={{ week, year }}
            />
          </div>
        )}
      />
    </>
  )

  return (
    <ListingLayout
      renderContent={renderContent}
      renderSidebar={renderSidebar}
      title={
        <>
          <T>Listings:SalesListing.title</T>
          <OnlyInPrintSpan>
            {' – '}
            <T l10n={`Listings:SalesListing.mode.${mode}`}></T>
            <small>
              {' | '}
              {formatDateRangeWithoutRepetition(
                from.format(HTML5_FMT.DATE),
                to.format(HTML5_FMT.DATE),
                'short'
              )}
            </small>
          </OnlyInPrintSpan>
        </>
      }
    />
  )
}

export default SalesListing
