import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FontWeight } from '@/components/Typography'
import { InlineModal } from '@/components/InlineModal'
import { Input } from '@/components/FormControls'
import { ItemsList } from '@/components/Modal/components/ItemsList'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

import { GroupTarget } from '../../../SalesReservationList.types'

type Props = {
  targets: GroupTarget[]
  roomTypeRooms?: number
  roomReservations?: number
  readOnly?: boolean
  isNested?: boolean
  isLast?: boolean
  targetAccommodation: (input: string, rooms: number) => Promise<void>
}

export const TargetSelector = ({
  targets,
  roomTypeRooms,
  roomReservations,
  readOnly,
  isNested,
  isLast,
  targetAccommodation,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [rooms, setRooms] = useState<number | null>(roomTypeRooms || null)

  const options = targets.map((target) => ({
    disabled: false,
    label: target.default
      ? translate('Accommodation:TargetGroup.default', language)
      : target.name
      ? target.name
      : `${translate(`Accommodation:TargetGroup.target`, language)} #${
          target.sortOrder
        }`,
    value: target.id,
  }))

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <FlexColumn
            flex={1}
            alignItems={'center'}
            style={{
              maxHeight: '370px',
              minWidth: '250px',
              padding: `${theme.spacing.gu(2)}rem 0`,
            }}
          >
            {rooms !== null && (
              <FlexRow style={{ marginBottom: `${theme.spacing.gu(1)}rem` }}>
                <T>Accommodation:TargetGroup.use</T>
                <NumberInput
                  min="1"
                  max={roomTypeRooms}
                  disabled={!!roomReservations}
                  onChange={(e) =>
                    setRooms(
                      Number(e.target.value) > (roomTypeRooms || 0)
                        ? roomTypeRooms || 0
                        : Number(e.target.value)
                    )
                  }
                  onFocus={(e) => e.currentTarget.select()}
                  type="number"
                  value={`${rooms}`}
                  style={{
                    width: `${theme.spacing.gu(2 + String(rooms).length)}rem`,
                  }}
                />
                <T>Accommodation:TargetGroup.roomTypes</T>
              </FlexRow>
            )}
            <FontWeight
              semiBold
              style={{
                fontSize: theme.typography.fontSizeBase2,
                marginBottom: `${theme.spacing.gu(1)}rem`,
              }}
            >
              <T>Accommodation:TargetGroup.selectTarget</T>
            </FontWeight>

            <ItemsList
              options={options}
              handleChange={(value: string) =>
                targetAccommodation(value, rooms || 0)
              }
              setModalOpen={setModalOpen}
            />
          </FlexColumn>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <SelectorButton
          readOnly={readOnly}
          borderTop={!isNested}
          borderBottom={isLast}
          ref={ref}
          onClick={() => !readOnly && setModalOpen(true)}
        >
          <FontAwesomeIcon
            color={theme.palette.coal.light}
            fixedWidth
            icon="users-cog"
          />
        </SelectorButton>
      )}
    />
  )
}

const SelectorButton = styled.div<{
  borderTop?: boolean
  borderBottom?: boolean
  readOnly?: boolean
}>`
  display: flex;
  align-items: center;
  align-self: stretch;

  cursor: pointer;
  transition: 0.2s;

  ${({ borderTop, borderBottom }) => css`
    ${borderTop && `border-top-right-radius: 5px`};
    ${borderBottom && `border-bottom-right-radius: 5px`};
  `}

  ${({ theme, readOnly }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;

    ${readOnly &&
    css`
      cursor: not-allowed;
      background-color: ${theme.palette.smoke.light};
    `}

    &:hover {
      background-color: ${theme.palette.smoke.light};
    }
  `}
`

const NumberInput = styled(Input)`
  &&& {
    text-align: end;

    ${({ theme }) => css`
      border: 1px solid ${theme.palette.white};
      color: ${theme.palette.primary.dark};
      font-size: ${theme.typography.fontSizeBase};
      height: ${theme.spacing.gu(3)}rem;
      padding: 0 ${theme.spacing.gutterSmall};
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
