import { getFullName } from './getFullName'
import { MobileSalesCustomer } from '../types'

export const parseCustomer = ({
  address,
  contact,
  customer,
}: MobileSalesCustomer) => {
  const currentAddress = customer.addresses.find(({ id }) => id === address?.id)
  const postalAddress = currentAddress?.postalAddress ?? null

  if (customer.__typename === 'CustomerPerson') {
    const {
      person: { email, firstName, lastName, phone },
    } = customer

    return {
      contactPerson: null,
      email,
      name: getFullName(firstName, lastName),
      phone,
      postalAddress,
    }
  }

  const {
    contacts,
    organization: { name },
  } = customer

  const currentContact = contacts.find(({ id }) => id === contact?.id)

  return {
    contactPerson: currentContact
      ? getFullName(
          currentContact.firstName.trim(),
          currentContact.lastName.trim()
        )
      : null,
    email: currentContact?.email ?? null,
    name,
    phone: currentContact?.phone ?? null,
    postalAddress,
  }
}
