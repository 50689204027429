import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { orderContexts, orderServices } from '@/modules/Order'
import { PaymentType as PaymentMethod, PaymentState } from '~generated-types'
import { Option } from '@/components/ThemedSelect'
import { Payment } from '@/modules/Order/types'
import { T } from '@/modules/Language'

import {
  Actions,
  AmountInput,
  GiftCardCodeInput,
  NotesTextarea,
  PaymentTypesSection,
  PointOfSaleSelector,
  TerminalFailSection,
  TerminalWaitingSection,
} from './components'
import { PaymentType } from './types'

type Props = {
  invoiceId: string
  orderId: string
  payableAmount: number
  payments: Payment[]
  refreshDocument: () => void
}

export const PaymentManager = ({
  invoiceId,
  orderId,
  payableAmount,
  payments,
  refreshDocument,
}: Props) => {
  const {
    addCashPayment,
    addCreditCardPayment,
    addGiftCardPayment,
    addVoucherPayment,
  } = orderServices.invoiceService()
  const { observablePayment, setObservablePayment } =
    orderContexts.useObservablePayment()

  const [amount, setAmount] = useState<number>(payableAmount)
  const [code, setCode] = useState<string>('')
  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [notes, setNotes] = useState<string>('')
  const [paymentType, setPaymentType] = useState<PaymentType | null>(null)
  const [pointOfSale, setPointOfSale] = useState<Option | null>(null)

  const paidPayments = payments.filter(
    ({ state }) =>
      state === PaymentState.Paid || state === PaymentState.PaidPending
  )

  useEffect(() => {
    setAmount(payableAmount)
  }, [payableAmount])

  useEffect(() => {
    if (!!paidPayments.length) {
      refreshDocument()
      setPaymentType(null)
      setCode('')
      setNotes('')
    }
  }, [paidPayments.length])

  const isPaymentFailed = observablePayment?.state === PaymentState.Failed
  const isPaymentInProgress =
    observablePayment?.state === PaymentState.InProgress

  const readOnly = isProcessing || isPaymentFailed || isPaymentInProgress

  const handleAddPayment = () => {
    const pointOfSaleId = pointOfSale?.value || ''
    const providerId = paymentType?.providerId || ''
    const terminalId = paymentType?.terminalId

    const attributes = { amount, invoiceId, notes, orderId, pointOfSaleId }

    const doAddPayment = () => {
      setProcessing(true)

      switch (paymentType?.method) {
        case PaymentMethod.CreditCard:
          return addCreditCardPayment({ ...attributes, terminalId })
        case PaymentMethod.GiftCard:
          return addGiftCardPayment({ ...attributes, code })
        case PaymentMethod.Voucher:
          return addVoucherPayment({ ...attributes, providerId })
        case PaymentMethod.Cash:
        default:
          return addCashPayment(attributes)
      }
    }

    return doAddPayment().then((payment) => {
      if (payment) {
        const isPaidPayment =
          payment.state === PaymentState.Paid ||
          payment.state === PaymentState.PaidPending

        if (!isPaidPayment) {
          setObservablePayment((prevPayment) => prevPayment ?? payment)
        }
      }
      setProcessing(false)
    })
  }

  const renderDynamicSection = () => {
    if (isPaymentInProgress && observablePayment) {
      return (
        <TerminalWaitingSection
          invoiceId={invoiceId}
          orderId={orderId}
          paymentId={observablePayment.id}
        />
      )
    }

    if (isPaymentFailed && observablePayment) {
      return (
        <TerminalFailSection
          paymentDetails={observablePayment.externalPayment}
          onClose={() => setObservablePayment(null)}
        />
      )
    }

    return (
      <>
        <PaymentTypesSection
          paymentType={paymentType}
          pointOfSaleId={pointOfSale?.value ?? null}
          setPaymentType={setPaymentType}
        />

        <LineCompact />

        <Actions
          handleAddPayment={handleAddPayment}
          isProcessing={isProcessing}
          values={{ amount, paymentType, pointOfSale }}
        />
      </>
    )
  }

  return (
    <Wrapper noPadding>
      <Title>
        <T>Orders:Payments.modal.title</T>
      </Title>

      <BasicDetailsWrapper>
        <AmountInput
          disabled={readOnly}
          value={amount.toString()}
          payableAmount={payableAmount}
          setAmount={setAmount}
        />

        <Spacer />

        <PointOfSaleSelector
          disabled={readOnly}
          pointOfSale={pointOfSale}
          setPointOfSale={setPointOfSale}
        />
      </BasicDetailsWrapper>

      <NotesTextarea disabled={readOnly} notes={notes} setNotes={setNotes} />

      {paymentType?.method === PaymentMethod.GiftCard && (
        <GiftCardCodeInput code={code} setCode={setCode} />
      )}

      <Line />

      {renderDynamicSection()}
    </Wrapper>
  )
}

///////

const BasicDetailsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}
`

const Line = styled.span`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(2)}rem 0;
  `}
`

const LineCompact = styled(Line)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
    padding: ${theme.spacing.gutter};
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const Wrapper = styled(FlexColumn)`
  border-radius: 6px;
  background: #f8fafb;

  ${({ theme }) => css`
    margin: ${theme.spacing.gutter};
    border: 1px solid ${theme.palette.smoke.main};
  `}
`
