import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { T } from '@/modules/Language'

type Props = {
  payableAmount: number
  totalAmount: number
}

export const Totals = ({ payableAmount, totalAmount }: Props) => (
  <Wrapper>
    <LeftToPayRow>
      <T>Orders:Payments.leftToPay</T>
      <Separator />
      {formatCurrency(payableAmount)} €
    </LeftToPayRow>
    <TotalRow>
      <T>Orders:Payments.totalPaid</T>
      <Separator />
      {formatCurrency((totalAmount * 1000 - payableAmount * 1000) / 1000)} €
    </TotalRow>
  </Wrapper>
)

///////

const Separator = styled.span`
  flex: 1;

  ${({ theme }) => css`
    border-top: 1px dashed ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gutter};
  `}
`

const TotalRow = styled(FlexRow)`
  align-items: center;
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeBase2};
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const LeftToPayRow = styled(TotalRow)`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutterBig}
      ${theme.spacing.gu(2)}rem;
  `}
`
