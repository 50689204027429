import { CSSProperties, MouseEvent, ReactNode, Ref } from 'react'
import { Manager, Popper, Reference, StrictModifier } from 'react-popper'
import { createPortal } from 'react-dom'
import { Placement as PopperPlacement } from 'popper.js'
import ReactResizeDetector from 'react-resize-detector'

import { Shadow } from '@/components/SideDrawer/SideDrawerShadow'

export type Placement = PopperPlacement

type Props = {
  // Use this flag to keep the modal mounted in the DOM and toggle visibility
  // via styles only. This might be useful if it's good to avoid remounting
  // the popper content often
  keepMounted?: boolean
  modal: ReactNode
  modalIsOpen?: boolean
  onShadowClick: (e: MouseEvent<HTMLDivElement>) => void
  popperModifiers: StrictModifier[]
  popperPlacement?: Placement
  referenceElement: (props: { ref?: Ref<HTMLDivElement> }) => ReactNode
  styleOverrides?: CSSProperties
  zIndex?: number
}

export const PopperWithShadow = ({
  keepMounted,
  modal,
  modalIsOpen = true,
  onShadowClick,
  popperModifiers,
  popperPlacement = 'bottom-start',
  referenceElement,
  styleOverrides = {},
  zIndex,
}: Props) => {
  const defaultPopperModifiers = [
    {
      name: 'offset',
      options: {
        offset: [0, 4],
      },
    },
  ]

  const isMounted = keepMounted || modalIsOpen
  const isVisible = keepMounted ? keepMounted && modalIsOpen : modalIsOpen

  const visibilityStyles = isVisible
    ? {}
    : {
        pointerEvents: 'none',
        visibility: 'hidden',
      }

  return (
    <Manager>
      <Reference>{referenceElement}</Reference>

      {isMounted &&
        createPortal(
          <>
            <Popper
              placement={popperPlacement}
              modifiers={[...defaultPopperModifiers, ...popperModifiers]}
            >
              {({ placement, ref, update, style }) => (
                <div
                  ref={ref}
                  placement={placement}
                  // @ts-ignore
                  style={{
                    ...style,
                    ...styleOverrides,
                    ...visibilityStyles,
                    zIndex: zIndex ?? 10002,
                  }}
                >
                  <ReactResizeDetector
                    handleHeight
                    handleWidth
                    onResize={update}
                    skipOnMount
                  >
                    {modal}
                  </ReactResizeDetector>
                </div>
              )}
            </Popper>

            {isVisible && (
              <Shadow
                onClick={onShadowClick}
                style={{
                  height: '100vh',
                  overflow: 'auto',
                  zIndex: zIndex ? zIndex - 1 : 10001,
                }}
              />
            )}
          </>,
          document.getElementById('popper-root') as any
        )}
    </Manager>
  )
}
