import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import Collapse from '@kunukn/react-collapse'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'

type Props = {
  children: ReactNode
  extraAction?: ReactNode
  extraDetails?: ReactNode
  isExpandable: boolean
  title: ReactNode
}

export const Section = ({
  children,
  extraAction,
  extraDetails,
  isExpandable,
  title,
}: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(false)

  return (
    <>
      <Wrapper alignItems="center" flex={1} isOpen={isExpanded}>
        <Button
          flex={1}
          onClick={() => isExpandable && setExpanded(!isExpanded)}
        >
          <FlexColumn alignItems="flex-start" flex={1} noPadding>
            <TitleWrapper alignItems="center">
              <Title>{title}</Title>

              {isExpanded && extraAction}

              {isExpandable && (
                <ChevronIcon
                  icon="chevron-up"
                  rotation={isExpanded ? 180 : 90}
                />
              )}
            </TitleWrapper>

            {extraDetails}
          </FlexColumn>
        </Button>
      </Wrapper>

      <CollapseWrapper isOpen={isExpanded}>{children}</CollapseWrapper>
    </>
  )
}

/////

const TitleWrapper = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const Button = styled(FlexRow)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const ChevronIcon = styled(FontAwesomeIcon)`
  transition: 0.25s;
  width: 12px;
  height: 12px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-left: ${theme.spacing.gu(2)}rem;
  `}
`

const CollapseWrapper = styled(Collapse)`
  transition: height 0.25s;
  overflow: visible;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 4px 0px inset;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`

const Title = styled.span`
  font-weight: 600;
  flex: 1;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Wrapper = styled(FlexRow)<{ isOpen: boolean }>`
  transition: border 0.25s;

  ${({ isOpen, theme }) => css`
    border-bottom: 1px solid
      ${isOpen ? 'transparent' : theme.palette.smoke.light};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}
`
