import { gql } from '@apollo/client'

export default gql`
  query SalesValidatedActions($id: ID!) {
    sales(id: $id) {
      id
      lifecycle {
        validatedActions {
          action
          issues {
            code
            key
            level
          }
          valid
        }
      }
    }
  }
`
