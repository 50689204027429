import React, { ElementRef, ReactNode, useState } from 'react'
import { Moment } from 'moment'

import { ModalContainer } from '@/components/Modal'
import { Placement } from '@/components/PopperWithShadow'

import { default as DatePicker, DatePickerProps } from './DatePicker'
import ControlModal from './components/ControlModal'

type Props = DatePickerProps & {
  clearable?: boolean
  dontCloseOnSelect?: boolean
  placement?: Placement
  render: (arg0: {
    closeModal: (...args: Array<any>) => any
    date: Moment | null | undefined
    openModal: (...args: Array<any>) => any
    ref: ElementRef<any>
    toggleModal: (...args: Array<any>) => any
  }) => ReactNode
}

const DateControl = ({
  clearable,
  dontCloseOnSelect,
  placement,
  render,
  setValue,
  ...datePickerProps
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={open}
      modal={
        <ControlModal
          hasValue={!!datePickerProps.value}
          // @ts-ignore
          onClear={clearable && (() => setValue(null))}
        >
          <DatePicker
            {...datePickerProps}
            setValue={(date) => {
              // @ts-ignore
              setValue(date)

              if (!dontCloseOnSelect) {
                setOpen(false)
              }
            }}
          />
        </ControlModal>
      }
      onClose={() => setOpen(false)}
      placement={placement}
      referenceElement={({ ref }) =>
        render({
          closeModal: () => setOpen(false),
          // @ts-ignore
          date: datePickerProps.value,
          openModal: () => setOpen(true),
          ref,
          toggleModal: () => setOpen((current) => !current),
        })
      }
      zIndex={10003}
    />
  )
}

export default DateControl
