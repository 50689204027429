import React, { Fragment } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useGlobalIconsContext, useTheme } from '@/theme'
import { generateCompareFn } from '@/utils/arrays'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'

type Props = {
  selectedFeatures: RoomFeature[]
}

export const SelectedFeatures = ({ selectedFeatures }: Props) => {
  const { spacing } = useTheme()
  const { checkIconValidity } = useGlobalIconsContext()

  return (
    <Wrapper>
      {[...selectedFeatures]
        .sort(generateCompareFn('name'))
        .map((feature: RoomFeature) => (
          <Fragment key={feature.id}>
            {checkIconValidity(feature.icon) ? (
              <FontAwesomeIcon
                fixedWidth
                icon={feature.icon as IconProp}
                style={{ marginRight: spacing.gutterSmall }}
              />
            ) : (
              <ShortName>{feature.shortName}</ShortName>
            )}
          </Fragment>
        ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ShortName = styled.span`
  font-weight: 500;
  line-height: 1;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
