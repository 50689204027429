import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import {
  Feature,
  PurchaseProductAction as PA,
  ServicePurchaseFragment,
} from '~generated-types'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { SalesProductManager } from '@/modules/Products'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'

type Purchase = NonNullable<ServicePurchaseFragment['purchaseProduct']>

type Props = {
  disabled: boolean
  handleRemovePurchase: (purchaseId: string) => Promise<any>
  purchase: Purchase
}

const SimpleProduct = ({
  disabled,
  handleRemovePurchase,
  purchase: {
    id,
    product,
    status: { issues, validatedActions },
  },
}: Props) => {
  const { palette, spacing } = useTheme()

  const {
    data: {
      commissionRate,
      facet: { features },
      id: salesId,
      seller,
      type: salesType,
    },
    saleReadOnly,
  } = useSalesDetailsContext()

  const isCommissionEnabled = !!features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  const isRemovable = validatedActions.find(
    (a) => a.action === PA.Delete
  )?.valid

  const renderContent = () => (
    <ContentWrapper justifyContent="flex-start">
      {product.name ? (
        <Name>
          <FontAwesomeIcon
            color={palette.primary.dark}
            icon="cube"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />

          {issues.map(({ code, key, level }) => (
            <ValidationIndicatorWrapper key={`${key}-${code}`}>
              <ValidationIndicator
                code={code}
                level={level}
                style={{ margin: `0 ${spacing.gutterSmall}` }}
              />
            </ValidationIndicatorWrapper>
          ))}

          {product.name}
        </Name>
      ) : (
        <Label>
          – <T>ResourceReservations:product.state.empty</T> –
        </Label>
      )}
    </ContentWrapper>
  )

  return (
    <>
      <ModalContainer
        isOpen={modalOpen}
        referenceElement={({ ref }) => (
          <ServiceItemSquared
            disabled={disabled}
            isRemovable={!disabled && isRemovable}
            margin={`0 0 0 ${spacing.gu(1)}rem`}
            minWidth="8rem"
            onClick={() => !disabled && setModalOpen(true)}
            onRemove={() => handleRemovePurchase(id)}
            ref={ref}
            renderContent={renderContent}
          />
        )}
        onClose={(e) => {
          e.stopPropagation()
          setModalOpen(false)
        }}
        modal={
          <SalesProductManager
            commission={commission}
            context="SALES"
            onClose={() => setModalOpen(false)}
            productId={product.id}
            readOnly={saleReadOnly}
            salesId={salesId}
            salesType={salesType}
            sellerId={seller?.id}
            targetPurchaseId={id}
          />
        }
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />
    </>
  )
}

export default SimpleProduct

const ContentWrapper = styled(FlexRow)`
  min-width: 5rem;
  width: 100%;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Name = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`
