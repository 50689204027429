import { useState } from 'react'

import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { CatalogProductPicker } from '../../../CatalogProductPicker'
import { useSalesProductListContext } from '../../SalesProductListState'

export const CatalogProductPickerButton = () => {
  const {
    data: { seller },
    saleReadOnly,
  } = useSalesDetailsContext()
  const { createProduct, deleteProduct } = useSalesProductListContext()

  const [isProductPickerOpen, setProductPickerOpen] = useState<boolean>(false)

  const handleCreateProduct = (id: string) =>
    createProduct(id).then((data) => (data ? [data.id] : undefined))

  const handleDeleteProduct = (ids: string[]) => deleteProduct(ids[0])

  return (
    <>
      <InnocuousButton
        disabled={saleReadOnly}
        onClick={() => setProductPickerOpen(true)}
      >
        <PrimaryColor>
          <T>Products:SalesProductList.openCatalog</T>
        </PrimaryColor>
      </InnocuousButton>

      {isProductPickerOpen && (
        <CatalogProductPicker
          isMulti
          onClose={() => setProductPickerOpen(false)}
          onItemSelect={handleCreateProduct}
          onItemUnselect={handleDeleteProduct}
          sellerId={seller?.id}
        />
      )}
    </>
  )
}
