import { Moment } from 'moment'

import { FlexRow } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { PlaceholderLabel } from '@/modules/Listing/common'
import { T } from '@/modules/Language'

import DeparturesListing from './DeparturesListing'
import { PrintSize } from '../../types'
import useDeparturesForDate from './useDeparturesForDate'

interface DeparturesListingContainerProps {
  printSize: PrintSize
  targetDate: Moment
}

export const DeparturesListingContainer = ({
  printSize,
  targetDate,
}: DeparturesListingContainerProps) => {
  const { error, loading, sales } = useDeparturesForDate({
    date: targetDate,
  })

  if (loading) {
    return (
      <FlexRow>
        <LoadingPlaceholder flex={1} size="xl" />
      </FlexRow>
    )
  }

  if (error) {
    return (
      <PlaceholderLabel>
        <T>Listings:ArrivalsAndDeparturesListing.departures.error</T>
      </PlaceholderLabel>
    )
  }

  if (!sales.length) {
    return (
      <PlaceholderLabel>
        <T>Listings:ArrivalsAndDeparturesListing.departures.empty</T>
      </PlaceholderLabel>
    )
  }

  return (
    <DeparturesListing
      printSize={printSize}
      sales={sales}
      targetDate={targetDate}
    />
  )
}

export default DeparturesListingContainer
