import { gql, useMutation } from '@apollo/client'

import { MealFragment } from '../fragments'

import type {
  ResetMealMutation,
  ResetMealMutationVariables,
} from '~generated-types'

const RESET_MEAL_MUTATION = gql`
  ${MealFragment}

  mutation ResetMeal($id: ID!) {
    scheduledMealResetDefaults(id: $id) {
      ...Meal
    }
  }
`

export const useResetMealMutation = () =>
  useMutation<ResetMealMutation, ResetMealMutationVariables>(
    RESET_MEAL_MUTATION
  )
