import { ScheduledMealStatus } from '~generated-types'

import { SalesForPrintMeal } from '../../../../../types'

export const getGroupedMeals = (meals: SalesForPrintMeal[]) =>
  meals
    .filter((meal) => meal.status !== ScheduledMealStatus.ManuallyRemoved)
    .reduce((acc: SalesForPrintMeal[], curr) => {
      const similarMeal = acc.findIndex(
        (item) =>
          item.start === curr.start &&
          item.duration === curr.duration &&
          item.location?.name === curr.location?.name &&
          item.meal?.name === curr.meal?.name
      )

      if (similarMeal !== -1) {
        acc[similarMeal].quantities.forEach(({ quantity }, idx) => {
          acc[similarMeal].quantities[idx].quantity =
            quantity + curr.quantities[idx].quantity
        })
      } else {
        return [...acc, curr]
      }

      return acc
    }, [])
