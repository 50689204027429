import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ReactLoading from 'react-loading'

import { FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { useTheme } from '@/theme'

type Props = {
  color?: 'success' | 'danger'
  disabled?: boolean
  loading?: boolean
  icon: IconProp
  children: React.ReactElement
  onClick: () => void
}

export const ActionButton = ({
  color,
  disabled,
  loading,
  children,
  onClick,
}: Props) => {
  const theme = useTheme()

  return (
    <InnocuousButton disabled={disabled} onClick={onClick}>
      <ButtonWithColor color={color}>
        {loading ? (
          <LoaderWrapper>
            <ReactLoading
              type="spin"
              height={12}
              width={12}
              color={
                color === 'success'
                  ? theme.palette.success.main
                  : color === 'danger'
                  ? theme.palette.danger.main
                  : theme.palette.smoke.extraDark
              }
            />
          </LoaderWrapper>
        ) : (
          <FontAwesomeIcon
            icon="check"
            style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
          />
        )}

        {children}
      </ButtonWithColor>
    </InnocuousButton>
  )
}

const ButtonWithColor = styled(FlexRow)<{
  color?: string
}>`
  ${({ theme, color }) => css`
    color: ${color === 'success'
      ? theme.palette.success.main
      : color === 'danger'
      ? theme.palette.danger.main
      : theme.palette.primary.main};
  `}
`

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
