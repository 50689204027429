import * as MomentBase from 'moment'
import moment, { Moment } from 'moment'
import { extendMoment } from 'moment-range'

import {
  ElasticRoomReservation,
  MomentDateRange,
} from '@/modules/Reservations/components/RoomLayout'
import { ItemPosition } from '@/components/Grid'

import { Column, getColumns } from './getColumns'

const mapDatesToColumns = (startStr: string, endStr: string) => {
  const start = moment(startStr)
  const end = moment(endStr)
  const range = extendMoment(MomentBase).range(start, end)
  const intervals = Array.from(range.by('days', { step: 1 })).map(
    (interval: Moment) => interval.format('YYYY-MM-DD')
  )
  return intervals
}

export const mapDataToPositions = (
  gridId: string,
  overlapOffsets: {
    [key: string]: number
  },
  reservation: ElasticRoomReservation,
  targetDateRange: MomentDateRange
): ItemPosition[] => {
  const {
    id,
    request: { checkIn, checkOut },
  } = reservation

  const columnIds = mapDatesToColumns(checkIn.date, checkOut.date)
  const allColumns = getColumns(targetDateRange)

  const columnIndexes = columnIds.map((cId: string) => {
    const targetColumn = allColumns.find((c: Column) => c.id === cId)
    return targetColumn ? targetColumn.index : -1
  })

  let prevColumnIndexes: number[] = []
  const firstVisibleIndex = columnIndexes.findIndex((item) => item === 0)

  if (firstVisibleIndex !== -1) {
    prevColumnIndexes = columnIndexes.slice(0, firstVisibleIndex)
    prevColumnIndexes = prevColumnIndexes
      .map((_, index) => (index + 1) * -1)
      .reverse()
  }

  let nextColumnIndexes: number[] = []
  const lastVisibleIndex = columnIndexes.findIndex(
    (item) => item === allColumns.length - 1
  )

  if (lastVisibleIndex !== -1) {
    nextColumnIndexes = columnIndexes.slice(lastVisibleIndex + 1)
    nextColumnIndexes = nextColumnIndexes.map(() => allColumns.length - 1)
  }

  const visibleColumnIndexes = columnIndexes.filter((index) => index >= 0)
  const rowIndexes = [overlapOffsets[id] ?? 0]

  return [
    {
      columnIndexes: [
        ...prevColumnIndexes,
        ...visibleColumnIndexes,
        ...nextColumnIndexes,
      ],
      dataId: id,
      gridId,
      id: `${gridId}.${id}`,
      rowIndexes,
    },
  ]
}
