import { Moment } from 'moment'
import styled from 'styled-components/macro'

import { BottomSheet } from '@/components/BottomSheet'
import { DeparturesListing } from '@/modules/Listing/ArrivalsAndDeparturesListing'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'

import { PrintSize } from '@/modules/Listing/types'

export interface PrintableDeparturesListProps {
  onClose: () => void
  printSize: PrintSize
  targetDate: Moment
}

export const PrintableDeparturesList = ({
  onClose,
  printSize,
  targetDate,
}: PrintableDeparturesListProps) => (
  <BottomSheet
    onClose={onClose}
    title={
      <>
        <T>Listings:ArrivalsAndDeparturesListing.departures.title</T>
        {' | '}
        {targetDate.format('dd D.M.YYYY')}
      </>
    }
  >
    <Gutter ignoreInPrint type={[2, 5, 10]}>
      <Wrapper>
        <DeparturesListing printSize={printSize} targetDate={targetDate} />
      </Wrapper>
    </Gutter>
  </BottomSheet>
)

export default PrintableDeparturesList

////////////

const Wrapper = styled.div`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0;
  overflow: visible;

  @media print {
    max-width: 100%;
    padding: 0;
  }
`
