import React from 'react'

import {
  CalendarStateProvider,
  CalendarStateProviderProps,
} from './CalendarState'
import Calendar from './Calendar'
import { ReservationsGridStateProvider } from './components/Calendar/ReservationsGrid'

export default function CalendarContainer(props: CalendarStateProviderProps) {
  return (
    <CalendarStateProvider {...props}>
      <ReservationsGridStateProvider>
        <Calendar />
      </ReservationsGridStateProvider>
    </CalendarStateProvider>
  )
}
