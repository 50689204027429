import moment from 'moment'
import React from 'react'

import { BottomSheet } from '@/components/BottomSheet'
import { Calendar } from '@/modules/Reservations'
import { T } from '@/modules/Language'

import { ReservationList } from './components'
import { useCompactViewContext } from '../../../SaleCompactView/CompactViewContainer'
import { useResourceReservationListContext } from './ReservationsState'
import { useSalesDetailsContext } from '../../'

export const Reservations = () => {
  const {
    data: { id },
    saleReadOnly,
  } = useSalesDetailsContext()

  const { closeCalendar, showCalendar, targetDate } =
    useResourceReservationListContext()

  const { salesId } = useCompactViewContext()

  return (
    <>
      <ReservationList readOnly={saleReadOnly} />

      {showCalendar && (
        <BottomSheet
          onClose={closeCalendar}
          title={<T>SalesDetails:overlay.resources</T>}
        >
          <Calendar
            initialDate={targetDate ? moment(new Date(targetDate)) : moment()}
            ownerId={salesId || id}
          />
        </BottomSheet>
      )}
    </>
  )
}
