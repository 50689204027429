import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Option, ThemedCreatableSelect } from '@/components/ThemedSelect'
import { Spacing, useTheme } from '@/theme'
import { T, translate, useLanguageContext } from '@/modules/Language'
import {
  useSalesProductManagerContext,
  useSalesProductsGroups,
} from '@/modules/Products/hooks'
import { EditButton } from '@/modules/Products/components/common'

type Props = {
  group: string | null
  id: string
}

export const GroupSelector = ({ group, id }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()
  const { salesId, salesReadOnly, updateProduct } =
    useSalesProductManagerContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const { loading, groups } = useSalesProductsGroups({
    salesId,
    skip: !isEditMode,
  })

  const onClickOutside = () => {
    setEditMode(false)
    setHover(false)
  }

  const handleSetGroup = (value: string | null) => {
    if (value === group) {
      onClickOutside()
      return
    }

    setProcessing(true)

    updateProduct({ group: value, id })
      .catch(() => undefined)
      .finally(() => {
        setProcessing(false)
        onClickOutside()
      })
  }

  const formatCreateLabel = (value: string) =>
    `${translate(
      'Products:ProductManager.pricing.group.create',
      language
    )} "${value}"`

  const clearOption = {
    label: (
      <ClearSelectionLabel>
        <T>Reactivesearch:clearSelection</T>
      </ClearSelectionLabel>
    ),
    value: '',
  }

  const groupOptions = groups.map((group) => ({ label: group, value: group }))

  const options = group ? [...groupOptions, clearOption] : groupOptions

  return (
    <Wrapper flex={1}>
      <Title>
        <T>Products:ProductManager.pricing.group.title</T>
      </Title>

      <FlexRow>
        {isEditMode ? (
          <ThemedCreatableSelect
            autoFocus
            createOptionPosition="first"
            extraStyles={getExtraStyles(spacing)}
            formatCreateLabel={formatCreateLabel}
            isCompact
            isLoading={processing || loading}
            isDisabled={salesReadOnly}
            menuIsOpen={isEditMode}
            name="product-group-selector"
            noOptionsMessage={() => (
              <T>Products:ProductManager.pricing.group.empty</T>
            )}
            onCreateOption={(value: string) => handleSetGroup(value)}
            onChange={(option?: Option | null) =>
              option && handleSetGroup(option.value || null)
            }
            onBlur={onClickOutside}
            options={options}
            placeholder={
              <T>Products:ProductManager.pricing.group.placeholder</T>
            }
            value={group ? { label: group, value: group } : null}
          />
        ) : (
          <EditButton
            disabled={salesReadOnly}
            onClick={() => setEditMode(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {group ?? '—'}

            {isHover && (
              <FontAwesomeIcon
                color={palette.text.lighter}
                icon="pen"
                size="sm"
              />
            )}
          </EditButton>
        )}
      </FlexRow>
    </Wrapper>
  )
}

/////////

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: `${spacing.gu(4)}rem`,
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: `${spacing.gu(4)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const ClearSelectionLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}
`

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`
