import styled, { css } from 'styled-components/macro'
import { ChangeEvent } from 'react'

import { Input, Label } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  disabled: boolean
  notes: string
  setNotes: (notes: string) => void
}

export const NotesTextarea = ({ disabled, notes, setNotes }: Props) => (
  <Wrapper>
    <SubTitle>
      <T>Orders:Payments.field.notes</T>
    </SubTitle>

    <Textarea
      disabled={disabled}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setNotes(e.target.value)}
      value={notes}
    />
  </Wrapper>
)

///////

const SubTitle = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))`
  &&& {
    min-height: 50px;
    line-height: 1.2;
    resize: vertical;

    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        border-color: ${theme.palette.smoke.dark};
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}
`
