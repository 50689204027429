import moment from 'moment'
import { useMemo } from 'react'

import {
  ParticipantForPrint,
  ParticipantsForPrintServiceBed,
} from '@/modules/ParticipantsList'
import { T, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'
import { ListingTable } from '@/modules/Listing/common'
import { useTheme } from '@/theme'

import {
  ParticipantService,
  ParticipantsTableRow,
} from './ParticipantsTableRow'

type Props = {
  participants: ParticipantForPrint[]
}

type ParticipantServiceById = {
  [key: string]: ParticipantService
}

export const ParticipantsTable = ({ participants }: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const sortedParticipants = useMemo(
    () =>
      participants
        .map((p) => {
          const { __typename, services: allServices, ...rest } = p

          const accommodationServices = allServices.filter(
            (s) => s.__typename === 'ServiceParticipantBed'
          ) as ParticipantsForPrintServiceBed[]

          const servicesById = accommodationServices.reduce(
            (acc: ParticipantServiceById, s) => {
              const dates = {
                checkIn: s.dates?.checkIn.date ?? null,
                checkOut: s.dates?.checkOut.date ?? null,
              }
              const room = s.participantRoom?.roomReservation.request.room
              const roomId = room?.id ?? 'unassigned'
              const roomNumber = room?.number ?? null

              acc[roomId] = {
                dates: [...(acc[roomId]?.dates ?? []), dates],
                roomId,
                roomNumber,
              }

              return acc
            },
            {}
          )

          const services = Object.values(servicesById)
            .map((service) => ({
              ...service,
              dates: service.dates.sort(
                (a, b) =>
                  moment(a.checkIn).valueOf() - moment(b.checkIn).valueOf()
              ),
            }))
            .sort((a, b) =>
              (a.roomNumber ?? '').localeCompare(b.roomNumber ?? '', language, {
                numeric: true,
              })
            )

          return { services, ...rest }
        })
        .sort(generateCompareFn(['lastName', 'firstName'])),
    [language, participants]
  )

  return (
    <ListingTable style={{ marginTop: spacing.gutter }}>
      <thead>
        <tr>
          <th>
            <T>ParticipantsList:excelHeader.name</T>
          </th>
          <th>
            <T>ParticipantsList:ParticipantRooms.room</T>
          </th>
          <th>
            <T>ParticipantsList:excelHeader.accommodationDates</T>
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedParticipants.map((p) => (
          <ParticipantsTableRow participant={p} key={p.id} />
        ))}
      </tbody>
    </ListingTable>
  )
}
