import { gql, useMutation } from '@apollo/client'

import ResourceReservationFragment from '../fragments/resourceReservation'

import {
  SetResourceReservationInternalNoteMutation,
  SetResourceReservationInternalNoteMutationVariables,
} from '~generated-types'

export type {
  SetResourceReservationInternalNoteMutation,
  SetResourceReservationInternalNoteMutationVariables,
}

export const SET_RESOURCE_RESERVATION_INTERNAL_NOTE_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationInternalNote(
    $input: ResourceReservationSetInternalNoteInput!
  ) {
    resourceReservationSetInternalNote(input: $input) {
      ...ResourceReservation
    }
  }
`

export function useSetResourceReservationInternalNoteMutation() {
  return useMutation<
    SetResourceReservationInternalNoteMutation,
    SetResourceReservationInternalNoteMutationVariables
  >(SET_RESOURCE_RESERVATION_INTERNAL_NOTE_MUTATION)
}

export default useSetResourceReservationInternalNoteMutation
