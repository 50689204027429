import { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'

import { FlexColumn, FlexRow, Right } from '@/components/Layout'
import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { CatalogProductPicker } from '@/modules/Products'
import { generateCompareFn } from '@/utils/arrays'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Input } from '@/components/FormControls'
import { PrimaryColor } from '@/components/Colors'
import type { Reservation } from '@/modules/Reservations/ResourceReservation'
import { resourceReservationHooks } from '@/modules/Reservations/ResourceReservation'
import { useSalesProductsCompact } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

import { Section } from '../types'

type Props = {
  currentSection: Section
  purchaseId: string | null
  reservationId: string
  salesId: string
  sellerId?: string
  setCurrentSection: (section: Section) => void
  updateReservations: (reservations: Reservation[]) => void
}

export const PurchaseSearchManager = ({
  currentSection: { previous, taskId, type },
  purchaseId,
  reservationId,
  salesId,
  sellerId,
  setCurrentSection,
  updateReservations,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const {
    addReservationPurchaseFromCatalogProduct,
    addReservationPurchaseFromSalesProduct,
    addTaskPurchaseFromCatalogProduct,
    addTaskPurchaseFromSalesProduct,
    changeReservationPurchaseFromCatalogProduct,
    changeReservationPurchaseFromSalesProduct,
  } = resourceReservationHooks.useResourceReservationMutations({
    updateReservations,
  })

  const { products, error, loading } = useSalesProductsCompact({ salesId })

  const [isBottomSheetOpen, setBottomSheetOpen] = useState<boolean>(false)
  const [processingId, setProcessingId] = useState<string | null>(null)
  const [searchValue, setSearchValue] = useState<string>('')

  const onSelectReservationPurchaseFromCatalogProduct = (id: string) => {
    const input = { catalogProductId: id, reservationId, salesId }

    const doAddPurchase = () =>
      purchaseId
        ? changeReservationPurchaseFromCatalogProduct({ ...input, purchaseId })
        : addReservationPurchaseFromCatalogProduct(input)

    return doAddPurchase().then(() => {
      setBottomSheetOpen(false)
      setCurrentSection({ type: 'MAIN' })
    })
  }

  const onSelectReservationPurchaseFromSalesProduct = (id: string) => {
    setProcessingId(id)

    const input = { reservationId, salesId, salesProductId: id }

    const doAddPurchase = () =>
      purchaseId
        ? changeReservationPurchaseFromSalesProduct({ ...input, purchaseId })
        : addReservationPurchaseFromSalesProduct(input)

    return doAddPurchase().then(() => {
      setProcessingId(null)
      setCurrentSection({ type: 'MAIN' })
    })
  }

  const onSelectTaskPurchaseFromCatalogProduct = (id: string) =>
    addTaskPurchaseFromCatalogProduct({
      catalogProductId: id,
      reservationId,
      salesId,
      taskId: taskId ?? '',
    }).then(() => {
      setBottomSheetOpen(false)
      setCurrentSection({ taskId, type: previous || 'MAIN' })
    })

  const onSelectTaskPurchaseFromSalesProduct = (id: string) => {
    setProcessingId(id)

    return addTaskPurchaseFromSalesProduct({
      reservationId,
      salesId,
      salesProductId: id,
      taskId: taskId ?? '',
    }).then(() => {
      setProcessingId(null)
      setCurrentSection({ taskId, type: previous || 'MAIN' })
    })
  }

  const filteredProducts = products
    .filter(
      ({ name }) => name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
    )
    .sort(generateCompareFn('name'))

  return (
    <>
      {loading ? (
        <FlexRow
          alignItems="center"
          flex={1}
          justifyContent="center"
          style={{ height: '100%' }}
        >
          <ReactLoading
            color={palette.smoke.main}
            height={40}
            type="spin"
            width={40}
          />
        </FlexRow>
      ) : (
        <>
          <InlineModalIconSection icon="cubes">
            {error ? (
              <Placeholder>
                <T>Products:SalesProductList.error</T>
              </Placeholder>
            ) : !products.length ? (
              <Placeholder>
                <T>Products:SalesProductList.noProducts</T>
              </Placeholder>
            ) : (
              <>
                <SearchWrapper justifyContent="flex-start" alignItems="center">
                  <SearchIcon>
                    <FontAwesomeIcon
                      color={palette.primary.main}
                      icon="magnifying-glass"
                    />
                  </SearchIcon>

                  <Input
                    autoFocus
                    value={searchValue}
                    placeholder={translate(
                      'Products:SalesProductList.search',
                      language
                    )}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setSearchValue(e.target.value)
                    }
                  />
                </SearchWrapper>

                <FlexColumn alignItems="center">
                  {filteredProducts.length ? (
                    filteredProducts.map(({ id, name }) => (
                      <Option
                        alignItems="center"
                        justifyContent="space-between"
                        key={`sales-product-option-${id}`}
                        onClick={() =>
                          type === 'RESERVATION_PURCHASE_SEARCH'
                            ? onSelectReservationPurchaseFromSalesProduct(id)
                            : onSelectTaskPurchaseFromSalesProduct(id)
                        }
                      >
                        {name}
                        {processingId === id && (
                          <ReactLoading
                            color={palette.primary.main}
                            height={16}
                            type="spin"
                            width={16}
                          />
                        )}
                      </Option>
                    ))
                  ) : (
                    <Placeholder>
                      – <T>Products:SalesProductList.noProducts</T> –
                    </Placeholder>
                  )}
                </FlexColumn>
              </>
            )}
          </InlineModalIconSection>

          <InlineModalLine
            style={{ margin: `${spacing.gutter} 0 ${spacing.gu(1)}rem 0` }}
          />

          <InlineModalIconSection>
            <Right>
              <InnocuousButton compact onClick={() => setBottomSheetOpen(true)}>
                <PrimaryColor>
                  <T>Products:SalesProductList.selectFromCatalog</T>
                </PrimaryColor>
              </InnocuousButton>
            </Right>
          </InlineModalIconSection>
        </>
      )}

      {isBottomSheetOpen && (
        <CatalogProductPicker
          onItemSelect={
            type === 'RESERVATION_PURCHASE_SEARCH'
              ? onSelectReservationPurchaseFromCatalogProduct
              : onSelectTaskPurchaseFromCatalogProduct
          }
          onClose={() => setBottomSheetOpen(false)}
          sellerId={sellerId}
        />
      )}
    </>
  )
}

////////

const Option = styled(FlexRow)`
  cursor: pointer;
  margin-top: -1px;
  width: 100%;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.primary.extraLighter};
    `}
  }

  &:first-of-type {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-of-type {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

const Placeholder = styled.span`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const SearchIcon = styled.div`
  position: absolute;
  margin-left: 10px;
  width: 30px;
`

const SearchWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutter};
  `}

  input {
    &:not([type='checkbox']):not([type='radio']) {
      border-radius: 6px;
      padding-left: 30px;

      &::placeholder {
        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`
