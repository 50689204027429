import styled, { css } from 'styled-components/macro'
import { Moment } from 'moment'

import { FlexColumn } from '@/components/Layout'
import { ListItemSection } from '@/components/List'

import { LastActionInfo } from './LastActionInfo'

type Props = {
  closedAt: Moment | null | undefined
  createdAt: Moment
  name: string | null | undefined
  number: number
}

const ItemInfo = ({ closedAt, createdAt, name, number }: Props) => (
  <ListItemSection>
    <FlexColumn noPadding>
      {name ? <Title>{name}</Title> : <TitlePlaceholder>—</TitlePlaceholder>}
      <small>
        #{number} <LastActionInfo closedAt={closedAt} createdAt={createdAt} />
      </small>
    </FlexColumn>
  </ListItemSection>
)

export default ItemInfo

////////////

const Title = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const TitlePlaceholder = styled(Title)`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
