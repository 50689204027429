import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

const BusyIndicator = styled(ReactLoading).attrs((props) => ({
  color: props.theme.palette.text.lighter,
  height: 14,
  type: 'spin',
  width: 14,
}))`
  position: relative;

  &:not(:first-child) {
    ${({ theme }) => css`
      margin-left: ${theme.spacing.gu(1)}rem;
    `}
  }

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`

export default BusyIndicator
