import moment from 'moment/moment'
import { Worksheet } from 'exceljs'

import { InvoiceCostingQuery } from '~generated-types'
import { Palette } from '@/theme'
import { PaymentReservationFeeDetails } from '@/modules/Order/types'

import { extendCells, getPaymentTypeLabel, hexToArgb } from './utils'
import { Payment, SchemaItem } from './types'

type Input = {
  palette: Palette
  payments: InvoiceCostingQuery['invoice']['payments']
  sheet: Worksheet
  translateFn: (keys: string | Array<string>) => string
}

export const addPayments = ({
  palette,
  payments,
  sheet,
  translateFn,
}: Input) => {
  const schema: Array<SchemaItem> = [
    {
      column: translateFn('Excel.paymentFields.payment'),
      value: (data: Payment) => getPaymentTypeLabel(data, translateFn),
    },
    {
      column: '',
      value: (data: Payment) =>
        (data.details as PaymentReservationFeeDetails)?.reservationFee
          ?.invoiceNumber,
    },
    {
      column: translateFn('Excel.paymentFields.date'),
      value: (data: Payment) => moment(data.date).toDate(),
    },
    {
      column: translateFn('Excel.paymentFields.amount'),
      value: (data: Payment) => data.amount,
    },
  ]

  const headerRow = schema.map(({ column }) => column)
  const tableRows = payments.map((payment) =>
    schema.map((it) => it.value(payment))
  )

  sheet.addRows([[], []])

  const header = sheet.addRow(headerRow)

  header.font = {
    bold: true,
    color: {
      argb: hexToArgb(palette.text.dark),
    },
    size: 12,
  }

  const headerColumns = ['A', 'D']

  headerColumns.map(
    (col) =>
      (sheet.getCell(`${col}${header.number}`).fill = {
        fgColor: {
          argb: hexToArgb(palette.primary.extraLight),
        },
        pattern: 'solid',
        type: 'pattern',
      })
  )

  sheet.getCell(`C${header.number}`).fill = {
    fgColor: {
      argb: hexToArgb(palette.primary.light),
    },
    pattern: 'solid',
    type: 'pattern',
  }

  sheet.mergeCells(`A${header.number}:B${header.number}`)

  sheet.addRows(tableRows)

  extendCells(4, header.number + 1, 4, header.number + tableRows.length).map(
    (cellLocation) => {
      const cell = sheet.getCell(cellLocation)
      cell.numFmt = '0.00 €'
    }
  )
}
