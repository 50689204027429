import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { SalesDetails } from '../SalesReservationManager.types'

const RIGHT_WIDTH = '37rem'

type Props = {
  salesDetails: SalesDetails
}

export const SalesDetailsRow = ({
  salesDetails: {
    customerName,
    dates,
    participants,
    saleName,
    saleNumber,
    totals: { beds, extraBeds, rooms },
  },
}: Props) => (
  <Wrapper alignItems="center">
    <FlexRow style={{ width: `calc(100% - ${RIGHT_WIDTH})` }}>
      {saleNumber && (
        <Sale>{`#${saleNumber}${saleName ? ` – ${saleName}` : ''}`}</Sale>
      )}

      {customerName && <Customer>{`, ${customerName}`}</Customer>}
    </FlexRow>

    <FlexRow justifyContent="flex-end" style={{ width: RIGHT_WIDTH }}>
      <Section isFirst>
        <Icon icon={['far', 'calendar']} />
        {dates ? (
          `${moment(dates.start).format('dd, D.M.')} – ${moment(
            dates.end
          ).format('dd, D.M.YYYY')}
        `
        ) : (
          <Placeholder>
            <T>SalesDetails:EstimatedDates.empty</T>
          </Placeholder>
        )}
      </Section>

      <Section>
        <Icon icon="user-group" />
        {participants ? participants : <Placeholder>–</Placeholder>}
      </Section>

      <Section>
        <Icon icon="door-open" />
        {rooms} <T>Accommodation:SalesReservationList.capacity.rooms</T>
      </Section>

      <Section>
        <Icon icon="bed" />
        {beds} + {extraBeds}{' '}
        <T>Accommodation:SalesReservationList.capacity.beds</T>
      </Section>
    </FlexRow>
  </Wrapper>
)

///////

const Sale = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeBig};
  `}

  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 10rem;
`

const Customer = styled(Sale)`
  font-weight: 300;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Section = styled(FlexRow)<{ isFirst?: boolean }>`
  font-weight: 500;
  margin-left: ${({ isFirst, theme }) =>
    isFirst ? 0 : theme.spacing.gutterBig};

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled(FlexRow)`
  margin: 5px 0;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    border-bottom: 1px solid ${theme.palette.smoke.main};
    border-top: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gutter};
    padding-left: ${theme.spacing.gu(8)}rem;
  `}
`
