import { gql } from '@apollo/client'

export default gql`
  query OtherPaymentTypes {
    registry {
      otherPaymentTypes {
        id
        name
      }
    }
  }
`
