import { useEffect } from 'react'
import { useQuery } from '@apollo/client'

import {
  Confirmation,
  ConfirmationByIdPayload,
  ConfirmationByIdVariables,
  ConfirmationInfo,
  OrderType as Order,
} from '@/modules/Order/types'
import { orderContexts, orderQueries } from '@/modules/Order'
import { FlexColumn } from '@/components/Layout'
import { salesHooks } from '@/modules/Sales'

import { CollapseWrapper, ContentWrapper } from '../common'
import { Content, Header } from './components'

type Props = {
  confirmation: Confirmation | ConfirmationInfo
  order: Order
}

export const ConfirmationPhase = ({ confirmation, order }: Props) => {
  const { id } = confirmation

  const { selectedPhaseId, setSelectedPhaseId } = orderContexts.useOrderView()
  const { setOrder } = salesHooks.useSalesDetailsContext()

  const isFull = (confirmation as Confirmation).document !== undefined
  const isOpen = selectedPhaseId === id

  const { data, loading, refetch } = useQuery<
    ConfirmationByIdPayload,
    ConfirmationByIdVariables
  >(orderQueries.CONFIRMATION_BY_ID, {
    skip: !isOpen,
    variables: { id },
  })

  useEffect(() => {
    if (data) {
      setOrder({
        ...order,
        confirmations: order.confirmations.map((c) =>
          c.id === id ? data.orderConfirmation : c
        ),
      })
    }
  }, [data])

  return (
    <FlexColumn>
      <Header
        confirmation={confirmation}
        isOpen={isOpen && isFull}
        isLoading={loading}
        openContent={() => setSelectedPhaseId(isOpen ? null : id)}
      />

      <CollapseWrapper isOpen={isOpen && isFull}>
        {isFull && (
          <ContentWrapper>
            <Content
              confirmation={confirmation as Confirmation}
              isVisible={isOpen}
              order={order}
              refetch={refetch}
            />
          </ContentWrapper>
        )}
      </CollapseWrapper>
    </FlexColumn>
  )
}
