import styled, { css } from 'styled-components/macro'

import type {
  ProductPurchaseDuration,
  ProductPurchaseParticipant,
} from '@/modules/Products/types'
import { FlexRow } from '@/components/Layout'
import { QuantityUnit } from '~generated-types'
import { T } from '@/modules/Language'

import { DatesSelector } from '../DatesSelector'

type Props = {
  duration: ProductPurchaseDuration
  id: string
  participant: ProductPurchaseParticipant | null
  quantityUnit: QuantityUnit
  readOnly: boolean
}

export const ParticipantSection = ({
  duration,
  id,
  participant,
  quantityUnit,
  readOnly,
}: Props) => {
  const renderParticipantName = () => {
    if (!participant) {
      return null
    }

    const { firstName, lastName } = participant

    return firstName || lastName ? (
      <Name>
        {`${firstName}${firstName && lastName ? ' ' : ''}${lastName}`}
      </Name>
    ) : (
      <Placeholder>
        <T>ParticipantsList:unnamedParticipant</T>
      </Placeholder>
    )
  }

  return (
    <FlexRow flex={1}>
      {renderParticipantName()}

      <Spacer />

      {quantityUnit !== QuantityUnit.Piece && (
        <DatesSelector
          id={id}
          duration={duration}
          quantityUnit={quantityUnit}
          readOnly={readOnly}
        />
      )}
    </FlexRow>
  )
}

/////////

const Name = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(24)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Spacer = styled.div`
  flex: 1;
`
