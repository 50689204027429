import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { useTheme } from '@/theme'

import { Placeholder } from './common'

type Props = {
  label: string
  isInvalid?: boolean
  setEditing: (isEditing: boolean) => void
}

export const ValueWrapper = ({ label, isInvalid, setEditing }: Props) => {
  const { palette } = useTheme()

  const [isHovered, setHovered] = useState(false)

  return (
    <Wrapper
      isInvalid={isInvalid}
      onClick={() => {
        setHovered(false)
        setEditing(true)
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {label ? <Label>{label}</Label> : <Placeholder>–</Placeholder>}

      <IconWrapper>
        {isHovered ? (
          <FontAwesomeIcon color={palette.text.lighter} icon="pen" size="sm" />
        ) : (
          isInvalid && (
            <FontAwesomeIcon
              color={palette.danger.main}
              icon="triangle-exclamation"
            />
          )
        )}
      </IconWrapper>
    </Wrapper>
  )
}

//////

const IconWrapper = styled.div`
  width: 20px;
`

const Label = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

const Wrapper = styled.button<{ isInvalid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 30px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-left: -${theme.spacing.gu(1)}rem;
    padding: ${theme.spacing.gu(1)}rem 6px;
    width: calc(100% + ${theme.spacing.gu(1)}rem);
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  ${({ isInvalid, theme }) =>
    isInvalid
      ? `border: 1px solid ${theme.palette.danger.main};
      box-shadow: 0 0 0 1px ${theme.palette.danger.main};`
      : `border: 1px solid ${theme.palette.white}`}
`
