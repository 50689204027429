import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import {
  HideFromPrintSpan,
  PrintOnlyTitle,
  PrintSubtitle,
} from '@/modules/Listing/common'
import { FlexRow } from '@/components/Layout'
import { LinkLikeButton } from '@/components/ExtraButtons'
import PrintButton from '@/components/PrintButton'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  AgeGroupBreakdown,
  ParticipantsMode,
  PrintMode,
  PrintSettings,
} from '../common'
import type { ArrivalsEvent, ArrivalsSales } from './useArrivalsForDate'
import { EventsTable } from './EventsTable'
import { PrintSize } from '../../types'
import { SalesTable } from './SalesTable'

type CountsForSales = Record<string, Record<string, number>>

type Props = {
  events: Readonly<ArrivalsEvent[]>
  printSize: PrintSize
  sales: Readonly<ArrivalsSales[]>
  targetDate: Moment
}

export const ArrivalsListing = ({
  events,
  printSize,
  sales,
  targetDate,
}: Props) => {
  const { spacing } = useTheme()

  const [printMode, setPrintMode] = useState<PrintMode>(PrintMode.All)
  const [participantsMode, setParticipantsMode] = useState<ParticipantsMode>(
    ParticipantsMode.ArrivingTODAY
  )
  const [selectedEvents, setSelectedEvents] = useState<string[]>([])
  const [selectedSales, setSelectedSales] = useState<string[]>([])

  const salesGuestCounts: CountsForSales = countForSales(
    filterArrivingParticipants(sales, targetDate)
  )
  const eventsParticipantCounts: CountsForSales = countForSales(
    filterArrivingParticipants(events, targetDate)
  )

  const totalSalesGuests = countsTotalSum(salesGuestCounts)
  const totalEventsGuests = countsTotalSum(eventsParticipantCounts)
  const totalParticipants = countsTotalSum({
    ...salesGuestCounts,
    ...eventsParticipantCounts,
  })

  const showTotalParticipants =
    printMode !== PrintMode.SalesOnly &&
    printMode !== PrintMode.EventsOnly &&
    sales.length &&
    events.length

  return (
    <>
      <PrintOnlyTitle style={{ marginBottom: `${spacing.gu(1)}rem` }}>
        <T>Listings:ArrivalsAndDeparturesListing.arrivals.title</T>
        {' | '}
        {targetDate.format('dd D.M.YYYY')}
      </PrintOnlyTitle>

      <FlexRow alignItems="flex-end" justifyContent="flex-end">
        {!!showTotalParticipants && (
          <AgeGroupWrapper>
            <AgeGroupBreakdown
              counts={totalParticipants}
              position="column"
              title={
                <T
                  l10n={`Listings:ArrivalsAndDeparturesListing.arrivals.grandTotalArrivals`}
                />
              }
            />
          </AgeGroupWrapper>
        )}

        <FlexRow alignItems="flex-end">
          <PrintSettings
            mode={printMode}
            participantsMode={participantsMode}
            participantsModeOptions={[
              ParticipantsMode.All,
              ParticipantsMode.ArrivingTODAY,
            ]}
            printModeOptions={[
              PrintMode.All,
              PrintMode.Selected,
              PrintMode.SalesOnly,
              PrintMode.EventsOnly,
            ]}
            setMode={setPrintMode}
            setParticipantsMode={setParticipantsMode}
          />

          <HideFromPrintSpan>
            <PrintButtonWrapper>
              <PrintButton />
            </PrintButtonWrapper>
          </HideFromPrintSpan>
        </FlexRow>
      </FlexRow>

      {!!sales.length && printMode !== PrintMode.EventsOnly && (
        <>
          <FlexRow
            alignItems="baseline"
            hideFromPrint={
              printMode === PrintMode.Selected && !selectedSales.length
            }
            style={{ marginBottom: `${spacing.gu(2)}rem` }}
          >
            <FlexRow
              justifyContent="space-between"
              flex={1}
              style={{ marginTop: spacing.gutter }}
            >
              <SectionTitle>
                <T>Listings:ArrivalsAndDeparturesListing.section.SALES</T>
              </SectionTitle>
              <AgeGroupBreakdown
                counts={totalSalesGuests}
                title={
                  <T
                    l10n={`Listings:ArrivalsAndDeparturesListing.arrivals.totalArrivals`}
                  />
                }
              />
            </FlexRow>
            {printMode === PrintMode.Selected && (
              <FlexRow hideFromPrint>
                <Separator />
                <T>
                  Listings:ArrivalsAndDeparturesListing.selection.targets
                </T>: {selectedSales.length}
                <Separator />
                <LinkLikeButton
                  onClick={() => setSelectedSales(sales.map(({ id }) => id))}
                >
                  <T>
                    Listings:ArrivalsAndDeparturesListing.selection.selectAll
                  </T>
                </LinkLikeButton>
                <Separator />
                <LinkLikeButton onClick={() => setSelectedSales([])}>
                  <T>
                    Listings:ArrivalsAndDeparturesListing.selection.selectNone
                  </T>
                </LinkLikeButton>
              </FlexRow>
            )}
          </FlexRow>

          <SalesTable
            data={sales}
            guestCounts={salesGuestCounts}
            printSize={printSize}
            selection={[...selectedSales]}
            selectionEnabled={printMode === PrintMode.Selected}
            targetDate={targetDate}
            participantsMode={participantsMode}
            toggleSelection={(targetId: string) =>
              setSelectedSales(
                selectedSales.includes(targetId)
                  ? selectedSales.filter((x) => x !== targetId)
                  : [...selectedSales, targetId]
              )
            }
          />
        </>
      )}

      {!!events.length && printMode !== PrintMode.SalesOnly && (
        <>
          <FlexRow
            alignItems="baseline"
            hideFromPrint={
              printMode === PrintMode.Selected && !selectedEvents.length
            }
            style={{
              marginBottom: `${spacing.gu(2)}rem`,
              pageBreakBefore:
                (printMode === PrintMode.All && sales.length) ||
                (printMode === PrintMode.Selected && selectedSales.length)
                  ? 'always'
                  : 'auto',
            }}
          >
            <FlexRow
              justifyContent="space-between"
              flex={1}
              style={{ marginTop: spacing.gutter }}
            >
              <SectionTitle>
                <T>Listings:ArrivalsAndDeparturesListing.section.EVENT</T>
              </SectionTitle>
              <AgeGroupBreakdown
                counts={totalEventsGuests}
                title={
                  <T
                    l10n={`Listings:ArrivalsAndDeparturesListing.arrivals.totalArrivals`}
                  />
                }
              />
            </FlexRow>
            {printMode === PrintMode.Selected && (
              <FlexRow hideFromPrint>
                <Separator />
                <T>
                  Listings:ArrivalsAndDeparturesListing.selection.targets
                </T>: {selectedEvents.length}
                <Separator />
                <LinkLikeButton
                  onClick={() => setSelectedEvents(events.map(({ id }) => id))}
                >
                  <T>
                    Listings:ArrivalsAndDeparturesListing.selection.selectAll
                  </T>
                </LinkLikeButton>
                <Separator />
                <LinkLikeButton onClick={() => setSelectedEvents([])}>
                  <T>
                    Listings:ArrivalsAndDeparturesListing.selection.selectNone
                  </T>
                </LinkLikeButton>
              </FlexRow>
            )}
          </FlexRow>

          <EventsTable
            data={events}
            participantCounts={eventsParticipantCounts}
            printSize={printSize}
            selection={[...selectedEvents]}
            selectionEnabled={printMode === PrintMode.Selected}
            targetDate={targetDate}
            participantsMode={participantsMode}
            toggleSelection={(targetId: string) =>
              setSelectedEvents(
                selectedEvents.includes(targetId)
                  ? selectedEvents.filter((x) => x !== targetId)
                  : [...selectedEvents, targetId]
              )
            }
          />
        </>
      )}
    </>
  )
}

////////////

const countForSales = (sales: Readonly<(ArrivalsEvent | ArrivalsSales)[]>) => {
  const salesGuestCounts: Record<string, Record<string, number>> = {}

  sales.forEach(
    (sale) =>
      (salesGuestCounts[sale.id] = sale.participants.reduce(
        (acc: Record<string, number>, { ageCategory }) => {
          const categoryKey = ageCategory?.shortName || 'unknown'
          return {
            ...acc,
            [categoryKey]: (acc[categoryKey] || 0) + 1,
            total: acc.total + 1,
          }
        },
        {
          total: 0,
          unknown: 0,
        }
      ))
  )

  return salesGuestCounts
}

const countsTotalSum = (totals: Record<string, Record<string, number>>) =>
  Object.keys(totals).reduce(
    (acc: Record<string, number>, val) => {
      const returnValue = { ...acc }
      Object.keys(totals[val]).forEach(
        (key) => (returnValue[key] = (returnValue[key] || 0) + totals[val][key])
      )
      return returnValue
    },
    {
      total: 0,
      unknown: 0,
    }
  )

const filterArrivingParticipants = (
  data: Readonly<(ArrivalsEvent | ArrivalsSales)[]>,
  date: Moment
) => {
  return data.map((sale) => {
    const participants = sale.participants
      .map((participant) => {
        const services = participant.services.filter((service) => {
          if (service.__typename === 'ServiceParticipantBed') {
            return moment(service.dates?.checkIn.date).isSame(date, 'day')
          } else {
            return false
          }
        })

        return { ...participant, services }
      })
      .filter((participants) => !!participants.services.length)

    return { ...sale, participants }
  })
}

const SectionTitle = styled(PrintSubtitle)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(3)}rem;
    margin-bottom: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBigger};
  `}
`

const StyledSeparator = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
  `}
`

const AgeGroupWrapper = styled.span`
  flex: 1;
  margin: ${({ theme }) => theme.spacing.gu(0.8)}rem 0;
`
const PrintButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.gu(0.1)}rem 0;
  margin-left: ${({ theme }) => theme.spacing.gu(3)}rem;
`
const Separator = () => <StyledSeparator>|</StyledSeparator>
