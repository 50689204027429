import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'

import { SalesCopyComponent as C, Feature, SalesType } from '~generated-types'
import {
  InlineModal,
  InlineModalContent,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import {
  AccommodationSection,
  BasicDetailsSection,
  GroupsAndTargetsSection,
  NewSaleDateSection,
  OrdersSection,
  SingleComponentsSection,
  SpacesAndResourcesSection,
} from './Sections'

type Props = {
  onClose: () => void
}

export const CopySaleModal = ({ onClose }: Props) => {
  const { palette, spacing } = useTheme()
  const {
    copySale,
    data: {
      estimatedDates,
      facet: { features },
      id,
      orderNumber,
      type,
    },
  } = useSalesDetailsContext()

  const defaultComponents = Object.values(C).filter(comp => comp !== C.RoomReservations)

  const [components, setComponents] = useState<C[]>(defaultComponents)
  const [date, setDate] = useState<Moment | null>(null)
  const [isDateValid, setDateValid] = useState<boolean>(true)
  const [processing, setProsessing] = useState<boolean>(false)

  useEffect(() => {
    if (type === SalesType.Enrollment && estimatedDates) {
      setDate(moment(estimatedDates.start))
    }
  }, [estimatedDates, type])

  const handleCopySale = () => {
    if (!date) {
      return setDateValid(false)
    }

    setProsessing(true)

    copySale(components, date.format('YYYY-MM-DD'), id)
      .then((salesId) => {
        if (salesId) {
          window.open(`/sales/details/${salesId}`, '_blank')
        }
      })
      .catch(() => undefined)
      .finally(() => {
        setProsessing(false)
        onClose()
      })
  }

  const sectionProps = {
    components,
    setComponents,
  }

  return (
    <Modal>
      <InlineModalHeader
        onClose={onClose}
        title={
          <ShiftLeft>
            <T>SalesDetails:CopySale.title</T> #{orderNumber}
          </ShiftLeft>
        }
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      <InlineModalContent>
        <NewSaleDateSection
          date={date}
          isDateValid={isDateValid}
          setDate={setDate}
          setDateValid={setDateValid}
        />

        <InlineModalDahedLine dashed />

        <SingleComponentsSection {...sectionProps} facetFeatures={features} />

        <InlineModalDahedLine dashed />

        <BasicDetailsSection {...sectionProps} />

        {features.find((f) => f.feature === Feature.Accommodation) && (
          <>
            <InlineModalDahedLine dashed />
            <AccommodationSection {...sectionProps} />
          </>
        )}

        {features.find((f) => f.feature === Feature.Reservations) && (
          <>
            <InlineModalDahedLine dashed />
            <SpacesAndResourcesSection {...sectionProps} />
          </>
        )}

        <InlineModalDahedLine dashed />

        <OrdersSection {...sectionProps} />

        {features.find((f) => f.feature === Feature.Accommodation) && (
          <>
            <InlineModalDahedLine dashed />
            <GroupsAndTargetsSection {...sectionProps} />
          </>
        )}

        <InlineModalDahedLine dashed />
      </InlineModalContent>

      <InlineModalFooter>
        <FlexRow alignItems="center" justifyContent="space-between" flex={1}>
          <InnocuousButton onClick={onClose}>
            <T>common:action.cancel</T>
          </InnocuousButton>

          <InnocuousButton disabled={processing} onClick={handleCopySale}>
            {processing ? (
              <LoaderWrapper>
                <ReactLoading
                  color={palette.primary.main}
                  height={24}
                  type="spin"
                  width={24}
                />
              </LoaderWrapper>
            ) : (
              <PrimaryColor>
                <T>SalesDetails:CopySale.copy</T>
              </PrimaryColor>
            )}
          </InnocuousButton>
        </FlexRow>
      </InlineModalFooter>
    </Modal>
  )
}

/////

const LoaderWrapper = styled.span`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Modal = styled(InlineModal)`
  max-height: 100vh;
  width: 450px;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const InlineModalDahedLine = styled(InlineModalLine)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(5)}rem;
  `}
`
