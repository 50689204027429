import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { FetchedParticipant } from '@/modules/ParticipantsList'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { isSameOption } from './utils'
import Label from '../../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../../ParticipantRow/common/ServiceItemSquared'
import { VSTDetailsModal } from './VSTDetailsModal/VSTDetailsModal'

type Props = {
  selectedParticipants: FetchedParticipant[]
  readOnly?: boolean
}

export const Education = ({ selectedParticipants, readOnly }: Props) => {
  const { spacing, palette } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  const education = isSameOption(selectedParticipants, 'education')
    ? selectedParticipants[0]?.education || null
    : null

  return (
    <ModalContainer
      referenceElement={({ ref }) => (
        <ServiceItemSquared
          ref={ref}
          disabled={readOnly}
          minWidth={`${spacing.gu(16)}rem`}
          onClick={readOnly ? () => null : () => setModalOpen(true)}
          renderContent={() =>
            education ? (
              <span>
                <T>{`enums:education.${education}`}</T>
              </span>
            ) : (
              <>
                <Label>
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon={'plus'}
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                  <T>ParticipantsList:PersonalFields.education</T>
                </Label>
              </>
            )
          }
        />
      )}
      onClose={() => setModalOpen(false)}
      isOpen={isModalOpen}
      modal={<VSTDetailsModal selectedParticipants={selectedParticipants} />}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}
