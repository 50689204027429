import styled, { css } from 'styled-components/macro'
import { Moment } from 'moment'
import ReactLoading from 'react-loading'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ListingTableSubheader } from '@/modules/Listing/common'
import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { PrintSize } from '../../types'
import useSalesArrivalsCountsForDate from '../useSalesArrivalsCountsForDate'

interface SummaryRowProps {
  colSpan: number
  date: Moment
  printSize: PrintSize
  typeFilter?: SalesType[]
}

const SummaryRow = ({
  colSpan,
  date,
  printSize,
  typeFilter,
}: SummaryRowProps) => {
  const theme = useTheme()

  const { data, error, loading } = useSalesArrivalsCountsForDate({
    date,
    typeFilter,
  })

  const arriving = (data?.arriving.beds || 0) + (data?.arriving.extraBeds || 0)
  const departing =
    (data?.departing.beds || 0) + (data?.departing.extraBeds || 0)

  return (
    <ListingTableSubheader colSpan={colSpan} noSpacer printSize={printSize}>
      <Container>
        <FlexRow>
          <strong style={{ marginRight: theme.spacing.gutterBig }}>
            {date.format('L')}
          </strong>
          <FlexColumn alignItems="flex-start" noPadding>
            <span style={{ marginBottom: theme.spacing.gutterSmall }}>
              <T>Listings:SalesListing.summary.arriving</T>:{' '}
              {loading ? (
                <ReactLoading
                  color={theme.palette.coal.main}
                  height={14}
                  type={'spin'}
                  width={14}
                />
              ) : error ? (
                'n/a'
              ) : (
                arriving
              )}
            </span>
            <span>
              <T>Listings:SalesListing.summary.departing</T>:{' '}
              {loading ? (
                <ReactLoading
                  color={theme.palette.coal.main}
                  height={14}
                  type={'spin'}
                  width={14}
                />
              ) : error ? (
                'n/a'
              ) : (
                departing
              )}
            </span>
          </FlexColumn>
        </FlexRow>
      </Container>
    </ListingTableSubheader>
  )
}

export default SummaryRow

////////////

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}
`
