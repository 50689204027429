import { useState } from 'react'

import { customerHooks } from '@/modules/Registry/Customer'
import { useRouteDate } from '@/utils/hooks'

import AccommodationGuestListing from './AccommodationGuestListing'
import { SortOrder } from './types'
import useAccommodationGuests from './useAccommodationGuests'

export const AccommodationGuestListingContainer = () => {
  const { date, setDate } = useRouteDate()
  const { error, guests, loading } = useAccommodationGuests({ date })
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.PERSON)

  const filteredGuests = guests.filter(({ guest, room }) => {
    const searchTerm = searchValue.toLowerCase()

    const { customerNumber, displayName: customerName } = guest.participant
      .sales.customer?.customer
      ? customerHooks.useCustomerDetails({
          customer: guest.participant.sales.customer.customer,
        })
      : { customerNumber: '', displayName: null }

    const customerMatch =
      customerNumber.toLowerCase().includes(searchTerm) ||
      customerName?.toLowerCase().includes(searchTerm)
    const guestNameMatch =
      guest.participant.firstName.toLowerCase().includes(searchTerm) ||
      guest.participant.lastName.toLowerCase().includes(searchTerm)
    const roomMatch = room.number.toLowerCase().includes(searchTerm)
    const salesMatch =
      guest.participant.sales.name?.toLowerCase().includes(searchTerm) ||
      (guest.participant.sales.orderNumber &&
        `${guest.participant.sales.orderNumber}`.includes(searchTerm))

    return (
      !searchValue || customerMatch || guestNameMatch || roomMatch || salesMatch
    )
  })

  return (
    <AccommodationGuestListing
      date={date}
      error={error}
      guests={filteredGuests}
      loading={loading}
      searchValue={searchValue}
      setDate={setDate}
      setSearchValue={setSearchValue}
      setSortOrder={(targetSortOrder: SortOrder) => {
        setSortOrder(targetSortOrder)
      }}
      sortOrder={sortOrder}
    />
  )
}

export default AccommodationGuestListingContainer
