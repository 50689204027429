import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { OrderStateChip } from '@/modules/Order'
import { OrderType } from '@/modules/Order/types'

import { SubNavLink } from '../SubNavLink'

type Props = {
  baseRoute: string
  order: OrderType
  readOnly?: boolean
  showOrder: (id: string) => void
}

export const Order = ({
  baseRoute,
  order: { customer, id, name, lifecycle, orderNumber, paymentInfo },
  readOnly,
  showOrder,
}: Props) => (
  <SubNavLink
    disabled={readOnly}
    label={
      <FlexColumn alignItems="flex-start" flex={1} noPadding>
        <NameRow>
          <OrderNumber className="order-number">#{orderNumber}</OrderNumber>
          {name && <OrderName> {name}</OrderName>}
        </NameRow>
        {customer && (
          <NameRow>
            {customer.details.type === 'ORGANIZATION' ? (
              <FontAwesomeIcon
                fixedWidth
                icon={['far', 'building']}
                size="sm"
              />
            ) : (
              <FontAwesomeIcon fixedWidth icon="user" size="xs" />
            )}{' '}
            {customer.details.name}
          </NameRow>
        )}
        <OrderDetails alignItems="center" justifyContent="space-between">
          <OrderStateChip state={lifecycle.state} />
          {formatCurrency(paymentInfo.totalPrice.amountVatIncluded)} €
        </OrderDetails>
      </FlexColumn>
    }
    style={{ margin: 0 }}
    onClick={(e) => {
      e.preventDefault()
      showOrder(id)
    }}
    to={`${baseRoute}`}
    isActive={() => false}
  />
)

///////

const NameRow = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(25)}rem;
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const OrderDetails = styled(FlexRow)`
  width: 100%;
`

const OrderName = styled.span`
  font-weight: 600;
`

const OrderNumber = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
