import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Catalog, catalogHooks } from '@/modules/Registry'
import { ActionButton } from '@/modules/Products/components'
import { DropdownButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useSalesProductListContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

type Props = {
  allowDeleting: boolean
  catalog: Catalog
  productId: string
}

export const Actions = ({ allowDeleting, catalog, productId }: Props) => {
  const { palette, spacing } = useTheme()
  const { copySalesProduct, deleteProductWithConfirm, saveSalesProduct } =
    useSalesProductListContext()
  const { catalogs } = catalogHooks.useCatalogs()

  const catalogOptions = catalogs
    .filter((c) => c.active)
    .filter((c) => c.company.id === catalog.company.id)
    .map((c) => ({
      label: (
        <>
          <Icon icon="list" size="sm" />
          {c.name}
        </>
      ),
      onClick: () => saveSalesProduct(productId, c.id),
    }))

  return (
    <>
      <ActionButton
        content={<T>Products:ProductManager.action.copyProduct</T>}
        icon="copy"
        iconColor={palette.text.light}
        onClick={() => copySalesProduct(productId)}
        style={{ marginLeft: spacing.gutterSmall }}
      />

      {catalogOptions.length > 1 ? (
        <DropdownButton
          options={catalogOptions}
          dropdownPlacement="bottom-end"
          renderCustomButton={({ onClick }) => (
            <ActionButton
              content={<T>Products:ProductManager.action.saveToCatalog</T>}
              icon="save"
              iconColor={palette.text.light}
              onClick={onClick}
              style={{ marginLeft: spacing.gutterSmall }}
            />
          )}
        />
      ) : (
        <ActionButton
          content={<T>Products:ProductManager.action.saveToCatalog</T>}
          icon="save"
          iconColor={palette.text.light}
          onClick={() => saveSalesProduct(productId, catalog.id)}
          style={{ marginLeft: spacing.gutterSmall }}
        />
      )}

      {allowDeleting ? (
        <ActionButton
          content={<T>Products:ProductManager.action.deleteProduct</T>}
          icon="trash"
          iconColor={palette.danger.main}
          onClick={() => deleteProductWithConfirm(productId)}
          style={{ marginLeft: spacing.gutterSmall }}
        />
      ) : (
        <ActionButtonPlaceholder />
      )}
    </>
  )
}

///////

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 12px;
    margin-right: 10px;
  }
`

const ActionButtonPlaceholder = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(0.5)}rem;
    width: ${theme.spacing.gu(4)}rem;
  `}
`
