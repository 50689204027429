import {
  Confirmation,
  Document,
  Invoice,
  Offer,
  OrderType as Order,
  Payment,
  PaymentSubscription,
} from '@/modules/Order/types'
import { OrderPhaseType, PaymentState } from '~generated-types'
import { ById } from '@/common/types'

type StateType<T> = T | ((data: T) => T)

export const updateDocument = (
  document: Document,
  orderId: string,
  ownerId: string,
  ownerType: OrderPhaseType,
  setOrdersById: (ordersById: StateType<ById<Order>>) => void
) => {
  switch (ownerType) {
    case OrderPhaseType.Invoice:
    case OrderPhaseType.InvoiceRefund:
    case OrderPhaseType.InvoiceReservationFee:
    case OrderPhaseType.InvoiceReservationFeeRefund:
      return setOrdersById((ordersById: ById<Order>) => {
        const invoices = ordersById[orderId].invoices as Invoice[]

        return {
          ...ordersById,
          [orderId]: {
            ...ordersById[orderId],
            invoices: invoices.map((i) =>
              i.id === ownerId ? { ...i, document } : i
            ),
          },
        }
      })
    case OrderPhaseType.OrderConfirmation:
      return setOrdersById((ordersById: ById<Order>) => {
        const confirmations = ordersById[orderId]
          .confirmations as Confirmation[]

        return {
          ...ordersById,
          [orderId]: {
            ...ordersById[orderId],
            confirmations: confirmations.map((c) =>
              c.id === ownerId ? { ...c, document } : c
            ),
          },
        }
      })
    case OrderPhaseType.Offer:
      return setOrdersById((ordersById: ById<Order>) => {
        const offers = ordersById[orderId].offers as Offer[]

        return {
          ...ordersById,
          [orderId]: {
            ...ordersById[orderId],
            offers: offers.map((o) =>
              o.id === ownerId ? { ...o, document } : o
            ),
          },
        }
      })
    default:
      return
  }
}

export const updatePayment = (
  data: PaymentSubscription,
  setOrdersById: (ordersById: StateType<ById<Order>>) => void,
  setObservablePayment: (payment: Payment | null) => void
) => {
  if (data) {
    const { event, invoiceId, orderId, paymentId } = data.payment

    const setPayments = (getPayments: (payments: Payment[]) => Payment[]) => {
      setOrdersById((ordersById: ById<Order>) => {
        const order = ordersById[orderId]

        const invoices = order.invoices.map((i) => {
          const isFull = (i as Invoice).payments !== undefined

          if (isFull && i.id === invoiceId) {
            const invoice = i as Invoice

            return { ...invoice, payments: getPayments(invoice.payments) }
          }

          return i
        })

        return { ...ordersById, [orderId]: { ...order, invoices } }
      })
    }

    switch (event.type) {
      case 'CREATE':
        if (event.data) {
          const newPayment = event.data as Payment
          const isPaymentPaid =
            newPayment.state === PaymentState.Paid ||
            newPayment.state === PaymentState.PaidPending

          if (!isPaymentPaid) {
            setObservablePayment(newPayment)
          }

          setPayments((payments) =>
            !payments.find(({ id }) => id === newPayment.id)
              ? [...payments, newPayment]
              : payments
          )
        }
        return
      case 'DELETE':
        setObservablePayment(null)
        setPayments((payments) => payments.filter((p) => p.id !== paymentId))
        return
      case 'UPDATE':
        if (event.data) {
          const newPayment = event.data as Payment
          const isPaymentPaid =
            newPayment.state === PaymentState.Paid ||
            newPayment.state === PaymentState.PaidPending

          setObservablePayment(!isPaymentPaid ? newPayment : null)
          setPayments((payments) =>
            payments.map((p) => (p.id === paymentId ? newPayment : p))
          )
        }
        return
      default:
        return
    }
  }
}
