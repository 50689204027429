import styled, { css } from 'styled-components/macro'
import { useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, useLanguageContext } from '@/modules/Language'
import { DangerColor } from '@/components/Colors'
import { DateRange } from '@/common/types'
import { FontWeight } from '@/components/Typography'
import { Label } from '@/components/FormControls'
import { ModalContainer } from '@/components/Modal'
// import { resourceReservationMutations } from '@/modules/Reservations/ResourceReservation'
import { ToggleButtonHelper } from '@/components/ToggleHelpers'
// import { useAccommodationDateOffset } from '@/modules/Accommodation/SalesReservationList/mutations'
import { useDialogService } from '@/components/DialogService'
import { useTheme } from '@/theme'

import { HeaderValue, HeaderValuePlaceholder } from './HeaderValue'
import { EstimatedDatesModal } from './EstimatedDatesModal'
import { useSalesDetailsContext } from '../../../'

type Props = {
  estimationAllowed?: boolean
}

export const SalesDates = ({ estimationAllowed }: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()
  const { confirm } = useDialogService()

  const {
    data: { id, estimatedDates, reservationDates },
    refresh,
    saleReadOnly,
    updateAttributes,
  } = useSalesDetailsContext()

  // const [accommodationMassUpdate] = useAccommodationDateOffset()
  // const [
  //   reservationsMassUpdate,
  // ] = resourceReservationMutations.useReservationsDateOffset()

  const dateValue = estimatedDates || reservationDates || null

  const [valueStr, setValueStr] = useState<string | null | undefined>(null)
  const [dateSource, setDateSource] = useState<'MANUAL' | 'AUTO' | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isUnsaved, setUnsaved] = useState(false)
  const [dates, setDates] = useState(
    dateValue
      ? {
          end: moment(dateValue.end),
          start: moment(dateValue.start),
        }
      : null
  )

  const hasChanged = useMemo(
    () =>
      estimatedDates?.start !== dates?.start.format('YYYY-MM-DD') ||
      estimatedDates?.end !== dates?.end.format('YYYY-MM-DD'),
    [estimatedDates, dates]
  )

  useEffect(() => {
    setUnsaved(hasChanged)
  }, [hasChanged])

  const formatDate = (date: { start: string; end: string }) => {
    const isSameDate = moment(date.start).isSame(moment(date.end), 'day')
    const isSameYear = moment(date.start).isSame(moment(date.end), 'year')

    return isSameDate
      ? moment(date.start).format('DD.MM.YY')
      : `${moment(date.start).format(
          isSameYear ? 'DD.MM' : 'DD.MM.YY'
        )} – ${moment(date.end).format('DD.MM.YY')}`
  }

  useEffect(() => {
    setValueStr(
      estimatedDates
        ? formatDate(estimatedDates)
        : reservationDates
        ? formatDate(reservationDates)
        : null
    )
    setDateSource(estimatedDates ? 'MANUAL' : reservationDates ? 'AUTO' : null)
  }, [language, estimatedDates, reservationDates])

  const reservationDatesIssue = estimatedDates
    ? reservationDates
      ? moment(estimatedDates.start).isAfter(reservationDates.start, 'day') ||
        moment(estimatedDates.end).isBefore(reservationDates.end, 'day')
      : null
    : null

  const warningMessage =
    !reservationDatesIssue || !reservationDates ? undefined : (
      <WarningWrapper>
        <FontWeight bold style={{ marginBottom: `${theme.spacing.gu(1)}rem` }}>
          <FontAwesomeIcon
            icon="triangle-exclamation"
            color={theme.palette.warning.dark}
            style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
          />
          <T>SalesDetails:ReservationDates.dontMatch</T>
        </FontWeight>
        <FontWeight light>
          <T>SalesDetails:ReservationDates.title</T>:{' '}
          {reservationDates.start === reservationDates.end
            ? moment(reservationDates.start).format('DD.MM.YY')
            : `${moment(reservationDates.start).format('DD.MM.')} – ${moment(
                reservationDates.end
              ).format('DD.MM.YY')}`}
        </FontWeight>
      </WarningWrapper>
    )

  // const handleMoveReservations = (daysToMove: number, isValid: boolean) => {
  //   if (!isValid) {
  //     return Promise.resolve()
  //   }

  //   accommodationMassUpdate({
  //     variables: {
  //       input: {
  //         offset: { days: daysToMove },
  //         salesId: id,
  //       },
  //     },
  //   }).then((res) => {
  //     const data = res.data?.accommodationUpdateAll
  //     const issues =
  //       data?.__typename === 'AccommodationUpdateAllError' && data?.issues

  //     if (!issues) {
  //       reservationsMassUpdate({
  //         variables: {
  //           input: {
  //             offset: { days: daysToMove, minutes: 0 },
  //             salesId: id,
  //           },
  //         },
  //       })
  //     } else {
  //       warn({
  //         cancelLabel: translate('common:action.close', language),
  //         description: issues?.map((issue) => (
  //           <FontWeight
  //             key={issue.key}
  //             style={{ padding: `${theme.spacing.gutterSmall} 0` }}
  //           >
  //             <InfoIconWrapper>
  //               {issue.level === 'INFO' ? (
  //                 <FontAwesomeIcon
  //                   icon="circle-info"
  //                   color={theme.palette.primary.main}
  //                 />
  //               ) : (
  //                 <FontAwesomeIcon
  //                   icon="triangle-exclamation"
  //                   color={
  //                     issue.level === 'ERROR'
  //                       ? theme.palette.danger.main
  //                       : theme.palette.warning.dark
  //                   }
  //                 />
  //               )}
  //             </InfoIconWrapper>
  //             <T>{`IssueCodes:${issue.code}`}</T>
  //           </FontWeight>
  //         )),
  //         title: translate(
  //           'Accommodation:SalesReservationManager.movingError',
  //           language
  //         ),
  //       }).catch(() => null)
  //     }
  //   })
  // }

  const handleUpdate = async (estimatedDates: DateRange | null | undefined) => {
    const doUpdate = async (promise: Promise<any>) =>
      await promise
        .then(() => {
          setIsEditing(false)
          return refresh()
        })
        .catch(() => undefined)

    // const daysToMove = moment
    //   .duration(dates?.start.diff(dateValue?.start))
    //   .asDays()

    // return daysToMove !== 0
    //   ? confirm({
    //       cancelLabel: <T>ResourceReservations:reservation.skipMove</T>,
    //       confirmLabel: (
    //         <PrimaryColor>
    //           <T>ResourceReservations:reservation.approveMove</T>
    //         </PrimaryColor>
    //       ),
    //       description: (
    //         <span>
    //           <T>ResourceReservations:reservation.move</T>{' '}
    //           {daysToMove > 0 ? (
    //             <>
    //               <b>
    //                 {daysToMove}
    //                 <T>enums:quantityUnit.abbrev.DAY</T>{' '}
    //               </b>
    //               <T>ResourceReservations:reservation.moveForward</T>
    //             </>
    //           ) : (
    //             <>
    //               <b>
    //                 {daysToMove * -1}
    //                 <T>enums:quantityUnit.abbrev.DAY</T>{' '}
    //               </b>
    //               <T>ResourceReservations:reservation.moveBack</T>
    //             </>
    //           )}
    //         </span>
    //       ),
    //     })
    //       .then(() =>
    //         doUpdate(
    //           Promise.all([
    //             updateAttributes(id, { estimatedDates }),
    //             handleMoveReservations(daysToMove, estimatedDates !== null),
    //           ])
    //         )
    //       )
    //       .catch(() =>
    //         doUpdate(Promise.all([updateAttributes(id, { estimatedDates })]))
    //       )
    //   :
    return doUpdate(
      Promise.all([
        updateAttributes(id, { estimatedDates }),
        // handleMoveReservations(daysToMove, estimatedDates !== null),
      ])
    )
  }

  const handleCloseModal = () => {
    isUnsaved
      ? confirm({
          cancelLabel: <T>common:action.cancel</T>,
          confirmLabel: (
            <DangerColor>
              <T>common:action.close</T>
            </DangerColor>
          ),
          description: <T>SalesDetails:EstimatedDates.unsaved</T>,
        })
          .then(() => {
            setIsEditing(false)
            setDates(
              dateValue
                ? {
                    end: moment(dateValue.end),
                    start: moment(dateValue.start),
                  }
                : null
            )
          })
          .catch(() => null)
      : setIsEditing(false)
  }

  return (
    <Wrapper alignItems="flex-start">
      <Label style={{ marginBottom: `${theme.spacing.gu(1)}rem` }}>
        {dateSource === 'MANUAL' ? (
          <T>SalesDetails:EstimatedDates.title</T>
        ) : (
          <T>SalesDetails:ReservationDates.title</T>
        )}
      </Label>
      <FlexRow alignItems="center">
        {!!reservationDatesIssue && (
          <FontAwesomeIcon
            icon="triangle-exclamation"
            color={theme.palette.warning.dark}
            style={{ marginRight: `${theme.spacing.gu(1.5)}rem` }}
          />
        )}
        {saleReadOnly || !estimationAllowed ? (
          valueStr ? (
            <HeaderValue>{valueStr}</HeaderValue>
          ) : (
            <HeaderValuePlaceholder>–</HeaderValuePlaceholder>
          )
        ) : (
          <ModalContainer
            isOpen={isEditing}
            modal={
              <EstimatedDatesModal
                handleChange={handleUpdate}
                clearDisabled={!estimatedDates}
                dates={dates}
                onUpdate={setDates}
                hasChanged={hasChanged}
                warningMessage={warningMessage}
              />
            }
            onClose={handleCloseModal}
            placement="bottom-end"
            referenceElement={({ ref }) => (
              <div ref={ref}>
                <ToggleButtonHelper
                  handleOnClick={() => setIsEditing(true)}
                  hideEditIcon
                  placeholder={
                    <Placeholder>
                      <HeaderValuePlaceholder>
                        <T>SalesDetails:EstimatedDates.placeholder</T>
                      </HeaderValuePlaceholder>
                    </Placeholder>
                  }
                  value={
                    valueStr ? <HeaderValue>{valueStr}</HeaderValue> : null
                  }
                />
              </div>
            )}
          />
        )}
      </FlexRow>
    </Wrapper>
  )
}

////////////

const Wrapper = styled(FlexColumn)`
  white-space: nowrap;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
    padding: ${theme.spacing.gutterSmall} 0;
  `}
`

const WarningWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter} 0;
  `}
`

const Placeholder = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

// const InfoIconWrapper = styled.span`
//   ${({ theme }) => css`
//     margin-right: ${theme.spacing.gutterSmall};
//   `}
// `
