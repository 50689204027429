import { Dimension, dimensionHooks } from '@/modules/Registry'
import { DimensionTarget } from '~generated-types'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { ThemedMultiSelect } from '@/components/ThemedSelect'
import { useRouteValue } from '@/utils/hooks'
import { useTheme } from '@/theme'

export const DimensionSelector = () => {
  const { spacing } = useTheme()

  const { setValue: setDimensions, value: dimensions } = useRouteValue({
    multi: true,
    routeKey: 'dimension',
  })

  const { dimensions: fetchedDimensions, loading } =
    dimensionHooks.useDimensionsByTarget({
      target: DimensionTarget.Sales,
    })

  return (
    <FilterSection
      label={<T>Reports:Reservations.dimensionSelector.title</T>}
      render={() => (
        <ThemedMultiSelect
          loading={loading}
          modalWidth={`${spacing.gu(42)}rem`}
          noOptionsPlaceholder={
            <T>Reports:Reservations.dimensionSelector.noOptions</T>
          }
          options={dimensionsToOptions(fetchedDimensions)}
          placeholder={
            <T>Reports:Reservations.dimensionSelector.placeholder</T>
          }
          selectedValues={Array.isArray(dimensions) ? dimensions : []}
          setSelectedValues={setDimensions}
        />
      )}
    />
  )
}

//////////

const dimensionsToOptions = (dimensions: Dimension[]) =>
  dimensions.sort(generateCompareFn('sortOrder')).map((d) => ({
    label: d.name,
    value: d.id,
  }))
