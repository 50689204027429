import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'

import { useTheme } from '@/theme'

import BusyIndicator from '../elements/BusyIndicator'
import EndAdornmentWrapper from '../elements/EndAdornmentWrapper'
import InputBase from '../elements/InputBase'
import LiveUpdateIndicator from '../elements/LiveUpdateIndicator'
import TD from '../elements/TD'

type Props = {
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
  borderBottomStyle?: 'dashed' | 'dotted' | 'inherit' | 'none' | 'solid'
  colSpan?: number
  disabled?: boolean
  editorColor?: string
  endAdornment?: ReactNode
  focus?: boolean
  inputType?: string
  onPressKey?: (key: string) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSubmit?: (value: string) => Promise<any>
  placeholder?: string
  rowSpan?: number
  style?: CSSProperties
  type?: string
  updated?: boolean
  validationFn?: (value: string | null | undefined) => Promise<boolean>
  value: string | null | undefined
  externalValue?: string | null | undefined
}

const InputCell = ({
  align,
  borderBottomStyle,
  colSpan,
  editorColor,
  endAdornment,
  focus,
  onPressKey,
  onChange,
  onSubmit,
  rowSpan,
  style,
  updated,
  validationFn,
  value,
  externalValue,
  ...inputProps
}: Props) => {
  const theme = useTheme()

  const isReadOnly = !onSubmit

  const [adornmentHeight, setAdornmentHeight] = useState<number>(0)
  const [hasError, setHasError] = useState<boolean>(false)
  const [innerValue, setInnerValue] = useState<string>(value || '')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const inputEl = useRef<any>(null)
  const tdEl = useRef<any>(null)

  useEffect(() => {
    if (tdEl.current) {
      const borderTopWidth = parseInt(
        window
          .getComputedStyle(tdEl.current)
          .getPropertyValue('border-top-width')
      )
      const borderBottomWidth = parseInt(
        window
          .getComputedStyle(tdEl.current)
          .getPropertyValue('border-top-width')
      )

      setAdornmentHeight(borderTopWidth === borderBottomWidth ? 1 : 0)
    }
  }, [])

  useEffect(() => {
    setInnerValue(value || '')
  }, [value])

  useEffect(() => {
    if (focus) {
      inputEl && inputEl.current && inputEl.current.focus()
    }
  }, [focus])

  const handleFocus = () => {
    const input = inputEl && inputEl.current

    if (input !== null) {
      const valueLength = input.value.length

      input.focus()

      if (input.type === 'text') {
        input.selectionStart = valueLength
        input.selectionEnd = valueLength
      }
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e

    setHasError(false)

    const value =
      target.type === 'number' && !target.value.endsWith('.0')
        ? `${Number(target.value)}`
        : target.value

    if (onChange) {
      return onChange(e)
    }

    setInnerValue(value)
  }

  const handleOnKeyDown = (e: React.KeyboardEvent) => {
    onPressKey && onPressKey(e.key)

    // Mimic submitting on enter press
    if (e.key === 'Enter') {
      inputEl && inputEl.current && inputEl.current.blur()
    }
  }

  const handleOnSubmit = async () => {
    const isValid = validationFn ? await validationFn(innerValue) : true

    if (!isValid) {
      setHasError(true)
    } else if (onSubmit) {
      setHasError(false)
      setIsSubmitting(true)

      onSubmit(innerValue)
        .catch(() => setHasError(true))
        .finally(() => setIsSubmitting(false))
    }
  }

  return (
    <TD
      // @ts-ignore
      align={align}
      borderBottomStyle={borderBottomStyle}
      colSpan={colSpan}
      noPadding
      noWrap
      rowSpan={rowSpan}
      style={{
        ...style,
        overflow: 'hidden',
      }}
      ref={tdEl}
    >
      <InputBase
        {...inputProps}
        borderless
        hasEndAdornment={!!endAdornment}
        onBlur={handleOnSubmit}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        ref={inputEl}
        tabIndex={isReadOnly ? -1 : 0}
        readOnly={isReadOnly || isSubmitting}
        state={hasError ? 'danger' : undefined}
        style={hasError ? { background: theme.palette.danger.lighter } : {}}
        value={externalValue || innerValue}
      />
      {endAdornment && (
        <EndAdornmentWrapper
          onClick={() => handleFocus()}
          style={{ lineHeight: `${adornmentHeight}px` }}
        >
          {isSubmitting ? <BusyIndicator /> : endAdornment || null}
        </EndAdornmentWrapper>
      )}
      <LiveUpdateIndicator updated={updated} editorColor={editorColor} />
    </TD>
  )
}

export default InputCell
