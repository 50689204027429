import styled, { css } from 'styled-components/macro'

import {
  ContentPlaceholder,
  LoadingPlaceholder,
} from '@/components/Placeholders'
import { T } from '@/modules/Language'
import { useRoomReservationsContext } from '@/modules/FrontDesk'

import { filterRoomReservations } from './utils'
import { RoomReservations } from './RoomReservations'

export const RoomReservationsContainer = () => {
  const {
    buildings,
    cleaningStatuses,
    error,
    isArrivingReservation,
    isDepartingReservation,
    loading,
    reservationTypes,
    roomReservations,
    roomStatuses,
    selectedParticipant,
    selectedSale,
  } = useRoomReservationsContext()

  const filteredRoomReservations = filterRoomReservations({
    buildings,
    cleaningStatuses,
    isArrivingReservation,
    isDepartingReservation,
    reservationTypes,
    roomReservations,
    roomStatuses,
    selectedParticipant,
    selectedSale,
  })

  return (
    <Wrapper>
      {loading ? (
        <LoadingPlaceholder flex={1} size="xl" transparent />
      ) : error ? (
        <ContentPlaceholder size="lg">
          <T>FrontDesk:DailyRoomReservations.error</T>
        </ContentPlaceholder>
      ) : !filteredRoomReservations.length ? (
        <ContentPlaceholder size="lg">
          <T>FrontDesk:DailyRoomReservations.empty</T>
        </ContentPlaceholder>
      ) : (
        <ListWrapper>
          <RoomReservations reservations={filteredRoomReservations} />
        </ListWrapper>
      )}
    </Wrapper>
  )
}

////////////

const ListWrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1800px;
  min-width: 1440px;
  width: 100%;
  z-index: 2;

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gu(4)}rem;
  `}
`

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: auto;
  position: relative;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    padding-top: ${theme.spacing.gu(4)}rem;
  `}
`
