import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'

import {
  ControlRow,
  LayoutCalendar,
  PrintCalendar,
  Sidebar,
} from './components'
import { useRoomLayoutContext } from './RoomLayoutContext'
import { Week } from './types'

export const RoomLayout = () => {
  const { calendarWrapperRef } = useRoomLayoutContext()

  const [showPrintVersionForWeek, setShowPrintVersionForWeek] =
    useState<Week | null>(null)
  const handleSetShowPrintVersion = (week: Week | null) =>
    setShowPrintVersionForWeek(week)

  return showPrintVersionForWeek ? (
    <PrintCalendar
      week={showPrintVersionForWeek}
      onClose={() => handleSetShowPrintVersion(null)}
    />
  ) : (
    <Wrapper flex={1}>
      <CalendarWrapper flex={1} ref={calendarWrapperRef}>
        <ControlRow openPrintableVersion={handleSetShowPrintVersion} />
        <LayoutCalendar />
      </CalendarWrapper>
      <Sidebar />
    </Wrapper>
  )
}

///////

const Wrapper = styled(FlexRow)`
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`

const CalendarWrapper = styled(FlexColumn)`
  margin-right: 5px;
  overflow: hidden;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border-right: 1px solid ${theme.palette.smoke.main};
  `}
`
