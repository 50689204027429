import { formatCurrency } from '@/utils/currency'
import React from 'react'

import { FlexColumn } from '@/components/Layout'
import { PaymentType } from '~generated-types'

import type {
  NotesByType,
  PaymentAmountsByType,
} from '../usePaymentsForInterval'

export interface PaymentCellsProps {
  amounts: PaymentAmountsByType
  notes: NotesByType | null
  voucherProviders: string[]
}

export const PaymentCells = ({
  amounts,
  notes,
  voucherProviders,
}: PaymentCellsProps) => {
  const renderColumnData = (
    type: PaymentType.Cash | PaymentType.CreditCard | PaymentType.GiftCard
  ) => (
    <FlexColumn noPadding>
      <span>{formatCurrency(amounts[type].toNumber())}</span>
      {notes && !!notes[type] ? <small>{notes[type]}</small> : null}
    </FlexColumn>
  )

  return (
    <React.Fragment>
      <td className="show-left-border" style={{ textAlign: 'right' }}>
        {renderColumnData(PaymentType.Cash)}
      </td>
      <td style={{ textAlign: 'right' }}>
        {renderColumnData(PaymentType.CreditCard)}
      </td>
      <td style={{ textAlign: 'right' }}>
        {renderColumnData(PaymentType.GiftCard)}
      </td>
      {voucherProviders.map((provider) => {
        const voucherAmount = amounts.vouchersByProvider[provider]?.toNumber()
        const voucherNotes = notes?.vouchersByProvider?.[provider]

        return (
          <td
            key={`payment-cell-voucher-${provider}`}
            style={{ textAlign: 'right' }}
          >
            <FlexColumn noPadding>
              <span>
                {voucherAmount ? formatCurrency(voucherAmount) : '0.00'}
              </span>
              {voucherNotes ? <small>{voucherNotes}</small> : null}
            </FlexColumn>
          </td>
        )
      })}
    </React.Fragment>
  )
}

export default PaymentCells
