import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useTheme } from '@/theme'

import { getBaseStyles } from './getBaseStyles'

type Props = {
  children: ReactNode
  icon?: IconProp
  style?: CSSProperties
}

export const InlineModalIconSection = ({
  children,
  icon,
  style,
  ...props
}: Props) => {
  const { palette } = useTheme()

  return (
    <Wrapper style={style} {...props}>
      <IconContainer>
        {icon && (
          <FontAwesomeIcon color={palette.text.light} icon={icon} size="sm" />
        )}
      </IconContainer>
      <Content>{children}</Content>
    </Wrapper>
  )
}

////////////

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;

  ${({ theme }) => css`
    width: ${getBaseStyles(theme).iconColumnWidth};
  `}

  /* Shift one pixel down to render more nicely */
  position: relative;
  bottom: -1px;
`

const Content = styled.div`
  flex: 1;
  min-width: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled.div`
  display: flex;
  align-items: baseline;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem 0
      ${theme.spacing.gu(1)}rem;
  `}
`
