import { gql, useMutation } from '@apollo/client'

import {
  UpdateSalesNameMutation,
  UpdateSalesNameMutationVariables,
} from '~generated-types'

export type { UpdateSalesNameMutation, UpdateSalesNameMutationVariables }

export const UPDATE_SALES_NAME_MUTATION = gql`
  mutation UpdateSalesName($input: SalesUpdateNameInput!) {
    salesUpdateName(input: $input) {
      id
      name
    }
  }
`

export function useUpdateSalesNameMutation() {
  return useMutation<UpdateSalesNameMutation, UpdateSalesNameMutationVariables>(
    UPDATE_SALES_NAME_MUTATION
  )
}

export default useUpdateSalesNameMutation
