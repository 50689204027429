import { SalesForDateType } from '~generated-types'
import { Theme } from '@/theme'

export enum ColorVariant {
  DARK = 'DARK',
  DEFAULT = 'DEFAULT',
  LIGHT = 'LIGHT',
}

export const getVisitTypeColor = (
  type: SalesForDateType,
  variant: ColorVariant,
  theme: Theme
): string => {
  if (variant === ColorVariant.DARK) {
    switch (type) {
      case SalesForDateType.Arriving:
        return theme.palette.success.dark
      case SalesForDateType.DayVisitor:
        return theme.palette.primary.dark
      case SalesForDateType.Departing:
        return theme.palette.danger.dark
      case SalesForDateType.Present:
        return theme.palette.warning.darker
    }
  } else if (variant === ColorVariant.LIGHT) {
    switch (type) {
      case SalesForDateType.Arriving:
        return theme.palette.success.lighter
      case SalesForDateType.DayVisitor:
        return theme.palette.primary.lighter
      case SalesForDateType.Departing:
        return theme.palette.danger.lighter
      case SalesForDateType.Present:
        return theme.palette.warning.light
    }
  } else {
    switch (type) {
      case SalesForDateType.Arriving:
        return theme.palette.success.main
      case SalesForDateType.DayVisitor:
        return theme.palette.primary.main
      case SalesForDateType.Departing:
        return theme.palette.danger.main
      case SalesForDateType.Present:
        return theme.palette.warning.dark
    }
  }
}
