import { MouseEvent as ReactMouseEvent, Ref } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTheme } from '@/theme'

type Props = {
  innerRef?: Ref<HTMLDivElement>
  isVisible: boolean
  isActive: boolean
  onClick: (e: ReactMouseEvent<HTMLElement, MouseEvent>) => void
}

export const NewServiceButton = ({
  innerRef,
  isVisible,
  isActive,
  onClick,
}: Props) => {
  const { palette } = useTheme()

  return (
    <Wrapper ref={innerRef} onClick={onClick}>
      {isVisible && (
        <IconWrapper>
          <FontAwesomeIcon
            color={palette.primary.main}
            fixedWidth
            size="xs"
            icon={isActive ? 'minus' : 'plus'}
          />
        </IconWrapper>
      )}
    </Wrapper>
  )
}

/////

const IconWrapper = styled.div`
  height: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  width: 30px;
  height: fit-content;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
