import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'

import Calendar from './components/Calendar'
import Sidebar from './components/Sidebar'
import { useReservationsGridState } from './components/Calendar'

const CalendarWrapper = () => {
  const { calendarWrapperRef } = useReservationsGridState()

  return (
    <FlexRow flex={1} style={{ overflow: 'hidden' }}>
      <Wrapper ref={calendarWrapperRef}>
        <Calendar />
      </Wrapper>
      <Sidebar />
    </FlexRow>
  )
}

export default CalendarWrapper

////////////

const Wrapper = styled(FlexColumn)`
  overflow: hidden;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.main};
  `}
`
