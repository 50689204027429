import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useTheme } from '@/theme'

type Props = {
  icon?: IconProp
  size?: 'sm' | 'default'
  style?: CSSProperties
  value: ReactNode
}

const ListItemSectionIconValue = ({ icon, size, style, value }: Props) => (
  <IconValueWrapper style={style}>
    <IconWrapper>{icon && <Icon icon={icon} />}</IconWrapper>
    {size === 'sm' ? <small>{value}</small> : value}
  </IconValueWrapper>
)

export default ListItemSectionIconValue

////////////

const IconValueWrapper = styled.span`
  display: flex;
  align-items: center;
`

const IconWrapper = styled.span`
  flex: 0 0 16px;
  display: flex;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

type IconProps = {
  icon: IconProp
}

export const Icon = ({ icon }: IconProps) => {
  const theme = useTheme()

  return (
    <FontAwesomeIcon
      color={theme.palette.text.lighter}
      fixedWidth
      icon={icon}
      size="xs"
    />
  )
}
