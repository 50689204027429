import { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import {
  InlineModal,
  InlineModalHeader,
  InlineModalSection,
} from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'

import {
  CellStatus,
  Emphasis,
  RefetchResponce,
} from '../../../AvailabilityCalendar.types'
import { DataCell } from './'
import { ReservationsInfo } from './SaleInfo'
import { ScrollTableProps } from '../ScrollTable'

type RoomType = {
  id: string
  name: string
}

type Props = ScrollTableProps & {
  categoryName?: string
  children: ReactNode
  dateKey: string
  depth: number
  emphasis: Emphasis | null | undefined
  isLast: boolean
  refetchAvailability: () => Promise<RefetchResponce>
  roomTypes: RoomType[]
  status: CellStatus
}

export const DataCellWithSaleInfo = ({
  categoryName,
  children,
  dateKey,
  refetchAvailability,
  roomTypes,
  ...dataCellProps
}: Props) => {
  const [isModalOpen, setModalOpen] = useState(false)

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalHeader
            onClose={() => setModalOpen(false)}
            title={
              <TitleWrapper>
                {roomTypes.map(({ name }) => (
                  <RoomType key={name}>{name}</RoomType>
                ))}
                {categoryName ? `${categoryName} ` : ''}
                {moment(dateKey).format('DD.MM.')}
              </TitleWrapper>
            }
          />
          <ContentWrapper>
            <ReservationsInfo
              dateKey={dateKey}
              roomTypes={roomTypes}
              refetchAvailability={refetchAvailability}
            />
          </ContentWrapper>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <StyledDataCell
          ref={ref}
          onClick={() => setModalOpen(true)}
          isOpen={isModalOpen}
          {...dataCellProps}
        >
          {children}
        </StyledDataCell>
      )}
    />
  )
}

///////

const ContentWrapper = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const RoomType = styled.span`
  ${({ theme }) => css`
    background-color: ${theme.palette.primary.extraLight};
    color: ${theme.palette.primary.dark};
    margin-right: ${theme.spacing.gutterSmall};
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
  `}
`

const StyledDataCell = styled(DataCell)<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  transition: 0.3s;

  ${({ isOpen }) =>
    isOpen &&
    `
    box-shadow: 0px 0px 4px 4px rgb(0 0 0 / 0.14);
    z-index: 1;
  `}

  &:hover {
    opacity: 0.75;
  }
`

const TitleWrapper = styled.div`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin-left: -${theme.spacing.gu(6)}rem;
  `}
`
