import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import TH from '../elements/TH'

type WidthValue = number | string
type OverlayPosition = 'left' | 'right'

type Props = {
  children?: ReactNode
  forcedWidth?: WidthValue
  minWidth?: WidthValue
  overlay?: ReactNode
  overlayPosition?: OverlayPosition
}

const HeaderCell = ({
  children,
  overlay,
  overlayPosition = 'left',
  ...props
}: Props) => (
  <TH {...props}>
    {children}
    {!children && overlay && (
      <Overlay position={overlayPosition}>{overlay}</Overlay>
    )}
  </TH>
)

export default HeaderCell

////////////

const Overlay = styled.div<{ position?: OverlayPosition }>`
  position: absolute;
  display: inline-flex;
  align-items: center;
  height: 100%;
  top: 0;

  ${({ position }) => position || 'left'}: ${({ theme }) =>
    theme.spacing.gu(1)}rem;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}

  line-height: 20px;
  white-space: nowrap;
`
