import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import Keycloak from 'keycloak-js'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from '@/config/env'
import { defaultTheme } from '@/theme'
import { RefreshModal } from '@/components/RefreshModal'

type Props = {
  children: ReactNode
}

interface KeycloakContextType {
  keycloak: Keycloak | null
  openRefreshModal: () => void
}

const KeycloakContext = createContext<KeycloakContextType>({
  keycloak: null,
  openRefreshModal: () => undefined,
})

export const KeycloakProvider = ({ children }: Props) => {
  const [isRefreshModalOpen, setRefreshModalOpen] = useState<boolean>(false)
  const [keycloak, setKeycloak] = useState<Keycloak | null>(null)

  useEffect(() => {
    const authClient = new Keycloak({
      clientId: KEYCLOAK_CLIENT_ID,
      realm: KEYCLOAK_REALM,
      url: KEYCLOAK_URL,
    })

    let isUpdateOngoing = false

    /**
     * Fetch new access token if current token is expired or will be expired within minValidity seconds
     * Set access token into SessionStorage
     */
    const updateToken = (minValidity = 10) => {
      if (isUpdateOngoing) {
        console.warn('Token update is already ongoing, returning...')
        return
      } else {
        isUpdateOngoing = true
        authClient
          .updateToken(minValidity)
          .then((refreshed) => {
            if (refreshed && authClient.token) {
              sessionStorage.setItem('token', authClient.token)
            }
          })
          .catch(() => {
            console.error('Failed to refresh the token')
            setRefreshModalOpen(true)
          })
          .finally(() => {
            isUpdateOngoing = false
          })
      }
    }

    /**
     * On frozen page state will be resumed, always refresh a token
     * In this scenario, the execution of the JS has been paused and the keycloak.js timeout is not reliable
     */
    document.addEventListener('resume', () => {
      updateToken(-1)
    })

    /**
     * On hidden page state will be visible, refresh a token if expired
     */
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        updateToken()
      }
    })

    /**
     * On passive page state will be active, refresh a token if expired
     */
    window.onfocus = () => {
      updateToken()
    }

    // Setup normal refresh flow: once the token will be expired, fetch a new one
    authClient.onTokenExpired = () => {
      updateToken()
    }

    authClient.init({ onLoad: 'login-required' }).then(() => {
      // Set token into session storage before rendering the other page content
      if (authClient.token) {
        sessionStorage.setItem('token', authClient.token)
      }
      setKeycloak(authClient)
    })

    // Cleanup effect
    return () => {
      if (keycloak) {
        keycloak.logout()
      }
    }
  }, [])

  const openRefreshModal = () => setRefreshModalOpen(true)

  if (!keycloak)
    return (
      <LoaderWrapper>
        <ReactLoading
          color={defaultTheme.palette.smoke.lighter}
          height={72}
          type="spin"
          width={72}
        />
      </LoaderWrapper>
    )

  return (
    <KeycloakContext.Provider value={{ keycloak, openRefreshModal }}>
      {isRefreshModalOpen && <RefreshModal />}
      {children}
    </KeycloakContext.Provider>
  )
}

export const useKeycloakContext = () => useContext(KeycloakContext)

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  position: fixed;
  width: 100%;
`
