import React from 'react'

import { DataSearch, DateRange, MultiList } from '@/components/Reactivesearch'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FilterSection } from '@/components/ElasticFilterSearchList'

import { ExpiredTasksFilter } from './ExpiredTasksFilter'
import { TaskStatusFilter } from './TaskStatusFilter'

type Props = {
  componentIds: {
    [key: string]: string
  }
}

const ListFilters = ({ componentIds }: Props) => {
  const { language } = useLanguageContext()

  return (
    <>
      <FilterSection
        label={<T>Tasks:ListControls.search</T>}
        render={() => (
          <DataSearch
            autosuggest={false}
            componentId={componentIds.SEARCH || 'undefined'}
            dataField="name"
            filterLabel={translate('Tasks:ListControls.search', language)}
            placeholder={translate(
              'Tasks:ListControls.searchPlaceholder',
              language
            )}
            queryFormat={'and'}
            showClear
            showIcon
            URLParams
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Tasks:ListHeader.assignee</T>}
        render={({ isCollapsed }) => (
          <MultiList
            componentId={componentIds.ASSIGNEE}
            dataField="assignee.name.keyword"
            filterLabel={translate('Tasks:ListHeader.assignee', language)}
            isCollapsed={isCollapsed}
            missingLabel={translate('Tasks:ListHeader.noAssignee', language)}
            showCheckbox
            showMissing
            showSearch={false}
            URLParams
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Tasks:ListFilters.status.title</T>}
        render={({ isCollapsed }) => (
          <TaskStatusFilter
            componentId={componentIds.STATUS}
            filterLabel={translate('Tasks:ListFilters.status.title', language)}
            l10nPrefix="Tasks:ListFilters.status.options"
            isCollapsed={isCollapsed}
            showFilter
            URLParams
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Tasks:ListFilters.expiredDueDate.title</T>}
        render={({ isCollapsed }) => (
          <ExpiredTasksFilter
            componentId={componentIds.EXPIRED}
            filterLabel={translate(
              'Tasks:ListFilters.expiredDueDate.title',
              language
            )}
            l10nPrefix="Tasks:ListFilters.expiredDueDate.options"
            isCollapsed={isCollapsed}
            showFilter
            URLParams
          />
        )}
      />
      <FilterSection
        canCollapse
        label={<T>Tasks:ListHeader.due</T>}
        render={({ isCollapsed }) => (
          <DateRange
            componentId={componentIds.DATES}
            dataField="dueDate"
            hideClearButton
            isCollapsed={isCollapsed}
            filterLabel={translate('Tasks:ListHeader.due', language)}
            URLParams
          />
        )}
      />
    </>
  )
}

export default ListFilters
