import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'
import { RefObject } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { DatePickerControl } from '@/components/TimeControls'
import { EditButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

type Props = {
  date: Moment | null
  isDateValid: boolean
  setDate: (date: Moment | null) => void
  setDateValid: (isDateValid: boolean) => void
}

export const NewSaleDateSection = ({
  date,
  isDateValid,
  setDate,
  setDateValid,
}: Props) => {
  const handleSetDate = (targetDate?: Moment | null) => {
    if (targetDate) {
      setDate(targetDate)
      setDateValid(true)
    }
  }

  return (
    <Wrapper>
      <DatePickerControl
        placement="bottom-start"
        render={({ date, ref, toggleModal }) => (
          <DateButton
            error={!isDateValid}
            onClick={toggleModal}
            innerRef={ref as RefObject<HTMLButtonElement>}
          >
            <FlexRow>
              <CalendarIcon icon={['far', 'calendar']} />
              {date ? (
                date.format('dd, D.M.YY')
              ) : (
                <Placeholder>
                  <T>SalesDetails:CopySale.label.DATE</T>
                </Placeholder>
              )}
            </FlexRow>
          </DateButton>
        )}
        setValue={handleSetDate}
        value={date}
      />
    </Wrapper>
  )
}

/////

const CalendarIcon = styled(FontAwesomeIcon)`
  width: 18px;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const DateButton = styled(EditButton)<{ error: boolean }>`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  ${({ error, theme }) =>
    error &&
    css`
      box-shadow: 0 0 0 1px ${theme.palette.danger.main};
    `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(3)}rem 0;
  `}
`
