import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import {
  Gender as GenderType,
  SalesParticipantUpdateInput as UpdateInput,
} from '~generated-types'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Spacing, useTheme } from '@/theme'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { EditButton } from '@/components/ExtraButtons'
import { InlineModalIconSection } from '@/components/InlineModal'
import { Participant } from '@/modules/FrontDesk'

import { Placeholder } from '../../common'

type Props = {
  participant: Participant
  updateParticipant: (input: UpdateInput) => Promise<any>
}

export const Gender = ({
  participant: {
    gender,
    id,
    sales: { locked },
  },
  updateParticipant,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const handleSelectGender = (input: UpdateInput) => {
    setProcessing(true)

    updateParticipant(input).finally(() => {
      setProcessing(false)
      setEditMode(false)
    })
  }

  const genderOptions = Object.values(GenderType).map((gender: GenderType) => ({
    label: translate(`enums:gender.${gender}`, language),
    value: gender,
  }))

  const clearOption = {
    label: (
      <ClearSelectionLabel>
        <T>Reactivesearch:clearSelection</T>
      </ClearSelectionLabel>
    ),
    value: '',
  }

  const options = gender ? [...genderOptions, clearOption] : genderOptions

  return (
    <InlineModalIconSection icon={getGenderIcon(gender)}>
      {isEditMode ? (
        <>
          <ThemedSelect
            autoFocus
            extraStyles={getExtraStyles(spacing)}
            isClearable
            isCompact
            isLoading={processing}
            menuIsOpen={isEditMode}
            name="participant-gender-selector"
            onBlur={() => setEditMode(false)}
            onChange={(o: Option | null | undefined) =>
              handleSelectGender({
                gender: (o?.value || null) as GenderType | null,
                id,
              })
            }
            options={options}
            placeholder={
              <T>FrontDesk:DailyRoomReservations.participants.setGender</T>
            }
            value={
              gender
                ? {
                    label: translate(`enums:gender.${gender}`, language),
                    value: gender,
                  }
                : null
            }
          />
        </>
      ) : (
        <EditButton
          disabled={locked}
          onClick={() => setEditMode(true)}
          style={{
            color: palette.text.light,
            width: `calc(100% + ${spacing.gu(1)}rem)`,
          }}
        >
          {gender ? (
            translate(`enums:gender.${gender}`, language)
          ) : (
            <Placeholder>
              <T>FrontDesk:DailyRoomReservations.participants.setGender</T>
            </Placeholder>
          )}
        </EditButton>
      )}
    </InlineModalIconSection>
  )
}

////////////

const getGenderIcon = (gender: GenderType | null) => {
  switch (gender) {
    case GenderType.Female:
      return 'venus'
    case GenderType.Male:
      return 'mars'
    case GenderType.Unknown:
      return 'genderless'
    default:
      return 'venus-mars'
  }
}

const getExtraStyles = (spacing: Spacing) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(1)}rem)`,
    zIndex: 10005,
  }),
})

const ClearSelectionLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}
`
