import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'
import { StateProvider } from '@appbaseio/reactivesearch'

import { CategorySet } from '@/modules/Registry/Category'

import { groupNamesBySet } from '../utils'

type Props = {
  categorySets: CategorySet[]
  componentId: string
  placeholder: ReactNode
}

export const CollapsedCategorySelectorValue = ({
  categorySets,
  componentId,
  placeholder,
}: Props) => (
  <StateProvider
    render={({ searchState }) => {
      const rawValue = searchState?.[componentId]?.value
      const value = Array.isArray(rawValue)
        ? groupNamesBySet(rawValue, categorySets)
        : rawValue
      const showFormatted = Array.isArray(rawValue)

      return (
        <Wrapper>
          {showFormatted ? (
            Object.keys(value)
              .sort()
              .map((set) => (
                <Row key={`collapsed-set-${set}`}>
                  <Label>{set}</Label>
                  <Value>{value[set].join(', ')}</Value>
                </Row>
              ))
          ) : (
            <Row>
              {value ? (
                <Value>{value}</Value>
              ) : (
                <ValuePlaceholder>{placeholder}</ValuePlaceholder>
              )}
            </Row>
          )}
        </Wrapper>
      )
    }}
  />
)

////////////

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2px;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.smoke.lighter};
    border: 1px solid ${theme.palette.smoke.main};
  `}
`

const Row = styled.div`
  display: inline-flex;
  align-items: baseline;
`

const Label = styled.span`
  flex: 0 0 25%;
  white-space: pre-wrap;
  text-align: right;
  font-variant-caps: all-small-caps;
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-right: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Value = styled.span`
  font-weight: 400;
  flex: 1;
  white-space: pre-wrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const ValuePlaceholder = styled(Value)`
  font-style: italic;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
