import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'
import { useState } from 'react'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { translate, useLanguageContext } from '@/modules/Language'
import { FlexColumn } from '@/components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { Gender } from '~generated-types'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

import OptionSelector from './common/OptionSelector'

type Props = {
  gender: Gender | null | undefined
  isVSTError?: boolean
  readOnly?: boolean
  handleSelect: (gender: string | null) => Promise<any>
}

const ParticipantGender = ({
  gender,
  isVSTError,
  readOnly,
  handleSelect,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)
  const [processing, setProcessing] = useState(false)

  const genderOptions = [
    {
      label: translate('enums:gender.UNDEFINED', language),
      value: '',
    },
    {
      label: translate('enums:gender.MALE', language),
      value: 'MALE',
    },
    {
      label: translate('enums:gender.FEMALE', language),
      value: 'FEMALE',
    },
    {
      label: translate('enums:gender.UNKNOWN', language),
      value: 'UNKNOWN',
    },
  ]

  return (
    <>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <InlineModal style={{ overflow: 'visible', width: '250px' }}>
            <InlineModalSection style={{ margin: 0, padding: 0 }}>
              <FlexColumn
                alignItems={'stretch'}
                flex={1}
                style={{ padding: `${spacing.gu(2)}rem` }}
              >
                <OptionSelector
                  options={genderOptions}
                  value={gender || ''}
                  style={{ margin: `${spacing.gu(1)}rem 0` }}
                  handleChange={(gender: string | null) => {
                    setProcessing(true)

                    return handleSelect(gender || null).then(() => {
                      setProcessing(false)
                      setModalOpen(false)
                    })
                  }}
                />
              </FlexColumn>
            </InlineModalSection>
          </InlineModal>
        }
        onClose={() => setModalOpen(false)}
        placement="bottom"
        referenceElement={({ ref }) => (
          <Selector
            ref={ref}
            disabled={readOnly}
            isError={isVSTError}
            onClick={() => !readOnly && setModalOpen(true)}
          >
            {!processing ? (
              <FontAwesomeIcon
                icon={
                  gender === 'MALE'
                    ? 'mars'
                    : gender === 'FEMALE'
                    ? 'venus'
                    : gender === 'UNKNOWN'
                    ? 'genderless'
                    : 'venus-mars'
                }
                color={gender ? palette.black : palette.smoke.dark}
                size={'lg'}
              />
            ) : (
              <ReactLoading
                type={'spin'}
                height={18}
                width={18}
                color={palette.smoke.main}
              />
            )}
          </Selector>
        )}
      />
    </>
  )
}

export default ParticipantGender

const Selector = styled.div<{ disabled?: boolean; isError?: boolean }>`
  transition: all 0.1s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(5)}rem;
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ disabled, isError, theme }) =>
    css`
      background-color: ${disabled
        ? theme.palette.smoke.light
        : isError
        ? '#fff2ef'
        : theme.palette.white};
    `};

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
