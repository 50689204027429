import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { FlexRow } from '@/components/Layout'
import { IconButton } from '@/components/ExtraButtons'
import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  value: string | null
  dietaryOptions: string[]
  placeholder: React.ReactNode
  onRemove: () => Promise<any>
  onAdd: (dietValue: string) => Promise<any>
}

const DietValueWrapper = ({
  value,
  dietaryOptions,
  placeholder,
  onRemove,
  onAdd,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [isHover, setHover] = useState<boolean>(false)

  const onSetLabel = (selectedOption: Option | null | undefined) => {
    setHover(false)
    setEditMode(false)

    return !!selectedOption ? onAdd(selectedOption.value) : onRemove()
  }

  const extraStyles = {
    control: (styles: CSSProperties) => ({
      ...styles,
      cursor: 'pointer',
      height: '30px',
      marginLeft: `-${spacing.gu(1)}rem`,
      minHeight: '30px',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      marginLeft: `-${spacing.gu(1)}rem`,
      width: `calc(100% + ${spacing.gu(1)}rem)`,
      zIndex: 10005,
    }),
    option: (styles: CSSProperties) => ({
      ...styles,
      cursor: 'pointer',
    }),
  }

  const options = dietaryOptions.map((option) => ({
    label: option,
    searchValue: option,
    value: option,
  }))

  return (
    <InlineModalIconSection
      icon="utensils"
      style={{
        flexDirection: 'row',
        padding: `0 ${spacing.gu(3)}rem ${spacing.gu(1)}rem ${spacing.gu(
          1
        )}rem`,
      }}
    >
      <FlexRow alignItems="center">
        {isEditMode ? (
          <>
            <ThemedSelect
              autoFocus
              extraStyles={extraStyles}
              isClearable
              isCompact
              isSearchable
              menuIsOpen={isEditMode}
              name="dimension-selector"
              noOptionsMessage={() => (
                <T>SalesDetails:dimensions.error.emptyLabels</T>
              )}
              onBlur={() => {
                setEditMode(false)
                setHover(false)
              }}
              onChange={(option: Option | null | undefined) =>
                onSetLabel(option)
              }
              options={options}
              placeholder={placeholder}
              value={
                value
                  ? {
                      label: value,
                      value,
                    }
                  : null
              }
            />
          </>
        ) : (
          <ValueWrapper
            onClick={() => setEditMode(true)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            {value ? value : <ValuePlaceholder>{placeholder}</ValuePlaceholder>}
            {isHover && (
              <FontAwesomeIcon color={palette.text.dark} icon="pen" size="sm" />
            )}
          </ValueWrapper>
        )}
        <IconButton
          color="transparent"
          icon="trash"
          onClick={onRemove}
          style={{
            color: !value ? palette.smoke.dark : palette.danger.main,
            marginLeft: spacing.gutterSmall,
          }}
        />
      </FlexRow>
    </InlineModalIconSection>
  )
}

export default DietValueWrapper

const ValueWrapper = styled(FlexRow)`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  font-weight: 500;
  height: 30px;
  cursor: pointer;
  transition: 0.2s;

  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.white};
    color: ${theme.palette.text.dark};
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const ValuePlaceholder = styled.span`
  font-weight: 300;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
