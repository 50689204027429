import { CirclePicker, ColorResult } from 'react-color'
import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  handleSubmit: (colorHex: string | null) => void
  value: string | null | undefined
}

export const DataFieldColorPickerModal = ({ handleSubmit, value }: Props) => {
  const theme = useTheme()

  return (
    <InlineModal style={{ minWidth: 'none ' }}>
      <PickerWrapper>
        <CirclePicker
          color={value ?? theme.palette.white}
          onChangeComplete={(color: ColorResult) => handleSubmit(color.hex)}
        />
        {value && (
          <>
            <Divider />
            <InnocuousButton compact onClick={() => handleSubmit(null)}>
              <T>ResourceReservations:product.colorPickerClear</T>
            </InnocuousButton>
          </>
        )}
      </PickerWrapper>
    </InlineModal>
  )
}

////////////

const Divider = styled.div`
  width: 4rem;
  height: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
    margin: ${theme.spacing.gutter} 0 ${theme.spacing.gutterSmall};
  `}
`

const PickerWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}
`
