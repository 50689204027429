import { CSSProperties, useState } from 'react'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Spacing, useTheme } from '@/theme'
import { EditButton } from '@/components/ExtraButtons'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { useSalesProductsCompact } from '@/modules/Products/hooks'

import { ContentPlaceholder, Section, TruncateContent } from '../../common'
import { PublicRoom } from '../../../types'
import { usePublishContext } from '../../../PublishState'

type Props = {
  publicRoom: PublicRoom | null
  targetId: string
}

export const ProductSelector = ({ publicRoom, targetId }: Props) => {
  const { spacing } = useTheme()
  const { readOnly, salesId, updatePublicRoom } = usePublishContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const { products, loading } = useSalesProductsCompact({
    salesId,
    skip: !isEditMode,
  })

  const options = [...products]
    .sort(generateCompareFn('name'))
    .map((product) => ({
      label: product.name,
      value: product.id,
    }))

  const handleUpdatePublicRoom = (option?: Option | null) => {
    if (option) {
      updatePublicRoom({
        roomId: publicRoom?.id,
        targetId,
        update: { productId: option.value },
      }).finally(() => setEditMode(false))
    }
  }

  const product = publicRoom?.product

  return (
    <Section flex={1.75}>
      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isLoading={loading}
          isDisabled={readOnly}
          menuIsOpen={isEditMode && !loading}
          name="webshop-product-selector"
          noOptionsMessage={() => (
            <T>Publish:Rooms.product.noProductsAvailable</T>
          )}
          onChange={handleUpdatePublicRoom}
          onBlur={() => setEditMode(false)}
          options={options}
          placeholder={<T>Publish:Rooms.product.noProductsSelected</T>}
          value={product ? { label: product.name, value: product.id } : null}
        />
      ) : (
        <EditButton
          disabled={readOnly}
          onClick={() => setEditMode(true)}
          style={{ flex: 'unset' }}
        >
          <TruncateContent
            content={
              product?.name || (
                <ContentPlaceholder>
                  <T>Publish:Rooms.product.noProductsSelected</T>
                </ContentPlaceholder>
              )
            }
          />
        </EditButton>
      )}
    </Section>
  )
}

///////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
})
