import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { HideFromPrintSpan, PrintOnlyTitle } from '@/modules/Listing/common'
import { FlexRow } from '@/components/Layout'
import { LinkLikeButton } from '@/components/ExtraButtons'
import PrintButton from '@/components/PrintButton'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  AgeGroupBreakdown,
  ParticipantsMode,
  PrintMode,
  PrintSettings,
} from '../common'
import type { DeparturesSales } from './useDeparturesForDate'
import { PrintSize } from '../../types'
import { SalesTable } from './SalesTable'

type CountsForSales = Record<string, Record<string, number>>

export interface DeparturesListingProps {
  printSize: PrintSize
  sales: Readonly<DeparturesSales[]>
  targetDate: Moment
}

export const DeparturesListing = ({
  printSize,
  sales,
  targetDate,
}: DeparturesListingProps) => {
  const { spacing } = useTheme()

  const [printMode, setPrintMode] = useState<PrintMode>(PrintMode.All)
  const [participantsMode, setParticipantsMode] = useState<ParticipantsMode>(
    ParticipantsMode.DeparturesTODAY
  )
  const [selectedSales, setSelectedSales] = useState<string[]>([])

  const salesGuestCounts: CountsForSales = countForSales(
    filterDepartingParticipants(sales, targetDate)
  )

  const totalParticipants = countsTotalSum(salesGuestCounts)

  return (
    <>
      <PrintOnlyTitle style={{ marginBottom: `${spacing.gu(1)}rem` }}>
        <T>Listings:ArrivalsAndDeparturesListing.departures.title</T>
        {' | '}
        {targetDate.format('dd D.M.YYYY')}
      </PrintOnlyTitle>

      <FlexRow alignItems="flex-end" justifyContent="flex-end">
        <FlexRow alignItems="flex-end">
          <PrintSettings
            mode={printMode}
            setMode={setPrintMode}
            setParticipantsMode={setParticipantsMode}
            participantsMode={participantsMode}
            participantsModeOptions={[
              ParticipantsMode.All,
              ParticipantsMode.DeparturesTODAY,
            ]}
            printModeOptions={[PrintMode.All, PrintMode.Selected]}
          />

          <HideFromPrintSpan>
            <PrintButtonWrapper>
              <PrintButton />
            </PrintButtonWrapper>
          </HideFromPrintSpan>
        </FlexRow>
      </FlexRow>

      {!!sales.length && (
        <>
          <FlexRow
            alignItems="baseline"
            hideFromPrint={
              printMode === PrintMode.Selected && !selectedSales.length
            }
            style={{ marginBottom: `${spacing.gu(2)}rem` }}
          >
            <FlexRow
              justifyContent="flex-end"
              flex={1}
              style={{
                marginBottom: `${spacing.gu(1)}rem`,
                marginTop: spacing.gutter,
              }}
            >
              <AgeGroupBreakdown
                counts={totalParticipants}
                title={
                  <T
                    l10n={`Listings:ArrivalsAndDeparturesListing.departures.totalDepartures`}
                  />
                }
              />
            </FlexRow>
            {printMode === PrintMode.Selected && (
              <FlexRow hideFromPrint>
                <Separator />
                <T>
                  Listings:ArrivalsAndDeparturesListing.selection.targets
                </T>: {selectedSales.length}
                <Separator />
                <LinkLikeButton
                  onClick={() => setSelectedSales(sales.map(({ id }) => id))}
                >
                  <T>
                    Listings:ArrivalsAndDeparturesListing.selection.selectAll
                  </T>
                </LinkLikeButton>
                <Separator />
                <LinkLikeButton onClick={() => setSelectedSales([])}>
                  <T>
                    Listings:ArrivalsAndDeparturesListing.selection.selectNone
                  </T>
                </LinkLikeButton>
              </FlexRow>
            )}
          </FlexRow>

          <SalesTable
            data={sales}
            guestCounts={salesGuestCounts}
            printSize={printSize}
            selection={[...selectedSales]}
            selectionEnabled={printMode === PrintMode.Selected}
            targetDate={targetDate}
            participantsMode={participantsMode}
            toggleSelection={(targetId: string) =>
              setSelectedSales(
                selectedSales.includes(targetId)
                  ? selectedSales.filter((x) => x !== targetId)
                  : [...selectedSales, targetId]
              )
            }
          />
        </>
      )}
    </>
  )
}

export default DeparturesListing

////////////

const countForSales = (sales: Readonly<DeparturesSales[]>) => {
  const salesGuestCounts: Record<string, Record<string, number>> = {}

  sales.forEach(
    (sale) =>
      (salesGuestCounts[sale.id] = sale.participants.reduce(
        (acc: Record<string, number>, { ageCategory }) => {
          const categoryKey = ageCategory?.shortName || 'unknown'
          return {
            ...acc,
            [categoryKey]: (acc[categoryKey] || 0) + 1,
            total: acc.total + 1,
          }
        },
        {
          total: 0,
          unknown: 0,
        }
      ))
  )

  return salesGuestCounts
}

const countsTotalSum = (totals: Record<string, Record<string, number>>) =>
  Object.keys(totals).reduce(
    (acc: Record<string, number>, val) => {
      const returnValue = { ...acc }
      Object.keys(totals[val]).forEach(
        (key) => (returnValue[key] = (returnValue[key] || 0) + totals[val][key])
      )
      return returnValue
    },
    {
      total: 0,
      unknown: 0,
    }
  )

const filterDepartingParticipants = (
  data: Readonly<DeparturesSales[]>,
  date: Moment
) =>
  data.map((sale) => {
    const participants = sale.participants
      .map((participant) => {
        const services = participant.services.filter((service) => {
          if (service.__typename === 'ServiceParticipantBed') {
            return moment(service.dates?.checkOut.date).isSame(date, 'day')
          } else {
            return false
          }
        })

        return { ...participant, services }
      })
      .filter((participants) => !!participants.services.length)

    return { ...sale, participants }
  })

const StyledSeparator = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
  `}
`

const PrintButtonWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing.gu(0.1)}rem 0;
  margin-left: ${({ theme }) => theme.spacing.gu(3)}rem;
`
const Separator = () => <StyledSeparator>|</StyledSeparator>
