import { CSSProperties, ReactNode, useState } from 'react'
import { Field, FieldProps } from 'formik'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { InlineFieldButton } from '@/components/ExtraButtons'
import { OutsideClick } from '@/components/OutsideClick'
import { useTheme } from '@/theme'

import { HoverHelper } from './HoverHelper'

type Props = {
  buttonStyle?: CSSProperties
  disabled?: boolean
  fieldName: string
  handleOnBlur?: (...args: Array<any>) => any
  hideEditIcon?: boolean
  placeholder?: ReactNode
  renderInput: (
    fieldProps: FieldProps,
    onBlur: (...args: Array<any>) => any,
    onKeyDown: (...args: Array<any>) => any
  ) => ReactNode
  renderValue: (value: string) => ReactNode
  wrapperStyle?: CSSProperties
}

export const ToggleFieldHelper = ({
  buttonStyle = {},
  disabled,
  fieldName,
  handleOnBlur,
  hideEditIcon,
  placeholder,
  renderInput,
  renderValue,
  wrapperStyle,
}: Props) => {
  const theme = useTheme()

  const [isEditing, setEditing] = useState(false)

  const valueComponent = ({ field }: FieldProps) => {
    const value = field.value
    const hasValue = value !== '' && value !== null && value !== undefined

    return (
      <HoverHelper disabled={disabled}>
        <InlineFieldButton
          disabled={disabled}
          onClick={() => setEditing(true)}
          style={{
            justifyContent: 'space-between',
            padding: `${theme.spacing.gu(1)}rem`,
            ...buttonStyle,
          }}
        >
          <div
            style={{
              margin: `0 ${hideEditIcon ? '0' : theme.spacing.gutter} 0 0`,
            }}
          >
            {hasValue ? renderValue(value) : placeholder || '—'}
          </div>
          {!hideEditIcon && (
            <FontAwesomeIcon
              className="ifb-icon"
              size="sm"
              color={theme.palette.text.light}
              icon="pen"
            />
          )}
        </InlineFieldButton>
      </HoverHelper>
    )
  }
  const input = (fieldProps: FieldProps) => {
    const onBlur = async () => {
      handleOnBlur && handleOnBlur()
      setEditing(false)
    }
    const onKeyDown = async (e: any) => {
      if (e.key === 'Enter') {
        handleOnBlur && handleOnBlur()
        setEditing(false)
      }
    }

    return (
      <OutsideClick onOutsideClick={() => setEditing(false)}>
        {renderInput(fieldProps, onBlur, onKeyDown)}
      </OutsideClick>
    )
  }

  return (
    <FieldWrapper style={wrapperStyle || {}}>
      <Field
        name={fieldName}
        render={(fieldProps: any) =>
          isEditing && !disabled
            ? input(fieldProps)
            : valueComponent(fieldProps)
        }
      />
    </FieldWrapper>
  )
}

////////////

const FieldWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: -${theme.spacing.gu(1)}rem;
  `}

  &:first-child {
    ${({ theme }) => css`
      margin-left: -${theme.spacing.gu(1)}rem;
    `}
  }
`
