import { gql, useMutation } from '@apollo/client'

import {
  SetSalesAssigneeMutation,
  SetSalesAssigneeMutationVariables,
} from '~generated-types'

export type { SetSalesAssigneeMutation, SetSalesAssigneeMutationVariables }

export const SET_SALES_ASSIGNEE = gql`
  mutation SetSalesAssignee($input: SalesUpdateAssigneeInput!) {
    salesAssignee(input: $input) {
      id
    }
  }
`

export function useSetSalesAssigneeMutation() {
  return useMutation<
    SetSalesAssigneeMutation,
    SetSalesAssigneeMutationVariables
  >(SET_SALES_ASSIGNEE)
}

export default useSetSalesAssigneeMutation
