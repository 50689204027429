import { useTheme as libUseTheme, ThemeProvider } from 'styled-components'
import { ReactNode } from 'react'

import { useWillbaConfigContext } from '@/config'

interface Props {
  children: ReactNode
}

export const WillbaThemeProvider = ({ children }: Props) => {
  const { theme } = useWillbaConfigContext()

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export const useTheme = libUseTheme

export default WillbaThemeProvider
