import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTheme } from '@/theme'

import { Placeholder } from './common'

type Props = {
  disabled?: boolean
  isInvalid?: boolean
  label: string
  placeholder?: string
}

export const ValueReadOnly = ({
  disabled,
  isInvalid,
  label,
  placeholder,
}: Props) => {
  const { palette } = useTheme()

  return (
    <Wrapper disabled={disabled} isInvalid={isInvalid}>
      {label ? (
        <Label>{label}</Label>
      ) : (
        <Placeholder>{placeholder || '–'}</Placeholder>
      )}

      {isInvalid && (
        <IconWrapper>
          <FontAwesomeIcon
            color={palette.danger.main}
            icon="triangle-exclamation"
          />
        </IconWrapper>
      )}
    </Wrapper>
  )
}

//////

const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Label = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`

const Wrapper = styled.button<{ disabled?: boolean; isInvalid?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  height: 30px;
  width: 100%;

  ${({ disabled }) => disabled && `opacity: 0.7;`}

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  ${({ isInvalid, theme }) =>
    isInvalid
      ? `border: 1px solid ${theme.palette.danger.main};
      box-shadow: 0 0 0 1px ${theme.palette.danger.main};`
      : `border: 1px solid ${theme.palette.white}`}
`
