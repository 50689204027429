import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { OrderPhaseType as PhaseType } from '~generated-types'
import { T } from '@/modules/Language'

type Props = {
  phase: PhaseType
  totalAmount: number
}

export const Title = ({ phase, totalAmount }: Props) => (
  <Wrapper justifyContent="space-between">
    <T>{`Orders:Payments.title.${isRefund(phase) ? 'REFUND' : phase}`}</T>
    <span>{formatCurrency(totalAmount)} €</span>
  </Wrapper>
)

///////

const isRefund = (phase: PhaseType) =>
  phase === PhaseType.InvoiceRefund ||
  phase === PhaseType.InvoiceReservationFeeRefund

const Wrapper = styled(FlexRow)`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gutterBig} ${theme.spacing.gutterBig}
      ${theme.spacing.gu(3)}rem ${theme.spacing.gutterBig};
  `}
`
