import { ResourceType } from '~generated-types'
import { translate } from '@/modules/Language'

import { SalesForPrintResourceReservation } from '../../../../../types'

export const formatResourceName = (
  resource: SalesForPrintResourceReservation['resource'],
  resourceQuantity: number,
  language: string
) =>
  resource
    ? resource.type === ResourceType.Equipment
      ? `${resourceQuantity > 1 ? `${resourceQuantity} × ` : ''}${
          resource.name
        }`
      : resource.name
    : translate('SalesDetails:PrintSale.programReservation', language)
