import styled, { css } from 'styled-components'

import { FlexRow } from '@/components/Layout'
import { ServicePurchaseFragment } from '~generated-types'

import ParticipantTotalPrice from '../../ParticipantRow/common/ParticipantTotalPrice'
import SimpleProduct from './SimpleProduct'

type Props = {
  actionsDisabled: boolean
  handleRemovePurchase: (purchaseId: string) => Promise<any>
  isMassUpdate?: boolean
  service: ServicePurchaseFragment
}

const ParticipantPurchaseService = ({
  actionsDisabled,
  handleRemovePurchase,
  isMassUpdate,
  service: { purchaseProduct },
}: Props) => {
  if (!purchaseProduct) {
    return null
  }

  return (
    <>
      <FlexRow style={{ height: '100%', minHeight: '35px' }}>
        <div style={{ minWidth: '15.5rem' }} />

        <div style={{ width: '15.5rem' }} />

        <SimpleProduct
          disabled={actionsDisabled || !!isMassUpdate}
          handleRemovePurchase={handleRemovePurchase}
          purchase={purchaseProduct}
        />

        <ParticipantTotalPrice
          priceTotal={
            purchaseProduct.totalPrice || {
              __typename: 'PriceElements',
              amount: 0,
              amountVatIncluded: 0,
              vatAmount: 0,
            }
          }
        />

        <Placeholder />
      </FlexRow>
    </>
  )
}

export default ParticipantPurchaseService

////

const Placeholder = styled.span`
  ${({ theme }) => css`
    width: calc(30px * 2 + ${theme.spacing.gu(2)}rem);
  `}
`
