import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  DimensionsByTargetQuery as QueryData,
  DimensionsByTargetQueryVariables as QueryVariables,
} from '~generated-types'
import { Dimension } from '../types'
import dimensionFragments from '../fragments'
import { DimensionTarget } from '~generated-types'
import { generateCompareFn } from '@/utils/arrays'

const QUERY = gql`
  ${dimensionFragments.dimension}

  query DimensionsByTarget($target: DimensionTarget) {
    registry {
      dimensionsByTarget(target: $target) {
        ...Dimension
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
  target: DimensionTarget | null
}

export default function useCategorySetsByTarget({
  forceRefetch,
  target,
}: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: forceRefetch ? 'cache-and-network' : 'cache-first',
      variables: { target },
    }
  )

  const dimensions: Dimension[] = useMemo(
    () =>
      data
        ? [...data.registry.dimensionsByTarget]
            .sort(generateCompareFn('sortOrder'))
            .map((x) => ({ ...x }))
        : [],
    [data]
  )

  dimensions.forEach((dimension) => {
    dimension.labels = [...dimension.labels].sort(generateCompareFn('name'))
  })

  return {
    dimensions,
    error,
    loading,
    refetch,
  }
}
