import salesAssignee from './salesAssignee'
import salesForPrintAccommodation from './salesForPrintAccommodation'
import salesForPrintMeal from './salesForPrintMeal'
import salesForPrintParticipant from './salesForPrintParticipant'
import salesForPrintResourceReservation from './salesForPrintResourceReservation'
import salesForPrintTask from './salesForPrintTask'
import salesLifecycle from './salesLifecycle'
import salesVst from './salesVst'

export default Object.freeze({
  salesAssignee,
  salesForPrintAccommodation,
  salesForPrintMeal,
  salesForPrintParticipant,
  salesForPrintResourceReservation,
  salesForPrintTask,
  salesLifecycle,
  salesVst,
})
