import { Link } from 'react-router-dom'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { SalesTypeChip } from '@/modules/Sales'
import { T } from '@/modules/Language'

import { ListingSectionTitle, ListingTable, TD } from '../../../common'
import { parseCustomerName, taskCompareFn } from '../utils'
import { TaskDue, TaskInfo, TaskPurchases } from '../Cells'
import { ListingTask } from '../useTaskList'
import { PrintSize } from '../../../types'

type Props = {
  label: ReactNode
  printSize: PrintSize
  salesTasks: ListingTask[]
}

export const TasksSection = ({ label, printSize, salesTasks }: Props) => (
  <div>
    <ListingSectionTitle>{label}</ListingSectionTitle>

    <ListingTable printSize={printSize}>
      <TableHead>
        <tr>
          <th>
            <T>Listings:CalendarResourceListing.field.task</T>
          </th>
          <th>
            <T>Listings:CalendarResourceListing.field.time</T>
          </th>
          <th style={{ width: '30%' }}>
            <T>Listings:CalendarResourceListing.field.product</T>
          </th>
          <th style={{ width: '15%' }}>
            <T>Listings:CalendarResourceListing.field.description</T>
          </th>
          <th>
            <T>Listings:CalendarResourceListing.field.seller</T>
          </th>
          <th>
            <T>Listings:CalendarResourceListing.field.customer</T>
          </th>
          <th colSpan={2}>
            <T>Listings:CalendarResourceListing.field.sales</T>
          </th>
        </tr>
      </TableHead>
      <tbody>
        {!salesTasks.length ? (
          <tr>
            <TD colSpan={12} style={{ textAlign: 'center' }}>
              – <T>Listings:CalendarResourceListing.emptyTask</T> –
            </TD>
          </tr>
        ) : (
          [...salesTasks].sort(taskCompareFn).map((task) => {
            const { sales, description, id } = task
            return (
              <tr key={`${id}`}>
                <TD>
                  <TaskInfo task={task} />
                </TD>
                <TD>
                  <TaskDue task={task} showEmpty />
                </TD>
                <TD>
                  <TaskPurchases task={task} showEmpty />
                </TD>
                <TD>{description || '–'}</TD>
                <TD>{sales.seller?.shortName || '–'}</TD>
                <TD>{parseCustomerName(sales)}</TD>
                <TD>
                  <StyledLink to={`/sales/details/${sales.id}`}>
                    {sales.orderNumber && `#${sales.orderNumber} `}
                    {sales.name && sales.orderNumber && '– '}
                    {sales.name}
                  </StyledLink>
                </TD>
                <TD>
                  <SalesTypeChip
                    facet={sales.facet}
                    format="short"
                    size="xs"
                    type={sales.type}
                  />
                </TD>
              </tr>
            )
          })
        )}
      </tbody>
    </ListingTable>
  </div>
)

////////////

const TableHead = styled.thead`
  z-index: 1;
`

const StyledLink = styled(Link)`
  @media print {
    color: inherit;
    text-decoration: none;
  }
`
