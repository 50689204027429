import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components/macro'

import { DropdownButton, DropdownOption } from '@/components/ExtraButtons'
import { IconButton } from '@/modules/Products/components'
import { T } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'
import { useWillbaConfigContext } from '@/config'

type Props = {
  id: string
  noInternalCost: boolean
  noInternalPurchase: boolean
  noMarginalTax: boolean
}

export const ActionsDropdown = ({
  id,
  noInternalCost,
  noInternalPurchase,
  noMarginalTax,
}: Props) => {
  const { palette } = useTheme()
  const { isCatalogInternalPriceEnabled, isMarginalTaxEnabled } =
    useWillbaConfigContext()
  const {
    addProductItemRule,
    deleteProductItemWithConfirm,
    updateProductItem,
  } = useCatalogProductManagerContext()

  const addInternalCostOption = {
    label: (
      <>
        <Icon icon="plus" size="sm" />
        <T>Products:ProductManager.action.addInternalCost</T>
      </>
    ),
    onClick: () => updateProductItem({ id, internalCost: 0 }),
  }

  const addInternalPurchaseOption = {
    label: (
      <>
        <Icon icon="plus" size="sm" />
        <T>Products:ProductManager.action.addInternalPurchase</T>
      </>
    ),
    onClick: () => updateProductItem({ id, internalPurchase: 0 }),
  }

  const addMarginalTaxOption = {
    label: (
      <>
        <Icon icon="plus" size="sm" />
        <T>Products:ProductManager.action.addMarginalTax</T>
      </>
    ),
    onClick: () => updateProductItem({ id, marginalTax: { active: true } }),
  }

  const addRuleOption = {
    label: (
      <>
        <Icon icon="plus" size="sm" />
        <T>Products:ProductManager.action.addProductItemRule</T>
      </>
    ),
    onClick: () => addProductItemRule(id),
  }

  const deleteItemOption = {
    label: (
      <>
        <Icon icon="trash" size="xs" />
        <T>Products:ProductManager.action.deleteProductItem</T>
      </>
    ),
    onClick: () => deleteProductItemWithConfirm(id),
  }

  const getOptions = () => {
    let options: DropdownOption[] = [addRuleOption]

    if (isCatalogInternalPriceEnabled && noInternalCost) {
      options = [...options, addInternalCostOption]
    }
    if (isCatalogInternalPriceEnabled && noInternalPurchase) {
      options = [...options, addInternalPurchaseOption]
    }
    if (
      (isCatalogInternalPriceEnabled || !noInternalPurchase) &&
      isMarginalTaxEnabled &&
      noMarginalTax
    ) {
      options = [...options, addMarginalTaxOption]
    }

    return [...options, deleteItemOption]
  }

  return (
    <DropdownButton
      options={getOptions()}
      dropdownPlacement="bottom-end"
      renderCustomButton={({ onClick }) => (
        <IconButton onClick={onClick}>
          <FontAwesomeIcon
            color={palette.text.light}
            icon="ellipsis-vertical"
            size="lg"
          />
        </IconButton>
      )}
      zIndex={10006}
    />
  )
}

/////////

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 12px;
    margin-right: 10px;
  }
`
