import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { OrderAction as OA } from '~generated-types'
import { OrderType as Order } from '@/modules/Order/types'

import {
  ActionsButton,
  Customer,
  NameInput,
  OrderStateChip,
  OrderViewToggle,
  Price,
} from './components'

type Props = {
  order: Order
}

export const OrderHeader = ({
  order: {
    customer,
    id,
    orderNumber,
    name,
    lifecycle: { state, validatedActions },
    paymentInfo: {
      paid: paidAmount,
      payableAmount,
      totalPrice: { amountVatIncluded: totalAmount },
    },
  },
}: Props) => {
  const readOnly = !validatedActions.find((a) => a.action === OA.Update)?.valid

  return (
    <Wrapper alignItems="center" flex={1}>
      <OrderStateChip fixedWidth state={state} />

      <OrderNumber className="order-number">#{orderNumber}</OrderNumber>

      <NameInput name={name} orderId={id} readOnly={readOnly} />

      <Customer customer={customer?.details} />

      <Spacer />

      <OrderViewToggle />

      <Price price={payableAmount} type="LEFT_TO_PAY" />

      <Price price={paidAmount} type="RECEIVED" />

      <Price price={totalAmount} type="TOTAL" />

      <ActionsButton orderId={id} />
    </Wrapper>
  )
}

/////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const OrderNumber = styled.span`
  font-weight: 500;
  text-align: center;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin-left: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(8)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`
