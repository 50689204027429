import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { Palette } from '@/theme'
import { T } from '@/modules/Language'

export const formatDateRange = (from: string | null, to: string | null) => {
  if (!from && !to) {
    return null
  }

  const isSameDate = moment(from).isSame(moment(to), 'day')
  const isSameYear = moment(from).isSame(moment(to), 'year')

  const fromString = from
    ? moment(from).format(isSameYear ? 'DD.MM' : 'DD.MM.YY')
    : ''
  const toString = to ? moment(to).format('DD.MM.YY') : ''

  return isSameDate ? fromString : `${fromString} – ${toString}`
}

export const getPublishStatus = (
  from: string | null,
  to: string | null,
  palette: Palette
) => {
  if (!from || !to) {
    return null
  }

  const isScheduled = moment(from).isAfter(moment(), 'day')
  const isExpired = moment(to).isBefore(moment(), 'day')
  const isPublished =
    moment(from).isSameOrBefore(moment(), 'day') &&
    moment(to).isSameOrAfter(moment(), 'day')

  if (isExpired) {
    return {
      content: <T>Publish:Rooms.status.expired</T>,
      icon: <StatusIcon color={palette.smoke.extraDark} icon="ban" />,
    }
  }

  if (isPublished) {
    return {
      content: <T>Publish:Rooms.status.published</T>,
      icon: (
        <StatusIcon
          color={palette.success.main}
          icon={['far', 'circle-check']}
        />
      ),
    }
  }

  if (isScheduled) {
    return {
      content: <T>Publish:Rooms.status.scheduled</T>,
      icon: <StatusIcon color={palette.warning.dark} icon={['far', 'clock']} />,
    }
  }

  return null
}

/////

const StatusIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
