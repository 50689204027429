import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'

import { Tooltip } from '@/components/Tooltip'

import { Cell } from './Cell'
import { Section } from './Section'

type Props = {
  content: ReactNode | string
  flex?: number
  icon?: IconProp
  sign?: string
  title: ReactNode
  width?: number
}

export const SectionWithTooltip = ({
  content,
  flex,
  icon,
  sign,
  title,
  width,
}: Props) => (
  <Cell flex={flex} width={width}>
    <Tooltip
      content={title}
      delay={300}
      placement="top-start"
      trigger={(triggerProps) => (
        <span {...triggerProps}>
          <Section icon={icon} sign={sign}>
            {content}
          </Section>
        </span>
      )}
    />
  </Cell>
)
