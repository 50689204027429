import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'

interface Props {
  color?: string
}

export const CountIcons = ({ color }: Props) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <IconWrapper>
        <FontAwesomeIcon
          color={color || theme.palette.text.lighter}
          icon="door-open"
        />
      </IconWrapper>
      <IconWrapper>
        <FontAwesomeIcon
          color={color || theme.palette.text.lighter}
          icon="user"
        />
      </IconWrapper>
    </Wrapper>
  )
}

////////////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const IconWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  align-items: flex-end;
  justify-content: center;
`
