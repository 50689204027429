import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
}

export const Sheet = ({ children }: Props) => (
  <Wrapper>
    <SheetWrapper>{children}</SheetWrapper>
  </Wrapper>
)

//////

const SheetWrapper = styled.div`
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  box-shadow: 0 3px 10px 0px rgba(0, 0, 0, 0.1);
  height: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    color: ${theme.palette.text.light};
  `}

  & > div {
    height: 100%;
  }

  & > div > div {
    height: 100%;
  }
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.primary.extraLighter};
    height: calc(100% - ${theme.spacing.gu(10)}rem);
  `}
`
