import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { RefObject } from 'react'

import { translate, useLanguageContext } from '@/modules/Language'
import { WeekPickerControl, WeekSelection } from '@/components/TimeControls'
import { FlexRow } from '@/components/Layout'
import { MomentDateRange } from '@/modules/Reservations/components/RoomLayout'

type Props = {
  targetDateRange: MomentDateRange
  setTargetDateRange: (...args: Array<any>) => any
}

export const DateRangeSelector = ({
  targetDateRange,
  setTargetDateRange,
}: Props) => {
  const { language } = useLanguageContext()

  const onSetDateRange = (value: WeekSelection | null | undefined) =>
    setTargetDateRange(
      value
        ? {
            from: moment()
              .year(value.year)
              .isoWeek(value.week)
              .startOf('isoWeek'),
            to: moment()
              .year(value.year)
              .isoWeek(value.week)
              .add(2, 'w')
              .endOf('isoWeek'),
          }
        : targetDateRange
    )

  const onSetNextDateRange = () =>
    setTargetDateRange({
      from: targetDateRange.from.clone().add(1, 'w'),
      to: targetDateRange.to.clone().add(1, 'w'),
    })

  const onSetPreviousDateRange = () =>
    setTargetDateRange({
      from: targetDateRange.from.clone().subtract(1, 'w'),
      to: targetDateRange.to.clone().subtract(1, 'w'),
    })

  const transformToMomentDate = (value: WeekSelection) =>
    moment().year(value.year).isoWeek(value.week)

  const getWeekLabel = (value: WeekSelection) => `
    ${translate('common:time.week', language)}
    ${transformToMomentDate(value).isoWeek()},
    ${transformToMomentDate(value).add(1, 'w').isoWeek()},
    ${transformToMomentDate(value).add(2, 'w').isoWeek()}
  `

  const getDateLabel = (value: WeekSelection) => `
    ${transformToMomentDate(value).isoWeekday(1).format('dd, D.M.YY')} –
    ${transformToMomentDate(value)
      .add(2, 'w')
      .isoWeekday(7)
      .format('dd, D.M.YY')}`

  return (
    <FlexRow alignItems="center">
      <ArrowButton onClick={onSetPreviousDateRange}>
        <FontAwesomeIcon icon="angle-left" />
      </ArrowButton>
      <WeekPickerControl
        numberOfMonths={2}
        numberOfWeeks={3}
        render={({ openModal, ref, week }) => (
          <WeekButton
            onClick={openModal}
            ref={ref as RefObject<HTMLButtonElement>}
          >
            <Label>{week ? getWeekLabel(week) : '–'}</Label>
            <DateLabel>{week ? getDateLabel(week) : '–'}</DateLabel>
          </WeekButton>
        )}
        setValue={(value: WeekSelection | null | undefined) =>
          onSetDateRange(value)
        }
        value={{
          week: targetDateRange.from.isoWeek(),
          year: targetDateRange.from.isoWeekYear(),
        }}
      />
      <ArrowButton onClick={onSetNextDateRange}>
        <FontAwesomeIcon icon="angle-right" />
      </ArrowButton>
    </FlexRow>
  )
}

////////////

const Label = styled.span`
  margin-bottom: 0.25rem;
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const DateLabel = styled.span`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    width: ${theme.spacing.gu(26)}rem;
  `}
`

const WeekButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background: transparent;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  padding: 0;
  outline: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBase2};
    height: ${theme.spacing.gu(7)}rem;
    margin: 0 ${theme.spacing.gutterSmall};
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const ArrowButton = styled(WeekButton)`
  margin: 0;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    padding: 0 ${theme.spacing.gutter};
  `}
`
