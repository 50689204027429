import { createContext, ReactNode, useContext, useRef } from 'react'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { IconName } from '@fortawesome/fontawesome-common-types'

type Props = {
  children: ReactNode
}

type ContextType = {
  checkIconValidity: (iconName: string | null) => IconName | null
}

const GlobalIconsContext = createContext<ContextType>({
  checkIconValidity: () => null,
})

export const GlobalIconsContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null | undefined>(null)

  const globalSolidIcons = [
    ...new Set(Object.values(fas).map(({ iconName }) => iconName)),
  ]

  const checkIconValidity = (iconName: string | null) => {
    if (!iconName) {
      return null
    }

    let validatedIconName = null

    for (let i = 0; i < globalSolidIcons.length; i++) {
      if (globalSolidIcons[i] === iconName) {
        validatedIconName = globalSolidIcons[i]
        break
      }
    }

    return validatedIconName
  }

  contextValueRef.current = {
    checkIconValidity,
  }

  return (
    <GlobalIconsContext.Provider value={contextValueRef.current}>
      {children}
    </GlobalIconsContext.Provider>
  )
}

export const useGlobalIconsContext = () => useContext(GlobalIconsContext)
