import { Fragment, ReactNode } from 'react'

import { DataCell, IndentedDataRow, ScrollTableProps } from './ScrollTable'
import { CellStatus } from '../../AvailabilityCalendar.types'

type Props = ScrollTableProps & {
  children?: ReactNode
  isLastCategory: boolean
  isLastRoomType: boolean
  isLastRow: boolean
}

export const RoomSectionPlaceholder = ({
  children,
  isLastCategory,
  isLastRoomType,
  isLastRow,
  ...tableProps
}: Props) => (
  <IndentedDataRow
    indents={[!isLastCategory, !isLastRoomType, true]}
    isLastRow={isLastRow}
    renderContent={() =>
      tableProps.columnGroups.map(({ items, key: weekKey }, weekIdx) => (
        <Fragment key={`data-${weekKey}`}>
          {items.map(({ key: dateKey }, dateIdx) => {
            const isLastCell =
              weekIdx === tableProps.columnGroups.length - 1 &&
              dateIdx === items.length - 1

            return (
              <DataCell
                depth={3}
                isLast={isLastCell}
                key={`data-${dateKey}-placeholder`}
                status={CellStatus.UNAVAILABLE}
                {...tableProps}
              />
            )
          })}
        </Fragment>
      ))
    }
    renderHeader={() => children || null}
    {...tableProps}
  />
)
