import styled, { css } from 'styled-components/macro'

import {
  SpreadsheetTable as Table,
  SpreadsheetTBody as TBody,
  SpreadsheetTD as TD,
  SpreadsheetTH as TH,
  SpreadsheetTHead as THead,
  SpreadsheetTR as TR,
} from '@/components/Spreadsheet'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

import { CreateInputKey, Language, Participant } from '../ExcelImport.types'

type Props = {
  fileSchema: (CreateInputKey | null)[]
  languages: Language[]
  participants: Participant[]
}

export const ParticipantsTable = ({
  fileSchema,
  languages,
  participants,
}: Props) => {
  const noAddressFileSchema = fileSchema.filter(
    (i) =>
      i !== 'address1' &&
      i !== 'address2' &&
      i !== 'city' &&
      i !== 'postcode' &&
      i !== 'country' &&
      i !== null
  )

  const isAdressExist = fileSchema.some(
    (i) =>
      i === 'address1' ||
      i === 'address2' ||
      i === 'city' ||
      i === 'postcode' ||
      i === 'country'
  )

  const renderTableBodyCell = (
    columnName: CreateInputKey | null,
    participant: Participant
  ) => {
    switch (columnName) {
      case 'birthday':
        return (
          <TD key={columnName}>
            {participant.birthday
              ? `${participant.birthday.date}.${participant.birthday.month}.${participant.birthday.year}`
              : ''}
          </TD>
        )
      case 'education':
        return (
          <TD key={columnName}>
            {participant[columnName] ? (
              <T>{`enums:education.${participant[columnName]}`}</T>
            ) : (
              ''
            )}
          </TD>
        )
      case 'lifeStage':
        return (
          <TD key={columnName}>
            {participant[columnName] ? (
              <T>{`enums:lifeStage.${participant[columnName]}`}</T>
            ) : (
              ''
            )}
          </TD>
        )
      case 'language':
        const participantLanguage = languages.find(
          ({ code }) => code === participant[columnName]
        )
        return <TD key={columnName}>{participantLanguage?.name}</TD>
      default:
        // @ts-ignore
        return <TD key={columnName}>{participant[columnName]}</TD>
    }
  }

  return (
    <StyledTable>
      <THead>
        <TR>
          {noAddressFileSchema.map((columnName) => (
            <TH key={columnName}>
              <T>{`ParticipantsList:Import.previewHeader.${columnName}`}</T>
            </TH>
          ))}

          {isAdressExist && (
            <TH>
              <T>ParticipantsList:PersonalFields.address</T>
            </TH>
          )}
        </TR>
      </THead>

      <TBody>
        {participants.map((p) => (
          <TR key={JSON.stringify(p)}>
            {noAddressFileSchema.map((c) => renderTableBodyCell(c, p))}

            {isAdressExist && (
              <TD>
                <FlexColumn>
                  <span>{p.postalAddress?.address1}</span>
                  <span>{p.postalAddress?.address2}</span>
                  <span>{p.postalAddress?.city}</span>
                  <span>{p.postalAddress?.country}</span>
                  <span>{p.postalAddress?.postcode}</span>
                </FlexColumn>
              </TD>
            )}
          </TR>
        ))}
      </TBody>
    </StyledTable>
  )
}

////////

const StyledTable = styled(Table)`
  max-width: unset;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`
