import styled, { css } from 'styled-components/macro'

import { Label, Toggle } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

export const PriceToggle = () => {
  const { salesPrintShowPrice, setSalesPrintShowPrice } =
    useSalesDetailsContext()

  const handleSetShowPrice = () => {
    setSalesPrintShowPrice(!salesPrintShowPrice)
    localStorage.setItem(
      'salesPrintShowPrice',
      JSON.stringify(!salesPrintShowPrice)
    )
  }

  return (
    <Wrapper flex={1} alignItems="center" justifyContent="flex-end">
      <ToggleLabel>
        <T>SalesDetails:PrintSale.showPrices</T>
      </ToggleLabel>

      <Toggle
        checked={salesPrintShowPrice}
        hideLabels
        noMargin
        onChange={handleSetShowPrice}
      />
    </Wrapper>
  )
}

/////

const ToggleLabel = styled(Label)`
  margin-bottom: 0;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem 0
      ${theme.spacing.gu(3)}rem;
  `}
`
