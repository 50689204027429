import { gql } from '@apollo/client'

import { attachmentFragments } from '@/modules/Attachments'

export default gql`
  ${attachmentFragments.attachment}

  mutation RenameAttachment($input: FileRenameInput!) {
    fileUpdate(input: $input) {
      ...Attachment
    }
  }
`
