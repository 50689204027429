import React, {
  ChangeEvent,
  CSSProperties,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { useTheme } from '@/theme'

import EndAdornmentWrapper from '../elements/EndAdornmentWrapper'
import LiveUpdateIndicator from '../elements/LiveUpdateIndicator'
import SelectEl from '../elements/Select'
import TD from '../elements/TD'

export type SelectOption = {
  disabled: boolean
  label: string
  value: string
}

type Props = {
  colSpan?: number
  onSubmit?: (value: string) => Promise<void>
  options: SelectOption[]
  rowSpan?: number
  style?: CSSProperties
  value?: string | null
  updated?: boolean
  editorColor?: string
  disabled?: boolean
}

const Select = ({
  colSpan,
  onSubmit,
  options,
  rowSpan,
  value,
  updated,
  editorColor,
  disabled,
  ...inputProps
}: Props) => {
  const theme = useTheme()

  const [hasError, setHasError] = useState<boolean>(false)
  const [innerValue, setInnerValue] = useState<string>(value || '')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const inputEl = useRef<any>(null)

  useEffect(() => {
    setInnerValue(value || '')
  }, [value])

  const handleFocus = () => {
    const input = inputEl && inputEl.current

    if (input !== null) {
      input.focus()
    }
  }

  const handleOnKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      inputEl && inputEl.current && inputEl.current.blur()
    }
  }

  const handleOnSubmit = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value
    const hasChanged = (!!value || !!newValue) && value !== newValue

    if (hasChanged && onSubmit) {
      setInnerValue(newValue)
      setHasError(false)
      setIsSubmitting(true)

      onSubmit(newValue)
        .catch(() => setHasError(true))
        .finally(() => {
          setIsSubmitting(false)
          handleFocus()
        })
    }
  }

  const renderBusyIndicator = () =>
    isSubmitting && (
      <div>
        <StyledLoader
          color={theme.palette.text.lighter}
          type={'spin'}
          height={14}
          width={14}
        />
      </div>
    )

  const isReadOnly = !onSubmit || isSubmitting || disabled

  return (
    <TD colSpan={colSpan} noPadding noWrap rowSpan={rowSpan}>
      <SelectEl
        {...inputProps}
        borderless
        disabled={isReadOnly}
        onChange={handleOnSubmit}
        onKeyDown={handleOnKeyDown}
        ref={inputEl}
        tabIndex={isReadOnly ? -1 : 0}
        readOnly={isReadOnly}
        state={hasError ? 'danger' : ''}
        value={innerValue}
      >
        {options.map(({ disabled, label, value }) => (
          <option
            key={`option-${label}-${value}`}
            disabled={disabled}
            value={value}
          >
            {label}
          </option>
        ))}
      </SelectEl>
      <EndAdornmentWrapper>
        {isSubmitting ? (
          renderBusyIndicator()
        ) : (
          <FontAwesomeIcon icon="caret-down" />
        )}
      </EndAdornmentWrapper>
      <LiveUpdateIndicator updated={updated} editorColor={editorColor} />
    </TD>
  )
}

export default Select

////////////

const StyledLoader = styled(ReactLoading)`
  position: relative;

  & > svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`
