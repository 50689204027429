import { gql } from '@apollo/client'

export default gql`
  query VoucherProviders {
    registry {
      voucherProviders {
        id
        name
      }
    }
  }
`
