import styled, { css } from 'styled-components/macro'
import React from 'react'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { IconButton } from '@/components/ExtraButtons'
import { Select } from '@/components/FormControls'
import { useTheme } from '@/theme'
import { ListFooter as Wrapper } from '@/components/List'

type Props = {
  columnCount: number
  compact?: boolean
  currentPage: number
  numberOfPages: number
  numberOfResults: number
  onSetPage: (page: number) => void
  onSetPageSize: (size: number) => void
  pageSize: number
  pageSizes: number[]
}

const ListFooter = ({
  columnCount,
  compact,
  currentPage,
  numberOfPages,
  numberOfResults,
  onSetPage,
  onSetPageSize,
  pageSize,
  pageSizes,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  return (
    <Wrapper colSpan={columnCount}>
      <FlexRow
        alignItems="center"
        justifyContent={compact ? 'center' : 'space-between'}
        style={{ marginTop: `${theme.spacing.gu(1)}rem` }}
      >
        {!compact && (
          <FooterLabel>
            <T>ElasticFilterSearchList:pageSize</T>:{' '}
            <Select
              inline
              noMargin
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                onSetPageSize(
                  e?.target?.value ? parseInt(e?.target?.value, 10) : pageSize
                )
              }
              style={{ height: `${theme.spacing.gu(4)}rem` }}
              value={pageSize}
            >
              {pageSizes.map((size) => (
                <option key={`page-size-option-${size}`} value={size}>
                  {size}
                </option>
              ))}
            </Select>
          </FooterLabel>
        )}

        {!compact && (
          <FooterLabel>
            <T>ElasticFilterSearchList:total</T>{' '}
            {translate('ElasticFilterSearchList:totalPostfix', language, {
              count: numberOfResults,
            })}
          </FooterLabel>
        )}

        <FlexRow alignItems="center">
          <IconButton
            color="transparent"
            disabled={currentPage === 0}
            icon="angles-left"
            onClick={() => onSetPage(0)}
          />
          <IconButton
            color="transparent"
            disabled={currentPage === 0}
            icon="angle-left"
            onClick={() => onSetPage(Math.max(currentPage - 1, 0))}
          />
          <FooterLabel>
            <T>ElasticFilterSearchList:page</T>: {currentPage + 1} /{' '}
            {numberOfPages}
          </FooterLabel>
          <IconButton
            color="transparent"
            disabled={currentPage === numberOfPages - 1}
            icon="angle-right"
            onClick={() =>
              onSetPage(Math.min(numberOfPages - 1, currentPage + 1))
            }
          />
          <IconButton
            color="transparent"
            disabled={currentPage === numberOfPages - 1}
            icon="angles-right"
            onClick={() => onSetPage(numberOfPages - 1)}
          />
        </FlexRow>
      </FlexRow>
    </Wrapper>
  )
}

export default ListFooter

////////////

const FooterLabel = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
