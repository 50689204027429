import { MouseEvent, useEffect, useState } from 'react'
import { get } from 'dot-prop'
import moment from 'moment'
import styled from 'styled-components/macro'

import {
  CalendarReservation,
  ResourceReservation,
} from '@/modules/Reservations/ResourceReservation'
import { ModalContainer } from '@/components/Modal'
import { ReservationManager } from '@/modules/Reservations'

import { ReservationBlock } from './ReservationBlock'
import { useReservationsGridState } from '../../../../ReservationsGridState'

type Props = {
  isLocked: boolean
  isDragSource: boolean
  isDragTarget: boolean
  isShadow: boolean
  reservation: CalendarReservation | ResourceReservation
  reservationContextId: string | null
  preview?: boolean
  cancelPreview: (...args: any[]) => void
}

export default function CalendarItem({
  isDragSource,
  isDragTarget,
  isLocked,
  isShadow,
  reservation,
  reservationContextId,
  preview,
  cancelPreview,
}: Props) {
  const {
    calendarDensity,
    calendarVariables: { RESERVATION_TIME_STEP },
    setReservationsArr,
    updateCalendarWidthForModal,
  } = useReservationsGridState()
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  useEffect(() => {
    setModalOpen((current) => {
      const value = !current ? preview ?? current : current

      if (value) {
        updateCalendarWidthForModal(value)
      }

      return value
    })
  }, [preview])

  const isSameContext = reservationContextId === get(reservation, 'sales.id')

  // Hide reservation content for 15 minute reservations at "S" density
  const hideContent =
    calendarDensity === 'S' &&
    moment(reservation.end).diff(reservation.start, 'minutes') ===
      RESERVATION_TIME_STEP

  const handleCloseModal = (e: MouseEvent) => {
    e.stopPropagation()
    setModalOpen(false)
    cancelPreview()
    updateCalendarWidthForModal(false)
  }

  const handleOpenModal = (e: MouseEvent) => {
    if (!isShadow) {
      e.stopPropagation()
      setModalOpen(true)
      updateCalendarWidthForModal(true)
    }
  }

  return (
    <ModalContainer
      isOpen={modalOpen}
      referenceElement={({ ref }) => (
        <ReservationBlockWrapper ref={ref}>
          <ReservationBlock
            color={reservation.sales.facet.color || ''}
            description={reservation.description || ''}
            externalOwner={!isSameContext ? reservation.sales : null}
            groupNumber={reservation.group?.number || null}
            hideContent={hideContent}
            isDragging={isDragTarget}
            isFocused={modalOpen}
            isLocked={isLocked && !isSameContext}
            isPooled={!!reservation.resource?.isPooled}
            isShadow={isShadow}
            isTranslucent={isDragSource}
            onClick={handleOpenModal}
            quantity={reservation.resourceQuantity}
            variant={isSameContext ? 'filled' : 'outlined'}
          />
        </ReservationBlockWrapper>
      )}
      onClose={handleCloseModal}
      modal={
        <ReservationManager
          context={reservationContextId ? 'BOTTOM_SHEET' : 'GLOBAL'}
          contextId={reservationContextId}
          group={reservation.group}
          onClose={handleCloseModal}
          updateReservations={setReservationsArr}
          readOnly={reservation.sales.locked}
          reservation={reservation}
        />
      }
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}

////////

const ReservationBlockWrapper = styled.div`
  height: 100%;
  position: relative;
`
