import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { Accommodation, BasicDetails, Guests, Program } from './components'
import { SalesPrintConfigKey as Key, SalesForPrint } from '../../types'
import { checkSalesPrintConfigVisibility } from '../../utils'

type Props = {
  salesForPrint: SalesForPrint
}

export const PrintableContent = ({
  salesForPrint: { sales, scheduledMealsBySales },
}: Props) => {
  const {
    accommodation,
    name,
    orderNumber,
    participantConnection,
    resourceReservations,
    tasks,
  } = sales
  const { salesPrintConfig } = useSalesDetailsContext()

  const checkVisibility = (path: string) =>
    checkSalesPrintConfigVisibility(salesPrintConfig, path)

  const showBasicDetails = checkVisibility(Key.BasicDetails)
  const showParticipants =
    checkVisibility(Key.Participants) && !!participantConnection
  const showAccommodation =
    checkVisibility(Key.Accommodation) && !!accommodation
  const showProgram = checkVisibility(Key.Program)

  return (
    <Wrapper>
      <FlexColumn>
        <Title>
          <T>SalesDetails:PrintSale.title</T>
        </Title>

        <Subtitle>
          #{orderNumber} {name}
        </Subtitle>

        {showBasicDetails && <BasicDetails sales={sales} />}

        {showParticipants && (
          <Guests participants={participantConnection.nodes} />
        )}

        {showAccommodation && (
          <Accommodation
            roomReservations={accommodation.roomReservations}
            roomTypeReservations={accommodation.roomTypeReservations}
          />
        )}

        {showProgram && (
          <Program
            meals={scheduledMealsBySales ?? []}
            reservations={resourceReservations ?? []}
            tasks={tasks ?? []}
          />
        )}
      </FlexColumn>
    </Wrapper>
  )
}

////////////

const Subtitle = styled.span`
  font-size: 1.375rem;
  font-weight: 600;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
  `}
`

const Title = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  display: none;

  @media print {
    display: block;
  }
`
