import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { EditButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'

import { Section } from '../../../common'
import { usePublishContext } from '../../../../PublishState'

type Props = {
  id: string
}

export const RemovePublicRoomButton = ({ id }: Props) => {
  const { removePublicRoom, readOnly } = usePublishContext()

  const [processing, setProcessing] = useState<boolean>(false)

  const handleRemovePublicRoom = () => {
    setProcessing(true)
    removePublicRoom({ id }).finally(() => setProcessing(false))
  }

  return (
    <Wrapper noPadding noRightMargin>
      <Tooltip
        content={<T>Publish:Rooms.removePublicRoom</T>}
        delay={200}
        placement="top"
        trigger={(triggerProps) => (
          <div {...triggerProps}>
            <IconButton
              disabled={readOnly || processing}
              noIcon
              onClick={handleRemovePublicRoom}
            >
              <Icon icon="trash" size="sm" />
            </IconButton>
          </div>
        )}
      />
    </Wrapper>
  )
}

////////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.danger.main};
  `}
`

const IconButton = styled(EditButton)`
  flex: unset;
  justify-content: center;
  margin: 0;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}

  &:disabled {
    opacity: 0.5;
  }
`

const Wrapper = styled(Section)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}
`
