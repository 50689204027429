import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import {
  FetchedParticipant,
  Participant,
  ParticipantSkeletonData,
} from '@/modules/ParticipantsList'
import {
  SaleAccommodationQuery,
  SalesParticipantFullFragment,
} from '~generated-types'
import { FlexColumn } from '@/components/Layout'

import RoomViewContent from './components/RoomViewContent'
import RoomViewHeader from './components/RoomViewHeader'

type Props = {
  room: SaleAccommodationQuery['suitableRooms'][0]['roomReservation']
  participants: (FetchedParticipant | ParticipantSkeletonData)[]
  isAllExpanded: boolean
  readOnly?: boolean
  updateExpandedCounter: (value: number) => void
  handleDeleteRoom: (id: string) => Promise<any>
}

const RoomsList = ({
  room,
  participants,
  isAllExpanded,
  readOnly,
  updateExpandedCounter,
  handleDeleteRoom,
}: Props) => {
  const [isExpanded, setExpanded] = useState<boolean>(true)

  const handleSetExpanded = (value: any) => {
    setExpanded(value)
    updateExpandedCounter(value ? 1 : -1)
  }

  useEffect(() => {
    isAllExpanded !== isExpanded && setExpanded(isAllExpanded)
  }, [isAllExpanded])

  const roomParticipantsIds = useMemo(
    () => room.participantRooms.map(({ participant }) => participant.id),
    [room]
  )

  const uniqueIds = new Set(roomParticipantsIds)

  // @ts-ignore
  const roomParticipants: SalesParticipantFullFragment[] = useMemo(
    () =>
      participants.filter(
        (participant) =>
          roomParticipantsIds.find((id) => id === participant.id) &&
          (participant as Participant).services.find(
            (service) =>
              service.__typename === 'ServiceParticipantBed' &&
              service.participantRoom?.roomReservation?.id === room?.id
          )
      ),
    [roomParticipantsIds, participants, room.id]
  )

  const placeholdersAmount = uniqueIds.size - roomParticipants.length

  return (
    <Wrapper>
      <RoomViewHeader
        room={room}
        isExpanded={isExpanded}
        readOnly={readOnly}
        setIsExpanded={handleSetExpanded}
        handleDeleteRoom={handleDeleteRoom}
      />
      {isExpanded && (
        <RoomViewContent
          room={room}
          roomParticipants={roomParticipants}
          readOnly={readOnly}
          placeholdersAmount={placeholdersAmount}
        />
      )}
    </Wrapper>
  )
}

export default RoomsList

const Wrapper = styled(FlexColumn)`
  margin-top: -1px;

  &:first-of-type > div:first-of-type {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &:last-of-type > div:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`
