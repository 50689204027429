import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLazyQuery } from '@apollo/client'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { ModalSelector } from '@/components/Modal'
import { ParticipantsListQueries } from '@/modules/ParticipantsList'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

type Props = {
  groupId: string
  target: any
  readOnly?: boolean
  targetAccommodation: (input: any) => Promise<any>
}

export const RoomTarget = ({
  groupId,
  target,
  readOnly,
  targetAccommodation,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const [loadTargets, { data }] = useLazyQuery(
    ParticipantsListQueries.ACCOMMODATION_GROUP_TARGETS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: groupId,
      },
    }
  )

  const options = (data?.accommodationGroup?.targets || []).map(
    (target: any) => ({
      disabled: false,
      label: target.default
        ? translate('Accommodation:TargetGroup.default', language)
        : target.name
        ? target.name
        : `${translate(`Accommodation:TargetGroup.target`, language)} #${
            target.sortOrder
          }`,
      value: target.id,
    })
  )

  const handleOpenModal = (setModalOpen: (isModalOpen: boolean) => void) => {
    if (readOnly) {
      return
    }

    loadTargets()
    setModalOpen(true)
  }

  return (
    <ModalSelector
      options={options}
      title={<T>Accommodation:TargetGroup.selectTarget</T>}
      handleChange={(value: any) => targetAccommodation(value)}
      referenceElement={(ref, setModalOpen) => (
        <TargetSelectorWrapper
          ref={ref}
          disabled={readOnly}
          onClick={() => handleOpenModal(setModalOpen)}
        >
          {!target ? (
            <>
              <FontAwesomeIcon
                color={palette.primary.main}
                fixedWidth
                size="xs"
                icon="plus"
              />
              <FontWeight
                style={{
                  color: palette.smoke.dark,
                  fontStyle: 'italic',
                  paddingLeft: `${spacing.gu(0.5)}rem`,
                }}
              >
                <T>Accommodation:TargetGroup.target</T>
              </FontWeight>
            </>
          ) : (
            <Tooltip
              content={
                target.accommodationGroup.name
                  ? target.accommodationGroup.name
                  : `${translate(`Group`, language)} #${
                      target.accommodationGroup.sortOrder
                    }`
              }
              delay={300}
              placement="top"
              trigger={(triggerProps) => (
                <FlexRow
                  {...triggerProps}
                  alignItems={'center'}
                  flex={1}
                  style={{ overflow: 'hidden' }}
                >
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="user-group"
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                  <TargetName>
                    {target.default
                      ? translate('Accommodation:TargetGroup.default', language)
                      : target.name
                      ? target.name
                      : `${translate(
                          `Accommodation:TargetGroup.target`,
                          language
                        )} #${target.sortOrder}`}
                  </TargetName>
                </FlexRow>
              )}
            />
          )}
        </TargetSelectorWrapper>
      )}
    />
  )
}

const TargetSelectorWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 10rem;
  max-width: 10rem;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : theme.palette.white};
  transition: all 0.1s ease-out;
  border-radius: 8px;

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const TargetName = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 500;
  margin: 0;
  width: 100%;
  text-align: center;
`
