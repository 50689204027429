import {
  Hierarchy,
  HierarchyDimension,
  HierarchyLabel,
  SalesDimension,
} from '@/modules/Dimensions'

export const transformHierarchy = (
  hierarchy: Hierarchy,
  dimensions: SalesDimension[]
) => {
  let nestingChilds = 0
  let parentOfActiveChild = ''

  const isActive = (item: HierarchyLabel, parentKey: string) => {
    let uniquePath = ''

    const currentIndex = dimensions.findIndex(
      (d: SalesDimension) => d.selectedLabel?.id === item.label.id
    )

    dimensions.forEach((d, index) => {
      if (index <= currentIndex && d.selectedLabel) {
        uniquePath = `${uniquePath}/${d.selectedLabel?.id}`
      }
    })

    if (uniquePath === item.uniquePath) {
      parentOfActiveChild = parentKey
      return true
    } else {
      return false
    }
  }

  const isExpanded = (item: HierarchyLabel) => {
    if (parentOfActiveChild === item.uniquePath) {
      if (item.childHierarchy) {
        nestingChilds = nestingChilds + item.childHierarchy.labels.length
      }
      return true
    }
    return false
  }

  const transform = (
    item: HierarchyLabel,
    dimensionItem: HierarchyDimension,
    nestingLevel: number,
    parentKey: string
  ): any => {
    return {
      childs: item.childHierarchy
        ? // @ts-ignore
          item.childHierarchy.labels.map((childItem: HierarhcyLabel) =>
            transform(
              childItem,
              // @ts-ignore
              item.childHierarchy.dimension,
              nestingLevel + 1,
              item.uniquePath
            )
          )
        : [],
      isActive: isActive(item, parentKey),
      isExpanded: isExpanded(item),
      key: item.label.id,
      levelKey: dimensionItem.id,
      name: item.label.name,
      nestingChilds:
        parentOfActiveChild === item.uniquePath ? nestingChilds : 0,
      nestingLevel,
      uniqueKey: item.uniquePath,
    }
  }

  return hierarchy.labels.map((item: HierarchyLabel) =>
    transform(item, hierarchy.dimension, 0, '')
  )
}
