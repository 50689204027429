import { gql } from '@apollo/client'

export default gql`
  query PaymentTerminals {
    registry {
      paymentTerminals {
        id
        name
        pointOfSale {
          id
          name
        }
        terminalConnected
      }
    }
  }
`
