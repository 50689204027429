import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Feature } from '~generated-types'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout/Gutter'
import { ModalContainer } from '@/components/Modal'
import { SalesTypeChip } from '@/modules/Sales'
import { T } from '@/modules/Language'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import {
  AccommodationSummary,
  Assignee,
  BasicDetails,
  CopySaleModal,
  EstimatedParticipants,
  HeaderButton,
  PrintSaleModal,
  SalesDates,
  SaleStatus,
  SalesTotal,
  TasksChecks,
} from './components'
import { useSalesDetailsContext } from '../../'

export const Header = () => {
  const {
    closeSale,
    data: {
      facet,
      id,
      name,
      lifecycle,
      orderNumber,
      reservationDates,
      tasks,
      type,
    },
    salesIssues,
  } = useSalesDetailsContext()

  const [isCopySaleModalOpen, setCopySaleModalOpen] = useState<boolean>(false)
  const [isPrintSaleModalOpen, setPrintSaleModalOpen] = useState<boolean>(false)

  const features = facet.features.map(({ feature }) => feature)
  const dateEstimationAllowed =
    features.includes(Feature.Accommodation) ||
    features.includes(Feature.Reservations)
  const estimatedParticipantsVisible = features.includes(Feature.Participants)
  const issues = salesIssues?.salesIssues ?? []

  return (
    <Wrapper>
      <Gutter type={[3, 1, 3, 3]}>
        <SalesTypeChip facet={facet} format="full" size="md" type={type} />
      </Gutter>

      <Gutter flex={1} type={[0, 2, 0, 0]}>
        <BasicDetails salesId={id} name={name} orderNumber={orderNumber} />
      </Gutter>

      {!!issues.length && (
        <Gutter type={[0, 2]}>
          {issues.map(({ code, key, level }) => (
            <ValidationIndicator
              code={code}
              key={`${key}-${code}`}
              level={level}
              size="lg"
            />
          ))}
        </Gutter>
      )}

      <SaleStatus salesId={id} lifecycle={lifecycle} />

      {!!tasks.length && (
        <>
          <TasksChecks />
          <Separator />
        </>
      )}

      {(dateEstimationAllowed || reservationDates) && (
        <>
          <SalesDates estimationAllowed={dateEstimationAllowed} />
          <Separator />
        </>
      )}

      <FlexRow style={{ height: '100%' }}>
        {estimatedParticipantsVisible && (
          <>
            <EstimatedParticipants />
            <Separator />
          </>
        )}

        {features.includes(Feature.Accommodation) && (
          <>
            <AccommodationSummary />
            <Separator />
          </>
        )}
      </FlexRow>

      <SalesTotal />

      <Separator />

      <Assignee />

      <ModalContainer
        isOpen={isPrintSaleModalOpen}
        modal={<PrintSaleModal onClose={() => setPrintSaleModalOpen(false)} />}
        onClose={() => setPrintSaleModalOpen(false)}
        referenceElement={({ ref }) => (
          <HeaderButton
            icon="print"
            innerRef={ref as Ref<HTMLButtonElement>}
            label={<T>SalesDetails:PrintSale.print</T>}
            onClick={() => setPrintSaleModalOpen(true)}
            size="1x"
          />
        )}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />

      <ModalContainer
        isOpen={isCopySaleModalOpen}
        modal={<CopySaleModal onClose={() => setCopySaleModalOpen(false)} />}
        onClose={() => setCopySaleModalOpen(false)}
        referenceElement={({ ref }) => (
          <HeaderButton
            icon={['far', 'clone']}
            innerRef={ref as Ref<HTMLButtonElement>}
            label={<T>SalesDetails:CopySale.copy</T>}
            onClick={() => setCopySaleModalOpen(true)}
            size="1x"
          />
        )}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />

      {!!closeSale && (
        <HeaderButton
          icon="xmark"
          label={<T>common:action.close</T>}
          onClick={closeSale}
          size="lg"
        />
      )}
    </Wrapper>
  )
}

////////////

const Separator = styled.div`
  flex: 0 0 1px;
  align-self: stretch;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.main};
  `}
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
  box-shadow: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border-bottom: solid 1px ${theme.palette.smoke.main};
  `}
`
