import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'

import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useParticipantsListContext } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

export const OutdatedListWarning = () => {
  const { palette } = useTheme()

  const { refreshingList, refetchParticipants } = useParticipantsListContext()

  return (
    <Wrapper alignItems="center">
      <FontWeight>
        <T>ParticipantsList:listOutdated</T>
      </FontWeight>
      <IconButtonWrapper alignItems="center" justifyContent="center">
        {refreshingList ? (
          <ReactLoading
            color={palette.warning.dark}
            height={18}
            type="spin"
            width={18}
          />
        ) : (
          <IconButton noNudge onClick={() => refetchParticipants()}>
            <FontAwesomeIcon icon="arrows-rotate" />
          </IconButton>
        )}
      </IconButtonWrapper>
    </Wrapper>
  )
}

/////////

const IconButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    color: ${theme.palette.warning.dark};
    width: ${theme.spacing.gu(5)}rem;
  `}
`

const IconButtonWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    height: ${theme.spacing.gu(5)}rem;
    width: ${theme.spacing.gu(5)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.warning.dark};
  `}
`
