import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'

import {
  ListItemSection as Section,
  ListItem as Wrapper,
} from '@/components/List'
import { useTheme } from '@/theme'

type Props = {
  columnCount: number
  content: ReactNode
  helper?: ReactNode
  icon?: IconProp
}

export const ListPlaceholder = ({
  columnCount,
  content,
  helper,
  icon,
}: Props) => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Section colSpan={columnCount}>
        <PlaceholderPosition>
          {icon && (
            <FontAwesomeIcon
              color={theme.palette.smoke.dark}
              icon={icon}
              size="3x"
              style={{ marginBottom: `${theme.spacing.gu(2)}rem` }}
            />
          )}
          <Content>{content}</Content>
          {helper && <Helper>{helper}</Helper>}
        </PlaceholderPosition>
      </Section>
    </Wrapper>
  )
}

////////////

const PlaceholderPosition = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(10)}rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`

const Content = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const Helper = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase};
  `}
`
