import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Button } from '@/components/Button'
import { T } from '@/modules/Language'
import { WillbaUser } from '@/modules/Auth'

import { IconButton } from '../IconButton'
import { Sidebar } from '../Sidebar'

type Props = {
  closeMenu: () => void
  isOpen: boolean
  signOut: () => void
  user: WillbaUser
}

export const UserSidebar = ({
  closeMenu,
  isOpen,
  signOut,
  user: { displayName, email, photoURL },
}: Props) => {
  return (
    <Sidebar noPadding isOpen={isOpen}>
      <Header alignItems="center" justifyContent="flex-end">
        <IconButton icon="times" onClick={closeMenu} />
      </Header>

      <Content alignItems="center" flex={1}>
        <AvatarWrapper>
          <Avatar
            alt="User avatar"
            src={photoURL || '/portrait_placeholder.png'}
          />
        </AvatarWrapper>

        <DisplayName>{displayName}</DisplayName>

        <Email>{email}</Email>
      </Content>

      <Footer justifyContent="center">
        <SignOutButton
          color="danger"
          noNudge
          onClick={signOut}
          size="big"
          variant="borderless"
        >
          <T>common:user.signOut</T>
        </SignOutButton>
      </Footer>
    </Sidebar>
  )
}

////////////

const Avatar = styled.img`
  border-radius: 50%;
  height: 100%;
  width: 100%;
`

const AvatarWrapper = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.main};
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const DisplayName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Email = styled(DisplayName)`
  font-weight: 500;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const Content = styled(FlexColumn)`
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const Header = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(10)}rem;
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`

const Footer = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(10)}rem;
  `}
`

const SignOutButton = styled(Button)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
