import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { formatDueDate, formatDueTime } from '../utils'
import { ListingReservationTask } from '../useReservationList'

type Props = {
  reservationDate: Moment
  task: ListingReservationTask
}

export const TaskDetails = ({ reservationDate, task }: Props) => {
  const differentDate =
    task.dueDate && !moment(task.dueDate).isSame(reservationDate, 'day')

  const assigneeDisplayName =
    task.assignee?.__typename === 'User'
      ? task.assignee.email
      : task.assignee?.name

  return (
    <Wrapper>
      <Row>
        <strong>{assigneeDisplayName || ''}</strong>
      </Row>
      <Row>
        {task.dueDate && differentDate && (
          <ExtraInfo>
            <FontAwesomeIcon fixedWidth icon={['far', 'calendar']} />{' '}
            {formatDueDate(task.dueDate)}
            {task.dueTime && (
              <>
                {` `}
                <FontAwesomeIcon fixedWidth icon={['far', 'clock']} />{' '}
                {formatDueTime(task.dueTime)}
              </>
            )}
          </ExtraInfo>
        )}
        {task.dueTime && !differentDate && (
          <ExtraInfo>
            <FontAwesomeIcon fixedWidth icon={['far', 'clock']} />{' '}
            {formatDueTime(task.dueTime)}
          </ExtraInfo>
        )}
      </Row>
      <Row>
        <span>{task.description}</span>
      </Row>
    </Wrapper>
  )
}

export default TaskDetails

////////////

const ExtraInfo = styled.small`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-left: ${theme.spacing.gutter};
  `}
`

const Row = styled.span`
  align-items: baseline;
  display: block;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 140px;

  @media print {
    width: 3cm;
  }
`

const Wrapper = styled.span`
  display: inline-flex;
  flex-direction: column;

  &:not(:last-child) {
    ${({ theme }) => css`
      margin-bottom: ${theme.spacing.gutterSmall};
      padding-bottom: ${theme.spacing.gutterSmall};
      border-bottom: dashed 1px ${theme.palette.smoke.extraDark};
    `}
  }
`
