import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import {
  useCloseTaskMutation,
  useOpenTaskMutation,
  useUpdateTaskMutation,
} from '@/modules/Task/mutations'
import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { SaleTasksQuery } from '~generated-types'
import TaskNote from '@/components/TextNoteModal'
import { UserGroup } from '@/modules/Registry'

type Props = {
  task: SaleTasksQuery['sales']['tasks'][0]
}

export const Task = ({ task }: Props) => {
  const [openTask] = useOpenTaskMutation()
  const [closeTask] = useCloseTaskMutation()
  const [updateTask] = useUpdateTaskMutation()

  const handleChangeTaskState = () => {
    const variables = {
      variables: {
        id: task.id,
      },
    }

    task.isOpen ? closeTask(variables) : openTask(variables)
  }

  const handleUpdateTaskNote = (note: string) =>
    updateTask({
      variables: {
        id: task.id,
        input: {
          description: note,
          dueDate: task.dueDate,
          dueTime: task.dueTime,
          name: task.name,
        },
      },
    })

  return (
    <CheckboxWrapper>
      <CheckboxInput
        checked={!task.isOpen}
        noMargin
        onChange={handleChangeTaskState}
      >
        {(task.assignee as UserGroup)?.name}
      </CheckboxInput>
      <DueDate light>
        {task.dueDate && (
          <>
            {moment(`${task.dueDate}T${task.dueTime || '00:00:00'}`).format(
              task.dueTime ? 'D.M.YY HH:mm' : 'D.M.YY'
            )}
            {', '}
          </>
        )}
        {task.name}
      </DueDate>
      <TaskNote
        note={task.description || ''}
        updateNote={handleUpdateTaskNote}
      />
    </CheckboxWrapper>
  )
}

const CheckboxWrapper = styled(FlexRow)<any>`
  align-items: center;
  line-height: 1;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterBig};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  span {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }

  label {
    flex: 1;
    color: ${({ theme }) => theme.palette.text.main};

    ${({ theme }) =>
      `
      &:hover {
        color: ${theme.palette.primary.darker};
      }
    `}
  }
`

const DueDate = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`
