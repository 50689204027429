import { FlexRow } from '@/components/Layout'

import {
  PaymentCashDetails as CashDetails,
  PaymentCreditCardDetails as CreditCardDetails,
  PaymentVoucherDetails as VoucherDetails,
} from '@/modules/Order/types'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'

import { Cell, Section, SectionWithTooltip } from '../../components'

type Props = {
  details: CashDetails | CreditCardDetails | VoucherDetails
}

export const BasicDetails = ({ details: { notes, pointOfSale } }: Props) => (
  <>
    <FlexRow flex={4}>
      <SectionWithTooltip
        content={pointOfSale?.name || <FontColor lighter>–</FontColor>}
        flex={1}
        icon="location-dot"
        title={<T>Orders:Payments.field.pointOfSale</T>}
      />
    </FlexRow>

    <FlexRow flex={3}>
      <Cell flex={1}>
        <Section icon={['far', 'note-sticky']}>
          {notes || <FontColor lighter>–</FontColor>}
        </Section>
      </Cell>
    </FlexRow>
  </>
)
