import styled, { css } from 'styled-components'

import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

import {
  Contacts,
  CustomerInformation,
  Theme,
  ThemeDescription,
} from './components'
import {
  HorizontalDivider,
  ListContainerRow,
  RowWrapper,
  VerticalDivider,
} from '../common'
import { ContentSection } from '../../../../components'

export const Basic = () => (
  <RowWrapper>
    <FlexColumn flex={8}>
      <BasicSettingsWrapper>
        <ContentSection title={<T>Publish:Basic.title</T>}>
          <ListContainerRow>
            <Section noPadding noLeftMargin>
              <ComponentWrapper>
                <Theme />
              </ComponentWrapper>

              <HorizontalDivider isTransparent />

              <ComponentWrapper>
                <ThemeDescription />
              </ComponentWrapper>
            </Section>

            <VerticalDivider />

            <Section noPadding>
              <ComponentWrapper>
                <Contacts />
              </ComponentWrapper>

              <HorizontalDivider isTransparent />

              <ComponentWrapper flex={1}></ComponentWrapper>
            </Section>
          </ListContainerRow>
        </ContentSection>
      </BasicSettingsWrapper>
    </FlexColumn>

    <CustomerInfoWrapper flex={5}>
      <ContentSection title={<T>Publish:Basic.customerInformation.title</T>}>
        <CustomerInformation />
      </ContentSection>
    </CustomerInfoWrapper>
  </RowWrapper>
)

/////////

const BasicSettingsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const ComponentWrapper = styled.div<{ flex?: number }>`
  min-height: 50px;
  display: flex;
  flex-direction: column;

  ${({ flex }) => css`
    flex: ${flex ?? 'initial'};
  `}
`

const CustomerInfoWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(50)}rem;
  `}
`

const Section = styled(FlexColumn)<{ noLeftMargin?: boolean }>`
  justify-content: center;

  ${({ theme, noLeftMargin }) => css`
    ${noLeftMargin
      ? `margin: 0 ${theme.spacing.gu(2)}rem 0 0;`
      : `margin: 0 ${theme.spacing.gu(2)}rem;`};
    width: ${theme.spacing.gu(40)}rem;
  `}
`
