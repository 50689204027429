import styled, { css } from 'styled-components/macro'

import { DataTableInputBase } from '@/components/DataTable'
import { FlexColumn } from '@/components/Layout'
import { InlineModalSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'

import { Title } from './common'

type Props = {
  notes: string
  setNotes: (notes: string) => void
}

export const NotesTextarea = ({ notes, setNotes }: Props) => (
  <InlineModalSection style={{ marginTop: 0 }}>
    <FlexColumn flex={1} noPadding>
      <Title>
        <T>Orders:Payments.field.notes</T>
      </Title>
      <Textarea
        onChange={(e) => setNotes(e.target.value)}
        showBorder
        value={notes}
      />
    </FlexColumn>
  </InlineModalSection>
)

////////

const Textarea = styled(DataTableInputBase).attrs(() => ({
  as: 'textarea',
}))`
  &&& {
    border-radius: 6px;
    min-height: 78px;
    line-height: 1.2;
    resize: vertical;

    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
    `}

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`
