import * as yup from 'yup'
import { Moment } from 'moment'
import styled from 'styled-components/macro'

import { emailRegex, FormikRenderProps } from '@/utils/forms'
import {
  FormField,
  FormFieldTuple,
  FormSection,
  FormSectionTitle,
} from '@/components/FormWrappers'
import { Label, TextInput } from '@/components/FormControls'
import { FormikFormFieldHelper } from '@/components/FormControls'
import { T } from '@/modules/Language'

import {
  CustomerDefaultAddress,
  customerDefaultAddressSchema,
} from './CustomerDefaultAddress'
import AutoSaveIndicator from './AutoSaveIndicator'

type Props = {
  hideTitle?: boolean
  disableAutosave?: boolean
  formikRenderProps: FormikRenderProps
  lastSaved?: Moment | null | undefined
  showDefaultAddress?: boolean
}

export const basicDetailsPersonCreateSchema = yup.object().shape({
  defaultAddress: customerDefaultAddressSchema,
  person: yup.object().shape({
    email: yup.string().matches(emailRegex, 'invalid').nullable(),
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    phone: yup.string().nullable(),
  }),
})

export const basicDetailsPersonEditSchema = yup.object().shape({
  person: yup.object().shape({
    email: yup.string().matches(emailRegex, 'invalid').nullable(),
    firstName: yup.string().required('required'),
    lastName: yup.string().required('required'),
    phone: yup.string().nullable(),
  }),
})

export const BasicDetailsPerson = ({
  hideTitle,
  disableAutosave,
  formikRenderProps,
  lastSaved,
  showDefaultAddress,
}: Props) => (
  <>
    <FormSection>
      {!hideTitle && (
        <Title>
          <T>Customers:basicDetails</T>
          {!disableAutosave && (
            <AutoSaveIndicator
              formikRenderProps={formikRenderProps}
              lastSaved={lastSaved}
            />
          )}
        </Title>
      )}
      <FormFieldTuple>
        <FormField>
          <Label>
            <T>Customers:firstName</T>*
          </Label>
          <FormikFormFieldHelper
            defaultValue={''}
            errorDescriptions={{
              required: <T>Customers:validation.firstName.required</T>,
            }}
            inputComponent={TextInput}
            name="person.firstName"
          />
        </FormField>
        <FormField>
          <Label>
            <T>Customers:lastName</T>*
          </Label>
          <FormikFormFieldHelper
            defaultValue={''}
            errorDescriptions={{
              required: <T>Customers:validation.lastName.required</T>,
            }}
            inputComponent={TextInput}
            name="person.lastName"
          />
        </FormField>
      </FormFieldTuple>
      <FormField>
        <Label>
          <T>Customers:email</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          errorDescriptions={{
            invalid: <T>Customers:validation.email.invalid</T>,
          }}
          inputComponent={TextInput}
          name="person.email"
        />
      </FormField>
      <FormField>
        <Label>
          <T>Customers:phone</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="person.phone"
        />
      </FormField>
    </FormSection>
    {showDefaultAddress && <CustomerDefaultAddress />}
  </>
)

////////////

const Title = styled(FormSectionTitle)`
  display: flex;
  align-items: baseline;
`
