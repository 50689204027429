import { Fragment, Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import {
  SalesProductUpdateInput,
  UpdatePublishedProductMutation,
} from '~generated-types'

import { InlineModal, InlineModalLine } from '@/components/InlineModal'
import {
  SalesAccommodationGroup,
  SalesProduct,
  SalesProductSettings,
} from '@/modules/Products/types'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ModalContainer } from '@/components/Modal'
import { useSalesAccommodationGroups } from '@/modules/Products/hooks'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

interface Props {
  id: string
  readOnly: boolean
  accommodationGroup: SalesProductSettings['accommodationGroup']
  updateProduct: (
    input: SalesProductUpdateInput
  ) => Promise<SalesProduct | UpdatePublishedProductMutation | null | undefined>
  noMargin?: boolean
}

export const AccommodationGroupSelector = ({
  id,
  readOnly,
  accommodationGroup,
  updateProduct,
  noMargin,
}: Props) => {
  const {
    data: { id: salesId },
  } = useSalesDetailsContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const { accommodationGroups, error, loading } = useSalesAccommodationGroups({
    id: salesId,
    skip: !isModalOpen,
  })

  const handleSetAccommodationGroup = (accommodationGroupId: string) =>
    updateProduct({ id, settings: { accommodationGroupId } }).then(() =>
      setModalOpen(false)
    )

  return (
    <ModalContainer
      isOpen={isModalOpen && !loading}
      modal={
        <Modal>
          <Title>
            <T>Products:Settings.accommodationGroup.title</T>
          </Title>

          <InlineModalLine style={{ margin: 0 }} />

          {error ? (
            <ModalPlaceholder justifyContent="center">
              – <T>common:error.common</T> –
            </ModalPlaceholder>
          ) : accommodationGroups.length ? (
            accommodationGroups.map((group) => (
              <Fragment key={group.id}>
                <Option
                  justifyContent="space-between"
                  onClick={() => handleSetAccommodationGroup(group.id)}
                >
                  {getGroupName(group)}
                  {group.id === accommodationGroup?.id && (
                    <CheckIcon icon="check" />
                  )}
                </Option>

                <InlineModalLine style={{ margin: 0 }} />
              </Fragment>
            ))
          ) : (
            <ModalPlaceholder justifyContent="center">
              – <T>Products:Settings.accommodationGroup.noGroups</T> –
            </ModalPlaceholder>
          )}
        </Modal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          noMargin={noMargin}
          disabled={readOnly}
          onClick={() => setModalOpen(true)}
          ref={ref as Ref<HTMLButtonElement> | undefined}
        >
          {accommodationGroup ? (
            getGroupName(accommodationGroup)
          ) : (
            <Placeholder>
              <T>Products:Settings.accommodationGroup.empty</T>
            </Placeholder>
          )}
        </Button>
      )}
      zIndex={10006}
    />
  )
}

/////

const getGroupName = ({ name, sortOrder }: SalesAccommodationGroup) => {
  const { language } = useLanguageContext()

  const placeholder = translate(
    'Products:Settings.accommodationGroup.placeholder',
    language
  )

  return `#${sortOrder + 1} ${name || placeholder}`
}

const Button = styled.button<{ noMargin?: boolean }>`
  align-items: center;
  justify-content: center;
  cursor: pointer;

  border-radius: 4px;

  ${({ theme, noMargin }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.text.light};
    height: ${theme.spacing.gu(4)}rem;
    min-width: ${theme.spacing.gu(30)}rem;
    ${noMargin ? '' : `margin-right: ${theme.spacing.gu(2)}rem;`}
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:disabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      color: ${theme.palette.text.light};
      cursor: not-allowed;
    `}
  }

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const CheckIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin-left: ${theme.spacing.gu(2)}rem;
  `}
`

const Option = styled(FlexRow)`
  cursor: pointer;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const Modal = styled(InlineModal)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    min-width: ${theme.spacing.gu(34)}rem;
    padding: ${theme.spacing.gu(1)}rem 0;
  `}
`

const ModalPlaceholder = styled(FlexRow)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gu(2)}rem 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.extraDark};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1.5)}rem;
  `}
`
