import {
  PaymentCashDetails as CashDetailsType,
  PaymentCreditCardDetails as CreditCardDetailsType,
  PaymentGiftCardDetails as GiftCardDetailsType,
  PaymentOtherDetails as OtherDetailsType,
  PaymentDetails as PaymentDetailsType,
  PaymentReferenceTransferDetails as ReferenceTransferDetailsType,
  PaymentRefundDetails as RefundDetailsType,
  PaymentReservationFeeDetails as ReservationFeeDetailsType,
  PaymentVoucherDetails as VoucherDetailsType,
} from '@/modules/Order/types'
import { PaymentType } from '~generated-types'

import {
  BasicDetails,
  GiftCardDetails,
  OtherDetails,
  ReferenceTransferDetails,
  RefundDetails,
  ReservationFeeDetails,
} from './Details'

type BasicDetailsType =
  | CashDetailsType
  | CreditCardDetailsType
  | VoucherDetailsType

type Props = {
  date: string
  details: PaymentDetailsType
  type: PaymentType
}

export const PaymentDetails = ({ date, details, type }: Props) => {
  switch (type) {
    case PaymentType.Cash:
    case PaymentType.CreditCard:
    case PaymentType.Voucher:
      return <BasicDetails details={details as BasicDetailsType} />
    case PaymentType.GiftCard:
      return <GiftCardDetails details={details as GiftCardDetailsType} />
    case PaymentType.Other:
      return <OtherDetails details={details as OtherDetailsType} />
    case PaymentType.RefundInvoice:
      return <RefundDetails details={details as RefundDetailsType} />
    case PaymentType.ReferenceTransfer:
      return (
        <ReferenceTransferDetails
          date={date}
          details={details as ReferenceTransferDetailsType}
        />
      )
    case PaymentType.ReservationFeeInvoice:
      return (
        <ReservationFeeDetails details={details as ReservationFeeDetailsType} />
      )
    case PaymentType.Online:
    default:
      return null
  }
}
