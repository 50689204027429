import styled, { css } from 'styled-components/macro'

import {
  Reservation,
  ResourceReservationResource,
  ResourceReservationValidationIssue,
} from '@/modules/Reservations/ResourceReservation'
import { translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { useTheme } from '@/theme'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import { ColorPicker } from './ColorPicker'
import { ResourceNote } from './ResourceNote'
import { ResourceSelector } from './ResourceSelector'

type Props = {
  color: string | null
  isEditable: boolean
  issues: ResourceReservationValidationIssue[]
  readOnly: boolean
  reservationId: string
  resource: ResourceReservationResource | null
  updateReservations: (reservations: Reservation[]) => void
}

export const Resource = ({
  color,
  isEditable,
  issues,
  readOnly,
  reservationId,
  resource,
  updateReservations,
}: Props) => {
  const { spacing } = useTheme()
  const { language } = useLanguageContext()

  return (
    <Wrapper alignItems="center">
      <ColorPicker
        color={color}
        readOnly={readOnly}
        reservationId={reservationId}
        updateReservations={updateReservations}
      />

      <ResourceSelector
        isEditable={!!resource && isEditable}
        readOnly={readOnly}
        reservationId={reservationId}
        resourceName={
          resource?.name ||
          translate('ResourceReservations:resource.program', language)
        }
        updateReservations={updateReservations}
      />

      {resource && (
        <ResourceNote
          id={resource.id}
          note={resource.internalInfo}
          reservationId={reservationId}
          updateReservations={updateReservations}
        />
      )}

      {issues.map(({ code, key, level }) => (
        <ValidationIndicator
          code={code}
          key={key}
          level={level}
          placement="top-start"
          style={{ margin: `0 ${spacing.gutterSmall} 0 ${spacing.gutter}` }}
        />
      ))}
    </Wrapper>
  )
}

/////////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem 0
      ${theme.spacing.gu(1)}rem;
  `}
`
