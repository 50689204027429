import { ReactNode, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import { translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

export interface PageProps {
  children: ReactNode
  /**
   * Used to set a descriptive page title. `pageTitleKey` takes precendence over
   * `pageTitle` if both are provided. `pageTitleKey` is used to provide a
   * localisation key for the title and `pageTitle` is just a simple string.
   */
  pageTitle?: string | string[]
  pageTitleKey?: string | string[]
}

const SEPARATOR = ' | '

export const Page = ({ children, pageTitle, pageTitleKey }: PageProps) => {
  const { language } = useLanguageContext()
  const { branding } = useTheme()

  const [localisedPageTitle, setLocalisedPageTitle] = useState<string>('')

  useEffect(() => {
    if (pageTitleKey) {
      if (Array.isArray(pageTitleKey)) {
        setLocalisedPageTitle(
          pageTitleKey.map((part) => translate(part, language)).join(SEPARATOR)
        )
      } else {
        setLocalisedPageTitle(translate(pageTitleKey, language))
      }
    }
  }, [language, pageTitleKey])

  const titlePostfix = pageTitleKey
    ? localisedPageTitle
    : Array.isArray(pageTitle)
    ? pageTitle.join(SEPARATOR)
    : pageTitle

  const titlePrefix = branding.pageTitle || 'Willba'

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: language }}
        defaultTitle={titlePrefix}
        titleTemplate={`${titlePrefix}${SEPARATOR}%s`}
        title={titlePostfix}
      />
      {children}
    </>
  )
}
