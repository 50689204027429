import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import {
  InlineModal,
  InlineModalContent,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  OtherPaymentMethod as Method,
  Payment,
  PaymentOtherDetails,
} from '@/modules/Order/types'
import { FlexRow } from '@/components/Layout'
import { orderServices } from '@/modules/Order'
import { PaymentOperation } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  AmountInput,
  AuthorInput,
  InvoiceReferenceInput,
  NotesTextarea,
  PaymentTypeSelector,
  SalesReferenceInput,
} from './components'
import { OtherPaymentModalFooter } from './OtherPaymentModalFooter'

type Props = {
  invoiceId: string
  method: Method
  onClose: () => void
  orderId: string
  payment?: Payment
}

export const OtherPaymentModal = ({
  invoiceId,
  method,
  onClose,
  orderId,
  payment,
}: Props) => {
  const { spacing } = useTheme()

  const { addOtherPayment, removeManualPayment, updateManualPayment } =
    orderServices.invoiceService()

  const details = payment?.details as PaymentOtherDetails | null

  const [amount, setAmount] = useState<number>(
    payment ? (method === Method.Payment ? payment.amount : -payment.amount) : 0
  )
  const [author, setAuthor] = useState<string>(details?.author ?? '')
  const [invoiceReference, setInvoiceReference] = useState<string>(
    details?.invoiceReference ?? ''
  )
  const [notes, setNotes] = useState<string>(details?.notes ?? '')
  const [processing, setProcessing] = useState<boolean>(false)
  const [salesReference, setSalesReference] = useState<string>(
    details?.salesReference ?? ''
  )
  const [typeId, setTypeId] = useState<string>(details?.paymentType?.id ?? '')

  const isAddingDisabled = !amount || !author || !notes || !typeId
  const isRemovingDisabled = !payment?.lifecycle.validatedActions.find(
    ({ action }) => action === PaymentOperation.DeleteManualPayment
  )?.valid

  const paymentInput = {
    amount: method === Method.Refund ? -Number(amount) : Number(amount),
    author,
    invoiceId,
    invoiceReference,
    notes,
    orderId,
    salesReference,
    typeId,
  }

  const handleAddPayment = () => {
    setProcessing(true)
    addOtherPayment(paymentInput).then(onClose)
  }

  const handleRemovePayment = () =>
    removeManualPayment(invoiceId, orderId, payment?.id ?? '')

  const handleUpdatePayment = () => {
    setProcessing(true)
    updateManualPayment({ id: payment?.id ?? '', ...paymentInput }).then(
      onClose
    )
  }

  return (
    <Modal>
      <InlineModalHeader
        onClose={onClose}
        title={
          <ShiftLeft>
            <T>{`Orders:Action.INVOICE.ADD_OTHER_${method}`}</T>
          </ShiftLeft>
        }
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      <InlineModalContent>
        <InvoiceReferenceInput
          reference={invoiceReference}
          setReference={setInvoiceReference}
        />

        <SalesReferenceInput
          reference={salesReference}
          setReference={setSalesReference}
        />

        <InlineModalLine dashed style={{ marginTop: spacing.gutter }} />

        <AuthorInput author={author} setAuthor={setAuthor} />

        <NotesTextarea notes={notes} setNotes={setNotes} />

        <InlineModalLine dashed style={{ marginTop: spacing.gutter }} />

        <FlexRow>
          <AmountInput value={amount.toString()} setAmount={setAmount} />
          <PaymentTypeSelector typeId={typeId} setTypeId={setTypeId} />
        </FlexRow>
      </InlineModalContent>

      <OtherPaymentModalFooter
        handleAddPayment={handleAddPayment}
        handleRemovePayment={handleRemovePayment}
        handleUpdatePayment={handleUpdatePayment}
        isAddingDisabled={isAddingDisabled}
        isRemovingDisabled={isRemovingDisabled}
        isEditMode={!!payment}
        isProcessing={processing}
        method={method}
        onClose={onClose}
      />
    </Modal>
  )
}

////////

const Modal = styled(InlineModal)`
  max-height: 100vh;
  width: 420px;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(5)}rem;
  `}
`
