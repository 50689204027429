import React, { Component } from 'react'
import { createGlobalStyle } from 'styled-components/macro'
import { DatePicker as LibraryDatePicker } from '@appbaseio/reactivesearch'
import MomentLocaleUtils from 'react-day-picker/moment'

import { Input } from '@/components/FormControls'
import { useLanguageContext } from '@/modules/Language'

import 'moment/locale/fi'

const wrapperClassName = 'date-picker-wrapper'

export const DatePicker = (
  props: Record<string, unknown> | undefined | null
) => {
  const { language } = useLanguageContext()
  return (
    <>
      <GlobalStyles />
      {/* @ts-ignore */}
      <LibraryDatePicker
        {...props}
        className={wrapperClassName}
        dayPickerInputProps={{
          // react-day-picker requires focus method on the input element
          component: InputWithFocus,
          dayPickerProps: {
            locale: language,
            localeUtils: MomentLocaleUtils,
          },
        }}
      />
    </>
  )
}

class InputWithFocus extends Component<any> {
  input = React.createRef<HTMLInputElement>()

  focus() {
    if (this.input.current) {
      // @ts-ignore
      this.input.current.focus()
    }
  }

  render() {
    return <Input ref={this.input} {...this.props} />
  }
}

// Reactive search uses react-day-picker for their date input. They have not
// styled the input directly, but instead wrapped it in a div, which has a
// border. Here we are getting rid of that said border.
const GlobalStyles = createGlobalStyle`
  .${wrapperClassName} > div {
    border: none;
  }
`
