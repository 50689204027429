import { ChangeEvent } from 'react'

import { FlexColumn } from '@/components/Layout'
import { InlineModalSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'

import { Input, InputWrapper, Title } from './common'

type Props = {
  reference: string
  setReference: (reference: string) => void
}

export const InvoiceReferenceInput = ({ reference, setReference }: Props) => (
  <InlineModalSection style={{ marginTop: 0 }}>
    <FlexColumn flex={1} noPadding>
      <Title>
        <T>Orders:Payments.field.invoiceReference</T>
      </Title>

      <InputWrapper>
        <Input
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setReference(e.target.value)
          }
          showBorder
          value={reference}
        />
      </InputWrapper>
    </FlexColumn>
  </InlineModalSection>
)
