import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import {
  Feature,
  PurchaseProductAction as PA,
  ServiceParticipantBedFragment,
} from '~generated-types'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { SalesProductManager } from '@/modules/Products'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import ServiceItemSquared from '../../../ParticipantRow/common/ServiceItemSquared'

type Purchase = NonNullable<ServiceParticipantBedFragment['purchaseProduct']>

type Props = {
  disabled: boolean
  handleRemovePurchase: (purchaseId: string) => Promise<any>
  isMassUpdate?: boolean
  purchase: Purchase
}

export const ParticipantProduct = ({
  disabled,
  handleRemovePurchase,
  isMassUpdate,
  purchase: {
    id,
    product,
    status: { issues, validatedActions },
  },
}: Props) => {
  const { palette, spacing } = useTheme()

  const {
    data: {
      commissionRate,
      facet: { features },
      id: salesId,
      seller,
      type: salesType,
    },
    saleReadOnly,
  } = useSalesDetailsContext()

  const isCommissionEnabled = !!features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const isRemovable = validatedActions.find(
    (a) => a.action === PA.Delete
  )?.valid

  const renderContent = () => (
    <ContentWrapper justifyContent="flex-start">
      <FontAwesomeIcon
        color={palette.primary.dark}
        icon="cube"
        style={{ marginRight: `${spacing.gu(1)}rem` }}
      />

      {issues.map(({ code, key, level }) => (
        <ValidationIndicatorWrapper key={`${key}-${code}`}>
          <ValidationIndicator
            code={code}
            level={level}
            style={{ margin: `0 ${spacing.gutterSmall}` }}
          />
        </ValidationIndicatorWrapper>
      ))}

      <PurchaseName>{product.name}</PurchaseName>
    </ContentWrapper>
  )

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <SalesProductManager
          commission={commission}
          context="SALES"
          onClose={() => setModalOpen(false)}
          productId={product.id}
          readOnly={saleReadOnly}
          salesId={salesId}
          salesType={salesType}
          sellerId={seller?.id}
          targetPurchaseId={id}
        />
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <ServiceItemSquared
          disabled={disabled || isMassUpdate}
          isRemovable={!disabled && isRemovable && !isMassUpdate}
          margin={`0 0 0 ${spacing.gu(1)}rem`}
          minWidth="8rem"
          onClick={() => !(disabled || isMassUpdate) && setModalOpen(true)}
          onRemove={() => handleRemovePurchase(id)}
          ref={ref}
          renderContent={renderContent}
        />
      )}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}

const ContentWrapper = styled(FlexRow)`
  min-width: 5rem;
  width: 100%;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const PurchaseName = styled.p`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`
