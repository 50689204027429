import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useState } from 'react'

import { Description, FontWeight, H4 } from '@/components/Typography'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Label, Select } from '@/components/FormControls'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { CheckboxSelector } from '@/components/ReservationsOffset/components'
import { DataField } from '@/components/DataField'
import { DateRange } from '@/modules/Accommodation'
import { IconButton } from '@/components/ExtraButtons'
import { InlineModal } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

import Calendar from './Capacity/AvailabilityCalendar'
import { ReservationManagementControls } from './ReservationManagementControls'
import { SalesAccommodationGroup } from '../SalesReservationList.types'
import { useReservationListContext } from '../ReservationListState'

type Props = {
  group: SalesAccommodationGroup
  readOnly?: boolean
  handleOpenManager: (targetId?: string) => void
  handleOpenRoomLayout: (targetId?: string) => void
  selectProcess: boolean
  targetIdsToMove: string[]
  setTargetIdsToMove: (ids: string[]) => void
}

export const ReservationListHeader = ({
  group,
  readOnly,
  handleOpenManager,
  handleOpenRoomLayout,
  selectProcess,
  targetIdsToMove,
  setTargetIdsToMove,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const { saleType, changeGroupSettings, setGroupName, removeGroup } =
    useReservationListContext()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const consumptionOptions = [
    {
      disabled: false,
      label: translate(
        'Accommodation:SalesReservationList.capacity.room',
        language
      ),
      value: 'ROOM',
    },
    {
      disabled: false,
      label: translate(
        'Accommodation:SalesReservationList.capacity.bed',
        language
      ),
      value: 'BED',
    },
  ]

  const durationOptions = [
    {
      disabled: false,
      label: translate(
        'Accommodation:SalesReservationList.capacity.static',
        language
      ),
      value: 'STATIC',
    },
    {
      disabled: false,
      label: translate(
        'Accommodation:SalesReservationList.capacity.variable',
        language
      ),
      value: 'VARIABLE',
    },
  ]

  const unsetOption = [
    {
      disabled: false,
      label: '–',
      value: 'UNSET',
    },
  ]

  const status = group?.status?.status || []
  const dateGroups = getColumnGroups({
    from: moment(status[0]?.date).startOf('isoWeek'),
    to: moment(status[status.length - 1]?.date).endOf('isoWeek'),
  })

  const groupCheckedTargets = group.targets.filter(({ id }) =>
    targetIdsToMove.includes(id)
  )
  const groupIsSelected = groupCheckedTargets.length === group.targets.length
  const handleSelectGroup = (selectAll: boolean) => {
    const groupTargetIds = group.targets.map(({ id }) => id)
    const filteredIds = targetIdsToMove.filter(
      (id) => !groupTargetIds.includes(id)
    )
    setTargetIdsToMove(
      selectAll ? [...filteredIds, ...groupTargetIds] : filteredIds
    )
  }

  return (
    <Header>
      <FlexRow flex={1} justifyContent="flex-start">
        {selectProcess && (
          <CheckboxSelector
            isChecked={groupIsSelected}
            isIndeterminate={!!groupCheckedTargets.length && !groupIsSelected}
            handleSelect={() => handleSelectGroup(!groupIsSelected)}
          />
        )}
        {group.sales.type !== 'SALES' ? (
          <FlexColumn alignItems="flex-start" flex={1}>
            <HeaderTitle>
              <H4>{`#${group.sortOrder + 1}`}</H4>
              {readOnly ? (
                <span
                  style={{
                    fontSize: theme.typography.fontSizeBig,
                    padding: `0 ${theme.spacing.gutterSmall}`,
                  }}
                >
                  {group.name || (
                    <T>Accommodation:SalesReservationList.groupName</T>
                  )}
                </span>
              ) : (
                <DataField
                  handleSubmit={(name) => setGroupName(group.id, name || '')}
                  inputType="text"
                  placeholder={
                    <T>Accommodation:SalesReservationList.groupName</T>
                  }
                  size={theme.typography.fontSizeBig}
                  value={group.name}
                  variant="primary"
                />
              )}
            </HeaderTitle>
            <Description>
              <T>Accommodation:SalesReservationList.reservationGroup</T>
            </Description>
          </FlexColumn>
        ) : (
          <>
            <Section marginLeft={`${theme.spacing.gu(4)}rem`}>
              <Icon icon="door-open" />
              {group.status.reservedDetailed.rooms}{' '}
              <T>Accommodation:SalesReservationList.capacity.rooms</T>
            </Section>

            <Section marginLeft={`${theme.spacing.gu(6)}rem`}>
              <Icon icon="bed" />
              {group.status.reservedDetailed.beds} +{' '}
              {group.status.reservedDetailed.extraBeds}{' '}
              <T>Accommodation:SalesReservationList.capacity.beds</T>
            </Section>
          </>
        )}
      </FlexRow>

      <FlexRow justifyContent="flex-end">
        <ReservationManagementControls
          isManagerDisabled={
            readOnly ||
            (saleType === 'EVENT'
              ? !group?.settings?.consumptionDurationType ||
                !group?.settings?.consumptionType
              : false)
          }
          isRoomLayoutDisabled={
            readOnly ||
            (!group.roomReservations.length &&
              !group.roomTypeReservations.length)
          }
          setManagerOpen={handleOpenManager}
          setRoomLayoutOpen={handleOpenRoomLayout}
          style={{ marginRight: `${theme.spacing.gu(2)}rem` }}
        />

        {group.sales.type === 'EVENT' && (
          <FlexRow>
            <Section marginRight={`${theme.spacing.gu(2)}rem`}>
              <FontWeight
                style={{
                  marginRight: `${theme.spacing.gu(1)}rem`,
                  whiteSpace: 'nowrap',
                }}
              >
                <T>
                  Accommodation:SalesReservationList.capacity.consumptionType
                </T>
                {': '}
              </FontWeight>
              <Select
                noMargin
                disabled={readOnly}
                value={group.settings?.consumptionType || 'UNSET'}
                onChange={(e) =>
                  changeGroupSettings({
                    consumptionType: e.target.value,
                    groupId: group.id,
                  })
                }
              >
                {(!group.settings?.consumptionDurationType
                  ? [...unsetOption, ...consumptionOptions]
                  : [...consumptionOptions]
                ).map((option) => (
                  <option
                    key={option.value}
                    disabled={option.disabled}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            </Section>

            <Section marginRight={`${theme.spacing.gu(2)}rem`}>
              <FontWeight
                style={{
                  marginRight: `${theme.spacing.gu(1)}rem`,
                  whiteSpace: 'nowrap',
                }}
              >
                <T>Accommodation:SalesReservationList.capacity.duration</T>
                {': '}
              </FontWeight>
              <Select
                noMargin
                disabled={readOnly}
                value={group.settings?.consumptionDurationType || 'UNSET'}
                onChange={(e) =>
                  changeGroupSettings({
                    consumptionDurationType: e.target.value,
                    groupId: group.id,
                  })
                }
              >
                {(!group.settings?.consumptionDurationType
                  ? [...unsetOption, ...durationOptions]
                  : [...durationOptions]
                ).map((option) => (
                  <option
                    key={option.value}
                    disabled={option.disabled}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            </Section>

            <Section marginRight={`${theme.spacing.gu(6)}rem`}>
              {group.status && group.status.type === 'BED' ? (
                <Icon icon="bed" />
              ) : (
                <Icon icon="door-open" />
              )}
              {group.status &&
              group.settings?.consumptionDurationType !== 'VARIABLE'
                ? `${group.status.available} / ${group.status.reserved} ${
                    group.status.type === 'BED'
                      ? translate(
                          'Accommodation:SalesReservationList.capacity.bedsAvailable',
                          language
                        )
                      : translate(
                          'Accommodation:SalesReservationList.capacity.roomsAvailable',
                          language
                        )
                  }`
                : `${group.status.reserved} ${
                    group.status.type === 'BED'
                      ? translate(
                          'Accommodation:SalesReservationList.capacity.beds',
                          language
                        )
                      : translate(
                          'Accommodation:SalesReservationList.capacity.rooms',
                          language
                        )
                  }`}
            </Section>

            {group.status && group.status.type === 'BED' ? (
              <Section marginRight={`${theme.spacing.gu(6)}rem`}>
                <Icon icon="door-open" />
                {group.status.reservedDetailed.rooms}{' '}
                <T>Accommodation:SalesReservationList.capacity.rooms</T>
              </Section>
            ) : (
              <Section marginRight={`${theme.spacing.gu(6)}rem`}>
                <Icon icon="bed" />
                {group.status.reservedDetailed.beds} +{' '}
                {group.status.reservedDetailed.extraBeds}
              </Section>
            )}

            <ModalContainer
              isOpen={isModalOpen}
              modal={
                <InlineModal style={{ padding: `${theme.spacing.gu(2)}rem` }}>
                  <Calendar status={status} dateGroups={dateGroups} />
                </InlineModal>
              }
              onClose={() => setModalOpen(false)}
              placement="bottom"
              referenceElement={({ ref }) => (
                <Section
                  ref={ref}
                  onClick={() => setModalOpen(true)}
                  marginRight={`${theme.spacing.gu(2)}rem`}
                >
                  <IconButton
                    color="transparent"
                    icon="calendar-days"
                    // @ts-ignore
                    size="lg"
                    onClick={() => null}
                    style={{
                      color: theme.palette.primary.main,
                    }}
                  />
                </Section>
              )}
            />

            <Tooltip
              content={translate(
                'Accommodation:SalesReservationList.deleteGroup',
                language
              )}
              delay={300}
              placement="top"
              trigger={(triggerProps) => (
                <Label
                  {...triggerProps}
                  style={{
                    marginBottom: 0,
                    marginRight: `${theme.spacing.guPx(1) + 2}px`,
                  }}
                >
                  <IconButton
                    disabled={readOnly}
                    color="transparent"
                    icon="trash"
                    onClick={() => removeGroup(group.id)}
                    style={{
                      color: theme.palette.danger.main,
                    }}
                  />
                </Label>
              )}
            />
          </FlexRow>
        )}
      </FlexRow>
    </Header>
  )
}

const getColumnGroups = ({ from, to }: DateRange) => {
  const firstDay = from.clone().startOf('day')
  const lastDay = to.clone().startOf('day')

  // Use weekday 4 to get the year correct for weeks during the
  // end/beginning of a year
  const weekIterator = firstDay.clone().isoWeekday(4)
  const lastWeek = lastDay.clone().isoWeekday(4)

  const grouped = []

  while (weekIterator.isValid() && weekIterator.isSameOrBefore(lastWeek)) {
    const items = []
    const dayIterator = weekIterator.clone().isoWeekday(1)

    for (let i = 0; i < 7; i++) {
      if (dayIterator.isSameOrAfter(firstDay)) {
        items.push({
          key: dayIterator.format('YYYY-MM-DD'),
          label: dayIterator.format('DD.MM.'),
        })
      }

      dayIterator.add(1, 'day')
    }

    const key = weekIterator.format('YYYY[W]WW')
    const label = (
      <span>
        <T>common:time.weekAbbreviated</T> {weekIterator.isoWeek()}
      </span>
    )

    grouped.push({ items, key, label })
    weekIterator.add(1, 'week')
  }

  return grouped
}

const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & p {
    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeSmall};
    `}
  }
`

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  & h4 {
    margin-bottom: 0px;
  }

  & > div {
    flex: 1;

    & > button {
      width: 100%;

      & > span {
        min-width: 10rem;
        width: 10rem;
        flex: 1;
        display: flex;
      }
    }
  }
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Section = styled(FlexRow)<{ marginLeft?: string; marginRight?: string }>`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  font-weight: 500;
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
`
