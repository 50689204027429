import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MouseEvent } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import type {
  ResourceReservationCustomer,
  ResourceReservationSales,
} from '@/modules/Reservations/ResourceReservation'
import { Chip } from '@/components/Chip'
import { InlineModalIconSection } from '@/components/InlineModal'
import { SalesState } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { EditButton, IconButton } from './common'
import { Section } from '../types'

type Props = {
  owner: ResourceReservationSales
  readOnly: boolean
  setCurrentSection: (section: Section) => void
}

export const Owner = ({
  owner: {
    customer,
    id,
    lifecycle: { state },
    name,
    orderNumber,
  },
  readOnly,
  setCurrentSection,
}: Props) => {
  const { palette, spacing } = useTheme()

  const openSalesInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <InlineModalIconSection icon="euro-sign">
      <FlexRow alignItems="center">
        <EditButton
          onClick={(e: MouseEvent) => {
            openSalesInNewTab(`/sales/details/${id}`)
            e.stopPropagation()
          }}
          style={{ height: '100%' }}
        >
          <FlexRow justifyContent="space-between">
            <FlexColumn alignItems="flex-start">
              <SalesWrapper alignItems="center">
                {name && <SalesName>{name}</SalesName>}

                {`#${orderNumber}`}

                <Chip
                  color={getStateColor(state)}
                  size="xs"
                  style={{ marginLeft: `${spacing.gu(1)}rem` }}
                  variant="outlined"
                >
                  <T>{`SalesDetails:Lifecycle.state.${state}`}</T>
                </Chip>
              </SalesWrapper>

              {customer?.customer && (
                <CustomerWrapper
                  alignItems="flex-start"
                  justifyContent="center"
                >
                  <FlexRow alignItems="center">
                    <FontAwesomeIcon
                      size={
                        customer.customer.__typename === 'CustomerPerson'
                          ? 'xs'
                          : 'sm'
                      }
                      color={palette.text.lighter}
                      icon={
                        customer.customer.__typename === 'CustomerPerson'
                          ? 'user'
                          : ['far', 'building']
                      }
                      style={{ marginRight: `${spacing.gu(1)}rem` }}
                    />
                    {getCustomerName(customer.customer)}
                  </FlexRow>
                  {customer.customer.publicName && (
                    <FlexRow alignItems="center">
                      <FontAwesomeIcon
                        size={
                          customer.customer.__typename === 'CustomerPerson'
                            ? 'xs'
                            : 'sm'
                        }
                        color={palette.danger.main}
                        icon="user-secret"
                        style={{ marginRight: `${spacing.gu(1)}rem` }}
                      />
                      {customer.customer.publicName}
                    </FlexRow>
                  )}
                </CustomerWrapper>
              )}
            </FlexColumn>
          </FlexRow>

          <Spacer />

          <FontAwesomeIcon
            color={palette.primary.main}
            icon="up-right-from-square"
            size="1x"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
        </EditButton>
        <IconButton
          disabled={readOnly}
          onClick={() => setCurrentSection({ type: 'SALE_SEARCH' })}
        >
          <FontAwesomeIcon
            color={readOnly ? palette.primary.lighter : palette.primary.main}
            icon="retweet"
            size="1x"
          />
        </IconButton>
      </FlexRow>
    </InlineModalIconSection>
  )
}

////////////

const getCustomerName = (customer: ResourceReservationCustomer) => {
  if (customer.__typename === 'CustomerOrganization') {
    return customer.organization.name ?? ''
  }

  if (customer.__typename === 'CustomerPerson') {
    return `${customer.person.lastName ?? ''}${
      customer.person.firstName && customer.person.lastName ? ', ' : ''
    }${customer.person.firstName ?? ''}`
  }
}

const getStateColor = (state: SalesState) => {
  switch (state) {
    case 'CANCELLED':
      return 'danger'
    case 'DRAFT':
    case 'TEMPORAL':
      return 'default'
    case 'CLOSED':
      return 'success'
    default:
      return 'primary'
  }
}

const CustomerWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.guPx(1) - 3}px;
  `}
`

const SalesName = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    max-width: ${theme.spacing.gu(32)}rem;
  `}
`

const SalesWrapper = styled(FlexRow)`
  font-weight: 500;
  cursor: pointer;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const Spacer = styled.div`
  flex: 1;
`
