import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import type { SalesProduct } from '@/modules/Products/types'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

import {
  Actions,
  CatalogSection,
  CategorySelector,
  GroupSelector,
  NameInput,
  NotesTextarea,
} from './components'

type Props = {
  product: SalesProduct
}

export const ProductSection = ({
  product: { catalog, categories, group, id, name, notes },
}: Props) => {
  const { salesReadOnly } = useSalesProductManagerContext()

  return (
    <Wrapper>
      <FlexRow alignItems="flex-start">
        <FlexColumn alignItems="stretch" flex={1}>
          <NameInput id={id} name={name} />
          <CategorySelector categories={categories} id={id} />
        </FlexColumn>

        <Divider />

        <FlexColumn alignItems="stretch" flex={1}>
          <CatalogSection catalog={catalog} />
          <GroupSelector group={group} id={id} />
        </FlexColumn>

        {!salesReadOnly && <Actions catalog={catalog} productId={id} />}
      </FlexRow>

      <NotesTextarea id={id} notes={notes} />
    </Wrapper>
  )
}

/////////

const Divider = styled(FlexColumn)`
  height: 100%;
  width: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(3)}rem 0 ${theme.spacing.gu(2)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(2)}rem ${theme.spacing.gu(4)}rem;
  `}
`
