import { Ref, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { DangerColor, PrimaryColor } from '@/components/Colors'
import { InlineModal, InlineModalFooter } from '@/components/InlineModal'
import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { Right } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useDialogService } from '@/components/DialogService'

type Props = {
  groupId: string
  onCloseModal: () => void
  readOnly: boolean
  updateReservations: (reservations: Reservation[]) => void
}
export const GroupManagerModalFooter = ({
  groupId,
  onCloseModal,
  readOnly,
  updateReservations,
}: Props) => {
  const { confirm } = useDialogService()

  const { removeGroup } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isDeleting, setIsDeleting] = useState<boolean>(false)

  useEffect(() => {
    return () => {
      setIsDeleting(false)
      onCloseModal()
    }
  }, [])

  const confirmDelete = (deleteFn: () => Promise<void>, type: string) => {
    setIsDeleting(true)

    const handleDelete = () =>
      deleteFn()
        .catch(() => undefined)
        .finally(() => setIsDeleting(false))

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>{`ReservationModal:removalConfirmation.${type}`}</T>,
    })
      .then(handleDelete)
      .catch(() => setIsDeleting(false))
  }

  const handleDeleteGroup = (groupId: string, keepReservations: boolean) => {
    const deleteFn = () => removeGroup(groupId, keepReservations)

    return confirmDelete(deleteFn, 'group')
  }

  return (
    <InlineModalFooter justifyContent="flex-end">
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <InlineModal>
            <RemoveButton
              disabled={isDeleting}
              onClick={() => handleDeleteGroup(groupId, false)}
            >
              <T>ReservationModal:action.deleteGroupAndKeepReservations</T>
            </RemoveButton>
            <RemoveButton
              disabled={isDeleting}
              onClick={() => handleDeleteGroup(groupId, true)}
            >
              <DangerColor>
                <T>ReservationModal:action.deleteGroupAndReservations</T>
              </DangerColor>
            </RemoveButton>
          </InlineModal>
        }
        onClose={() => setModalOpen(false)}
        placement="bottom-start"
        referenceElement={({ ref }) => (
          <InnocuousButton
            disabled={readOnly || isDeleting}
            innerRef={ref as Ref<HTMLButtonElement> | undefined}
            onClick={() => setModalOpen(true)}
          >
            <DangerColor>
              <T>ReservationModal:action.deleteGroup</T>
            </DangerColor>
          </InnocuousButton>
        )}
        zIndex={10003}
      />

      <Right>
        <InnocuousButton disabled={readOnly} onClick={onCloseModal}>
          <PrimaryColor>
            <T>common:action.ready</T>
          </PrimaryColor>
        </InnocuousButton>
      </Right>
    </InlineModalFooter>
  )
}

///////

const RemoveButton = styled(InnocuousButton).attrs(() => ({
  noMargin: true,
  noNudge: true,
}))`
  ${({ theme }) => css`
    justify-content: flex-start;
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}
`
