import { gql, useMutation } from '@apollo/client'

import {
  SaleCancelProcessMutation,
  SaleCancelProcessMutationVariables,
} from '~generated-types'

export type { SaleCancelProcessMutation, SaleCancelProcessMutationVariables }

export const SALE_CANCEL_PROCESS_MUTATION = gql`
  mutation SaleCancelProcess($input: SalesCancelProcessInput!) {
    salesCancelProcess(input: $input) {
      id
      locked
      lifecycle {
        state
      }
    }
  }
`

export function useSaleCancelProcessMutation() {
  return useMutation<
    SaleCancelProcessMutation,
    SaleCancelProcessMutationVariables
  >(SALE_CANCEL_PROCESS_MUTATION)
}

export default useSaleCancelProcessMutation
