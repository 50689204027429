import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import moment from 'moment'

import { AgeReference, ParticipantBirthday } from '@/modules/ParticipantsList'
import { Input, Label } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ToggleEditableValue } from './CalendarDatePicker/ToggleEditableValue'

type Props = {
  birthday: ParticipantBirthday | null
  handleSubmit: (value: AgeReference) => Promise<any>
}

export const BirthdayInputs = ({ birthday, handleSubmit }: Props) => {
  const { spacing } = useTheme()

  const [birthdayValue, setBirthdayValue] = useState<string>(
    birthday ? formatBirthday(birthday, 'YYYY-MM-DD') : ''
  )
  const [yearValue, setYearValue] = useState<string>(
    birthday?.year.toString() || ''
  )

  useEffect(() => {
    setBirthdayValue(birthday ? formatBirthday(birthday, 'YYYY-MM-DD') : '')
    setYearValue(birthday?.year.toString() || '')
  }, [JSON.stringify(birthday)])

  const onBirthdayChange = (onClose: () => void) => {
    const value = birthdayValue || null

    if (value !== (birthday ? formatBirthday(birthday, 'YYYY-MM-DD') : null)) {
      const birthdayMoment = moment(value, 'YYYY-MM-DD')

      handleSubmit({
        age: null,
        ageCategoryKey: null,
        birthday: value
          ? {
              date: parseInt(birthdayMoment.format('DD')),
              month: parseInt(birthdayMoment.format('MM')),
              year: parseInt(birthdayMoment.format('YYYY')),
            }
          : null,
      }).finally(onClose)
    }
  }

  const onYearChange = (onClose: () => void) => {
    const year = yearValue ? parseInt(yearValue, 10) : null
    const prevYear = birthday?.year || null

    if (year !== prevYear) {
      handleSubmit({
        age: null,
        ageCategoryKey: null,
        birthday: year ? { year } : null,
      }).finally(onClose)
    }
  }

  return (
    <>
      <Label>
        <T>ParticipantsList:ListHeader.dob</T>
      </Label>

      <ToggleEditableValue
        mode="input"
        placeholder="–"
        renderEditor={(onClose) => (
          <StyledDataFieldInput
            autoFocus
            type="date"
            onChange={(e) => setBirthdayValue(e.target.value || '')}
            onBlur={() => onBirthdayChange(onClose)}
            value={birthdayValue}
          />
        )}
        style={{
          height: '30px',
          margin: `${spacing.gu(1)}rem 0 ${spacing.gu(2)}rem 0`,
        }}
        value={birthday?.date ? formatBirthday(birthday) : null}
      />

      <Label>
        <T>ParticipantsList:ListHeader.year</T>
      </Label>

      <ToggleEditableValue
        disabled={!!birthday?.date || !!birthday?.month}
        mode="input"
        placeholder="–"
        renderEditor={(onClose) => (
          <StyledDataFieldInput
            autoFocus
            type="number"
            min="1900"
            max="2099"
            step="1"
            onChange={(e) => setYearValue(e.target.value || '')}
            onFocus={(e) => e.currentTarget.select()}
            onBlur={() => onYearChange(onClose)}
            value={yearValue}
          />
        )}
        style={{
          height: '30px',
          margin: `${spacing.gu(1)}rem 0 ${spacing.gu(2)}rem 0`,
        }}
        value={birthday ? formatBirthday(birthday, 'YYYY') : null}
      />
    </>
  )
}

//////

const formatBirthday = (birthday: ParticipantBirthday, format = 'DD.MM.YYYY') =>
  moment()
    .year(birthday.year)
    .month((birthday.month ?? 1) - 1)
    .date(birthday.date ?? 1)
    .format(format)

const StyledDataFieldInput = styled(Input)`
  &:not([type='checkbox']):not([type='radio']) {
    height: 30px;
    text-align: center;
    border-radius: 4px;
    flex-grow: inherit;

    ${({ theme }) => css`
      margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(2)}rem 0;
      padding: 0 ${theme.spacing.gutterSmall};
    `}
  }

  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`
