import moment from 'moment'

import { FlexRow } from '@/components/Layout'
import { FontColor } from '@/components/Typography'
import { PaymentReferenceTransferDetails as ReferenceTransferDetailsType } from '@/modules/Order/types'
import { T } from '@/modules/Language'

import { Cell, Section, SectionWithTooltip } from '../../components'

type Props = {
  date: string
  details: ReferenceTransferDetailsType
}

export const ReferenceTransferDetails = ({
  date,
  details: { notes, reference, source },
}: Props) => {
  const renderSource = () => {
    if (!source) {
      return <FontColor lighter>–</FontColor>
    }

    return source === 'MANUAL' ? <T>Orders:Payments.field.manual</T> : source
  }

  return (
    <>
      <FlexRow flex={4}>
        <SectionWithTooltip
          content={moment(date).format('dd, D.M.YYYY')}
          width={20}
          icon={['far', 'calendar']}
          title={<T>Orders:Payments.field.paymentDate</T>}
        />
        <FlexRow flex={3}>
          <SectionWithTooltip
            content={reference || <FontColor lighter>–</FontColor>}
            flex={1}
            sign="#"
            title={<T>Orders:Payments.field.reference</T>}
          />
        </FlexRow>
        <FlexRow flex={2}>
          <SectionWithTooltip
            content={renderSource()}
            flex={1}
            icon="info"
            title={<T>Orders:Payments.field.source</T>}
          />
        </FlexRow>
      </FlexRow>

      <FlexRow flex={3}>
        <Cell flex={1}>
          <Section icon={['far', 'note-sticky']}>
            {notes || <FontColor lighter>–</FontColor>}
          </Section>
        </Cell>
      </FlexRow>
    </>
  )
}
