import styled, { css } from 'styled-components/macro'
import { lighten } from 'polished'

import { Theme } from '@/theme'
import { useCalendarState } from '@/modules/Reservations'

import Cell from './Cell'
import { useReservationsGridState } from '../../../../ReservationsGridState'

type Availability = {
  available: null | number
  offset: number
  totalCapacity: null | number
}

type Props = {
  availabilityOffset: number
  resourceId: string
}

export default function AvailabilityRow({
  availabilityOffset,
  resourceId,
}: Props) {
  const { viewMode } = useCalendarState()
  const {
    calendarVariables: { COLUMN_WIDTH, RESERVATION_TIME_STEP, ROW_HEIGHT },
    resourceAvailabilities,
  } = useReservationsGridState()

  const columnCount = (24 * 60) / RESERVATION_TIME_STEP
  const targetResourceAvailabilities = resourceAvailabilities[resourceId]
  const fallbackAvailabilities = Array(columnCount)
    .fill(null)
    .map((val, idx) => ({
      available: null,
      offset: idx,
      totalCapacity: null,
    }))

  const endIndex =
    viewMode === 'WEEK' ? columnCount * (availabilityOffset + 1) : columnCount
  const startIndex = viewMode === 'WEEK' ? columnCount * availabilityOffset : 0

  const availabilities = !!targetResourceAvailabilities
    ? targetResourceAvailabilities.slice(startIndex, endIndex)
    : fallbackAvailabilities

  return (
    <Wrapper height={ROW_HEIGHT}>
      {availabilities.map(
        ({ available, offset, totalCapacity }: Availability) => (
          <Cell border key={`availability-${offset}`} width={COLUMN_WIDTH}>
            <AvailabilityCell
              availabilityRatio={(available ?? 1) / (totalCapacity ?? 1)}
            >
              {available ?? '–'}
            </AvailabilityCell>
          </Cell>
        )
      )}
    </Wrapper>
  )
}

////////////

const Wrapper = styled.div<{ height: number }>`
  display: inline-flex;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  width: 100%;

  ${({ theme }) => css`
    border-bottom: dashed 1px ${theme.palette.smoke.main};
  `}
`

const AvailabilityCell = styled.div<{ availabilityRatio: number }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}

  ${({ availabilityRatio, theme }) => colorReconciler(availabilityRatio, theme)}
`

const colorReconciler = (availabilityRatio: number, theme: Theme) => {
  if (availabilityRatio <= 0) {
    return css<any>`
      background: ${theme.palette.danger.light};
      color: ${theme.palette.text.white};
    `
  }

  if (availabilityRatio <= 0.3) {
    return css<any>`
      background: ${lighten(0.28, theme.palette.warning.main)};
      color: ${theme.palette.text.light};
    `
  }

  return css<any>`
    background: ${theme.palette.smoke.extraLight};
    color: ${theme.palette.text.light};
  `
}
