import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'

import { HeaderValue } from './HeaderValue'
import { useSalesDetailsContext } from '../../../'

export const TasksChecks = () => {
  const {
    data: { tasks },
  } = useSalesDetailsContext()

  const checkedTasks = tasks.filter((task) => !task.isOpen)

  return (
    <Wrapper alignItems="flex-start">
      <StyledLabel>
        <T>SalesDetails:Checks.title</T>
      </StyledLabel>
      <HeaderValue>{`${checkedTasks.length} / ${tasks.length}`}</HeaderValue>
    </Wrapper>
  )
}

const StyledLabel = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} 0;
    margin: 0 ${theme.spacing.gutter};
  `}
`
