import styled, { css } from 'styled-components/macro'
import React from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { SalesParticipantFullFragment, ServiceType } from '~generated-types'
import { AccommodationServiceLoading } from './AccommodationService'
import { ParticipantDayVisitorLoading } from '../ParticipantDayVisitorLine'
import { ParticipantPurchaseServiceLoading } from './PurchaseService'
import { useTheme } from '@/theme'

type Props = {
  services: SalesParticipantFullFragment['services']
  isDayVisitor: boolean
}

const ParticipantServicesLoading = ({ services, isDayVisitor }: Props) => {
  const { spacing } = useTheme()

  return (
    <FlexRow flex={1}>
      <AddNewServiceWrapper />

      <FlexColumn style={{ marginLeft: `${spacing.gu(1)}rem` }} flex={1}>
        {isDayVisitor && <ParticipantDayVisitorLoading />}
        {isDayVisitor && !!services.length && <ServiceDivider />}
        {!services.length && !isDayVisitor && <AccommodationServiceLoading />}
        {services.map((service, index) => (
          <React.Fragment key={service.id}>
            {index !== 0 && <ServiceDivider />}
            {service.type === ServiceType.ParticipantBed ? (
              <AccommodationServiceLoading />
            ) : (
              service.type === ServiceType.Purchase && (
                <ParticipantPurchaseServiceLoading />
              )
            )}
          </React.Fragment>
        ))}
      </FlexColumn>
    </FlexRow>
  )
}

export default ParticipantServicesLoading

const AddNewServiceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  width: 30px;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const ServiceDivider = styled.div`
  width: 100%;
  height: 1px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`
