import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { DataField } from '@/components/DataField'
import { Placeholder } from '@/modules/Products/components'
import { T } from '@/modules/Language'
import { useCatalogProductManagerContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

type Props = {
  code: string
  id: string
  name: string
}

export const NameSection = ({ code, id, name }: Props) => {
  const { spacing } = useTheme()
  const { updateProductItem } = useCatalogProductManagerContext()

  const handleUpdateName = (name: string) => updateProductItem({ id, name })

  return (
    <FlexColumn alignItems="flex-start" flex={1}>
      <NameWrapper>
        <DataFieldWrapper>
          <DataField
            customValueRenderer={(value) => <Label>{value}</Label>}
            handleSubmit={(value) => handleUpdateName(value || '')}
            inputType="text"
            placeholder={
              <Placeholder>
                <T>Products:ProductManager.pricing.unnamedProduct</T>
              </Placeholder>
            }
            style={{
              marginLeft: `-${spacing.guPx(1) + 1}px`,
              width: `calc(100% + ${spacing.gu(1)}rem)`,
            }}
            value={name}
          />
        </DataFieldWrapper>
      </NameWrapper>

      <Code>{code}</Code>
    </FlexColumn>
  )
}

/////////

const Code = styled.code`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}
`

const DataFieldWrapper = styled.div`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      ${({ theme }) => css`
        height: ${theme.spacing.gu(4)}rem;
      `}
    }
  }
`

const Label = styled(FlexRow)`
  align-items: center;

  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(4)}rem;
    padding: ${theme.spacing.gu(0.75)}rem ${theme.spacing.gu(0.5)}rem;
  `}
`

const NameWrapper = styled.div`
  width: 100%;

  ${({ theme }) => css`
    margin-top: -${theme.spacing.gu(1)}rem;
  `}
`
