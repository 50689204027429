import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { useTheme } from '@/theme'

type Props = {
  icon: IconProp
  large?: boolean
}

export const Icon = ({ icon, large }: Props) => {
  const theme = useTheme()

  return (
    <FontAwesomeIcon
      fixedWidth
      icon={icon}
      style={{
        color: theme.palette.text.light,
        fontSize: `${
          large ? theme.typography.fontSizeBase2 : theme.typography.fontSizeBase
        }`,
        marginTop: `${theme.spacing.gu(1)}rem`,
        width: `${theme.spacing.gu(3)}rem`,
      }}
    />
  )
}
