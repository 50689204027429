import { ChangeEvent, Ref, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  SalesProductUpdateInput,
  UpdatePublishedProductMutation,
} from '~generated-types'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FontColor } from '@/components/Typography'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { Textarea } from '@/components/FormControls'
import { useTheme } from '@/theme'

import { TruncateContent } from '../../common'

type Props = {
  description: string | null
  productId: string
  readOnly: boolean
  updateProduct: (
    input: SalesProductUpdateInput
  ) => Promise<UpdatePublishedProductMutation | null | undefined>
}

export const ProductDescription = ({
  description,
  productId,
  readOnly,
  updateProduct,
}: Props) => {
  const { spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [value, setValue] = useState<string>(description ?? '')

  const handleChangeDescription = (description: string) =>
    updateProduct({ id: productId, settings: { description } })

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <InlineModalSection style={{ margin: 0, padding: spacing.gutter }}>
            <Textarea
              disabled={readOnly}
              onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                setValue(e.target.value)
              }
              autoFocus
              value={value}
              onBlur={() => !readOnly && handleChangeDescription(value)}
              style={{ height: 200, width: 360 }}
            />
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          disabled={readOnly}
          onClick={() => setModalOpen(true)}
          ref={ref as Ref<HTMLButtonElement> | undefined}
        >
          <TruncateContent
            linesToShow={1}
            content={
              description || (
                <FontColor lighter>
                  <ContentPlaceholder>
                    <T>Publish:Products.addDescription</T>
                  </ContentPlaceholder>
                </FontColor>
              )
            }
          />
        </Button>
      )}
      zIndex={10003}
    />
  )
}

////////////

const Button = styled.button<{ noMargin?: boolean }>`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;

  ${({ theme, noMargin }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.text.light};
    height: ${theme.spacing.gu(4)}rem;
    min-width: ${theme.spacing.gu(30)}rem;
    ${noMargin ? '' : `margin-right: ${theme.spacing.gu(2)}rem;`}
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:disabled {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
      color: ${theme.palette.text.light};
      cursor: not-allowed;
    `}
  }

  &:hover:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.lighter};
    `}
  }

  &:active:not([disabled]) {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }
`

const ContentPlaceholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.smoke.extraDark};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
