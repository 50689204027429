import { parse, stringify } from 'query-string'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import React from 'react'

interface UseRouteValueHook {
  setValue: (targetValue: string | string[] | null) => void
  value: string | string[] | null
}

interface UseRouteValueProps {
  /**
   * A default selection to use if initial selection is empty
   */
  defaultSelection?: string | string[]
  /**
   * Are multiple values allowed? Default: false
   */
  multi?: boolean
  /**
   * The key to store the value with into the route
   */
  routeKey: string
  /**
   * A list of allowed values. If there are no restrictions, everything is
   * allowed.
   */
  whitelist?: string[]
}

function useRouteValue({
  defaultSelection,
  multi,
  routeKey,
  whitelist,
}: UseRouteValueProps): UseRouteValueHook {
  const history = useHistory()
  const { search } = useLocation()
  const { path } = useRouteMatch()
  const { [routeKey]: searchValue } = parse(search)

  const filterValue = (
    inputValue: string | (string | null)[] | null
  ): string | string[] | null => {
    const input = Array.isArray(inputValue)
      ? (inputValue.filter(Boolean) as string[])
      : inputValue

    if (!input || !input.length) {
      return null
    }

    if (multi) {
      const all: string[] = Array.isArray(input) ? input : [input]
      const filtered = whitelist
        ? all.filter((x) => whitelist.includes(x))
        : all

      return filtered.length ? filtered : null
    } else {
      const value: string = Array.isArray(input) ? input[0] : input
      const filtered = !whitelist
        ? value
        : whitelist.includes(value)
        ? value
        : null

      return !!filtered ? filtered : null
    }
  }

  const handleSetValue = (targetValue: string | string[] | null) => {
    const filteredValue = filterValue(targetValue)
    const { [routeKey]: oldValue, ...others } = parse(search)

    history.replace(
      `${path}?${stringify(
        filteredValue
          ? {
              ...others,
              [routeKey]: filteredValue,
            }
          : { ...others }
      )}`
    )
  }

  const value = filterValue(searchValue)

  React.useEffect(() => {
    if (value === null && defaultSelection) {
      handleSetValue(defaultSelection)
    }
  }, [])

  return {
    setValue: handleSetValue,
    value,
  }
}

export default useRouteValue
