import {
  FetchedParticipant,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'

import {
  Address,
  Allergy,
  EducationAndLifeStage,
  Note,
  Request,
  SaleGroup,
} from './modules'
import { FlexRow } from '@/components/Layout'

type Props = {
  participant: FetchedParticipant
  readOnly?: boolean
}

const ParticipantPersonalInfo = ({
  participant: {
    id,
    education,
    language,
    lifeStage,
    nationality,
    additionalInfo,
    accommodationRequest,
    allergyDescription,
    drugAllergyDescription,
    roomFriends,
    allergies,
    diets,
    postalAddress,
    group,
  },
  readOnly,
}: Props) => {
  const { salesId, handleChangeParticipantData, handleSetRoomFriends } =
    useParticipantsListContext()

  return (
    <FlexRow flex={1} style={{ minHeight: 35, width: '100%' }}>
      <Address
        address={postalAddress}
        nationality={nationality ?? ''}
        participantLanguage={language ?? ''}
        handleUpdateAddress={(postalAddress) =>
          handleChangeParticipantData({ postalAddress }, id)
        }
        handleUpdateLanguage={(language) =>
          handleChangeParticipantData({ language }, id)
        }
        handleUpdateNationality={(nationality) =>
          handleChangeParticipantData({ nationality }, id)
        }
        readOnly={readOnly}
      />
      <EducationAndLifeStage
        education={education}
        lifeStage={lifeStage}
        handleUpdateEducation={(education) =>
          handleChangeParticipantData({ education }, id)
        }
        handleUpdateLifeStage={(lifeStage) =>
          handleChangeParticipantData({ lifeStage }, id)
        }
        readOnly={readOnly}
      />
      <Request
        note={accommodationRequest ?? ''}
        roomFriends={roomFriends}
        readOnly={readOnly}
        handleUpdateNote={(note) =>
          handleChangeParticipantData({ accommodationRequest: note }, id)
        }
        handleUpdateRoomFriends={(roomFriends) =>
          handleSetRoomFriends({ id, roomFriends })
        }
      />
      <Allergy
        allergyDescription={allergyDescription ?? ''}
        drugAllergyDescription={drugAllergyDescription ?? ''}
        specialDietaryNeeds={allergies}
        diets={diets}
        readOnly={readOnly}
        handleUpdateDrugAllergy={(drugAllergyDescription) =>
          handleChangeParticipantData({ drugAllergyDescription }, id)
        }
        handleUpdateNote={(allergyDescription) =>
          handleChangeParticipantData({ allergyDescription }, id)
        }
        handleUpdateDietary={(specialDietaryNeeds) =>
          handleChangeParticipantData({ allergies: specialDietaryNeeds }, id)
        }
        handleUpdateDiets={(diets) =>
          handleChangeParticipantData({ diets }, id)
        }
      />
      <SaleGroup
        salesId={salesId}
        group={group}
        readOnly={readOnly}
        handleUpdateGroup={(group) =>
          handleChangeParticipantData({ group }, id)
        }
      />
      <Note
        note={additionalInfo ?? ''}
        readOnly={readOnly}
        handleUpdateNote={(additionalInfo) =>
          handleChangeParticipantData({ additionalInfo }, id)
        }
      />
    </FlexRow>
  )
}

export default ParticipantPersonalInfo
