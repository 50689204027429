import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

type Props = {
  children?: ReactNode
  size?: Size
}

const ContentPlaceholder = styled.div<Props>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
    color: ${theme.palette.text.light};
  `}

  ${({ size }) => sizeReconciler(size)}
`

export default ContentPlaceholder

////////////

const sizeReconciler = (size?: Size) => {
  switch (size) {
    case 'xs':
      return css`
        ${({ theme }) => css`
          font-size: ${theme.typography.fontSizeSmaller};
          min-height: ${theme.spacing.gu(2)}rem;
          padding: ${theme.spacing.gutterSmall};
        `}
      `
    case 'sm':
      return css`
        ${({ theme }) => css`
          font-size: ${theme.typography.fontSizeSmall};
          min-height: ${theme.spacing.gu(3)}rem;
          padding: ${theme.spacing.gutterSmall};
        `}
      `
    case 'lg':
      return css`
        ${({ theme }) => css`
          font-size: ${theme.typography.fontSizeBigger};
          min-height: ${theme.spacing.gu(12)}rem;
        `}
      `
    case 'xl':
      return css`
        ${({ theme }) => css`
          font-size: ${theme.typography.fontSizeLarge};
        `}

        min-height: 40vh;
      `
    case 'md':
    default:
      return css`
        ${({ theme }) => css`
          font-size: ${theme.typography.fontSizeBase2};
          min-height: ${theme.spacing.gu(6)}rem;
        `}
      `
  }
}
