import { gql, useMutation } from '@apollo/client'

import ResourceReservationFragment from '../fragments/resourceReservation'

import {
  ReservationsDateOffsetMutation,
  ReservationsDateOffsetMutationVariables,
} from '~generated-types'

export type {
  ReservationsDateOffsetMutation,
  ReservationsDateOffsetMutationVariables,
}

export const RESERVATIONS_DATE_OFFSET = gql`
  ${ResourceReservationFragment}

  mutation ReservationsDateOffset($input: ResourceReservationUpdateAllInput!) {
    resourceReservationUpdateAll(input: $input) {
      reservations {
        ...ResourceReservation
      }
    }
  }
`

export function useReservationsDateOffset() {
  return useMutation<
    ReservationsDateOffsetMutation,
    ReservationsDateOffsetMutationVariables
  >(RESERVATIONS_DATE_OFFSET)
}

export default useReservationsDateOffset
