import { gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'

import {
  UseArrivalsForDateQuery as QueryData,
  UseArrivalsForDateQueryVariables as QueryVariables,
  SalesType,
  UseArrivalsForDateBedServiceFragment,
  UseArrivalsForDateCustomerFragment,
  UseArrivalsForDateParticipantFragment,
  UseArrivalsForDateRoomReservationFragment,
} from '~generated-types'
import { generateCompareFn } from '@/utils/arrays'
import { ListingRoomFeatureFragment } from '@/modules/Listing/fragments'
import { OPEN_STATES } from '@/modules/Sales'

export type ArrivalsCustomer = UseArrivalsForDateCustomerFragment
export type ArrivalsDates = UseArrivalsForDateBedServiceFragment['dates']
export type ArrivalsParticipant = UseArrivalsForDateParticipantFragment
export type ArrivalsRoomReservation = UseArrivalsForDateRoomReservationFragment
export type ArrivalsSalesFacet = QueryData['arrivingSalesForDate'][0]['facet']
export type ArrivalsSalesPaymentAgreement =
  QueryData['arrivingSalesForDate'][0]['paymentAgreement']
export type CheckIn = NonNullable<ArrivalsDates>['checkIn']
export type CheckOut = NonNullable<ArrivalsDates>['checkOut']

const QUERY = gql`
  ${ListingRoomFeatureFragment}

  fragment UseArrivalsForDateCustomer on Customer {
    customerNumber
    id

    ... on CustomerOrganization {
      organization {
        businessId
        name
      }
    }

    ... on CustomerPerson {
      person {
        email
        firstName
        lastName
        phone
      }
    }
  }

  fragment UseArrivalsForDateRoomReservation on RoomReservation {
    id
    request {
      beds
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
      extraBeds
      features {
        ...ListingRoomFeature
      }
      info
      room {
        beds
        building {
          id
          name
        }
        extraBeds
        id
        number
      }
    }
    target {
      id
      name
    }
  }

  fragment UseArrivalsForDateBedService on ServiceParticipantBed {
    accommodationTarget {
      id
      name
    }
    dates {
      checkIn {
        date
        type
      }
      checkOut {
        date
        type
      }
    }
    participantRoom {
      id
      roomReservation {
        id
      }
    }
    purchaseProduct {
      id
      product {
        id
        name
      }
      totalPrice {
        amount
        amountVatIncluded
        vatAmount
      }
    }
  }

  fragment UseArrivalsForDateParticipant on SalesParticipant {
    ageCategory {
      key
      shortName
    }
    additionalInfo
    firstName
    id
    lastName
    sales {
      id
      orderNumber
      paymentAgreement {
        id
        name
        code
      }
    }
    services {
      id
      ... on ServiceParticipantBed {
        ...UseArrivalsForDateBedService
      }
    }
  }

  query UseArrivalsForDate(
    $input: SalesForDateInput!
    $enrollmentStates: [SalesState!]
  ) {
    arrivingSalesForDate(input: $input) {
      accommodation {
        id
        roomReservations(includeRoomTypeRooms: true) {
          ...UseArrivalsForDateRoomReservation
        }
      }
      customer {
        customer {
          ...UseArrivalsForDateCustomer
        }
      }
      facet {
        id
        name
        abbreviation
        color
        dimensionTarget
        features {
          feature
          defaultVisibility
        }
      }
      id
      lifecycle {
        state
      }
      name
      orderNumber
      participantConnection(
        input: {
          filter: { enrollmentStates: $enrollmentStates }
          pagination: { size: 10000 }
        }
      ) {
        nodes {
          ...UseArrivalsForDateParticipant
        }
      }
      paymentAgreement {
        id
        name
        code
      }
      type
    }
  }
`

interface UseArrivalsForDateProps {
  date: Moment
}

export interface ArrivalsEvent {
  customer: ArrivalsCustomer | null
  facet: ArrivalsSalesFacet
  id: string
  name: string | null
  orderNumber: number | null
  participants: ArrivalsParticipant[]
  paymentAgreement: ArrivalsSalesPaymentAgreement | null
  roomsById: Record<string, ArrivalsRoomReservation>
  type: SalesType.Event
}

export interface ArrivalsSales {
  customer: ArrivalsCustomer | null
  facet: ArrivalsSalesFacet
  id: string
  name: string | null
  orderNumber: number | null
  participants: ArrivalsParticipant[]
  paymentAgreement: ArrivalsSalesPaymentAgreement | null
  roomsById: Record<string, ArrivalsRoomReservation>
  type: SalesType.Sales
}

interface UseArrivalsForDateHook {
  error: boolean
  events: Readonly<ArrivalsEvent[]>
  loading: boolean
  sales: Readonly<ArrivalsSales[]>
}

export const useArrivalsForDate = ({
  date,
}: UseArrivalsForDateProps): UseArrivalsForDateHook => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      enrollmentStates: OPEN_STATES,
      input: {
        date: date.format('YYYY-MM-DD'),
      },
    },
  })

  const parsedEvents: ArrivalsEvent[] = []
  const parsedSales: ArrivalsSales[] = []

  data?.arrivingSalesForDate
    .filter(({ lifecycle }) => OPEN_STATES.includes(lifecycle.state))
    .forEach(
      ({
        accommodation,
        facet,
        id,
        name,
        orderNumber,
        paymentAgreement,
        type,
        ...rest
      }) => {
        const roomsById = accommodation.roomReservations.reduce(
          (acc: Record<string, ArrivalsRoomReservation>, val) => ({
            ...acc,
            [val.id]: val,
          }),
          {}
        )

        if (type === SalesType.Event) {
          parsedEvents.push({
            customer: rest.customer?.customer || null,
            facet,
            id,
            name,
            orderNumber,
            participants: rest.participantConnection.nodes,
            paymentAgreement,
            roomsById,
            type,
          })
        } else if (type === SalesType.Sales) {
          parsedSales.push({
            customer: rest.customer?.customer || null,
            facet,
            id,
            name,
            orderNumber,
            participants: rest.participantConnection.nodes,
            paymentAgreement,
            roomsById,
            type,
          })
        } else {
          console.warn(`Unsupported type [${type}]`)
        }
      }
    )

  const salesComparator = generateCompareFn(['name', 'id'])

  return {
    error: !!error,
    events: Object.freeze([...parsedEvents.sort(salesComparator)]),
    loading,
    sales: parsedSales.sort((a, b) => {
      const customerOrder = getCustomerName(a.customer).localeCompare(
        getCustomerName(b.customer)
      )

      return customerOrder === 0 ? salesComparator(a, b) : customerOrder
    }),
  }
}

////////////

const getCustomerName = (customer: ArrivalsCustomer | null): string => {
  if (!customer) {
    return ''
  }

  if (customer.__typename === 'CustomerOrganization') {
    return customer.organization.name || ''
  } else {
    return `${customer.person.lastName}${customer.person.firstName}`
  }
}
