import { ChangeEvent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FetchedParticipant } from '@/modules/ParticipantsList'
import { FlexRow } from '@/components/Layout'
import { InlineModalLine } from '@/components/InlineModal'
import { Input } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  title?: React.ReactNode
  note: string
  handleUpdateNote: (note: string) => Promise<FetchedParticipant | undefined>
}

const ModalNoteEditor = ({ title, note, handleUpdateNote }: Props) => {
  const { palette } = useTheme()

  const [noteValue, setNote] = useState(note)

  useEffect(() => {
    note !== noteValue && setNote(note)
  }, [note])

  return (
    <>
      <Wrapper>
        <IconWrapper>
          <FontAwesomeIcon
            color={noteValue ? palette.warning.dark : palette.text.light}
            icon={['far', 'note-sticky']}
            size="sm"
          />
        </IconWrapper>
        <TextareaWrapper>
          {title && <Title>{title}</Title>}
          <Textarea
            height={120}
            onBlur={() => handleUpdateNote(noteValue)}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setNote(e.target.value)
            }
            value={noteValue}
          />
        </TextareaWrapper>
      </Wrapper>

      <InlineModalLine />
    </>
  )
}

export default ModalNoteEditor

/////////

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.guPx(1) + 2}px;
    width: ${theme.spacing.gu(7)}rem;
  `}
`

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))<any>`
  &&& {
    line-height: 1.2;
    resize: vertical;
    min-height: ${({ height }) => height}px;

    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
      border-color: ${theme.palette.smoke.dark};
    `}

    ::placeholder {
      font-style: italic;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }
  }
`

const TextareaWrapper = styled.div`
  flex: 1;

  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem 0
      ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.div`
  font-weight: 500;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.guPx(1) + 1}px;
    margin-bottom: ${theme.spacing.guPx(1) + 1}px;
  `}
`
