import { gql, useQuery } from '@apollo/client'

import {
  UseSalesResourceReservationsForGroupQuery as QueryData,
  UseSalesResourceReservationsForGroupQueryVariables as QueryVariables,
} from '~generated-types'

import { SalesResourceReservationForGroup } from '../types'
import SalesResourceReservationFragmentForGroup from '../fragments/salesResourceReservationForGroup'

const QUERY = gql`
  ${SalesResourceReservationFragmentForGroup}

  query UseSalesResourceReservationsForGroup($id: ID!) {
    sales(id: $id) {
      id
      resourceReservations {
        ...SalesResourceReservationForGroup
      }
    }
  }
`

type Params = {
  salesId: string
}

export function useSalesResourceReservationsForGroup({ salesId }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { id: salesId },
    }
  )

  const reservations: SalesResourceReservationForGroup[] = data
    ? data.sales.resourceReservations || []
    : []

  return {
    error,
    loading,
    refetch,
    reservations,
  }
}

export default useSalesResourceReservationsForGroup
