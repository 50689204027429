import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { ConditionRuleType, QuantityUnit } from '~generated-types'
import { translate, useLanguageContext } from '@/modules/Language'
import { DataTableInput } from '@/components/DataTable'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  id: string
  quantity: number
  quantityUnit: QuantityUnit
}

export const QuantityInput = ({ id, quantity, quantityUnit }: Props) => {
  const { language } = useLanguageContext()
  const { readOnly, updateProductItemRule } = useSalesProductManagerContext()

  const [value, setValue] = useState<string>(`${quantity}`)

  const handleUpdateRuleValue = () => {
    const quantityValue = Number(value) || 1

    setValue(`${quantityValue}`)

    return updateProductItemRule({
      condition: {
        quantity: quantityValue,
        ruleType: ConditionRuleType.Quantity,
      },
      productRuleId: id,
    }).then((res) => !res && setValue(`${quantity}`))
  }

  return (
    <Wrapper>
      <Input
        disabled={readOnly}
        onBlur={handleUpdateRuleValue}
        onChange={(e) => setValue(e.target.value)}
        onFocus={(e) => e.currentTarget.select()}
        endAdornment={`${translate(
          `enums:quantityUnit.abbrev.${quantityUnit}`,
          language
        )}`}
        showBorder
        flex="unset"
        type="number"
        value={value}
      />
    </Wrapper>
  )
}

/////////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: end;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0 ${theme.spacing.gu(5.5)}rem 0 ${theme.spacing.gu(1)}rem;
      width: ${theme.spacing.gu(10.5)}rem;
    `}

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}

  span {
    ${({ theme }) => css`
      width: ${theme.spacing.gu(5.5)}rem;
      padding-right: ${theme.spacing.gu(0.5)}rem;
    `}
  }
`
