import { createContext, ReactNode, useContext, useRef, useState } from 'react'

import { Payment } from '@/modules/Order/types'

type StateType<T> = T | ((data: T) => T)

type ContextType = {
  observablePayment: Payment | null
  setObservablePayment: (payment: StateType<Payment | null>) => void
}

const ObservablePaymentContext = createContext<ContextType>({
  observablePayment: null,
  setObservablePayment: () => undefined,
})

type Props = {
  children: ReactNode
}

export const ObservablePaymentProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const [observablePayment, setObservablePayment] = useState<Payment | null>(
    null
  )

  contextValueRef.current = {
    observablePayment,
    setObservablePayment,
  }

  return (
    <ObservablePaymentContext.Provider value={contextValueRef.current}>
      {children}
    </ObservablePaymentContext.Provider>
  )
}

export const useObservablePayment = () => useContext(ObservablePaymentContext)
