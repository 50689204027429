import styled, { css } from 'styled-components/macro'

import { OrderItemParticipant as Participant } from '@/modules/Order/types'
import { T } from '@/modules/Language'

export const getParticipantName = (participant: Participant | null) => {
  if (!participant) {
    return '–'
  }

  const { firstName, lastName } = participant

  return firstName || lastName ? (
    `${firstName}${firstName && lastName ? ' ' : ''}${lastName}`
  ) : (
    <Placeholder>
      <T>ParticipantsList:unnamedParticipant</T>
    </Placeholder>
  )
}

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
