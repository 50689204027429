import { useEffect, useState } from 'react'

import {
  CalendarResourcesService,
  CalendarResourceElastic as Resource,
} from '@/modules/Registry'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ById } from '@/common/types'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { generateCompareFn } from '@/utils/arrays'
import { useRouteValue } from '@/utils/hooks'

type Props = {
  setTargetResource: (resource: Resource | null) => void
}

export const ResourceSelector = ({ setTargetResource }: Props) => {
  const { language } = useLanguageContext()

  const { setValue: setTargetResourceId, value: targetResourceId } =
    useRouteValue({
      multi: false,
      routeKey: 'resource',
    })

  const [resourcesById, setResourcesById] = useState<ById<Resource>>({})
  const [isFetching, setFetching] = useState<boolean>(true)

  useEffect(() => {
    CalendarResourcesService.findAll()
      .then((resources) => {
        setResourcesById(
          resources.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
        )
        setTargetResource(
          resources.find(({ id }) => id === targetResourceId) ?? null
        )
      })
      .catch((err) => console.warn('Failed to retrieve all resources', err))
      .finally(() => setFetching(false))
  }, [])

  const selectorOptions = Object.values(resourcesById)
    .sort(generateCompareFn('name'))
    .map(({ id, name }) => ({
      label:
        name || translate('ResourceReservations:unnamedResource', language),
      searchValue: name || '',
      value: id,
    }))

  const selectorValue = targetResourceId
    ? selectorOptions.find(({ value }) => targetResourceId === value)
    : null

  const handleSetTargetResource = (resourceId: string) => {
    setTargetResourceId(resourceId)
    setTargetResource(resourcesById[resourceId])
  }

  return (
    <FilterSection
      label={<T>ResourceReservationsCalendar:ResourceSelector.title</T>}
      render={() => (
        <ThemedSelect
          blurInputOnSelect
          isLoading={isFetching}
          isSearchable
          loadingMessage={() => (
            <T>ResourceReservationsCalendar:ResourceSelector.loading</T>
          )}
          name="resource-selector"
          noOptionsMessage={() => (
            <T>ResourceReservationsCalendar:ResourceSelector.empty</T>
          )}
          onChange={(target: Option | null | undefined) => {
            if (target && target.value) {
              handleSetTargetResource(target.value)
            }
          }}
          options={selectorOptions}
          placeholder={
            <T>ResourceReservationsCalendar:ResourceSelector.placeholder</T>
          }
          value={selectorValue}
        />
      )}
    />
  )
}
