import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  Feature,
  PurchaseProductAction as PA,
  UseResourceReservationQuery,
} from '~generated-types'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { formatCurrency } from '@/utils/currency'
import { InlineModalIconSection } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { ProductPurchase } from '@/modules/Products/types'
import { ResourceReservationSales } from '@/modules/Reservations/ResourceReservation'
import { SalesProductManager } from '@/modules/Products'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import { ActionButton, EditButton, IconWrapper, Placeholder } from './common'
import { Context, Section } from '../types'

type Props = {
  context: Context
  purchase: ProductPurchase | null
  readOnly: boolean
  refetchReservation: () => Promise<
    ApolloQueryResult<UseResourceReservationQuery>
  >
  sales: ResourceReservationSales
  setCurrentSection: (section: Section) => void
}

// TODO Purchase quantity

export const Purchase = ({
  context,
  purchase,
  readOnly,
  refetchReservation,
  sales: { commissionRate, facet, id: salesId, seller, type: salesType },
  setCurrentSection,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isHover, setHover] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState(false)

  const isCommissionEnabled = !!facet.features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const renderPurchase = ({
    id,
    product: { id: productId, name },
    // quantityBreakdown: { durationUnit, quantity },
    status: { issues, validatedActions },
    totalPrice: { amountVatIncluded },
  }: ProductPurchase) => {
    const deleteAction = validatedActions.find((a) => a.action === PA.Delete)

    const handleChangePurchase = () =>
      setCurrentSection({ type: 'RESERVATION_PURCHASE_SEARCH' })

    const handleCloseModal = () => {
      setModalOpen(false)
      refetchReservation()
    }

    return (
      <FlexRow
        alignItems="center"
        flex={1}
        justifyContent="space-between"
        style={{ width: '100%' }}
      >
        <ModalContainer
          isOpen={isModalOpen}
          modal={
            <SalesProductManager
              commission={commission}
              context={context}
              onClose={handleCloseModal}
              productId={productId}
              readOnly={readOnly}
              salesId={salesId}
              salesType={salesType}
              sellerId={seller?.id}
              targetPurchaseId={id}
            />
          }
          onClose={handleCloseModal}
          placement="bottom"
          referenceElement={({ ref }) => (
            <StyledEditButton
              onClick={() => setModalOpen(true)}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              ref={ref as Ref<HTMLButtonElement>}
            >
              <PurchaseName>
                {name ? (
                  <>
                    {issues.map(({ code, key, level }) => (
                      <ValidationIndicatorWrapper key={`${key}-${code}`}>
                        <ValidationIndicator
                          code={code}
                          level={level}
                          style={{ margin: `0 ${spacing.gutterSmall}` }}
                        />
                      </ValidationIndicatorWrapper>
                    ))}

                    <Tooltip
                      content={name}
                      delay={300}
                      trigger={(triggerProps) => (
                        <span {...triggerProps}>{name}</span>
                      )}
                    />
                  </>
                ) : (
                  <Placeholder>
                    <T>Products:ProductManager.pricing.unnamedPurchase</T>
                  </Placeholder>
                )}
              </PurchaseName>
            </StyledEditButton>
          )}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />

        {/* {durationUnit !== 'PIECE' && (
          <DurationField
            duration={quantity.duration}
            durationUnit={durationUnit}
            id={id}
            readOnly={readOnly}
            setDurantion={(duration: string | null, id: string) =>
              setReservationPurchaseDuration(duration, id, reservationId)
            }
            valueStyle={{ color: palette.text.light }}
          />
        )}

        {isPooled && durationUnit !== 'PIECE' && (
          <MultiplyLabel>x</MultiplyLabel>
        )}

        {isPooled && (
          <QuantityField
            id={id}
            quantity={quantity.resourceQuantity}
            readOnly={readOnly}
            setQuantity={(quantity: string | null, id: string) =>
              setReservationPurchaseQuantity(quantity, id, reservationId)
            }
            valueStyle={{ color: palette.text.light }}
          />
        )} */}

        <PurchaseTotal semiBold>
          {formatCurrency(amountVatIncluded || 0)} €
        </PurchaseTotal>

        {deleteAction && deleteAction.valid && (
          <ActionButton
            content={<T>ReservationModal:action.changeProduct</T>}
            icon="retweet"
            iconColor={palette.primary.main}
            onClick={handleChangePurchase}
          />
        )}
      </FlexRow>
    )
  }

  const renderPlaceholder = () => (
    <EditButton
      disabled={readOnly}
      onClick={() => setCurrentSection({ type: 'RESERVATION_PURCHASE_SEARCH' })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Placeholder>
        <T>ReservationModal:action.addProduct</T>
      </Placeholder>

      <IconWrapper>
        {isHover && (
          <FontAwesomeIcon color={palette.primary.main} icon="plus" size="sm" />
        )}
      </IconWrapper>
    </EditButton>
  )

  return (
    <InlineModalIconSection icon="cube">
      <FlexRow>
        {purchase ? renderPurchase(purchase) : renderPlaceholder()}
      </FlexRow>
    </InlineModalIconSection>
  )
}

////////////

// const MultiplyLabel = styled.span`
//   font-weight: 500;

//   ${({ theme }) => css`
//     color: ${theme.palette.text.lighter};
//     margin: 0 ${theme.spacing.gu(1)}rem;
//   `}
// `

const PurchaseTotal = styled(FontWeight)`
  align-self: center;
  text-align: end;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(14)}rem;
  `}
`

const PurchaseName = styled.span`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`

const StyledEditButton = styled(EditButton)`
  text-align: left;

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(24)}rem;
    width: ${theme.spacing.gu(24)}rem;
  `}
`
