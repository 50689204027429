import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { formatCurrency } from '@/utils/currency'
import { OrderType as Order } from '@/modules/Order/types'
import { useTheme } from '@/theme'

import { HeaderValue } from './HeaderValue'
import { useSalesDetailsContext } from '../../../'

export const SalesTotal = () => {
  const { palette } = useTheme()
  const { ordersById, ordersError, ordersLoading } = useSalesDetailsContext()

  return (
    <Wrapper alignItems="flex-start" noPadding>
      <ValueWrapper>
        {ordersLoading ? (
          <ReactLoading
            type="spin"
            height={24}
            width={24}
            color={palette.smoke.main}
          />
        ) : ordersError ? (
          '–'
        ) : (
          <HeaderValue style={{ whiteSpace: 'nowrap' }}>
            <FontWeight>€ </FontWeight>
            {formatCurrency(getOrdersTotal(Object.values(ordersById)))}
          </HeaderValue>
        )}
      </ValueWrapper>
    </Wrapper>
  )
}

////////////

const getOrdersTotal = (orders: Order[]) =>
  +orders
    ?.reduce(
      (acc: number, order: Order) =>
        acc + order.paymentInfo.totalPrice.amountVatIncluded,
      0
    )
    .toFixed(2)

const ValueWrapper = styled.div`
  height: 30;
  display: inline-flex;
  align-items: center;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter};
  `}
`
