import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ListItemSectionIconValue as IconValue,
  ListItemSection as Section,
} from '@/components/List'
import { ElasticSalesContext } from '@/modules/Sales'
import { FontWeight } from '@/components/Typography'
import { useTheme } from '@/theme'

import { ElasticRootEvent } from '../Invoices.types'

const DATE_FORMAT = 'dd DD.MM.YY'

type ListItemProps = {
  sales: ElasticSalesContext | ElasticRootEvent
  onClick: (id: string) => void
}

const SalesLink = ({ sales, onClick }: ListItemProps) => {
  const theme = useTheme()

  return (
    <SalesLinkSection
      onClick={(e) => {
        e.stopPropagation()
        onClick(sales.id)
      }}
    >
      <FlexRow alignItems="center" justifyContent="flex-start">
        {(sales as ElasticSalesContext)?.searchDates && (
          <DatesWrapper alignItems="flex-start">
            <IconValue
              icon="right-to-bracket"
              value={moment(
                (sales as ElasticSalesContext).searchDates?.start
              ).format(DATE_FORMAT)}
            />
            <IconValue
              icon="right-from-bracket"
              value={moment(
                (sales as ElasticSalesContext).searchDates?.end
              ).format(DATE_FORMAT)}
            />
          </DatesWrapper>
        )}
        <FlexColumn alignItems="flex-start" justifyContent="center" noPadding>
          <small>
            <span style={{ paddingRight: theme.spacing.gutterSmall }}>
              #{sales.number}
            </span>
            {' | '}
            <FacetLabel bold>
              {(sales as ElasticSalesContext)?.facet?.abbreviation || 'TAP'}
            </FacetLabel>
            <FontAwesomeIcon
              size="sm"
              icon="up-right-from-square"
              style={{
                marginBottom: 1,
                marginLeft: `${theme.spacing.gu(1)}rem`,
              }}
              color={theme.palette.primary.dark}
            />
          </small>
          {sales.name && <Title>{sales.name}</Title>}
        </FlexColumn>
      </FlexRow>
    </SalesLinkSection>
  )
}

export default SalesLink

////////////

const SalesLinkSection = styled(Section)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}

  &:hover {
    opacity: 0.6;

    ${({ theme }) => css`
      background-color: ${theme.palette.white};
    `}
  }
`

const FacetLabel = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeSmall};
    margin-left: ${theme.spacing.gutterSmall};
  `}
`

const Title = styled.div`
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const DatesWrapper = styled(FlexColumn)`
  width: 100px;
  white-space: nowrap;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
