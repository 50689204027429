import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
}

export const InlineModalFooter = ({ children, justifyContent }: Props) => (
  <Wrapper justifyContent={justifyContent}>{children}</Wrapper>
)

////////////

const Wrapper = styled.div<{ justifyContent?: string }>`
  display: flex;
  align-items: baseline;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
    border-top: solid 1px ${theme.palette.smoke.main};
  `}

  ${({ justifyContent }) =>
    justifyContent ? `justify-content: ${justifyContent};` : ''}
`
