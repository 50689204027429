import { Ref, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components/macro'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { FontColor } from '@/components/Typography'
import { ModalContainer } from '@/components/Modal'

import { EditButton, Subsection } from './components'
import { DateDurationModal } from './DateDurationModal'

type Props = {
  date: string | null
  duration: number | null
  durationOptions: number[]
  isCompact?: boolean
  readOnly: boolean
  setDate:
    | ((
        dueDate: string | null,
        dueDateDuration: number | null
      ) => Promise<void | null | undefined>)
    | ((
        validTo: string | null,
        validToDuration: number | null
      ) => Promise<void | null | undefined>)
  type: 'DUE_DATE' | 'VALID_TO'
  valueDate?: string | null
}

export const DateDurationSelector = ({
  date,
  duration,
  durationOptions,
  isCompact = false,
  readOnly,
  setDate,
  type,
  valueDate,
}: Props) => {
  const { language } = useLanguageContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  const initialDate = valueDate ? moment(valueDate) : moment().startOf('day')
  const isDurationMode = !!duration || (!date && !duration)

  const renderDateLabel = () =>
    date ? (
      <FlexRow flex={1} style={{ justifyContent: 'flex-start' }}>
        {moment(date).format('dd, D.M.YYYY')}
        <FontColor lighter>
          <Dash>–</Dash>
          {translate('Orders:DateDurationManager.day', language, {
            count: moment(date).diff(initialDate, 'days'),
          })}
        </FontColor>
      </FlexRow>
    ) : (
      <FontColor lighter>
        <T>Orders:DateDurationManager.empty</T>
      </FontColor>
    )

  const renderDurationLabel = () =>
    duration ? (
      <FlexRow flex={1} style={{ justifyContent: 'flex-start' }}>
        {translate('Orders:DateDurationManager.day', language, {
          count: duration,
        })}
        <FontColor lighter>
          <Dash>–</Dash>
          {initialDate.clone().add(duration, 'd').format('dd, D.M.YYYY')}
        </FontColor>
      </FlexRow>
    ) : (
      <FontColor lighter>
        <T>Orders:DateDurationManager.empty</T>
      </FontColor>
    )

  const renderLabel = () =>
    isDurationMode ? renderDurationLabel() : renderDateLabel()

  return (
    <Subsection
      isEditable
      title={<T l10n={`Orders:DateDurationManager.title.${type}`} />}
    >
      <ModalContainer
        placement="bottom-start"
        isOpen={isEditMode}
        onClose={() => setEditMode(false)}
        modal={
          <DateDurationModal
            date={date}
            duration={duration}
            durationOptions={durationOptions}
            initialDate={initialDate}
            onClose={() => setEditMode(false)}
            setDate={setDate}
          />
        }
        referenceElement={({ ref }) => (
          <EditButton
            disabled={readOnly}
            onClick={() => setEditMode(true)}
            innerRef={ref as Ref<HTMLButtonElement> | undefined}
          >
            {isCompact && date
              ? moment(date).format('dd, D.M.YYYY')
              : renderLabel()}
          </EditButton>
        )}
      />
    </Subsection>
  )
}

/////

const Dash = styled.span`
  ${({ theme }) => `
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
