import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import { useTheme } from '@/theme'

const GridLoadingIndicator = () => {
  const theme = useTheme()

  return (
    <LoadingIndicatorPosition>
      <ReactLoading type={'spin'} color={theme.palette.text.white} />
    </LoadingIndicatorPosition>
  )
}

export default GridLoadingIndicator

////////////

const LoadingIndicatorPosition = styled.div`
  position: absolute;
  border-radius: 8px;
  z-index: 10005;

  ${({ theme }) => css`
    top: ${theme.spacing.gu(13)}rem;
    right: ${theme.spacing.gu(2)}rem;
    background: ${theme.palette.smoke.lighter};
    padding: ${theme.spacing.gutter};
  `}
`
