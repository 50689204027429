import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { ActionButton, LoaderWrapper } from '@/modules/Products/components'
import ReactLoading from 'react-loading'
import { T } from '@/modules/Language'
import { useSalesProductListContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

type Props = {
  purchaseId: string
}

export const DeleteButton = ({ purchaseId }: Props) => {
  const { palette } = useTheme()
  const { deletePurchaseWithConfirm } = useSalesProductListContext()

  const [isProcessing, setProcessing] = useState<boolean>(false)

  const handleDeletePurchase = () => {
    setProcessing(true)

    return deletePurchaseWithConfirm(purchaseId).then((res) => {
      res ? null : setProcessing(false)
    })
  }

  return (
    <Wrapper>
      {isProcessing ? (
        <LoaderWrapper>
          <ReactLoading
            color={palette.smoke.dark}
            height={18}
            type="spin"
            width={18}
          />
        </LoaderWrapper>
      ) : (
        <ActionButton
          content={<T>Products:ProductManager.action.deleteProduct</T>}
          icon="trash"
          iconColor={palette.danger.main}
          onClick={handleDeletePurchase}
        />
      )}
    </Wrapper>
  )
}

///////

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(0.5)}rem;
  `}
`
