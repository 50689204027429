import { ApolloQueryResult, gql, useQuery } from '@apollo/client'

import { Document, DownloadableDocument } from '../types'
import { DocumentsService } from '../services'

import {
  UseOwnerDocumentsQuery as QueryData,
  UseOwnerDocumentsQueryVariables as QueryVariables,
} from '~generated-types'

interface UseOwnerDocumentsParams {
  ownerId: string
  skip?: boolean
}

interface UseOwnerDocumentsHook {
  documentsByTemplateType: Record<string, DownloadableDocument[]>
  error: boolean
  loading: boolean
  refetch: () => Promise<ApolloQueryResult<QueryData>>
}

export const QUERY = gql`
  query UseOwnerDocuments($ownerId: ID!) {
    documentsByOwnerId(ownerId: $ownerId) {
      attributes {
        key
        value
      }
      downloadedAt
      file {
        created
        fileName
        mimeType
        objectName
      }
      fileStatus
      id
      name
      status
      template {
        documentName
        documentType
        filesAllowed
        id
        name
      }
      userAttributes {
        key
        name
        editorValue
        templateValue
        active
      }
    }
  }
`

export default function useOwnerDocuments({
  ownerId,
  skip,
}: UseOwnerDocumentsParams): UseOwnerDocumentsHook {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'cache-and-network',
      skip,
      variables: { ownerId },
    }
  )

  const documentsByTemplateType: Record<string, DownloadableDocument[]> = data
    ? data?.documentsByOwnerId.reduce(
        (acc: Record<string, DownloadableDocument[]>, document) => {
          const templateType: string =
            document.template.documentType || 'undefined'

          acc[templateType] = acc[templateType] || []
          acc[templateType].push({
            document,
            downloadPDF: generateDownloadFn(document),
            printPDF: generatePrintFn(document),
          })

          return acc
        },
        {}
      )
    : {}

  return {
    documentsByTemplateType,
    error: !!error,
    loading,
    refetch,
  }
}

////////////

const generateDownloadFn =
  ({ id, name }: Document) =>
  async () => {
    try {
      const link = window.document.createElement('a')
      link.download = `${name}.pdf`
      link.href = await DocumentsService.buildPdf(id)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      console.error('Failed to download', err)
      throw new Error(`Failed to download target PDF [id=${id}]`)
    }
  }

const generatePrintFn =
  ({ id }: Document) =>
  async () => {
    try {
      const iframe = window.document.createElement('iframe')
      iframe.src = await DocumentsService.buildPdf(id)
      iframe.style.display = 'none'

      const closePrint = () => {
        document.body.removeChild(iframe)
        window.removeEventListener('focus', closePrint)
      }

      iframe.onload = () => {
        if (iframe.contentWindow) {
          iframe.contentWindow.focus()
          iframe.contentWindow.print()

          window.addEventListener('focus', closePrint)
        }
      }

      document.body.appendChild(iframe)
    } catch (err) {
      console.error('Failed to print', err)
      throw new Error(`Failed to print target PDF [id=${id}]`)
    }
  }
