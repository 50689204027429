import { useState } from 'react'

import { ContentSection } from '../../../components'
import { Note } from './Note'
import { useSalesDetailsContext } from '../../../'

export const Notes = () => {
  const {
    data: { id, notes: salesNotes },
    saleReadOnly: readOnly,
    updateAttributes,
  } = useSalesDetailsContext()

  const [notes, setNotes] = useState<string | null>(salesNotes)

  const parsedNotes = (() => {
    if (notes !== null && notes !== undefined) {
      return JSON.parse(notes)
    }

    return null
  })()

  const updateNotes = (notes: { [key: string]: any }) =>
    updateAttributes(id, { notes: JSON.stringify(notes) }).then((data) =>
      setNotes(data?.notes ?? JSON.stringify(notes))
    )

  return (
    <ContentSection>
      <Note notes={parsedNotes} readOnly={readOnly} updateNotes={updateNotes} />
    </ContentSection>
  )
}
