import moment, { HTML5_FMT } from 'moment'
import styled, { css } from 'styled-components/macro'

import { formatDateRangeWithoutRepetition } from '@/utils/time'
import { T } from '@/modules/Language'

import OnlyInPrintSpan from './OnlyInPrintSpan'

interface GroupHeaderProps {
  isoWeek: number
  isoWeekYear: number
  title: React.ReactNode
}

export const GroupHeader = ({
  isoWeek,
  isoWeekYear,
  title,
}: GroupHeaderProps) => {
  const FORMAT = 'YYYY[W]W'

  const from = moment(`${isoWeekYear}W${isoWeek}`, FORMAT).startOf('isoWeek')
  const to = moment(`${isoWeekYear}W${isoWeek}`, FORMAT).endOf('isoWeek')

  return (
    <tr>
      <th colSpan={18}>
        <Title>
          <span>
            <OnlyInPrintSpan>
              <T>RoomLayout:PrintCalendar.title</T>
              {' – '}
            </OnlyInPrintSpan>
            {title}
            {' – '}
            <small>
              <T>common:time.week</T> {isoWeek}
            </small>
          </span>
          <small>
            {formatDateRangeWithoutRepetition(
              from.format(HTML5_FMT.DATE),
              to.format(HTML5_FMT.DATE)
            )}
          </small>
        </Title>
      </th>
    </tr>
  )
}

export default GroupHeader

////////////

const Title = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  font-weight: 500;
  font-variant: all-small-caps;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}

  @media print {
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
      font-size: ${theme.typography.fontSizeBase2};
    `}
  }
`
