import { useCallback, useEffect, useState } from 'react'
import { generateCompareFn } from '@/utils/arrays'
import ReactLoading from 'react-loading'
import { useLazyQuery } from '@apollo/client'

import {
  SaleAccommodationQuery,
  SaleAccommodationQueryVariables,
  SuitableFilter,
  TargetFragment,
} from '~generated-types'
import { FlexColumn } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { ParticipantsListQueries } from '@/modules/ParticipantsList'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import ContextSwitcher from '../ContextSwitcher'
import GroupTitle from './GroupTitle'
import OptionsListWithHeading from '../OptionsListWithHeading'
import ParticipantRoomLabel from './ParticipantRoomLabel'

type Label = 'ALL' | 'DEFAULT'

type Props = {
  salesId: string
  serviceId: string | null
  handleClose: () => void
  handleSelect: (roomReservationId: string) => Promise<any>
}

const RoomsOptions = ({
  salesId,
  serviceId,
  handleClose,
  handleSelect,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [selectedLabel, setSelectedLabel] = useState<Label>('DEFAULT')
  const [targets, setTargets] = useState<TargetFragment[]>([])

  const [loadRooms, { data, loading, error }] = useLazyQuery<
    SaleAccommodationQuery,
    SaleAccommodationQueryVariables
  >(ParticipantsListQueries.SUITABLE_ROOMS, {
    fetchPolicy: 'no-cache',
  })

  const loadDataForLabel = (filter: SuitableFilter) => [
    loadRooms({
      variables: {
        input: {
          filter,
          salesId,
          serviceId,
        },
      },
    }),
  ]

  useEffect(() => {
    loadDataForLabel(SuitableFilter.Default)
  }, [])

  useEffect(() => {
    if (!data?.suitableRooms) {
      return
    }

    const targets = (data?.suitableRooms || [])
      .map(({ roomReservation }) => roomReservation.target)
      .filter(Boolean) as TargetFragment[]

    const uniqueTargets = targets
      .sort(generateCompareFn('id'))
      .filter((item, pos, ary) => !pos || item?.id !== ary[pos - 1]?.id)

    setTargets(uniqueTargets)
  }, [data])

  const getRoomsOptionsForTarget = useCallback(
    (targetId: string) =>
      (data?.suitableRooms || [])
        .filter(
          ({ roomReservation }) => roomReservation.target?.id === targetId
        )
        .sort(generateCompareFn('roomReservation.request.room.number'))
        .map(({ roomReservation: { id, request, participantRooms } }) => ({
          disabled: false,
          label: (
            <ParticipantRoomLabel
              request={request}
              participantRooms={participantRooms}
            />
          ),
          value: id,
        })),
    [data?.suitableRooms]
  )

  return (
    <FlexColumn
      flex={1}
      alignItems={'center'}
      style={{
        maxHeight: '370px',
        padding: `${spacing.gu(2)}rem`,
      }}
    >
      {loading ? (
        <ReactLoading
          type={'bubbles'}
          height={18}
          width={18}
          color={palette.smoke.main}
        />
      ) : error ? (
        <T>common:error.common</T>
      ) : (
        <>
          <ContextSwitcher
            handleClose={handleClose}
            loadDataForLabel={loadDataForLabel}
            selectedLabel={selectedLabel}
            setSelectedLabel={setSelectedLabel}
          />
          {!targets?.length ? (
            <FontWeight style={{ marginTop: `${spacing.gu(1)}rem` }}>
              <T>ParticipantsList:ParticipantRooms.noRooms</T>
            </FontWeight>
          ) : (
            targets.map((target) => (
              <OptionsListWithHeading
                key={`room-options-target-${target.id || 'floating'}`}
                groupTitle={<GroupTitle target={target} />}
                options={getRoomsOptionsForTarget(target.id)}
                noOptionsLabel={
                  <T>ParticipantsList:ParticipantRooms.noRooms</T>
                }
                handleSelect={handleSelect}
              />
            ))
          )}
        </>
      )}
    </FlexColumn>
  )
}

export default RoomsOptions
