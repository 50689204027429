import moment, { HTML5_FMT } from 'moment'
import { parse, stringify } from 'query-string'
import styled, { css } from 'styled-components/macro'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { generateCompareFn } from '@/utils/arrays'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  Reservation as ReservationType,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { CheckboxInput } from '@/components/FormControls'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { ReservationsOffset } from '@/components/ReservationsOffset'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  ProgramReservationButton,
  Reservation,
  ReservationGroup,
} from './components'
import { ResourceReservationGroup } from './types'

type OpenCalendarProps = {
  target: 'DATE' | 'MAIN'
  reservation?: ReservationType
  date?: string
}

type Props = {
  addProgramReservation: () => void
  isProgramReservationLoading: boolean
  openCalendar: (props: OpenCalendarProps) => void
  reservations: ReservationType[]
  readOnly: boolean
  setReservations: (reservations: ReservationType[]) => void
}

export const ReservationList = ({
  addProgramReservation,
  isProgramReservationLoading,
  openCalendar,
  reservations,
  readOnly,
  setReservations,
}: Props) => {
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { palette, spacing } = useTheme()

  const { moveReservationsByOffset } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations: setReservations,
    })

  const [groupModalId, setGroupModalId] = useState<string | null>(null)
  const [reservationModalId, setReservationModalId] = useState<string | null>(
    null
  )
  const [isMovingProcess, setMovingProcess] = useState(false)
  const [idsToMove, setIdsToMove] = useState<string[]>([])

  useEffect(() => {
    const params = parse(search)
    const { targetReservationId, ...rest } = params

    if (targetReservationId) {
      setReservationModalId(targetReservationId as string)

      history.push(`${pathname}?${stringify(rest)}`)
    }
  }, [])

  const reservationsByGroupId = useMemo(
    () =>
      reservations.reduce((groups: Record<string, ReservationType[]>, r) => {
        if (r.group?.id) {
          groups[r.group.id] = groups[r.group.id]
            ? [...groups[r.group.id], r]
            : [r]
        }

        return groups
      }, {}),
    [reservations]
  )

  const reservationGroups: ResourceReservationGroup[] = useMemo(
    () =>
      Object.keys(reservationsByGroupId).map(
        (groupId) =>
          ({
            ...reservationsByGroupId[groupId][0].group,
            reservations: reservationsByGroupId[groupId],
          } as ResourceReservationGroup)
      ),
    [reservationsByGroupId]
  )

  const singleReservations = useMemo(
    () => reservations.filter((r) => !r.group),
    [reservations]
  )

  const reservationsByDate = useMemo(
    () =>
      [...singleReservations, ...reservationGroups].reduce(
        (
          acc: Record<string, (ReservationType | ResourceReservationGroup)[]>,
          item: ReservationType | ResourceReservationGroup
        ) => {
          const date = moment(item.start).format(HTML5_FMT.DATE)
          acc[date] = acc[date] ? [...acc[date], item] : [item]

          return acc
        },
        {}
      ),
    [singleReservations, reservationGroups]
  )

  const resetMoving = () => {
    setMovingProcess(false)
    setIdsToMove([])
  }

  const moveReservations = (days: number, minutes: number) =>
    moveReservationsByOffset(idsToMove, {
      days,
      minutes,
    })

  const getAllIds = (
    reservations: (ReservationType | ResourceReservationGroup)[]
  ): string[] =>
    reservations
      .map((r) => {
        return isReservationGroup(r) ? getAllIds(r.reservations) : r.id
      })
      .flat()
      .filter(Boolean)

  const isAllSelected = (
    reservations: (ReservationType | ResourceReservationGroup)[]
  ): boolean =>
    reservations.every((r) =>
      isReservationGroup(r)
        ? isAllSelected(r.reservations)
        : idsToMove.find((selectedId) => selectedId === r.id)
    )

  const isSomeSelected = (
    reservations: (ReservationType | ResourceReservationGroup)[]
  ): boolean =>
    reservations.some((r) =>
      isReservationGroup(r)
        ? isAllSelected(r.reservations)
        : idsToMove.find((selectedId) => selectedId === r.id)
    )

  const handleSelectDateReservations = (date: string, allSelected: boolean) => {
    const allDateReservationIds = getAllIds(reservationsByDate[date])

    setIdsToMove(
      allSelected
        ? idsToMove.filter((id) => !allDateReservationIds.includes(id))
        : [
            ...idsToMove.filter((id) => !allDateReservationIds.includes(id)),
            ...allDateReservationIds,
          ]
    )
  }

  const selectedReservations = reservations
    .filter((r) => idsToMove.includes(r.id))
    .sort(generateCompareFn('start'))
  const firstReservationDate = selectedReservations[0]?.start

  return (
    <>
      {Object.keys(reservationsByDate)
        .sort()
        .map((date, index) => {
          const allSelected = isAllSelected(reservationsByDate[date])
          const someSelected = isSomeSelected(reservationsByDate[date])

          return (
            <FlexColumn key={date}>
              {index === 0 && isMovingProcess && (
                <ReservationsOffset
                  onApply={moveReservations}
                  onCancel={resetMoving}
                  startDate={firstReservationDate}
                  totalSelectionsAmount={reservations.length}
                  selectedAmount={idsToMove.length}
                  onSelectAll={(selectAll) =>
                    setIdsToMove(
                      selectAll ? reservations.map(({ id }) => id) : []
                    )
                  }
                  readOnly={readOnly}
                />
              )}
              <Header justifyContent="space-between">
                <FlexRow alignItems="center">
                  {isMovingProcess && (
                    <StyledCheckboxInput isFirst={index === 0}>
                      <CheckboxInput
                        checked={allSelected}
                        noMargin
                        indeterminate={someSelected && !allSelected}
                        onChange={() =>
                          handleSelectDateReservations(date, allSelected)
                        }
                      />
                    </StyledCheckboxInput>
                  )}
                  <DateButton
                    readOnly={readOnly}
                    isFirst={index === 0}
                    onClick={() =>
                      !readOnly ? openCalendar({ date, target: 'DATE' }) : null
                    }
                  >
                    <FontAwesomeIcon
                      color={palette.primary.main}
                      icon={['far', 'calendar']}
                      size="sm"
                      style={{ marginRight: `${spacing.gu(1.5)}rem` }}
                    />
                    {moment(new Date(date)).format('DD MMM, dddd')}
                  </DateButton>
                </FlexRow>

                {index === 0 && (
                  <FlexRow alignItems="center">
                    {!isMovingProcess && (
                      <InnocuousButton
                        disabled={readOnly}
                        onClick={() => setMovingProcess(true)}
                      >
                        <PrimaryColor>
                          <FontAwesomeIcon
                            icon={['far', 'square-check']}
                            style={{ marginRight: `${spacing.gu(1)}rem` }}
                          />
                          <T>ResourceReservations:reservation.select</T>
                        </PrimaryColor>
                      </InnocuousButton>
                    )}

                    <ProgramReservationButton
                      isLoading={isProgramReservationLoading}
                      onClick={addProgramReservation}
                      readOnly={readOnly}
                    />

                    <InnocuousButton
                      disabled={readOnly}
                      onClick={() => openCalendar({ target: 'MAIN' })}
                    >
                      <PrimaryColor>
                        <FontAwesomeIcon
                          icon={['far', 'calendar']}
                          style={{ marginRight: `${spacing.gu(1)}rem` }}
                        />
                        <T>ResourceReservations:action.addReservation</T>
                      </PrimaryColor>
                    </InnocuousButton>
                  </FlexRow>
                )}
              </Header>

              <FlexColumn noPadding>
                {reservationsByDate[date]
                  .sort(
                    (a, b) =>
                      moment(a.start).valueOf() - moment(b.start).valueOf()
                  )
                  .map(
                    (
                      item: ReservationType | ResourceReservationGroup,
                      index: number
                    ) =>
                      isReservationGroup(item) ? (
                        <ReservationGroup
                          group={item}
                          isFirst={index === 0}
                          isModalOpen={groupModalId === item.id}
                          readOnly={readOnly}
                          key={item.id}
                          reservationModalId={reservationModalId}
                          isMovingProcess={isMovingProcess}
                          idsToMove={idsToMove}
                          setIdsToMove={setIdsToMove}
                          setGroupModalId={setGroupModalId}
                          setReservationModalId={setReservationModalId}
                          setReservations={setReservations}
                        />
                      ) : (
                        <Reservation
                          group={null}
                          isModalOpen={
                            !groupModalId && reservationModalId === item.id
                          }
                          key={item.id}
                          reservation={item}
                          readOnly={readOnly}
                          isMovingProcess={isMovingProcess}
                          idsToMove={idsToMove}
                          setIdsToMove={setIdsToMove}
                          setReservationModalId={setReservationModalId}
                          setReservations={setReservations}
                        />
                      )
                  )}
              </FlexColumn>
            </FlexColumn>
          )
        })}
    </>
  )
}

///////

const isReservationGroup = (
  item: ReservationType | ResourceReservationGroup
): item is ResourceReservationGroup =>
  (item as ResourceReservationGroup).__typename === 'ResourceReservationGroup'

const DateButton = styled.button<{ isFirst: boolean; readOnly: boolean }>`
  background: transparent;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  width: fit-content;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ isFirst, theme }) =>
    !isFirst && `margin-top: ${theme.spacing.gu(2)}rem;`}
  ${({ readOnly, theme }) =>
    !readOnly &&
    `
      cursor: pointer;
      &:hover {
        background: ${theme.palette.smoke.light};
      }

      &:active {
        background: ${theme.palette.smoke.main};
      }
  `}
`

const Header = styled(FlexRow)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter} ${theme.spacing.gu(1)}rem
      ${theme.spacing.gutter};
  `}
`

const StyledCheckboxInput = styled.div<{ isFirst?: boolean }>`
  ${({ theme, isFirst }) => css`
    ${!isFirst && `margin-top: ${theme.spacing.gu(2)}rem`};
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
