import { useState } from 'react'

import {
  CustomerDetails as CustomerDetailsType,
  Customer as OrderCustomer,
} from '@/modules/Order/types'
import {
  CustomerTarget,
  DetailsModal,
  RegistryModal,
} from '@/modules/Registry/Customer'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { orderServices } from '@/modules/Order'
import { PrimaryColor } from '@/components/Colors'
import { salesHooks } from '@/modules/Sales'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { IconButtonWithTooltip, Section, Subsection } from '../components'
import { CustomerDetails } from './CustomerDetails'

type Props = {
  customer: CustomerDetailsType | null
  orderCustomer: OrderCustomer | null
  orderId: string
  readOnly: boolean
}

export const Customer = ({
  customer,
  orderCustomer,
  orderId,
  readOnly,
}: Props) => {
  const { spacing } = useTheme()

  const { navItemSelect } = salesHooks.useSalesDetailsContext()
  const { setCustomer } = orderServices.orderService()

  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false)
  const [isRegistryModalOpen, setRegistryModalOpen] = useState(false)

  const isException =
    customer?.customerNumber !==
    orderCustomer?.inheritedCustomer?.customer.customerNumber

  const handleSetCustomer = (
    target: {
      addressId?: string | null
      contactId?: string | null
      customer: CustomerTarget | null
    } | null
  ) =>
    setCustomer(
      orderId,
      target?.customer?.id,
      target?.addressId,
      target?.contactId
    )

  return (
    <Section flex={1}>
      <Subsection isEditable title={<T>Orders:Header.section.CUSTOMER</T>}>
        {!customer ? (
          <InnocuousButton
            compact
            disabled={readOnly}
            noNudge
            style={{ marginLeft: `-${spacing.gu(1)}rem` }}
            onClick={() => navItemSelect('overview')}
          >
            <PrimaryColor>
              <T>Orders:Customer.setSalesCustomer</T>
            </PrimaryColor>
          </InnocuousButton>
        ) : (
          <FlexRow flex={1}>
            <CustomerDetails
              customer={customer}
              isException={isException}
              readOnly={readOnly}
              openDetailsModal={() => setDetailsModalOpen(true)}
            />

            {!readOnly && (
              <FlexColumn>
                <IconButtonWithTooltip
                  icon="retweet"
                  onClick={() => setRegistryModalOpen(true)}
                  title={<T>Orders:Customer.changeException</T>}
                />

                {isException && (
                  <IconButtonWithTooltip
                    icon="rotate-left"
                    onClick={() => handleSetCustomer(null)}
                    title={<T>Orders:Customer.resetSalesCustomer</T>}
                  />
                )}
              </FlexColumn>
            )}

            {isDetailsModalOpen && (
              <DetailsModal
                onClose={() => setDetailsModalOpen(false)}
                target={{ customerNumber: customer.customerNumber || '-1' }}
              />
            )}

            {isRegistryModalOpen && (
              <RegistryModal
                focusTarget={{
                  customerNumber: customer.customerNumber || '-1',
                }}
                onClose={() => setRegistryModalOpen(false)}
                onSetCustomer={handleSetCustomer}
              />
            )}
          </FlexRow>
        )}
      </Subsection>
    </Section>
  )
}
