import { draftToMarkdown } from 'markdown-draft-js'
import { RawDraftContentState } from 'draft-js'

type DraftLinkData = {
  data: {
    url: string
  }
}

export const convertDraftToPDFString = (draft: RawDraftContentState) => {
  const markdownString = draftToMarkdown(draft, {
    entityItems: {
      LINK: {
        close: () => '</Link>',
        open: (data) => `<Link src="${(data as DraftLinkData).data.url}">`,
      },
    },
    styleItems: {
      BOLD: {
        close: () => '</Text>',
        open: () => '<Text style={{ fontWeight: 700 }}>',
      },
      ITALIC: {
        close: () => '</Text>',
        open: () => '<Text style={{ fontStyle: "italic" }}>',
      },
    },
  })

  const newLineReplacer = (match: string) =>
    `<Text style={{ marginBottom: 2 }}>${match}</Text>`

  return markdownString.replace(/(.*\S.*)/gm, newLineReplacer)
}
