import { darken, desaturate } from 'polished'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { ElasticRoomReservation } from '@/modules/Reservations/components/RoomLayout'
import { Right } from '@/components/Layout'
import { useTheme } from '@/theme'

interface PrintReservationBlockProps {
  overflowEnd?: boolean
  overflowStart?: boolean
  reservation: ElasticRoomReservation
}

export const PrintReservationBlock = ({
  overflowEnd,
  overflowStart,
  reservation,
}: PrintReservationBlockProps) => {
  const theme = useTheme()

  const getBlockColor = (reservation: ElasticRoomReservation) => {
    const defaultColor = darken(
      0.02,
      desaturate(0.04, theme.palette.text.light)
    )

    switch (reservation.sales.type) {
      case 'ENROLLMENT':
        return theme.palette.secondary.darker
      case 'EVENT':
        return theme.palette.accent1.dark
      case 'SALES':
        return theme.palette.primary.dark
      default:
        return defaultColor
    }
  }

  const color = getBlockColor(reservation)

  return (
    <Wrapper>
      <Block
        color={color}
        overflowEnd={overflowEnd}
        overflowStart={overflowStart}
      >
        {reservation.request.checkIn.type === CheckInTypes.EARLY &&
        !overflowStart ? (
          <CheckInOutIcon icon="sun" color={color} fixedWidth size="sm" />
        ) : (
          <IconPlaceholder />
        )}
        <Text color={color}>
          {reservation.sales.customerName ?? ''} #{reservation.sales.number}
          <strong style={{ marginLeft: theme.spacing.gutter }}>
            {reservation.request.beds}+{reservation.request.extraBeds}
          </strong>
        </Text>
        <Right style={{ alignItems: 'center' }}>
          {reservation.request.checkOut.type === CheckOutTypes.LATE &&
          !overflowEnd ? (
            <CheckInOutIcon icon="moon" color={color} fixedWidth size="xs" />
          ) : (
            <IconPlaceholder />
          )}
        </Right>
      </Block>
    </Wrapper>
  )
}

export default PrintReservationBlock

////////////

interface BlockProps {
  color: string
  overflowEnd?: boolean
  overflowStart?: boolean
}

const Block = styled.div<BlockProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  padding: 4px 3px;
  max-width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}

  border: 1px solid ${(props) => props.color};
  border-radius: 4px;

  ${({ overflowEnd, theme }) =>
    overflowEnd
      ? css`
          border-right-width: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-right: -${theme.spacing.gu(2)}rem;

          @media print {
            margin-right: -${theme.spacing.gu(0.5)}rem;
          }
        `
      : ''}
  ${({ overflowStart, theme }) =>
    overflowStart
      ? css`
          border-left-width: 0;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: -${theme.spacing.gu(2)}rem;

          @media print {
            margin-left: -${theme.spacing.gu(0.5)}rem;
          }
        `
      : ''}

  ${({ overflowEnd, overflowStart, theme }) => {
    if (overflowEnd && overflowStart) {
      return css`
        max-width: calc(100% + ${theme.spacing.gu(4)}rem);

        @media print {
          max-width: calc(100% + ${theme.spacing.gu(2)}rem);
        }
      `
    }

    if (overflowEnd || overflowStart) {
      return css`
        max-width: calc(100% + ${theme.spacing.gu(2)}rem);

        @media print {
          max-width: calc(100% + ${theme.spacing.gu(1)}rem);
        }
      `
    }
  }}
`

const CheckInOutIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(0.5)}rem;
  `}

  @media print {
    ${({ theme }) => css`
      margin: 0 ${theme.spacing.gu(0.1)}rem;
    `}
  }
`

const IconPlaceholder = styled.span`
  width: 22px;
`

const Text = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
    margin: 0 ${theme.spacing.gu(0.5)}rem;
  `}

  color: ${(props) => props.color};
  white-space: pre-wrap;

  @media print {
    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeSmaller};
      margin: 0 ${theme.spacing.gu(0.25)}rem;
    `}
  }
`

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 100%;
`
