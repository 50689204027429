import classNames from 'classnames'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { FeatureChip } from '@/modules/Listing/common'
import { FontWeight } from '@/components/Typography'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import type {
  ArrivalsParticipant,
  ArrivalsRoomReservation,
} from '../useArrivalsForDate'
import {
  getCustomDate,
  parseParticipantName,
  parseParticipantProduct,
} from '../../utils'
import { CheckInOutTimes } from '../../common'

type Props = {
  guests: ArrivalsParticipant[]
  hideFromPrint?: boolean
  room: ArrivalsRoomReservation
}

export const SalesTableRoomRow = ({ guests, hideFromPrint, room }: Props) => {
  const theme = useTheme()

  const sortedGuests = guests.sort(generateCompareFn(['lastName', 'firstName']))

  const renderGuestCells = (guest: ArrivalsParticipant) => {
    const customDates = getCustomDate(guest, room)?.dates

    return (
      <>
        <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
          <FlexColumn noPadding>
            <span>{parseParticipantName(guest) || ''}</span>
            {customDates && (
              <small style={{ marginTop: theme.spacing.gutterSmall }}>
                <CheckInOutTimes
                  checkIn={customDates.checkIn}
                  checkOut={customDates.checkOut}
                />
              </small>
            )}
          </FlexColumn>
        </td>
        <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
          {parseParticipantProduct(guest, theme)}
        </td>
        <td style={{ maxWidth: '30mm', wordBreak: 'break-word' }}>
          {guest.additionalInfo || '—'}
        </td>
      </>
    )
  }

  const rowSpan = sortedGuests.length || 1

  return (
    <>
      <tr
        className={hideFromPrint ? 'hide-from-print' : ''}
        key="arrivals-sales-room-participant-0"
      >
        <td rowSpan={rowSpan}>
          <FlexColumn noPadding>
            <FontWeight
              bold
              style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
            >
              #{room.request.room.number}
            </FontWeight>
            <small>
              <FontWeight normal>
                {room.request.room.beds} + {room.request.room.extraBeds}
              </FontWeight>
            </small>
            {!!room.target?.name ? <FlexRow>{room.target.name}</FlexRow> : null}
          </FlexColumn>
        </td>
        <td rowSpan={rowSpan}>
          <FlexColumn noPadding>
            <span>
              {room.request.beds} + {room.request.extraBeds}
            </span>
            {room.request.features.length ? (
              <span style={{ marginTop: `${theme.spacing.gu(1)}rem` }}>
                {[...room.request.features]
                  .sort(generateCompareFn('name'))
                  .map((feature) => (
                    <FeatureChip
                      feature={feature}
                      key={`feature-${feature.id}`}
                    />
                  ))}
              </span>
            ) : null}
          </FlexColumn>
        </td>
        {!!sortedGuests.length ? (
          renderGuestCells(sortedGuests[0])
        ) : (
          <>
            <td colSpan={3}>
              <em>
                <T>Listings:ArrivalsAndDeparturesListing.noGuests</T>
              </em>
            </td>
          </>
        )}
        <td
          rowSpan={rowSpan}
          style={{ maxWidth: '30mm', wordBreak: 'break-word' }}
        >
          {room.request.info || '—'}
        </td>
        <td rowSpan={rowSpan} style={{ whiteSpace: 'nowrap' }}>
          <CheckInOutTimes
            checkIn={room.request.checkIn}
            checkOut={room.request.checkOut}
          />
        </td>
      </tr>
      {sortedGuests.slice(1).map((x, idx) => (
        <tr
          className={classNames({
            'extra-row': true,
            'hide-from-print': hideFromPrint,
          })}
          key={`arrivals-sales-room-participant-${idx}`}
        >
          {renderGuestCells(x)}
        </tr>
      ))}
    </>
  )
}
