import { ReactNode, Ref, RefObject, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalContainer } from '@/components/Modal'

import { DateRangeSelector } from './DateRangeSelector'
import { MultiListSelector } from './MultiListSelector'
import { RangeSelector } from './RangeSelector'
import { SingleListSelector } from './SingleListSelector'

type Props = {
  children: ReactNode
  componentId: string
  type: 'DATE_RANGE' | 'MULTI_LIST' | 'RANGE' | 'SINGLE_LIST'
}

export const FilterToggle = ({ children, type, ...filterProps }: Props) => {
  const [selectorOpen, setSelectorOpen] = useState<boolean>(false)

  const renderFilterSelector = () => {
    switch (type) {
      case 'DATE_RANGE':
        return <DateRangeSelector {...filterProps} />
      case 'MULTI_LIST':
        // @ts-ignore
        return <MultiListSelector {...filterProps} />
      case 'RANGE':
        return <RangeSelector {...filterProps} />
      case 'SINGLE_LIST':
        return <SingleListSelector {...filterProps} />
      default:
        console.warn(`Unsupported elastic list filter type [${type}]`)
        return null
    }
  }

  const renderToggle = (ref: Ref<HTMLDivElement> | null | undefined) => {
    switch (type) {
      case 'DATE_RANGE':
      case 'MULTI_LIST':
      case 'RANGE':
      case 'SINGLE_LIST':
        return (
          <Toggle
            onClick={() => setSelectorOpen(true)}
            ref={ref as RefObject<HTMLSpanElement>}
          >
            {children}
            <FontAwesomeIcon icon="caret-down" />
          </Toggle>
        )
      default:
        console.warn(`Unsupported elastic list filter type [${type}]`)
        return children
    }
  }

  return (
    <ModalContainer
      isOpen={selectorOpen}
      keepMounted
      modal={renderFilterSelector()}
      onClose={() => setSelectorOpen(false)}
      placement="bottom-start"
      referenceElement={({ ref }) => renderToggle(ref)}
    />
  )
}

////////////

const Toggle = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.text.dark};
    `}
  }

  & > svg {
    ${({ theme }) => css`
      margin-left: ${theme.spacing.gutterSmall};
    `}
  }
`
