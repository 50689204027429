import { useState } from 'react'

import {
  DocumentPDFDownloader,
  DocumentPDFDownloaderRenderProps,
} from '@/modules/Document'
import { notify } from '@/components/NotificationService'
import { T } from '@/modules/Language'

import { SubNavLinkButton } from './SubNavLink'

type Props = {
  ownerId: string
}

export const ProgramDocumentButton = ({ ownerId }: Props) => {
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false)

  const render = ({
    documents,
    error,
    loading,
  }: DocumentPDFDownloaderRenderProps) => {
    const handleLoadPDF = () => {
      setLoadingPDF(true)
      documents[0]
        .downloadPDF()
        .catch(() =>
          notify({
            content: <T>SalesDetails:error:programPDF</T>,
            type: 'ERROR',
          })
        )
        .finally(() => setLoadingPDF(false))
    }

    if (error || !documents.length) {
      return (
        <SubNavLinkButton
          disabled
          icon={['far', 'file-alt']}
          label={<T>SalesDetails:section.programPDF</T>}
          loading={loading}
          onClick={() => undefined}
        />
      )
    }

    return (
      <SubNavLinkButton
        disabled={loadingPDF || loading}
        icon={['far', 'file-alt']}
        label={<T>SalesDetails:section.programPDF</T>}
        loading={loadingPDF || loading}
        onClick={handleLoadPDF}
      />
    )
  }

  return (
    <>
      {DocumentPDFDownloader({
        ownerId,
        render,
        templateType: 'PROGRAM',
      })}
    </>
  )
}
