import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'

import { Button } from '@/components/Button'
import type { ButtonProps } from '@/components/Button'

const NoTextDecorationAnchor = styled.a`
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const NoTextDecorationLink = styled(NoTextDecorationAnchor).attrs(() => ({
  as: Link,
}))<any>``

type Props = ButtonProps & {
  href?: string
  target?: string
  to?: string
}
export const ButtonLink = ({ href, target, to, ...props }: Props) =>
  href ? (
    <NoTextDecorationAnchor href={href} target={target}>
      <Button {...props} />
    </NoTextDecorationAnchor>
  ) : (
    <NoTextDecorationLink target={target} to={to}>
      <Button {...props} />
    </NoTextDecorationLink>
  )
