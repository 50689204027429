import { Reservations } from './Reservations'
import { ResourceReservationListContextProvider } from './ReservationsState'
import { SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'

const SECTION: SectionFeatures = {
  icon: 'door-open',
  key: 'reservations',
}

export const ReservationsComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <ResourceReservationListContextProvider>
      <Reservations />
    </ResourceReservationListContextProvider>
  </SectionContainer>
)

export default Object.freeze({
  component: ReservationsComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
