import moment from 'moment'
import { useMemo } from 'react'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { formatDateRangeWithoutRepetition } from '@/utils/time'
import { generateCompareFn } from '@/utils/arrays'
import { PrintLink } from '@/modules/Listing/common'
import { SalesTypeChip } from '@/modules/Sales'

import { ListingSales, ListingScheduledMeal } from '../../usePacketMealsListing'
import { NoWrapSpan } from '../common'
import { parseCustomerName } from '../../utils'

type Props = {
  data: ListingScheduledMeal
}

export const MealRow = ({ data }: Props) => {
  const { language } = useLanguageContext()

  const from = moment(data.start)
  const to = moment(data.start).add(data.duration ?? 0, 'minutes')

  const sales: ListingSales = data.sales

  const salesEstimatedDates = formatDateRangeWithoutRepetition(
    sales.estimatedDates?.start,
    sales.estimatedDates?.end,
    'short'
  )

  const rows = useMemo(
    () =>
      [...data.quantities]
        .filter((q) => !!q.quantity)
        .sort(generateCompareFn('ageCategory.key')),
    [data.quantities]
  )

  return (
    <>
      {rows.map(({ ageCategory, quantity }, idx) => {
        return (
          <tr key={`meal-${data.id}-${ageCategory?.key ?? 'missed'}-${idx}`}>
            <td />
            <td>
              <NoWrapSpan>
                {from.format('HH:mm')} – {to.format('HH:mm')}
              </NoWrapSpan>
            </td>
            <td>
              <NoWrapSpan>
                {quantity} <T>common:unit.PERSON</T>
              </NoWrapSpan>
            </td>
            <td>
              <NoWrapSpan>
                {ageCategory
                  ? ageCategory.shortName
                  : translate(
                      'Listings:PacketMealListing.field.noAge',
                      language
                    )}
              </NoWrapSpan>
            </td>
            <td>
              <FlexRow alignItems="flex-start">
                <PrintLink to={`/sales/details/${sales.id}`}>
                  {parseCustomerName(sales)}
                </PrintLink>
                {salesEstimatedDates && (
                  <NoWrapSpan style={{ margin: '0 12px' }}>
                    [{salesEstimatedDates}]
                  </NoWrapSpan>
                )}
                <span style={{ flex: 1 }} />
                <SalesTypeChip
                  facet={sales.facet}
                  format="short"
                  type={sales.type}
                />
              </FlexRow>
            </td>
            <td>
              <span style={{ whiteSpace: 'pre-wrap' }}>
                {data.notes || '—'}
              </span>
            </td>
          </tr>
        )
      })}
    </>
  )
}
