import { gql, useLazyQuery } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Moment } from 'moment'

import type {
  SalesByIdsStatsQuery as QueryData,
  SalesByIdsStatsQueryVariables as QueryVariables,
} from '~generated-types'

import dayVisitors from '../fragments/dayVisitors'
import roomsAndParticipants from '../fragments/roomsAndParticipants'

const QUERY = gql`
  ${dayVisitors}
  ${roomsAndParticipants}

  query SalesByIdsStats($ids: [ID!]!, $date: LocalDate!) {
    salesAll(ids: $ids) {
      id
      customer {
        customer {
          id
          customerNumber

          ... on CustomerOrganization {
            id
            organization {
              name
            }
          }

          ... on CustomerPerson {
            id
            person {
              firstName
              lastName
            }
          }
        }
      }
      visitStats(date: $date) {
        arriving {
          ...RoomsAndParticipants
        }
        dayVisitors {
          ...DayVisitors
        }
        departing {
          ...RoomsAndParticipants
        }
        present {
          ...RoomsAndParticipants
        }
        types
      }
    }
  }
`

type Params = {
  date: Moment
  ids: string[]
  skip?: boolean
  CHUNK_SIZE?: number
}

export const useSalesByIdsStats = ({
  date,
  ids,
  skip,
  CHUNK_SIZE = 30,
}: Params) => {
  const [sales, setSales] = useState<QueryData['salesAll']>([])
  const [chunkToFetch, setChunkToFetch] = useState(0)
  const [loading, setLoading] = useState(true)

  const [loadSales, { data, error, loading: queryProcessing }] = useLazyQuery<
    QueryData,
    QueryVariables
  >(QUERY, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (queryProcessing || skip) return
    data &&
      setSales((current) => {
        const currentMap = new Map(current.map((i) => [i.id, i]))
        const newMap = new Map(data.salesAll.map((i) => [i.id, i]))
        return Array.from(new Map([...currentMap, ...newMap]).values())
      })
    if (!loading) return

    const idsToFetch = ids.slice(
      chunkToFetch * CHUNK_SIZE,
      CHUNK_SIZE * (chunkToFetch + 1)
    )
    const idsLeftToFetch = ids.slice(CHUNK_SIZE * (chunkToFetch + 1))

    loadSales({
      variables: {
        date: date.format('YYYY-MM-DD'),
        ids: idsToFetch,
      },
    })

    setChunkToFetch((current) => current + 1)
    if (idsLeftToFetch.length === 0) setLoading(false)
  }, [data, skip, loading])

  useEffect(() => {
    setLoading(true)
    setChunkToFetch(0)
    setSales([])
  }, [date])

  return {
    error,
    loading,
    sales,
  }
}
