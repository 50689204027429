import { Fragment, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  InlineModalContent,
  InlineModalHeader,
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  PurchaseProductAction as PPA,
  QuantityUnit as QU,
  SalesType as ST,
} from '~generated-types'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import type { ProductPurchase } from '@/modules/Products/types'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

import { EditButton, IconWrapper, Placeholder } from '../../../common'
import { PurchaseSection } from './components'
import { PurchasesFooter } from './PurchasesFooter'

type Props = {
  onClose: () => void
  productId: string
  purchases: ProductPurchase[]
  quantityUnit: QU
}

export const PurchasesSection = ({
  onClose,
  productId,
  purchases,
  quantityUnit,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()
  const {
    addPurchase,
    isPricingLocked,
    salesId,
    salesReadOnly,
    salesType,
    targetPurchaseId,
  } = useSalesProductManagerContext()

  const [isHover, setHover] = useState<boolean>(false)
  const [showAll, setShowAll] = useState<boolean>(false)

  const targetPurchase = purchases.find(({ id }) => id === targetPurchaseId)
  const updateAction = targetPurchase?.status.validatedActions.find(
    (a) => a.action === PPA.Update
  )
  const readOnly = !updateAction?.valid ?? false

  const filteredPurchases = purchases.filter(
    (p) => salesType === ST.Event || salesId === p.link.sales.id
  )

  return (
    <FlexColumn flex={2.5}>
      <ModalHeader
        onClose={onClose}
        title={<T>Products:ProductManager.purchase.title</T>}
      />

      <InlineModalLine style={{ margin: 0 }} />

      <InlineModalContent style={{ padding: 0 }}>
        {filteredPurchases
          .filter(
            (p) =>
              !targetPurchaseId ||
              !isPricingLocked ||
              showAll ||
              targetPurchaseId === p.id
          )
          .sort((p) => (targetPurchaseId === p.id ? -1 : 0))
          .map((p) => (
            <Fragment key={p.id}>
              <PurchaseSection purchase={p} quantityUnit={quantityUnit} />
              <InlineModalLine style={{ margin: 0 }} />
            </Fragment>
          ))}

        {isPricingLocked &&
          targetPurchaseId &&
          filteredPurchases.length > 1 && (
            <ShowAllWrapper justifyContent="center">
              <InnocuousButton
                compact
                noNudge
                onClick={() => setShowAll(!showAll)}
              >
                <PrimaryColor>
                  {showAll ? (
                    <T>Products:ProductManager.action.hideAllPurchases</T>
                  ) : (
                    <>
                      <T>Products:ProductManager.action.showAllPurchases</T>
                      {` (${filteredPurchases.length - 1} ${translate(
                        'common:helpers.more',
                        language
                      )})`}
                    </>
                  )}
                </PrimaryColor>
              </InnocuousButton>
            </ShowAllWrapper>
          )}

        {!salesReadOnly && (!targetPurchaseId || !isPricingLocked) && (
          <>
            <AddPurchaseButtonWrapper>
              <AddPurchaseButton
                onClick={() => addPurchase(productId)}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                <Placeholder>
                  <T>Products:ProductManager.action.addPurchase</T>
                </Placeholder>

                <IconWrapper>
                  {isHover && (
                    <FontAwesomeIcon
                      color={palette.primary.main}
                      icon="plus"
                      size="sm"
                    />
                  )}
                </IconWrapper>
              </AddPurchaseButton>
            </AddPurchaseButtonWrapper>

            <InlineModalLine style={{ margin: 0 }} />
          </>
        )}
      </InlineModalContent>

      <PurchasesFooter onClose={onClose} readOnly={readOnly} />
    </FlexColumn>
  )
}

/////////

const AddPurchaseButton = styled(EditButton)`
  min-height: 30px;
`

const ModalHeader = styled(InlineModalHeader)`
  ${({ theme }) => css`
    min-height: ${theme.spacing.gu(7)}rem;
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const AddPurchaseButtonWrapper = styled(InlineModalIconSection)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1.5)}rem;
    padding-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`

const ShowAllWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1.5)}rem;
    padding-bottom: ${theme.spacing.gu(1.5)}rem;
  `}
`
