import {
  PaymentOtherDetails,
  PaymentVoucherDetails,
} from '@/modules/Order/types'
import { PaymentType } from '~generated-types'

import { Payment } from './types'

export const extendCells = (
  startCol: number,
  startRow: number,
  endCol: number,
  endRow: number
) => {
  // Helper function to convert column numbers to letters
  const numberToColumn = (number: number) => {
    let column = ''
    while (number > 0) {
      const remainder: number = (number - 1) % 26
      column = String.fromCharCode('A'.charCodeAt(0) + remainder) + column
      number = Math.floor((number - 1) / 26)
    }
    return column
  }

  const result = []

  // Iterate over columns and rows to generate the cell range
  for (let col = startCol; col <= endCol; col++) {
    for (let row = startRow; row <= endRow; row++) {
      result.push(numberToColumn(col) + row)
    }
  }

  return result
}

// TODO unify and move common
export const getPaymentTypeLabel = (
  payment: Payment,
  translateFn: (keys: string | Array<string>) => string
): string => {
  switch (payment.type) {
    case PaymentType.Other:
      return (
        (payment.details as PaymentOtherDetails).paymentType?.name ??
        translateFn('Payments.type.OTHER')
      )
    case PaymentType.Voucher:
      return (
        (payment.details as PaymentVoucherDetails).type?.name ??
        translateFn('Payments.type.VOUCHER')
      )
    default:
      return translateFn(`Payments.type.${payment.type}`)
  }
}

export const hexToArgb = (hex: string): string => {
  const hexValue = hex.replace('#', '')
  return hexValue.length == 3
    ? hexValue
        .split('')
        .map((char) => `${char}${char}`)
        .join('')
    : hexValue
}
