import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

type ContextType = {
  addLastSale: (id: string) => void
  sales: Array<any>
}

const BasketContext = createContext<ContextType>({
  addLastSale: () => undefined,
  sales: [],
})

type Props = {
  children: ReactNode
}

export const BasketContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const [sales, setSales] = useState<string[]>(
    JSON.parse(localStorage.getItem('salesBasket') || '[]')
  )

  useEffect(() => {
    localStorage.setItem('salesBasket', JSON.stringify(sales))
  }, [sales])

  const addLastSale = (saleId: string) => {
    setSales((current) =>
      [saleId, ...current.filter((id) => id !== saleId)].slice(0, 10)
    )
  }

  contextValueRef.current = {
    addLastSale,
    sales,
  }

  return (
    <BasketContext.Provider value={contextValueRef.current}>
      {children}
    </BasketContext.Provider>
  )
}

export const useBasketContext = () => useContext(BasketContext)
