import React, { useState } from 'react'

import { EventEnrollment } from '@/API/EventEnrollments'
import { salesMutations } from '@/modules/Sales'

import Content from './EnrollmentContent'
import { EnrollmentContainer } from './EnrollmentContainer'
import Header from './EnrollmentHeader'

type StateCurrentFunc = (isExpanded: boolean) => void

type Props = {
  data: EventEnrollment
  isExpanded: boolean
  isFirst: boolean
  isLast: boolean
  readOnly?: boolean
  setExpanded: (current: StateCurrentFunc | boolean) => void
}

export default function Enrollment({
  data,
  isExpanded,
  readOnly,
  setExpanded,
  ...props
}: Props) {
  const [updateName] = salesMutations.useUpdateSalesNameMutation()

  const [activeSections, openSections] = useState<string[] | undefined>(
    undefined
  )

  const handleOpenSections = (sections: string[]) => {
    openSections(sections)
    !isExpanded && setExpanded(true)
  }

  const rename = (enrollmentId: string, newName: string | null) =>
    updateName({
      variables: {
        input: {
          name: newName,
          salesId: enrollmentId,
        },
      },
    }).catch(() => undefined)

  return (
    <EnrollmentContainer
      isExpanded={isExpanded}
      onClose={() => setExpanded(false)}
      renderHeader={() => (
        <Header
          enrollment={data}
          readOnly={readOnly}
          isExpanded={isExpanded}
          rename={rename}
          setExpanded={setExpanded}
          openSections={handleOpenSections}
        />
      )}
      renderContent={() => (
        <Content
          enrollment={data}
          enrollmentActiveSections={activeSections}
          setActiveSections={openSections}
        />
      )}
      {...props}
    />
  )
}
