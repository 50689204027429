import { CSSProperties, ReactNode, Ref } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'

import { FontColor, FontWeight } from '@/components/Typography'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { Option } from '@/components/ThemedSelect'
import { useTheme } from '@/theme'

type Props = {
  allSelectedPlaceholder?: ReactNode | string
  innerRef?: Ref<HTMLDivElement>
  labelsAmount: number
  isCompact?: boolean
  isOpen: boolean
  loading: boolean
  openModal: () => void
  options: Option[]
  placeholder?: ReactNode | string
  style?: CSSProperties
  values: string[]
}

export const Control = ({
  allSelectedPlaceholder,
  innerRef,
  isCompact,
  isOpen,
  labelsAmount,
  loading,
  openModal,
  options,
  placeholder,
  style,
  values,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const getLabelByValue = (targetValue: string) =>
    (options.find(({ value }) => value === targetValue) as Option)?.label

  const getLabelByValues = (values: string[]) => {
    const valuesToShow = values.slice(0, labelsAmount)
    const restValues = values.slice(labelsAmount - 1, values.length - 1)

    const labelsToShow = valuesToShow.map(getLabelByValue).join(', ')
    const restLabelsAmount = restValues.length
      ? ` + ${restValues.length} ${translate('common:helpers.more', language)}`
      : ''

    return labelsToShow + restLabelsAmount
  }

  const getSelectorLabel = () => {
    switch (values.length) {
      case 0:
        return (
          <FontWeight normal style={{ color: palette.text.lighter }}>
            {placeholder ?? <T>ThemedSelect:select</T>}
          </FontWeight>
        )
      case 1:
        return <FontWeight normal>{getLabelByValue(values[0])}</FontWeight>
      case options.length:
        return (
          <FontColor lighter>
            {allSelectedPlaceholder ?? <T>ThemedSelect:all</T>}
          </FontColor>
        )
      default:
        return <FontWeight normal>{getLabelByValues(values)}</FontWeight>
    }
  }

  return (
    <Wrapper
      isCompact={isCompact}
      isOpen={isOpen}
      onClick={openModal}
      ref={innerRef}
      style={style}
    >
      <FlexRow>
        {loading ? (
          <ReactLoading
            color={palette.smoke.main}
            height={24}
            type="bubbles"
            width={24}
          />
        ) : (
          getSelectorLabel()
        )}
      </FlexRow>

      <FlexRow style={{ height: '100%' }}>
        <VerticalDivider isCompact={isCompact} />

        <ArrowDownButton isCompact={isCompact}>
          <FontAwesomeIcon
            color={palette.smoke.dark}
            icon="chevron-down"
            size="1x"
          />
        </ArrowDownButton>
      </FlexRow>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isCompact?: boolean; isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;

  ${({ isCompact }) => css`
    height: ${isCompact ? '30px' : '38px'};
  `}

  ${({ isCompact, theme }) => css`
    padding-left: calc(${theme.spacing.gu(isCompact ? 1 : 1.5)}rem - 1px);
    background-color: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
  `}

  cursor: pointer;
  transition: 0.1s;

  &:hover {
    ${({ theme }) => css`
      border: 1px solid ${theme.palette.smoke.extraDark};
    `}
  }

  ${({ theme, isOpen }) =>
    isOpen &&
    css`
      border: 1px solid ${theme.palette.primary.main};
      box-shadow: 0 0 0 1px ${theme.palette.primary.main};
    `}

  & > div:first-child, 
  div:first-child span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const VerticalDivider = styled.span<{ isCompact?: boolean }>`
  width: 1px;
  align-self: stretch;

  ${({ isCompact }) => css`
    margin-bottom: ${isCompact ? '4px' : '8px'};
    margin-top: ${isCompact ? '4px' : '8px'};
  `}

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.smoke.dark};
  `}
`

const ArrowDownButton = styled.div<{ isCompact?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  width: 36px;

  ${({ isCompact }) => css`
    width: ${isCompact ? '24px' : '36px'};
  `}

  & svg {
    transition: 0.1s;
  }

  &:hover svg {
    ${({ theme }) => css`
      color: ${theme.palette.smoke.extraDark};
    `}
  }
`
