import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { Chip } from '@/components/Chip'
import { MobileSales } from '@/modules/Mobile/SalesList'

import {
  formatDate,
  getParticipantsByAgeCategory,
  getSalesStateColor,
} from '../../utils'

type Props = {
  sales: MobileSales
}

export const MainDetails = ({
  sales: {
    estimatedDates,
    name,
    orderNumber,
    lifecycle: { state },
    participantConnection,
    reservationDates,
  },
}: Props) => {
  const { language } = useLanguageContext()

  const participants = participantConnection?.nodes ?? []
  const particicantsTotals = getParticipantsByAgeCategory(participants)

  const salesDates = estimatedDates
    ? formatDate(estimatedDates)
    : reservationDates
    ? formatDate(reservationDates)
    : null

  return (
    <Wrapper>
      <FlexRow justifyContent="space-between">
        <div>
          <OrderNumber>#{orderNumber}</OrderNumber>
          <SalesName>{name}</SalesName>
        </div>

        <SalesStateChip
          color={getSalesStateColor(state)}
          size="sm"
          variant="outlined"
        >
          <T>{`SalesDetails:Lifecycle.state.${state}`}</T>
        </SalesStateChip>
      </FlexRow>

      {salesDates && <Row>{salesDates}</Row>}

      {!!particicantsTotals.length && (
        <Row>
          {particicantsTotals
            .map((t) =>
              particicantsTotals.length === 1 && !t.ageCategory
                ? translate('Mobile:salesList.participants.title', language, {
                    count: t.amount,
                  })
                : `${t.amount} × ${
                    t.ageCategory?.name ||
                    translate('common:helpers.more', language)
                  }`
            )
            .join(', ')}
        </Row>
      )}
    </Wrapper>
  )
}

/////

const Row = styled.span`
  margin-top: 4px;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const OrderNumber = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const SalesName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`

const SalesStateChip = styled(Chip)`
  height: fit-content;
  width: fit-content;
  margin-top: -2px;
  margin-right: -2px;
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2.5)}rem ${theme.spacing.gu(2.5)}rem
      ${theme.spacing.gu(2.5)}rem ${theme.spacing.gu(3)}rem;
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
