import { ReactNode, useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'

import { CheckboxInput, Input } from '@/components/FormControls'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Option } from '../types'

type Props = {
  loading: boolean
  modalWidth: string
  noOptionsPlaceholder?: ReactNode | string
  options: Option[]
  setValues: (values: string[]) => void
  values: string[]
  withSearch?: boolean
  withSelectAll?: boolean
}

export const OptionsModal = ({
  loading,
  modalWidth,
  noOptionsPlaceholder,
  options,
  setValues,
  values,
  withSearch,
  withSelectAll,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const [searchValue, setSearchValue] = useState<string>('')

  const isAllSelected = options.length === values.length

  const handleToggleAll = () =>
    setValues(isAllSelected ? [] : options.map(({ value }) => value))

  const handleSelect = (targetValue: string) => {
    const isSelected = !!values.find((v) => v === targetValue)

    setValues(
      isSelected
        ? values.filter((v) => v !== targetValue)
        : [...values, targetValue]
    )
  }

  const filteredOptions = useMemo(
    () =>
      options.filter((o: Option) =>
        withSearch && o.searchValue
          ? o.searchValue.toLowerCase().includes(searchValue.toLowerCase())
          : true
      ),
    [options, searchValue, withSearch]
  )

  const renderContent = () => (
    <>
      {withSelectAll && (
        <>
          <SelectAllWrapper noPadding flex={1}>
            <CheckboxWrapper alignItems="center">
              <CheckboxInput
                checked={isAllSelected}
                noMargin
                onChange={handleToggleAll}
                labelStyle={{ width: '100%' }}
              >
                <CheckboxLabel flex={1} justifyContent="space-between">
                  <T>ThemedSelect:selectAll</T>
                  <LightLabel>{options.length}</LightLabel>
                </CheckboxLabel>
              </CheckboxInput>
            </CheckboxWrapper>
          </SelectAllWrapper>

          <Divider />
        </>
      )}

      {withSearch && (
        <>
          <SearchInput alignItems="center">
            <SearchIcon justifyContent="center" flex="none">
              <FontAwesomeIcon
                color={palette.primary.main}
                icon="magnifying-glass"
              />
            </SearchIcon>
            <Input
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSearchValue(event.target.value)
              }
              placeholder={translate('ThemedSelect:search', language)}
              style={{ paddingLeft: 0 }}
              value={searchValue}
            />
          </SearchInput>

          <Divider />
        </>
      )}

      {filteredOptions.length ? (
        <OptionsWrapper>
          <FlexColumn noPadding flex={1}>
            {filteredOptions.map(renderOption)}
          </FlexColumn>
        </OptionsWrapper>
      ) : (
        <Section justifyContent="center" flex={1}>
          <Placeholder>
            {noOptionsPlaceholder ?? <T>ThemedSelect:empty</T>}
          </Placeholder>
        </Section>
      )}
    </>
  )

  const renderOption = ({ label, value, isDisabled }: Option) => (
    <CheckboxWrapper alignItems="center" key={value}>
      <CheckboxInput
        checked={!!values.find((v) => v === value)}
        noMargin
        onChange={() => handleSelect(value)}
        disabled={isDisabled}
      >
        <CheckboxLabel>{label}</CheckboxLabel>
      </CheckboxInput>
    </CheckboxWrapper>
  )

  const renderLoader = () => (
    <Section justifyContent="center" flex={1}>
      <ReactLoading
        color={palette.smoke.main}
        height={24}
        type="spin"
        width={24}
      />
    </Section>
  )

  const renderPlaceholder = () => (
    <Section justifyContent="center" flex={1}>
      <Placeholder>
        {noOptionsPlaceholder ?? <T>ThemedSelect:empty</T>}
      </Placeholder>
    </Section>
  )

  return (
    <Modal minWidth={modalWidth}>
      <ContentWrapper>
        {loading
          ? renderLoader()
          : options.length > 0
          ? renderContent()
          : renderPlaceholder()}
      </ContentWrapper>
    </Modal>
  )
}

const CheckboxWrapper = styled(FlexRow)`
  height: 30px;
  padding: 0 10px;
`

const CheckboxLabel = styled(FlexRow)`
  margin-left: 10px;
`

const ContentWrapper = styled.div`
  border-radius: 4px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
  `};
`

const Divider = styled.div`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.dark};
  `}
`

const LightLabel = styled.span`
  ${({ theme }) => `
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Modal = styled(InlineModal)<{ minWidth: string }>`
  border-radius: 4px;
  min-width: ${({ minWidth }) => minWidth};
`

const OptionsWrapper = styled(InlineModalSection)`
  margin-top: 0;
  max-height: calc(30px * 7 + 10px);
  overflow: auto;
  padding: 5px 0;
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const SearchIcon = styled(FlexRow)`
  margin: 0 10px;
  width: 18px;
`

const SearchInput = styled(FlexRow)`
  padding: 2px 0;

  input {
    &:not([type='checkbox']):not([type='radio']) {
      border-radius: 0;
      border: 0;

      &:focus {
        ${({ theme }) => css`
          background: ${theme.palette.white};
        `}
      }

      &::placeholder {
        font-style: italic;

        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`

const Section = styled(FlexRow)`
  padding: 10px;
`

const SelectAllWrapper = styled(FlexColumn)`
  padding: 5px 0;
`
