import styled, { css } from 'styled-components/macro'
import { ComponentType } from 'react'

export const EllipsisLabel = styled.div`
  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(22)}rem;
    margin-right: ${theme.spacing.gu(1)}rem;
  `}

  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
` as ComponentType<any>
