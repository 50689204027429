import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'
import { useState } from 'react'

import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  Reservation,
  resourceReservationHooks,
  ResourceReservationSales,
  ResourceReservationTask,
} from '@/modules/Reservations/ResourceReservation'
import { T } from '@/modules/Language'
import { UseResourceReservationQuery } from '~generated-types'
import { useTheme } from '@/theme'

import { Context, Section } from '../../types'
import { EditButton, Placeholder } from '../common'
import { Task } from './Task'

type Props = {
  context: Context
  readOnly: boolean
  refetchReservation: () => Promise<
    ApolloQueryResult<UseResourceReservationQuery>
  >
  reservationId: string
  sales: ResourceReservationSales
  setCurrentSection: (section: Section) => void
  tasks: ResourceReservationTask[]
  updateReservations: (reservations: Reservation[]) => void
}

export const Tasks = ({
  context,
  readOnly,
  refetchReservation,
  reservationId,
  sales,
  setCurrentSection,
  tasks,
  updateReservations,
}: Props) => {
  const { palette } = useTheme()

  const { createTask } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations,
    })

  const [isHover, setHover] = useState<boolean>(false)
  const [isProcessing, setProcessing] = useState<boolean>(false)

  const handleCreateTask = () => {
    setProcessing(true)

    return createTask(reservationId).then((task) => {
      setProcessing(false)

      setCurrentSection({
        taskId: (task as ResourceReservationTask).id || '',
        type: 'TASK_MANAGER',
      })
    })
  }

  return (
    <>
      {[...tasks]
        .sort((a, b) => {
          const toDate = (date: string, time: string | null) =>
            new Date(time ? `${date}T${time}` : date)

          if (a.dueDate && b.dueDate) {
            return (
              moment(toDate(a.dueDate, a.dueTime)).valueOf() -
              moment(toDate(b.dueDate, b.dueTime)).valueOf()
            )
          }

          return -1
        })
        .map((task: ResourceReservationTask) => (
          <Task
            context={context}
            key={task.id}
            readOnly={readOnly}
            refetchReservation={refetchReservation}
            reservationId={reservationId}
            sales={sales}
            setCurrentSection={setCurrentSection}
            task={task}
            updateReservations={updateReservations}
          />
        ))}

      {!readOnly && (
        <>
          <InlineModalLine />
          <InlineModalIconSection icon={['far', 'square-check']}>
            <EditButton
              onClick={handleCreateTask}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <Placeholder>
                <T>ReservationModal:action.addTask</T>
              </Placeholder>

              <Spacer />

              {isProcessing ? (
                <ReactLoading
                  color={palette.smoke.dark}
                  height={17}
                  type="spin"
                  width={17}
                />
              ) : (
                isHover && (
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="plus"
                    size="sm"
                  />
                )
              )}
            </EditButton>
          </InlineModalIconSection>
        </>
      )}
    </>
  )
}

////////////

const Spacer = styled.div`
  flex: 1;
`
