import {
  ElasticSaleStatus,
  ElasticSaleRoomReservation as RoomReservation,
  ElasticSaleRoomTypeReservation as RoomTypeReservation,
  Sale,
} from '../'

const getRequestedTotalRooms = (
  roomTypeReservations: RoomTypeReservation[],
  toplevelRoomReservations: RoomReservation[]
) => {
  const totalNestedRooms = roomTypeReservations.reduce(
    (acc: number, r: RoomTypeReservation) => acc + r.rooms,
    0
  )

  return toplevelRoomReservations.length + totalNestedRooms
}

const getTotalToplevelRoomBeds = (
  toplevelRoomReservations: RoomReservation[],
  field: string
) =>
  toplevelRoomReservations.reduce(
    // @ts-ignore
    (acc: number, r: RoomReservation) => acc + r[field],
    0
  )

const getRequestedTotalBeds = (
  roomTypeReservations: RoomTypeReservation[],
  toplevelRoomReservations: RoomReservation[],
  field: string
) => {
  const totalNestedRoomBeds = roomTypeReservations.reduce(
    // @ts-ignore
    (acc: number, r: RoomTypeReservation) => acc + r[field],
    0
  )

  return (
    getTotalToplevelRoomBeds(toplevelRoomReservations, field) +
    totalNestedRoomBeds
  )
}

const getTotalBeds = (
  roomTypeReservations: RoomTypeReservation[],
  toplevelRoomReservations: RoomReservation[],
  field: string
) => {
  const totalNestedRoomBeds = roomTypeReservations.reduce(
    (acc: number, r: RoomTypeReservation) =>
      acc +
      r.roomReservations.reduce(
        // @ts-ignore
        (acc: number, r: RoomReservation) => acc + r[field],
        0
      ),
    0
  )

  return (
    getTotalToplevelRoomBeds(toplevelRoomReservations, field) +
    totalNestedRoomBeds
  )
}

const getTotalRooms = (
  roomTypeReservations: RoomTypeReservation[],
  toplevelRoomReservations: RoomReservation[]
) => {
  const totalNestedRooms = roomTypeReservations.reduce(
    (acc: number, r: RoomTypeReservation) => acc + r.roomReservations.length,
    0
  )

  return toplevelRoomReservations.length + totalNestedRooms
}

const getStatus = (reservations: RoomTypeReservation[]): ElasticSaleStatus =>
  reservations.every((r: RoomTypeReservation) => r.fulfilledByRooms)
    ? 'READY'
    : reservations.some((r: RoomTypeReservation) => r.fulfilledByRooms)
    ? 'IN_PROGRESS'
    : 'NOT_READY'

export const getSaleDataFromElastic = ({
  customerName,
  customerNumber,
  estimatedDates,
  name,
  orderNumber,
  roomTypeReservations,
  toplevelRoomReservations,
}: Sale) => ({
  customerName: customerName || null,
  customerNumber: customerNumber || null,
  estimatedDates: estimatedDates || null,
  requestedTotalNumbers: {
    beds: getRequestedTotalBeds(
      roomTypeReservations,
      toplevelRoomReservations,
      'beds'
    ),
    extraBeds: getRequestedTotalBeds(
      roomTypeReservations,
      toplevelRoomReservations,
      'extraBeds'
    ),
    rooms: getRequestedTotalRooms(
      roomTypeReservations,
      toplevelRoomReservations
    ),
  },
  saleName: name || null,
  saleNumber: orderNumber,
  status: getStatus(roomTypeReservations),
  totalNumbers: {
    beds: getTotalBeds(roomTypeReservations, toplevelRoomReservations, 'beds'),
    extraBeds: getTotalBeds(
      roomTypeReservations,
      toplevelRoomReservations,
      'extraBeds'
    ),
    rooms: getTotalRooms(roomTypeReservations, toplevelRoomReservations),
  },
})
