import { Fragment, Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { useGlobalIconsContext, useTheme } from '@/theme'
import { CheckboxInput } from '@/components/FormControls'
import { generateCompareFn } from '@/utils/arrays'
import { ModalContainer } from '@/components/Modal'
import { RoomFeature } from '@/modules/Reservations/components/RoomLayout'
import { T } from '@/modules/Language'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'

type Props = {
  note: string
  reservationId: string
  selectedFeatures: RoomFeature[]
}

type Feature = RoomFeature & {
  selected: boolean
}

export const Features = ({ note, reservationId, selectedFeatures }: Props) => {
  const theme = useTheme()

  const { checkIconValidity } = useGlobalIconsContext()
  const { setNeeds, roomFeatures } = useReservationListContext()

  const getCurrentFeatures = () =>
    roomFeatures.map((feature: RoomFeature) => ({
      ...feature,
      selected: !!selectedFeatures.find(
        (selectedFeature: RoomFeature) => selectedFeature.id === feature.id
      ),
    }))

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [features, setFeatuers] = useState<Feature[]>(getCurrentFeatures())

  return (
    <FlexRow alignItems="center">
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <InlineModal>
            <InlineModalSection
              style={{ margin: 0, padding: theme.spacing.gutter }}
            >
              <FlexColumn noPadding>
                {[...features]
                  .sort(generateCompareFn('name'))
                  .map((feature: Feature) => (
                    <CheckboxWrapper key={feature.id}>
                      <CheckboxInput
                        checked={feature.selected}
                        noMargin
                        onChange={() =>
                          setFeatuers(
                            features.map((f: Feature) =>
                              f.id === feature.id
                                ? { ...f, selected: !f.selected }
                                : f
                            )
                          )
                        }
                      >
                        <FlexRow alignItems="center">
                          <CheckboxLabel>{feature.name}</CheckboxLabel>
                          {checkIconValidity(feature.icon) && (
                            <FontAwesomeIcon
                              fixedWidth
                              icon={feature.icon as IconProp}
                              size="sm"
                            />
                          )}
                        </FlexRow>
                      </CheckboxInput>
                    </CheckboxWrapper>
                  ))}
              </FlexColumn>
            </InlineModalSection>
          </InlineModal>
        }
        onClose={() => {
          setModalOpen(false)
          setNeeds({
            featureIds: features
              .filter((f: Feature) => f.selected)
              .map((f: Feature) => f.id),
            note,
            reservationId,
          })
        }}
        placement="bottom"
        referenceElement={({ ref }) => (
          <ToggleFeatures
            isEmpty={!selectedFeatures.length}
            onClick={() => setModalOpen(true)}
            ref={ref as Ref<HTMLButtonElement> | undefined}
          >
            {selectedFeatures.length ? (
              [...selectedFeatures]
                .sort(generateCompareFn('name'))
                .map((feature: RoomFeature) => (
                  <Fragment key={feature.id}>
                    {checkIconValidity(feature.icon) ? (
                      <FontAwesomeIcon
                        fixedWidth
                        icon={feature.icon as IconProp}
                        style={{ marginRight: theme.spacing.gutterSmall }}
                      />
                    ) : (
                      <ShortName>{feature.shortName}</ShortName>
                    )}
                  </Fragment>
                ))
            ) : (
              <T>Accommodation:SalesReservationList.addFeatures</T>
            )}
          </ToggleFeatures>
        )}
      />
    </FlexRow>
  )
}

////////////

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const CheckboxWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}

  &:last-child {
    margin-bottom: 0;
  }
`

const ToggleFeatures = styled.button<any>`
  display: inline-block;
  width: 100%;
  text-align: start;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  border-radius: 6px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: 1px solid ${theme.palette.smoke.dark};
    padding: ${theme.spacing.gu(1)}rem;
  `}

  color: ${(props) =>
    props.isEmpty
      ? props.theme.palette.text.lighter
      : props.theme.palette.text.light};
  cursor: pointer;
  outline: none;

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
      border: 1px solid ${theme.palette.primary.main};
    `}
  }
`

const ShortName = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
