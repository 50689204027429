import styled, { css } from 'styled-components/macro'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'

import { DocumentPreview, DocumentsService } from '@/modules/Document'
import { FetchState, FetchStates } from '@/common/types'
import { InvoiceState, PaymentState, PaymentType } from '~generated-types'
import {
  SideDrawer,
  SideDrawerBottomControls,
  SideDrawerShadow,
} from '@/components/SideDrawer'
import { FlexColumn } from '@/components/Layout'
import { Gutter } from '@/components/Layout/Gutter'
import { InnocuousButton } from '@/components/ExtraButtons'
import { Invoice } from '@/modules/Order/types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Actions, PaymentManager, Payments, Title, Totals } from './components'

type Props = {
  documentId: string
  invoice: Invoice
  onClose: () => void
  orderId: string
}

export const PaymentsModal = ({
  documentId,
  invoice: {
    id: invoiceId,
    lifecycle: { state },
    paymentInfo: {
      payableAmount,
      totalPrice: { amountVatIncluded: totalAmount },
    },
    payments,
    type: phase,
  },
  onClose,
  orderId,
}: Props) => {
  const { spacing } = useTheme()
  const { pathname, search } = useLocation()

  const [refreshDocumentTicker, setRefreshDocumentTicker] = useState<number>(0)
  const [PDFUrl, setPDFUrl] = useState<string>('')
  const [PDFState, setPDFState] = useState<FetchState>(FetchStates.IDLE)

  const manualPayments = payments.filter(
    ({ type, state }) =>
      state !== PaymentState.Failed &&
      (type === PaymentType.Cash ||
        type === PaymentType.CreditCard ||
        type === PaymentType.GiftCard ||
        type === PaymentType.Voucher)
  )
  const isDisabled = !!manualPayments.length && state !== InvoiceState.Accepted

  const refreshPDFContent = useCallback(async () => {
    setPDFState(FetchStates.LOADING)

    try {
      const url = await DocumentsService.buildPdf(documentId)
      setPDFUrl(url)
      setPDFState(FetchStates.IDLE)
    } catch (err) {
      setPDFState(FetchStates.ERROR)
      console.error('Failed to download', err)
    }
  }, [documentId])

  useEffect(() => {
    refreshPDFContent()
  }, [refreshPDFContent, refreshDocumentTicker])

  const handlePrint = async (forceRefresh?: boolean) => {
    const triggerPrint = () => {
      const iframe = document.getElementById(
        'iframe-pdf-document'
      ) as HTMLIFrameElement | null
      const iframeWindow = iframe?.contentWindow

      iframe && iframe?.focus()
      iframeWindow && iframeWindow.print()
    }

    if (forceRefresh) {
      await refreshPDFContent()
    }

    triggerPrint()
  }

  const refreshDocument = () => setRefreshDocumentTicker((old) => old + 1)

  return createPortal(
    <>
      <SideDrawer width={`${spacing.gu(170)}rem`}>
        <Container>
          <FlexColumn style={{ width: '35%' }}>
            <Title phase={phase} totalAmount={totalAmount} />

            <Separator />

            <FlexColumn flex={1} style={{ overflow: 'auto' }}>
              <Payments
                invoiceId={invoiceId}
                orderId={orderId}
                payments={payments}
              />

              <Separator />

              <Totals payableAmount={payableAmount} totalAmount={totalAmount} />

              <Separator />

              {payableAmount ? (
                <>
                  <Spacer />

                  <PaymentManager
                    invoiceId={invoiceId}
                    orderId={orderId}
                    payableAmount={payableAmount}
                    payments={payments}
                    refreshDocument={refreshDocument}
                  />
                </>
              ) : (
                <Actions
                  closeModal={onClose}
                  handlePrint={handlePrint}
                  invoiceId={invoiceId}
                  orderId={orderId}
                  state={state}
                />
              )}
            </FlexColumn>
          </FlexColumn>

          <VerticalLine />

          <FlexColumn style={{ padding: spacing.gutterBig, width: '65%' }}>
            <DocumentPreview fetchingState={PDFState} url={PDFUrl} />
          </FlexColumn>
        </Container>

        <SideDrawerBottomControls>
          <Gutter type={[2, 2]}>
            <InnocuousButton
              disabled={isDisabled}
              noNudge
              noMargin
              onClick={onClose}
            >
              <T>common:action.close</T>
            </InnocuousButton>
          </Gutter>
        </SideDrawerBottomControls>
      </SideDrawer>

      <SideDrawerShadow
        to={`${pathname}${search}`}
        onClick={isDisabled ? undefined : onClose}
      />
    </>,
    document.getElementById('modal-root') as any
  )
}

///////

const Container = styled.div`
  display: flex;
  height: 100%;
`

const Separator = styled.div`
  ${({ theme }) => css`
    border-bottom: 2px solid ${theme.palette.smoke.light};
  `}
`

const Spacer = styled.div`
  flex: 1;
`

const VerticalLine = styled.div`
  width: 2px;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.light};
  `}
`
