import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { ProbabilitySource, SalesType } from '~generated-types'
import { Spacing, useTheme } from '@/theme'
import { EditButton } from '@/components/ExtraButtons'
import { FlexColumn } from '@/components/Layout'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'

import { useSalesDetailsContext } from '../../../..'

type Props = {
  source: ProbabilitySource
}

export const Source = ({ source }: Props) => {
  const { spacing } = useTheme()
  const {
    data: { id, type },
    saleReadOnly: readOnly,
    updateAttributes,
  } = useSalesDetailsContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState(false)

  const sourceOptions = [
    {
      label: <T>{`Sales:entity.typeShort.${ProbabilitySource.Enrollment}`}</T>,
      value: ProbabilitySource.Enrollment,
    },
    {
      label: <T>{`Sales:entity.typeShort.${ProbabilitySource.Event}`}</T>,
      value: ProbabilitySource.Event,
    },
  ]

  const handleSetSource = (option?: Option | null) => {
    if (option) {
      setProcessing(true)

      updateAttributes(id, {
        probabilitySource:
          option.value === ProbabilitySource.Enrollment
            ? ProbabilitySource.Enrollment
            : ProbabilitySource.Event,
      })
        .catch(() => undefined)
        .finally(() => {
          setProcessing(false)
          setEditMode(false)
        })
    }
  }

  return (
    <Wrapper flex={1}>
      <Label>
        <T>SalesDetails:Settings.probability.source</T>
      </Label>

      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(spacing)}
          isCompact
          isLoading={processing}
          menuIsOpen={isEditMode}
          name="language-selector"
          noOptionsMessage={() => <T>SalesDetails:Language.noLanguages</T>}
          onChange={handleSetSource}
          onBlur={() => setEditMode(false)}
          options={sourceOptions}
          placeholder="—"
          value={{
            label: <T>{`Sales:entity.typeShort.${source}`}</T>,
            value: source,
          }}
        />
      ) : (
        <EditButton
          disabled={type === SalesType.Enrollment || readOnly}
          onClick={() => setEditMode(true)}
        >
          <T>{`Sales:entity.typeShort.${source}`}</T>
        </EditButton>
      )}
    </Wrapper>
  )
}

/////

const getExtraStyles = (spacing: Spacing) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    zIndex: 600,
  }),
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${spacing.gu(1)}rem`,
    minHeight: '30px',
    width: `calc(100% + ${spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${spacing.gu(1)}rem`,
    width: `calc(100% + ${spacing.gu(2)}rem)`,
    zIndex: 2,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
  }),
})

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
    margin-right: ${theme.spacing.gu(2)}rem;
  `}
`
