import { Fragment, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'

import {
  EventEnrollment,
  Participant,
  SetDatesInput,
} from '@/modules/ParticipantsList'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ParticipantServiceFragment,
  PurchaseProductAddFromCatalogProductInput,
  PurchaseProductAddFromSalesProductInput,
  SalesType,
  ServiceParticipantBedFragment,
} from '~generated-types'

import { AccommodationService } from './AccommodationService'
import { NewServiceModal } from './NewServiceModal'
import { ParticipantDayVisitor } from '../ParticipantDayVisitorLine'
import { ParticipantPurchaseService } from './PurchaseService'

type Props = {
  participant: Participant
  isEventParticipant?: boolean
  participantSaleCustomer?:
    | NonNullable<EventEnrollment['customer']>['customer']
    | null
  services: ParticipantServiceFragment[]
  allowAccommodation: boolean
  isDayVisitor: boolean
  isMassUpdate?: boolean
  actionsDisabled: boolean
  saleType: SalesType | null
  readOnly?: boolean
  creatingNew: 'BED' | 'PRODUCT' | null
  checkIn?: string | null
  checkOut?: string | null
  setCreatingNew: (creatingNew: 'BED' | 'PRODUCT' | null) => void
  handleAddRoom: (input: {
    roomReservationId: string
    serviceId: string | null
  }) => Promise<any>
  handleDeleteService: (id: string) => Promise<any>
  handleServiceSetDates: (
    input: SetDatesInput,
    participantId: string
  ) => Promise<any>
  handleSetCheckIn: (
    roomReservationId: string,
    providedTime: string | null | undefined,
    serviceId: string
  ) => Promise<void>
  handleSetCheckOut: (
    roomReservationId: string,
    providedTime: string | null | undefined,
    serviceId: string
  ) => Promise<void>
  handleSetParticipantTarget: (input: {
    targetId: string
    serviceId: string | null
  }) => Promise<any>
  handleRemoveRoom: (roomId: string, serviceId: string) => Promise<any>
  handleRemovePurchase: (
    purchaseId: string,
    participantId: string
  ) => Promise<any>
  handleSetVisitStatus: (
    dayVisitorDates: {
      end: string
      start: string
    } | null,
    isDayVisitor: boolean
  ) => Promise<any>
  handleAddCatalogProduct: (
    input: PurchaseProductAddFromCatalogProductInput
  ) => Promise<any>
  handleAddSalesProduct: (
    input: PurchaseProductAddFromSalesProductInput
  ) => Promise<any>
}

const ParticipantServices = ({
  participant,
  isEventParticipant,
  participantSaleCustomer,
  services,
  allowAccommodation,
  isDayVisitor,
  isMassUpdate,
  actionsDisabled,
  saleType,
  readOnly,
  creatingNew,
  checkIn,
  checkOut,
  setCreatingNew,
  handleAddRoom,
  handleDeleteService,
  handleServiceSetDates,
  handleSetCheckIn,
  handleSetCheckOut,
  handleSetParticipantTarget,
  handleRemoveRoom,
  handleRemovePurchase,
  handleSetVisitStatus,
  handleAddCatalogProduct,
  handleAddSalesProduct,
}: Props) => {
  useEffect(() => {
    !services.length &&
      !creatingNew &&
      !isDayVisitor &&
      allowAccommodation &&
      setCreatingNew('BED')
    services.length && creatingNew && setCreatingNew(null)
  }, [services])

  useEffect(() => {
    !services.length &&
      (!isDayVisitor && allowAccommodation
        ? setCreatingNew('BED')
        : setCreatingNew(null))
  }, [isDayVisitor])

  return (
    <FlexRow flex={1}>
      <NewServiceModal
        allowAccommodation={allowAccommodation}
        creatingNew={creatingNew}
        handleAddCatalogProduct={handleAddCatalogProduct}
        handleAddSalesProduct={handleAddSalesProduct}
        isActive={!!creatingNew}
        isVisible={!readOnly}
        participantId={participant.id}
        participantSalesId={participant.sales.id}
        setCreatingNew={setCreatingNew}
      />

      <FlexColumn flex={1}>
        {isDayVisitor && (
          <>
            <ParticipantDayVisitor
              dates={participant.visitStatus.dayVisitorDates}
              readOnly={readOnly}
              setVisitDates={(
                dateRange: {
                  end: string
                  start: string
                } | null
              ) =>
                handleSetVisitStatus(
                  dateRange?.end && dateRange?.start ? dateRange : null,
                  true
                )
              }
            />
            {(services.length || creatingNew) && <ServiceDivider />}
          </>
        )}
        {[
          ...services,
          ...(creatingNew === 'BED'
            ? [
                {
                  __typename: 'ServiceParticipantBed',
                  accommodationTarget: null,
                  dates: null,
                  id: `PENDING-${participant.id}`,
                  participantRoom: null,
                  purchaseProduct: null,
                } as ServiceParticipantBedFragment,
              ]
            : []),
        ].map((service, index) => (
          <Fragment key={`wrapper-${service.id}`}>
            {index !== 0 && <ServiceDivider key={`divider-${service.id}`} />}
            {service.__typename === 'ServiceParticipantBed' ? (
              <AccommodationService
                key={service.id}
                participantId={participant.id}
                isEventParticipant={isEventParticipant}
                participantSaleCustomer={participantSaleCustomer || null}
                service={service}
                participantSalesId={participant.sales.id}
                actionsDisabled={actionsDisabled}
                isMassUpdate={isMassUpdate}
                saleType={saleType}
                checkAvailable={!!service.participantRoom?.id}
                checkIn={
                  service.participantRoom?.checkIn !== undefined
                    ? service.participantRoom?.checkIn
                    : checkIn
                }
                checkOut={
                  service.participantRoom?.checkOut !== undefined
                    ? service.participantRoom?.checkOut
                    : checkOut
                }
                setCreatingNew={setCreatingNew}
                handleAddRoom={handleAddRoom}
                handleDeleteService={handleDeleteService}
                handleAddCatalogProduct={handleAddCatalogProduct}
                handleAddSalesProduct={handleAddSalesProduct}
                handleRemovePurchase={(id: string) =>
                  handleRemovePurchase(id, participant.id)
                }
                handleServiceSetDates={handleServiceSetDates}
                handleSetCheckIn={(providedTime, serviceId) =>
                  handleSetCheckIn(
                    service.participantRoom?.id || '',
                    providedTime,
                    serviceId
                  )
                }
                handleSetCheckOut={(providedTime, serviceId) =>
                  handleSetCheckOut(
                    service.participantRoom?.id || '',
                    providedTime,
                    serviceId
                  )
                }
                handleSetParticipantTarget={handleSetParticipantTarget}
                handleRemoveRoom={handleRemoveRoom}
              />
            ) : (
              service.__typename === 'ServicePurchase' && (
                <ParticipantPurchaseService
                  actionsDisabled={actionsDisabled}
                  handleRemovePurchase={(id: string) =>
                    handleRemovePurchase(id, participant.id)
                  }
                  isMassUpdate={isMassUpdate}
                  key={service.id}
                  service={service}
                />
              )
            )}
          </Fragment>
        ))}
      </FlexColumn>
    </FlexRow>
  )
}

export default ParticipantServices

/////

const ServiceDivider = styled.div`
  width: 100%;

  ${({ theme }) => css`
    border-top: 1px dashed ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`
