import { gql, useQuery } from '@apollo/client'

import type {
  MealsBySalesQuery as QueryData,
  MealsBySalesQueryVariables as QueryVariables,
} from '~generated-types'

import { Meal } from '../types'
import { MealFragment } from '../fragments'

const QUERY = gql`
  ${MealFragment}

  query MealsBySales($salesId: ID!) {
    scheduledMealsBySales(salesId: $salesId) {
      ...Meal
    }
  }
`

type Params = {
  salesId: string
}

export const useMealsBySales = ({ salesId }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'cache-and-network',
      variables: { salesId },
    }
  )

  const meals: Meal[] = data?.scheduledMealsBySales || []

  return {
    error,
    loading,
    meals,
    refetch,
  }
}
