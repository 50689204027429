import styled, { css } from 'styled-components/macro'

import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Column, Header, Row, ShortSeparator } from '../../../common'
import { MealRow } from './MealRow'
import { MealRowsByDate } from '../../../../types'
import { useMealsContext } from '../../../../MealsState'

type Props = {
  day: MealRowsByDate
}

export const MealSection = ({ day: { meals } }: Props) => {
  const { spacing } = useTheme()
  const { isEditMode } = useMealsContext()

  return (
    <Column>
      <Row style={{ height: `${spacing.gu(3)}rem` }}>
        <Spacer isEditMode={isEditMode} />

        <Header width={`${spacing.gu(10)}rem`}>
          <T>SalesDetails:meals.title.time</T>
        </Header>

        <ShortSeparator />

        <Header width={`${spacing.gu(28)}rem`}>
          <T>SalesDetails:meals.title.meal</T>
        </Header>

        <ShortSeparator />

        <Header width={`${spacing.gu(12)}rem`}>
          <T>SalesDetails:meals.title.duration</T>
        </Header>

        <ShortSeparator />

        <Header width={`${spacing.gu(18)}rem`}>
          <T>SalesDetails:meals.title.restaurant</T>
        </Header>
      </Row>

      <Column>
        {meals.map((item) => (
          <MealRow key={item.rowId} meal={item} />
        ))}
      </Column>
    </Column>
  )
}

///////

const Spacer = styled.div<{ isEditMode: boolean }>`
  ${({ isEditMode, theme }) => css`
    margin-left: ${theme.spacing.gu(isEditMode ? 4 : 2)}rem;
  `}
`
