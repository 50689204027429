import { gql } from '@apollo/client'

import { customerFragments } from '@/modules/Registry/Customer'

export const EventEnrollmentFragment = gql`
  ${customerFragments.customerReference}

  fragment EventEnrollment on Sales {
    id
    type
    orderNumber
    name
    notes
    customer {
      ...CustomerReference
    }
    locked
    lifecycle {
      state
      reason
      specifier
    }
    reservationDates {
      start
      end
    }
    estimatedDates {
      start
      end
    }
    estimatedParticipants
    participantConnection(input: { pagination: { size: 5, page: 0 } }) {
      totalElements
      nodes {
        id
        firstName
        lastName
      }
    }
    orders {
      paymentInfo {
        totalPrice {
          amountVatIncluded
        }
      }
    }
    paymentAgreement {
      id
      name
      code
    }
  }
`

export const EventSalesFragment = gql`
  ${EventEnrollmentFragment}

  fragment EventSales on SalesConnection {
    nodes {
      ...EventEnrollment
    }
    totalPages
    totalElements
    hasNextPage
    hasPreviousPage
  }
`
