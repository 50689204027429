import { gql, useMutation } from '@apollo/client'

import { MealFragment } from '../fragments'

import type {
  SetMealNotesMutation,
  SetMealNotesMutationVariables,
} from '~generated-types'

const SET_MEAL_NOTES_MUTATION = gql`
  ${MealFragment}

  mutation SetMealNotes($id: ID!, $input: ScheduledMealSetNotes!) {
    scheduledMealSetNotes(id: $id, input: $input) {
      ...Meal
    }
  }
`

export const useSetMealNotesMutation = () =>
  useMutation<SetMealNotesMutation, SetMealNotesMutationVariables>(
    SET_MEAL_NOTES_MUTATION
  )
