import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'
import { useQuery } from '@apollo/client'

import { SaleTasksQuery, SaleTasksQueryVariables } from '~generated-types'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ContentSection } from '../../../components'
import SaleTasks from '../SaleTasks.query'
import { Task } from './Task'
import { useSalesDetailsContext } from '../../../'

export const TasksListContainer = () => {
  const theme = useTheme()

  const {
    data: { id },
  } = useSalesDetailsContext()

  const { data, loading } = useQuery<SaleTasksQuery, SaleTasksQueryVariables>(
    SaleTasks,
    {
      variables: { id },
    }
  )

  const tasks = data?.sales.tasks

  return (
    <ContentSection title={<T>SalesDetails:Checks.sectionTitle</T>} flex={3}>
      <Wrapper>
        {loading ? (
          <ReactLoading
            color={theme.palette.smoke.main}
            type={'spin'}
            height={24}
            width={24}
          />
        ) : tasks ? (
          tasks.map((task) => <Task key={task.id} task={task} />)
        ) : (
          <>
            – <T>common:error:common</T> –
          </>
        )}
      </Wrapper>
    </ContentSection>
  )
}

const Wrapper = styled(FlexColumn)`
  align-items: flex-start;
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background-color: ${theme.palette.white};
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(2)}rem
      ${theme.spacing.gu(1)}rem;
  `}
`
