import moment, { HTML5_FMT, Moment } from 'moment'

import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { ElasticRoomReservation } from '@/modules/Reservations/components/RoomLayout'

import { generateCompareFn } from '@/utils/arrays'

interface Input {
  isoWeek: number
  isoWeekYear: number
  reservations: ElasticRoomReservation[]
}

interface RowCell {
  colSpan: number
  overflowEnd?: boolean
  overflowStart?: boolean
  reservation: ElasticRoomReservation | null
}

export default function calculateRoomReservationRows({
  isoWeek,
  isoWeekYear,
  reservations,
}: Input): RowCell[][] {
  const offsetRows: [ElasticRoomReservation[]] = [[]]

  const weekStart = moment(`${isoWeekYear}W${isoWeek}`, 'YYYY[W}W').startOf(
    'isoWeek'
  )
  const dates = [
    moment(weekStart).add(0, 'days').format(HTML5_FMT.DATE),
    moment(weekStart).add(1, 'days').format(HTML5_FMT.DATE),
    moment(weekStart).add(2, 'days').format(HTML5_FMT.DATE),
    moment(weekStart).add(3, 'days').format(HTML5_FMT.DATE),
    moment(weekStart).add(4, 'days').format(HTML5_FMT.DATE),
    moment(weekStart).add(5, 'days').format(HTML5_FMT.DATE),
    moment(weekStart).add(6, 'days').format(HTML5_FMT.DATE),
  ]

  const getColumnIndex = (date: Moment) => {
    const idx = dates.indexOf(date.format(HTML5_FMT.DATE))

    return idx < 0 ? idx : idx * 2 + 1
  }

  reservations
    .sort(generateCompareFn('request.checkIn.date'))
    .forEach((res: ElasticRoomReservation) => {
      const targetRowIdx = offsetRows.findIndex(
        (rowReservations: ElasticRoomReservation[]) => {
          const { request } = rowReservations.slice(-1)[0] || {}

          if (!request?.checkOut?.date) {
            return true
          }

          // Exception - "checkIn" and "checkOut" of reservations can be in
          // the same cell without moving to a new line.
          if (moment(request.checkOut.date).isSame(res.request.checkIn.date)) {
            if (
              request.checkOut.type === CheckOutTypes.STANDARD &&
              res.request.checkIn.type === CheckInTypes.STANDARD
            ) {
              return true
            }
            return false
          }

          return moment(request.checkOut.date).isSameOrBefore(
            res.request.checkIn.date
          )
        }
      )

      if (targetRowIdx < 0) {
        offsetRows.push([res])
      } else {
        offsetRows[targetRowIdx].push(res)
      }
    })

  const rows: RowCell[][] = offsetRows.map((row) => {
    if (!row.length) {
      return [{ colSpan: 14, reservation: null }]
    }

    const columns: RowCell[] = []
    let nextColumnIdx = 0

    row.forEach((reservation) => {
      if (nextColumnIdx > 13) {
        return
      }

      const { checkIn, checkOut } = reservation.request
      const checkInIdx = getColumnIndex(moment(checkIn.date)) // -1 or specific index
      const rawCheckOutIdx = getColumnIndex(moment(checkOut.date))
      const checkOutIdx = rawCheckOutIdx < 0 ? 14 : rawCheckOutIdx // 14 or specific index

      if (nextColumnIdx < checkInIdx) {
        columns.push({
          colSpan: checkInIdx - nextColumnIdx,
          reservation: null,
        })
      }

      columns.push({
        colSpan:
          checkOutIdx -
          (checkInIdx < nextColumnIdx ? nextColumnIdx : checkInIdx),
        overflowEnd: checkOutIdx > 13,
        overflowStart: checkInIdx < nextColumnIdx,
        reservation,
      })

      nextColumnIdx = checkOutIdx
    })

    if (nextColumnIdx < 14) {
      columns.push({
        colSpan: 14 - nextColumnIdx,
        reservation: null,
      })
    }

    return columns
  })

  return rows
}
