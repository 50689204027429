import { memo, MouseEvent, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import {
  InlineModal,
  InlineModalContent,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  Reservation,
  ResourceReservation,
  ResourceReservationGroup,
  resourceReservationHooks,
  ResourceReservationTask,
} from '@/modules/Reservations/ResourceReservation'
import { Theme, useTheme } from '@/theme'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { Context, Section, SectionType } from './types'
import {
  GroupManager,
  OwnerSearchManager,
  PurchaseSearchManager,
  TaskManager,
} from './Sections'
import { Footer } from './components'
import { ReservationManager } from './ReservationManager'

type Props = {
  context: Context
  contextId?: string | null
  group: ResourceReservationGroup
  onClose: (event: MouseEvent) => void
  readOnly: boolean
  reservation: Reservation
  updateReservations: (reservations: Reservation[]) => void
}

export const ReservationManagerContainer = memo(
  ({
    context,
    contextId,
    group,
    onClose,
    readOnly,
    reservation,
    updateReservations,
  }: Props) => {
    const { palette, spacing } = useTheme()

    const isReservationFull =
      (reservation as ResourceReservation)?.tasks !== undefined &&
      (reservation as ResourceReservation)?.sales !== undefined

    const {
      error,
      refetch: refetchReservation,
      reservation: fetchedReservation,
    } = resourceReservationHooks.useResourceReservation({
      id: reservation.id,
    })

    const [isLoading, setLoading] = useState<boolean>(!isReservationFull)
    const [currentSection, setCurrentSection] = useState<Section>({
      type: 'MAIN',
    })

    useEffect(() => {
      if (fetchedReservation) {
        updateReservations(
          // @ts-ignore
          (current: Reservation[]) =>
            current.map((res: Reservation) =>
              res.id === reservation.id ? fetchedReservation : res
            )
        )
      }
    }, [fetchedReservation])

    useEffect(() => {
      if (isReservationFull) {
        setLoading(false)
      }
    }, [Object.keys(reservation || {}).length])

    const onBackNavigate = () =>
      currentSection.type !== 'MAIN'
        ? setCurrentSection({
            taskId: currentSection?.taskId,
            type: currentSection?.previous || 'MAIN',
          })
        : null

    const renderContent = () => {
      if (error) {
        return (
          <Placeholder alignItems="center" flex={1} justifyContent="center">
            <T>ReservationModal:error</T>
          </Placeholder>
        )
      }

      if (isLoading) {
        return (
          <LoaderWrapper alignItems="center" flex={1} justifyContent="center">
            <ReactLoading
              color={palette.smoke.main}
              height={40}
              type="spin"
              width={40}
            />
          </LoaderWrapper>
        )
      }

      if (reservation && isReservationFull) {
        const resourceReservation = reservation as ResourceReservation

        const { id, purchaseProducts, sales, tasks } = resourceReservation

        const currentTask = tasks.find(
          (task: ResourceReservationTask) => task.id === currentSection.taskId
        )

        return (
          <>
            <InlineModalContent>
              {currentSection.type === 'MAIN' && (
                <ReservationManager
                  context={context}
                  contextId={contextId}
                  group={group}
                  readOnly={readOnly}
                  refetchReservation={refetchReservation}
                  reservation={resourceReservation}
                  setCurrentSection={setCurrentSection}
                  updateReservations={updateReservations}
                />
              )}

              {currentSection.type === 'GROUP_MANAGER' && group && (
                <GroupManager
                  currentReservationId={id}
                  group={group}
                  readOnly={readOnly}
                  updateReservations={updateReservations}
                />
              )}

              {currentSection.type === 'SALE_SEARCH' && (
                <OwnerSearchManager
                  closeSection={() => setCurrentSection({ type: 'MAIN' })}
                  reservationId={id}
                  updateReservations={updateReservations}
                />
              )}

              {(currentSection.type === 'RESERVATION_PURCHASE_SEARCH' ||
                currentSection.type === 'TASK_PURCHASE_SEARCH') && (
                <PurchaseSearchManager
                  currentSection={currentSection}
                  purchaseId={
                    currentSection.type === 'RESERVATION_PURCHASE_SEARCH'
                      ? purchaseProducts[0]?.id || null
                      : null
                  }
                  reservationId={id}
                  salesId={sales.id}
                  sellerId={sales?.seller?.id}
                  setCurrentSection={setCurrentSection}
                  updateReservations={updateReservations}
                />
              )}

              {currentSection.type === 'TASK_MANAGER' && currentTask && (
                <TaskManager
                  context={context}
                  readOnly={readOnly}
                  refetchReservation={refetchReservation}
                  reservationId={id}
                  sales={sales}
                  setCurrentSection={setCurrentSection}
                  task={currentTask}
                  updateReservations={updateReservations}
                />
              )}
            </InlineModalContent>

            <Footer
              context={context}
              currentSection={currentSection}
              onCloseModal={onClose}
              readOnly={readOnly}
              reservation={resourceReservation}
              setCurrentSection={setCurrentSection}
              updateReservations={updateReservations}
            />
          </>
        )
      }
    }

    return (
      <Modal context={context}>
        <InlineModalHeader
          onClose={(e: MouseEvent) => onClose(e)}
          onBackNavigate={
            currentSection.type === 'MAIN' ? undefined : onBackNavigate
          }
          title={getHeaderTitle(currentSection.type)}
          style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
        />

        <InlineModalLine />

        {renderContent()}
      </Modal>
    )
  }
)

/////////

const getHeaderTitle = (sectionType: SectionType) => {
  switch (sectionType) {
    case 'GROUP_MANAGER':
      return <T>ReservationModal:title.groupManager</T>
    case 'RESERVATION_PURCHASE_SEARCH':
      return <T>ReservationModal:title.selectReservationProduct</T>
    case 'SALE_SEARCH':
      return <T>ReservationModal:title.selectSale</T>
    case 'TASK_MANAGER':
      return <T>ReservationModal:title.taskManager</T>
    case 'TASK_PURCHASE_SEARCH':
      return <T>ReservationModal:title.selectTaskProduct</T>
    case 'MAIN':
      return <T>ReservationModal:title.reservationManager</T>
    default:
      return ''
  }
}

const getModalTopOffset = (context: Context, theme: Theme) => {
  switch (context) {
    case 'SALES':
      return `${theme.spacing.guPx(19) + 3}px`
    case 'BOTTOM_SHEET':
      return `${theme.spacing.guPx(17) + 3}px`
    case 'GLOBAL':
      return `${theme.spacing.guPx(9) + 3}px`
    default:
      return 0
  }
}

const Modal = styled(InlineModal)<{ context: Context }>`
  height: ${({ context, theme }) =>
    `calc(100vh - ${getModalTopOffset(context, theme)} - 10px)`};
  max-height: 100vh;
  width: 500px;

  margin-top: ${({ context, theme }) => getModalTopOffset(context, theme)};
  margin-bottom: 10px;
  margin-right: 10px;
`

const LoaderWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(7)}rem;
  `}
`

const Placeholder = styled(FlexRow)`
  font-weight: 300;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
    margin-bottom: ${theme.spacing.gu(7)}rem;
  `}
`
