import React from 'react'

import { Chip, ChipProps } from '@/components/Chip'
import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'

type Props = ChipProps & {
  format?: 'full' | 'short'
  plainText?: boolean
  type: SalesType
  facet?: {
    abbreviation: string
    color: string
    id: string
    name: string
  }
}

const getChipColor = (type: SalesType) => {
  switch (type) {
    case 'ENROLLMENT':
      return 'secondary'
    case 'EVENT':
      return 'accent1'
    case 'SALES':
      return 'primary'
    default:
      return 'default'
  }
}

const SalesTypeChip = ({
  format = 'short',
  plainText,
  type,
  facet,
  ...rest
}: Props) => {
  return plainText ? (
    <T
      l10n={`Sales:entity.${format === 'full' ? 'type' : 'typeShort'}.${type}`}
    />
  ) : (
    <Chip
      customColor={
        facet
          ? {
              fill: facet?.color,
              stroke: facet?.color,
            }
          : undefined
      }
      color={getChipColor(type)}
      size="xs"
      variant="outlined"
      {...rest}
    >
      {facet ? (
        format === 'full' ? (
          facet.name
        ) : (
          facet.abbreviation
        )
      ) : (
        <T
          l10n={`Sales:entity.${
            format === 'full' ? 'type' : 'typeShort'
          }.${type}`}
        />
      )}
    </Chip>
  )
}

export default SalesTypeChip
