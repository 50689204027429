import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import EndAdornmentWrapper from '@/components/Spreadsheet/elements/EndAdornmentWrapper'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { Input } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  roomId: string
  disabled: boolean
  selectedBeds: number
  bedsAvailable: number
  extraBedsAvailable: number
  onSubmit: (room: { id: string; emptyBeds: number }) => void
}

export const BedsAmount = ({
  roomId,
  disabled,
  bedsAvailable,
  extraBedsAvailable,
  selectedBeds,
  onSubmit,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [beds, setBeds] = useState<number>(selectedBeds)

  useEffect(() => {
    selectedBeds !== beds &&
      setBeds(
        selectedBeds > bedsAvailable + extraBedsAvailable
          ? bedsAvailable + extraBedsAvailable
          : selectedBeds
      )
  }, [selectedBeds])

  return (
    <FlexRow alignItems="center" style={{ marginLeft: `${spacing.gu(1)}rem` }}>
      <FlexRow style={{ position: 'relative' }}>
        <EndAdornmentWrapper style={{ color: palette.primary.main, left: 0 }}>
          <FontAwesomeIcon icon="bed" />
        </EndAdornmentWrapper>

        <StyledInput
          min={0}
          disabled={disabled}
          max={bedsAvailable + extraBedsAvailable}
          onBlur={() => onSubmit({ emptyBeds: beds, id: roomId })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSubmit({ emptyBeds: beds, id: roomId })
            }
          }}
          onChange={(e) =>
            setBeds(
              Number(e.target.value) > bedsAvailable + extraBedsAvailable
                ? bedsAvailable + extraBedsAvailable
                : Number(e.target.value)
            )
          }
          onFocus={(e) => e.currentTarget.select()}
          placeholder={'–'}
          type="number"
          value={`${beds}`}
        />

        <EndAdornmentWrapper style={{ width: `${spacing.gu(3)}rem` }}>
          {'x'}
        </EndAdornmentWrapper>
      </FlexRow>
      <FlexRow
        flex={1}
        style={{
          color: palette.text.light,
          margin: spacing.gutterSmall,
          width: 55,
        }}
      >
        <FontWeight style={{ whiteSpace: 'nowrap' }}>
          ({' '}
          {`${
            beds - Math.sqrt(bedsAvailable * bedsAvailable) >= 0
              ? beds - Math.sqrt(bedsAvailable * bedsAvailable)
              : 0
          }x`}
          <FontAwesomeIcon
            icon="couch"
            color={
              beds - Math.sqrt(bedsAvailable * bedsAvailable) > 0
                ? palette.primary.main
                : palette.smoke.dark
            }
            style={{ marginLeft: spacing.gutterSmall }}
          />{' '}
          )
        </FontWeight>
      </FlexRow>
    </FlexRow>
  )
}

////////

const StyledInput = styled(Input)`
  &&& {
    border: 0;
    border-radius: 0;
    font-weight: 500;
    height: 32px;

    ${({ theme, type }) =>
      type === 'number' &&
      `padding-right: calc(${theme.spacing.gu(2)}rem + 1px)`};

    ${({ theme }) => css`
      padding-left: ${theme.spacing.gu(5)}rem;
      width: ${theme.spacing.gu(9)}rem;
      font-size: ${theme.typography.fontSizeBase};
    `}

    text-align: ${({ type }) => (type === 'number' ? 'end' : 'start')};

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }

    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      font-weight: 400;
      font-style: italic;
      opacity: 1;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }
  }
`
