import { gql, useQuery, useSubscription } from '@apollo/client'
import { useState } from 'react'

import {
  SalesCustomerQuery as QueryData,
  SalesCustomerQueryVariables as QueryVariables,
} from '~generated-types'
import { salesSubscriptions } from '@/modules/Sales'

import customerFragments from '../fragments'
import { CustomerReference } from '../types'

export const QUERY = gql`
  ${customerFragments.customerReference}

  query SalesCustomer($id: ID!) {
    sales(id: $id) {
      customer {
        ...CustomerReference
      }
      id
    }
  }
`

type Params = {
  forceRefetch?: boolean
  salesId: string
}

export default function useSalesCustomer({ forceRefetch, salesId }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: !!forceRefetch ? 'cache-and-network' : 'cache-first',
      variables: { id: salesId },
    }
  )

  const customer: CustomerReference | null | undefined = data
    ? data.sales.customer
    : null

  const [updated, setUpdated] = useState<boolean>(false)
  const [updatedInCurrentSession, setUpdatedInCurrentSession] =
    useState<boolean>(false)

  useSubscription(salesSubscriptions.SALES, {
    onData({ data: { data } }) {
      !updatedInCurrentSession &&
        data.sales.event.__typename === 'SalesCustomerEvent' &&
        setUpdated(true)
      setUpdatedInCurrentSession(false)
      setTimeout(() => setUpdated(false), 500)
      refetch()
    },
    variables: {
      filter: {
        salesId,
      },
    },
  })

  return {
    customer,
    error,
    loading,
    refetch,
    setUpdated: setUpdatedInCurrentSession,
    updated,
  }
}
