import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import {
  DateRange,
  DateRangePicker as DateRangePickerBase,
} from '@/components/TimeControls'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useRouteDateInterval } from '@/utils/hooks'

export const DateRangePicker = () => {
  const { from, setInterval, to } = useRouteDateInterval()

  const handleSetInterval = (range?: DateRange | null) =>
    range ? setInterval(range.from, range.to) : setInterval(moment(), moment())

  return (
    <FilterSection
      label={<T>ResourceReservationsCalendar:interval</T>}
      render={() => (
        <Wrapper justifyContent="center">
          <DateRangePickerBase
            setValue={handleSetInterval}
            value={{ from, to }}
          />
        </Wrapper>
      )}
    />
  )
}

////////////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin: -${theme.spacing.gu(1)}rem;
  `}
`
