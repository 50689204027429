import { DangerColor } from '@/components/Colors'
import { InnocuousButton } from '@/components/ExtraButtons'

import { T } from '@/modules/Language'

type Props = {
  disabled: boolean
  onClick: () => Promise<void> | void
  target: string
}

export const RemoveButton = ({ disabled, onClick, target }: Props) => (
  <InnocuousButton disabled={disabled} onClick={onClick}>
    <DangerColor>
      <T>{`ReservationModal:action.${target}`}</T>
    </DangerColor>
  </InnocuousButton>
)
