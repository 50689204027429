import { Field, FieldProps } from 'formik'
import React, { CElement, ComponentType, createElement, ReactNode } from 'react'
import { get } from 'dot-prop'

import { DangerColor } from '@/components/Colors'
import { T } from '@/modules/Language'

import { InputDescription } from '../InputDescription'

// This component completes these responsibilities:
// (1) It infers its correct value based on its name and value props
// (2) It handles displaying errors correctly
// (3) It allows for itself to be rendered with a function or a component
//    - Components are useful for general cases
//    - Functions are useful for more complex cases, for instance selects
// (?) It displays success states correctly
//    - This could not be done with wrapping; it could be included
//
// It does not, and should not, do: positioning itself, handle displaying
// descriptions etc. Anything which can be done without affecting its internals.
//
// You can wrap this component if you want a more full fledged experience.
//
// Simple responsibilities allow for better composability, which is an issue
// with form components especially. Favour writing flavoured wrappers of this
// helper, instead of increasing its API surface, to allow for it to work in as
// many instances as possible.
//
// Value and error handling have been included because inferring both requires
// Formik logic which would otherwise be duplicated throughout the application.

type InputProps = {
  onChange: (e: any) => any
  onBlur: (e: any) => any
  value: any
  name: string
}
type RenderProps = FieldProps
type Props = {
  name: string
  inputComponent?: ComponentType<InputProps>
  render?: (props: RenderProps) => ReactNode
  disabled?: boolean
  errorDescriptions?: {
    [field: string]: string | CElement<typeof T, any>
  }
  defaultValue?: any
}
export const FormikFormFieldHelper = ({
  name,
  inputComponent,
  render,
  disabled,
  errorDescriptions,
  defaultValue,
}: Props) => (
  <Field name={name}>
    {({ field, form }: any) => {
      const error: string | undefined = get(form.errors, name)
      const errorMessage: string | undefined = get(
        errorDescriptions,
        error || ''
      )

      return (
        <React.Fragment>
          {/* @ts-ignore */}
          <InputWrapper
            field={field}
            form={form}
            errorMessage={errorMessage}
            render={render}
            inputComponent={inputComponent}
            defaultValue={defaultValue}
            disabled={disabled}
          />
          {error && (
            <ErrorWrapper
              error={error}
              hasErrorDescriptions={!!errorDescriptions}
              errorMessage={errorMessage}
            />
          )}
        </React.Fragment>
      )
    }}
  </Field>
)

type IWProps = FieldProps & {
  disabled?: boolean
  errorMessage: string | null | undefined
  render?: (renderProps: RenderProps) => ReactNode
  inputComponent?: ComponentType<InputProps>
  defaultValue: any
}
export const InputWrapper = ({
  field,
  form,
  errorMessage,
  render,
  inputComponent,
  defaultValue,
  ...props
}: IWProps): ReactNode => {
  const noWayToRender = !inputComponent && !render

  if (noWayToRender) {
    throw new Error(
      'FormFieldHelper needs one of inputComponent or render props'
    )
  }

  const valueIsNullOrUndefined =
    field.value === null || field.value === undefined
  const value =
    valueIsNullOrUndefined && defaultValue !== undefined
      ? defaultValue
      : field.value
  const renderProps = {
    field: {
      ...field,
      disabled: props.disabled,
      value,
    },
    form,
    ...props,
  }
  const inputProps = {
    ...field,
    ...props,
    value,
  }
  const input: ReactNode = render
    ? render(renderProps) // input component should always exist at this point, but check for flow
    : inputComponent && createElement(inputComponent, inputProps, null)

  return input
}

type EWProps = {
  errorMessage: string | null | undefined
  hasErrorDescriptions: boolean
  error: string
}
export const ErrorWrapper = ({
  error,
  errorMessage,
  hasErrorDescriptions,
}: EWProps) => (
  <InputDescription>
    <DangerColor>
      {error &&
        !hasErrorDescriptions &&
        'an error occurred, but no error messages are defined'}
      {error && hasErrorDescriptions && (errorMessage || 'empty error message')}
    </DangerColor>
  </InputDescription>
)
