import { createGlobalStyle } from 'styled-components/macro'
import { MultiList as LibraryMultiList } from '@appbaseio/reactivesearch'
import { MultiList as MultiListProps } from '@appbaseio/reactivesearch/lib/components/list/MultiList'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { DisabledColor } from '@/components/Colors'
import { LoadingPlaceholder } from '@/components/Placeholders'

import { inputCss, listCss } from './styles'
import { CollapsedFilterValue } from './CollapsedFilterValue'
import { l10nListItemRenderer } from './utils'

export const inputClassName = 'rs-multi-list-input'
export const labelClassName = 'rs-multi-list-label'
export const wrapperClassName = 'rs-multi-list-wrap'

interface Props extends MultiListProps {
  isCollapsed?: boolean
  l10nPrefix?: string
}

export const MultiList = ({ isCollapsed, l10nPrefix, ...props }: Props) => {
  const { language } = useLanguageContext()

  const customRenderProps = l10nPrefix
    ? {
        renderItem: l10nListItemRenderer(l10nPrefix),
      }
    : {}

  return (
    <>
      <MultiListStyles />
      {isCollapsed && (
        <CollapsedFilterValue
          componentId={props.componentId}
          placeholder={<T>Reactivesearch:unfiltered</T>}
          transformaValue={(value) =>
            !!value
              ? value
                  .split(',')
                  .map((x) => x.trim())
                  .map((x) =>
                    l10nPrefix ? translate(`${l10nPrefix}.${x}`, language) : x
                  )
                  .sort()
                  .join(', ')
              : ''
          }
        />
      )}
      <LibraryMultiList
        {...props}
        {...customRenderProps}
        className={wrapperClassName}
        innerClass={{ input: inputClassName }}
        loader={!isCollapsed ? <LoadingPlaceholder size="lg" /> : null}
        renderError={() =>
          !isCollapsed ? (
            <DisabledColor>
              <T>Reactivesearch:error</T>
            </DisabledColor>
          ) : null
        }
        renderNoResults={() =>
          !isCollapsed ? (
            <DisabledColor>
              <T>Reactivesearch:empty</T>
            </DisabledColor>
          ) : null
        }
        style={isCollapsed ? { display: 'none' } : {}}
      />
    </>
  )
}

////////////

const MultiListStyles = createGlobalStyle`
  .${wrapperClassName} {
    ${listCss}
  }

  .${inputClassName} {
    ${inputCss}
  }
`
