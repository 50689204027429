import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { BoundType, ConditionRuleType } from '~generated-types'
import { translate, useLanguageContext } from '@/modules/Language'
import { CheckboxInput } from '@/components/FormControls'
import { FlexColumn } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { useCatalogProductManagerContext } from '@/modules/Products/hooks'
import { useTheme } from '@/theme'

import { Button } from './common'

type Props = {
  bounds: BoundType[]
  id: string
}

export const BoundarySelector = ({ bounds: selectedBounds, id }: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()
  const { updateProductItemRule } = useCatalogProductManagerContext()

  const [bounds, setBounds] = useState<BoundType[]>(selectedBounds)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)

  const handleSetRuleValue = () => {
    updateProductItemRule({
      condition: {
        bounds,
        ruleType: ConditionRuleType.Boundary,
      },
      productRuleId: id,
    }).then(() => setModalOpen(false))
  }

  const handleUpdateBounds = (bound: BoundType) =>
    setBounds(
      bounds.includes(bound)
        ? bounds.filter((b) => b !== bound)
        : [...bounds, bound]
    )

  const translationPath = 'Products:ProductManager.pricing.rule.bound'

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <Section>
            <CheckboxInput
              checked={bounds.includes(BoundType.Start)}
              noMargin
              onChange={() => handleUpdateBounds(BoundType.Start)}
            >
              <CheckboxLabel>
                {translate(`${translationPath}.${BoundType.Start}`, language)}
              </CheckboxLabel>
            </CheckboxInput>

            <Spacer />

            <CheckboxInput
              checked={bounds.includes(BoundType.End)}
              noMargin
              onChange={() => handleUpdateBounds(BoundType.End)}
            >
              <CheckboxLabel>
                {translate(`${translationPath}.${BoundType.End}`, language)}
              </CheckboxLabel>
            </CheckboxInput>
          </Section>
        </InlineModal>
      }
      onClose={handleSetRuleValue}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Button
          ref={ref as Ref<HTMLButtonElement> | undefined}
          onClick={() => setModalOpen(true)}
        >
          {selectedBounds.length ? (
            selectedBounds
              .map((b) => translate(`${translationPath}.${b}`, language))
              .join(', ')
          ) : (
            <FontAwesomeIcon
              color={palette.primary.main}
              fixedWidth
              size="sm"
              icon="plus"
            />
          )}
        </Button>
      )}
      zIndex={10006}
    />
  )
}

///////

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`
