import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'
import { ReactNode } from 'react'

import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { P } from '@/components/Typography'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { MealsList } from './MealsList'
import { NewMealButton } from './components'
import { useMealsContext } from './MealsState'

export const Meals = () => {
  const { palette } = useTheme()

  const { error, loading, mealsByDate } = useMealsContext()

  if (loading) {
    return (
      <ReactLoading
        color={palette.smoke.main}
        height={24}
        type="spin"
        width={24}
      />
    )
  }

  if (!mealsByDate.length) {
    return (
      <FlexRow justifyContent="space-between">
        <Placeholder>
          <T>SalesDetails:meals.error.empty</T>
        </Placeholder>

        <NewMealButton />
      </FlexRow>
    )
  }

  if (error) {
    return (
      <FlexRow justifyContent="space-between">
        <Placeholder>
          <T>SalesDetails:meals.error.meals</T>
        </Placeholder>

        <NewMealButton />
      </FlexRow>
    )
  }

  return <MealsList />
}

type PlaceholderProps = {
  children: ReactNode
}

const Placeholder = ({ children }: PlaceholderProps) => (
  <Gutter type={[1, 3]}>
    <PlaceholderLabel>{children}</PlaceholderLabel>
  </Gutter>
)

const PlaceholderLabel = styled(P)`
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}
`
