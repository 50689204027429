import styled, { css } from 'styled-components/macro'

import {
  MobileSalesMeal as Meal,
  MobileSalesResourceReservationWithTasks as ReservationWithTasks,
  MobileSalesTask as Task,
} from '@/modules/Mobile/SalesList'
import { generateCompareFn } from '@/utils/arrays'

import { Group, ProgramLine, ProgramTypeKey } from './utils/getProgramByDateKey'
import { InnerContainer, InnerSection } from '../components'
import { MealRow, ReservationGroupRow, ReservationRow, TaskRow } from './Rows'
import { SectionTitle } from './SectionTitle'

type Props = {
  date: string
  expand: () => void
  isExpanded: boolean
  program: ProgramLine[]
  showAllResources: boolean
  showPrices: boolean
}

export const ProgramDay = ({
  date,
  isExpanded,
  program,
  expand,
  showAllResources,
  showPrices,
}: Props) => (
  <InnerContainer>
    <InnerSection
      expand={expand}
      isExpanded={isExpanded}
      isExpandable
      visibleContent={<SectionTitle date={date} />}
    >
      <SectionContent>
        {program.sort(generateCompareFn('start')).map((x) => {
          const { __type, ...pureData } = x

          switch (__type) {
            case ProgramTypeKey.Meal:
              return <MealRow key={x.id} data={pureData as Meal} />
            case ProgramTypeKey.Reservation:
              return (
                <ReservationRow
                  key={x.id}
                  data={pureData as ReservationWithTasks}
                  showAllResources={showAllResources}
                  showPrices={showPrices}
                />
              )
            case ProgramTypeKey.ReservationGroup:
              return (
                <ReservationGroupRow
                  key={x.id}
                  data={pureData as Group}
                  showAllResources={showAllResources}
                  showPrices={showPrices}
                />
              )
            case ProgramTypeKey.Task:
              const { start, ...pureTask } = pureData

              return (
                <TaskRow
                  key={x.id}
                  data={pureTask as Task}
                  showPrices={showPrices}
                />
              )
            default:
              return null
          }
        })}
      </SectionContent>
    </InnerSection>
  </InnerContainer>
)

/////

const SectionContent = styled.div`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(0.5)}rem;
  `}
`
