import { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import moment from 'moment'
import ReactLoading from 'react-loading'
import { useQuery } from '@apollo/client'

import {
  AccommodationGroup,
  ElasticSaleRoomReservation,
  ElasticSaleRoomTypeReservation,
  GQLAccommodationGroup,
  RoomReservation as GQLRoomReservation,
  RoomTypeReservation as GQLRoomTypeReservation,
  GQLTarget,
  RoomFeature,
  RoomLayoutQueries,
  Sale,
  Target,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { FetchState, FetchStates } from '@/common/types'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'
import { SalesTypeChip } from '@/modules/Sales'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

import {
  Button,
  ContentWrapper,
  Dates,
  TotalNumber,
  TotalNumberBold,
  VerticalDivider,
} from './common'
import { RoomFeatures } from './RoomFeatures'
import { RoomReservation } from './RoomReservation'
import { RoomTypeReservation } from './RoomTypeReservation'

type Props = {
  isSingleMode: boolean
  sale: Sale
}

export const SaleCard = ({ isSingleMode, sale }: Props) => {
  const {
    id,
    roomTypeReservations,
    saleData: {
      customerName,
      customerNumber,
      estimatedDates,
      requestedTotalNumbers,
      saleName,
      saleNumber,
      status,
      totalNumbers,
    },
    toplevelRoomReservations,
    facet,
    type,
  } = sale

  const { language } = useLanguageContext()
  const theme = useTheme()

  const {
    onSetSales,
    refreshSidebarTicker,
    roomFeatures,
    sales,
    targetGroupId,
    targetId,
  } = useRoomLayoutContext()

  const [fetchingState, setFetching] = useState<FetchState>(FetchStates.LOADING)
  const [isExpanded, setExpanded] = useState<boolean>(!!isSingleMode)
  const [isTargetHided, setTargetHided] = useState<{
    [targetId: string]: boolean
  }>({})
  const [features, setFeatures] = useState<RoomFeature[]>(roomFeatures)

  useEffect(() => {
    setFeatures(roomFeatures)
  }, [roomFeatures])

  const { data, error, loading, refetch } = useQuery(
    RoomLayoutQueries.SALE_RESERVATIONS,
    {
      fetchPolicy: 'cache-and-network',
      skip: !isExpanded,
      variables: { id },
    }
  )

  useEffect(() => {
    if (!data && loading) {
      setFetching(FetchStates.LOADING)
    }
    if (error) {
      setFetching(FetchStates.ERROR)
    }
    if (data) {
      onSetSales(
        sales.map((sale: Sale) =>
          sale.id === id
            ? {
                ...sale,
                accommodationGroups:
                  data.sales.accommodation.accommodationGroups
                    .filter(
                      (group: GQLAccommodationGroup) =>
                        !targetGroupId || group.id === targetGroupId
                    )
                    .map((group: GQLAccommodationGroup) => ({
                      id: group.id,
                      name: group.name,
                      sortOrder: group.sortOrder,
                      targets: group.targets
                        .filter(
                          (target: GQLTarget) =>
                            !targetId || target.id === targetId
                        )
                        .map((target: GQLTarget) => ({
                          default: target.default,
                          gqlRoomReservations:
                            target.targetedReservations?.roomReservations || [],
                          gqlRoomTypeReservations:
                            target.targetedReservations?.roomTypeReservations ||
                            [],
                          id: target.id,
                          name: target.name,
                          sortOrder: target.sortOrder,
                        })),
                    })),
              }
            : sale
        )
      )
      setFetching(FetchStates.IDLE)
    }
  }, [data, error, loading])

  useEffect(() => {
    refreshSidebarTicker && refetch()
  }, [refreshSidebarTicker])

  const getAllFeatures = () => {
    const allFeaturesIds: string[][] = []

    toplevelRoomReservations.forEach(
      (reservation: ElasticSaleRoomReservation) =>
        allFeaturesIds.push(reservation.featureIds)
    )
    roomTypeReservations.forEach(
      (reservation: ElasticSaleRoomTypeReservation) => {
        allFeaturesIds.push(reservation.featureIds)
        reservation.roomReservations.forEach(
          (reservation: ElasticSaleRoomReservation) =>
            allFeaturesIds.push(reservation.featureIds)
        )
      }
    )

    return features.filter(
      (feature: RoomFeature) =>
        feature.id ===
        [...new Set(allFeaturesIds.flat())].find(
          (featureId: string) => feature.id === featureId
        )
    )
  }

  const renderStatusIcon = () => {
    switch (status) {
      case 'READY':
        return (
          <FontAwesomeIcon
            color={theme.palette.success.main}
            icon={['far', 'circle-check']}
            size="lg"
          />
        )
      case 'IN_PROGRESS':
        return (
          <FontAwesomeIcon
            color={theme.palette.gold}
            icon={['far', 'clock']}
            size="lg"
          />
        )
      default:
      case 'NOT_READY':
        return (
          <FontAwesomeIcon
            color={theme.palette.text.light}
            icon={['far', 'circle']}
            size="lg"
          />
        )
    }
  }

  const renderName = (name: string) =>
    name.length > 25 ? (
      <Tooltip
        content={name}
        delay={300}
        placement="top"
        trigger={(triggerProps) => <Name {...triggerProps}>{name}</Name>}
      />
    ) : (
      <Name>{name}</Name>
    )

  const totals = [
    {
      icon: 'door-open',
      number: totalNumbers.rooms,
      requestedNumber: requestedTotalNumbers.rooms,
    },
    {
      icon: 'bed',
      number: totalNumbers.beds,
      requestedNumber: requestedTotalNumbers.beds,
    },
    {
      icon: 'couch',
      number: totalNumbers.extraBeds,
      requestedNumber: requestedTotalNumbers.extraBeds,
    },
  ]

  const renderTotals = () =>
    totals.map(({ icon, number }: { icon: string; number: number }) => (
      <TotalNumber
        alignItems="center"
        justifyContent="space-between"
        key={icon}
        width={`${theme.spacing.gu(5)}rem`}
      >
        <FontAwesomeIcon size="sm" icon={icon as IconProp} />
        {number}
      </TotalNumber>
    ))

  const renderRequestedTotals = () =>
    totals.map(
      ({ requestedNumber }: { requestedNumber: number }, i: number) => (
        <TotalNumberBold alignItems="center" justifyContent="center" key={i}>
          {requestedNumber}
        </TotalNumberBold>
      )
    )

  const renderRoomReservations = (groupId: string, target: Target) =>
    !!target.gqlRoomReservations?.length && (
      <>
        <SectionSubTitle>
          <T>RoomLayout:sidebar.title.rooms</T>
        </SectionSubTitle>
        <div>
          {target.gqlRoomReservations.map((r: GQLRoomReservation) => (
            <RoomReservation
              groupId={groupId}
              key={r.id}
              reservation={r}
              saleId={id}
              targetId={target.id}
            />
          ))}
        </div>
      </>
    )

  const renderRoomTypeReservations = (groupId: string, target: Target) =>
    !!target.gqlRoomTypeReservations?.length && (
      <>
        <SectionSubTitle>
          <T>RoomLayout:sidebar.title.roomTypes</T>
        </SectionSubTitle>
        <div>
          {target.gqlRoomTypeReservations.map((r: GQLRoomTypeReservation) => (
            <RoomTypeReservation
              groupId={groupId}
              key={r.id}
              reservation={r}
              saleId={id}
              targetId={target.id}
            />
          ))}
        </div>
      </>
    )

  const saleLink = `/sales/details/${id}?view[0]=accommodation`

  return (
    <Wrapper color={facet.color} isExpanded={isExpanded} noPadding>
      <SaleContentWrapper>
        {!isSingleMode && (
          <>
            <FlexColumn noPadding>
              <Button
                borderRadius={`8px 0 0 ${isExpanded ? '0' : '8px'}`}
                onClick={() => setExpanded(!isExpanded)}
              >
                <FontAwesomeIcon
                  icon={isExpanded ? 'angle-up' : 'angle-down'}
                />
              </Button>
            </FlexColumn>

            <VerticalDivider />
          </>
        )}

        <ContentWrapper flex={1} justifyContent="space-between">
          <FlexColumn flex={1} noPadding justifyContent="space-between">
            <FlexRow justifyContent="space-between">
              <FlexColumn alignItems="flex-start">
                <SalesTypeChip
                  size="xs"
                  style={{ marginBottom: `${theme.spacing.gu(0.25)}rem` }}
                  type={type}
                  facet={facet}
                />
                {(customerName || customerNumber) && (
                  <FlexRow style={{ fontWeight: 500 }}>
                    {customerName && renderName(customerName)}
                    {customerNumber && `#${customerNumber}`}
                  </FlexRow>
                )}

                <FlexRow>
                  {saleName && renderName(saleName)}
                  <Link href={saleLink}>#{saleNumber}</Link>
                </FlexRow>
              </FlexColumn>

              {renderStatusIcon()}
            </FlexRow>
            <FlexRow alignItems="center">
              <Dates>
                {estimatedDates && (
                  <>
                    {moment(estimatedDates.start).format('dd, D.M.YY')} {' – '}
                    {moment(estimatedDates.end).format('dd, D.M.YY')}
                  </>
                )}
              </Dates>

              <span style={{ flex: 1 }} />

              <RoomFeatures features={getAllFeatures()} />
            </FlexRow>
          </FlexColumn>
        </ContentWrapper>

        <VerticalDivider />

        <FlexColumn
          justifyContent="center"
          style={{
            padding: `${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem`,
          }}
        >
          {renderTotals()}
        </FlexColumn>

        <VerticalDivider />

        <FlexColumn
          justifyContent="center"
          style={{
            background: theme.palette.primary.extraLighter,
            padding: theme.spacing.gutterSmall,
          }}
        >
          {renderRequestedTotals()}
        </FlexColumn>
      </SaleContentWrapper>
      {isExpanded && (
        <FlexColumn>
          {fetchingState === FetchStates.LOADING && (
            <Placeholder>
              <ReactLoading
                type={'bubbles'}
                height={24}
                width={24}
                color={theme.palette.smoke.main}
              />
            </Placeholder>
          )}

          {fetchingState === FetchStates.ERROR && (
            <Placeholder>
              <T>RoomLayout:sidebar.error</T>
            </Placeholder>
          )}

          {fetchingState === FetchStates.IDLE &&
            !!sale.accommodationGroups.length &&
            [...sale.accommodationGroups]
              .sort(generateCompareFn('sortOrder'))
              .map((group: AccommodationGroup) => (
                <Fragment key={group.id}>
                  {[...group.targets].sort(generateCompareFn('sortOrder')).map(
                    (target: Target) =>
                      (!!target.gqlRoomReservations.length ||
                        !!target.gqlRoomTypeReservations.length) && (
                        <Fragment key={target.id}>
                          <SectionTitle borderColor={facet.color}>
                            <Button
                              borderRadius={'0'}
                              flex="none"
                              onClick={() =>
                                setTargetHided({
                                  ...isTargetHided,
                                  [target.id]: !isTargetHided[target.id],
                                })
                              }
                            >
                              <FontAwesomeIcon
                                icon={
                                  isTargetHided[target.id]
                                    ? 'angle-down'
                                    : 'angle-up'
                                }
                              />
                            </Button>

                            <VerticalDivider />

                            <SectionTitleLabel
                              alignItems="center"
                              justifyContent="space-between"
                              flex={1}
                              color={facet.color}
                            >
                              <span>
                                {target.default ? (
                                  <T>Accommodation:TargetGroup.default</T>
                                ) : (
                                  target.name ||
                                  `${translate(
                                    `Accommodation:TargetGroup.target`,
                                    language
                                  )} #${target.sortOrder}`
                                )}
                              </span>
                              {(type === 'EVENT' || type === 'ENROLLMENT') && (
                                <GroupNameLabel>
                                  {group.name ||
                                    `${translate(
                                      `Accommodation:SalesReservationList.group`,
                                      language
                                    )} #${group.sortOrder}`}
                                </GroupNameLabel>
                              )}
                            </SectionTitleLabel>
                          </SectionTitle>
                          {!isTargetHided[target.id] && (
                            <>
                              {renderRoomTypeReservations(group.id, target)}
                              {renderRoomReservations(group.id, target)}
                            </>
                          )}
                        </Fragment>
                      )
                  )}
                </Fragment>
              ))}
        </FlexColumn>
      )}
    </Wrapper>
  )
}

////////////

const GroupNameLabel = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Link = styled.a`
  text-decoration: none;

  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}

  &:hover {
    text-decoration: underline;
  }
`

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    max-width: ${theme.spacing.gu(28)}rem;
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: italic;

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
    border-top: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.lighter};
  `}
`

const SaleContentWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`

const SectionTitle = styled(FlexRow)<{ borderColor: string }>`
  border-top: 1px solid ${({ borderColor }) => borderColor};

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}
`

const SectionTitleLabel = styled(FlexRow)<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 600;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const SectionSubTitle = styled.div`
  font-weight: 600;

  ${({ theme }) => css`
    background: ${theme.palette.primary.extraLighter};
    border-bottom: 1px solid ${theme.palette.smoke.main};
    border-top: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.guPx(1) - 2}px ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)<{ color: string; isExpanded: boolean }>`
  border-radius: 10px;
  overflow: hidden;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
  `}

  border-left: 3px solid ${({ color }) => color};
  margin-bottom: ${({ isExpanded, theme }) =>
    isExpanded ? theme.spacing.gutter : `${theme.spacing.gu(1)}rem`};
`
