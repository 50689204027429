import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

type Props = {
  errorCode?: string
  errorText: ReactNode
}

export const FullScreenError = ({ errorCode, errorText }: Props) => (
  <ErrorWrapper>
    {errorCode && <ErrorCode>{errorCode}</ErrorCode>}
    {errorText}
  </ErrorWrapper>
)

export default FullScreenError

////////////

const ErrorCode = styled.span`
  font-weight: 200;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutter};
  `}

  &::after {
    content: '|';

    ${({ theme }) => css`
      margin-left: ${theme.spacing.gutter};
    `}
  }
`

const ErrorWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
