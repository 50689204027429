import styled, { css } from 'styled-components/macro'
import { lighten } from 'polished'

import { ButtonGroup, ButtonGroupButton } from '@/components/ExtraButtons'
import { OrderPhaseType as OPT } from '~generated-types'
import { orderContexts } from '@/modules/Order'
import { T } from '@/modules/Language'

export const OrderViewToggle = () => {
  const { orderView, setOrderView } = orderContexts.useOrderView()

  return (
    <ToggleWrapper>
      <ToggleButton
        noNudge
        size="small"
        onClick={() => setOrderView(OPT.Invoice)}
        selected={orderView === OPT.Invoice}
      >
        <T>{`Orders:Header.title.${OPT.Invoice}`}</T>
      </ToggleButton>
      <ToggleButton
        noNudge
        size="small"
        onClick={() => setOrderView(OPT.Offer)}
        selected={orderView === OPT.Offer}
      >
        <T>{`Orders:Header.title.${OPT.Offer}`}</T>
      </ToggleButton>
    </ToggleWrapper>
  )
}

/////

const ToggleButton = styled(ButtonGroupButton)`
  ${({ selected }) =>
    selected &&
    css`
      &,
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        ${({ theme }) => css`
          background: ${lighten(0.05, theme.palette.primary.extraLight)};
          border-color: ${lighten(0.05, theme.palette.primary.extraLight)};
          color: ${theme.palette.primary.dark};
        `}
      }
    `}
`

const ToggleWrapper = styled(ButtonGroup)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gutterBig};
  `}
`
