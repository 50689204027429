import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { DataTableInput } from '@/components/DataTable'
import { parseTimeFromString } from '@/utils/time'
import { useSalesProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  mealId: string
  startTime: string
}

export const StartTimeInput = ({ mealId, startTime }: Props) => {
  const { readOnly, updateProductItemMeal } = useSalesProductManagerContext()

  const [value, setValue] = useState<string>(startTime.slice(0, -3))

  const handleUpdateStartTime = () => {
    const time = parseTimeFromString(value)
    const startTimeValue = time ? parseTimeFromObject(time) : '00:00:00'

    setValue(startTimeValue.slice(0, -3))

    return updateProductItemMeal({
      id: mealId,
      startTime: startTimeValue,
    }).then((res) => !res && setValue(startTime.slice(0, -3)))
  }

  return (
    <Input
      disabled={readOnly}
      onBlur={handleUpdateStartTime}
      onChange={(e) => setValue(e.target.value)}
      onFocus={(e) => e.currentTarget.select()}
      showBorder
      flex="unset"
      value={value}
    />
  )
}

/////////

type TimeParams = {
  hour: number
  minute: number
}

const parseTimeFromObject = ({ hour, minute }: TimeParams) => {
  const validHour = hour > 9 ? hour : `0${hour}`
  const validMinutes = minute > 9 ? minute : `0${minute}`

  return `${validHour}:${validMinutes}:00`
}

const Input = styled(DataTableInput)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `}

  &:not([type='checkbox']):not([type='radio']) {
    text-align: center;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0 ${theme.spacing.gu(0.5)}rem;
      width: ${theme.spacing.gu(7.5)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`
