import React, { CSSProperties, ReactNode, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components/macro'

import { StatePropType } from '@/common/types'
import { useTheme } from '@/theme'

import { InlineLabel } from '../InlineLabel'
import { Input } from '../Input'
import { InputIndicatorBase } from '../InputIndicatorBase'

type SCIProps = {
  type: string
  disabled?: boolean
}
type CbProps = {
  disabled?: boolean
  noMargin?: boolean
  state?: StatePropType
}
type Props = {
  checked?: boolean
  children?: ReactNode
  disabled?: boolean
  indeterminate?: boolean
  labelStyle?: CSSProperties
  noMargin?: boolean
  onChange?: (event: React.ChangeEvent<HTMLElement>) => void
  readOnly?: boolean
  state?: StatePropType
  style?: CSSProperties
}

const StyledCheckboxInput = styled(Input)<SCIProps>`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
`

const Checkbox = styled(InputIndicatorBase)<CbProps>`
  ${({ theme }) => css`
    color: ${theme.palette.white};
  `}

  input:checked:disabled ~ & {
    ${({ theme }) => css`
      color: ${theme.palette.smoke.extraDark};
    `}
  }

  &::before {
    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeBase};
      height: ${theme.typography.fontSizeBase};
    `}
  }

  ${({ noMargin }) => noMargin && `margin-right: 0;`}
`

const Label = styled(InlineLabel)`
  & ${StyledCheckboxInput}:active ~ ${Checkbox} {
    box-shadow: 0 0 0 2px rgba(0, 149, 218, 0.3);
  }
  & ${StyledCheckboxInput}:focus ~ ${Checkbox} {
    box-shadow: 0 0 0 2px rgba(0, 149, 218, 0.3);
  }

  & ${StyledCheckboxInput}:checked ~ ${Checkbox} {
    ${({ state, theme }) => css`
      background: ${theme.palette.findUtilityColor(state) ||
      theme.palette.primary.main};
      border-color: ${theme.palette.findUtilityColor(state) ||
      theme.palette.primary.main};
    `}
  }

  & ${StyledCheckboxInput}:checked:disabled ~ ${Checkbox} {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
      border-color: ${theme.palette.smoke.main};
    `}
  }

  & ${StyledCheckboxInput}:indeterminate ~ ${Checkbox} {
    ${({ state, theme }) => css`
      background: ${theme.palette.findUtilityColor(state) ||
      theme.palette.primary.light};
      border-color: ${theme.palette.findUtilityColor(state) ||
      theme.palette.primary.light};
    `}
  }

  & ${StyledCheckboxInput}:indeterminate:disabled ~ ${Checkbox} {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
      border-color: ${theme.palette.smoke.main};
    `}
  }

  & ${StyledCheckboxInput}[type='Checkbox']:checked ~ ${Checkbox}::before {
    content: '✓';
    font-weight: 700;
    font-family: system-ui;
    margin-bottom: 1px;
  }

  & ${StyledCheckboxInput}[type='Checkbox']:indeterminate ~ ${Checkbox}::before {
    content: '–';
    font-weight: 900;
    margin-top: -2px;
  }
`

const CheckboxInput = ({
  children,
  disabled,
  indeterminate,
  labelStyle,
  noMargin,
  state,
  ...props
}: Props) => {
  const theme = useTheme()

  const inputEl = useRef<any>(null)

  useEffect(() => {
    if (inputEl && inputEl.current) {
      inputEl.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  const style: CSSProperties = {
    ...(noMargin ? { margin: 0 } : { marginRight: theme.spacing.gutterSmall }),
    ...{ position: 'relative' },
    ...(labelStyle || {}),
  }

  return (
    <Label state={state} disabled={disabled} style={style}>
      <StyledCheckboxInput
        {...props}
        disabled={disabled}
        ref={inputEl}
        type="Checkbox"
      />
      <Checkbox disabled={disabled} noMargin={noMargin} state={state} />
      {children}
    </Label>
  )
}

export default CheckboxInput
