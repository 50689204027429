import { Moment } from 'moment'

import { FlexRow } from '@/components/Layout'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { PlaceholderLabel } from '@/modules/Listing/common'
import { T } from '@/modules/Language'

import { ArrivalsListing } from './ArrivalsListing'
import { PrintSize } from '../../types'
import { useArrivalsForDate } from './useArrivalsForDate'

type Props = {
  printSize: PrintSize
  targetDate: Moment
}

export const ArrivalsListingContainer = ({ printSize, targetDate }: Props) => {
  const { error, events, loading, sales } = useArrivalsForDate({
    date: targetDate,
  })

  if (loading) {
    return (
      <FlexRow>
        <LoadingPlaceholder flex={1} size="xl" />
      </FlexRow>
    )
  }

  if (error) {
    return (
      <PlaceholderLabel>
        <T>Listings:ArrivalsAndDeparturesListing.arrivals.error</T>
      </PlaceholderLabel>
    )
  }

  if (!events.length && !sales.length) {
    return (
      <PlaceholderLabel>
        <T>Listings:ArrivalsAndDeparturesListing.arrivals.empty</T>
      </PlaceholderLabel>
    )
  }

  return (
    <ArrivalsListing
      events={events}
      printSize={printSize}
      sales={sales}
      targetDate={targetDate}
    />
  )
}
