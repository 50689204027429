import { useQuery } from '@apollo/client'

import {
  AccommodationTargetsForPublishQuery as QueryData,
  AccommodationTargetsForPublishQueryVariables as QueryVariables,
} from '~generated-types'

import { AccommodationTarget } from '../types'
import { publishQueries } from '../queries'

type Params = {
  salesId: string
}

export const useAccommodationTargets = ({ salesId }: Params) => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(
    publishQueries.accommodationTargets,
    {
      fetchPolicy: 'cache-and-network',
      variables: { salesId },
    }
  )

  const accommodationTargets: AccommodationTarget[] =
    data?.sales.accommodation.accommodationTargets || []

  return {
    accommodationTargets,
    error,
    loading,
  }
}
