import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { translate, useLanguageContext } from '@/modules/Language'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { useTheme } from '@/theme'

import { FilterOption } from './ListFilters'

type Props = {
  filters: FilterOption[]
  nameSpace: 'SalesSearchList' | 'InvoicesSearchList'
  onOptionClick: (option: string) => void
}

export const FiltersSettings = ({
  filters,
  nameSpace,
  onOptionClick,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [isOpen, setOpen] = useState(false)

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={
        <InlineModal style={{ minWidth: `${theme.spacing.gu(24)}rem` }}>
          <InlineModalSection
            style={{
              marginTop: `${theme.spacing.gu(1)}rem`,
              padding: `0 0 ${theme.spacing.gutter}`,
            }}
          >
            <FlexColumn noPadding flex={1}>
              {filters.map((filter) => (
                <OptionWrapper
                  key={filter.value}
                  isDisabled={filter.disabled}
                  onClick={() =>
                    !filter.disabled && onOptionClick(filter.value)
                  }
                >
                  <CheckIconWrapper>
                    {filter.selected && (
                      <FontAwesomeIcon
                        icon="check"
                        size="sm"
                        color={theme.palette.primary.dark}
                      />
                    )}
                  </CheckIconWrapper>
                  {translate(
                    `${nameSpace}:ListHeader.filter.${filter.value}`,
                    language
                  )}
                </OptionWrapper>
              ))}
            </FlexColumn>
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setOpen(false)}
      placement="bottom-end"
      referenceElement={({ ref }) => (
        <IconWrapper ref={ref} onClick={() => setOpen(true)}>
          <FontAwesomeIcon icon="gear" />
        </IconWrapper>
      )}
    />
  )
}

const IconWrapper = styled.div`
  transition: 0.2s;
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.coal.main};
  `}

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.smoke.extraDark};
    `}
  }
`

const OptionWrapper = styled.div<{ isDisabled: boolean }>`
  display: flex;
  width: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}

  cursor: pointer;

  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    background-color: ${theme.palette.smoke.light};
    cursor: not-allowed;
  `}

  ${({ isDisabled, theme }) =>
    !isDisabled &&
    `&:hover {
      background-color: ${theme.palette.primary.extraLight};
    }`}
`

const CheckIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
`
