import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'

import { getDurationString, getTimeArray } from './utils'

type Props = {
  allowSameTime?: boolean
  boundaryTime?: string
  currentTime: string
  setTime: (time: string) => void
}

export const TimeOptionsModal = ({
  allowSameTime,
  boundaryTime,
  currentTime,
  setTime,
}: Props) => (
  <Modal>
    <FlexColumn noPadding>
      {getTimeArray({ allowSameTime, boundaryTime }).map((time) => (
        <Option
          alignItems="center"
          id={`time-option-${time}`}
          isSelected={time === currentTime}
          key={`time-option-${time}`}
          onMouseDown={() => setTime(time)}
        >
          <Time>{time}</Time>

          {boundaryTime && (
            <Duration>{getDurationString(boundaryTime, time)}</Duration>
          )}
        </Option>
      ))}
    </FlexColumn>
  </Modal>
)

////////

const Duration = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Modal = styled(InlineModal)`
  max-height: 160px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(22)}rem;
  `}
`

const Option = styled(FlexRow)<{ isSelected: boolean }>`
  cursor: pointer;
  height: 30px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ isSelected, theme }) =>
      !isSelected &&
      css`
        background: ${theme.palette.smoke.light};
      `}
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      cursor: not-allowed;

      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    `}
`

const Time = styled.span`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(6)}rem;
  `}
`
