import { gql, useMutation } from '@apollo/client'

import { CopySaleMutation, CopySaleMutationVariables } from '~generated-types'

export type { CopySaleMutation, CopySaleMutationVariables }

export const COPY_SALE_MUTATION = gql`
  mutation CopySale($input: SalesCopyInput!) {
    salesCopy(input: $input) {
      sales {
        id
      }
    }
  }
`

export function useCopySaleMutation() {
  return useMutation<CopySaleMutation, CopySaleMutationVariables>(
    COPY_SALE_MUTATION
  )
}

export default useCopySaleMutation
