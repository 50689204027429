import styled, { css } from 'styled-components/macro'
import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import { useQuery } from '@apollo/client'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexColumn } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { InlineModal } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { orderQueries } from '@/modules/Order'
import { PaymentType as PaymentMethod } from '~generated-types'
import { PaymentTerminalsQuery } from '@/modules/Order/types'
import { useTheme } from '@/theme'

import {
  Dropdown,
  DropdownArrowButton,
  DropdownButton,
  DropdownWrapper,
  Icon,
  Label,
  LabelTruncate,
  OptionButton,
  TerminalIndicator,
} from '../common'
import { PaymentType } from '../../../types'

type PaymentTerminal = PaymentTerminalsQuery['registry']['paymentTerminals'][0]

type Props = {
  paymentType: PaymentType | null
  pointOfSaleId: string | null
  setPaymentType: (paymentType: PaymentType | null) => void
}

export const CreditCardPaymentButton = ({
  paymentType,
  pointOfSaleId,
  setPaymentType,
}: Props) => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const wrapperRef = useRef<HTMLDivElement | null>(null)

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [isShowOtherTerminals, setShowOtherTerminals] = useState<boolean>(false)
  const [modalWidth, setModalWidth] = useState<number>(0)
  const [terminals, setTerminals] = useState<PaymentTerminal[]>([])
  const [selectedTerminal, setSelectedTerminal] =
    useState<PaymentTerminal | null>(null)

  const { data, loading } = useQuery<PaymentTerminalsQuery>(
    orderQueries.PAYMENT_TERMINALS
  )

  useEffect(() => {
    if (data) {
      const fetchedTerminals = data.registry.paymentTerminals
      const storedTerminal: PaymentTerminal | null = JSON.parse(
        localStorage.getItem('paymentTerminal') || 'null'
      )
      const isStoredTerminalConnected = fetchedTerminals.find(
        (t) => t.id === storedTerminal?.id
      )?.terminalConnected

      setTerminals(fetchedTerminals)

      if (isStoredTerminalConnected) {
        setSelectedTerminal(storedTerminal)
      }
    }
  }, [data])

  useEffect(() => {
    if (wrapperRef.current?.clientWidth) {
      setModalWidth(wrapperRef.current.clientWidth + 1)
    }
  }, [wrapperRef.current?.clientWidth])

  const handleSetPaymentType = () =>
    !isPaymentTypeSelected
      ? selectedTerminal
        ? setPaymentType({
            method: PaymentMethod.CreditCard,
            terminalId:
              selectedTerminal.id === 'MANUAL'
                ? undefined
                : selectedTerminal.id,
          })
        : setModalOpen(true)
      : setPaymentType(null)

  const handleSelectPaymentTerminal = (terminal: PaymentTerminal) => {
    setSelectedTerminal(terminal)
    setPaymentType({
      method: PaymentMethod.CreditCard,
      terminalId: terminal.id === 'MANUAL' ? undefined : terminal.id,
    })
    setShowOtherTerminals(false)
    setModalOpen(false)
    localStorage.setItem('paymentTerminal', JSON.stringify(terminal))
  }

  const isPaymentTypeSelected = paymentType?.method === PaymentMethod.CreditCard
  const mainTerminals = terminals.filter(
    (t) => t.pointOfSale?.id === pointOfSaleId
  )
  const otherTerminals = terminals.filter(
    (t) => t.pointOfSale?.id !== pointOfSaleId
  )
  const manualTerminal: PaymentTerminal = {
    __typename: 'PaymentTerminal',
    id: 'MANUAL',
    name: translate('Orders:Payments.terminal.manual', language),
    pointOfSale: null,
    terminalConnected: true,
  }

  return (
    <DropdownWrapper ref={wrapperRef}>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <Modal modalWidth={modalWidth}>
            <FlexColumn noPadding>
              {mainTerminals.sort(generateCompareFn('name')).map((t) => (
                <OptionButton
                  disabled={!t.terminalConnected}
                  isSelected={selectedTerminal?.id === t.id}
                  key={`terminal-${t.id}`}
                  onClick={() =>
                    t.terminalConnected && handleSelectPaymentTerminal(t)
                  }
                >
                  {t.name}
                  <TerminalIndicator isAvailable={t.terminalConnected} />
                </OptionButton>
              ))}

              <OptionButton
                isSelected={selectedTerminal?.id === 'MANUAL'}
                key="terminal-manual"
                onClick={() => handleSelectPaymentTerminal(manualTerminal)}
              >
                {manualTerminal.name}
              </OptionButton>

              {!!otherTerminals.length &&
                (!isShowOtherTerminals ? (
                  <>
                    <Divider />

                    <OptionButton
                      key="show-other-terminals"
                      onClick={() => setShowOtherTerminals(true)}
                    >
                      <LightLabel>
                        <T>Orders:Payments.terminal.showOther</T>
                      </LightLabel>
                    </OptionButton>
                  </>
                ) : (
                  otherTerminals.sort(generateCompareFn('name')).map((t) => (
                    <OptionButton
                      disabled={!t.terminalConnected}
                      isSelected={selectedTerminal?.id === t.id}
                      key={`other-terminal-${t.id}`}
                      onClick={() =>
                        t.terminalConnected && handleSelectPaymentTerminal(t)
                      }
                    >
                      {t.name}
                      <TerminalIndicator isAvailable={t.terminalConnected} />
                    </OptionButton>
                  ))
                ))}
            </FlexColumn>
          </Modal>
        }
        onClose={() => {
          setModalOpen(false)
          setShowOtherTerminals(false)
        }}
        placement="top-end"
        referenceElement={({ ref }) => (
          <Dropdown ref={ref} isSelected={isPaymentTypeSelected}>
            <DropdownButton
              isSelected={isPaymentTypeSelected}
              onClick={handleSetPaymentType}
            >
              <Icon fixedWidth icon="credit-card" />
              <FlexColumn noPadding alignItems="flex-start">
                <Label>
                  <T>Orders:Payments.type.CREDIT_CARD</T>
                </Label>

                {selectedTerminal && (
                  <LabelTruncate title={selectedTerminal.name}>
                    {selectedTerminal.name}
                  </LabelTruncate>
                )}
              </FlexColumn>
            </DropdownButton>

            <DropdownArrowButton
              isSelected={isPaymentTypeSelected}
              onClick={() => setModalOpen(true)}
            >
              {loading ? (
                <ReactLoading
                  color={palette.smoke.dark}
                  height={18}
                  type="spin"
                  width={18}
                />
              ) : (
                <FontAwesomeIcon icon="chevron-down" />
              )}
            </DropdownArrowButton>
          </Dropdown>
        )}
      />
    </DropdownWrapper>
  )
}

/////////

const Divider = styled.div`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.main};
  `}
`

const LightLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Modal = styled(InlineModal)<{ modalWidth: number }>`
  ${({ modalWidth, theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem 0;
    min-width: ${modalWidth}px;
    max-height: ${theme.spacing.gu(33)}rem;
  `}
`
