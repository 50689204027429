import styled, { css } from 'styled-components/macro'
import { useRouteMatch } from 'react-router-dom'

import { ContentSection } from '@/components/ExpansionPanel'
import { EventEnrollment } from '@/API/EventEnrollments'
import { SalesDetails } from '@/modules/Sales/components/SalesDetails'

type Props = {
  enrollment: EventEnrollment
  enrollmentActiveSections?: string[]
  setActiveSections: (sections: string[]) => void
}

export default function EnrollmentContext({
  enrollment,
  enrollmentActiveSections,
  setActiveSections,
}: Props) {
  const { url } = useRouteMatch()

  return (
    <SaleWrapper title={''}>
      <SalesDetails
        baseRoute={url}
        id={enrollment.id}
        viewType="secondary"
        externalEmbeddedSections={enrollmentActiveSections}
        onSectionsUpdate={setActiveSections}
      />
    </SaleWrapper>
  )
}

const SaleWrapper = styled(ContentSection)`
  padding: 0;
  height: calc(100vh - 164px);
  overflow: auto;

  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
  `}
`
