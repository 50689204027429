import moment, { Moment } from 'moment'
import {
  ParticipantDefaultVstDatesQuery,
  ParticipantDefaultVstDatesQueryVariables,
} from '~generated-types'
import styled, { css } from 'styled-components'
import ReactLoading from 'react-loading'
import { useQuery } from '@apollo/client'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { CheckBox } from '@/modules/ParticipantsList/components/common'
import { DateRangePicker } from '@/components/TimeControls'
import { Gutter } from '@/components/Layout'
import { Input } from '@/components/FormControls'
import { ParticipantsListQueries } from '@/modules/ParticipantsList'
import { T } from '@/modules/Language'
import { TimeInputControl } from '@/components/TimeControls'
import { useTheme } from '@/theme'

type Dates = {
  end: Moment
  start: Moment
}

type Props = {
  participantId: string
  dates: Dates | null
  setDates: (dates: Dates | null) => void
  updateAll: boolean
  setUpdateAll: (updateAll: boolean) => void
  useDefault: boolean
  setUseDefault: (useDefault: boolean) => void
}

export const VSTDatesModal = ({
  participantId,
  dates,
  setDates,
  updateAll,
  setUpdateAll,
  useDefault,
  setUseDefault,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { data, loading } = useQuery<
    ParticipantDefaultVstDatesQuery,
    ParticipantDefaultVstDatesQueryVariables
  >(ParticipantsListQueries.PARTICIPANT_DEFAULT_VST_DATES, {
    fetchPolicy: 'no-cache',
    variables: {
      id: participantId,
    },
  })

  const defaultDatesData = data?.salesParticipant?.vst?.defaults || null
  const defaultDates = defaultDatesData
    ? {
        end: moment(defaultDatesData.end),
        start: moment(defaultDatesData.start),
      }
    : null

  return (
    <FlexRow>
      <SectionWrapper>
        {loading ? (
          <LoaderWrapper>
            <ReactLoading
              color={palette.smoke.extraDark}
              type={'spin'}
              height={24}
              width={24}
            />
          </LoaderWrapper>
        ) : (
          <DateRangePicker
            setValue={(value) => {
              const range = !!value
                ? {
                    end: value.to.set({ hour: 12 }),
                    start: value.from.set({ hour: 15 }),
                  }
                : null

              useDefault && setUseDefault(false)
              setDates(range)
            }}
            value={
              useDefault
                ? defaultDates && {
                    from: defaultDates.start,
                    to: defaultDates.end,
                  }
                : dates && { from: dates.start, to: dates.end }
            }
          />
        )}

        <HorizontalDivider />

        <TimeWrapper>
          <FlexRow
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            style={{
              marginBottom: `${spacing.gu(1)}rem`,
              width: '100%',
            }}
          >
            <Label>
              <T>ParticipantsList:VST.startTime</T>:
            </Label>
            {/* @ts-ignore */}
            <TimeInputControl
              setValue={(value) => {
                if (!value) return

                const specifiedDate = dates
                  ? moment(
                      `${dates.start.format('YYYY-MM-DD')}T${value.hour
                        .toString()
                        .padStart(2, '0')}:${value.minute
                        .toString()
                        .padStart(2, '0')}:00+00:00`
                    )
                  : null

                setDates({
                  end: dates?.end || moment.utc(specifiedDate),
                  start: moment.utc(specifiedDate),
                })
              }}
              value={
                useDefault
                  ? defaultDates && {
                      hour: Number(defaultDates.start.format('HH')),
                      minute: Number(defaultDates.start.format('mm')),
                    }
                  : dates && {
                      hour: Number(dates.start.format('HH')),
                      minute: Number(dates.start.format('mm')),
                    }
              }
            >
              {({ handleSubmit, inputValue, setInputValue }) => (
                <Input
                  onBlur={handleSubmit}
                  disabled={!dates?.start && !useDefault}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setInputValue(event.target.value)
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      handleSubmit()
                    }
                  }}
                  placeholder="__ : __"
                  style={{
                    height: `${spacing.gu(3)}rem`,
                    maxWidth: 56,
                  }}
                  value={inputValue}
                />
              )}
            </TimeInputControl>
          </FlexRow>
          <FlexRow
            flex={1}
            alignItems="center"
            justifyContent="space-between"
            style={{ width: '100%' }}
          >
            <Label>
              <T>ParticipantsList:VST.endTime</T>:
            </Label>
            {/* @ts-ignore */}
            <TimeInputControl
              setValue={(value) => {
                if (!value) return

                const specifiedDate = dates
                  ? moment(
                      `${dates.end.format('YYYY-MM-DD')}T${value.hour
                        .toString()
                        .padStart(2, '0')}:${value.minute
                        .toString()
                        .padStart(2, '0')}:00+00:00`
                    ).format()
                  : null

                setDates({
                  end: moment.utc(specifiedDate),
                  start: dates?.start || moment.utc(specifiedDate),
                })
              }}
              value={
                useDefault
                  ? defaultDates && {
                      hour: Number(defaultDates.end.format('HH')),
                      minute: Number(defaultDates.end.format('mm')),
                    }
                  : dates && {
                      hour: Number(dates.end.format('HH')),
                      minute: Number(dates.end.format('mm')),
                    }
              }
            >
              {({ handleSubmit, inputValue, setInputValue }) => (
                <Input
                  onBlur={handleSubmit}
                  disabled={!dates?.start && !useDefault}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setInputValue(event.target.value)
                  }
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter') {
                      handleSubmit()
                    }
                  }}
                  placeholder="__ : __"
                  style={{
                    height: `${spacing.gu(3)}rem`,
                    maxWidth: 56,
                  }}
                  value={inputValue}
                />
              )}
            </TimeInputControl>
          </FlexRow>
        </TimeWrapper>
      </SectionWrapper>

      <VerticalDivider />

      <SectionWrapper>
        <Gutter type={[2, 0, 1]}>
          <FlexRow alignItems="center">
            <CheckBox
              isSelected={useDefault}
              onClick={() => setUseDefault(!useDefault)}
            />
            <T>ParticipantsList:VST.setDefaultDates</T>
          </FlexRow>
          <DescriptionWrapper>
            <p>
              <T>ParticipantsList:VST.defaultDatesDescription.part1</T>
            </p>
            <p>
              <T>ParticipantsList:VST.defaultDatesDescription.part2</T>
            </p>
            <p>
              <T>ParticipantsList:VST.defaultDatesDescription.part3</T>
            </p>
          </DescriptionWrapper>
        </Gutter>

        <Gutter type={[1, 0, 0]}>
          <FlexRow alignItems="center">
            <CheckBox
              isSelected={updateAll}
              onClick={() => setUpdateAll(!updateAll)}
            />
            <T>ParticipantsList:VST.applyForAll</T>
          </FlexRow>
          <DescriptionWrapper>
            <p>
              <T>ParticipantsList:VST.applyForAllDescription.part1</T>
            </p>
            <p>
              <T>ParticipantsList:VST.applyForAllDescription.part2</T>
            </p>
          </DescriptionWrapper>
        </Gutter>
      </SectionWrapper>
    </FlexRow>
  )
}

const Label = styled.span`
  font-weight: 500;

  margin-right: ${({ theme }) => theme.spacing.gu(1)}rem;
`

const TimeWrapper = styled(FlexColumn)`
  flex: 1;
  width: 100%;
  align-items: flex-start;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(2)}rem;
  `}
`

const SectionWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const HorizontalDivider = styled.div<{ margin?: string }>`
  width: 100%;

  ${({ theme, margin }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    margin: ${margin ? margin : `${theme.spacing.gu(1)}rem 0`};
  `}
`

const VerticalDivider = styled.div`
  align-self: stretch;

  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.main};
    margin: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(1)}rem 0;
  `}
`

const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem 0 calc(20px + ${theme.spacing.gu(2)}rem);

    & > p {
      color: ${theme.palette.smoke.extraDark};
    }
  `}

  max-width: 240px;
`

const LoaderWrapper = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
`
