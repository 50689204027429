import styled from 'styled-components/macro'
import { useQuery } from '@apollo/client'

import { SalesGroupsQuery, SalesGroupsQueryVariables } from '~generated-types'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'
import { ModalSelector } from '@/components/Modal'
import { ParticipantsListQueries } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import Label from '../../ParticipantRow/common/ServiceItemEmptyLabel'
import ServiceItemSquared from '../../ParticipantRow/common/ServiceItemSquared'

type Props = {
  salesId: string
  group: string | null
  readOnly?: boolean
  handleUpdateGroup: (
    group: string | null
  ) => Promise<FetchedParticipant | undefined>
}

const SaleGroup = ({ salesId, group, readOnly, handleUpdateGroup }: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const { data } = useQuery<SalesGroupsQuery, SalesGroupsQueryVariables>(
    ParticipantsListQueries.SALES_GROUPS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        id: salesId,
      },
    }
  )

  const groupsOptions = [
    ...(data?.sales.groups?.values.map((groupName) => ({
      disabled: false,
      label: groupName,
      value: groupName,
    })) || []),
  ]

  if (!!group) {
    groupsOptions.push({
      disabled: false,
      label: translate(
        'ParticipantsList:ParticipantFormFields.notSelected',
        language
      ),
      value: '',
    })
  }

  return (
    <ModalSelector
      options={groupsOptions}
      handleChange={(groupName) => handleUpdateGroup(groupName)}
      emptyPlaceholder={<T>ParticipantsList:PersonalFields.noGroups</T>}
      referenceElement={(ref, setModalOpen) => (
        <ServiceItemSquared
          ref={ref}
          width={`${spacing.gu(24)}rem`}
          disabled={readOnly}
          noBorder
          onClick={() => setModalOpen(!readOnly)}
          renderContent={() =>
            group ? (
              <ContentWrapper>{group}</ContentWrapper>
            ) : (
              <>
                <Label>
                  {data?.sales.groups?.label || (
                    <T>ParticipantsList:PersonalFields.group</T>
                  )}
                </Label>
              </>
            )
          }
        />
      )}
    />
  )
}

export default SaleGroup

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
`
