import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { ElasticSales } from '@/modules/Sales'

type Props = {
  item: ElasticSales
}

export const Dates = ({
  item: { estimatedDates, reservationDates },
}: Props) => {
  const dates = estimatedDates ?? reservationDates ?? null

  return (
    <Wrapper noPadding>
      {dates ? (
        <>
          <FlexRow alignItems="center">
            <Icon icon="right-to-bracket" size="xs" />
            {moment(dates.start).format('dd DD.MM.YY')}
          </FlexRow>

          <FlexRow alignItems="center">
            <Icon icon="right-from-bracket" size="xs" />
            {moment(dates.end).format('dd DD.MM.YY')}
          </FlexRow>
        </>
      ) : null}
    </Wrapper>
  )
}

////////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(14)}rem;
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
