import moment, { Moment } from 'moment'
import { ApolloError } from '@apollo/client'

import { DateRange, DateRangePicker } from '@/components/TimeControls'
import { ListingLayout, PlaceholderLabel } from '@/modules/Listing/common'
import { FilterSection } from '@/components/ElasticFilterSearchList'
import { generateCompareFn } from '@/utils/arrays'
import { LoadingPlaceholder } from '@/components/Placeholders'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SalesType, ScheduledMealStatus } from '~generated-types'

import { DailyMeals, PacketMealListControls } from './components'
import {
  ListingMealType,
  ListingRestaurant,
  ListingScheduledMeal,
} from './usePacketMealsListing'
import usePacketMealListControls, {
  EnrollmentDisplay,
} from './usePacketMealListControls'
import { PrintSize } from '../types'

type Props = {
  error?: ApolloError
  interval: DateRange
  loading: boolean
  meals: ListingScheduledMeal[]
  range: Moment[]
  setInterval: (arg0: DateRange) => void
}

const PacketMealListing = ({
  error,
  interval,
  loading,
  meals,
  range,
  setInterval,
}: Props) => {
  const theme = useTheme()

  const {
    allergyBy,
    enrollmentDisplay,
    listType,
    restaurantKey,
    setAllergyBy,
    setEnrollmentDisplay,
    setListType,
    setRestaurantKey,
  } = usePacketMealListControls()

  const {
    grouped,
    mealsByDate,
    meals: mealTypes,
    restaurants,
  } = groupMeals(meals, enrollmentDisplay)

  const renderContent = (printSize: PrintSize) => {
    if (loading) {
      return <LoadingPlaceholder flex={1} size="xl" />
    }

    if (error) {
      return (
        <PlaceholderLabel>
          – <T>Listings:PacketMealListing.error</T> –
        </PlaceholderLabel>
      )
    }

    if (!Object.keys(grouped).length) {
      return (
        <PlaceholderLabel>
          – <T>Listings:PacketMealListing.empty</T> –
        </PlaceholderLabel>
      )
    }

    return (
      <div style={{ width: '100%' }}>
        {range.map((date) => (
          <DailyMeals
            key={`meals-${date.format('YYYY-MM-DD')}`}
            meals={mealsByDate[date.format('YYYY-MM-DD')] || []}
            data={grouped[date.format('YYYY-MM-DD')] || {}}
            mealTypes={mealTypes}
            date={date}
            restaurants={Object.values(restaurants).filter(
              (r) => restaurantKey.indexOf(r.id) !== -1
            )}
            listType={listType}
            printSize={printSize}
            allergyBy={allergyBy}
          />
        ))}
      </div>
    )
  }

  const renderHeaderControls = () => (
    <PacketMealListControls
      allergyBy={allergyBy}
      setAllergyBy={setAllergyBy}
      enrollmentDisplay={enrollmentDisplay}
      setEnrollmentDisplay={setEnrollmentDisplay}
      listType={listType}
      setListType={setListType}
      setRestaurantKey={setRestaurantKey}
      restaurants={restaurants}
      loading={loading}
    />
  )

  const renderSidebar = () => (
    <FilterSection
      label={<T>Listings:PacketMealListing.filter.interval</T>}
      render={() => (
        <div style={{ margin: `-${theme.spacing.gu(1)}rem` }}>
          <DateRangePicker
            maxWeekRange={5}
            setValue={(range) =>
              setInterval(range || { from: moment(), to: moment() })
            }
            value={interval}
          />
        </div>
      )}
    />
  )

  return (
    <ListingLayout
      renderContent={renderContent}
      renderHeaderControls={renderHeaderControls}
      renderSidebar={renderSidebar}
      title={<T>Listings:PacketMealListing.title</T>}
      type="VERTICAL"
    />
  )
}

export default PacketMealListing

////////////

type GroupedMeals = {
  meals: Record<string, ListingMealType>
  mealsByDate: Record<string, ListingScheduledMeal[]>
  restaurants: Record<string, ListingRestaurant>
  grouped: Record<
    string,
    Record<string, Record<string, ListingScheduledMeal[]>>
  >
}

const groupMeals = (
  meals: ListingScheduledMeal[],
  enrollmentDisplay: EnrollmentDisplay
): GroupedMeals => {
  const mealsByKey: Record<string, ListingMealType> = {}
  const restaurantsByKey: Record<string, ListingRestaurant> = {
    missing: {
      __typename: 'Restaurant',
      id: 'missing',
      name: 'Ravintola ase',
    },
  }

  const groupEnrollments = enrollmentDisplay === 'GROUPED'

  const grouped: Record<
    string,
    Record<string, Record<string, ListingScheduledMeal[]>>
  > = {}

  const mealsByDate: Record<string, ListingScheduledMeal[]> = {}

  groupSimilarMeals(meals).forEach((meal: ListingScheduledMeal) => {
    if (meal.status === ScheduledMealStatus.ManuallyRemoved) {
      return
    }

    const rKey = meal.location.id ?? 'missing'
    const mKey = meal.meal.id

    if (meal.location) {
      restaurantsByKey[rKey] = meal.location
    }

    mealsByKey[mKey] = meal.meal

    const dateKey = moment(meal.start).format('YYYY-MM-DD')

    mealsByDate[dateKey] = mealsByDate[dateKey] || []
    mealsByDate[dateKey].push(meal)

    grouped[dateKey] = grouped[dateKey] || {}
    grouped[dateKey][rKey] = grouped[dateKey][rKey] || {}
    grouped[dateKey][rKey][mKey] = grouped[dateKey][rKey][mKey] || []

    if (
      groupEnrollments &&
      meal.sales.type === SalesType.Enrollment &&
      !!meal.sales.event
    ) {
      const targetMeal = grouped[dateKey][rKey][mKey].find(
        ({ duration, sales, start }) =>
          sales.event?.id === meal.sales.event?.id &&
          duration === meal.duration &&
          start === meal.start
      )

      if (targetMeal) {
        targetMeal.notes = [targetMeal.notes, meal.notes]
          .filter(Boolean)
          .join(' / ')
        targetMeal.quantities = targetMeal.quantities.map((x) => {
          const addition = meal.quantities.find(
            ({ ageCategory }) => ageCategory?.key === x.ageCategory?.key
          )

          return {
            ...x,
            quantity: x.quantity + (addition?.quantity || 0),
          }
        })
        targetMeal.totalQuantity += meal.totalQuantity
        targetMeal.participants = [
          ...meal.participants,
          ...targetMeal.participants,
        ]
      } else {
        grouped[dateKey][rKey][mKey].push({
          ...meal,
          sales: {
            ...meal.sales,
            customer: meal.sales.event.customer,
            estimatedDates: meal.sales.event.estimatedDates,
            facet: meal.sales.event.facet,
            id: meal.sales.event.id,
            lifecycle: meal.sales.event.lifecycle,
            name: meal.sales.event.name,
            orderNumber: meal.sales.event.orderNumber,
            type: SalesType.Event,
          },
        })
      }
    } else {
      grouped[dateKey][rKey][mKey].push(meal)
    }

    grouped[dateKey][rKey][mKey] = grouped[dateKey][rKey][mKey].sort(
      generateCompareFn('start')
    )
  })

  return {
    grouped,
    meals: mealsByKey,
    mealsByDate,
    restaurants: restaurantsByKey,
  }
}

const groupSimilarMeals = (data: ListingScheduledMeal[]) =>
  data.reduce((acc: ListingScheduledMeal[], meal) => {
    const similarMeal = acc.find(
      (m) => m.meal.name === meal.meal.name && m.meal.id !== meal.meal.id
    )

    if (similarMeal) {
      return [
        ...acc,
        { ...meal, meal: { ...meal.meal, id: similarMeal.meal.id } },
      ]
    } else {
      return [...acc, meal]
    }
  }, [])
