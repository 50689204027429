import { CSSProperties, useState } from 'react'
import { OptionProps, OptionTypeBase } from 'react-select'

import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { Theme, useTheme } from '@/theme'
import { EditButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'

import { CustomerContactDisplayName } from './CustomerContactDisplayName'
import { CustomerIconValue } from './CustomerIconValue'
import { OrganizationCustomerContact } from '../types'

type Props = {
  contacts: OrganizationCustomerContact[]
  defaultContactId: string | null
  editInRegistry: () => void
  readOnly?: boolean
  selectedContact: OrganizationCustomerContact | null
  setContact: (contactId: string | null) => Promise<void>
}

export const CustomerContactSelector = ({
  contacts,
  defaultContactId,
  editInRegistry,
  readOnly,
  selectedContact,
  setContact,
}: Props) => {
  const theme = useTheme()

  const [isEditMode, setEditMode] = useState<boolean>(false)
  const [processing, setProcessing] = useState<boolean>(false)

  const contactOptions = [
    ...contacts.map((contact) => ({
      label: (
        <CustomerIconValue icon="user">
          <CustomerContactDisplayName
            contact={contact}
            isDefault={defaultContactId === contact.id}
          />
        </CustomerIconValue>
      ),
      value: contact.id,
    })),
    {
      label: (
        <FontWeight semiBold>
          <PrimaryColor>
            + <T>Customers:contacts.add</T>
          </PrimaryColor>
        </FontWeight>
      ),
      value: 'NEW',
    },
  ]

  const selectedOption =
    contactOptions.find(({ value }) => value === selectedContact?.id) || null

  const handleSelectContact = (option?: Option | null) => {
    if (option?.value === 'NEW') {
      editInRegistry()
    } else {
      setProcessing(true)
      setContact(option?.value ?? null)
        .catch(() => undefined)
        .finally(() => {
          setProcessing(false)
          setEditMode(false)
        })
    }
  }

  return (
    <FlexRow>
      {isEditMode ? (
        <ThemedSelect
          autoFocus
          extraStyles={getExtraStyles(theme)}
          isCompact
          isDisabled={readOnly || processing}
          isLoading={processing}
          menuIsOpen={isEditMode}
          name="customer-contact"
          noOptionsMessage={() => <T>Customers:contacts.empty</T>}
          onBlur={() => setEditMode(false)}
          onChange={handleSelectContact}
          options={contactOptions}
          placeholder={<T>Customers:contact.placeholder</T>}
          value={selectedOption}
        />
      ) : (
        <EditButton disabled={readOnly} onClick={() => setEditMode(true)}>
          <CustomerIconValue icon="user">
            {selectedContact ? (
              <CustomerContactDisplayName
                contact={selectedContact}
                isDefault={defaultContactId === selectedContact.id}
              />
            ) : (
              <T>Customers:contacts.empty</T>
            )}
          </CustomerIconValue>
        </EditButton>
      )}
    </FlexRow>
  )
}

////////////

const getExtraStyles = (theme: Theme) => ({
  control: (styles: CSSProperties) => ({
    ...styles,
    cursor: 'pointer',
    height: '30px',
    marginLeft: `-${theme.spacing.gu(1)}rem`,
    minHeight: '30px',
    pagging: 0,
    width: `calc(100% + ${theme.spacing.gu(2)}rem)`,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    marginLeft: `-${theme.spacing.gu(1)}rem`,
    marginTop: 4,
    width: `calc(100% + ${theme.spacing.gu(2)}rem)`,
    zIndex: 10005,
  }),
  option: (
    styles: CSSProperties,
    { isSelected }: OptionProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    cursor: 'pointer',
    paddingLeft: 8,
    path: {
      fill: isSelected && theme.palette.white,
    },
    span: {
      color: isSelected && theme.palette.white,
    },
  }),
})
