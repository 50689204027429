import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import React from 'react'

import { DatePicker } from '@/components/TimeControls'
import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { SalesForDateType } from '~generated-types'
import { SelectedFiltersButton } from '@/components/Reactivesearch'
import { T } from '@/modules/Language'

import { DimensionFilter } from './DimensionFilter'
import { FreeTextSearch } from './FreeTextSearch'
import { TypeFilter } from './TypeFilter'

type Props = {
  date: Moment
  dimensions: string | null
  onClearAllFilters: () => void
  onClearDimensions: () => void
  onChangeDimensionHits: (salesIds: string[]) => void
  onChangeSearchHits: (salesIds: null | string[]) => void
  onSetDate: (date: Moment) => void
  onSetTypes: (type: SalesForDateType[]) => void
  types: SalesForDateType[]
}

export const Sidebar = ({
  date,
  dimensions,
  onClearAllFilters,
  onClearDimensions,
  onChangeDimensionHits,
  onChangeSearchHits,
  onSetDate,
  onSetTypes,
  types,
}: Props) => {
  const hasTypeFilter = !!types.length

  const hasAnyFilter = dimensions || hasTypeFilter

  return (
    <Wrapper>
      <DatePicker
        setValue={(date) => onSetDate(date || moment())}
        showTodayButton
        value={date}
      />

      <Separator />

      {hasAnyFilter && (
        <SelectedFiltersWrapper wrap="wrap">
          {hasTypeFilter && (
            <SelectedFiltersButton
              onClick={() => onSetTypes([])}
              style={{ maxWidth: '100%' }}
            >
              <T>FrontDesk:DailyCustomerList.type.title</T>:{' '}
              {types.map((x, idx) => (
                <React.Fragment key={`type-label-${x}`}>
                  <T l10n={`FrontDesk:DailyCustomerList.type.${x}`} />
                  {idx + 1 < types.length && <span>, </span>}
                </React.Fragment>
              ))}
            </SelectedFiltersButton>
          )}

          {dimensions && (
            <SelectedFiltersButton
              onClick={() => onClearDimensions()}
              style={{ maxWidth: '100%' }}
            >
              <T>FrontDesk:DailyCustomerList.dimensions.title</T>: {dimensions}
            </SelectedFiltersButton>
          )}

          <SelectedFiltersButton
            noCross
            onClick={onClearAllFilters}
            style={{ maxWidth: '100%' }}
          >
            <T>FrontDesk:DailyCustomerList.resetFilters</T>
          </SelectedFiltersButton>
        </SelectedFiltersWrapper>
      )}

      {hasAnyFilter && <Separator />}

      <Gutter type={[2, 2]}>
        <FreeTextSearch onChangeMatchingSales={onChangeSearchHits} />

        <TypeFilter setValue={onSetTypes} value={types} />

        <DimensionFilter onChangeMatchingSales={onChangeDimensionHits} />
      </Gutter>
    </Wrapper>
  )
}

////////////

const SelectedFiltersWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gu(1)}rem;
  `}
`

const Separator = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 280px;

  ${({ theme }) => css`
    border-left: 1px solid ${theme.palette.smoke.main};
  `}
`
