import { gql, useMutation } from '@apollo/client'

import ResourceReservationFragment from '../fragments/resourceReservation'

import {
  SetResourceReservationDescriptionMutation,
  SetResourceReservationDescriptionMutationVariables,
} from '~generated-types'

export type {
  SetResourceReservationDescriptionMutation,
  SetResourceReservationDescriptionMutationVariables,
}

export const SET_RESOURCE_RESERVATION_DESCRIPTION_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationDescription(
    $input: ResourceReservationSetDescriptionInput!
  ) {
    resourceReservationSetDescription(input: $input) {
      ...ResourceReservation
    }
  }
`

export function useSetResourceReservationDescriptionMutation() {
  return useMutation<
    SetResourceReservationDescriptionMutation,
    SetResourceReservationDescriptionMutationVariables
  >(SET_RESOURCE_RESERVATION_DESCRIPTION_MUTATION)
}

export default useSetResourceReservationDescriptionMutation
