import { useRouteIsoWeek, useRouteValue } from '@/utils/hooks'
import { SalesType } from '~generated-types'

import { Mode } from './types'
import SalesListing from './SalesListing'
import useSalesForIsoWeek from './useSalesForIsoWeek'

export const SalesListingContainer = () => {
  const typeFilter = [SalesType.Event, SalesType.Sales]

  const { setValue: setRouteMode, value: routeModeValue } = useRouteValue({
    multi: false,
    routeKey: 'mode',
    whitelist: ['CONCISE', 'FULL'],
  })
  const { setWeek, week: isoWeek, year: isoWeekYear } = useRouteIsoWeek()
  const { error, loading, sales } = useSalesForIsoWeek({
    isoWeek,
    isoWeekYear,
    typeFilter,
  })

  const routeMode: string = Array.isArray(routeModeValue)
    ? routeModeValue[0]
    : routeModeValue || Mode.FULL
  const mode: Mode = routeMode as Mode

  const handleSetMode = (target: Mode) => {
    setRouteMode(target)
  }

  return (
    <>
      <SalesListing
        error={error}
        loading={loading}
        mode={mode}
        sales={sales}
        setMode={handleSetMode}
        setTargetIsoWeek={setWeek}
        targetIsoWeek={isoWeek}
        targetIsoWeekYear={isoWeekYear}
        typeFilter={typeFilter}
      />
    </>
  )
}

export default SalesListingContainer
