import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import EndAdornmentWrapper from '@/components/Spreadsheet/elements/EndAdornmentWrapper'
import { FontWeight } from '@/components/Typography'
import { IconButton } from '@/components/ExtraButtons'
import { Input } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  beds: number
  setBeds: (beds: number) => void
  onSubmit: () => void
}

export const CapacityMassUpdate = ({ beds, setBeds, onSubmit }: Props) => {
  const theme = useTheme()

  return (
    <FlexColumn alignItems="center" noPadding style={{ width: '100%' }}>
      <FlexRow
        justifyContent="flex-start"
        alignItems="center"
        style={{ padding: `0 ${theme.spacing.gutter}`, width: '100%' }}
      >
        <FontWeight>
          <T>Products:ProductManager.purchase.apply</T>
        </FontWeight>
        <FlexRow style={{ position: 'relative' }}>
          <StyledInput
            min={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onSubmit()
              }
            }}
            onChange={(e) => setBeds(Number(e.target.value))}
            onFocus={(e) => e.currentTarget.select()}
            placeholder={'–'}
            style={{ color: theme.palette.primary.main }}
            type="number"
            value={`${beds}`}
          />
          <EndAdornmentWrapper style={{ width: `${theme.spacing.gu(3)}rem` }}>
            {'x'}
          </EndAdornmentWrapper>
        </FlexRow>
        <FontWeight>
          <T>Products:ProductManager.purchase.toSelected</T>
        </FontWeight>
        <IconButton
          icon="check"
          onClick={onSubmit}
          style={{
            backgroundColor: theme.palette.primary.extraLight,
            borderColor: theme.palette.primary.extraLight,
            color: theme.palette.primary.main,
            marginLeft: `${theme.spacing.gu(1)}rem`,
          }}
        />
      </FlexRow>
    </FlexColumn>
  )
}

///////

const StyledInput = styled(Input)`
  &&& {
    border: 0;
    border-radius: 0;
    height: 100%;
    font-weight: 500;
    ${({ theme, type }) =>
      type === 'number' &&
      `padding-right: calc(${theme.spacing.gu(2)}rem + 1px)`};

    ${({ theme }) => css`
      width: ${theme.spacing.gu(6)}rem;
      font-size: ${theme.typography.fontSizeBase};
      margin: 0 ${theme.spacing.gutterSmall};
    `}

    text-align: ${({ type }) => (type === 'number' ? 'end' : 'start')};

    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      font-weight: 400;
      font-style: italic;
      opacity: 1;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }
  }
`
