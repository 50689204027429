import { gql, useMutation } from '@apollo/client'

import {
  CreateSaleMutation,
  CreateSaleMutationVariables,
} from '~generated-types'

export type { CreateSaleMutation, CreateSaleMutationVariables }

export const CREATE_SALE_MUTATION = gql`
  mutation CreateSale($input: SalesCreateInput!) {
    salesCreate(input: $input) {
      id
      orderNumber
    }
  }
`

export function useCreateSaleMutation() {
  return useMutation<CreateSaleMutation, CreateSaleMutationVariables>(
    CREATE_SALE_MUTATION
  )
}

export default useCreateSaleMutation
