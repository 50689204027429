import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { ApolloQueryResult } from '@apollo/client'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'

import {
  DocumentPreview,
  DocumentsService,
  DownloadableDocument,
} from '@/modules/Document'
import { FetchState, FetchStates } from '@/common/types'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  SideDrawer,
  SideDrawerBottomControls,
  SideDrawerShadow,
} from '@/components/SideDrawer'
import { Gutter } from '@/components/Layout/Gutter'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { UseOwnerDocumentsQuery } from '~generated-types'
import { useTheme } from '@/theme'

import { Header } from './Header'
import { Sections } from './Sections'

type Props = {
  document: DownloadableDocument
  onClose: () => void
  refetch: () => Promise<ApolloQueryResult<UseOwnerDocumentsQuery>>
  showFreeText: boolean
}

export const Document = ({
  document: doc,
  onClose,
  refetch,
  showFreeText,
}: Props) => {
  const { spacing } = useTheme()

  const { pathname, search } = useLocation()

  const [PDFUrl, setPDFUrl] = useState<string>('')
  const [PDFState, setPDFState] = useState<FetchState>(FetchStates.IDLE)

  const buildPdf = async () => {
    setPDFState(FetchStates.LOADING)

    try {
      const url = await DocumentsService.buildPdf(doc.document.id)
      setPDFUrl(url)
      setPDFState(FetchStates.IDLE)
    } catch (err) {
      setPDFState(FetchStates.ERROR)
      console.error('Failed to download', err)
    }
  }

  useEffect(() => {
    buildPdf()
  }, [doc.document.id])

  return createPortal(
    <>
      <SideDrawer width={`${spacing.gu(170)}rem`}>
        <FlexColumn style={{ height: '100%' }}>
          <Header
            document={doc}
            readOnly={PDFState !== FetchStates.IDLE}
            refetch={refetch}
          />

          <HorizontalLine />

          <FlexRow flex={1}>
            <SectionContainer noPadding style={{ width: '35%' }}>
              <Sections
                document={doc.document}
                refetchPdf={buildPdf}
                refetch={refetch}
                showFreeText={showFreeText}
              />
            </SectionContainer>

            <VerticalLine />

            <SectionContainer style={{ width: '65%' }}>
              <DocumentPreview url={PDFUrl} fetchingState={PDFState} />
            </SectionContainer>
          </FlexRow>
        </FlexColumn>

        <SideDrawerBottomControls>
          <Gutter type={['gutter', 'gutterBig']}>
            <InnocuousButton noNudge onClick={onClose}>
              <T>common:action.close</T>
            </InnocuousButton>
          </Gutter>
        </SideDrawerBottomControls>
      </SideDrawer>

      <SideDrawerShadow to={`${pathname}${search}`} onClick={onClose} />
    </>,
    document.getElementById('modal-root') as any
  )
}

///////

const HorizontalLine = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.smoke.light};
  `}
`

const SectionContainer = styled(FlexColumn)<{ noPadding?: boolean }>`
  width: 100%;

  ${({ noPadding, theme }) => css`
    line-height: ${theme.spacing.gu(2.5)}rem;
    padding: ${noPadding ? 0 : theme.spacing.gutterBig};
  `}
`

const VerticalLine = styled.div`
  ${({ theme }) => css`
    border-right: 2px solid ${theme.palette.smoke.light};
  `}
`
