import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useTheme } from '@/theme'

type Props = {
  disabled: boolean
  onClick: (...args: Array<any>) => any
}

export const PlusButton = ({ disabled, onClick }: Props) => {
  const theme = useTheme()

  return (
    <Button disabled={disabled} onClick={onClick}>
      <FontAwesomeIcon
        size="xs"
        color={disabled ? theme.palette.smoke.main : theme.palette.primary.main}
        icon="plus"
      />
    </Button>
  )
}

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  outline: none;
  width: 35px;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }

  &:disabled {
    background: transparent;
    cursor: not-allowed;
  }
`
