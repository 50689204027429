import { gql, useMutation } from '@apollo/client'

import {
  UpdateSalesCommissionMutation,
  UpdateSalesCommissionMutationVariables,
} from '~generated-types'

export type {
  UpdateSalesCommissionMutation,
  UpdateSalesCommissionMutationVariables,
}

export const UPDATE_SALES_COMMISSION_MUTATION = gql`
  mutation UpdateSalesCommission($input: SalesUpdateCommissionInput!) {
    salesUpdateCommission(input: $input) {
      commissionRate
      id
    }
  }
`

export function useUpdateSalesCommissionMutation() {
  return useMutation<
    UpdateSalesCommissionMutation,
    UpdateSalesCommissionMutationVariables
  >(UPDATE_SALES_COMMISSION_MUTATION)
}

export default useUpdateSalesCommissionMutation
