import styled, { css } from 'styled-components/macro'
import { useEffect, useMemo, useState } from 'react'
import chunk from 'lodash.chunk'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  Participant,
  SetDatesInput,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import {
  ParticipantServiceFragment,
  PurchaseProductAddFromSalesProductInput,
  SalesType,
} from '~generated-types'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { ActionDropdown } from '@/components/ActionDropdown'
import { DangerColor } from '@/components/Colors'
import { IconButton } from '@/components/ExtraButtons'
import { useDialogService } from '@/components/DialogService'
import { useTheme } from '@/theme'

import {
  ParticipantAge,
  ParticipantGender,
  ParticipantVisitStatus,
} from '../ParticipantRow/ParticipantMainInfo/components'
import { ParticipantSaleSelector } from '../ParticipantRow'
import { ParticipantServices } from '../ParticipantServices'

type Props = {
  selectedIds: string[]
  isAllSelected: boolean
  selectAll: (selectAll: boolean) => void
}

const MassUpdateMainInfo = ({
  selectedIds,
  isAllSelected,
  selectAll,
}: Props) => {
  const { confirm } = useDialogService()
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const {
    salesId,
    saleType,
    participants,
    handleAddRoom,
    handleAddSalesProducts,
    handleDeleteService,
    handleChangeParticipantData,
    handleServiceSetDates,
    handleSetCheckIn,
    handleSetCheckOut,
    handleSetServiceTarget,
    handleRemovePurchase,
    handleRemovePurchases,
    handleRemoveRoom,
    handleMoveParticipant,
    handleRemoveParticipant,
    handleSetVisitStatus,
    refetchParticipants,
  } = useParticipantsListContext()

  const [creatingNewService, setCreatingNewService] = useState<
    'BED' | 'PRODUCT' | null
  >('BED')
  const [processing, setProcessing] = useState(false)
  const [massServiceRelations, setMassServiceRelations] = useState<any[]>([])
  const [services, setServices] = useState<any[]>([])

  const getStringifiedOptions = (participants: Participant[], option: string) =>
    participants.map((participant) => {
      // @ts-ignore
      const result = removeUnimportantFields(participant[option])

      return typeof result === 'object' && result?.length >= 0
        ? result.map((item: any) => JSON.stringify(item))
        : JSON.stringify(result)
    })

  const isSameOption = (participants: Participant[], option: string) => {
    const options = getStringifiedOptions(participants, option)

    return options.every((option) => option.includes(options[0]))
  }

  // @ts-ignore
  const selectedParticipants: Participant[] = useMemo(
    () =>
      participants.filter(({ id }) =>
        selectedIds.find((selectedId) => selectedId === id)
      ),
    [selectedIds, participants]
  )

  useEffect(() => {
    if (processing) {
      return
    }

    const serviceOptions = getStringifiedOptions(
      selectedParticipants,
      'services'
    )

    const sameServices =
      typeof serviceOptions[0] === 'object' &&
      serviceOptions.map((serviceOption) =>
        serviceOption.map((o: any) =>
          serviceOptions.every((op) => op.includes(o)) ? o : null
        )
      )

    const services = sameServices
      ? selectedParticipants[0].services.filter(
          (service, index) => sameServices[0][index]
        )
      : []

    const massServiceRelations = sameServices
      ? selectedParticipants.map((selectedParticipant, index) =>
          // @ts-ignore
          sameServices[index]
            .map((option: any, index: number) =>
              option
                ? {
                    participantId: selectedParticipant.id,
                    serviceId: selectedParticipant.services[index].id,
                  }
                : null
            )
            .filter(Boolean)
        )
      : []

    setServices(services)
    setMassServiceRelations(massServiceRelations)
  }, [processing, selectedParticipants])

  const eventParticipantSelected =
    saleType === SalesType.Event &&
    selectedParticipants[0]?.sales.id === salesId

  const handleMassAddRoom = (roomReservationId: string) => {
    setProcessing(true)

    const addRooms = async () => {
      for (const participant of selectedParticipants) {
        const { id, services } = participant

        const serviceId = services.find(
          (s) => s.__typename === 'ServiceParticipantBed'
        )?.id

        await handleAddRoom({ id, roomReservationId, serviceId })
      }
    }

    return addRooms().then(() => setProcessing(false))
  }

  const handleMassAddSalesProducts = (
    input: PurchaseProductAddFromSalesProductInput
  ) => {
    setProcessing(true)

    const create = async () => {
      const purchaseInput = selectedParticipants.map(({ id, services }) => ({
        ...input,
        add: {
          ...input.add,
          link: {
            ...input.add.link,
            participantId: id,
            serviceId: services.find((s) => !s.purchaseProduct)?.id,
          },
        },
      }))

      const inputChunks = chunk(purchaseInput, 20)

      for (const chunk of inputChunks) {
        await handleAddSalesProducts(chunk)
      }
    }

    return create()
      .then(refetchParticipants)
      .then(() => setProcessing(false))
  }

  const handleMassSetTarget = (targetId: string) => {
    setProcessing(true)

    const setTargets = async () => {
      for (const participant of selectedParticipants) {
        const { id, services } = participant

        const serviceId = services.find(
          (s) => s.__typename === 'ServiceParticipantBed'
        )?.id

        await handleSetServiceTarget({ id, serviceId, targetId })
      }
    }

    return setTargets().then(() => setProcessing(false))
  }

  const handleMassRemovePurchase = () => {
    setProcessing(true)

    const removePurchases = async () => {
      const ids: string[] = selectedParticipants
        .map(({ services }) => {
          return services.find((s) => s.purchaseProduct?.id !== undefined)
            ?.purchaseProduct?.id
        })
        .filter((purchaseId) => purchaseId !== undefined)
        .map((purchaseId) => purchaseId || '')

      const purchaseIdChunks = chunk(ids, 20)
      for (const chunkOfIds of purchaseIdChunks) {
        await handleRemovePurchases(chunkOfIds)
      }
    }

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>ParticipantsList:Actions.removePurchaseConfirmation</T>,
    })
      .then(removePurchases)
      .then(() => {
        setProcessing(false)
        return refetchParticipants()
      })
      .catch(() => setProcessing(false))
  }

  const isDayVisitor = useMemo(
    () =>
      selectedParticipants.every(({ visitStatus }) => visitStatus.isDayVisitor),
    [selectedParticipants]
  )

  const handleMassUpdate = (
    serviceId: string,
    customVariables: { [key: string]: any },
    handleUpdate: (input: { [key: string]: string }) => Promise<any>,
    variablesInterface?: (
      service: ParticipantServiceFragment,
      participantId: string
    ) => { [key: string]: string }
  ): Promise<any> => {
    setProcessing(true)

    const serviceIndex = massServiceRelations[0].findIndex(
      (relation: any) => relation.serviceId === serviceId
    )

    async function update() {
      await Promise.all(
        massServiceRelations.map(async (relations: any) => {
          const serviceId = relations[serviceIndex].serviceId
          const participantId = relations[serviceIndex].participantId

          const [[service]] = variablesInterface
            ? selectedParticipants
                .map(({ services }) => services)
                .map((services) =>
                  services.filter(({ id }) => id === serviceId)
                )
                .filter((services) => !!services.length)
            : [[]]

          const serviceVariables =
            variablesInterface && variablesInterface(service, participantId)

          await handleUpdate({
            ...serviceVariables,
            ...customVariables,
          })
        })
      )
    }

    return update().then(() => {
      setProcessing(false)
    })
  }

  const removeParticipantOption = {
    icon: 'trash',
    label: translate(
      'ParticipantsList:ParticipantRooms.removeParticipant',
      language
    ),
    onClick: async () => {
      setProcessing(true)
      for (const participant of selectedParticipants) {
        await handleRemoveParticipant(participant.id)
      }
      setProcessing(false)
      selectAll(false)
      await refetchParticipants()
    },
  }

  const removePurchaseOption = {
    disabled: false,
    icon: 'trash',
    label: translate(
      'ParticipantsList:ParticipantProducts.removeProduct',
      language
    ),
    onClick: () => handleMassRemovePurchase(),
  }

  return (
    <FlexRow
      alignItems={'stretch'}
      justifyContent={'flex-start'}
      flex={1}
      style={{ minHeight: '35px' }}
    >
      <FlexRow style={{ maxHeight: '35px' }}>
        <FlexRow alignItems="center">
          <CheckBoxPlaceholder onClick={() => selectAll(!isAllSelected)}>
            {isAllSelected && (
              <FontAwesomeIcon
                color={palette.primary.main}
                fixedWidth
                size="sm"
                icon={'check'}
              />
            )}
          </CheckBoxPlaceholder>
        </FlexRow>

        <FlexRow
          alignItems="center"
          justifyContent="flex-start"
          style={{ width: `${spacing.gu(24)}rem` }}
        >
          <FontAwesomeIcon
            color={palette.primary.main}
            fixedWidth
            size="1x"
            icon="user-group"
            style={{ margin: `${spacing.gu(1)}rem` }}
          />
          {selectedParticipants.length}
        </FlexRow>

        <ParticipantGender
          gender={
            isSameOption(selectedParticipants, 'gender')
              ? selectedParticipants[0]?.gender || null
              : null
          }
          handleSelect={async (gender) => {
            await Promise.all(
              selectedParticipants.map(
                async ({ id }) =>
                  // @ts-ignore
                  await handleChangeParticipantData({ gender }, id)
              )
            )
          }}
        />

        <ParticipantAge
          birthday={
            isSameOption(selectedParticipants, 'birthday')
              ? selectedParticipants[0]?.birthday || null
              : null
          }
          age={
            isSameOption(selectedParticipants, 'age')
              ? selectedParticipants[0]?.age || null
              : null
          }
          ageCategory={
            isSameOption(selectedParticipants, 'ageCategory')
              ? selectedParticipants[0]?.ageCategory || null
              : null
          }
          ageSource={
            isSameOption(selectedParticipants, 'ageSource')
              ? selectedParticipants[0]?.ageSource || null
              : null
          }
          handleChange={async (age, ageCategoryKey, birthday) => {
            await Promise.all(
              selectedParticipants.map(
                async ({ id }) =>
                  await handleChangeParticipantData(
                    {
                      age,
                      ageCategoryKey,
                      birthday,
                    },
                    id
                  )
              )
            )
          }}
        />

        <ParticipantVisitStatus
          disabled={selectedParticipants.some((participant) =>
            participant.services.find(
              (service) => service.__typename === 'ServiceParticipantBed'
            )
          )}
          isDayVisitor={
            selectedParticipants.length > 0
              ? selectedParticipants.every(
                  (participant) =>
                    participant.visitStatus.isDayVisitor ===
                    selectedParticipants[0].visitStatus.isDayVisitor
                )
                ? selectedParticipants[0].visitStatus.isDayVisitor
                : null
              : null
          }
          setVisitStatus={async (visitStatus: string | null) =>
            await Promise.all(
              selectedParticipants.map(
                async ({ id }) =>
                  await handleSetVisitStatus({
                    dayVisitorDates: null,
                    id,
                    isDayVisitor: visitStatus === 'day',
                  })
              )
            )
          }
        />
      </FlexRow>

      <FlexRow style={{ minWidth: `calc(4px + ${spacing.gu(2)}rem)` }} />

      {eventParticipantSelected ? (
        <FlexColumn flex={1}>
          <ParticipantSaleSelector
            participantSalesId={selectedParticipants[0].sales.id}
            handleSelectSale={async (newSale) => {
              await Promise.all(
                selectedParticipants.map(
                  async ({ id }) =>
                    await handleMoveParticipant(id, newSale, salesId)
                )
              )
            }}
          />
          <span style={{ flex: 1 }} />
        </FlexColumn>
      ) : (
        <ParticipantServices
          participant={selectedParticipants[0]}
          services={services}
          allowAccommodation={
            !selectedParticipants.find(
              ({ visitStatus }) => visitStatus.isDayVisitor
            )
          }
          isMassUpdate
          isDayVisitor={isDayVisitor}
          actionsDisabled={eventParticipantSelected}
          saleType={saleType}
          creatingNew={creatingNewService}
          setCreatingNew={setCreatingNewService}
          handleAddRoom={({ roomReservationId }) =>
            handleMassAddRoom(roomReservationId)
          }
          handleDeleteService={(id) =>
            handleMassUpdate(
              id,
              {},
              ({ id }) => handleDeleteService(id),
              (service: ParticipantServiceFragment) => ({
                id: service.id,
              })
            )
          }
          handleAddCatalogProduct={() => new Promise(() => undefined)}
          handleAddSalesProduct={(input) => handleMassAddSalesProducts(input)}
          handleServiceSetDates={(input) =>
            handleMassUpdate(
              input.id,
              {
                checkIn: input.checkIn,
                checkOut: input.checkOut,
              },
              //@ts-ignore
              ({
                id,
                checkIn,
                checkOut,
                participantId,
              }: SetDatesInput & { participantId: string }) =>
                handleServiceSetDates(
                  {
                    checkIn,
                    checkOut,
                    id,
                  },
                  participantId
                ),
              (service, participantId) => ({
                id: service.id,
                participantId: participantId,
              })
            )
          }
          handleSetCheckIn={(roomReservationId, providedTime, serviceId) =>
            handleMassUpdate(
              serviceId,
              { providedTime },
              ({ roomReservationId, providedTime }) =>
                handleSetCheckIn(roomReservationId, providedTime),
              (service: ParticipantServiceFragment) => ({
                roomReservationId:
                  (service.__typename === 'ServiceParticipantBed' &&
                    service.participantRoom?.id) ||
                  '',
              })
            )
          }
          handleSetCheckOut={(roomReservationId, providedTime, serviceId) =>
            handleMassUpdate(
              serviceId,
              { providedTime },
              ({ roomReservationId, providedTime }) =>
                handleSetCheckOut(roomReservationId, providedTime),
              (service: ParticipantServiceFragment) => ({
                roomReservationId:
                  (service.__typename === 'ServiceParticipantBed' &&
                    service.participantRoom?.id) ||
                  '',
              })
            )
          }
          handleSetParticipantTarget={({ targetId }) =>
            handleMassSetTarget(targetId)
          }
          handleRemoveRoom={(roomId, serviceId) =>
            handleMassUpdate(
              serviceId,
              {},
              ({ roomId }) => handleRemoveRoom(roomId),
              (service: ParticipantServiceFragment) => ({
                roomId:
                  (service.__typename === 'ServiceParticipantBed' &&
                    service.participantRoom?.id) ||
                  '',
              })
            )
          }
          handleRemovePurchase={handleRemovePurchase}
          handleSetVisitStatus={async (dayVisitorDates, isDayVisitor) =>
            await Promise.all(
              selectedParticipants.map(
                async ({ id }) =>
                  await handleSetVisitStatus({
                    dayVisitorDates,
                    id,
                    isDayVisitor,
                  })
              )
            )
          }
        />
      )}

      <FlexRow style={{ marginLeft: `${spacing.gu(0.5)}rem` }}>
        <DeleteButtonsWrapper>
          <ActionDropdown
            positioning={{ right: '30px', top: '0px' }}
            // @ts-ignore
            items={[removeParticipantOption, removePurchaseOption]}
            renderToggle={({ toggleDropdown }) => (
              <IconWrapper>
                <IconButton
                  color="transparent"
                  disabled={processing}
                  icon="trash"
                  onClick={toggleDropdown}
                  style={{
                    color: palette.danger.main,
                  }}
                />
              </IconWrapper>
            )}
          />
        </DeleteButtonsWrapper>
      </FlexRow>
    </FlexRow>
  )
}

const removeUnimportantFields = (obj: any): any => {
  if (typeof obj === 'object' && obj?.length >= 0) {
    return obj.map((item: any) => removeUnimportantFields(item))
  } else if (
    typeof obj === 'object' &&
    obj !== null &&
    !obj.hasOwnProperty('length')
  ) {
    const { id, fixedCheckIn, fixedCheckOut, participant, ...objWithNoId } = obj
    const newObj = {}
    for (const key in objWithNoId) {
      // @ts-ignore
      newObj[key] = removeUnimportantFields(objWithNoId[key])
    }
    return newObj
  } else {
    return obj
  }
}

export default MassUpdateMainInfo

const CheckBoxPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gu(1)}rem;
    background-color: ${theme.palette.white};
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`

const DeleteButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  & > div:not(:last-of-type) {
    ${({ theme }) => css`
      margin-bottom: calc(${theme.spacing.gu(2)}rem + 1px);
    `}
  }
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
`
