import { gql, useQuery } from '@apollo/client'

import { RoomTypesQuery as QueryData } from '~generated-types'
import { RoomType } from '../types'
import roomTypeFragments from '../fragments'

export const QUERY = gql`
  ${roomTypeFragments.roomType}

  query RoomTypes {
    registry {
      roomTypes {
        ...RoomType
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export default function useRoomTypes(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, any>(QUERY, {
    fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
  })

  const roomTypes: RoomType[] = data ? [...data.registry.roomTypes] : []

  return {
    error,
    loading,
    refetch,
    roomTypes,
  }
}
