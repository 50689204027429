import { gql } from '@apollo/client'

export default gql`
  query PointsOfSales {
    registry {
      pointOfSales {
        id
        name
      }
    }
  }
`
