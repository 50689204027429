import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import {
  DateRange,
  WeekPickerControl,
  WeekSelection,
} from '@/components/TimeControls'
import { translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'

import { Button, WeeksAmountSelector } from './WeeksAmountSelector'

type Props = {
  displayRange: DateRange
  setDisplayRange: (range: DateRange) => void
}

export const DateRangeSelector = ({ displayRange, setDisplayRange }: Props) => {
  const { language } = useLanguageContext()

  const currentWeeksAmount = displayRange.to.diff(displayRange.from, 'w') + 1

  const [weeksAmount, setWeeksAmount] = useState<number>(currentWeeksAmount)
  const [defaultRange] = useState<DateRange>(displayRange)
  const [defaultWeeksAmount] = useState<number>(currentWeeksAmount)

  const handleShiftWeekToLeft = () =>
    setDisplayRange({
      from: displayRange.from.clone().subtract(1, 'w'),
      to: displayRange.to.clone().subtract(1, 'w'),
    })

  const handleShiftWeekToRight = () =>
    setDisplayRange({
      from: displayRange.from.clone().add(1, 'w'),
      to: displayRange.to.clone().add(1, 'w'),
    })

  const handleSetSelectorRange = (value?: WeekSelection | null) =>
    setDisplayRange(
      value
        ? {
            from: moment()
              .year(value.year)
              .isoWeek(value.week)
              .startOf('isoWeek'),
            to: moment()
              .year(value.year)
              .isoWeek(value.week)
              .add(weeksAmount - 1, 'w')
              .endOf('isoWeek'),
          }
        : displayRange
    )

  const isDefaultValues =
    defaultRange.from.isSame(displayRange.from) &&
    defaultRange.to.isSame(displayRange.to) &&
    defaultWeeksAmount === weeksAmount

  return (
    <FlexRow alignItems="center">
      <IconButton onClick={handleShiftWeekToLeft}>
        <FontAwesomeIcon icon="angle-left" />
      </IconButton>

      <WeekPickerControl
        numberOfMonths={2}
        numberOfWeeks={weeksAmount}
        render={({ openModal, ref }) => (
          <Button onClick={openModal} ref={ref as Ref<HTMLButtonElement>}>
            {formatIntervalSelection(displayRange, language)}
          </Button>
        )}
        setValue={handleSetSelectorRange}
        value={{
          week: displayRange.from.isoWeek(),
          year: displayRange.from.isoWeekYear(),
        }}
      />

      <IconButton onClick={handleShiftWeekToRight}>
        <FontAwesomeIcon icon="angle-right" />
      </IconButton>

      <Separator />

      <WeeksAmountSelector
        onUpdateWeeksAmount={(value) => {
          setWeeksAmount(value)
          setDisplayRange({
            ...displayRange,
            to: displayRange.from
              .clone()
              .add(value - 1, 'w')
              .endOf('isoWeek'),
          })
        }}
        weeksAmount={weeksAmount}
      />

      {!isDefaultValues && (
        <>
          <Separator />

          <IconButton
            onClick={() => {
              setDisplayRange(defaultRange)
              setWeeksAmount(defaultWeeksAmount)
            }}
          >
            <FontAwesomeIcon icon="rotate-left" size="sm" />
          </IconButton>
        </>
      )}
    </FlexRow>
  )
}

////////////

const formatIntervalSelection = (range: DateRange, language: string) => {
  const invalidDates = !range.from.isValid() || !range.to.isValid()
  const invalidRange = range.from.isAfter(range.to)

  if (invalidDates || invalidRange) {
    return translate(
      'Accommodation:AvailabilityCalendar.invalidDisplayRange',
      language
    )
  }

  return `${range.from.format('dd, D.M.YY')} – ${range.to.format('dd, D.M.YY')}`
}

const IconButton = styled(Button)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(5)}rem;
    padding: 0 ${theme.spacing.gutter};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Separator = styled.span`
  ${({ theme }) => css`
    width: 1px;
    height: ${theme.spacing.gu(5)}rem;
    background: ${theme.palette.smoke.main};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
