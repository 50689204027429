import { gql, useQuery } from '@apollo/client'

import {
  UseSalesResourceReservationsQuery as QueryData,
  UseSalesResourceReservationsQueryVariables as QueryVariables,
} from '~generated-types'

import { SalesResourceReservation } from '../types'
import SalesResourceReservationFragment from '../fragments/salesResourceReservation'

const QUERY = gql`
  ${SalesResourceReservationFragment}

  query UseSalesResourceReservations($id: ID!) {
    sales(id: $id) {
      id
      resourceReservations {
        ...SalesResourceReservation
      }
    }
  }
`

type Params = {
  salesId: string
}

export function useSalesResourceReservations({ salesId }: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { id: salesId },
    }
  )

  const reservations: SalesResourceReservation[] = data
    ? data.sales.resourceReservations || []
    : []

  return {
    error,
    loading,
    refetch,
    reservations,
  }
}

export default useSalesResourceReservations
