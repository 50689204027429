import React, { ReactNode, useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import moment from 'moment'

import { useHasUnmounted, useInterval } from '@/utils/hooks'
import { FormikRenderProps } from '@/utils/forms'
import { T } from '@/modules/Language'

type Props = {
  debounceMs?: number
  formikRenderProps: FormikRenderProps
  lastSaved: moment.Moment | null | undefined
  savingPlaceholder?: ReactNode
}

export const FormikAutoSave = ({
  debounceMs,
  formikRenderProps: { isSubmitting, submitForm, values },
  lastSaved,
  savingPlaceholder,
}: Props) => {
  const { hasUnmounted } = useHasUnmounted()

  const [lastSavedTimestamp, setLastSavedTimestamp] = useState<
    string | null | undefined
  >(lastSaved && lastSaved.fromNow())

  const debouncedSubmit = useCallback(
    debounce(() => !hasUnmounted && submitForm(), debounceMs ?? 1000),
    [submitForm, debounce]
  )

  // @ts-ignore
  useEffect(() => debouncedSubmit, [debouncedSubmit, values])

  useEffect(
    () => setLastSavedTimestamp(lastSaved && lastSaved.fromNow()),
    [lastSaved]
  )

  useInterval(() => {
    setLastSavedTimestamp(lastSaved && lastSaved.fromNow())
  }, 10000)

  return !!isSubmitting ? (
    savingPlaceholder ?? <T>FormControls:FormikAutoSave.saving</T>
  ) : !!lastSavedTimestamp ? (
    <>
      <T>FormControls:FormikAutoSave.lastSaved</T> {lastSavedTimestamp}
    </>
  ) : null
}
