import { ChangeEvent, useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { Input } from '@/components/FormControls'
import { useParticipantsListContext } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

export const SearchInput = () => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()
  const { filters, setFilters } = useParticipantsListContext()

  const [value, setValue] = useState<string>(filters.search ?? '')

  const initialRender = useRef<boolean>(true)

  const handleSetSearch = (search: string) =>
    setFilters({ ...filters, search: search || null })

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false
    } else {
      const debounceTimer = setTimeout(() => {
        handleSetSearch(value)
      }, 500)

      return () => clearTimeout(debounceTimer)
    }
  }, [value])

  return (
    <Wrapper justifyContent="flex-start" alignItems="center">
      <SearchIcon>
        <FontAwesomeIcon color={palette.primary.main} icon="magnifying-glass" />
      </SearchIcon>

      <Input
        value={value}
        placeholder={translate('FilterSearchList:search', language)}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
      />
    </Wrapper>
  )
}

////////

const SearchIcon = styled.div`
  position: absolute;
  margin-left: 10px;
  width: 30px;
`

const Wrapper = styled(FlexRow)`
  input {
    &:not([type='checkbox']):not([type='radio']) {
      border-radius: 4px;
      padding-left: 30px;

      ${({ theme }) => css`
        border: 1px solid ${theme.palette.smoke.main};
        font-size: ${theme.typography.fontSizeSmall};
        width: ${theme.spacing.gu(26)}rem;
      `}

      &::placeholder {
        ${({ theme }) => css`
          color: ${theme.palette.text.lighter};
        `}
      }
    }
  }
`
