import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  SpreadsheetTD as TD,
  SpreadsheetTR as TR,
} from '@/components/Spreadsheet'
import { FontWeight } from '@/components/Typography'
import { formatCurrency } from '@/utils/currency'
import { OrderItemProduct as OrderItemProductType } from '@/modules/Order/types'
import { PriceType } from '~generated-types'
import { T } from '@/modules/Language'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import {
  ItemName,
  ItemNameWrapper,
  LightLabel,
  LineLeader,
  Spacer,
} from './common'

type Props = {
  isHover?: boolean
  isLastNestedItem?: boolean
  isNested?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  openEditor: () => void
  participantName?: string | ReactNode
  product: OrderItemProductType
  showProductCodes?: boolean
}

export const OrderItemProduct = ({
  isLastNestedItem,
  isNested,
  openEditor,
  participantName,
  product,
  showProductCodes,
  ...props
}: Props) => {
  const {
    code,
    marginalTax,
    name,
    quantity,
    totalPrice,
    unitPrice: {
      price,
      type,
      vat: { vatRate },
    },
    validationStatus: { issues },
  } = product

  return (
    <Row
      borderBottomStyle={isNested && !isLastNestedItem ? 'dashed' : 'solid'}
      onClick={openEditor}
      {...props}
    >
      <TD style={{ padding: 0 }}>
        <LineLeaderWrapper>
          {isNested && <LineLeader fullHeight={!isLastNestedItem} />}
        </LineLeaderWrapper>
      </TD>

      <TD style={{ paddingLeft: 0 }}>
        <ItemNameWrapper alignItems="center" withPaddingLeft={isNested}>
          <FlexColumn alignItems="flex-start">
            {isNested ? name : <ItemName>{name}</ItemName>}
            {showProductCodes && code && <ProductCode>{code}</ProductCode>}
          </FlexColumn>

          <Spacer />

          {!isNested &&
            issues?.map(({ code, key, level }) => (
              <ValidationIndicator
                key={`${key}-${code}`}
                code={code}
                level={level}
              />
            ))}
        </ItemNameWrapper>
      </TD>

      <TD>{!isNested && participantName}</TD>

      <TD align="right">
        {marginalTax?.active ? (
          <T>Products:ProductManager.pricing.marginal</T>
        ) : (
          `${vatRate} %`
        )}
      </TD>

      <TD align="right">
        {type === PriceType.VatIncluded ? (
          <T>Orders:OrderItems.vatIncluded</T>
        ) : (
          <T>Orders:OrderItems.vatExcluded</T>
        )}
      </TD>

      <TD align="right">{formatCurrency(price)} €</TD>

      <TD align="right">
        {quantity} <T>common:unit.PIECE</T>
      </TD>

      <TD align="right">
        {formatCurrency(totalPrice.vatAmount)} €{' '}
        <LightLabel>
          (<T>Orders:OrderItems.vat</T>)
        </LightLabel>
      </TD>

      <TD align="right">
        <FontWeight bold={!isNested} normal={isNested}>
          {formatCurrency(totalPrice.amountVatIncluded)} €
        </FontWeight>
      </TD>

      <TD />
    </Row>
  )
}

////////////

const LineLeaderWrapper = styled(FlexRow)`
  height: 100%;
`

const Row = styled(TR)<{ isHover?: boolean }>`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  ${({ isHover, theme }) =>
    isHover && `background: ${theme.palette.primary.extraLighter};`}

  &:hover {
    ${({ theme }) => css`
      background: ${theme.palette.primary.extraLighter};
    `}
  }
`

const ProductCode = styled.code`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
