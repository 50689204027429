import { ButtonGroup, ButtonGroupButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

import { ListViewMode } from '../../types'
import { useSalesProductListContext } from '../../SalesProductListState'

export const ListViewModeToggle = () => {
  const { listViewMode, setListViewMode } = useSalesProductListContext()

  return (
    <ButtonGroup style={{ margin: 0 }}>
      <ButtonGroupButton
        noNudge
        onClick={() => setListViewMode(ListViewMode.Products)}
        selected={listViewMode === ListViewMode.Products}
        size="small"
      >
        <T>Products:SalesProductList.products</T>
      </ButtonGroupButton>
      <ButtonGroupButton
        noNudge
        onClick={() => setListViewMode(ListViewMode.Purchases)}
        selected={listViewMode === ListViewMode.Purchases}
        size="small"
      >
        <T>Products:SalesProductList.purchases</T>
      </ButtonGroupButton>
    </ButtonGroup>
  )
}
