import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { FlexColumn } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import OptionSelector from './common/OptionSelector'

type Props = {
  disabled?: boolean
  isDayVisitor: boolean | null
  setVisitStatus: (visitStatus: string) => Promise<any>
}

const ParticipantVisitStatus = ({
  disabled,
  isDayVisitor,
  setVisitStatus,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)

  const statusOptions = [
    {
      label: (
        <span>
          <FontAwesomeIcon
            fixedWidth
            color={palette.primary.main}
            icon="bed"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>ParticipantsList:VisitStatus.nightStay</T>
        </span>
      ),
      value: 'accommodation',
    },
    {
      label: (
        <span>
          <FontAwesomeIcon
            fixedWidth
            color={palette.primary.main}
            icon="sun"
            style={{ marginRight: `${spacing.gu(1)}rem` }}
          />
          <T>ParticipantsList:VisitStatus.dayVisitor</T>
        </span>
      ),
      value: 'day',
    },
  ]

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal style={{ overflow: 'visible', width: '250px' }}>
          <InlineModalSection style={{ margin: 0, padding: 0 }}>
            <FlexColumn
              alignItems={'stretch'}
              flex={1}
              style={{ padding: `${spacing.gu(2)}rem` }}
            >
              <OptionSelector
                options={statusOptions}
                value={
                  isDayVisitor === null
                    ? null
                    : isDayVisitor
                    ? 'day'
                    : 'accommodation'
                }
                style={{ margin: `${spacing.gu(1)}rem 0` }}
                handleChange={(visitStatus: string | null) =>
                  setVisitStatus(visitStatus || 'accommodation').then(() =>
                    setModalOpen(false)
                  )
                }
              />
            </FlexColumn>
          </InlineModalSection>
        </InlineModal>
      }
      onClose={() => setModalOpen(false)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <StatusWrapper
          disabled={disabled}
          ref={ref}
          onClick={() => (disabled ? null : setModalOpen(true))}
        >
          <FontAwesomeIcon
            fixedWidth
            color={palette.primary.main}
            size="lg"
            icon={
              isDayVisitor === null ? 'circle' : isDayVisitor ? 'sun' : 'bed'
            }
          />
        </StatusWrapper>
      )}
    />
  )
}

export default ParticipantVisitStatus

const StatusWrapper = styled.div<{ disabled?: boolean }>`
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-align: center;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : 'transparent'};

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`
