import { FlexColumn } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { T } from '@/modules/Language'

import {
  ListingReservationTask,
  ListingReservationTaskPurchase,
} from '../useReservationList'
import { ListingTask, ListingTaskPurchase } from '../useTaskList'

type Purchase = ListingReservationTaskPurchase | ListingTaskPurchase

type Props = {
  task: ListingReservationTask | ListingTask
  showEmpty?: boolean
}

export const TaskPurchases = ({ task, showEmpty }: Props) => {
  const renderProductInfo = ({ attributes, product }: Purchase) => {
    const name = product.name
    const price = product.items
      .map(({ unitPrice }) => unitPrice.price)
      .reduce((acc, val) => acc + val, 0)
    const quantity = attributes.quantity
    const vat = Array.from(
      new Set(product.items.map(({ unitPrice }) => unitPrice.vat.vatRate))
    )
    const vatRate =
      vat.length > 1 ? (
        <T>Listings:CalendarResourceListing.field.taskDetails.multiVat</T>
      ) : (
        `${vat[0] || '—'}`
      )

    return (
      <span>
        {quantity} × {name} – {formatCurrency(price)} €/
        <T>enums:quantityUnit.abbrev.PIECE</T> (
        <T>Listings:CalendarResourceListing.field.taskDetails.vat</T> {vatRate}
        {' %'})
      </span>
    )
  }

  const renderPriceInfo = (purchase: Purchase) =>
    `yht. ${formatCurrency(purchase.totalPrice.amountVatIncluded)} €`

  const taskPurchases = task.purchaseProducts as Purchase[]

  return (
    <>
      {taskPurchases.map((purchase) => (
        <FlexColumn
          key={`${task.id}-${purchase.id}`}
          noPadding
          style={{ marginBottom: 4 }}
        >
          {renderProductInfo(purchase)}
          <strong>{renderPriceInfo(purchase)}</strong>
        </FlexColumn>
      ))}
      {showEmpty && !taskPurchases.length && '–'}
    </>
  )
}
