import { FC, ReactElement, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import ReactLoading from 'react-loading'

import { IconButton, IconButtonLink } from '@/components/ExtraButtons'
import { documentHooks } from '@/modules/Document'
import { notify } from '@/components/NotificationService'
import { SalesForDateType } from '~generated-types'
import { SalesTypeChip } from '@/modules/Sales'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

import { ColorVariant, getVisitTypeColor } from '../utils'
import {
  RoomsAndParticipants,
  SalesForDate,
  SalesForDateStats,
} from '../../../types'
import { CountIcons } from './CountIcons'
import { ListItemColumn } from './ListItemColumn'

interface Props {
  data: SalesForDate
  salesStats?: SalesForDateStats['visitStats']
  customerData?: NonNullable<SalesForDateStats['customer']>['customer']
  showHeaders?: boolean
}

export const CustomerInfo: FC<Props> = ({
  data: sales,
  salesStats,
  customerData,
  showHeaders,
}): ReactElement => {
  const theme = useTheme()

  const [loadingPDF, setLoadingPDF] = useState<boolean>(false)

  const { documentsByTemplateType, loading } = documentHooks.useOwnerDocuments({
    ownerId: sales.id,
    skip: !loadingPDF,
  })

  const programDocument = documentsByTemplateType.PROGRAM?.[0]

  const customerName =
    customerData?.__typename === 'CustomerOrganization'
      ? customerData.organization.name
      : customerData?.__typename === 'CustomerPerson'
      ? `${customerData.person.lastName}, ${customerData.person.firstName}`
      : null

  const [startDate, endDate] = sales.estimatedDates
    ? formatDates(sales.estimatedDates.start, sales.estimatedDates.end)
    : sales.reservationDates
    ? formatDates(sales.reservationDates.start, sales.reservationDates.end)
    : []

  useEffect(() => {
    if (loading || !programDocument) return

    programDocument
      .downloadPDF()
      .catch(() =>
        notify({
          content: <T>SalesDetails:error:programPDF</T>,
          type: 'ERROR',
        })
      )
      .finally(() => setLoadingPDF(false))
  }, [loading, programDocument])

  const renderActions = () => (
    <>
      <Tooltip
        content={
          <T
            l10n={`FrontDesk:DailyCustomerList.sales.openDetails.${sales.type}`}
          />
        }
        placement="left"
        trigger={(triggerProps) => (
          <span {...triggerProps}>
            <IconButtonLink
              color="transparent"
              href={`/sales/details/${sales.id}/overview`}
              icon="magnifying-glass"
              iconColor={theme.palette.text.light}
              noNudge
              onClick={(event) => event.stopPropagation()}
              target="_blank"
            />
          </span>
        )}
      />
      <Tooltip
        content={<T>SalesDetails:section.programPDF</T>}
        placement="left"
        trigger={(triggerProps) => (
          <span {...triggerProps}>
            <IconButton
              color="transparent"
              disabled={loadingPDF}
              icon={['far', 'file-alt']}
              iconColor={theme.palette.text.light}
              noNudge
              onClick={(event) => {
                event.stopPropagation()
                setLoadingPDF(true)
              }}
            />
          </span>
        )}
      />
    </>
  )

  const getCountsForType = (
    type: SalesForDateType
  ): RoomsAndParticipants | null => {
    if (!salesStats) return null
    if (type === SalesForDateType.Arriving) return salesStats.arriving
    if (type === SalesForDateType.Departing) return salesStats.departing
    if (type === SalesForDateType.Present) return salesStats.present

    return null
  }

  const renderCountsColumn = (type: SalesForDateType) => {
    const { checkedParticipants, checkedRooms, participants, rooms } =
      getCountsForType(type) || {
        checkedParticipants: 0,
        checkedRooms: 0,
        participants: 0,
        rooms: 0,
      }

    return showHeaders ? (
      <InfoColumn hasMargin width="120px">
        <HeaderLabel
          align="center"
          color={getVisitTypeColor(type, ColorVariant.DARK, theme)}
        >
          <T l10n={`FrontDesk:DailyCustomerList.visitType.${type}`} />
        </HeaderLabel>
        <CountIcons
          color={getVisitTypeColor(type, ColorVariant.DEFAULT, theme)}
        />
        <InfoRowHeaderVariant
          color={getVisitTypeColor(type, ColorVariant.LIGHT, theme)}
          isLoading={!salesStats}
        >
          <InfoCell>
            {salesStats && (
              <>
                {rooms || '–'}
                {renderCheckStatus(rooms, checkedRooms)}
              </>
            )}
          </InfoCell>
          <InfoCell>
            {salesStats && (
              <>
                {participants || '–'}
                {renderCheckStatus(participants, checkedParticipants)}
              </>
            )}
          </InfoCell>
        </InfoRowHeaderVariant>
      </InfoColumn>
    ) : (
      <InfoRow
        color={getVisitTypeColor(type, ColorVariant.LIGHT, theme)}
        isLoading={!salesStats}
        width="120px"
      >
        <InfoCell>
          {salesStats && (
            <>
              {rooms || '–'}
              {renderCheckStatus(rooms, checkedRooms)}
            </>
          )}
        </InfoCell>
        <InfoCell>
          {salesStats && (
            <>
              {participants || '–'}
              {renderCheckStatus(participants, checkedParticipants)}
            </>
          )}
        </InfoCell>
      </InfoRow>
    )
  }

  const renderCheckStatus = (target: number, checked: number) => {
    if (!target) {
      return null
    }

    const uncheckedCount = Math.max(0, target - checked)
    const isReady = uncheckedCount === 0

    return (
      <Tooltip
        color={!uncheckedCount ? 'success' : 'default'}
        content={
          !uncheckedCount ? (
            <T>FrontDesk:DailyCustomerList.checkIn.checked</T>
          ) : (
            <T count={uncheckedCount}>
              FrontDesk:DailyCustomerList.checkIn.unchecked
            </T>
          )
        }
        delay={300}
        placement="top"
        trigger={(triggerProps) => (
          <span {...triggerProps}>
            <FontAwesomeIcon
              color={
                isReady ? theme.palette.success.dark : theme.palette.text.light
              }
              fixedWidth
              icon={isReady ? 'check' : ['far', 'clock']}
              style={{ marginLeft: theme.spacing.gutterSmall }}
            />
          </span>
        )}
      />
    )
  }

  return (
    <>
      <ListItem>
        {showHeaders && (
          <HeaderLabel>
            <T>FrontDesk:DailyCustomerList.customer.title</T>
          </HeaderLabel>
        )}
        <CustomerLabel isPlaceholder={!customerName} title={customerName || ''}>
          {!!salesStats ? (
            customerName ?? <T>FrontDesk:DailyCustomerList.customer.unset</T>
          ) : (
            <ReactLoading
              height={20}
              width={20}
              type={'bubbles'}
              color={theme.palette.smoke.main}
            />
          )}
        </CustomerLabel>

        <SaleLabelWrapper>
          <SaleLabel>
            <SalesTypeChip
              facet={sales.facet}
              format="short"
              size="xs"
              type={sales.type}
            />

            <TruncateLabel title={sales.name || ' '}>
              {sales.orderNumber ? `#${sales.orderNumber} ` : ''}
              {sales.name || (
                <T
                  l10n={`FrontDesk:DailyCustomerList.sales.unnamed.${sales.type}`}
                />
              )}
            </TruncateLabel>
          </SaleLabel>
        </SaleLabelWrapper>
      </ListItem>

      <ListItemColumn width="140px">
        {showHeaders && (
          <HeaderLabel>
            <T>FrontDesk:DailyCustomerList.time</T>
          </HeaderLabel>
        )}
        {startDate && (
          <LabelSmall>
            <FontAwesomeIcon
              color={theme.palette.text.lighter}
              fixedWidth
              icon="right-to-bracket"
              size="sm"
            />{' '}
            {startDate}
          </LabelSmall>
        )}
        {endDate && (
          <LabelSmall>
            <FontAwesomeIcon
              color={theme.palette.text.lighter}
              fixedWidth
              icon="right-from-bracket"
              size="sm"
            />{' '}
            {endDate}
          </LabelSmall>
        )}
      </ListItemColumn>

      <ListItemColumn width="50px">
        {sales?.paymentAgreement && (
          <>
            {showHeaders && <HeaderLabel minHeight={2} />}
            <Label>
              <Tooltip
                color={'default'}
                content={sales?.paymentAgreement?.name}
                delay={300}
                placement="top"
                trigger={(triggerProps) => (
                  <span {...triggerProps}>{sales?.paymentAgreement?.code}</span>
                )}
              />
            </Label>
          </>
        )}
      </ListItemColumn>

      <ListItemColumn width="120px">
        {showHeaders && (
          <HeaderLabel>
            <T>FrontDesk:DailyCustomerList.seller</T>
          </HeaderLabel>
        )}
        <Label>{sales.seller?.shortName || sales.seller?.name || '—'}</Label>
      </ListItemColumn>

      {renderCountsColumn(SalesForDateType.Arriving)}
      {renderCountsColumn(SalesForDateType.Present)}
      {renderCountsColumn(SalesForDateType.Departing)}

      {showHeaders ? (
        <InfoColumn hasMargin noGrow width="40px">
          <HeaderLabel
            color={getVisitTypeColor(
              SalesForDateType.DayVisitor,
              ColorVariant.DARK,
              theme
            )}
          >
            <T
              l10n={`FrontDesk:DailyCustomerList.visitType.${SalesForDateType.DayVisitor}`}
            />
          </HeaderLabel>
          <InfoRowHeaderVariant
            color={getVisitTypeColor(
              SalesForDateType.DayVisitor,
              ColorVariant.LIGHT,
              theme
            )}
            isLoading={!salesStats}
          >
            <InfoCell>
              {salesStats ? salesStats.dayVisitors.participants || '–' : ''}
            </InfoCell>
          </InfoRowHeaderVariant>
        </InfoColumn>
      ) : (
        <InfoRow
          color={getVisitTypeColor(
            SalesForDateType.DayVisitor,
            ColorVariant.LIGHT,
            theme
          )}
          smallMargin
          width="40px"
          isLoading={!salesStats}
        >
          <InfoCell>
            {salesStats ? salesStats.dayVisitors.participants || '–' : ''}
          </InfoCell>
        </InfoRow>
      )}

      <InfoColumn
        style={{
          alignItems: 'center',
          alignSelf: 'center',
        }}
        width={`${theme.spacing.gu(5)}rem`}
      >
        {renderActions()}
      </InfoColumn>
    </>
  )
}

////////////

function formatDates(startStr: string, endStr: string): [string, string] {
  const WITH_YEAR = 'dd DD.MM.YYYY'
  const WITHOUT_YEAR = 'dd DD.MM'

  const currentYear = moment().year()
  const from = moment(startStr)
  const to = moment(endStr)

  return [
    from.format(from.year() === currentYear ? WITHOUT_YEAR : WITH_YEAR),
    to.format(to.year() === currentYear ? WITHOUT_YEAR : WITH_YEAR),
  ]
}

const truncateText = ` 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

interface CustomerLabelProps {
  isPlaceholder?: boolean
}

const CustomerLabel = styled.span<CustomerLabelProps>`
  font-weight: 500;
  height: 20px;
  ${truncateText}

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    margin-bottom: ${theme.spacing.gutterSmall};
  `}

  ${({ isPlaceholder, theme }) =>
    isPlaceholder &&
    css`
      color: ${theme.palette.text.light};
      font-style: italic;
      font-weight: 400;
    `};
`

interface HeaderLabelProps {
  align?: string
  color?: string
  minHeight?: number
}

const HeaderLabel = styled.span<HeaderLabelProps>`
  color: ${({ color, theme }) => (color ? color : theme.palette.text.light)};

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}

  ${({ theme, minHeight }) =>
    minHeight &&
    css`
      min-height: ${theme.spacing.gu(minHeight)}rem;
      with: -webkit-fill-available;
    `}

  font-variant-caps: all-small-caps;

  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''};
`

interface InfoCellProps {
  noBorder?: boolean
  notAvailable?: boolean
}

const InfoCell = styled.div<InfoCellProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-weight: 500;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
    border-left: solid 1px ${theme.palette.smoke.dark};
    border-right: solid 1px ${theme.palette.smoke.dark};
  `}

  &:not(:last-child) {
    border-right-width: 0;
  }
`

interface InfoColumnProps {
  hasMargin?: boolean
}

const InfoColumn = styled(ListItemColumn)<InfoColumnProps>`
  align-items: stretch;
  align-self: flex-end;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  ${({ hasMargin, theme }) =>
    hasMargin
      ? css`
          margin-right: ${theme.spacing.gutterBig};
        `
      : ''}
`

interface InfoRowProps {
  color?: string
  smallMargin?: boolean
  isLoading?: boolean
}

const glow = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.625;
  }
  100% {
    opacity: 1;
  }
`

const InfoRow = styled(ListItemColumn)<InfoRowProps>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: ${glow} 2s infinite;
    `};

  align-self: stretch;
  align-items: stretch;
  flex-direction: row;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterBig};
  `}

  ${({ color }) =>
    color
      ? css`
          background: ${color};
        `
      : ''}

  ${({ smallMargin, theme }) =>
    smallMargin
      ? css`
          margin-right: ${theme.spacing.gutter};
        `
      : ''}
`

const InfoRowHeaderVariant = styled.div<InfoRowProps>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: ${glow} 2s infinite;
    `};

  align-self: stretch;
  display: flex;

  ${({ theme }) => css`
    border-top: solid 1px ${theme.palette.smoke.dark};
    border-bottom: solid 1px ${theme.palette.smoke.dark};
    margin-top: ${theme.spacing.gu(1)}rem;
  `}

  ${({ color }) =>
    color
      ? css`
          background: ${color};
        `
      : ''}

  ${({ smallMargin, theme }) =>
    smallMargin
      ? css`
          margin-right: ${theme.spacing.gutter};
        `
      : ''}
`

const Label = styled.span`
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.main};
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const LabelSmall = styled.span`
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const ListItem = styled(ListItemColumn)`
  display: grid;
  flex: auto;

  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(15)}rem;
  `};
`

const SaleLabelWrapper = styled.div`
  display: grid;
`

const SaleLabel = styled.div`
  display: flex;
  align-items: center;
  ${truncateText}
`

const TruncateLabel = styled.span`
  font-weight: 300;
  ${truncateText}

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`
