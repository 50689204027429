import { T } from '@/modules/Language'

export const l10nListItemRenderer =
  (l10nPrefix: string) => (label: string, count: number) =>
    (
      <span>
        <span>
          <T l10n={`${l10nPrefix}.${label}`}></T>
        </span>
        <span>{count}</span>
      </span>
    )
