import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import { Payment } from '@/modules/Order/types'
import { PaymentState } from '~generated-types'
import { T } from '@/modules/Language'

import { PaymentRow } from './PaymentRow'

type Props = {
  invoiceId: string
  orderId: string
  payments: Payment[]
}

export const Payments = ({ invoiceId, orderId, payments }: Props) => {
  const paymentsArr = payments
    .filter(
      ({ state }) =>
        state === PaymentState.Paid || state === PaymentState.PaidPending
    )
    .sort(
      (a, b) =>
        moment(a.auditLog.createdAt).valueOf() -
        moment(b.auditLog.createdAt).valueOf()
    )

  return (
    <Wrapper>
      <Title>
        <T>Orders:Payments.payments</T>
      </Title>

      <PaymentsWrapper>
        {!paymentsArr.length ? (
          <Placeholder>
            <span>
              <T>Orders:Payments.modal.emptyTitle</T>
            </span>
            <span>
              <T>Orders:Payments.modal.emptyLabel</T>
            </span>
          </Placeholder>
        ) : (
          paymentsArr.map((payment) => (
            <PaymentRow
              invoiceId={invoiceId}
              key={payment.id}
              orderId={orderId}
              payment={payment}
            />
          ))
        )}
      </PaymentsWrapper>
    </Wrapper>
  )
}

///////

const PaymentsWrapper = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Placeholder = styled.span`
  display: flex;
  flex-direction: column;
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    color: ${theme.palette.text.light};
  `}
`

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig};
  `}
`
