import { gql, useMutation } from '@apollo/client'

import {
  CreateEventMutation,
  CreateEventMutationVariables,
} from '~generated-types'

export type { CreateEventMutation, CreateEventMutationVariables }

export const CREATE_EVENT_MUTATION = gql`
  mutation CreateEvent($input: SalesCreateEventInput!) {
    salesCreateEvent(input: $input) {
      id
      orderNumber
    }
  }
`

export function useCreateEventMutation() {
  return useMutation<CreateEventMutation, CreateEventMutationVariables>(
    CREATE_EVENT_MUTATION
  )
}

export default useCreateEventMutation
