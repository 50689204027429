import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { Chip as ChipBase } from '@/components/Chip'
import { FontWeight } from '@/components/Typography'

type Props = {
  /**
   * KEYS:
   * total = totals
   * unknown = no age group set
   * [shortname] = short name of the age group
   */
  counts: Record<string, number>
  position?: 'column' | 'row'
  title: ReactNode
}

export const AgeGroupBreakdown = ({
  counts,
  position = 'row',
  title,
}: Props) => {
  const renderTitle = () => (
    <Title withMargin={position === 'column'}>
      {title}: <strong>{counts.total}</strong>
    </Title>
  )

  const renderChips = () => (
    <FlexRow>
      {Object.keys(counts)
        .filter((x) => x !== 'total' && x !== 'unknown')
        .sort()
        .map(
          (x) =>
            !!counts[x] && (
              <Chip
                key={`participant-counts-${x}`}
                size="xs"
                variant="outlined"
              >
                {x}
                <ChipDivider />
                <FontWeight extraBold>{counts[x]}</FontWeight>
              </Chip>
            )
        )}
      {!!counts['unknown'] && (
        <Chip size="xs" variant="outlined">
          ?
          <ChipDivider />
          <FontWeight extraBold>{counts['unknown']}</FontWeight>
        </Chip>
      )}
    </FlexRow>
  )

  return position === 'column' ? (
    <FlexColumn>
      {renderTitle()}
      {renderChips()}
    </FlexColumn>
  ) : (
    <FlexRow alignItems="center">
      {renderTitle()}
      {renderChips()}
    </FlexRow>
  )
}

////////////

const Title = styled.span<{ withMargin: boolean }>`
  ${({ theme, withMargin }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    margin-right: ${theme.spacing.gutterBig};
    margin-bottom: ${withMargin ? theme.spacing.gu(0.5) : 0}rem;
  `}
`

const Chip = styled(ChipBase)`
  font-size: 0.75rem;
  font-variant-caps: normal;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(0.75)}rem;
  `}
`

const ChipDivider = styled.div`
  display: inline-block;
  position: relative;
  border-left: inherit;

  ${({ theme }) => css`
    margin: -${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(0.75)}rem;
  `}

  @media print {
    margin-left: 2pt;
    margin-right: 2pt;
  }
`
