import styled, { css } from 'styled-components/macro'

import { StatePropType } from '@/common/types'

type Props = Partial<{
  disabled: boolean
  state: StatePropType
}>

export const InputIndicatorBase = styled.span<Props>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.guPx(1) + 1}px;
    width: ${theme.spacing.guPx(2) + 4}px;
    height: ${theme.spacing.guPx(2) + 4}px;
  `}

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.dark};
  `}

  border-color: ${({ disabled, state, theme }) =>
    disabled
      ? theme.palette.smoke.main
      : theme.palette.findUtilityColor(state) || theme.palette.smoke.dark};
  ${({ theme }) => css`
    border-radius: ${theme.spacing.guPx(1) - 4}px;
  `}
  background: ${(props) =>
    props.disabled
      ? props.theme.palette.smoke.light
      : props.theme.palette.white};

  &::before {
    content: '';
  }
`

export default InputIndicatorBase
