import { gql, useQuery } from '@apollo/client'

import {
  UseSalesTaskCountsByAssigneeQuery as QueryData,
  UseSalesTaskCountsByAssigneeQueryVariables as QueryVariables,
} from '~generated-types'
import { taskFragments } from '@/modules/Task'
import { UserGroup } from '@/modules/Registry'

const QUERY = gql`
  ${taskFragments.taskAssignee}

  query UseSalesTaskCountsByAssignee($id: ID!) {
    sales(id: $id) {
      id
      tasks {
        assignee {
          ...TaskAssignee
        }
        description
        dueDate
        dueTime
        id
        name
        number
      }
    }
  }
`

interface UseSalesTaskCountsByAssigneeProps {
  salesId: string
}

type AssigneeName = string | 'unassigned'

interface UseSalesTaskCountsByAssigneeHook {
  error: boolean
  loading: boolean
  taskCounts: Record<AssigneeName, number>
}

const useSalesTaskCountsByAssignee = ({
  salesId,
}: UseSalesTaskCountsByAssigneeProps): UseSalesTaskCountsByAssigneeHook => {
  const {
    data,
    error: apolloError,
    loading,
  } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: salesId,
    },
  })

  const taskCounts: Record<AssigneeName, number> = data
    ? data.sales.tasks.reduce((acc: Record<AssigneeName, number>, task) => {
        const key = (task.assignee as UserGroup)?.name ?? 'unassigned'
        acc[key] = acc[key] || 0
        acc[key] += 1

        return acc
      }, {})
    : {}

  return {
    error: !!apolloError,
    loading,
    taskCounts,
  }
}

export default useSalesTaskCountsByAssignee
