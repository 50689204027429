import styled, { css } from 'styled-components/macro'

import { StatePropType } from '@/common/types'
import { useTheme } from '@/theme'

import { InlineLabel } from '../InlineLabel'
import { Input } from '../Input'

type SProps = {
  disabled?: boolean
  state?: StatePropType
}
type TProps = {
  checked?: boolean
  disabled?: boolean
  hideLabels?: boolean
  noMargin?: boolean
  offLabel?: string
  onChange?: (...args: any[]) => void
  onLabel?: string
  state?: StatePropType
}

const StyledToggleInput = styled(Input)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
`

export const Slider = styled.span<SProps>`
  display: inline-flex;
  align-items: center;
  padding: 3px;

  ${({ theme }) => css`
    width: ${theme.spacing.guPx(6)}px;
    height: ${theme.spacing.guPx(2) + 4}px;
  `}

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}

  background: ${(props) =>
    props.disabled
      ? props.theme.palette.smoke.main
      : props.theme.palette.white};
  border: 2px solid transparent;
  border-color: ${(props) =>
    props.disabled
      ? props.theme.palette.smoke.main
      : props.theme.palette.findUtilityColor(props.state) ||
        props.theme.palette.smoke.dark};

  ${({ theme }) => css`
    border-radius: ${theme.spacing.guPx(1) + 2}px;
  `}

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  &::after {
    content: '';
    display: block;

    ${({ theme }) => css`
      width: ${theme.spacing.guPx(1) + 2}px;
      height: ${theme.spacing.guPx(1) + 2}px;
    `}

    background: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.dark
        : props.theme.palette.findUtilityColor(props.state) ||
          props.theme.palette.primary.main};

    ${({ theme }) => css`
      border-radius: ${theme.spacing.guPx(1) - 3}px;
    `}

    transition: transform 0.1s ease-out, background 0.05s ease-out,
      border-color 0.05s ease-out;
  }

  input[type='checkbox']:checked ~ & {
    background: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.main
        : props.theme.palette.findUtilityColor(props.state) ||
          props.theme.palette.primary.main};
    border-color: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.main
        : props.theme.palette.findUtilityColor(props.state) ||
          props.theme.palette.primary.main};
  }

  input[type='checkbox']:active ~ & {
    ${({ theme }) => css`
      border-color: ${theme.palette.primary.main};
    `}
  }

  input[type='checkbox']:checked ~ &::after {
    background: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.dark
        : props.theme.palette.white};

    ${({ theme }) => css`
      transform: translateX(${theme.spacing.guPx(3) + 3}px);
    `}
  }
`

const Description = styled.span`
  font-weight: 300;
`
const OnDescription = styled(Description)`
  display: none;

  input[type='checkbox']:checked ~ & {
    display: inline;
  }
`
const OffDescription = styled(Description)`
  display: inline;

  input[type='checkbox']:checked ~ & {
    display: none;
  }
`

const Toggle = ({
  disabled,
  hideLabels,
  noMargin,
  onLabel,
  offLabel,
  state,
  ...props
}: TProps) => {
  const theme = useTheme()

  return (
    <InlineLabel
      disabled={disabled}
      style={
        noMargin ? { margin: 0 } : { marginRight: theme.spacing.gutterSmall }
      }
    >
      <StyledToggleInput
        {...props}
        disabled={disabled}
        state={state}
        type="checkbox"
      />
      <Slider disabled={disabled} state={state} />
      {!hideLabels && <OnDescription>{onLabel || 'On'}</OnDescription>}
      {!hideLabels && <OffDescription>{offLabel || 'Off'}</OffDescription>}
    </InlineLabel>
  )
}

export default Toggle
