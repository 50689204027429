import React, { useMemo, useState } from 'react'

import { SearchListContext } from '@/modules/Sales'

interface WithLocalStorageProps {
  componentIds: Record<string, string>
  getDefaultQuery: () => Record<string, unknown>
  URLParams?: boolean
  filterNames: string[]
  searchContext: SearchListContext
}

export type FilterOption = {
  disabled: boolean
  selected: boolean
  value: string
}

export const withFilterSettings =
  <T extends WithLocalStorageProps>(FiltersComponent: React.ComponentType<T>) =>
  ({ filterNames, ...props }: WithLocalStorageProps) => {
    const savedFilterName =
      props.searchContext === SearchListContext.SALES
        ? 'salesSearchFilters'
        : props.searchContext === SearchListContext.ENROLLMENT
        ? 'enrollmentsSearchFilters'
        : 'invoiceSearchFilters'
    const savedFilters: FilterOption[] | null = useMemo(
      () => JSON.parse(localStorage.getItem(savedFilterName) || 'null'),

      []
    )
    let filtersInitialState =
      savedFilters ||
      filterNames.map((filter) => ({
        disabled: filter === 'saleState',
        selected: filter !== 'salesStartDate' && filter !== 'invoiceDate',
        value: filter,
      }))

    // Add potentially missing filters to the initial state
    filterNames.forEach((x) => {
      if (!filtersInitialState.find(({ value }) => x === value)) {
        filtersInitialState.push({
          disabled: false,
          selected: false,
          value: x,
        })
      }
    })

    // Remove porentially removed filters from the initial state
    filtersInitialState = filtersInitialState.filter(({ value }) =>
      filterNames.includes(value)
    )

    const [filtersOptions, setFiltersOptions] = useState([
      ...filtersInitialState,
    ])

    const handleOptionClick = (option: string) => {
      const updatedFilters = filtersOptions.map((filter) =>
        filter.value === option
          ? {
              ...filter,
              selected: !filter.selected,
            }
          : filter
      )

      localStorage.setItem(savedFilterName, JSON.stringify(updatedFilters))
      setFiltersOptions(updatedFilters)
    }

    return (
      <FiltersComponent
        {...(props as T)}
        filters={filtersOptions}
        onFilterSettingsChange={handleOptionClick}
      />
    )
  }
