import styled, { css } from 'styled-components/macro'
import { memo } from 'react'

import { ScrollTableProps } from './types'

type Props = ScrollTableProps

export const Header = ({
  cellWidth,
  columnGroups,
  columnHeaderWidth,
}: Props) => (
  <HeaderWrapper offset={columnHeaderWidth}>
    {columnGroups.map(({ items, key, label }) => (
      <HeaderGroupWrapper key={key}>
        <HeaderGroupLabel>{label}</HeaderGroupLabel>
        <ItemsWrapper>
          {items.map(({ key, label }) => (
            <HeaderCell key={key} width={cellWidth}>
              {label}
            </HeaderCell>
          ))}
        </ItemsWrapper>
      </HeaderGroupWrapper>
    ))}
  </HeaderWrapper>
)

////////////

const HeaderWrapper = styled.div<{ offset: string }>`
  display: inline-flex;
  flex-direction: row;
  padding-top: 2rem;
  padding-left: ${({ offset }) => offset};
  position: sticky;
  top: 0;
  z-index: 3100;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.dark};
    background: ${theme.palette.white};
  `}
`

const HeaderGroupWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  &:not(:first-child)::before {
    content: ' ';
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    bottom: 0;
    width: 1px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.dark};
    `}
  }
`

const HeaderGroupLabel = styled.div`
  padding-left: 12px;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gutterSmall};
    font-size: ${theme.typography.fontSizeBig};
  `}
`

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const HeaderCell = memo(styled.div<{ width: string }>`
  position: relative;
  width: ${({ width }) => width};
  flex: 0 0 ${({ width }) => width};
  height: 30px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeSmall};
  `}

  text-align: center;
  line-height: 30px;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 50%;
    width: 1px;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.dark};
    `}
  }

  &:first-child::before {
    display: none;
  }
`)
