import { gql, useMutation } from '@apollo/client'

import type {
  AddCashPaymentMutation,
  AddCashPaymentMutationVariables,
} from '~generated-types'
import { orderFragments } from '@/modules/Order'

const ADD_CASH_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation AddCashPayment($input: PaymentCashAddInput!) {
    paymentCashAdd(input: $input) {
      payment {
        ...Payment
      }
    }
  }
`

export const useAddCashPaymentMutation = () =>
  useMutation<AddCashPaymentMutation, AddCashPaymentMutationVariables>(
    ADD_CASH_PAYMENT_MUTATION
  )
