import { Switch, useRouteMatch } from 'react-router-dom'
import { parse } from 'query-string'

import { Page, PageRoute } from '@/components/Page'
import { NotFound } from '@/components/NotFound'

import { SalesDetails } from './components'

const SalesRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PageRoute
        component={WrappedSalesDetails}
        path={`${path}/details/:salesId`}
      />

      <PageRoute component={NotFound} pageTitle="404" />
    </Switch>
  )
}

export default SalesRoutes

////////////

const WrappedSalesDetails = () => {
  const {
    params: { salesId },
    url,
  } = useRouteMatch<{ salesId: string | undefined }>()

  // We need to use window.location.search as there are some issues with
  // the search response in the useLocation() hook.
  const searchObj = parse(window.location.search || '')
  const closeUrlRaw = searchObj['close-url']
  const closeUrl = Array.isArray(closeUrlRaw) ? closeUrlRaw[0] : closeUrlRaw

  if (!salesId) {
    console.warn('SalesId missing')
    return (
      <Page pageTitle="404">
        <NotFound />
      </Page>
    )
  }

  return <SalesDetails baseRoute={url} closeHref={closeUrl} id={salesId} />
}
