import styled, { css } from 'styled-components/macro'

import {
  ReservationType,
  RoomReservation,
  useRoomReservationsContext,
} from '@/modules/FrontDesk'
import { FlexRow } from '@/components/Layout'

import { ReservationSection, RoomSection } from './Sections'
import { Separator } from './common'

type Props = {
  isLast: boolean
  reservation: RoomReservation
}

export const Row = ({ isLast, reservation: { room, reservations } }: Props) => {
  const {
    isArrivingReservation,
    isDepartingReservation,
    isPresentReservation,
  } = useRoomReservationsContext()

  const arrivingReservation = reservations.find(isArrivingReservation) ?? null
  const departingReservation = reservations.find(isDepartingReservation) ?? null
  const presentReservation = reservations.find(isPresentReservation) ?? null

  return (
    <Wrapper flex={1} isLast={isLast}>
      <RoomSection flex={2} isLast={isLast} room={room} />

      <Separator />

      <ReservationSection
        flex={4}
        reservation={presentReservation || departingReservation}
        type={
          presentReservation
            ? ReservationType.Ongoing
            : ReservationType.Departing
        }
      />

      <Separator />

      <ReservationSection
        flex={4.675}
        isLast={isLast}
        reservation={arrivingReservation}
        type={ReservationType.Arrivinng}
      />
    </Wrapper>
  )
}

////////////

const Wrapper = styled(FlexRow)<{ isLast: boolean }>`
  ${({ isLast, theme }) => css`
    margin-bottom: ${theme.spacing.gu(isLast ? 1.5 : 1)}rem;
  `};
`
