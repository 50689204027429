import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'
import { useGlobalIconsContext } from '@/theme'

import { SalesForPrintAccommodationRoomFeature } from '../../../../types'

type Props = {
  features: SalesForPrintAccommodationRoomFeature[]
}

export const Features = ({ features }: Props) => {
  const { checkIconValidity } = useGlobalIconsContext()

  return (
    <Wrapper flex={1}>
      {[...features].sort(generateCompareFn('name')).map((feature) => (
        <Fragment key={feature.id}>
          {checkIconValidity(feature.icon) ? (
            <Icon fixedWidth icon={feature.icon as IconProp} />
          ) : (
            <ShortName>{feature.shortName}</ShortName>
          )}
        </Fragment>
      ))}
    </Wrapper>
  )
}

////////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const ShortName = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`
