import REFRESH_VAT_ENABLED from './refreshVatEnabled'
import SALES_ASSIGNERS from './salesAssigners'
import SALES_DETAILS from './salesDetails'
import SALES_DETAILS_FOR_PRINT from './salesDetailsForPrint'
import SALES_ISSUES from './salesIssues'
import SALES_PAYMENT_INFO from './salesPaymentInfo'

export default Object.freeze({
  REFRESH_VAT_ENABLED,
  SALES_ASSIGNERS,
  SALES_DETAILS,
  SALES_DETAILS_FOR_PRINT,
  SALES_ISSUES,
  SALES_PAYMENT_INFO,
})

export type {
  RefreshVatEnabledQuery as RefreshVatEnabledPayload,
  RefreshVatEnabledQueryVariables as RefreshVatEnabledVariables,
  SalesAssignersQuery as SalesAssignersPayload,
  SalesDetailsQuery as SalesDetailsPayload,
  SalesDetailsQueryVariables as SalesDetailsVariables,
  SalesDetailsForPrintQuery as SalesDetailsForPrintPayload,
  SalesDetailsForPrintQueryVariables as SalesDetailsForPrintVariables,
  SalesIssuesQuery as SalesIssuesPayload,
  SalesIssuesQueryVariables as SalesIssuesVariables,
  SalesPaymentInfoQuery as SalesPaymentInfoPayload,
  SalesPaymentInfoQueryVariables as SalesPaymentInfoVariables,
} from '~generated-types'
