import styled, { css } from 'styled-components/macro'

import { InlineModal } from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { DataFieldTextarea } from './DataFieldTextarea'

type Props = {
  close: () => void
  handleSubmit: (newValue: string | null) => Promise<any>
  value: string | null
}

export const DataFieldTextareaModal = ({
  close,
  handleSubmit,
  value,
}: Props) => {
  const theme = useTheme()

  return (
    <InlineModal style={{ minWidth: 'none ' }}>
      <Wrapper>
        <DataFieldTextarea handleSubmit={handleSubmit} value={value} />
        <InnocuousButton
          compact
          onClick={() => close()}
          style={{ marginTop: `${theme.spacing.gu(1)}rem` }}
        >
          <T>common:action.close</T>
        </InnocuousButton>
      </Wrapper>
    </InlineModal>
  )
}

////////////

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gu(1)}rem;
  `}
`
