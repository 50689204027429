import { useEffect, useState } from 'react'
import { HTML5_FMT } from 'moment'
import { v4 as uuidv4 } from 'uuid'

import type {
  CalendarListingPreset,
  CalendarNestedResourceElastic,
  CalendarResourceElastic,
} from '@/modules/Registry'
import { CalendarResourcesService, useUserGroups } from '@/modules/Registry'
import { ById } from '@/common/types'
import type { CategorySelection } from '@/modules/Reservations'
import { generateCompareFn } from '@/utils/arrays'
import { useRouteDateInterval } from '@/utils/hooks'

import { CalendarResourceListing } from './CalendarResourceListing'
import fetchTaskTargetReservationResourceIds from './fetchTaskTargetReservationResourceIds'

import useCategorySelections from './useCategorySelections'

export type Resource = CalendarNestedResourceElastic | CalendarResourceElastic

export type SortOption = 'main_resource' | 'resource' | 'time'
export type ShowReservationGroups = 'hidden' | 'show_once' | 'repeat'
export type TaskDetailsOption = 'minimal' | 'extended'

export const CalendarResourceListingContainer = () => {
  const { from, range, setInterval, to } = useRouteDateInterval()

  const { userGroups } = useUserGroups()

  const [activePreset, setActivePreset] = useState<string | null>(null)
  const [fetchingPresets, setFetchingPresets] = useState<boolean>(true)
  const [presetsById, setPresetsById] = useState<
    Record<string, CalendarListingPreset>
  >({})

  const [allResourcesById, setAllResourcesById] = useState<
    ById<CalendarResourceElastic>
  >({})
  const [fetchingResources, setFetchingResources] = useState<boolean>(true)

  const [selectedResourceIds, setSelectedResourceIds] = useState<string[]>([])
  const [showExtendedTasks, setShowExtendedTasks] = useState<boolean>(true)
  const [showReservationGroups, setShowReservationGroups] =
    useState<ShowReservationGroups>('hidden')
  const [sortBy, setSortBy] = useState<SortOption>('time')
  const [taskTargetResourceIds, setTaskTargetResourceIds] = useState<string[]>(
    []
  )
  const [taskTargetSelection, setTaskTargetSelection] = useState<string[]>([])
  const [viewAssigneeSelection, setViewAssigneeSelection] = useState<string[]>(
    []
  )
  const [showAllTasks, setShowAllTasks] = useState<boolean>(true)

  const {
    add: addCategorySelection,
    init: initialiseCategorySelection,
    remove: removeCategorySelection,
    selection: selectedCategories,
    update: updateCategorySelection,
  } = useCategorySelections({ throttleDuration: 1000 })

  const taskTargetOptions = userGroups
    ? [...userGroups.map(({ id, name }) => ({ id, label: name }))]
    : []

  const handleUsePreset = (id: string) => {
    const preset = presetsById[id]

    if (!preset) {
      console.warn('Failed to find preset for target', id)
      return
    }

    initialiseCategorySelection(
      preset.searchGroups.map((x) => ({ ...x, resourceIds: [] }))
    )
    setSelectedResourceIds(preset.resourceIds)
    setShowExtendedTasks(preset.taskDisplay === 'extended')
    setShowReservationGroups(preset.groupsDisplay)
    setSortBy(preset.sortOrder)
    setTaskTargetSelection(preset.userGroupId)
    setViewAssigneeSelection(preset.viewAssigneeSelection)
    setShowAllTasks(preset.showAllTasks)

    setActivePreset(id)
  }

  const handleAddCategorySelection = (): CategorySelection => {
    const selection = getEmptyCategorySelection()

    addCategorySelection(selection)

    return selection
  }

  const addResourceSelection = (resourceId: string) =>
    setSelectedResourceIds((current) =>
      current.includes(resourceId) ? current : [...current, resourceId]
    )

  const removeResourceSelection = (resourceId: string) =>
    setSelectedResourceIds((current) =>
      current.filter((id) => id !== resourceId)
    )

  // Resolve listing presets
  useEffect(() => {
    CalendarResourcesService.fetchListingPresets()
      .then((presets) => {
        setPresetsById(
          presets.reduce(
            (acc, val) => ({
              ...acc,
              [val.id]: val,
            }),
            {}
          )
        )
      })
      .catch((err) => {
        console.warn('Failed to fetch presets', err)
        setPresetsById({})
        setActivePreset(null)
      })
      .finally(() => setFetchingPresets(false))
  }, [])

  // Resolve details for all resources
  useEffect(() => {
    CalendarResourcesService.findAll()
      .then((resources) =>
        setAllResourcesById(
          resources.reduce((acc, val) => ({ ...acc, [val.id]: val }), {})
        )
      )
      .catch((err) => console.warn('Failed to retrieve all resources', err))
      .finally(() => setFetchingResources(false))
  }, [])

  const fromStr = from.format(HTML5_FMT.DATE)
  const toStr = to.format(HTML5_FMT.DATE)

  useEffect(() => {
    if (userGroups.length) {
      setViewAssigneeSelection(userGroups.map(({ id }) => id))
    }
  }, [userGroups.length])

  useEffect(() => {
    const fetchResourceIds = async () => {
      if (!taskTargetSelection) {
        setTaskTargetResourceIds([])
      } else {
        const resourceIds = await fetchTaskTargetReservationResourceIds({
          assigneeIds: taskTargetSelection,
          from: fromStr,
          to: toStr,
        })

        setTaskTargetResourceIds(resourceIds)
      }
    }

    fetchResourceIds()
  }, [fromStr, taskTargetSelection, toStr])

  const allResources = Object.keys(allResourcesById).map(
    (id) => allResourcesById[id]
  )

  const selectedResources = selectedResourceIds
    .map((id) => allResourcesById[`${id}`])
    .filter(Boolean)
    .sort(generateCompareFn('name'))

  const selectedResourcesById = selectedResources.reduce(
    (acc, val) => ({ ...acc, [val.id]: val }),
    {}
  )

  const selectedCategoryResourcesById = selectedCategories
    .filter(({ active }) => active)
    .flatMap(({ resourceIds }) => resourceIds)
    .map((id) => allResourcesById[id])
    .filter(Boolean)
    .reduce((acc, val) => ({ ...acc, [val.id]: val }), {})

  const targetResourcesById = {
    ...selectedCategoryResourcesById,
    ...selectedResourcesById,
  }

  const targetResourceIds = Object.keys(targetResourcesById)
    .map((id) => targetResourcesById[id as keyof typeof targetResourcesById])
    .reduce((acc: string[], resource: CalendarResourceElastic) => {
      acc.push(resource.id)
      resource.nestedResources?.map(({ id }) => acc.push(id))

      return acc
    }, [])

  const onlyTaskTargetResourceIds = taskTargetResourceIds.filter(
    (x) => !targetResourceIds.includes(x)
  )

  const allVisibleResourceIds = [
    ...targetResourceIds,
    ...onlyTaskTargetResourceIds,
  ]

  const taskTargetResources = [
    ...new Set(
      onlyTaskTargetResourceIds.map(
        (id) =>
          Object.values(allResourcesById).find((val) => {
            if (val.id === id) {
              return true
            }
            if (val.nestedResources?.length) {
              return !!val.nestedResources.find((r) => r.id === id)
            }
            return false
          }) as CalendarResourceElastic
      )
    ),
  ]
    .filter(Boolean)
    .sort(generateCompareFn('name'))

  return (
    <>
      <CalendarResourceListing
        activePreset={
          activePreset !== null ? presetsById[activePreset] || null : null
        }
        addCategorySelection={handleAddCategorySelection}
        addResourceSelection={addResourceSelection}
        allResources={allResources}
        clearActivePreset={() => {
          setActivePreset(null)
        }}
        dateRange={range}
        interval={{ from, to }}
        loadingPresets={fetchingPresets}
        loadingResources={fetchingResources}
        presets={Object.values(presetsById)}
        removeCategorySelection={removeCategorySelection}
        removeResourceSelection={removeResourceSelection}
        resourceIds={allVisibleResourceIds}
        selectedCategories={selectedCategories}
        selectedResources={selectedResources}
        selectPreset={handleUsePreset}
        setInterval={({ from, to }) => setInterval(from, to)}
        setShowAllTasks={setShowAllTasks}
        setShowExtendedTasks={(target) => setShowExtendedTasks(target)}
        setSortBy={setSortBy}
        setTaskTarget={(target) => {
          setTaskTargetSelection(target)
        }}
        setViewAssigneeSelection={setViewAssigneeSelection}
        showAllTasks={showAllTasks}
        showExtendedTasks={showExtendedTasks}
        showReservationGroups={showReservationGroups}
        sortBy={sortBy}
        taskTargetResources={taskTargetResources}
        setShowReservationGroups={setShowReservationGroups}
        taskTargetOptions={taskTargetOptions}
        taskTargetSelection={taskTargetSelection}
        updateCategorySelection={updateCategorySelection}
        viewAssigneeSelection={viewAssigneeSelection}
      />
    </>
  )
}

////////////

const getEmptyCategorySelection = () => ({
  active: true,
  categoryPaths: [],
  id: uuidv4(),
  isRestrictedMode: false,
  label: null,
  resourceIds: [],
})
