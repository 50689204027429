import { ChangeEvent, useState } from 'react'
import moment, { Moment } from 'moment'
import ReactLoading from 'react-loading'
import styled from 'styled-components/macro'

import { DatePickerControl, TimeInputControl } from '@/components/TimeControls'
import {
  InlineModal,
  InlineModalContent,
  InlineModalDescription,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  DataTableInput as Input,
  DataTableSelect as Select,
} from '@/components/DataTable'
import { Restaurant, useCreateMealMutation } from '@/modules/Meals'
import { InnocuousButton } from '@/components/ExtraButtons'
import { LocationType } from '~generated-types'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import { Quantity } from '../types'
import { useMealsContext } from '../MealsState'

type NewMeal = {
  date: string
  duration: number | null
  mealId: string
  quantities: Quantity[]
  restaurantId: string
  salesId: string
  startTime: string | null
}

type Props = {
  defaultDate: string
  isExistingDate?: boolean
  onClose: () => void
}

export const NewMealModal = ({
  defaultDate,
  isExistingDate,
  onClose,
}: Props) => {
  const { palette, spacing } = useTheme()
  const {
    data: { id: salesId },
  } = useSalesDetailsContext()
  const { defaultQuantities, mealTypes, restaurants } = useMealsContext()

  const [newMeal, setNewMeal] = useState<NewMeal>({
    date: defaultDate,
    duration: 0,
    mealId: '',
    quantities: defaultQuantities,
    restaurantId: '',
    salesId,
    startTime: '',
  })

  const [createMeal, { loading: processing }] = useCreateMealMutation()

  const handleMealChange = (mealId: string) => {
    const currentMeal = mealTypes.find((meal) => meal.id === mealId)

    if (currentMeal) {
      setNewMeal({
        ...newMeal,
        duration: currentMeal.defaultDuration,
        mealId,
        restaurantId: currentMeal.defaultRestaurant?.id || '',
        startTime: currentMeal.defaultStartTime,
      })
    }
  }

  const handleQuantityChange = (key: string, quantity: string) => {
    const updatedQuantities = newMeal.quantities.map((q: Quantity) =>
      q.key === key ? { ...q, quantity: Number(quantity) } : q
    )

    setNewMeal({
      ...newMeal,
      quantities: updatedQuantities,
    })
  }

  const handleCreateMeal = () => {
    const quantities = newMeal.quantities.map((q: Quantity) => ({
      ageCategoryKey: q.key,
      quantity: Number(q.quantity),
    }))

    const start = `${newMeal.date}T${newMeal.startTime}`

    createMeal({
      variables: {
        input: {
          duration: newMeal.duration,
          location: {
            id: newMeal.restaurantId,
            type: LocationType.Restaurant,
          },
          mealId: newMeal.mealId,
          quantities,
          salesId: newMeal.salesId,
          start,
        },
      },
    })
      .then(onClose)
      .catch(() => undefined)
  }

  const renderActions = () => (
    <InlineModalFooter justifyContent="center">
      <InnocuousButton
        disabled={processing || !newMeal.mealId || !newMeal.startTime}
        onClick={handleCreateMeal}
      >
        <PrimaryColor>
          {processing ? (
            <ReactLoading
              color={palette.primary.light}
              type={'spin'}
              height={24}
              width={24}
            />
          ) : (
            <T>SalesDetails:meals.action.add</T>
          )}
        </PrimaryColor>
      </InnocuousButton>
    </InlineModalFooter>
  )

  const renderContent = () => (
    <>
      {!!mealTypes.length && (
        <>
          <InlineModalIconSection icon={['far', 'calendar']}>
            <Title>
              <T>SalesDetails:meals.title.date</T>
            </Title>
          </InlineModalIconSection>
          <InlineModalIconSection>
            {isExistingDate ? (
              <div style={{ height: `${spacing.gu(3.5)}rem` }}>
                {moment(newMeal.date).format('Do MMMM YYYY')}
              </div>
            ) : (
              <DatePickerControl
                render={({ date, ref, toggleModal }: any) => (
                  <div ref={ref}>
                    <Input
                      onClick={toggleModal}
                      readOnly
                      showBorder
                      type="text"
                      value={date ? date.format('L') : '—'}
                    />
                  </div>
                )}
                setValue={(date) =>
                  setNewMeal({
                    ...newMeal,
                    date: moment(date).format('YYYY-MM-DD'),
                  })
                }
                value={newMeal.date ? moment(newMeal.date) : null}
              />
            )}
          </InlineModalIconSection>
          <InlineModalIconSection icon="utensils">
            <Title>
              <T>SalesDetails:meals.title.meal</T>
            </Title>
          </InlineModalIconSection>
          <InlineModalIconSection>
            <Select
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                handleMealChange(e.target.value)
              }
              showBorder
              value={newMeal.mealId}
            >
              {!newMeal.mealId && <option>—</option>}
              {mealTypes.map((meal) => (
                <option key={meal.id} value={meal.id}>
                  {meal.name}
                </option>
              ))}
            </Select>
          </InlineModalIconSection>
          <InlineModalIconSection icon="location-dot">
            <Title>
              <T>SalesDetails:meals.title.restaurant</T>
            </Title>
          </InlineModalIconSection>
          <InlineModalIconSection>
            <Select
              disabled={!newMeal.restaurantId}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setNewMeal({ ...newMeal, restaurantId: e.target.value })
              }
              showBorder
              value={newMeal.restaurantId}
            >
              {!newMeal.restaurantId && <option>—</option>}
              {restaurants.map((restaurant: Restaurant) => (
                <option key={restaurant.id} value={restaurant.id}>
                  {restaurant.name}
                </option>
              ))}
            </Select>
          </InlineModalIconSection>
          <div style={{ display: 'flex' }}>
            <div>
              <InlineModalIconSection icon={['far', 'clock']}>
                <Title>
                  <T>SalesDetails:meals.title.time</T>
                </Title>
              </InlineModalIconSection>
              <InlineModalIconSection>
                <TimeInput
                  disabled={!newMeal.mealId}
                  setValue={(hour, minute) => {
                    setNewMeal({
                      ...newMeal,
                      startTime: moment()
                        .set({ hour, minute, second: 0 })
                        .format('HH:mm:ss'),
                    })
                  }}
                  value={
                    newMeal.startTime
                      ? moment(newMeal.date)
                          .hour(parseInt(newMeal.startTime.split(':')[0], 10))
                          .minute(parseInt(newMeal.startTime.split(':')[1], 10))
                      : null
                  }
                />
              </InlineModalIconSection>
            </div>
            <div>
              <InlineModalIconSection>
                <Title>
                  <T>SalesDetails:meals.title.duration</T>
                </Title>
              </InlineModalIconSection>
              <InlineModalIconSection>
                <div style={{ alignItems: 'center', display: 'flex' }}>
                  <Input
                    disabled={!newMeal.mealId}
                    onChange={(e) =>
                      setNewMeal({
                        ...newMeal,
                        duration: Number(e.target.value),
                      })
                    }
                    onFocus={(e) => e.currentTarget.select()}
                    showBorder
                    type="number"
                    value={`${newMeal.duration}`}
                  />
                  <span style={{ marginLeft: spacing.gutterSmall }}>min</span>
                </div>
              </InlineModalIconSection>
            </div>
          </div>
          <InlineModalIconSection icon="user-group">
            <Title>
              <T>SalesDetails:meals.title.persons</T>
            </Title>
          </InlineModalIconSection>
          <InlineModalIconSection>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {newMeal.quantities.map((quantity) => (
                <div
                  key={quantity.key}
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  <Input
                    disabled={!newMeal.mealId}
                    onChange={(e) =>
                      handleQuantityChange(quantity.key, e.target.value)
                    }
                    onFocus={(e) => e.currentTarget.select()}
                    placeholder={0}
                    showBorder
                    style={{ width: `${spacing.gu(8)}rem` }}
                    type="number"
                    value={`${quantity.quantity}`}
                  />
                  <span style={{ marginLeft: spacing.gutterSmall }}>
                    {quantity.shortName}
                  </span>
                </div>
              ))}
            </div>
          </InlineModalIconSection>
        </>
      )}
    </>
  )

  return (
    <InlineModal style={{ maxWidth: 440, minWidth: 440 }}>
      <InlineModalHeader
        onClose={onClose}
        title={<T>SalesDetails:meals.title.newMeal</T>}
      />

      <InlineModalLine />

      <InlineModalContent>{renderContent()}</InlineModalContent>

      {renderActions()}
    </InlineModal>
  )
}

////////////

const Title = styled(InlineModalDescription)`
  font-weight: 600;
`

type TimeInputProps = {
  disabled?: boolean
  setValue: (hours: number, minutes: number) => void
  value: Moment | null | undefined
}

const TimeInput = ({ disabled, setValue, value }: TimeInputProps) => {
  return (
    // @ts-ignore
    <TimeInputControl
      setValue={(value) =>
        value ? setValue(value.hour, value.minute) : setValue(0, 0)
      }
      value={value && { hour: value.hour(), minute: value.minute() }}
    >
      {({ handleSubmit, inputValue, setInputValue, value }) => (
        <Input
          disabled={disabled || !value}
          flex={0}
          onBlur={handleSubmit}
          onChange={(e) => setInputValue(e.target.value)}
          onFocus={(e) => e.currentTarget.select()}
          placeholder="—"
          showBorder
          style={{ width: 56 }}
          value={inputValue}
        />
      )}
    </TimeInputControl>
  )
}
