import { CustomerLabel } from '@/modules/Registry/Customer'
import type { ElasticCustomerLabel } from '@/modules/Registry'
import { ListItemSection as Wrapper } from '@/components/List'

export interface LabelsProps {
  data: ElasticCustomerLabel[]
}

export const Labels = ({ data }: LabelsProps) => (
  <Wrapper>
    {data.map((label) => (
      <CustomerLabel key={`customer-label-${label.id}`} {...label} />
    ))}
  </Wrapper>
)

export default Labels
