import styled, { css } from 'styled-components/macro'

import { PrintSize } from '../types'

interface ListingTableProps {
  collapsePaddingRight?: boolean
  hideFromPrint?: boolean
  printSize?: PrintSize
}

export const ListingTable = styled.table<ListingTableProps>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  page-break-inside: auto;

  ${({ printSize, theme }) => {
    const spacing =
      printSize === PrintSize.Compact
        ? theme.spacing.gu(3)
        : printSize === PrintSize.Large
        ? theme.spacing.gu(5)
        : theme.spacing.gu(4)

    return css`
      margin-bottom: ${spacing}rem;
    `
  }}

  ${({ hideFromPrint }) =>
    hideFromPrint
      ? css`
          @media print {
            display: none;
          }
        `
      : ''}

  & > thead {
    position: sticky;
    top: 0;

    & > tr {
      position: relative;

      ${({ theme }) => css`
        background-color: ${theme.palette.smoke.lighter};
      `}
    }

    @media print {
      position: relative;
      top: 0;

      & > tr {
        -webkit-print-color-adjust: exact;

        ${({ theme }) => css`
          background-color: ${theme.palette.smoke.light};
        `}
      }
    }
  }

  & > thead.sticky {
  }

  & > tbody td {
    vertical-align: baseline;
  }

  & > tbody > tr,
  & > thead > tr {
    page-break-inside: avoid;
  }

  & > tbody > tr > td,
  & > thead > tr > th {
    border-style: solid;
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    vertical-align: top;

    ${({ printSize, theme }) => {
      const fontSize =
        printSize === PrintSize.Compact
          ? theme.typography.fontSizeSmaller
          : printSize === PrintSize.Large
          ? theme.typography.fontSizeBase
          : theme.typography.fontSizeSmall
      const spacing =
        printSize === PrintSize.Compact
          ? theme.spacing.gu(0.75)
          : printSize === PrintSize.Large
          ? theme.spacing.gu(1.5)
          : theme.spacing.gu(1)

      const verticalSpacingCoeficcient = printSize === PrintSize.Large ? 1.5 : 1

      return css`
        border-color: ${theme.palette.smoke.dark};
        font-size: ${fontSize};
        padding: ${spacing * verticalSpacingCoeficcient}rem ${spacing * 2}rem;
      `
    }}

    @media print {
      border-right-width: 1px;

      ${({ printSize, theme }) => {
        const fontSize =
          printSize === PrintSize.Compact
            ? '7pt'
            : printSize === PrintSize.Large
            ? '9pt'
            : '8pt'
        const spacing =
          printSize === PrintSize.Compact
            ? theme.spacing.gu(0.5)
            : printSize === PrintSize.Large
            ? theme.spacing.gu(1)
            : theme.spacing.gu(0.75)

        const verticalSpacingCoeficcient = printSize === PrintSize.Large ? 2 : 1

        return css`
          border-color: ${theme.palette.text.main};
          font-size: ${fontSize};
          padding: ${spacing * verticalSpacingCoeficcient}rem ${spacing * 2}rem;
        `
      }}
    }
  }

  & > thead > tr > th {
    font-weight: 400;
    text-align: left;

    ${({ theme }) => css`
      color: ${theme.palette.text.light};
      padding-top: ${theme.spacing.gutter};
    `}

    @media print {
      font-weight: 500;

      ${({ theme }) => css`
        color: ${theme.palette.text.main};
      `}
    }
  }

  & > tbody > tr > td:first-child,
  & > thead > tr > th:first-child {
    border-left-width: 1px;

    ${({ theme }) => css`
      padding-left: ${theme.spacing.gu(5)}rem;
    `}

    @media print {
      ${({ theme }) => css`
        padding-left: ${theme.spacing.gu(2)}rem;
      `}
    }
  }

  & > tbody > tr > td:last-child,
  & > thead > tr > th:last-child {
    border-right-width: 1px;

    ${({ collapsePaddingRight, theme }) =>
      collapsePaddingRight
        ? css`
            padding-right: ${theme.spacing.gu(2)}rem;
          `
        : css`
            padding-right: ${theme.spacing.gu(5)}rem;
          `}

    @media print {
      ${({ theme }) => css`
        padding-right: ${theme.spacing.gu(2)}rem;
      `}
    }
  }

  & > thead > tr:first-child > th:first-child {
    border-top-left-radius: 8px;
  }
  & > thead > tr:first-child > th:last-child {
    border-top-right-radius: 8px;
  }

  & > tbody > tr:last-child > td:first-child {
    border-bottom-left-radius: 8px;
  }
  & > tbody tr:last-child > td:last-child {
    border-bottom-right-radius: 8px;
  }

  & tr.lastGroupRow > td:first-child {
    border-bottom-left-radius: 8px;
  }

  & > tbody > tr.subrow > td:first-child {
    border-bottom-left-radius: 0;
  }

  & > thead > tr:first-child > th {
    border-top-width: 1px;
  }

  & > tbody > tr.extra-row td {
    border-left-width: 0;
    border-right-width: 0;
    border-radius: 0 !important;

    ${({ printSize, theme }) => {
      const spacing =
        printSize === PrintSize.Compact
          ? theme.spacing.gu(1.5)
          : printSize === PrintSize.Large
          ? theme.spacing.gu(3)
          : theme.spacing.gu(2)

      return css`
        padding-right: ${spacing}rem;
        padding-left: ${spacing}rem;
      `
    }}

    @media print {
      border-right-width: 1px;

      ${({ theme }) => css`
        padding-right: ${theme.spacing.gu(1.5)}rem;
        padding-left: ${theme.spacing.gu(1.5)}rem;
      `}
    }
  }

  & > tbody > tr.extra-row td:last-child {
    ${({ collapsePaddingRight, theme }) =>
      collapsePaddingRight
        ? css`
            padding-right: ${theme.spacing.gu(2)}rem;
          `
        : css`
            padding-right: ${theme.spacing.gu(5)}rem;
          `}

    @media print {
      ${({ theme }) => css`
        padding-right: ${theme.spacing.gu(2)}rem;
      `}
    }
  }

  & td.horizontal-spacer,
  & th.horizontal-spacer {
    ${({ theme }) => css`
      background: ${theme.palette.white};
      border-bottom: solid 1px ${theme.palette.white};
      border-top: solid 1px ${theme.palette.white};
    `}

    border-right-width: 1px;
    border-left-width: 1px;
  }

  & td.show-left-border,
  & th.show-left-border {
    border-left-width: 1px;

    @media print {
      border-left-width: 0;
    }
  }

  & td.groupheader {
    ${({ printSize, theme }) => {
      const fontSize =
        printSize === PrintSize.Compact
          ? theme.typography.fontSizeBase
          : printSize === PrintSize.Large
          ? theme.typography.fontSizeBase2
          : theme.typography.fontSizeBase2

      return css`
        background-color: ${theme.palette.smoke.main};
        color: ${theme.palette.text.light};
        font-size: ${fontSize};
      `
    }}

    font-variant-caps: all-small-caps;
    font-weight: 600;
    letter-spacing: 0.3rem;

    padding-left: 0;
    padding-right: 0;

    ${({ theme }) => css`
      width: ${theme.spacing.gu(5)}rem;
    `}

    vertical-align: top;
    text-align: center;

    & > span {
      -ms-writing-mode: tb-rl;
      -webkit-writing-mode: vertical-rl;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      white-space: nowrap;
    }

    @media print {
      -webkit-print-color-adjust: exact;
      letter-spacing: 0.15rem;

      ${({ theme }) => css`
        background-color: ${theme.palette.smoke.light};
        font-size: ${theme.typography.fontSizeSmaller};
        width: ${theme.spacing.gu(2)}rem;
      `}
    }
  }

  & tr.subrow > td:first-child {
    border-left-width: 0;

    ${({ theme }) => css`
      padding-left: ${theme.spacing.gutter};
    `}

    @media print {
      ${({ theme }) => css`
        padding-left: ${theme.spacing.gu(1)}rem;
      `}
    }
  }

  & tr.dashed-bottom-border > td:not(.groupheader) {
    border-bottom-style: dashed;

    @media print {
      ${({ theme }) => css`
        border-bottom-color: ${theme.palette.text.lighter};
      `}
    }
  }

  & tr.summaryrow > td {
    font-weight: 600;

    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.main};
      color: ${theme.palette.text.light};
    `}

    @media print {
      -webkit-print-color-adjust: exact;

      ${({ printSize, theme }) => {
        const fontSize =
          printSize === PrintSize.Compact
            ? '7pt'
            : printSize === PrintSize.Large
            ? '9pt'
            : '8pt'

        return css`
          background-color: ${theme.palette.smoke.light};
          color: ${theme.palette.text.main};
          font-size: ${fontSize};
        `
      }}
    }
  }

  & thead.hide-from-print,
  & tr.hide-from-print {
    @media print {
      display: none;
    }
  }
`
