import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CheckInType, CheckOutType } from '~generated-types'
import { formatDateRangeWithoutRepetition } from '@/utils/time'
import { useTheme } from '@/theme'

import { CheckIn, CheckOut } from '../ArrivalsListing/useArrivalsForDate'

type Props = {
  checkIn: CheckIn
  checkOut: CheckOut
}

export const CheckInOutTimes = ({ checkIn, checkOut }: Props) => {
  const { palette, spacing } = useTheme()

  return (
    <>
      {checkIn.type === CheckInType.Early && (
        <FontAwesomeIcon
          color={palette.primary.dark}
          icon="sun"
          size="xs"
          style={{ marginRight: `${spacing.gu(0.5)}rem` }}
        />
      )}
      {formatDateRangeWithoutRepetition(checkIn.date, checkOut.date, 'short')}
      {checkOut.type === CheckOutType.Late && (
        <FontAwesomeIcon
          color={palette.primary.dark}
          icon="moon"
          size="sm"
          style={{ marginLeft: `${spacing.gu(0.5)}rem` }}
        />
      )}
    </>
  )
}
