import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { SaleAccommodationQuery } from '~generated-types'
import { useRoomReservationSetBedQuantity } from '@/modules/Accommodation/SalesReservationManager/mutations'
import { useTheme } from '@/theme'

import { NumberInput } from './NumberInput'

type Props = {
  room: SaleAccommodationQuery['suitableRooms'][0]['roomReservation']
}

export const RoomCapacity = ({ room }: Props) => {
  const { spacing } = useTheme()

  const [setBedQuantity] = useRoomReservationSetBedQuantity()

  const handleSetBedQUantity = (beds: number, extraBeds: number) =>
    setBedQuantity({
      variables: {
        input: {
          beds,
          extraBeds,
          id: room.id,
        },
      },
    })

  return (
    <FlexRow
      justifyContent={'flex-start'}
      style={{ marginRight: `${spacing.gu(1)}rem`, minWidth: '140px' }}
    >
      <FlexRow
        alignItems={'center'}
        style={{ marginRight: `${spacing.gu(1.5)}rem` }}
      >
        <NumberInput
          value={room.request.beds}
          maxValue={room.request.room.beds}
          icon={'bed'}
          onSubmit={(bedsValue) =>
            handleSetBedQUantity(bedsValue, room.request.room.extraBeds)
          }
        />
        <FontWeight
          semiBold
          style={{ whiteSpace: 'nowrap' }}
        >{` / ${room.request.room.beds}`}</FontWeight>
      </FlexRow>

      <FlexRow
        alignItems={'center'}
        style={{ marginRight: `${spacing.gu(1.5)}rem` }}
      >
        <NumberInput
          value={room.request.extraBeds}
          maxValue={room.request.room.extraBeds}
          icon={'couch'}
          onSubmit={(extraBedsValue) =>
            handleSetBedQUantity(room.request.beds, extraBedsValue)
          }
        />
        <FontWeight
          semiBold
          style={{ whiteSpace: 'nowrap' }}
        >{` / ${room.request.room.extraBeds}`}</FontWeight>
      </FlexRow>
    </FlexRow>
  )
}

export default RoomCapacity
