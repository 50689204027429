import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { generateCompareFn } from '@/utils/arrays'
import { ResourceReservationCustomerVisibility } from '~generated-types'
import { T } from '@/modules/Language'
import { useLanguageContext } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { formatResourceName, formatTime, getDimensionValues } from '../utils'
import {
  SalesPrintConfigKey as Key,
  SalesForPrintResourceReservationWithTasks as ReservationWithTasks,
} from '../../../../../types'
import { checkSalesPrintConfigVisibility } from '../../../../../utils'
import { TaskRow } from './TaskRow'

type Props = {
  data: ReservationWithTasks
}

export const ReservationRow = ({ data }: Props) => {
  const {
    customerVisibility,
    description,
    dimensions: dimensionsInput,
    end,
    internalNote,
    purchaseProducts,
    resource,
    resourceQuantity,
    start,
    tasks,
  } = data

  const { language } = useLanguageContext()
  const { salesPrintConfig: config, salesPrintShowPrice: showPrice } =
    useSalesDetailsContext()

  const checkVisibility = (key: string) =>
    checkSalesPrintConfigVisibility(
      config,
      `${Key.Program}.${Key.Reservations}.${key}`
    )

  const endTime = formatTime(end)
  const startTime = formatTime(start)
  const time = startTime === endTime ? startTime : `${startTime} – ${endTime}`

  const dimensionValues = getDimensionValues(dimensionsInput)
  const dimensions = Array.from(new Set(dimensionValues)).join(', ')
  const resourceName = formatResourceName(resource, resourceQuantity, language)
  const product = purchaseProducts[0] ?? null
  const showDescription = checkVisibility(Key.Subject) && !!description
  const showNotes = checkVisibility(Key.Notes) && !!internalNote
  const showProduct = checkVisibility(Key.Product) && !!product
  const showResourceName =
    checkVisibility(Key.Resource) &&
    customerVisibility === ResourceReservationCustomerVisibility.Visible
  const showDetails = showResourceName || !!dimensions || !!showDescription

  return (
    <>
      <Wrapper>
        <Time>{time}</Time>

        <DetailsWrapper>
          <FlexColumn alignItems="flex-start">
            <span>
              {showResourceName && (
                <span style={{ fontWeight: 500 }}>{resourceName}</span>
              )}

              {showResourceName && dimensions && ', '}

              {dimensions}

              {(showResourceName || dimensions) && showDescription && ' – '}

              {showDescription && description}
            </span>

            {(showNotes || showProduct) && (
              <Details shiftTop={!showDetails}>
                {showNotes && (
                  <DetailsRow>
                    <Subtitle>
                      <T>SalesDetails:PrintSale.label.NOTES</T>
                    </Subtitle>

                    <span>{internalNote}</span>
                  </DetailsRow>
                )}

                {showProduct && (
                  <DetailsRow>
                    <Subtitle>
                      <T>SalesDetails:PrintSale.label.PRODUCT</T>
                    </Subtitle>

                    <FlexRow flex={1} justifyContent="space-between">
                      <span>{product.product.name}</span>

                      {showPrice && (
                        <span style={{ fontWeight: 500 }}>
                          {formatCurrency(product.totalPrice.amountVatIncluded)}{' '}
                          €
                        </span>
                      )}
                    </FlexRow>
                  </DetailsRow>
                )}
              </Details>
            )}
          </FlexColumn>
        </DetailsWrapper>
      </Wrapper>

      {tasks
        .map((task) => ({
          ...task,
          sortDue: task.dueDate
            ? moment(`${task.dueDate}T${task.dueTime || '00:00:00'}`)
            : null,
        }))
        .sort(generateCompareFn('sortDue'))
        .map((task) => {
          const { sortDue, ...pureTask } = task

          return (
            <TaskRow
              isNested
              key={task.id}
              data={pureTask}
              reservationStart={start}
            />
          )
        })}
    </>
  )
}

///////

const Details = styled(FlexColumn)<{ shiftTop: boolean }>`
  font-size: 1rem;
  width: 100%;

  ${({ theme, shiftTop }) =>
    shiftTop &&
    css`
      margin-top: -${theme.spacing.gu(0.5)}rem;
    `}
`

const DetailsRow = styled(FlexRow)`
  width: 100%;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(0.5)}rem;
  `}
`

const DetailsWrapper = styled.td`
  white-space: pre-wrap;

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Subtitle = styled.span`
  font-weight: 500;
  text-align: end;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(10)}rem;
    padding-right: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Time = styled.td`
  text-align: end;
  vertical-align: top;

  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(1)}rem;
    padding-right: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(22)}rem;
  `}
`

const Wrapper = styled.tr`
  page-break-inside: avoid;
`
