import { gql } from '@apollo/client'

import { EventSalesFragment } from './EventEnrollments.fragments'

export default Object.freeze({
  ENROLLMENTS: gql`
    ${EventSalesFragment}

    query EventEnrollments($id: ID!, $input: SalesEnrollmentsInput) {
      sales(id: $id) {
        id
        type
        enrollments(input: $input) {
          ...EventSales
        }
      }
    }
  `,
})
