import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { CSSProperties } from 'react'

import { FontColor, FontWeight } from '@/components/Typography'
import { InlineModal, InlineModalContent } from '@/components/InlineModal'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { DatePicker } from '@/components/TimeControls'
import { FlexColumn } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'
import { ToggleEditableValue } from '@/components/ToggleHelpers'

type Props = {
  hideMinutes?: boolean
  newDate: string
  startDate: string
  sectionTitle: React.ReactElement
  handleChangeDate: (date?: Moment | null) => void
  handleSetTimes: (newTime: string) => void
}

export const NewDateSelector = ({
  hideMinutes,
  newDate,
  startDate,
  sectionTitle,
  handleChangeDate,
  handleSetTimes,
}: Props) => {
  const formatDate = (date: string) =>
    moment(date).format(hideMinutes ? 'dd. DD.MM.YY' : 'dd. DD.MM.YY, HH:mm')

  const dateFormatted = newDate
    ? formatDate(newDate)
    : startDate
    ? formatDate(startDate)
    : undefined

  const onMenuOpen = () => {
    setTimeout(() => {
      const selectedEl = document.getElementsByClassName(
        'time-dropdown__option--is-selected'
      )[0]
      if (selectedEl) {
        selectedEl.scrollIntoView({ block: 'center' })
      }
    }, 0)
  }

  return (
    <DateValue>
      <ToggleEditableValue
        mode="modal"
        hideEditIcon
        placeholder={
          <FlexColumn noPadding alignItems="flex-start">
            {sectionTitle}

            <FontColor light>
              <FontWeight>
                <T>SalesDetails:EstimatedDates.placeholder</T>
              </FontWeight>
            </FontColor>
          </FlexColumn>
        }
        renderEditor={() => (
          <InlineModal>
            <InlineModalContent>
              <FlexColumn alignItems="center">
                {!hideMinutes && (
                  <>
                    <TimeWrapper type={[1.5, 2, 2]}>
                      <ThemedSelect
                        className="time-dropdown"
                        classNamePrefix="time-dropdown"
                        extraStyles={extraStyles}
                        isCompact
                        isSearchable
                        name="reservation-start-time-selector"
                        onChange={(selected: Option | null | undefined) =>
                          selected && handleSetTimes(selected.value)
                        }
                        onMenuOpen={onMenuOpen}
                        options={getTimeArray()}
                        placeholder=""
                        value={{
                          label: getTimeString(newDate),
                          value: getTimeString(newDate),
                        }}
                      />
                    </TimeWrapper>

                    <HorizontalDivider />
                  </>
                )}

                <DatePicker
                  setValue={handleChangeDate}
                  value={newDate ? moment(newDate) : undefined}
                />
              </FlexColumn>
            </InlineModalContent>
          </InlineModal>
        )}
        value={
          dateFormatted ? (
            <FlexColumn noPadding alignItems="flex-start">
              {sectionTitle}

              <FontColor light>
                <FontWeight>{dateFormatted}</FontWeight>
              </FontColor>
            </FlexColumn>
          ) : null
        }
      />
    </DateValue>
  )
}

////////

const extraStyles = {
  menuList: (styles: CSSProperties) => ({
    ...styles,
    maxHeight: 225,
  }),
}

const getTimeString = (date: string) => moment(date).format('HH:mm')

const getTimeArray = () => {
  const timeArray: Moment[] = []

  new Array(24).fill(0).forEach((_, idx: number) => {
    timeArray.push(moment({ hour: idx }))
    timeArray.push(moment({ hour: idx, minute: 15 }))
    timeArray.push(moment({ hour: idx, minute: 30 }))
    timeArray.push(moment({ hour: idx, minute: 45 }))
  })

  return timeArray.map((time: Moment) => ({
    label: time.format('HH:mm'),
    searchValue: time.format('HH:mm'),
    value: time.format('HH:mm'),
  }))
}

const HorizontalDivider = styled.div`
  height: 1px;
  width: 100%;

  ${({ theme }) => css`
    background-color: ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gu(2)}rem;
  `}
`

const DateValue = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`

const TimeWrapper = styled(Gutter)`
  width: 100%;
`
