import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'
import { useState } from 'react'

import { FetchStates } from '@/common/types'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SalesReservationManager } from './SalesReservationManager'

import {
  DateRange,
  PendingRoomReservation,
  PendingRoomTypeReservation,
  RoomReservation,
  RoomTypeReservation,
  SalesDetailsBase,
  SalesRoomReservation,
  SalesRoomTypeReservation,
  SalesRoomTypeReservationWithRoomReservations,
} from './SalesReservationManager.types'
import { SalesAccommodationGroup } from '../SalesReservationList/SalesReservationList.types'
import { useReservationListContext } from '../SalesReservationList/ReservationListState'

type OpenSections = {
  [sectionId: string]: boolean
}

type Props = {
  defaultRange: DateRange | null | undefined
  groupId: string
  targetId: string | null
  salesDetails: SalesDetailsBase
  focusedRoomId?: string
  openSectionsInitially?: OpenSections
}

export const SalesReservationManagerContainer = ({
  defaultRange,
  groupId,
  targetId,
  salesDetails,
  focusedRoomId,
  openSectionsInitially = {},
}: Props) => {
  const theme = useTheme()

  const [openSectionsFromSidebar, setOpenSectionsFromSidebar] =
    useState<OpenSections>(openSectionsInitially)

  const {
    addPendingRoomReservation,
    addPendingRoomTypeReservation,
    refreshTicker,
    reservationGroups,
    state,
  } = useReservationListContext()

  const group = reservationGroups.find(
    (group) => group.id === groupId
  ) as SalesAccommodationGroup

  const renderManager = () => {
    const pendingRoomReservations = group.roomReservations.filter(
      (roomReservation: RoomReservation) =>
        roomReservation.__typename === 'PendingRoomReservation'
    ) as PendingRoomReservation[]

    const pendingRoomTypeReservations = group.roomTypeReservations.filter(
      (roomTypeReservation: RoomTypeReservation) =>
        roomTypeReservation.__typename === 'PendingRoomTypeReservation'
    ) as PendingRoomTypeReservation[]

    const topLevelRoomReservations = (
      group.roomReservations.filter(
        (roomReservation: RoomReservation) =>
          roomReservation.__typename !== 'PendingRoomReservation'
      ) as SalesRoomReservation[]
    ).filter(
      (roomReservation: SalesRoomReservation) =>
        roomReservation.type === 'INDEPENDENT'
    )

    const roomTypeReservations = (
      group.roomTypeReservations.filter(
        (roomTypeReservation: RoomTypeReservation) =>
          roomTypeReservation.__typename !== 'PendingRoomTypeReservation'
      ) as SalesRoomTypeReservation[]
    ).map((roomTypeReservation: SalesRoomTypeReservation) => ({
      ...roomTypeReservation,
      roomReservations: (group.roomReservations || []).filter(
        (roomReservation: RoomReservation) =>
          (roomReservation as SalesRoomReservation).roomTypeReservation?.id ===
          roomTypeReservation.id
      ),
    })) as SalesRoomTypeReservationWithRoomReservations[]

    const sortedReservations = [
      ...roomTypeReservations,
      ...topLevelRoomReservations,
    ]
      .sort(generateCompareFn('request.checkIn.date'))
      .filter((r) => (targetId !== '' ? r.target?.id === targetId : r))

    const reservations = [
      ...pendingRoomReservations,
      ...pendingRoomTypeReservations,
      ...sortedReservations,
    ]

    return (
      <SalesReservationManager
        defaultRange={defaultRange}
        groupId={groupId}
        focusedRoomId={focusedRoomId}
        handleAddPendingRoomReservation={({
          roomId,
          roomNumber,
          roomTypeName,
        }) =>
          addPendingRoomReservation({
            defaultRange,
            groupId,
            roomId,
            roomNumber,
            roomTypeName,
            target: targetId
              ? {
                  id: targetId,
                }
              : null,
          })
        }
        handleAddPendingRoomTypeReservation={({ roomTypeId, roomTypeName }) =>
          addPendingRoomTypeReservation({
            defaultRange,
            groupId,
            roomTypeId,
            roomTypeName,
            target: targetId
              ? {
                  id: targetId,
                }
              : null,
          })
        }
        openSectionsFromSidebar={openSectionsFromSidebar}
        refreshTicker={refreshTicker}
        reservations={reservations}
        salesDetails={{
          ...salesDetails,
          totals: group?.status?.reservedDetailed || {
            beds: 0,
            extraBeds: 0,
            rooms: 0,
          },
        }}
        setOpenSections={setOpenSectionsFromSidebar}
      />
    )
  }

  switch (state) {
    case FetchStates.LOADING:
      return (
        <FullScreenContent>
          <ReactLoading
            type={'spin'}
            height={30}
            width={30}
            color={theme.palette.smoke.light}
          />
        </FullScreenContent>
      )
    case FetchStates.IDLE:
    case FetchStates.EMPTY:
      return renderManager()
    default:
      return (
        <FullScreenContent>
          <T>common:error.common</T>
        </FullScreenContent>
      )
  }
}

export default SalesReservationManagerContainer

const FullScreenContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBigger};
  `}
`
