import ReactLoading from 'react-loading'
import { useTheme } from '@/theme'

import { TD } from '../../common'
import useSalesAccommodationCounts from '../useSalesAccommodationCounts'

interface SalesTableBedCountsCellProps {
  salesId: string
}

export const SalesTableBedCountsCell = ({
  salesId,
}: SalesTableBedCountsCellProps) => {
  const theme = useTheme()

  const { counts, error, loading } = useSalesAccommodationCounts({
    salesId,
  })

  return (
    <TD noWrap>
      {loading ? (
        <ReactLoading
          color={theme.palette.coal.main}
          height={14}
          type={'spin'}
          width={14}
        />
      ) : error ? (
        'n/a'
      ) : (
        `${counts.beds} + ${counts.extraBeds}`
      )}
    </TD>
  )
}

export default SalesTableBedCountsCell
