import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { InlineModal } from '@/components/InlineModal'

type Props = {
  children: ReactNode
}

export const SelectorWrapper = ({ children }: Props) => (
  <InlineModal style={{ minWidth: 'none', width: 360 }}>
    <Wrapper>{children}</Wrapper>
  </InlineModal>
)

////////////

const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}
`
