import React, { ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  DataTableButton as Button,
  DataTableInput as Input,
} from '@/components/DataTable'
import { InlineFieldButton } from '@/components/ExtraButtons'
import { useTheme } from '@/theme'

type Props = {
  disabled?: boolean
  placeholder?: ReactNode
  saveTitle: (newTitle: string) => Promise<void>
  title: string | null | undefined
}

export const EditableTitle = ({
  disabled,
  placeholder,
  saveTitle,
  title,
}: Props) => {
  const theme = useTheme()

  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isProcessing, setIsProcessing] = useState<boolean>(false)
  const [editedTitle, setEditedTitle] = useState<string>(title || '')

  const handleInitEdit = () => {
    setIsEditing(true)
  }

  const handleTitleEditSubmit = (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault()
    }

    if (!isProcessing) {
      setIsProcessing(true)
      saveTitle(editedTitle)
        .then(() => setIsEditing(false))
        .finally(() => setIsProcessing(false))
    }
  }

  const resetForm = () => {
    setIsEditing(false)
    setEditedTitle(title || '')
  }

  return (
    <TitleSection>
      {isEditing ? (
        <>
          <Input
            background={theme.palette.smoke.light}
            disabled={isProcessing}
            isProcessing={isProcessing}
            focusOnMount
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEditedTitle(event.target.value)
            }
            onClick={(event) => event.stopPropagation()}
            onSubmit={handleTitleEditSubmit}
            style={{ marginRight: theme.spacing.gutterSmall }}
            value={editedTitle}
          />
          <Button
            key="save-title-edit"
            disabled={isProcessing}
            icon="check"
            onClick={handleTitleEditSubmit}
          />
          <Button
            color="danger"
            disabled={isProcessing}
            icon="xmark"
            key="cancel-title-edit"
            onClick={resetForm}
            variant="outlined"
          />
        </>
      ) : (
        <>
          {disabled ? (
            <>
              {title ? (
                <Title>{title}</Title>
              ) : (
                <Title>
                  <em>{placeholder}</em>
                </Title>
              )}
            </>
          ) : (
            <>
              {title ? (
                <InlineFieldButton onClick={handleInitEdit}>
                  <Title style={{ marginLeft: 0 }}>{title}</Title>
                  <span style={{ flex: 1 }} />
                  <FontAwesomeIcon icon="pen" />
                </InlineFieldButton>
              ) : (
                <InlineFieldButton onClick={handleInitEdit}>
                  <TitlePlaceholder style={{ marginLeft: 0 }}>
                    {placeholder}
                  </TitlePlaceholder>
                  <span style={{ flex: 1 }} />
                  <FontAwesomeIcon icon="pen" />
                </InlineFieldButton>
              )}
            </>
          )}
        </>
      )}
    </TitleSection>
  )
}

////////////

const TitleSection = styled.div`
  display: flex;
  width: 16rem;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterBig};
  `}
`

const Title = styled.span`
  line-height: 1;
  text-align: left;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
    font-size: ${theme.typography.fontSizeBase2};
    color: ${theme.palette.text.light};
  `}
`

const TitlePlaceholder = styled(Title)`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`
