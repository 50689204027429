import { gql, useMutation } from '@apollo/client'

import type {
  AddVoucherPaymentMutation,
  AddVoucherPaymentMutationVariables,
} from '~generated-types'
import { orderFragments } from '@/modules/Order'

const ADD_VOUCHER_PAYMENT_MUTATION = gql`
  ${orderFragments.payment}

  mutation AddVoucherPayment($input: PaymentVoucherAddInput!) {
    paymentVoucherAdd(input: $input) {
      payment {
        ...Payment
      }
    }
  }
`

export const useAddVoucherPaymentMutation = () =>
  useMutation<AddVoucherPaymentMutation, AddVoucherPaymentMutationVariables>(
    ADD_VOUCHER_PAYMENT_MUTATION
  )
