import { darken, desaturate, lighten } from 'polished'
import React, { MouseEvent } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  CalendarReservationCustomer,
  CalendarReservationSales,
} from '@/modules/Reservations/ResourceReservation'
import { Left, Right } from '@/components/Layout'
import { Theme, useTheme } from '@/theme'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'

type Variant = 'filled' | 'outlined'

type Props = {
  color: string
  description: string
  externalOwner?: CalendarReservationSales | null
  groupNumber?: number | null
  hideContent: boolean
  isDragging: boolean
  isFocused: boolean
  isLocked: boolean
  isPooled: boolean
  isShadow: boolean
  isTranslucent: boolean
  onClick: (e: MouseEvent) => void
  quantity?: number | null
  variant?: Variant
}

type PreviewProps = {
  isFocused?: boolean
  onClick?: (e: MouseEvent) => void
}

const getDefaultColor = (theme: Theme) =>
  darken(0.02, desaturate(0.04, theme.palette.text.light))

export const ReservationBlock = React.memo<Props>(
  ({
    color,
    description,
    externalOwner,
    groupNumber,
    hideContent,
    isDragging,
    isFocused,
    isLocked,
    isPooled,
    isShadow,
    isTranslucent,
    onClick,
    quantity,
    variant,
  }: Props) => {
    const theme = useTheme()

    const getCustomerName = (customer: CalendarReservationCustomer) =>
      customer.__typename === 'CustomerOrganization' && customer?.organization
        ? ` | ${customer.organization.name}`
        : customer.__typename === 'CustomerPerson' && customer?.person
        ? ` | ${customer.person.firstName} ${customer.person.lastName}`
        : ''

    const renderExternalDetails = (owner: CalendarReservationSales) => (
      <Text>
        {isPooled && (
          <>
            {quantity ?? 1} <T>common:unit.PIECE</T>
            {' | '}
          </>
        )}

        {owner.orderNumber && (
          <FontWeight>{`#${owner.orderNumber}`}</FontWeight>
        )}

        {owner?.customer?.customer
          ? getCustomerName(owner?.customer?.customer)
          : owner.name && ` | ${owner.name}`}
      </Text>
    )

    const renderDetails = () => (
      <FlexRow
        alignItems="center"
        style={{ margin: `0 ${theme.spacing.gu(1)}rem`, whiteSpace: 'pre' }}
      >
        {isPooled && (
          <>
            {quantity ?? 1} <T>common:unit.PIECE</T>
            {' | '}
          </>
        )}
        <Description bold>{description}</Description>

        <span style={{ flex: 1 }} />

        {groupNumber && (
          <>
            <FontWeight
              semiBold
              style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
            >
              #{groupNumber}
            </FontWeight>
            <FontAwesomeIcon
              style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
              icon="user-group"
              size="xs"
            />
          </>
        )}
      </FlexRow>
    )

    const reservationColor = color || getDefaultColor(theme)

    return (
      <Wrapper onClick={onClick}>
        {isShadow ? (
          <ReservationShadow />
        ) : (
          <ReservationBg
            className="draggable-item"
            color={reservationColor}
            isDragging={isDragging}
            isFocused={isFocused}
            isLocked={isLocked}
            isTranslucent={isTranslucent}
            variant={variant}
          >
            {!hideContent && (
              <>
                <Left>
                  <VerticalLine
                    color={reservationColor}
                    isLocked={isLocked}
                    variant={variant}
                  />
                </Left>

                <Content
                  color={reservationColor}
                  variant={variant}
                  isFlex={!!externalOwner}
                >
                  {externalOwner
                    ? renderExternalDetails(externalOwner)
                    : renderDetails()}
                </Content>

                <Right>
                  <VerticalLine
                    color={reservationColor}
                    isLocked={isLocked}
                    variant={variant}
                  />
                </Right>
              </>
            )}
          </ReservationBg>
        )}
      </Wrapper>
    )
  }
)

export const ReservationBlockPreview = ({
  isFocused,
  onClick,
}: PreviewProps) => {
  const theme = useTheme()
  const color = getDefaultColor(theme)

  return (
    <Wrapper onClick={onClick}>
      <ReservationBg
        className="draggable-item"
        color={color}
        isFocused={isFocused}
        isPreview
      >
        <VerticalLine color={color} />

        <Right>
          <VerticalLine color={color} />
        </Right>
      </ReservationBg>
    </Wrapper>
  )
}

const pickColor = (color: string, theme: Theme, variant?: Variant) =>
  variant !== 'filled' ? color : theme.palette.white

const pickFillColor = (color: string, theme: Theme, variant?: Variant) =>
  variant === 'filled' ? color : theme.palette.white

const blinkingEffect = (variant?: Variant) =>
  keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: ${variant === 'filled' ? 0.5 : 0.25};
    }
    100% {
      opacity: 1;
    }
  `

const Description = styled(FontWeight)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ReservationBg = styled.div<{
  color: string
  isDragging?: boolean
  isFocused?: boolean
  isLocked?: boolean
  isPreview?: boolean
  isTranslucent?: boolean
  variant?: Variant
}>`
  width: 100%;
  height: ${({ isPreview }) => (isPreview ? 32 : 28)}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 3px;
  margin-bottom: 1px;

  background: ${({ color, theme, variant }) =>
    pickFillColor(color, theme, variant)};
  border-radius: 4px;
  border: 1px solid ${({ color }) => color};
  transition: 0.2s ease-in;
  cursor: ${({ isLocked }) => (isLocked ? 'pointer' : 'grab')};
  opacity: ${({ isPreview }) => (isPreview ? 0.6 : 1)};

  ${({ isDragging }) =>
    isDragging &&
    css`
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    `}

  ${({ isFocused, variant }) =>
    isFocused &&
    css`
      animation: 2s ease-in-out 0s infinite ${blinkingEffect(variant)};
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
        0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.2);
    `}

  ${({ isLocked }) => isLocked && `background: #F8F8F8`}

  ${({ isTranslucent }) => isTranslucent && 'opacity: 0.5;'}
`

const ReservationShadow = styled.div`
  height: 100%;
  margin-left: -1px;
  width: calc(100% + 1px);

  ${({ theme }) => css`
    background: repeating-linear-gradient(
      135deg,
      ${theme.palette.white},
      ${theme.palette.white} 6px,
      ${lighten(0.015, theme.palette.smoke.light)} 6px,
      ${lighten(0.015, theme.palette.smoke.light)} 13px
    );
    border-left: 1px solid ${theme.palette.smoke.light};
    border-right: 1px solid ${theme.palette.smoke.light};
  `}
`

const Text = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const Content = styled.div<{
  color: string
  isFlex: boolean
  variant?: Variant
}>`
  display: ${({ isFlex }) => isFlex && 'flex'};
  overflow: hidden;
  font-weight: 400;
  color: ${({ color, theme, variant }) => pickColor(color, theme, variant)};
  width: 100%;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const VerticalLine = styled.div<{
  color: string
  isLocked?: boolean
  variant?: Variant
}>`
  height: 20px;
  width: 1px;
  background: ${({ color, isLocked, theme, variant }) =>
    !isLocked ? pickColor(color, theme, variant) : 'transparent'};
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`
