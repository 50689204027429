import styled, { css } from 'styled-components/macro'

import { Chip } from '@/components/Chip'
import { OrderState as OS } from '~generated-types'
import { T } from '@/modules/Language'

type Props = {
  fixedWidth?: boolean
  state: OS
}

export const OrderStateChip = ({ fixedWidth, state, ...props }: Props) => (
  <StyledChip
    color={reconcileColor(state)}
    fixedWidth={fixedWidth}
    size="sm"
    {...props}
  >
    <T l10n={`Orders:OrderState.${state}`} />
  </StyledChip>
)

///////

const StyledChip = styled(Chip)<{ fixedWidth?: boolean }>`
  && {
    justify-content: center;
    margin-right: 0;
    white-space: initial;

    ${({ fixedWidth, theme }) =>
      fixedWidth &&
      css`
        width: ${theme.spacing.gu(13)}rem;
      `}
  }
`

const reconcileColor = (state: OS) => {
  switch (state) {
    case OS.CancelProcess:
    case OS.Correction:
    case OS.ReInvoiceProcess:
      return 'primary'
    case OS.Open:
      return 'secondary'
    case OS.Cancelled:
    case OS.Closed:
    default:
      return 'default'
  }
}
