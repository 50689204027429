import { MobileSalesResourceReservation } from '@/modules/Mobile/SalesList'

export const getDimensionValues = (
  input: MobileSalesResourceReservation['dimensions']
) => {
  const dimensions =
    input && Array.isArray(input && input.selection) ? input.selection : []

  return dimensions
    .map((x) => !!x && !!x.selectedLabel && x.selectedLabel.name)
    .filter((x) => !!x)
}
