import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ApolloError } from '@apollo/client'

import { Task, taskHooks } from '@/modules/Task'

// Contexts
import { useCompactViewContext } from '../../../SaleCompactView/CompactViewContainer'
import { useSalesDetailsContext } from '../../'

type ContextType = {
  error?: ApolloError
  isLoading: boolean
  tasks: Task[]
  setTasks: (tasks: Task[]) => void
}

const TaskListContext = createContext<ContextType>({
  error: undefined,
  isLoading: false,
  setTasks: () => Promise.reject(),
  tasks: [],
})

type Props = {
  children: ReactNode
}

export const TaskListContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const { data } = useSalesDetailsContext()
  const { salesId } = useCompactViewContext()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [tasks, setTasks] = useState<Task[]>([])

  const {
    error,
    loading,
    tasks: tasksArr,
  } = taskHooks.useSalesTasks({
    salesId: salesId || data.id,
  })

  useEffect(() => {
    setTasks(tasksArr || [])
  }, [JSON.stringify(tasksArr)])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  contextValueRef.current = {
    error,
    isLoading,
    setTasks,
    tasks,
  }

  return (
    <TaskListContext.Provider value={contextValueRef.current}>
      {children}
    </TaskListContext.Provider>
  )
}

export const useTaskListContext = () => useContext(TaskListContext)
