import styled, { css } from 'styled-components/macro'

import { Button } from '@/components/Button'

const simpleButtonCss = css`
  font-size: 0.88rem;
  letter-spacing: 0;
  border-radius: 20px;

  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
  `}
`

const SimpleButtonBase = styled(Button).attrs(() => ({
  size: 'small',
}))<any>`
  ${simpleButtonCss}

  ${(props) => {
    const rules = []

    if (props.background) {
      rules.push(`background: ${props.background} !important;`)
    }

    if (props.borderColor) {
      rules.push(`border-color: ${props.borderColor} !important;`)
    }

    if (props.color) {
      rules.push(`color: ${props.color};`)
    }

    return rules.join('\n')
  }}
`

export const SimpleButton = styled(SimpleButtonBase)<any>`
  padding-left: 0.75rem;

  & .svg-inline--fa {
    font-size: 0.72rem;

    ${({ theme }) => css`
      margin: 0 ${theme.spacing.gu(1)}rem 0 0;
    `}
  }
`

export const SimpleIconButton = styled(SimpleButtonBase)<any>`
  justify-content: center;
  padding-left: 0;
  padding-right: 0;
  width: 32px;

  text-align: center;

  ${(props) => {
    const rules = []

    if (props.miniscule) {
      rules.push(`
      width: 28px;
      height: 28px;

      font-size: 0.72rem;
      `)
    }

    return rules.join('\n')
  }}
`
