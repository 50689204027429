import { CSSProperties, MouseEvent, ReactNode, Ref } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import ReactLoading from 'react-loading'

import { InnocuousButton } from '@/components/ExtraButtons'
import { useTheme } from '@/theme'

import { getActionColor, getActionIcon, IconWrapper, Label } from './utils'
import { ActionType } from './types'

type ActionButtonProps = {
  action: ActionType
  compact?: boolean
  innerRef?: Ref<HTMLButtonElement>
  isDisabled?: boolean
  isProcessing?: boolean
  label: ReactNode
  onClick: (e: MouseEvent) => void | Promise<void>
  style?: CSSProperties
}

export const ActionButton = ({
  action,
  compact = true,
  isDisabled,
  isProcessing,
  label,
  ...rest
}: ActionButtonProps) => {
  const { palette, spacing } = useTheme()

  const isCompactIcon = action === 'DELETE' || action === 'CORRECT_INVOICE'

  return (
    <Button
      compact={compact}
      disabled={isProcessing || isDisabled}
      noMargin
      noNudge
      textColor={getActionColor(action, palette)}
      {...rest}
    >
      {isProcessing ? (
        <LoaderWrapper>
          <ReactLoading
            color={getActionColor(action, palette)}
            height={spacing.guPx(2)}
            type="spin"
            width={spacing.guPx(2)}
          />
        </LoaderWrapper>
      ) : (
        <IconWrapper>
          <FontAwesomeIcon
            fixedWidth
            icon={getActionIcon(action) as IconProp}
            size={isCompactIcon ? 'xs' : 'sm'}
          />
        </IconWrapper>
      )}
      <Label>{label}</Label>
    </Button>
  )
}

//////

const Button = styled(InnocuousButton)<{ textColor: string }>`
  color: ${({ textColor }) => textColor};
  width: fit-content;
  min-width: 0;
`

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`
