import { generateCompareFn } from '@/utils/arrays'

import { groupAvailabilityStatusByDate } from './groupAvailabilityStatusByDate'

import {
  RawRoomTypeAvailability,
  RoomTypeAvailability,
} from '../AvailabilityCalendar.types'

export const groupRawAvailabilityDataByDate = (
  input: RawRoomTypeAvailability[]
): RoomTypeAvailability[] =>
  input
    .map(({ rooms, roomType, status }) => ({
      rooms: rooms
        .map(({ room, status }) => ({
          room,
          status: groupAvailabilityStatusByDate(status),
        }))
        .sort(generateCompareFn('room.number')),
      roomType: roomType,
      status: groupAvailabilityStatusByDate(status),
    }))
    .sort(generateCompareFn('roomType.name'))
