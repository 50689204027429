import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'

import { useParticipantsListContext } from '../../ParticipantsListState'

export const LoadAllButton = () => {
  const { setShowAllParticipant } = useParticipantsListContext()

  return (
    <Wrapper justifyContent="flex-start">
      <Button compact onClick={() => setShowAllParticipant(true)}>
        <PrimaryColor>
          <T>ParticipantsList:loadAll</T>
        </PrimaryColor>
      </Button>
    </Wrapper>
  )
}

/////////

const Button = styled(InnocuousButton)`
  font-weight: 500;
  text-transform: unset;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const Wrapper = styled(FlexRow)`
  position: absolute;
  right: 0;

  ${({ theme }) => css`
    margin: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
  `}
`
