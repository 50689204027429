import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { SalesCopyComponent as C } from '~generated-types'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'

import { Checkbox } from '../components'

type Props = {
  components: C[]
  setComponents: (components: C[]) => void
}

export const AccommodationSection = ({ components, setComponents }: Props) => {
  const toggleComponent = (target: C) =>
    setComponents(
      components.includes(target)
        ? components.filter((c) => c !== target)
        : [...components, target]
    )

  return (
    <Wrapper>
      <Title>
        <T>SalesDetails:CopySale.label.ACCOMMODATION</T>
      </Title>

      <FlexRow wrap="wrap">
        <Checkbox
          checked={components.includes(C.RoomReservations)}
          label={C.RoomReservations}
          onChange={() => toggleComponent(C.RoomReservations)}
        />

        <Checkbox
          checked={components.includes(C.RoomTypeReservations)}
          label={C.RoomTypeReservations}
          onChange={() => toggleComponent(C.RoomTypeReservations)}
        />
      </FlexRow>
    </Wrapper>
  )
}

/////

const Title = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`
