import { CSSProperties, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'
import isNumber from 'lodash.isnumber'

import { Gutter } from '@/components/Layout'

type Props = {
  withPadding?: boolean
  children: ReactNode
  paddingBottom?: number
  paddingTop?: number
  style?: CSSProperties
}

export const ContentWrapper = ({
  withPadding,
  children,
  paddingBottom,
  paddingTop,
  style,
}: Props) => (
  <Wrapper
    type={
      withPadding
        ? [
            isNumber(paddingTop) ? paddingTop : 2,
            2,
            isNumber(paddingBottom) ? paddingBottom : 2,
            2,
          ]
        : 0
    }
    style={style}
  >
    {children}
  </Wrapper>
)

const Wrapper = styled(Gutter)`
  position: relative;
  flex-grow: 1;
  max-width: 1500px;
  min-width: min-content;
  border-radius: 8px;
  width: 100%;

  ${({ theme }) => css`
    margin: 0 auto ${theme.spacing.gu(5)}rem;
  `}
`
