import { CustomerCell, SalesCell } from '@/modules/Listing/common/cells'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { Mode, SalesForIsoWeekSales } from '../types'
import BedCountsCell from './SalesTableBedCountsCell'
import TaskCountsCell from './SalesTableTaskCountsCell'
import { TD } from '../../common'

interface SalesTableRowProps {
  data: SalesForIsoWeekSales
  mode: Mode
}

export const SalesTableRow = ({ data, mode }: SalesTableRowProps) => {
  const {
    customer,
    estimatedDates,
    estimatedParticipants,
    id,
    reservationDates,
    resourceReservationCount,
    seller,
  } = data
  const bedsCell = <BedCountsCell salesId={id} />
  const customerCell = customer?.customer ? (
    <CustomerCell {...customer.customer} noWrap />
  ) : (
    <TD>—</TD>
  )

  const datesCell = (
    <TD noWrap>
      {estimatedDates
        ? formatDateRangeWithoutRepetition(
            estimatedDates.start,
            estimatedDates.end,
            'short'
          )
        : reservationDates
        ? formatDateRangeWithoutRepetition(
            reservationDates.start,
            reservationDates.end,
            'short'
          )
        : '–'}
    </TD>
  )
  /**
   * TODO: Add when API support available
   */
  const ownerCell = <TD>—</TD>
  const participantsCell = <TD>{estimatedParticipants || 0}</TD>
  const resourceReservationsCell = <TD>{resourceReservationCount}</TD>
  const salesCell = <SalesCell noWrap {...data} />
  const paymentAgreementCell = (
    <TD title={data.paymentAgreement?.name}>{data.paymentAgreement?.code}</TD>
  )

  const sellerCell = <TD>{seller?.shortName}</TD>
  /**
   * TODO: Add when state display format is specified
   */
  // const stateCell = <td>{data.state}</td>

  switch (mode) {
    case Mode.FULL:
      return (
        <tr>
          {customerCell}
          {salesCell}
          {paymentAgreementCell}
          {datesCell}
          {participantsCell}
          {bedsCell}
          {resourceReservationsCell}
          {<TaskCountsCell salesId={id} />}
          {sellerCell}
          {ownerCell}
        </tr>
      )
    case Mode.CONCISE:
      return (
        <tr>
          {customerCell}
          {salesCell}
          {paymentAgreementCell}
          {datesCell}
          {participantsCell}
          {bedsCell}
          {ownerCell}
        </tr>
      )
  }
}

export default SalesTableRow
