import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'

import { getFullName } from '../../utils'
import { SalesForPrintCustomer } from '../../../../types'

type Props = {
  customer: SalesForPrintCustomer
}

export const Customer = ({ customer }: Props) => {
  const { contactPerson, email, name, phone, postalAddress } =
    parseCustomer(customer)

  return (
    <Wrapper>
      <Title>
        <T>SalesDetails:PrintSale.label.CUSTOMER</T>
      </Title>

      <FlexRow>
        <FlexColumn flex={1}>
          <span>{name}</span>
          <span>{contactPerson}</span>
          <span>{phone}</span>
          <span>{email}</span>
        </FlexColumn>

        {postalAddress && (
          <FlexColumn flex={1}>
            {postalAddress.address1 && <span>{postalAddress.address1}</span>}
            {postalAddress.address2 && <span>{postalAddress.address2}</span>}
            {(postalAddress.postcode || postalAddress.city) && (
              <span>
                {postalAddress.postcode ? `${postalAddress.postcode} ` : ''}
                {postalAddress.city}
              </span>
            )}
            {postalAddress.country && <span>{postalAddress.country}</span>}
          </FlexColumn>
        )}
      </FlexRow>
    </Wrapper>
  )
}

/////////

const parseCustomer = ({
  address,
  contact,
  customer,
}: SalesForPrintCustomer) => {
  const currentAddress = customer.addresses.find(({ id }) => id === address?.id)
  const postalAddress = currentAddress?.postalAddress ?? null

  if (customer.__typename === 'CustomerPerson') {
    const {
      person: { email, firstName, lastName, phone },
    } = customer

    return {
      contactPerson: null,
      email,
      name: getFullName(firstName, lastName),
      phone,
      postalAddress,
    }
  }

  const {
    contacts,
    organization: { name },
  } = customer

  const currentContact = contacts.find(({ id }) => id === contact?.id)

  return {
    contactPerson: currentContact
      ? getFullName(currentContact.firstName, currentContact.lastName)
      : null,
    email: currentContact?.email ?? null,
    name,
    phone: currentContact?.phone ?? null,
    postalAddress,
  }
}

const Title = styled.span`
  font-size: 1.375rem;
  font-weight: 600;
  page-break-after: avoid;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexColumn)`
  font-size: 1.1rem;
`
