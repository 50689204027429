import { ChangeEvent, useState } from 'react'

import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { Label, Textarea } from '@/components/FormControls'
import { EditButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ContentPlaceholder, TruncateContent } from '../../common'
import { usePublishContext } from '../../../PublishState'

export const ThemeDescription = () => {
  const { spacing } = useTheme()
  const { salesWebshopSettings } = usePublishContext()

  const themeDescription = salesWebshopSettings?.theme?.description

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [description, setDescription] = useState<string>('')

  return (
    <>
      <Label>
        <T>Publish:Basic.themeDescription.title</T>
      </Label>

      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <InlineModal>
            <InlineModalSection style={{ margin: 0, padding: spacing.gutter }}>
              <Textarea
                disabled={true}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setDescription(e.target.value)
                }
                autoFocus
                value={description || ''}
                style={{ height: 200, width: 360 }}
              />
            </InlineModalSection>
          </InlineModal>
        }
        onClose={() => setModalOpen(false)}
        placement="bottom"
        referenceElement={({ ref }) => (
          <FlexRow ref={ref} style={{ height: '-webkit-fill-available' }}>
            <EditButton
              disabled={false}
              onClick={() => {
                setModalOpen(true)
                setDescription(themeDescription || '')
              }}
              style={{ flex: 'unset', height: 'auto' }}
            >
              <TruncateContent
                linesToShow={2}
                content={
                  themeDescription || (
                    <ContentPlaceholder>
                      <T>Publish:Basic.themeDescription.noDescription</T>
                    </ContentPlaceholder>
                  )
                }
              />
            </EditButton>
          </FlexRow>
        )}
        zIndex={10003}
      />
    </>
  )
}
