import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { EditButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ContentPlaceholder, TruncateContent } from '../../common'
import { SalesDetails } from '../../../../../types'
import { usePublishContext } from '../../../PublishState'
import { WebshopSettingsToken } from '../../../types'

type Props = {
  token: WebshopSettingsToken
  type: 'EMAIL' | 'LINK'
}

export const CopyToClipboard = ({
  token: { issues, completedAt, link },
  type,
}: Props) => {
  const { palette } = useTheme()
  const { readOnly: salesReadOnly, customer } = usePublishContext()

  const [copied, setCopied] = useState<boolean>(false)

  const resetRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleCopyUrl = (text: string) => {
    navigator.clipboard.writeText(text)
    setCopied(true)
    resetRef.current = setTimeout(() => setCopied(false), 4000)
  }

  useEffect(() => {
    return () => {
      if (resetRef.current) clearTimeout(resetRef.current)
    }
  }, [resetRef])

  const readOnly = !!(
    issues.find((issue) => issue.level === 'ERROR') ||
    completedAt ||
    salesReadOnly
  )

  const textToCopy = type === 'EMAIL' ? getCustomerEmail(customer) : link

  return (
    <>
      {textToCopy ? (
        <EditButton
          customIcon={
            <FontAwesomeIcon
              icon={!copied ? 'copy' : 'check'}
              color={!copied ? palette.text.lighter : palette.success.main}
              size="sm"
            />
          }
          disabled={readOnly}
          onClick={() => handleCopyUrl(textToCopy)}
          style={{ flex: 'unset' }}
        >
          <TruncateContent content={textToCopy} />
        </EditButton>
      ) : (
        <ContentPlaceholder>
          <TruncateContent
            content={
              type === 'EMAIL' ? (
                <T>Publish:Permissions.customer.empty</T>
              ) : (
                <T>Publish:Permissions.link.empty</T>
              )
            }
          />
        </ContentPlaceholder>
      )}
    </>
  )
}

////////////

const getCustomerEmail = (customer: SalesDetails['customer']) =>
  customer?.customer.__typename === 'CustomerOrganization'
    ? customer.customer.contacts.find(({ id }) => id === customer.contact?.id)
        ?.email
    : customer?.customer.__typename === 'CustomerPerson'
    ? customer.customer.person.email
    : null
