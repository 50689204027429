import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'

import { T, useLanguageContext } from '@/modules/Language'
import { FlexColumn } from '@/components/Layout'
import { FontColor } from '@/components/Typography'
import { formatDateRangeWithoutRepetition } from '@/utils/time'

import { ArrowButton, ControlWrapper, TextButton, Wrapper } from './common'

type Props = {
  decrement: () => void
  end: Moment
  increment: () => void
  setCurrentWeek: () => void
  start: Moment
}

export const WeekControl = ({
  decrement,
  end,
  increment,
  setCurrentWeek,
  start,
}: Props) => {
  const { language } = useLanguageContext()

  const [datesLabel, setDatesLabel] = useState<string>(
    formatDateRangeWithoutRepetition(
      start.toISOString(),
      end.toISOString(),
      'normal',
      'short'
    )
  )

  useEffect(() => {
    setDatesLabel(
      formatDateRangeWithoutRepetition(
        start.toISOString(),
        end.toISOString(),
        'normal',
        'short'
      )
    )
  }, [end, language, start])

  return (
    <Wrapper alignItems="center" noPadding>
      <TextButton onClick={() => setCurrentWeek()}>
        <T>ResourceReservationsCalendar:navigation.week</T>
      </TextButton>

      <ControlWrapper alignItems="center" justifyContent="space-between">
        <ArrowButton onClick={() => decrement()}>
          <FontAwesomeIcon icon="angle-left" />
        </ArrowButton>

        <Week>
          <span>
            <T>common:time.week</T> {start.isoWeek()}
          </span>
          <small>
            <FontColor light>{datesLabel}</FontColor>
          </small>
        </Week>

        <ArrowButton onClick={() => increment()}>
          <FontAwesomeIcon icon="angle-right" />
        </ArrowButton>
      </ControlWrapper>
    </Wrapper>
  )
}

////////////

const Week = styled(FlexColumn)`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    margin: 0 ${theme.spacing.gutter};
  `}
`
