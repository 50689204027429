import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import {
  CheckInInput,
  CheckInType,
  CheckOutInput,
  CheckOutType,
  SaleAccommodationQuery,
} from '~generated-types'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal, InlineModalFooter } from '@/components/InlineModal'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { CheckboxInput } from '@/components/FormControls'
import { DateRangePicker } from '@/components/TimeControls'
import { ModalContainer } from '@/components/Modal'
import { useDialogService } from '@/components/DialogService'
import { useRoomReservationSetDates } from '@/modules/Accommodation/SalesReservationManager/mutations'
import { useTheme } from '@/theme'

type Request =
  SaleAccommodationQuery['suitableRooms'][0]['roomReservation']['request']

type Props = {
  checkIn: Request['checkIn']
  checkOut: Request['checkOut']
  id: string
  roomNumber: string
}

export const RoomDates = ({ checkIn, checkOut, id, roomNumber }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()

  const { confirm } = useDialogService()

  const [updateDates] = useRoomReservationSetDates()

  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [checkInType, setCheckInType] = useState<CheckInType>(checkIn.type)
  const [checkOutType, setCheckOutType] = useState<CheckOutType>(checkOut.type)
  const [range, setRange] = useState<{
    from: Moment
    to: Moment
  } | null>(
    checkIn.date && checkOut.date
      ? {
          from: moment(checkIn.date),
          to: moment(checkOut.date),
        }
      : null
  )

  const handleUpdateDates = () => {
    if (!range) {
      return
    }

    const checkIn: CheckInInput = {
      date: range.from.format('YYYY-MM-DD'),
      type: checkInType,
    }
    const checkOut: CheckOutInput = {
      date: range.to.format('YYYY-MM-DD'),
      type: checkOutType,
    }

    const inputRange = { checkIn, checkOut }

    return updateDates({
      variables: {
        input: {
          ...inputRange,
          allowOverlapping: false,
          id,
        },
      },
    }).then((res) => {
      res.data?.accommodationRoomReservationSetDates.__typename ===
        'RoomReservationOverlappingError' &&
        res.data?.accommodationRoomReservationSetDates.message &&
        confirm({
          cancelLabel: <T>common:action.cancel</T>,
          confirmLabel: <T>common:action.continue</T>,
          description: translate(
            'RoomLayout:calendar.overlapping.description',
            language,
            {
              roomNumber: `#${roomNumber}`,
            }
          ),
          title: <T>RoomLayout:calendar.overlapping.title</T>,
        })
          .then(() =>
            updateDates({
              variables: {
                input: {
                  ...inputRange,
                  allowOverlapping: true,
                  id,
                },
              },
            })
          )
          .catch(() => {
            setRange(
              checkIn.date && checkOut.date
                ? {
                    from: moment(checkIn.date),
                    to: moment(checkOut.date),
                  }
                : null
            )
            setCheckInType(checkIn.type)
            setCheckOutType(checkOut.type)
          })
    })
  }

  const renderDateRange = () =>
    range ? (
      <FlexRow alignItems="center">
        <IconWrapper>
          {checkInType === CheckInType.Early && (
            <FontAwesomeIcon
              icon="sun"
              color={palette.text.light}
              size="sm"
              style={{ marginRight: spacing.gutterSmall }}
            />
          )}
        </IconWrapper>
        {range.from.format('dd, D.M.')}
        <span style={{ margin: `0 ${spacing.gu(1)}rem` }}>–</span>
        {range.to.format('dd, D.M.YY')}
        <IconWrapper>
          {checkOutType === CheckOutType.Late && (
            <FontAwesomeIcon
              icon="moon"
              color={palette.text.light}
              size="xs"
              style={{ marginLeft: spacing.gutterSmall }}
            />
          )}
        </IconWrapper>
      </FlexRow>
    ) : (
      translate(
        'Accommodation:SalesReservationManager.rangePlaceholder',
        language
      )
    )

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <DateRangePicker
            setValue={(range) => {
              range && setRange(range)
            }}
            value={range}
          />
          <InlineModalFooter>
            <CheckInOutWrapper flex={1}>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkIn</T>
                </Title>
                <CheckboxInput
                  checked={checkInType === CheckInType.Early}
                  noMargin
                  onChange={() =>
                    setCheckInType(
                      checkInType === CheckInType.Early
                        ? CheckInType.Standard
                        : CheckInType.Early
                    )
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckInType.Early}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkOut</T>
                </Title>
                <CheckboxInput
                  checked={checkOutType === CheckOutType.Late}
                  noMargin
                  onChange={() =>
                    setCheckOutType(
                      checkOutType === CheckOutType.Late
                        ? CheckOutType.Standard
                        : CheckOutType.Late
                    )
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckOutType.Late}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
            </CheckInOutWrapper>
          </InlineModalFooter>
        </InlineModal>
      }
      onClose={() => {
        handleUpdateDates()
        setModalOpen(false)
      }}
      referenceElement={({ ref }) => (
        <DatesWrapper ref={ref} onClick={() => setModalOpen(true)}>
          {renderDateRange()}
        </DatesWrapper>
      )}
    />
  )
}

////////////

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const CheckInOutWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const DatesWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 16rem;
  max-width: 16rem;
  background-color: ${({ disabled, theme }) =>
    disabled ? theme.palette.smoke.light : theme.palette.white};
  transition: all 0.1s ease-out;
  border-radius: 8px;

  ${({ theme }) => css`
    border: 2px solid ${theme.palette.smoke.light};
    padding: ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(1)}rem;
    margin-left: ${theme.spacing.gu(1)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const IconWrapper = styled.div`
  width: 1.5rem;
  text-align: center;
`
