import { SectionContainer } from '../../components'
import { SectionFeatures } from '../../types'
import { TasksList } from './components'

const SECTION: SectionFeatures = {
  icon: ['far', 'circle-check'],
  key: 'checks',
}

const TasksChecksComponent = () => (
  <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
    <TasksList />
  </SectionContainer>
)

export const TasksChecks = Object.freeze({
  component: TasksChecksComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
