import { gql, useMutation } from '@apollo/client'

import {
  SaleVstSetAttributesMutation,
  SaleVstSetAttributesMutationVariables,
} from '~generated-types'

import SalesVstFragment from '../fragments/salesVst'

export type {
  SaleVstSetAttributesMutation,
  SaleVstSetAttributesMutationVariables,
}

export const SET_VST_ATTRIBUTES = gql`
  ${SalesVstFragment}

  mutation SaleVstSetAttributes($input: SalesVstSetAttributesInput!) {
    salesVstSetAttributes(input: $input) {
      salesId
      vst {
        ...SalesVst
      }
    }
  }
`

export function useSaleVstSetAttributes() {
  return useMutation<
    SaleVstSetAttributesMutation,
    SaleVstSetAttributesMutationVariables
  >(SET_VST_ATTRIBUTES)
}

export default useSaleVstSetAttributes
