import { CSSProperties } from 'react'

import { FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'

type Props = {
  targetId?: string
  isManagerDisabled: boolean
  isRoomLayoutDisabled: boolean
  style?: CSSProperties
  setManagerOpen: (targetId?: string) => void
  setRoomLayoutOpen: (targetId?: string) => void
}

export const ReservationManagementControls = ({
  targetId,
  isManagerDisabled,
  isRoomLayoutDisabled,
  style,
  setManagerOpen,
  setRoomLayoutOpen,
}: Props) => (
  <FlexRow style={style} justifyContent="center">
    <InnocuousButton
      compact
      onClick={() => setManagerOpen(targetId)}
      disabled={isManagerDisabled}
    >
      <PrimaryColor>
        <T>Accommodation:SalesReservationList.manageReservations</T>
      </PrimaryColor>
    </InnocuousButton>
    <InnocuousButton
      compact
      onClick={() => setRoomLayoutOpen(targetId)}
      disabled={isRoomLayoutDisabled}
    >
      <PrimaryColor>
        <T>Accommodation:SalesReservationList.manageRoomLayout</T>
      </PrimaryColor>
    </InnocuousButton>
  </FlexRow>
)
