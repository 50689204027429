import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { CustomerDetails as CustomerDetailsType } from '@/modules/Order/types'
import { FontColor } from '@/components/Typography'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { useTheme } from '@/theme'

import { CustomerAddress } from './CustomerAddress'
import { EditButton } from '../components'

type Props = {
  customer: CustomerDetailsType
  isException: boolean
  openDetailsModal: () => void
  readOnly: boolean
}

export const CustomerDetails = ({
  customer: {
    address,
    contactPerson,
    customerNumber,
    email,
    name,
    phone,
    type,
  },
  isException,
  openDetailsModal,
  readOnly,
}: Props) => {
  const { palette, spacing } = useTheme()

  return (
    <FlexRow flex={1} style={{ overflow: 'hidden' }}>
      <TitlesWrapper alignItems="flex-end" noPadding>
        {type === 'ORGANIZATION' ? (
          <Icon icon={['far', 'building']} size="sm" />
        ) : (
          <Icon icon="user" />
        )}

        <Separator transparent />

        {type === 'ORGANIZATION' && <Icon icon="user" withMargin />}

        <Icon icon="at" withMargin />

        <Icon icon="phone" />

        <Separator transparent />

        <Icon icon="envelope" />
      </TitlesWrapper>

      <StyledEditButton disabled={readOnly} onClick={openDetailsModal}>
        <FlexColumn flex={1} noPadding style={{ overflow: 'hidden' }}>
          <CustomerLabelWrapper justifyContent="flex-start">
            <CustomerLabel title={name ?? ''}>{name}</CustomerLabel>

            <FontColor lighter>#{customerNumber}</FontColor>

            {isException && (
              <Tooltip
                content={<T>Orders:Customer.exception</T>}
                delay={300}
                placement="top"
                trigger={(triggerProps) => (
                  <span {...triggerProps}>
                    <FontAwesomeIcon
                      color={palette.warning.dark}
                      icon="triangle-exclamation"
                      style={{ marginLeft: `${spacing.gu(1)}rem` }}
                    />
                  </span>
                )}
              />
            )}
          </CustomerLabelWrapper>

          <Separator />

          {type === 'ORGANIZATION' && <Label>{contactPerson ?? '–'}</Label>}

          <Label>{email ?? '–'}</Label>

          {phone ?? '–'}

          <Separator />

          <CustomerAddress address={address} />
        </FlexColumn>
      </StyledEditButton>
    </FlexRow>
  )
}

////////

type IconProps = { icon: IconProp; size?: SizeProp; withMargin?: boolean }

const Icon = ({ withMargin, ...props }: IconProps) => {
  const { palette } = useTheme()

  return (
    <IconWrapper alignItems="center" withMargin={withMargin}>
      <FontAwesomeIcon
        color={palette.text.lighter}
        fixedWidth
        size="xs"
        {...props}
      />
    </IconWrapper>
  )
}

const IconWrapper = styled(FlexRow)<{ withMargin?: boolean }>`
  height: 15px;

  ${({ theme, withMargin }) => css`
    ${withMargin && `margin-bottom: ${theme.spacing.gu(1)}rem;`}
  `}
`

const CustomerLabelWrapper = styled(FlexRow)`
  overflow: hidden;
  width: 100%;
`

const CustomerLabel = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Label = styled.span`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`

const Separator = styled.div<{ transparent?: boolean }>`
  width: 100%;
  height: 1px;
  margin: 8px 0;

  ${({ theme, transparent }) => css`
    background: ${transparent ? 'transparent' : theme.palette.smoke.light};
  `}
`

const StyledEditButton = styled(EditButton)`
  align-items: flex-start;
  flex: 1;
  height: 100%;
  line-height: 15px;
  overflow: hidden;

  &:disabled {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }
`

const TitlesWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(0.5)}rem 0
      ${theme.spacing.gu(1)}rem;
  `}
`
