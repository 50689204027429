import { FC, ReactElement, useState } from 'react'

import {
  CountLabel,
  ExpansionButton,
  TreeRow,
  TreeRowLabel,
} from '@/components/TreeSelector'
import { Dimension, DimensionLabel } from '@/modules/Registry'
import { Chip } from '@/components/Chip'
import { useTheme } from '@/theme'

import DimensionLabelNode from './DimensionLabelNode'
import { DimensionSelection } from './types'

type Props = {
  data: Dimension
  documentCounts: {
    [key: string]: number
  }
  hideCounts?: boolean
  hideEmpty?: boolean
  openInitially?: boolean
  selection: DimensionSelection
  toggleDimensionLabel: (dimension: Dimension, label: DimensionLabel) => void
}

const DimensionNode: FC<Props> = ({
  data,
  documentCounts,
  hideCounts,
  hideEmpty,
  openInitially,
  selection,
  toggleDimensionLabel,
}): ReactElement | null => {
  const { spacing } = useTheme()

  const { id, labels, name } = data

  const [isOpen, setIsOpen] = useState<boolean>(!!openInitially)

  const selectionCount = (selection[name] || []).length

  if (hideEmpty && !labels.length) {
    return null
  }

  return (
    <>
      <TreeRow>
        <ExpansionButton
          isExpanded={isOpen}
          onClick={() => setIsOpen((x) => !x)}
        >
          <TreeRowLabel>
            {name}
            {!!selectionCount && (
              <Chip
                color="primary"
                size="xs"
                style={{ marginLeft: `${spacing.gu(1)}rem` }}
              >
                {selectionCount}
              </Chip>
            )}
          </TreeRowLabel>
          {!hideCounts && <CountLabel>{documentCounts[id] || 0}</CountLabel>}
        </ExpansionButton>
      </TreeRow>

      {isOpen &&
        labels.map((label, idx) => (
          <DimensionLabelNode
            data={label}
            documentCounts={documentCounts}
            hideCounts={hideCounts}
            isLast={labels.length === idx + 1}
            isSelected={!!selection[name]?.includes(label.name)}
            key={`dimension-label-node-${label.id}`}
            toggle={() => toggleDimensionLabel(data, label)}
          />
        ))}
    </>
  )
}

export default DimensionNode
