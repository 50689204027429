import { gql, useMutation } from '@apollo/client'

import {
  SetVstSignerMutation,
  SetVstSignerMutationVariables,
} from '~generated-types'

export type { SetVstSignerMutation, SetVstSignerMutationVariables }

export const SET_VST_SIGNER = gql`
  mutation SetVstSigner($input: SalesVstSetSignerInput!) {
    salesVstSetSigner(input: $input) {
      firstName
      id
      lastName
      sortOrder
    }
  }
`

export function useSetVstSignerMutation() {
  return useMutation<SetVstSignerMutation, SetVstSignerMutationVariables>(
    SET_VST_SIGNER
  )
}

export default useSetVstSignerMutation
