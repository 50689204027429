import React, { ElementRef, ReactNode, useState } from 'react'

import { ModalContainer } from '@/components/Modal'
import { Placement } from '@/components/PopperWithShadow'

import {
  DateRange,
  default as DateRangePicker,
  DateRangePickerProps,
} from './DateRangePicker'
import ControlModal from './components/ControlModal'

type Props = DateRangePickerProps & {
  clearable?: boolean
  maxWeekRange?: number
  onClose?: (...args: Array<any>) => any
  placement?: Placement
  render: (arg0: {
    closeModal: (...args: Array<any>) => any
    openModal: (...args: Array<any>) => any
    range: DateRange | null | undefined
    ref: ElementRef<any>
    toggleModal: (...args: Array<any>) => any
  }) => ReactNode
}

const DateRangeControl = ({
  clearable,
  onClose,
  placement,
  render,
  ...dateRangePickerProps
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={open}
      modal={
        <ControlModal
          hasValue={!!dateRangePickerProps.value}
          onClear={clearable ? () => dateRangePickerProps.setValue(null) : null}
        >
          <DateRangePicker fromModal {...dateRangePickerProps} />
        </ControlModal>
      }
      onClose={() => {
        setOpen(false)
        onClose && onClose()
      }}
      placement={placement}
      referenceElement={({ ref }) =>
        render({
          closeModal: () => setOpen(false),
          openModal: () => setOpen(true),
          range: dateRangePickerProps.value,
          ref,
          toggleModal: () =>
            setOpen((current) => {
              if (current) {
                onClose && onClose()
              }

              return !current
            }),
        })
      }
    />
  )
}

export default DateRangeControl
