import styled, { css } from 'styled-components/macro'

import { FlexColumn } from '@/components/Layout'

import { OrderContent, OrderHeader } from './components'
import { OrderType } from './types'
import { OrderViewProvider } from './contexts'

type Props = {
  order: OrderType
}

export const Order = ({ order }: Props) => (
  <Wrapper noPadding>
    <OrderViewProvider>
      <OrderHeader order={order} />
      <OrderContent order={order} />
    </OrderViewProvider>
  </Wrapper>
)

const Wrapper = styled(FlexColumn)`
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    background: ${theme.palette.white};
  `}
`
