import styled, { css } from 'styled-components/macro'
import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { EditButton } from '@/components/ExtraButtons'
import { FlexColumn } from '@/components/Layout'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { WebshopSettings } from '../../../../types'

type EventSettings = NonNullable<NonNullable<WebshopSettings>['eventSettings']>
type EventSettingsLink = EventSettings['link']

type Props = {
  link: EventSettingsLink
}

export const WebshopLink = ({ link }: Props) => {
  const { palette } = useTheme()
  const [copied, setCopied] = useState<boolean>(false)

  const resetRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const handleCopyUrl = (url: EventSettings['link']) => {
    navigator.clipboard.writeText(url)
    setCopied(true)
    resetRef.current = setTimeout(() => setCopied(false), 4000)
  }

  useEffect(() => {
    return () => {
      if (resetRef.current) clearTimeout(resetRef.current)
    }
  }, [resetRef])

  return (
    <Section noPadding>
      <Label>
        <T>SalesDetails:eventSettings.eventLink</T>
      </Label>

      <EditButton
        onClick={() => handleCopyUrl(link)}
        customIcon={
          <FontAwesomeIcon
            icon={!copied ? 'copy' : 'check'}
            color={!copied ? palette.text.lighter : palette.success.main}
            size="sm"
          />
        }
      >
        <LinkWrapper>
          <LinkTruncate>{link}</LinkTruncate>
        </LinkWrapper>
      </EditButton>
    </Section>
  )
}

////////////

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
  `}
`

const LinkWrapper = styled.span`
  display: grid;
  flex: auto;
`

const LinkTruncate = styled.span`
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`
