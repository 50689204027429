import { ChangeEvent, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  Header,
  HeaderControls,
  HideFromPrintSpan,
  PrintTitle,
} from '@/modules/Listing/common'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { Gutter } from '@/components/Layout'
import { Label } from '@/components/FormControls'
import { ListControls } from '@/components/List'
import PrintButton from '@/components/PrintButton'
import { useTheme } from '@/theme'

import { PrintSize } from '../types'

interface Props {
  renderContent: (printSize: PrintSize) => ReactNode
  renderHeaderControls?: () => ReactNode
  renderSidebar: () => ReactNode
  sidebarWidth?: string
  title: ReactNode | string
  type?: string
}

const DEFAULT_SIDEBAR_WIDTH = '300px'

export const ListingLayout = ({
  renderContent,
  renderHeaderControls,
  renderSidebar,
  sidebarWidth = DEFAULT_SIDEBAR_WIDTH,
  title,
  type,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [printSize, setPrintSize] = useState<PrintSize>(PrintSize.Default)

  return (
    <Gutter ignoreInPrint type={[0, 5, 10]}>
      <Wrapper>
        <HeaderWrapper sidebarWidth={sidebarWidth}>
          {type === 'VERTICAL' ? (
            <FlexColumn>
              <Header>
                <PrintTitle>{title}</PrintTitle>
              </Header>

              <HideFromPrintSpan style={{ margin: theme.spacing.gutter }} />

              <FlexRow>
                {renderHeaderControls && (
                  <FlexRow>{renderHeaderControls()}</FlexRow>
                )}
                <ControlsWrapper>
                  <SelectWrapper>
                    <Label style={{ display: 'block' }}>
                      <T>Listings:printSize.title</T>
                    </Label>
                    <ThemedSelect
                      isCompact
                      name="print-size-selector"
                      onChange={(option?: Option | null) =>
                        option && setPrintSize(option.value as PrintSize)
                      }
                      options={[
                        {
                          label: translate(
                            `Listings:printSize.${PrintSize.Compact}`,
                            language
                          ),
                          value: PrintSize.Compact,
                        },
                        {
                          label: translate(
                            `Listings:printSize.${PrintSize.Default}`,
                            language
                          ),
                          value: PrintSize.Default,
                        },
                        {
                          label: translate(
                            `Listings:printSize.${PrintSize.Large}`,
                            language
                          ),
                          value: PrintSize.Large,
                        },
                      ]}
                      placeholder=""
                      value={{
                        label: translate(
                          `Listings:printSize.${printSize}`,
                          language
                        ),
                        value: printSize,
                      }}
                    />
                  </SelectWrapper>

                  <HideFromPrintSpan>
                    <Label
                      style={{
                        display: 'block',
                        height: `${theme.spacing.gu(2.5)}rem`,
                      }}
                    />
                    <PrintButton />
                  </HideFromPrintSpan>
                </ControlsWrapper>
              </FlexRow>

              <HideFromPrintSpan style={{ margin: theme.spacing.gutter }} />
            </FlexColumn>
          ) : (
            <FlexRow alignItems="center" justifyContent="space-between">
              <Header>
                <PrintTitle>{title}</PrintTitle>
              </Header>
              {renderHeaderControls && (
                <FlexRow flex={1}>{renderHeaderControls()}</FlexRow>
              )}

              <HeaderControls justifyContent="flex-end">
                <FlexColumn alignItems="flex-start">
                  <Label>
                    <T>Listings:printSize.title</T>
                  </Label>
                  <select
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      if (e.target.value === PrintSize.Compact) {
                        setPrintSize(PrintSize.Compact)
                      }
                      if (e.target.value === PrintSize.Default) {
                        setPrintSize(PrintSize.Default)
                      }
                      if (e.target.value === PrintSize.Large) {
                        setPrintSize(PrintSize.Large)
                      }
                    }}
                    value={printSize}
                  >
                    <option value={PrintSize.Compact}>
                      {translate(
                        `Listings:printSize.${PrintSize.Compact}`,
                        language
                      )}
                    </option>
                    <option value={PrintSize.Default}>
                      {translate(
                        `Listings:printSize.${PrintSize.Default}`,
                        language
                      )}
                    </option>
                    <option value={PrintSize.Large}>
                      {translate(
                        `Listings:printSize.${PrintSize.Large}`,
                        language
                      )}
                    </option>
                  </select>
                </FlexColumn>

                <HideFromPrintSpan style={{ margin: theme.spacing.gutter }} />

                <PrintButton />
              </HeaderControls>
            </FlexRow>
          )}
        </HeaderWrapper>

        <ContentWrapper>
          <SidebarWrapper width={sidebarWidth}>
            {renderSidebar()}
          </SidebarWrapper>
          {renderContent(printSize)}
        </ContentWrapper>
      </Wrapper>
    </Gutter>
  )
}

////////////

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const HeaderWrapper = styled(ListControls)<{ sidebarWidth: string }>`
  flex: 0 0 auto;
  page-break-before: avoid;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
  margin-left: ${({ sidebarWidth }) => sidebarWidth};

  @media print {
    margin-left: 0;
  }
`

const SidebarWrapper = styled(FlexColumn)<{ width: string }>`
  flex: 0 0
    calc(${({ width }) => width} - ${({ theme }) => theme.spacing.gutter});
  align-self: flex-start;
  align-items: stretch;
  border-radius: 8px;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border: solid 1px ${theme.palette.smoke.dark};
    margin-right: ${theme.spacing.gutter};
    padding: ${theme.spacing.gutter};
  `}

  @media print {
    display: none;
  }
`

const Wrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 0;
  overflow: visible;

  @media print {
    max-width: 100%;
    padding: 0;
  }
`

const SelectWrapper = styled.span`
  width: ${({ theme }) => theme.spacing.gu(15)}rem;
  margin-right: ${({ theme }) => theme.spacing.gu(2)}rem;

  @media print {
    display: none;
  }
`

const ControlsWrapper = styled(FlexRow)`
  flex: 1;

  @media print {
    margin: ${({ theme }) => theme.spacing.gu(2)}rem 0
      ${({ theme }) => theme.spacing.gu(1.5)}rem 0;
  }
`
