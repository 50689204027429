import { gql } from '@apollo/client'

import { salesFragments } from '@/modules/Sales'

export default gql`
  ${salesFragments.salesAssignee}

  query SalesAssigners {
    users {
      ...SalesAssignee
    }
  }
`
