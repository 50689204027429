import moment, { Moment } from 'moment'
import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { CheckInType, CheckOutType } from '~generated-types'
import { CheckInTypes, CheckOutTypes } from '@/common/enums'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal, InlineModalFooter } from '@/components/InlineModal'
import { CheckboxInput } from '@/components/FormControls'
import { DateRangePicker } from '@/components/TimeControls'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { CheckIn, CheckOut } from '../../../SalesReservationList.types'

type DatesType = {
  checkIn: {
    date: string
    type: CheckInType
  }
  checkOut: {
    date: string
    type: CheckOutType
  }
}

type Props = {
  iconWrapper: React.ComponentType
  checkIn: CheckIn
  checkOut: CheckOut
  handleUpdateDates: (dates: {
    checkInType: CheckInType
    checkOutType: CheckOutType
    range: { from: Moment; to: Moment }
  }) => Promise<void>
}

export const VisitDates = ({
  iconWrapper: IconWrapper,
  checkIn,
  checkOut,
  handleUpdateDates,
}: Props) => {
  const theme = useTheme()

  const [isModalOpen, setModalOpen] = useState(false)
  const [dates, setDates] = useState<DatesType>({
    checkIn: {
      date: checkIn.date,
      type: checkIn.type,
    },
    checkOut: {
      date: checkOut.date,
      type: checkOut.type,
    },
  })

  const isEarlyCheckIn = dates?.checkIn.type === CheckInType.Early
  const isLateCheckOut = dates?.checkOut.type === CheckOutType.Late

  const isSameYear = moment(checkIn.date).isSame(moment(checkOut.date), 'year')

  const checkInDate = moment(checkIn.date).format(
    isSameYear ? 'DD.MM' : 'DD.MM.YY'
  )
  const checkOutDate = moment(checkOut.date).format('DD.MM.YY')

  const updateDates = ({
    checkIn,
    checkOut,
    checkInType,
    checkOutType,
  }: {
    checkIn: string
    checkOut: string
    checkInType?: CheckInType
    checkOutType?: CheckOutType
  }) =>
    setDates({
      checkIn: {
        date: checkIn,
        type: checkInType || dates?.checkIn.type || CheckInType.Standard,
      },
      checkOut: {
        date: checkOut,
        type: checkOutType || dates?.checkOut.type || CheckOutType.Standard,
      },
    })

  const onDatesSubmit = ({ checkIn, checkOut }: DatesType) => {
    handleUpdateDates({
      checkInType: checkIn.type,
      checkOutType: checkOut.type,
      range: {
        from: moment(checkIn.date),
        to: moment(checkOut.date),
      },
    })
  }

  useEffect(() => {
    setDates({
      checkIn: {
        date: checkIn.date,
        type: checkIn.type,
      },
      checkOut: {
        date: checkOut.date,
        type: checkOut.type,
      },
    })
  }, [checkIn, checkOut])

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <InlineModal>
          <DateRangePicker
            setValue={(newRange) => {
              newRange &&
                updateDates({
                  checkIn: newRange.from.format('YYYY-MM-DD'),
                  checkOut: newRange.to.format('YYYY-MM-DD'),
                })
            }}
            value={
              dates
                ? {
                    from: moment(dates.checkIn.date),
                    to: moment(dates.checkOut.date),
                  }
                : null
            }
          />
          <InlineModalFooter>
            <CheckInOutWrapper flex={1}>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkIn</T>
                </Title>
                <CheckboxInput
                  checked={isEarlyCheckIn}
                  noMargin
                  onChange={() =>
                    dates &&
                    updateDates({
                      checkIn: dates.checkIn.date,
                      checkInType: isEarlyCheckIn
                        ? CheckInType.Standard
                        : CheckInType.Early,
                      checkOut: dates.checkOut.date,
                    })
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckInType.Early}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
              <FlexColumn flex={1} noPadding>
                <Title>
                  <T>Accommodation:SalesReservationManager.checkOut</T>
                </Title>
                <CheckboxInput
                  checked={isLateCheckOut}
                  noMargin
                  onChange={() =>
                    dates &&
                    updateDates({
                      checkIn: dates.checkIn.date,
                      checkOut: dates.checkOut.date,
                      checkOutType: isLateCheckOut
                        ? CheckOutType.Standard
                        : CheckOutType.Late,
                    })
                  }
                >
                  <CheckboxLabel>
                    <T>{`enums:checkInOut.${CheckOutType.Late}`}</T>
                  </CheckboxLabel>
                </CheckboxInput>
              </FlexColumn>
            </CheckInOutWrapper>
          </InlineModalFooter>
        </InlineModal>
      }
      onClose={() => {
        dates && onDatesSubmit(dates)
        setModalOpen(false)
      }}
      referenceElement={({ ref }) => (
        <Wrapper ref={ref} onClick={() => setModalOpen(true)}>
          <IconWrapper>
            {checkIn.type === CheckInTypes.EARLY && (
              <FontAwesomeIcon
                icon="sun"
                color={theme.palette.primary.dark}
                size="sm"
              />
            )}
          </IconWrapper>

          <Dates>{`${checkInDate} – ${checkOutDate}`}</Dates>

          <IconWrapper>
            {checkOut.type === CheckOutTypes.LATE && (
              <FontAwesomeIcon
                icon="moon"
                color={theme.palette.primary.dark}
                size="xs"
              />
            )}
          </IconWrapper>
        </Wrapper>
      )}
    />
  )
}

const Wrapper = styled(FlexRow)`
  transition: 0.2s;
  height: 100%;

  ${({ theme }) => css`
    &:hover {
      cursor: pointer;
      background-color: ${theme.palette.smoke.light};
    }
  `}
`

const Dates = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(18)}rem;
  `}
  text-align: center;
`

const CheckboxLabel = styled.span`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const CheckInOutWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
