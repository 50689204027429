import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { DataTableInput } from '@/components/DataTable'
import { useCatalogProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  disabled: boolean
  id: string
  vatRate: number
}

export const MarginalVatInput = ({ disabled, id, vatRate }: Props) => {
  const { updateProductItem } = useCatalogProductManagerContext()

  const [value, setValue] = useState<string>(`${vatRate}`)

  const handleUpdateVatRate = () => {
    const vatRateValue = Number(value) || 0

    const validatedVatRateValue =
      vatRateValue > 100 ? 100 : vatRateValue < 0 ? 0 : vatRateValue

    setValue(`${validatedVatRateValue}`)

    return updateProductItem({
      id,
      marginalTax: { marginalTaxRate: validatedVatRateValue },
    }).then((res) => !res && setValue(`${vatRate}`))
  }

  return (
    <Input
      disabled={disabled}
      endAdornment="%"
      flex="unset"
      onBlur={handleUpdateVatRate}
      onChange={(e) => setValue(e.target.value)}
      onFocus={(e) => e.currentTarget.select()}
      showBorder
      type="number"
      value={value}
    />
  )
}

/////////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: end;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0px ${theme.spacing.gu(3)}rem 0px ${theme.spacing.gu(1)}rem;
      width: ${theme.spacing.gu(7.5)}rem;
    `}

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`
