import { ControlProps, OptionTypeBase } from 'react-select'
import { CSSProperties, useState } from 'react'
import styled, { css } from 'styled-components/macro'

import { EducationLevel, ParticipantLifeStage } from '~generated-types'
import {
  FetchedParticipant,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { Input, Label } from '@/components/FormControls'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { FlexColumn } from '@/components/Layout'

import { translate, useLanguageContext } from '@/modules/Language'
import { LanguageSelector } from '../../ParticipantPersonalInfo/modules/components/LanguageSelector'
import { useTheme } from '@/theme'

type Props = {
  participant: FetchedParticipant
}

export const VSTDetailsModal = ({
  participant: {
    id,
    education,
    lifeStage,
    language: participantLanguage,
    birthday,
  },
}: Props) => {
  const { spacing, palette } = useTheme()
  const { language } = useLanguageContext()
  const { handleChangeParticipantData } = useParticipantsListContext()

  const [yearValue, setYearValue] = useState<number | undefined>(birthday?.year)
  const [educationLoading, setEducationLoading] = useState<boolean>(false)
  const [lifeStageLoading, setLifeStageLoading] = useState<boolean>(false)
  const [languageLoading, setLanguageLoading] = useState<boolean>(false)

  const extraStyles = {
    container: (styles: CSSProperties) => ({
      ...styles,
      fontWeight: 400,
      marginBottom: spacing.gutter,
      width: '100%',
    }),
    control: (
      styles: CSSProperties,
      { isFocused }: ControlProps<OptionTypeBase, false>
    ) => ({
      ...styles,
      borderColor: isFocused ? palette.primary.main : palette.smoke.dark,
      cursor: 'pointer',
    }),
    menu: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
    menuList: (styles: CSSProperties) => ({
      ...styles,
      maxHeight: 250,
    }),
  }

  const educationOptions = Object.values(EducationLevel).map(
    (education: EducationLevel) => ({
      label: translate(`enums:education.${education}`, language),
      value: education,
    })
  )

  const lifeStageOptions = Object.values(ParticipantLifeStage).map(
    (lifeStage: ParticipantLifeStage) => ({
      label: translate(`enums:lifeStage.${lifeStage}`, language),
      value: lifeStage,
    })
  )

  const handleUpdateEducation = (value: EducationLevel | null) => {
    setEducationLoading(true)
    handleChangeParticipantData({ education: value }, id).then(() =>
      setEducationLoading(false)
    )
  }

  const handleUpdateLifeStage = (value: ParticipantLifeStage | null) => {
    setLifeStageLoading(true)
    handleChangeParticipantData({ lifeStage: value }, id).then(() =>
      setLifeStageLoading(false)
    )
  }

  const handleUpdateLanguage = (value: string | null) => {
    setLanguageLoading(true)
    handleChangeParticipantData({ language: value }, id).then(() =>
      setLanguageLoading(false)
    )
  }

  const handleUpdateYear = () =>
    handleChangeParticipantData(
      { birthday: yearValue ? { year: yearValue } : null },
      id
    )

  return (
    <ModalContainer>
      <Section>
        <FlexColumn noPadding flex={1}>
          <FlexColumn style={{ width: '100%' }}>
            <Title>
              {translate('ParticipantsList:ListHeader.year', language)}
            </Title>
            <StyledInput
              type="number"
              min="1900"
              max="2099"
              step="1"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setYearValue(parseInt(e.target.value, 10))
              }
              onBlur={handleUpdateYear}
              value={yearValue}
            />
          </FlexColumn>
          <FlexColumn style={{ width: '100%' }}>
            <Title>
              {translate('ParticipantsList:PersonalFields.education', language)}
            </Title>
            <ThemedSelect
              isClearable
              isLoading={educationLoading}
              extraStyles={extraStyles}
              name="education-selector"
              onChange={(selected: Option | null | undefined) =>
                handleUpdateEducation(
                  selected ? (selected.value as EducationLevel) : null
                )
              }
              options={educationOptions}
              placeholder=""
              value={
                education
                  ? educationOptions.find(({ value }) => value === education)
                  : null
              }
            />
          </FlexColumn>
          <LanguageSelector
            isLoading={languageLoading}
            title={translate(
              'ParticipantsList:PersonalFields.language',
              language
            )}
            participantLanguage={participantLanguage || ''}
            handleSetLanguage={handleUpdateLanguage}
          />
          <FlexColumn style={{ width: '100%' }}>
            <Title>
              {translate('ParticipantsList:PersonalFields.lifeStage', language)}
            </Title>
            <ThemedSelect
              isClearable
              isLoading={lifeStageLoading}
              extraStyles={extraStyles}
              name="lifeStage-selector"
              onChange={(selected: Option | null | undefined) =>
                handleUpdateLifeStage(
                  selected ? (selected.value as ParticipantLifeStage) : null
                )
              }
              options={lifeStageOptions}
              placeholder=""
              value={
                lifeStage
                  ? lifeStageOptions.find(({ value }) => value === lifeStage)
                  : null
              }
            />
          </FlexColumn>
        </FlexColumn>
      </Section>
    </ModalContainer>
  )
}

const ModalContainer = styled(InlineModal)`
  max-height: 100vh;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(65)}rem;
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const Section = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter} 0;
  `}
`

const StyledInput = styled(Input)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const Title = styled(Label)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
