import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ReactNode } from 'react'

import { useTheme } from '@/theme'

type Props = {
  children: ReactNode
  icon?: IconProp
}

export const CustomerIconValue = ({ children, icon }: Props) => (
  <IconValueWrapper>
    <IconWrapper>{icon && <Icon icon={icon} />}</IconWrapper>
    {children}
  </IconValueWrapper>
)

////////////

const IconValueWrapper = styled.span`
  display: flex;
  flex: 1;
  align-items: baseline;

  &:not(:first-child) {
    ${({ theme }) => css`
      margin-top: ${theme.spacing.gu(1.5)}rem;
    `}
  }
`

const IconWrapper = styled.span`
  flex: 0 0 16px;
  display: flex;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

type IconProps = { icon: IconProp }

export const Icon = ({ icon }: IconProps) => {
  const { palette } = useTheme()

  return (
    <FontAwesomeIcon
      color={palette.text.lighter}
      fixedWidth
      icon={icon}
      size="xs"
    />
  )
}
