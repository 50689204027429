import { gql, useQuery } from '@apollo/client'

import { AccommodationLevel } from '../types'
import accommodationLevelFragments from '../fragments'
import { AccommodationLevelsQuery as QueryData } from '~generated-types'

export const QUERY = gql`
  ${accommodationLevelFragments.accommodationLevel}

  query AccommodationLevels {
    registry {
      accommodationLevels {
        ...AccommodationLevel
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export default function useAccommodationLevels(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, any>(QUERY, {
    fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
  })

  const accommodationLevels: AccommodationLevel[] = data
    ? data.registry.accommodationLevels
    : []

  return {
    accommodationLevels,
    error,
    loading,
    refetch,
  }
}
