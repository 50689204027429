import styled, { css } from 'styled-components/macro'
import { darken } from 'polished'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect } from 'react'

import { DisplayOption, IssueLevel } from '~generated-types'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { getIssueColor, useTheme } from '@/theme'
import {
  Reservation as ReservationType,
  resourceReservationHooks,
  SalesResourceReservation,
} from '@/modules/Reservations/ResourceReservation'
import { translate, useLanguageContext } from '@/modules/Language'
import { CheckboxInput } from '@/components/FormControls'
import { DataFieldColorCircle } from '@/components/DataField'
import { ModalContainer } from '@/components/Modal'
import { ReservationManager } from '@/modules/Reservations'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import { Name, Separator, TimeInterval } from './common'
import { CustomerVisibilityToggle } from './CustomerVisibilityToggle'
import { DisplayMessageNotification } from './DisplayMessageNotification'
import { ReservationPurchase } from './ReservationPurchase'
import { ReservationTask } from './ReservationTask'
import { ResourceReservationGroup } from '../types'

type Props = {
  group: ResourceReservationGroup | null
  isModalOpen: boolean
  reservation: ReservationType
  readOnly: boolean
  isMovingProcess?: boolean
  idsToMove: string[]
  setIdsToMove: (ids: string[]) => void
  setReservationModalId: (id: string | null) => void
  setReservations: (reservations: ReservationType[]) => void
}

export const Reservation = ({
  group,
  isModalOpen,
  reservation,
  readOnly,
  isMovingProcess,
  idsToMove,
  setIdsToMove,
  setReservationModalId,
  setReservations,
}: Props) => {
  const { spacing, palette } = useTheme()
  const { language } = useLanguageContext()

  const {
    color,
    customerVisibility,
    description,
    dimensions,
    end,
    id,
    internalNote,
    purchaseProducts,
    resource,
    displayMessage,
    resourceQuantity,
    start,
    tasks,
    issues,
  } = reservation as SalesResourceReservation

  const { resourceConfig } = resourceReservationHooks.useResourceConfig()
  const { setDisplayMessage } =
    resourceReservationHooks.useResourceReservationMutations({
      updateReservations: setReservations,
    })

  useEffect(() => {
    if (
      displayMessage &&
      resourceConfig?.displayOption.roomMessageDisplayOption ===
        DisplayOption.Hidden
    ) {
      setDisplayMessage(reservation.id, null)
    }
  }, [resourceConfig, displayMessage])

  const isSelected = !!idsToMove.find((selectedId) => selectedId === id)

  const issueLevel = issues.reduce((acc: IssueLevel | null, { level }) => {
    if (level === IssueLevel.Error) {
      return level
    }

    if (level === IssueLevel.Warning && !acc) {
      return level
    }

    return acc
  }, null)

  const handleSelect = () => {
    setReservationModalId(null)
    setIdsToMove(
      isSelected
        ? idsToMove.filter((selectedId) => selectedId !== id)
        : [...idsToMove, id]
    )
  }

  return (
    <ModalContainer
      isOpen={isModalOpen}
      modal={
        <ReservationManager
          context="SALES"
          group={getGroupWithoutReservations(group)}
          onClose={() => setReservationModalId(null)}
          readOnly={readOnly}
          reservation={reservation}
          updateReservations={setReservations}
        />
      }
      onClose={() => setReservationModalId(null)}
      placement="bottom"
      referenceElement={({ ref }) => (
        <Wrapper
          isOpen={isModalOpen}
          background={
            issueLevel && getIssueColor(issueLevel, palette).background
          }
          noPadding
          onClick={() => setReservationModalId(id)}
          ref={ref}
        >
          <FlexRow style={{ width: '100%' }} flex={1} alignItems="center">
            {isMovingProcess && (
              <CheckboxInput
                noMargin
                checked={isSelected}
                onChange={handleSelect}
              />
            )}
            <ReservationRowWrapper
              className="reservation-row"
              flex={1}
              noMarginLeft={!isMovingProcess}
              withTasks={!!tasks.length}
            >
              <ReservationRow alignItems="center" flex={1}>
                <ColorWrapper>
                  <DataFieldColorCircle color={color || ''} />
                </ColorWrapper>

                <Name
                  customMargin={`0 ${spacing.gu(1)}rem 0 ${spacing.gu(2)}rem`}
                  width={20}
                >
                  {resource?.isPooled &&
                    resourceQuantity > 1 &&
                    `${resourceQuantity} x `}
                  {resource?.name ||
                    translate(
                      'ResourceReservations:resource.program',
                      language
                    )}
                </Name>

                {resource?.internalInfo ? (
                  <NoteIcon icon={['far', 'clipboard']} />
                ) : (
                  <NoteIconPlaceholder />
                )}

                <Separator />

                <TimeInterval>
                  {getTimeInterval(start, end, group?.start || null)}
                </TimeInterval>

                <Separator />

                <Name compactWidth={10} width={14}>
                  {dimensions?.selection[0]?.selectedLabel?.name}
                </Name>

                <Separator />

                <Name compactWidth={16} width={24}>
                  {description}
                </Name>

                {internalNote ? (
                  <NoteIcon icon={['far', 'note-sticky']} />
                ) : (
                  <NoteIconPlaceholder />
                )}

                <Separator />

                <ReservationPurchase
                  isPooled={!!resource?.isPooled}
                  purchase={purchaseProducts[0]}
                />

                <span style={{ flex: 1 }} />

                {!!issues?.length && (
                  <>
                    <SpacingWrapper>
                      {issues.map(({ code, key, level }) => (
                        <ValidationIndicator
                          code={code}
                          key={key}
                          level={level}
                          style={{ marginRight: 0 }}
                        />
                      ))}
                    </SpacingWrapper>
                    <Separator />
                  </>
                )}

                {displayMessage &&
                  resourceConfig?.displayOption.roomMessageDisplayOption !==
                    DisplayOption.Hidden && (
                    <>
                      <SpacingWrapper>
                        <DisplayMessageNotification message={displayMessage} />
                      </SpacingWrapper>
                      <Separator />
                    </>
                  )}

                <SpacingWrapper noMarginRight>
                  <CustomerVisibilityToggle
                    customerVisibility={customerVisibility}
                    disabled={readOnly}
                    reservationId={id}
                    resource={resource}
                    setReservations={setReservations}
                  />
                </SpacingWrapper>
              </ReservationRow>
            </ReservationRowWrapper>
          </FlexRow>

          {[...tasks]
            .sort((a, b) => {
              const toDate = (date: string, time: string | null) =>
                new Date(time ? `${date}T${time}` : date)

              if (a.dueDate && b.dueDate) {
                return (
                  moment(toDate(a.dueDate, a.dueTime)).valueOf() -
                  moment(toDate(b.dueDate, b.dueTime)).valueOf()
                )
              }

              return -1
            })
            .map((task, index: number) => (
              <ReservationTask
                isLast={index === tasks.length - 1}
                key={task.id}
                reservationStartDate={start}
                task={task}
                isMovingProcess={isMovingProcess}
              />
            ))}
        </Wrapper>
      )}
      styleOverrides={{
        left: 'unset',
        right: 0,
        transform: 'none',
      }}
    />
  )
}

////////////

const getGroupWithoutReservations = (
  group: ResourceReservationGroup | null
) => {
  if (group) {
    const { reservations, ...newGroup } = group
    return newGroup
  } else {
    return null
  }
}

const getTimeInterval = (
  start: string,
  end: string,
  groupStart: string | null
) => {
  const date = moment(start).format('D.M')
  const endTime = moment(end).format('HH:mm')
  const startTime = moment(start).format('HH:mm')

  const isSameDate = moment(start).isSame(groupStart, 'day')
  const isSameTime = endTime === startTime

  const timelabel = isSameTime ? startTime : `${startTime} – ${endTime}`

  if (!groupStart || isSameDate) {
    return timelabel
  }

  return (
    <span>
      {timelabel} <ExtraDateLabel>({date})</ExtraDateLabel>
    </span>
  )
}

const ColorWrapper = styled.div`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const ExtraDateLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.danger.dark};
    font-size: ${theme.typography.fontSizeSmall};
  `}
`

const NoteIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.warning.dark};
    margin-right: ${theme.spacing.gu(2)}rem;
    width: ${theme.spacing.gu(2)}rem;
  `}
`

const NoteIconPlaceholder = styled.div`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(4)}rem;
  `}
`

const ReservationRowWrapper = styled(FlexRow)<{
  withTasks?: boolean
  noMarginLeft?: boolean
}>`
  border-radius: 6px;
  font-weight: 500;

  ${({ theme, withTasks, noMarginLeft }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    border-bottom-right-radius: ${withTasks ? 0 : 6}px;
    padding: ${theme.spacing.gu(1)}rem;
    margin-left: ${noMarginLeft ? 0 : theme.spacing.gu(2)}rem;
  `}
`

const ReservationRow = styled(FlexRow)`
  height: 16px;
`

const Wrapper = styled(FlexColumn)<{
  background: string | null
  isOpen: boolean
}>`
  cursor: pointer;
  min-width: 900px;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutter} ${theme.spacing.gu(1)}rem
      ${theme.spacing.gutter};
  `}

  .reservation-row {
    transition: 0.2s;

    ${({ background, theme }) => css`
      background: ${background ?? theme.palette.white};
    `}

    ${({ isOpen, theme }) =>
      isOpen &&
      `
        border-color: ${theme.palette.primary.dark};
        box-shadow: 0 0 2px 0 ${theme.palette.primary.dark};
      `}
  }

  .task-row {
    transition: 0.2s;
    ${({ isOpen, theme }) =>
      isOpen &&
      `
        border-color: ${theme.palette.primary.dark};
        box-shadow: 0 0 2px 0 ${theme.palette.primary.dark};
      `}
  }

  &:hover {
    .reservation-row {
      ${({ background, theme }) => css`
        background: ${background
          ? darken(0.03, background)
          : theme.palette.smoke.lighter};
      `}
    }
    .task-row {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const SpacingWrapper = styled.div<{ noMarginRight?: boolean }>`
  ${({ noMarginRight, theme }) => css`
    margin-left: ${theme.spacing.gu(1.5)}rem;
    margin-right: ${theme.spacing.gu(noMarginRight ? 0 : 1.5)}rem;
  `}
`
