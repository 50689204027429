import { gql, useMutation } from '@apollo/client'

import {
  DownloadDocumentMutation,
  DownloadDocumentMutationVariables,
} from '~generated-types'

export type { DownloadDocumentMutation, DownloadDocumentMutationVariables }

export const DOWNLOAD_DOCUMENT = gql`
  mutation DownloadDocument($input: DocumentDownloadInput!) {
    documentDownload(input: $input) {
      id
    }
  }
`

export function useDownloadDocumentMutation() {
  return useMutation<
    DownloadDocumentMutation,
    DownloadDocumentMutationVariables
  >(DOWNLOAD_DOCUMENT)
}

export default useDownloadDocumentMutation
