import { CSSProperties, ReactNode, Ref } from 'react'
import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import { Button, ButtonProps } from '@/components/Button'
import { useTheme } from '@/theme'

type Props = ButtonProps & {
  busy?: boolean
  children: ReactNode
  compact?: boolean
  disabled?: boolean
  innerRef?: Ref<HTMLButtonElement>
  style?: CSSProperties
}

export const InnocuousButton = ({
  busy,
  children,
  disabled,
  innerRef,
  ...props
}: Props) => {
  const theme = useTheme()

  return (
    <StyledInnocuousButton
      {...props}
      busy={busy}
      disabled={busy || disabled}
      ref={innerRef}
    >
      {busy ? (
        <ReactLoading
          color={theme.palette.coal.main}
          height={14}
          type={'spin'}
          width={14}
        />
      ) : (
        children
      )}
    </StyledInnocuousButton>
  )
}

export const StyledInnocuousButton = styled(Button)<{
  busy?: boolean
  compact?: boolean
}>`
  text-transform: uppercase;

  border-radius: 4px;
  background: transparent;
  border: none;

  justify-content: center;
  transition: min-width 300 ease;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem;
  `}

  ${({ busy, theme }) =>
    busy
      ? css`
          background: ${theme.palette.smoke.light};
          min-width: 120px;
        `
      : ''}

  ${({ compact }) =>
    compact
      ? css`
          height: auto;
        `
      : ''}

  &:not(:disabled):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:not(:disabled):focus {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`
