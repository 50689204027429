import styled, { css } from 'styled-components/macro'
import { useState } from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal, InlineModalSection } from '@/components/InlineModal'
import { translate, useLanguageContext } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'

import { AddressSection } from './components/AddressSection'
import { CountrySelector } from './components/CountrySelector'

type AddressType = {
  address1: string | null
  address2: string | null
  city: string | null
  postcode: string | null
  country: string | null
}

type Props = {
  address?: FetchedParticipant['postalAddress']
  handleUpdateAddress: (address: AddressType) => Promise<void>
}

export const CompactAddressModal = ({
  address,
  handleUpdateAddress,
}: Props) => {
  const { language } = useLanguageContext()
  const { address1, address2, city, postcode, country } = address || {}

  const [addressState, setAddress] = useState<AddressType>({
    address1: address1 || null,
    address2: address2 || null,
    city: city || null,
    country: country || null,
    postcode: postcode || null,
  })

  const onAddressChange = (addressField: Partial<AddressType>) =>
    setAddress({ ...addressState, ...addressField })

  const onSubmit = () => handleUpdateAddress(addressState)

  const handleSetCountry = (country: string) => {
    onAddressChange({ country })
    handleUpdateAddress({ ...addressState, country })
  }

  return (
    <InlineModal>
      <Section>
        <FlexColumn noPadding flex={1}>
          <CountrySelector
            countryName={addressState?.country}
            handleSetCountry={handleSetCountry}
            title={translate('Customers:address.country', language)}
          />
          <AddressSection
            onChange={(address1) => onAddressChange({ address1 })}
            onSubmit={onSubmit}
            title={translate('Customers:address.address', language)}
            type="textarea"
            value={addressState?.address1 || ''}
          />
          <AddressSection
            onChange={(address2) => onAddressChange({ address2 })}
            onSubmit={onSubmit}
            title={translate('Customers:address.address2', language)}
            type="textarea"
            value={addressState?.address2 || ''}
          />
          <FlexRow>
            <AddressSection
              flex={2}
              onChange={(postcode) => onAddressChange({ postcode })}
              onSubmit={onSubmit}
              title={translate('Customers:address.postcode', language)}
              value={addressState?.postcode || ''}
            />
            <Spacer />
            <AddressSection
              flex={3}
              title={translate('Customers:address.city', language)}
              value={addressState?.city || ''}
              onChange={(city) => onAddressChange({ city })}
              onSubmit={onSubmit}
            />
          </FlexRow>
        </FlexColumn>
      </Section>
    </InlineModal>
  )
}

const Section = styled(InlineModalSection)`
  margin: 0;

  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter} 0;
    width: ${theme.spacing.gu(48)}rem;
  `}
`

const Spacer = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`
