import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { TaskReservation, TaskReservationGroup } from '@/modules/Task'
import { InlineModalIconSection } from '@/components/InlineModal'
import { T } from '@/modules/Language'

import { EditButton } from './common'

type Props = {
  ownerId: string
  reservation: TaskReservation
}

export const Reservation = ({
  ownerId,
  reservation: { description, end, id, group, resource, start },
}: Props) => {
  const openReservationInNewTab = () => {
    const url = `/sales/details/${ownerId}?view[0]=reservations&targetReservationId=${id}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <Wrapper icon="info">
      <EditButton
        onClick={openReservationInNewTab}
        style={{ alignItems: group || description ? 'flex-start' : 'center' }}
      >
        <FlexColumn alignItems="flex-start" flex={1} noPadding>
          <ResourceWrapper alignItems="center" justifyContent="space-between">
            <Resource>
              {resource?.name ?? <T>ResourceReservations:resource.program</T>}
            </Resource>

            <DateTimeWrapper>
              <Icon fixedWidth icon={['far', 'calendar']} size="sm" />
              {moment(start).format('dd, D.M.YY')}
              <Icon fixedWidth icon={['far', 'clock']} size="sm" />
              {moment(start).format('HH:mm')} – {moment(end).format('HH:mm')}
            </DateTimeWrapper>
          </ResourceWrapper>

          {group && (
            <Details>
              <LightIcon fixedWidth icon="user-group" size="xs" />
              {getGroupName(group)}
            </Details>
          )}

          {description && (
            <Details>
              <LightIcon fixedWidth icon="align-left" size="xs" />
              {description}
            </Details>
          )}
        </FlexColumn>

        <ExternalLinkIcon icon="up-right-from-square" />
      </EditButton>
    </Wrapper>
  )
}

////////////

const getGroupName = ({ name, number }: TaskReservationGroup) => (
  <>
    {`#${number}`} {name || <T>ReservationModal:group.title</T>}
  </>
)

const DateTimeWrapper = styled.div`
  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    color: ${theme.palette.text.light};
  `}
`

const Details = styled.span`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gutterSmall};
  `}
`

const ExternalLinkIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
    margin: 0 ${theme.spacing.gutterSmall} 0 ${theme.spacing.gutter};
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gutterSmall} 0 ${theme.spacing.gutter};
  `}
`

const LightIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    color: ${theme.palette.text.lighter};
  `}
`

const Resource = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const ResourceWrapper = styled(FlexRow)`
  width: 100%;
`

const Wrapper = styled(InlineModalIconSection)`
  ${({ theme }) => css`
    padding-top: ${theme.spacing.gu(0.5)}rem;
  `}
`
