import { ButtonGroup, ButtonGroupButton } from '@/components/ExtraButtons'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'

import { NewMealButton } from './NewMealButton'
import { useMealsContext } from '../MealsState'

export const MealsControls = () => {
  const { saleReadOnly } = useSalesDetailsContext()
  const { isEditMode, setEditMode } = useMealsContext()

  return (
    <FlexRow>
      <ButtonGroup>
        <ButtonGroupButton
          noNudge
          disabled={saleReadOnly}
          size="small"
          onClick={() => setEditMode(false)}
          selected={!isEditMode}
        >
          <T>SalesDetails:meals.action.view</T>
        </ButtonGroupButton>
        <ButtonGroupButton
          noNudge
          disabled={saleReadOnly}
          size="small"
          onClick={() => setEditMode(true)}
          selected={isEditMode}
        >
          <T>common:action.edit</T>
        </ButtonGroupButton>
      </ButtonGroup>

      <NewMealButton />
    </FlexRow>
  )
}
