import { FC, ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'

import { SalesForDateType } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ColorVariant, getVisitTypeColor } from '../utils'
import { CountIcons } from './CountIcons'
import { ListItemColumn } from './ListItemColumn'

export const CustomerInfoHeader: FC<EmptyObject> = (): ReactElement => {
  const theme = useTheme()

  const renderCountsColumn = (type: SalesForDateType) => {
    if (type === SalesForDateType.DayVisitor) {
      return null
    }

    return (
      <Column bigMargin width="120px">
        <Label
          align="center"
          color={getVisitTypeColor(type, ColorVariant.DARK, theme)}
        >
          <T l10n={`FrontDesk:DailyCustomerList.visitType.${type}`} />
        </Label>
        <CountIcons
          color={getVisitTypeColor(type, ColorVariant.DEFAULT, theme)}
        />
      </Column>
    )
  }

  return (
    <>
      <Column width="40px" />
      <Column>
        <Label>
          <T>FrontDesk:DailyCustomerList.customer.title</T>
        </Label>
      </Column>

      <Column width="140px">
        <Label>
          <T>FrontDesk:DailyCustomerList.time</T>
        </Label>
      </Column>

      <Column width="50px" />

      <Column width="120px">
        <Label>
          <T>FrontDesk:DailyCustomerList.seller</T>
        </Label>
      </Column>

      {renderCountsColumn(SalesForDateType.Arriving)}
      {renderCountsColumn(SalesForDateType.Present)}
      {renderCountsColumn(SalesForDateType.Departing)}

      <Column maxWidth="40px" smallMargin width="40px">
        <Label
          color={getVisitTypeColor(
            SalesForDateType.DayVisitor,
            ColorVariant.DARK,
            theme
          )}
        >
          <T>FrontDesk:DailyCustomerList.visitType.DAY_VISITOR</T>
        </Label>
      </Column>

      <Column width={`${theme.spacing.gu(5)}rem`} />
    </>
  )
}

////////////

interface LabelProps {
  align?: string
  color?: string
}

const Label = styled.span<LabelProps>`
  color: ${({ color, theme }) => (color ? color : theme.palette.text.light)};

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}

  font-weight: 600;

  ${({ align }) =>
    align
      ? css`
          text-align: ${align};
        `
      : ''}
`

interface ColumnProps {
  bigMargin?: boolean
  maxWidth?: string
  smallMargin?: boolean
}

const Column = styled(ListItemColumn)<ColumnProps>`
  align-items: stretch;
  align-self: flex-end;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;

  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gutterSmall};
  `}

  ${({ bigMargin, smallMargin, theme }) =>
    bigMargin
      ? css`
          margin-right: ${theme.spacing.gutterBig};
        `
      : smallMargin
      ? css`
          margin-right: ${theme.spacing.gutter};
        `
      : ''}

  ${({ maxWidth }) =>
    maxWidth
      ? css`
          max-width: ${maxWidth};
        `
      : ''}
`
