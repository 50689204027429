import { ApolloQueryResult } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useState } from 'react'

import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import { Task, taskHooks } from '@/modules/Task'
import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { UseTaskQuery } from '~generated-types'
import { useTheme } from '@/theme'

import {
  Assignee,
  BasicDetails,
  Description,
  DueDate,
  DueTime,
  EditButton,
  IconWrapper,
  Owner,
  Placeholder,
  Purchase,
  Reservation,
} from './components'
import { Context, Section } from './types'

type Props = {
  context: Context
  onUpdateTasks: (tasks: Task[]) => void
  readOnly: boolean
  refetchTask: () => Promise<ApolloQueryResult<UseTaskQuery>>
  setCurrentSection: (section: Section) => void
  task: Task
}

export const TaskManager = ({
  context,
  onUpdateTasks,
  readOnly,
  refetchTask,
  setCurrentSection,
  task,
}: Props) => {
  const {
    palette,
    spacing: { gu },
  } = useTheme()

  const {
    assignee,
    description,
    dueDate,
    dueTime,
    id,
    isOpen,
    name,
    number,
    purchaseProducts,
    reservation,
    sales,
  } = task

  const { closeTask, openTask, updateTask, setTaskAssignee } =
    taskHooks.useTaskMutations({ onUpdateTasks })

  const [isHover, setHover] = useState<boolean>(false)

  return (
    <>
      {context === 'GLOBAL' && (
        <>
          <Owner owner={sales} />
          <InlineModalLine
            style={{ margin: `${gu(1)}rem 0 ${gu(0.5)}rem 0` }}
          />
        </>
      )}

      {reservation && (
        <>
          <Reservation ownerId={sales.id} reservation={reservation} />
          <InlineModalLine style={{ margin: `${gu(1)}rem 0` }} />
        </>
      )}

      <BasicDetails
        closeTask={() => closeTask(id)}
        isOpen={isOpen}
        name={name}
        number={number}
        openTask={() => openTask(id)}
        readOnly={readOnly}
        updateName={(name: string) => updateTask(task, { name: name })}
      />

      <Assignee
        assignee={assignee}
        readOnly={readOnly}
        updateAssignee={(assignee) => setTaskAssignee(id, assignee)}
      />

      <DueDate
        dueDate={dueDate}
        readOnly={readOnly}
        updateDueDate={(value: string) =>
          updateTask(task, { dueDate: value || null })
        }
      />

      <DueTime
        dueTime={dueTime}
        readOnly={!dueDate || readOnly}
        updateDueTime={(value: string) =>
          updateTask(task, { dueTime: value || null })
        }
      />

      <InlineModalLine style={{ margin: `${gu(2)}rem 0 ${gu(1)}rem 0` }} />

      <Description
        description={description}
        readOnly={readOnly}
        updateDescription={(value: string) =>
          updateTask(task, { description: value })
        }
      />

      <InlineModalLine style={{ margin: `${gu(1.5)}rem 0 ${gu(1)}rem 0` }} />

      {!!purchaseProducts.length &&
        [...purchaseProducts]
          .sort(
            (a, b) =>
              moment(a.auditLog.createdAt).valueOf() -
              moment(b.auditLog.createdAt).valueOf()
          )
          .map((purchase) => (
            <Purchase
              context={context}
              key={purchase.id}
              onUpdateTasks={onUpdateTasks}
              purchase={purchase}
              readOnly={readOnly}
              refetchTask={refetchTask}
              sales={sales}
              taskId={id}
            />
          ))}

      {!readOnly && (
        <InlineModalIconSection>
          <FlexRow>
            <EditButton
              onClick={() =>
                setCurrentSection({
                  previous: 'TASK_MANAGER',
                  type: 'TASK_PURCHASE_SEARCH',
                })
              }
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              <Placeholder>
                <T>ReservationModal:action.addProduct</T>
              </Placeholder>

              <IconWrapper>
                {isHover && (
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    icon="plus"
                    size="sm"
                  />
                )}
              </IconWrapper>
            </EditButton>
          </FlexRow>
        </InlineModalIconSection>
      )}
    </>
  )
}
