import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { ProductPurchase } from '@/modules/Products/types'
import { TargetType as TT } from '~generated-types'

import {
  DeleteButton,
  ExtractToParticipantButton,
  ExtractToRoomsButton,
} from './components'

type Props = {
  productId: string
  purchase: ProductPurchase
  readOnly: boolean
}

export const Actions = ({
  productId,
  purchase: {
    attributes: { quantity },
    id,
    targetType,
  },
  readOnly,
}: Props) => (
  <ActionsWrapper justifyContent="flex-end">
    {!readOnly && (
      <>
        {targetType == TT.PurchaseOrder && (
          <>
            <ExtractToParticipantButton
              productId={productId}
              purchaseId={id}
              quantity={1}
            />

            <ExtractToParticipantButton
              extractAll
              productId={productId}
              purchaseId={id}
              quantity={quantity}
            />

            <ExtractToRoomsButton
              productId={productId}
              purchaseId={id}
              quantity={quantity}
            />
          </>
        )}

        <DeleteButton purchaseId={id} />
      </>
    )}
  </ActionsWrapper>
)

///////

const ActionsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(18)}rem;
  `}
`
