import { BottomSheet } from '@/components/BottomSheet'
import { ElasticProductTitle } from '@/modules/Products/types'
import { Gutter } from '@/components/Layout'
import { T } from '@/modules/Language'

import { ProductTitleList } from './ProductTitleList'

type Props = {
  isMulti?: boolean
  onItemSelect: (item: ElasticProductTitle) => Promise<any>
  onClose: () => void
  sellerId: string
}

export const ProductTitlePicker = ({
  isMulti,
  onItemSelect,
  onClose,
  sellerId,
}: Props) => (
  <BottomSheet
    isTextCloseButton={isMulti}
    onClose={onClose}
    title={<T>Products:ProductTitleList.picker</T>}
    zIndex={10010}
  >
    <Gutter type={[0, 5, 10]}>
      <ProductTitleList
        isMulti={isMulti}
        onItemSelect={onItemSelect}
        sellerId={sellerId}
      />
    </Gutter>
  </BottomSheet>
)
