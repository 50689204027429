import styled, { css } from 'styled-components/macro'
import { createPortal } from 'react-dom'
import ReactLoading from 'react-loading'
import { useLocation } from 'react-router-dom'

import { FetchState, FetchStates } from '@/common/types'
import {
  SideDrawer,
  SideDrawerBottomControls,
  SideDrawerShadow,
} from '@/components/SideDrawer'
import { Gutter } from '@/components/Layout/Gutter'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  fileState: FetchState
  fileUrl: string
  onClose: () => void
}

export const FilePreview = ({ fileState, fileUrl, onClose }: Props) => {
  const { pathname, search } = useLocation()
  const theme = useTheme()

  return createPortal(
    <>
      <SideDrawer width={`${theme.spacing.gu(110)}rem`}>
        <Wrapper>
          <Container>
            <Title>
              <T>Orders:Documents.documentEditor.preview</T>
            </Title>
            {fileState === FetchStates.IDLE && <Iframe src={fileUrl} />}
            {fileState === FetchStates.LOADING && (
              <Placeholder>
                <ReactLoading
                  type="spin"
                  color={theme.palette.smoke.main}
                  height={32}
                  width={32}
                />
              </Placeholder>
            )}
            {fileState === FetchStates.ERROR && (
              <Placeholder>
                <T>Orders:Documents.documentEditor.errorPreview</T>
              </Placeholder>
            )}
          </Container>
        </Wrapper>
        <SideDrawerBottomControls>
          <Gutter type={['gutter', 'gutterBig']}>
            <InnocuousButton noNudge onClick={onClose}>
              <T>common:action.close</T>
            </InnocuousButton>
          </Gutter>
        </SideDrawerBottomControls>
      </SideDrawer>
      <SideDrawerShadow to={`${pathname}${search}`} onClick={onClose} />
    </>,
    document.getElementById('modal-root') as any
  )
}

///////

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => css`
    line-height: ${theme.spacing.gu(2.5)}rem;
    padding: ${theme.spacing.gutterBig};
  `}
`

const Iframe = styled.iframe`
  flex: 1;
  width: 100%;
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.light};
  `}
`

const Placeholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 79vh;
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBig};
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gutter};
  `}
`

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`
