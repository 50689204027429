import styled, { css } from 'styled-components/macro'

import { SalesProductUpdateInput } from '~generated-types'

import { SalesProduct, SalesProductSettings } from '@/modules/Products/types'
import { FlexRow } from '@/components/Layout'

import { AccommodationGroupSelector } from './AccommodationGroupSelector'
import { DatesSelector } from './DatesSelector'
import { VisibilityToggle } from './VisibilityToggle'

interface Props {
  id: string
  readOnly: boolean
  settings: SalesProductSettings
  updateProduct: (
    input: SalesProductUpdateInput
  ) => Promise<SalesProduct | undefined>
}

export const Settings = ({ id, readOnly, settings, updateProduct }: Props) => (
  <Wrapper>
    <AccommodationGroupSelector
      id={id}
      readOnly={readOnly}
      accommodationGroup={settings.accommodationGroup}
      updateProduct={updateProduct}
    />

    <DatesSelector
      id={id}
      readOnly={readOnly}
      purchaseDates={settings.purchaseDates}
      updateProduct={updateProduct}
    />

    <VisibilityToggle id={id} readOnly={readOnly} settings={settings} />
  </Wrapper>
)

/////

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem;
  `}
`
