import styled, { css } from 'styled-components/macro'

import { ElasticCustomer } from '@/modules/Registry'
import { FlexColumn } from '@/components/Layout'
import { ListItemSection as Wrapper } from '@/components/List'

type Props = {
  data: ElasticCustomer
}

const NameInfo = ({ data }: Props) => {
  const renderDetails = (customer: ElasticCustomer) => {
    switch (customer.customerType) {
      case 'ORGANIZATION':
        return (
          customer.organization.businessId && (
            <small>{customer.organization.businessId}</small>
          )
        )
      case 'PERSON':
        return null
      default:
        console.warn('Invalid customer type')
        return null
    }
  }

  return (
    <Wrapper>
      <FlexColumn noPadding>
        <small>#{data.customerNumber}</small>
        <Title>{data.searchOptimization.name}</Title>
        {renderDetails(data)}
      </FlexColumn>
    </Wrapper>
  )
}

export default NameInfo

////////////

const Title = styled.span`
  cursor: pointer;
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
