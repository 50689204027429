import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  RoomLayoutMutations,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { RoomCleaningStatus } from '~generated-types'
import { useTheme } from '@/theme'

export const CleaningManager = () => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const {
    isCleaningMode,
    layoutVariables: { COLUMN_HEADER_WIDTH },
    onSetCleaningMode,
    onSetSelectedRoomIds,
    onSetSelectedRoomType,
    refreshCalendar,
    selectedRoomIds,
  } = useRoomLayoutContext()

  const [setRoomStatus] = useMutation(RoomLayoutMutations.SET_ROOM_STATUS)

  const handleSubmit = (cleaningStatus: RoomCleaningStatus) =>
    setRoomStatus({
      variables: { input: { cleaningStatus, roomIds: selectedRoomIds } },
    }).then(() => {
      onSetSelectedRoomIds([])
      refreshCalendar()
    })

  const isEmpty = !selectedRoomIds.length

  return (
    <Wrapper alignItems="center" width={COLUMN_HEADER_WIDTH}>
      {isCleaningMode ? (
        <FlexRow
          alignItems="flex-start"
          justifyContent="space-between"
          flex={1}
          style={{ height: '100%' }}
        >
          <FlexColumn
            noPadding
            justifyContent="space-between"
            style={{ height: '100%' }}
          >
            <FlexRow alignItems="center">
              <RoomLabel isEmpty={isEmpty}>
                {translate('RoomLayout:cleaningManager.room', language, {
                  count: selectedRoomIds.length,
                })}
              </RoomLabel>
              {' – '}
              <Date>{moment().format('dd, D.M.YY')}</Date>
            </FlexRow>
            <FlexRow>
              <StatusButton
                color={
                  isEmpty
                    ? theme.palette.smoke.dark
                    : theme.palette.success.dark
                }
                disabled={isEmpty}
                onClick={() => handleSubmit(RoomCleaningStatus.Clean)}
              >
                <T>RoomLayout:cleaningManager.CLEAN</T>
              </StatusButton>
              <StatusButton
                color={
                  isEmpty ? theme.palette.smoke.dark : theme.palette.danger.main
                }
                disabled={isEmpty}
                onClick={() => handleSubmit(RoomCleaningStatus.Dirty)}
              >
                <T>RoomLayout:cleaningManager.DIRTY</T>
              </StatusButton>
            </FlexRow>
          </FlexColumn>
          <TimesButton onClick={() => onSetCleaningMode(false)}>
            <FontAwesomeIcon icon="xmark" />
          </TimesButton>
        </FlexRow>
      ) : (
        <CleaningButton
          onClick={() => {
            onSetSelectedRoomType(null)
            onSetCleaningMode(true)
          }}
        >
          <FontAwesomeIcon
            icon="broom"
            style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
          />
          <T>RoomLayout:cleaningManager.title</T>
        </CleaningButton>
      )}
    </Wrapper>
  )
}

////////

const RoomLabel = styled.span<{ isEmpty: boolean }>`
  color: ${({ isEmpty, theme }) =>
    isEmpty ? theme.palette.text.lighter : theme.palette.primary.dark};

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexRow)<{ width: number }>`
  position: fixed;
  z-index: 4019;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    border-right: 1px solid ${theme.palette.smoke.dark};
    height: ${theme.spacing.gu(13)}rem;
    padding: ${theme.spacing.gutter} ${theme.spacing.gutter}
      ${theme.spacing.gutter} ${theme.spacing.gutterBig};
  `}

  width: ${({ width }) => width}px;
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  outline: none;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    height: ${theme.spacing.gu(5)}rem;
    padding: 0 ${theme.spacing.gutter};
  `}

  &:not(:disabled):hover {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
    `}
  }

  &:not(:disabled):active {
    ${({ theme }) => css`
      background: ${theme.palette.smoke.main};
    `}
  }
`

const CleaningButton = styled(Button)`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.dark};
    color: ${theme.palette.text.light};
    margin-left: ${theme.spacing.gutterBig};
  `}
`

const Date = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const TimesButton = styled(Button)`
  border: 0;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    height: ${theme.spacing.gutterBig};
    width: ${theme.spacing.gutterBig};
  `}
`

const StatusButton = styled(Button)<{
  color: string
  disabled: boolean
}>`
  border: 1px solid ${({ color }) => color};
  color: ${({ color }) => color};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ theme }) => css`
    height: ${theme.spacing.gutterBig};
  `}

  &:first-of-type {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }
`
