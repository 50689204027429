import styled, { css } from 'styled-components/macro'

import { Label, RadioInput } from '@/components/FormControls'
import { SalesCopyComponent as C } from '~generated-types'
import { FlexColumn } from '@/components/Layout'
import { T } from '@/modules/Language'

type Props = {
  components: C[]
  setComponents: (components: C[]) => void
}

export const OrdersSection = ({ components, setComponents }: Props) => {
  const toggleComponent = (target: C) =>
    setComponents(
      components.includes(target)
        ? components.filter((c) => c !== target)
        : [...components, target]
    )

  return (
    <Wrapper flex={1}>
      <Title>
        <T>SalesDetails:CopySale.label.ORDERS</T>
      </Title>

      <RadioInput
        checked={!components.includes(C.Orders)}
        onChange={() => toggleComponent(C.Orders)}
      >
        <T>SalesDetails:CopySale.option.combineToOne</T>
      </RadioInput>
      <RadioInput
        checked={components.includes(C.Orders)}
        onChange={() => toggleComponent(C.Orders)}
      >
        <T>SalesDetails:CopySale.option.asOriginal</T>
      </RadioInput>
    </Wrapper>
  )
}

/////

const Title = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(1.5)}rem 0;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`
