import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Fragment } from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import styled from 'styled-components/macro'

import { useGlobalIconsContext, useTheme } from '@/theme'
import { FlexRow } from '@/components/Layout'
import { generateCompareFn } from '@/utils/arrays'

import { ReservationRequest } from '../../types'

interface Props {
  data: ReservationRequest | null
}

export const InfoCell = ({ data }: Props) => {
  const theme = useTheme()

  const { checkIconValidity } = useGlobalIconsContext()

  if (!data) {
    return <td />
  }

  const { features, info } = data

  return (
    <td>
      <FlexRow justifyContent="space-between">
        <Info>{info}</Info>
        <div>
          {[...features]
            .sort(generateCompareFn('name'))
            .map(({ icon, id, shortName }, idx) => (
              <Fragment key={id}>
                {checkIconValidity(icon) ? (
                  <FontAwesomeIcon
                    color={theme.palette.text.light}
                    icon={icon as IconProp}
                    size="sm"
                    style={{
                      marginRight:
                        idx === features.length - 1
                          ? 0
                          : theme.spacing.gutterSmall,
                    }}
                  />
                ) : (
                  <ShortName isLast={idx === features.length - 1}>
                    {shortName}
                  </ShortName>
                )}
              </Fragment>
            ))}
        </div>
      </FlexRow>
    </td>
  )
}

//////

const Info = styled.span`
  word-break: break-word;
  margin-right: ${({ theme }) => theme.spacing.gutter};
`

const ShortName = styled.span<{ isLast: boolean }>`
  font-weight: 500;
  margin-right: ${({ isLast, theme }) =>
    isLast ? 0 : theme.spacing.gutterSmall};
`
