import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { SalesRoute } from '../../types'
import { SubNavLinkSelectable } from './SubNavLink'
import { useSalesDetailsContext } from '../../'

type Props = {
  baseRoute: string
  routes: Omit<SalesRoute, 'component'>[]
}

export const Topbar = ({ baseRoute, routes }: Props) => {
  const { palette, spacing } = useTheme()

  const {
    contentColors,
    embeddedActiveSections,
    navItemEmbeddedSelect,
    navItemEmbeddedUnselect,
  } = useSalesDetailsContext()

  return (
    <FlexRow>
      <NavigationWrapper flex={1}>
        {routes.map(({ icon, issue, key }) => {
          const isActive = embeddedActiveSections.includes(key)

          return (
            <ItemWrapper
              key={key}
              alignItems="stretch"
              justifyContent="space-between"
            >
              <SubNavLinkSelectable
                icon={icon}
                issue={issue}
                label={<T l10n={`SalesDetails:section.${key}`} />}
                onClick={(e) => {
                  e.preventDefault()
                  navItemEmbeddedSelect(key)
                }}
                color={contentColors[key]}
                to={`${baseRoute}`}
                isActive={() => isActive}
                style={{
                  justifyContent: 'center',
                  marginTop: `${spacing.gu(0.25)}rem`,
                }}
              />
              <RemoveButton
                onClick={() => (isActive ? navItemEmbeddedUnselect(key) : null)}
              >
                {isActive && (
                  <FontAwesomeIcon
                    color={palette.primary.main}
                    fixedWidth
                    icon={['far', 'eye-slash']}
                    size="sm"
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                )}
              </RemoveButton>
            </ItemWrapper>
          )
        })}
      </NavigationWrapper>
    </FlexRow>
  )
}

const NavigationWrapper = styled(FlexRow)`
  overflow: auto;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.12);
  z-index: 2;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutterBig};
    border-top: 1px solid ${theme.palette.smoke.main};
    border-bottom: 1px solid ${theme.palette.smoke.main};
  `}
`

const RemoveButton = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`

const ItemWrapper = styled(FlexRow)`
  align-items: center;
  border-radius: 8px;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutter};
  `}

  & a > span {
    ${({ theme }) => css`
      margin-right: ${theme.spacing.gu(1)}rem;
    `}
  }

  &:hover ${RemoveButton} {
    display: flex;
  }
`
