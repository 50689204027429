import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'

type Props = {
  disabled: boolean
  onClick: () => void
  visible: boolean
}

export const SectionVisibilityToggle = ({
  disabled,
  onClick,
  visible,
}: Props) =>
  disabled ? (
    <Icon
      disabled
      fixedWidth
      icon={visible ? ['far', 'eye'] : ['far', 'eye-slash']}
    />
  ) : (
    <Tooltip
      content={
        <T>{`SalesDetails:PrintSale.visibility.${
          visible ? 'visible' : 'hidden'
        }`}</T>
      }
      delay={200}
      placement="left"
      trigger={(triggerProps) => (
        <FlexRow {...triggerProps}>
          <Icon
            fixedWidth
            icon={visible ? ['far', 'eye'] : ['far', 'eye-slash']}
            onClick={onClick}
            visible={visible ? 1 : undefined}
          />
        </FlexRow>
      )}
    />
  )

/////

const Icon = styled(FontAwesomeIcon)<{ disabled?: boolean; visible?: number }>`
  ${({ disabled, visible, theme }) => css`
    color: ${disabled
      ? theme.palette.smoke.dark
      : visible
      ? theme.palette.primary.dark
      : theme.palette.text.lighter};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `}

  &:hover {
    ${({ disabled, visible, theme }) =>
      !disabled &&
      css`
        color: ${visible
          ? theme.palette.primary.darker
          : theme.palette.text.light};
      `}
  }
`
