import writeXlsxFile, { Schema } from 'write-excel-file'
import type { ApolloClient } from '@apollo/client'
import { gql } from '@apollo/client'
import moment from 'moment'

import {
  CheckInType,
  CheckOutType,
  ParticipantExcelQuery as QueryData,
  ParticipantExcelQueryVariables as QueryVariables,
} from '~generated-types'
import { generateCompareFn } from '@/utils/arrays'
import { ParticipantFragmentFull } from '@/modules/ParticipantsList/ParticipantsList.fragments'
import { typeGuardFactory } from '@/utils/types'

type SalesParticipant = QueryData['sales']['participantConnection']['nodes'][0]

const QUERY = gql`
  ${ParticipantFragmentFull}

  fragment ParticipantSales on SalesParticipant {
    sales {
      customer {
        address {
          postalAddress {
            address1
            address2
            city
            country
            postcode
          }
        }
        contact {
          email
          firstName
          lastName
          phone
        }
        customer {
          customerNumber
          id
          ... on CustomerOrganization {
            organization {
              businessId
              name
            }
          }
          ... on CustomerPerson {
            person {
              email
              firstName
              lastName
              phone
            }
          }
        }
      }
      id
      orders {
        invoices {
          paymentType
        }
        paymentInfo {
          payableAmount
        }
      }
    }
  }

  query ParticipantExcel($salesId: ID!) {
    sales(id: $salesId) {
      id
      orderNumber
      participantConnection(input: { pagination: { size: 10000 } }) {
        nodes {
          ...SalesParticipantFull
          ...ParticipantSales
        }
      }
      type
    }
  }
`

interface Input {
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>
  salesId: string
  translateFn: (keys: string | Array<string>) => string
}

// eslint-disable-next-line @typescript-eslint/ban-types
export default async function generateParticipantExcel({
  client,
  salesId,
  translateFn,
}: Input): Promise<void> {
  const { data } = await client.query<QueryData, QueryVariables>({
    fetchPolicy: 'no-cache',
    query: QUERY,
    variables: { salesId },
  })

  if (!data) {
    throw new Error('Failed to fetch participant excel data')
  }

  const participants = [...data.sales.participantConnection.nodes].sort(
    generateCompareFn('sortOrder')
  )

  const schema: Schema<SalesParticipant> = [
    {
      column: translateFn('ParticipantsList:excelHeader.name'),
      value: ({ firstName, lastName }) =>
        `${lastName}${!!firstName && !!lastName ? ', ' : ''}${firstName}`,
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.products'),
      value: ({ services }) =>
        services
          .map(({ purchaseProduct }) => purchaseProduct?.product?.name)
          .filter(Boolean)
          .join(' / '),
      width: 24,
      wrap: true,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.paymentStatus'),
      value: ({ sales }) => {
        const payableTotal = sales.orders.reduce((acc, { paymentInfo }) => {
          return acc + paymentInfo.payableAmount
        }, 0)
        const paymentTypes = sales.orders
          .map(({ invoices }) =>
            Array.from(
              new Set(
                invoices.map(({ paymentType }) =>
                  translateFn(`Orders:PaymentType.${paymentType}`)
                )
              )
            )
          )
          .flat()

        return `${
          payableTotal > 0 ? 'Maksamatta' : 'Maksettu'
        } (${paymentTypes.join('/')})`
      },
      width: 24,
      wrap: true,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.accommodationDates'),
      value: ({ services, visitStatus }) => {
        const accommodationService = services.find(
          typeGuardFactory('__typename', 'ServiceParticipantBed')
        )

        if (accommodationService?.dates) {
          const { checkIn, checkOut } = accommodationService.dates

          return `${moment(checkIn.date).format('dd, D.M.YY')}${
            checkIn.type === CheckInType.Early
              ? ` (${translateFn(`enums:checkInOut.EARLY`)})`
              : ''
          } – ${moment(checkOut.date).format('dd, D.M.YY')}${
            checkOut.type === CheckOutType.Late
              ? ` (${translateFn(`enums:checkInOut.LATE`)})`
              : ''
          }`
        }

        return visitStatus.isDayVisitor
          ? translateFn('ParticipantsList:excelHeader.dayVisitor')
          : ''
      },
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.accommodationTarget'),
      value: ({ services }) => {
        const accommodationService = services.find(
          typeGuardFactory('__typename', 'ServiceParticipantBed')
        )

        if (accommodationService) {
          return (
            accommodationService.accommodationTarget?.name ||
            translateFn('Accommodation:TargetGroup.default')
          )
        }

        return ''
      },
      width: 14,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.room'),
      value: ({ services }) => {
        const accommodationService = services.find(
          typeGuardFactory('__typename', 'ServiceParticipantBed')
        )

        if (accommodationService) {
          const reservation =
            accommodationService.participantRoom?.roomReservation

          return reservation ? `#${reservation.request.room.number}` : ''
        }

        return ''
      },
      width: 14,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.roomFeaturesAndNotes'),
      value: ({ services }) => {
        const accommodationService = services.find(
          typeGuardFactory('__typename', 'ServiceParticipantBed')
        )

        if (accommodationService) {
          const reservation =
            accommodationService.participantRoom?.roomReservation

          let value = ''

          if (reservation) {
            if (reservation.request.features.length > 0) {
              value += reservation.request.features
                .map(({ name }) => name)
                .join(' / ')
            }
            if (reservation.request.info) {
              value +=
                `${!!value ? ' / ' : ''}${reservation.request.info}` || ''
            }

            const roomTypeReservation = reservation.roomTypeReservation

            if (!value && !!roomTypeReservation) {
              value += roomTypeReservation.request.features
                .map(({ name }) => name)
                .join(' / ')
              value +=
                `${!!value ? ' / ' : ''}${roomTypeReservation.request.info}` ||
                ''
            }
          }
          return value || ''
        }

        return ''
      },
      width: 24,
      wrap: true,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.gender'),
      value: ({ gender }) =>
        gender ? translateFn(`enums:gender.${gender}`) : '',
      width: 12,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.age'),
      value: ({ age, birthday }) => {
        if (birthday) {
          return Math.abs(
            moment()
              .year(birthday.year)
              .month((birthday.month ?? 1) - 1)
              .date(birthday.date ?? 1)
              .diff(moment(), 'y')
          )
        }

        return age ? `${age}` : ''
      },
      width: 12,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.birthday'),
      value: ({ birthday }) => {
        if (birthday) {
          return moment()
            .year(birthday.year)
            .month((birthday.month ?? 1) - 1)
            .date(birthday.date ?? 1)
            .format('D.M.YYYY')
        } else {
          return ''
        }
      },
      width: 12,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.ageCategory'),
      value: ({ ageCategory }) => ageCategory?.name,
      width: 14,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.nationality'),
      value: ({ nationality }) => nationality,
      width: 14,
    },
    {
      column: translateFn(
        'ParticipantsList:excelHeader.accommodationRequestText'
      ),
      value: ({ accommodationRequest }) => accommodationRequest,
      width: 24,
      wrap: true,
    },
    {
      column: translateFn(
        'ParticipantsList:excelHeader.accommodationRequestNames'
      ),
      value: ({ roomFriends }) => roomFriends.join(' / '),
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.allergiesText'),
      value: ({ allergyDescription }) => allergyDescription,
      width: 24,
      wrap: true,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.allergiesSelected'),
      value: ({ allergies }) => allergies.join(' / '),
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.diets'),
      value: ({ diets }) => diets.join(' / '),
      width: 20,
    },
    {
      column: translateFn(
        'ParticipantsList:excelHeader.drugAllergyDescription'
      ),
      value: ({ drugAllergyDescription }) => drugAllergyDescription,
      width: 24,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.group'),
      value: ({ group }) => group,
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.createdAt'),
      value: ({ auditLog }) =>
        moment(auditLog.createdAt).format('D.M.YYYY HH:MM'),
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.participantAddress'),
      value: ({ postalAddress }) => {
        const addressParts: string[] = postalAddress
          ? [
              postalAddress.address1 || '',
              postalAddress.address2 || '',
              `${postalAddress.postcode || ''} ${
                postalAddress.city || ''
              }`.trim(),
              postalAddress.country || '',
            ]
          : []

        return addressParts.filter((x) => !!x).join(', ')
      },
      width: 32,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.additionalInfo'),
      value: ({ additionalInfo }) => additionalInfo,
      width: 24,
      wrap: true,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.customerName'),
      value: ({ sales }) => {
        const customer = sales.customer?.customer

        if (customer) {
          if (customer.__typename === 'CustomerOrganization') {
            const contact = sales.customer?.contact

            return contact
              ? `${contact.lastName}${
                  !!contact.lastName && !!contact.firstName ? ', ' : ''
                }${contact.firstName} (${customer.organization.name})`
              : `${customer.organization.name}`
          } else {
            return `${customer.person.lastName}${
              !!customer.person.lastName && !!customer.person.firstName
                ? ', '
                : ''
            }${customer.person.firstName}`
          }
        }

        return ''
      },
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.customerEmail'),
      value: ({ sales }) => {
        const customer = sales.customer?.customer

        if (customer) {
          return customer.__typename === 'CustomerOrganization'
            ? sales.customer?.contact?.email
            : customer.person.email
        }

        return ''
      },
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.customerPhone'),
      value: ({ sales }) => {
        const customer = sales.customer?.customer

        if (customer) {
          return customer.__typename === 'CustomerOrganization'
            ? sales.customer?.contact?.phone
            : customer.person.phone
        }

        return ''
      },
      width: 20,
    },
    {
      column: translateFn('ParticipantsList:excelHeader.customerAddress'),
      value: ({ sales }) => {
        const address = sales.customer?.address?.postalAddress
        const addressParts: string[] = address
          ? [
              address.address1 || '',
              address.address2 || '',
              `${address.postcode || ''} ${address.city || ''}`.trim(),
              address.country || '',
            ]
          : []

        return addressParts.filter((x) => !!x).join(', ')
      },
      width: 32,
    },
  ]

  await writeXlsxFile<SalesParticipant>(participants, {
    fileName: translateFn('ParticipantsList:excelHeader.filename'),
    schema,
  })
}
