import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { salesHooks } from '@/modules/Sales'
import { useTheme } from '@/theme'

export const WarningRow = () => {
  const { palette } = useTheme()
  const { language } = useLanguageContext()
  const {
    data: { vst },
  } = salesHooks.useSalesDetailsContext()

  const errorAmount = vst?.totalErrorParticipants ?? null
  const incompleteAmount = vst?.totalIncompleteParticipants ?? null
  const fields = vst?.missingFields ?? []

  return (
    <Wrapper justifyContent="flex-start">
      <FontAwesomeIcon
        color={errorAmount ? palette.danger.main : palette.warning.dark}
        icon="triangle-exclamation"
      />

      {!!errorAmount && !!incompleteAmount && (
        <Label>
          {errorAmount} {translate('SalesDetails:VST.participants', language)}{' '}
          {translate('SalesDetails:VST.vstErrorWarning', language)}
          {', '}
          {incompleteAmount}{' '}
          {translate('SalesDetails:VST.vstIncompleteWarning', language)}
        </Label>
      )}

      {!!errorAmount && !incompleteAmount && (
        <Label>
          {errorAmount} {translate('SalesDetails:VST.participants', language)}{' '}
          {translate('SalesDetails:VST.vstErrorWarning', language)}
        </Label>
      )}

      {!errorAmount && !!incompleteAmount && (
        <Label>
          {incompleteAmount}{' '}
          {translate('SalesDetails:VST.participants', language)}{' '}
          {translate('SalesDetails:VST.vstIncompleteWarning', language)}
        </Label>
      )}

      <Label>
        {'  '}–{'  '}
      </Label>

      <Fields>
        {fields
          .map((f) => translate(`SalesDetails:VST.fields.${f}`, language))
          .join(', ')}
      </Fields>
    </Wrapper>
  )
}

/////////

const Fields = styled(FlexRow)`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const Label = styled.span`
  white-space: pre;
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.light};
    margin-top: ${theme.spacing.gu(2)}rem;
    padding-top: ${theme.spacing.gu(2)}rem;

    & > svg {
      margin-right: ${theme.spacing.gu(1.5)}rem;
    }
  `}
`
