import React, { ReactNode } from 'react'

import { ListTD, ListTFoot, ListTR } from './common'
import { CellProps } from './common/types'

type Props = CellProps & {
  children: ReactNode
  colSpan?: number
}

const ListFooter = ({
  align,
  children,
  colSpan = 1,
  verticalAlign,
  ...rest
}: Props) => (
  <ListTFoot {...rest}>
    <ListTR>
      {/* @ts-ignore */}
      <ListTD align={align} colSpan={colSpan} verticalAlign={verticalAlign}>
        {children}
      </ListTD>
    </ListTR>
  </ListTFoot>
)

export default ListFooter
