import { gql, useMutation } from '@apollo/client'

import type {
  UpdateOrderMutation,
  UpdateOrderMutationVariables,
} from '~generated-types'
import { orderFragments } from '@/modules/Order'

const UPDATE_ORDER_MUTATION = gql`
  ${orderFragments.order}

  mutation UpdateOrder($input: OrderUpdateInput!) {
    orderUpdate(input: $input) {
      ...Order
    }
  }
`

export const useUpdateOrderMutation = () =>
  useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(
    UPDATE_ORDER_MUTATION
  )
