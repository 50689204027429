import * as yup from 'yup'
import { Moment } from 'moment'
import styled from 'styled-components/macro'

import {
  FormField,
  FormSection,
  FormSectionSubtitle,
  FormSectionTitle,
} from '@/components/FormWrappers'
import { Label, TextInput } from '@/components/FormControls'
import { FormikFormFieldHelper } from '@/components/FormControls'
import { FormikRenderProps } from '@/utils/forms'
import { T } from '@/modules/Language'

import {
  CustomerDefaultAddress,
  customerDefaultAddressSchema,
} from './CustomerDefaultAddress'
import {
  OrganizationDefaultContact,
  organizationDefaultContactSchema,
} from './OrganizationDefaultContact'
import AutoSaveIndicator from './AutoSaveIndicator'

type Props = {
  hideTitle?: boolean
  disableAutosave?: boolean
  formikRenderProps: FormikRenderProps
  lastSaved?: Moment | null | undefined
  showDefaultSubForms?: boolean
}

export const basicDetailsOrganizationCreateSchema = yup.object().shape({
  defaultAddress: customerDefaultAddressSchema,
  defaultCustomerContact: organizationDefaultContactSchema,
  eInvoicingAddress: yup
    .object()
    .shape({
      address: yup.string(),
      operator: yup.string(),
    })
    .nullable(),
  organization: yup.object().shape({
    businessId: yup.string().nullable(),
    name: yup.string().required('required'),
  }),
})

export const basicDetailsOrganizationEditSchema = yup.object().shape({
  eInvoicingAddress: yup.object().shape({
    address: yup.string(),
    operator: yup.string(),
  }),
  organization: yup.object().shape({
    businessId: yup.string().nullable(),
    name: yup.string().required('required'),
  }),
})

export const BasicDetailsOrganization = ({
  hideTitle,
  disableAutosave,
  formikRenderProps,
  lastSaved,
  showDefaultSubForms,
}: Props) => (
  <>
    <FormSection>
      {!hideTitle && (
        <Title>
          <T>Customers:basicDetails</T>
          {!disableAutosave && (
            <AutoSaveIndicator
              formikRenderProps={formikRenderProps}
              lastSaved={lastSaved}
            />
          )}
        </Title>
      )}
      <FormField>
        <Label>
          <T>Customers:name</T>*
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          errorDescriptions={{
            required: <T>Customers:validation.name.required</T>,
          }}
          inputComponent={TextInput}
          name="organization.name"
        />
      </FormField>
      <FormField>
        <Label>
          <T>Customers:businessId</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="organization.businessId"
        />
      </FormField>
    </FormSection>

    <FormSection>
      <FormSectionSubtitle>
        <T>Customers:eInvoicingAddress.title</T>
      </FormSectionSubtitle>
      <FormField>
        <Label>
          <T>Customers:eInvoicingAddress.address</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="eInvoicingAddress.address"
        />
      </FormField>
      <FormField>
        <Label>
          <T>Customers:eInvoicingAddress.operator</T>
        </Label>
        <FormikFormFieldHelper
          defaultValue={''}
          inputComponent={TextInput}
          name="eInvoicingAddress.operator"
        />
      </FormField>
    </FormSection>

    {showDefaultSubForms && (
      <>
        <CustomerDefaultAddress />
        <OrganizationDefaultContact />
      </>
    )}
  </>
)

////////////

const Title = styled(FormSectionTitle)`
  display: flex;
  align-items: baseline;
`
