import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { FlexRow } from '@/components/Layout'
import { Input } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  value: number
  maxValue: number
  icon: IconProp
  onSubmit: (value: number) => Promise<any>
}

export const NumberInput = ({
  value: valueProp,
  maxValue,
  icon,
  onSubmit,
}: Props) => {
  const { palette } = useTheme()

  const [value, setValue] = useState(valueProp)

  const onKeyDown = (key: React.KeyboardEvent['key']) =>
    key === 'Enter' && valueProp !== value && onSubmit(value)

  const onChange = (value: number) =>
    value >= 0 && setValue(value > maxValue ? maxValue : value)

  return (
    <FlexRow
      alignItems={'center'}
      justifyContent={'flex-start'}
      style={{ alignSelf: 'stretch', position: 'relative' }}
    >
      <StartAdornment>
        <FontAwesomeIcon
          color={palette.text.light}
          fixedWidth
          icon={icon}
          size="sm"
          style={{ marginRight: '5px' }}
        />
      </StartAdornment>
      <StyledInput
        min={0}
        onBlur={() => valueProp !== value && onSubmit(value)}
        onKeyDown={onKeyDown}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(Number(e.target.value))
        }
        onFocus={(e: React.FocusEvent<HTMLInputElement>) =>
          e.currentTarget.select()
        }
        placeholder={'–'}
        style={{ color: palette.primary.main }}
        type="number"
        value={`${value}`}
      />
    </FlexRow>
  )
}

export default NumberInput

const StartAdornment = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  ${({ theme }) => css`
    left: ${theme.spacing.gu(1)}rem;
  `}
`

const StyledInput = styled(Input)<any>`
  &&& {
    border: 0;
    border-radius: 0;
    height: 100%;
    font-weight: 500;

    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gutterSmall} 0 ${theme.spacing.gu(3.5)}rem;
      width: ${theme.spacing.gu(6)}rem;
      font-size: ${theme.typography.fontSizeSmall};
    `}

    text-align: ${({ type }) => (type === 'number' ? 'end' : 'start')};
    margin: 0;
    &:hover {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.light};
      `}
    }
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    ::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &::placeholder {
      font-weight: 400;
      font-style: italic;
      opacity: 1;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }
  }
`
