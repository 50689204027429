import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'

import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { translate, useLanguageContext } from '@/modules/Language'
import { DisplayOption } from '~generated-types'
import { useTheme } from '@/theme'

import { Field, FieldType } from './Field'
import { FieldSelector } from './FieldSelector'

type Props = {
  displayMessage: string | null
  readOnly: boolean
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
}

export const Fields = ({
  displayMessage,
  readOnly,
  reservationId,
  updateReservations,
}: Props) => {
  const { palette } = useTheme()
  const { language } = useLanguageContext()

  const { resourceConfig, loading } =
    resourceReservationHooks.useResourceConfig()

  const [fields, setFields] = useState<FieldType[]>([])

  useEffect(() => {
    if (resourceConfig) {
      const displayOption =
        resourceConfig.displayOption.roomMessageDisplayOption

      const isShown =
        displayOption === DisplayOption.ShowAlways ||
        (displayOption === DisplayOption.ShowMenu && !!displayMessage)

      setFields([
        {
          displayOption,
          icon: 'display',
          isShown,
          key: 'roomMessage',
          value: displayMessage ?? '',
        },
      ])
    }
  }, [displayMessage, resourceConfig])

  const onAddField = (fieldKey: string) => {
    setFields((fields) =>
      fields.map((f) => (f.key === fieldKey ? { ...f, isShown: true } : f))
    )
  }

  const onRemoveField = (fieldKey: string) => {
    setFields((fields) =>
      fields.map((f) => (f.key === fieldKey ? { ...f, isShown: false } : f))
    )
  }

  const options = fields
    .filter(
      ({ displayOption, isShown }) =>
        displayOption !== DisplayOption.Hidden && !isShown
    )
    .map(({ key, value }) => ({
      key,
      label: translate(`ReservationModal:fields.${key}.label`, language),
      value,
    }))

  return (
    <>
      {loading ? (
        <>
          <InlineModalLine />
          <InlineModalIconSection>
            <ReactLoading
              color={palette.smoke.main}
              height={24}
              type="bubbles"
              width={24}
            />
          </InlineModalIconSection>
        </>
      ) : (
        <>
          {fields
            .filter(({ isShown }) => isShown)
            .map((field) => (
              <Field
                key={`field-${field.key}`}
                field={field}
                onRemoveField={onRemoveField}
                readOnly={readOnly}
                reservationId={reservationId}
                updateReservations={updateReservations}
              />
            ))}

          {!!options.length && !readOnly && (
            <>
              <InlineModalLine />
              <FieldSelector onAddField={onAddField} options={options} />
            </>
          )}
        </>
      )}
    </>
  )
}
