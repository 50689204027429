import confirmationService from './confirmationService'
import invoiceService from './invoiceService'
import offerService from './offerService'
import orderService from './orderService'

export default Object.freeze({
  confirmationService,
  invoiceService,
  offerService,
  orderService,
})
