import styled, { css } from 'styled-components/macro'
import moment from 'moment'

import {
  DateDurationSelector,
  DateSelector,
  PaymentTypeSelector,
  Section,
  Seller,
  Subsection,
} from '@/modules/Order/components'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InvoicePaymentType, InvoiceState } from '~generated-types'
import { Invoice } from '@/modules/Order/types'
import { orderServices } from '@/modules/Order'
import { T } from '@/modules/Language'

type Props = {
  invoice: Invoice
  orderId: string
  readOnly: boolean
}

export const DetailsSection = ({
  invoice: {
    auditLog: { bookkeepingTransferred, createdAt, invoiceAccepted },
    dueDate,
    dueDateDuration,
    dueDateDurationOptions,
    id,
    invoiceDate,
    invoiceNumber,
    lifecycle: { state },
    paymentType,
    referenceNumber,
    seller,
    sellerContact,
    type,
    valueDate,
  },
  orderId,
  readOnly,
}: Props) => {
  const { setPaymentType, updateInvoice } = orderServices.invoiceService()

  const isAccepted = state === InvoiceState.Accepted

  const showExtraDetailsColumn =
    invoiceAccepted ||
    bookkeepingTransferred ||
    invoiceNumber ||
    referenceNumber

  return (
    <Section flex={1.5} style={{ overflow: 'unset' }}>
      <FlexRow flex={1}>
        <FlexColumn flex={isAccepted ? 1 : 1.25}>
          <PaymentTypeSelector
            paymentType={paymentType}
            phase={type}
            readOnly={readOnly}
            setPaymentType={(paymentType: InvoicePaymentType) =>
              setPaymentType(id, orderId, paymentType)
            }
          />

          <DateSelector
            date={invoiceDate}
            readOnly={readOnly}
            type="INVOICE_DATE"
            setDate={(invoiceDate: string | null) =>
              updateInvoice({ id, invoiceDate }, orderId)
            }
          />

          <DateSelector
            date={valueDate}
            readOnly={readOnly}
            type="VALUE_DATE"
            setDate={(valueDate: string | null) =>
              updateInvoice({ id, valueDate }, orderId)
            }
          />

          <DateDurationSelector
            date={dueDate}
            duration={dueDateDuration}
            durationOptions={dueDateDurationOptions}
            isCompact={isAccepted}
            readOnly={readOnly}
            type="DUE_DATE"
            setDate={(dueDate: string | null, dueDateDuration: number | null) =>
              updateInvoice({ dueDate, dueDateDuration, id }, orderId)
            }
            valueDate={valueDate}
          />
        </FlexColumn>

        <VerticalSeparator />

        <FlexColumn flex={1}>
          <Seller
            readOnly={readOnly}
            seller={seller}
            sellerContact={sellerContact}
            setSellerContact={(sellerContact: string | null) =>
              updateInvoice({ id, sellerContact }, orderId)
            }
          />

          <Subsection title={<T>Orders:Details.created</T>}>
            {moment(createdAt).format('dd, D.M.YYYY, HH:mm')}
          </Subsection>
        </FlexColumn>

        {showExtraDetailsColumn && (
          <>
            <VerticalSeparator />

            <FlexColumn flex={1}>
              {invoiceNumber && (
                <Subsection title={<T>Orders:Details.invoiceNumber</T>}>
                  {invoiceNumber}
                </Subsection>
              )}
              {referenceNumber && (
                <Subsection title={<T>Orders:Details.referenceNumber</T>}>
                  {referenceNumber}
                </Subsection>
              )}
              {invoiceAccepted && (
                <Subsection title={<T>Orders:Details.acceptedDate</T>}>
                  {moment(invoiceAccepted).format('dd, D.M.YYYY, HH:mm')}
                </Subsection>
              )}

              {bookkeepingTransferred && (
                <Subsection
                  title={<T>Orders:Details.bookkeepingTransferred</T>}
                >
                  {moment(bookkeepingTransferred).format('dd, D.M.YYYY, HH:mm')}
                </Subsection>
              )}
            </FlexColumn>
          </>
        )}
      </FlexRow>
    </Section>
  )
}

////////////

const VerticalSeparator = styled.div`
  ${({ theme }) => css`
    border-right: 1px solid ${theme.palette.smoke.light};
    margin: 0 ${theme.spacing.gutter};
  `}
`
