import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'

import { FlexRow } from '@/components/Layout'

type Props = {
  children?: ReactNode
  isExpanded: boolean
  onClick: (...args: Array<any>) => any
}

const ExpansionButton = ({ children, isExpanded, onClick }: Props) => (
  <Wrapper compact={!children} onClick={() => onClick()}>
    <IconWrapper>
      <FontAwesomeIcon
        fixedWidth
        icon={isExpanded ? 'angle-down' : 'angle-right'}
      />
    </IconWrapper>
    {children}
  </Wrapper>
)

export default ExpansionButton

////////////

const Wrapper = styled(FlexRow)<{ compact: boolean }>`
  cursor: pointer;
  ${({ compact }) => !compact && 'flex: 1'};

  &:hover {
    ${({ theme }) => css`
      color: ${theme.palette.primary.darker};
    `}

    path {
      ${({ theme }) => css`
        color: ${theme.palette.primary.darker};
      `}
    }
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;

  ${({ theme }) => css`
    background: ${theme.palette.white};
    color: ${theme.palette.text.lighter};
    width: ${theme.spacing.gu(3)}rem;
    height: ${theme.spacing.gu(3)}rem;
    margin-right: ${theme.spacing.gutterSmall};
  `}
`
