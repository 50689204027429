import React, { ElementRef, ReactNode, useState } from 'react'

import { ModalContainer } from '@/components/Modal'
import { Placement } from '@/components/PopperWithShadow'

import {
  default as WeekPicker,
  WeekPickerProps,
  WeekSelection,
} from './WeekPicker'
import ControlModal from './components/ControlModal'

type Props = WeekPickerProps & {
  clearable?: boolean
  placement?: Placement
  render: (arg0: {
    closeModal: (...args: Array<any>) => any
    openModal: (...args: Array<any>) => any
    ref: ElementRef<any>
    toggleModal: (...args: Array<any>) => any
    week: WeekSelection | null | undefined
  }) => ReactNode
}

const WeekPickerControl = ({
  clearable,
  placement,
  render,
  ...weekPickerProps
}: Props) => {
  const [open, setOpen] = useState<boolean>(false)

  return (
    <ModalContainer
      isOpen={open}
      modal={
        <ControlModal
          hasValue={!!weekPickerProps.value}
          onClear={clearable ? () => weekPickerProps.setValue(null) : undefined}
        >
          <WeekPicker {...weekPickerProps} />
        </ControlModal>
      }
      onClose={() => setOpen(false)}
      placement={placement}
      referenceElement={({ ref }) =>
        render({
          closeModal: () => setOpen(false),
          openModal: () => setOpen(true),
          ref,
          toggleModal: () => setOpen((current) => !current),
          week: weekPickerProps.value,
        })
      }
    />
  )
}

export default WeekPickerControl
