import { gql } from '@apollo/client'

import { attachmentFragments } from '@/modules/Attachments'

export default gql`
  ${attachmentFragments.attachment}

  mutation CopyAttachment($input: FileCopyToInput!) {
    fileCopyTo(input: $input) {
      ...Attachment
    }
  }
`
