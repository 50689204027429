import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import ReactLoading from 'react-loading'

import {
  InlineModal,
  InlineModalContent,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import { PriceToggle, PrintSaleSection } from './components'

type Props = {
  onClose: () => void
}

export const PrintSaleModal = ({ onClose }: Props) => {
  const { language } = useLanguageContext()
  const { palette, spacing } = useTheme()
  const {
    data: { orderNumber },
    isFetchingSalesForPrint,
    salesForPrint,
    salesForPrintLoading,
    salesPrintConfig,
    setFetchingSalesForPrint,
  } = useSalesDetailsContext()

  const [processing, setProsessing] = useState<boolean>(false)

  const title = `${translate(
    'SalesDetails:PrintSale.title',
    language
  )} #${orderNumber}`

  useEffect(() => {
    if (salesForPrint && !salesForPrintLoading && isFetchingSalesForPrint) {
      setProsessing(false)
      setFetchingSalesForPrint(false)

      const prevTitle = document.title

      window.onbeforeprint = () => {
        document.title = title
      }

      window.onafterprint = () => {
        document.title = prevTitle
      }

      window.print()
    }
  }, [salesForPrint, salesForPrintLoading])

  const handlePrintSales = () => {
    setProsessing(true)
    setFetchingSalesForPrint(true)
  }

  return (
    <Modal>
      <InlineModalHeader
        onClose={onClose}
        title={<ShiftLeft>{title}</ShiftLeft>}
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      <InlineModalContent style={{ paddingTop: 0 }}>
        {salesPrintConfig.map((section) => (
          <PrintSaleSection key={section.key} section={section} />
        ))}

        <PriceToggle />
      </InlineModalContent>

      <InlineModalFooter>
        <FlexRow alignItems="center" justifyContent="space-between" flex={1}>
          <InnocuousButton onClick={onClose}>
            <T>common:action.cancel</T>
          </InnocuousButton>

          <InnocuousButton disabled={processing} onClick={handlePrintSales}>
            {processing ? (
              <LoaderWrapper>
                <ReactLoading
                  color={palette.primary.main}
                  height={20}
                  type="spin"
                  width={20}
                />
              </LoaderWrapper>
            ) : (
              <PrimaryColor>
                <T>SalesDetails:PrintSale.print</T>
              </PrimaryColor>
            )}
          </InnocuousButton>
        </FlexRow>
      </InlineModalFooter>
    </Modal>
  )
}

/////

const LoaderWrapper = styled.span`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1.5)}rem;
  `}
`

const Modal = styled(InlineModal)`
  max-height: 100vh;
  width: 450px;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}

  @media print {
    display: none;
  }
`

const ShiftLeft = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(5)}rem;
  `}
`
