import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import { FetchState, FetchStates } from '@/common/types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

type Props = {
  fetchingState: FetchState
  minWidth?: number | string
  url: string
}

export const DocumentPreview = ({ fetchingState, minWidth, url }: Props) => {
  const theme = useTheme()

  return (
    <>
      {fetchingState === FetchStates.IDLE && (
        <Iframe
          id="iframe-pdf-document"
          minWidth={minWidth}
          src={`${url}#toolbar=0`}
        />
      )}
      {fetchingState === FetchStates.LOADING && (
        <Wrapper>
          <ReactLoading
            type={'spin'}
            color={theme.palette.smoke.main}
            height={35}
            width={35}
          />
        </Wrapper>
      )}
      {fetchingState === FetchStates.ERROR && (
        <Wrapper>
          <T>Orders:Documents.documentEditor.errorPreview</T>
        </Wrapper>
      )}
    </>
  )
}

///////

const Wrapper = styled.div<{ minWidth?: number | string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 89vh;
  font-style: italic;
  font-weight: 300;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase2};
  `}

  ${({ minWidth }) =>
    minWidth
      ? css`
          min-width: ${minWidth};
        `
      : ''}
`

const Iframe = styled.iframe<{ minWidth?: number | string }>`
  flex: 1;
  ${({ minWidth }) =>
    minWidth
      ? css`
          min-width: ${minWidth};
        `
      : ''}
  width: 100%;
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.light};
  `}
`
