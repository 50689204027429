import { useEffect, useState } from 'react'

import {
  ElasticRoomSize,
  RoomLayoutService,
} from '@/modules/Reservations/components/RoomLayout'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { FilterWrapper } from './FilterWrapper'

type Props = {
  targetRoomSize: string
  setTargetRoomSize: (...args: Array<any>) => any
}

export const RoomSizeSelector = ({
  targetRoomSize,
  setTargetRoomSize,
}: Props) => {
  const theme = useTheme()

  const [isFetching, setFetching] = useState<boolean>(false)
  const [roomSizes, setRoomSizes] = useState([])

  useEffect(() => {
    setFetching(true)

    RoomLayoutService.fetchRoomSizes().then(({ data, ok }) => {
      setRoomSizes(
        // @ts-ignore
        ok && data
          ? data.roomSizes.buckets
              .map((size: ElasticRoomSize) => ({
                label: size.key,
                value: size.key,
              }))
              .sort(generateCompareFn('value'))
          : []
      )
      setFetching(false)
    })
  }, [])

  return (
    <FilterWrapper
      label={<T>RoomLayout:controlRow.label.roomSize</T>}
      width={`${theme.spacing.gu(15)}rem`}
    >
      <ThemedSelect
        isClearable
        isLoading={isFetching}
        name="room-size-selector"
        noOptionsMessage={() => <T>RoomLayout:controlRow.empty.noRoomSizes</T>}
        onChange={(selected: Option | null | undefined) =>
          setTargetRoomSize(selected ? selected.value : '')
        }
        options={roomSizes}
        placeholder={<T>RoomLayout:controlRow.placeholder.all</T>}
        value={
          targetRoomSize
            ? {
                label: targetRoomSize,
                value: targetRoomSize,
              }
            : null
        }
      />
    </FilterWrapper>
  )
}
