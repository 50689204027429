import { Ref, useEffect, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
  DefaultFilterType,
  EnrollmentStateFilterType,
  GenderFilterType,
  useParticipantsListContext,
} from '@/modules/ParticipantsList'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { ModalContainer } from '@/components/Modal'
import { PrimaryColor } from '@/components/Colors'
import { SalesType } from '~generated-types'
import { T } from '@/modules/Language'

import {
  AccommodationTargetFilter,
  AgeCategoryFilter,
  EnrollmentStateFilter,
  GenderFilter,
  RoomFilter,
} from './Filters'

enum FilterType {
  RoomNumber = 'roomIds',
  AgeCategory = 'ageCategoryIds',
  Gender = 'genders',
  AccommodationTarget = 'accommodationTargetIds',
  EnrollmentState = 'enrollmentStates',
}

export const FilterModal = () => {
  const { filters, saleType, setFilters } = useParticipantsListContext()

  const [accommodationTargetFilters, setAccommodationTargetFilters] = useState<
    DefaultFilterType[] | null
  >(null)
  const [ageCategoryFilters, setAgeCategoryFilters] = useState<
    DefaultFilterType[] | null
  >(null)
  const [enrollmentStateFilters, setEnrollmentStateFilters] = useState<
    EnrollmentStateFilterType[] | null
  >(null)
  const [genderFilters, setGenderFilters] = useState<GenderFilterType[] | null>(
    null
  )
  const [roomFilters, setRoomFilters] = useState<DefaultFilterType[] | null>(
    null
  )

  const [selectedFilterPosition, setSelectedFilterPosition] =
    useState<number>(0)
  const [selectedFilter, setSelectedFilter] = useState<FilterType | null>(null)
  const [isOpen, setOpen] = useState<boolean>(false)

  useEffect(() => {
    setAccommodationTargetFilters(filters.accommodationTargetIds ?? null)
    setAgeCategoryFilters(filters.ageCategoryIds ?? null)
    setEnrollmentStateFilters(filters.enrollmentStates ?? null)
    setGenderFilters(filters.genders ?? null)
    setRoomFilters(filters.roomIds ?? null)
  }, [filters])

  const handleSelectFilter = (filter: FilterType, position: number) => {
    setSelectedFilter(selectedFilter === filter ? null : filter)
    setSelectedFilterPosition(position)
  }

  const handleCloseModal = () => {
    setFilters({
      ...filters,
      accommodationTargetIds: accommodationTargetFilters,
      ageCategoryIds: ageCategoryFilters,
      enrollmentStates: enrollmentStateFilters,
      genders: genderFilters,
      roomIds: roomFilters,
    })
    setSelectedFilter(null)
    setOpen(false)
  }

  const renderFilter = () => {
    switch (selectedFilter) {
      case FilterType.AccommodationTarget:
        return (
          <AccommodationTargetFilter
            filters={accommodationTargetFilters}
            setFilters={setAccommodationTargetFilters}
          />
        )
      case FilterType.AgeCategory:
        return (
          <AgeCategoryFilter
            filters={ageCategoryFilters}
            setFilters={setAgeCategoryFilters}
          />
        )
      case FilterType.EnrollmentState:
        return (
          <EnrollmentStateFilter
            filters={enrollmentStateFilters}
            setFilters={setEnrollmentStateFilters}
          />
        )
      case FilterType.Gender:
        return (
          <GenderFilter filters={genderFilters} setFilters={setGenderFilters} />
        )
      case FilterType.RoomNumber:
        return <RoomFilter filters={roomFilters} setFilters={setRoomFilters} />
      default:
        return null
    }
  }

  return (
    <ModalContainer
      isOpen={isOpen}
      modal={
        <FlexRow>
          <InlineModal>
            <FlexRow>
              <FilterOptionsWrapper>
                {Object.values(FilterType)
                  .filter(
                    (filter) =>
                      filter !== FilterType.EnrollmentState ||
                      saleType === SalesType.Event
                  )
                  .map((filter, index) => (
                    <FilterOption
                      alignItems="center"
                      justifyContent="space-between"
                      isSelected={selectedFilter === filter}
                      key={filter}
                      onClick={() => handleSelectFilter(filter, index)}
                    >
                      <T>{`ParticipantsList:filters.type.${filter}`}</T>
                      {selectedFilter === filter && (
                        <FontAwesomeIcon icon="angle-right" />
                      )}
                    </FilterOption>
                  ))}
              </FilterOptionsWrapper>
            </FlexRow>
          </InlineModal>

          <ModalWrapper position={selectedFilterPosition}>
            <InlineModal>
              {selectedFilter && (
                <SelectedFilterWrapper flex={1}>
                  {renderFilter()}
                </SelectedFilterWrapper>
              )}
            </InlineModal>
          </ModalWrapper>
        </FlexRow>
      }
      onClose={handleCloseModal}
      placement="bottom-start"
      referenceElement={({ ref }) => (
        <InnocuousButton
          innerRef={ref as Ref<HTMLButtonElement> | undefined}
          noNudge
          onClick={() => setOpen(true)}
        >
          <PrimaryColor>
            <Icon icon="filter" size="xs" />
            <T>ParticipantsList:filters.title</T>
          </PrimaryColor>
        </InnocuousButton>
      )}
    />
  )
}

////////

const FilterOption = styled(FlexRow)<{ isSelected: boolean }>`
  cursor: pointer;
  height: 32px;

  ${({ isSelected, theme }) => css`
    ${isSelected && `border-bottom: 1px solid ${theme.palette.smoke.main};`}
    ${isSelected && `border-top: 1px solid ${theme.palette.smoke.main};`}

    color: ${isSelected
      ? theme.palette.primary.dark
      : theme.palette.text.light};
    font-weight: ${isSelected ? 500 : 400};
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}
  }
`

const FilterOptionsWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem 0;
    width: ${theme.spacing.gu(30)}rem;
  `}
`

const ModalWrapper = styled.div<{ position: number }>`
  margin-left: 5px;

  ${({ position }) => css`
    margin-top: calc(32px * ${position});
  `}
`

const SelectedFilterWrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    min-width: ${theme.spacing.gu(30)}rem;
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    padding-right: ${theme.spacing.gu(1)}rem;
  `}
`
