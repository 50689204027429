import { DangerColor, PrimaryColor } from '@/components/Colors'
import { InlineModalFooter } from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

type Props = {
  handleAddPayment: () => void
  handleRemovePayment: () => void
  handleUpdatePayment: () => void
  isAddingDisabled: boolean
  isRemovingDisabled: boolean
  isEditMode: boolean
  isProcessing: boolean
  onClose: () => void
}

export const ReferencePaymentModalFooter = ({
  handleAddPayment,
  handleRemovePayment,
  handleUpdatePayment,
  isAddingDisabled,
  isRemovingDisabled,
  isEditMode,
  isProcessing,
  onClose,
}: Props) => (
  <InlineModalFooter justifyContent="space-between">
    {isEditMode ? (
      <>
        <InnocuousButton
          disabled={isRemovingDisabled}
          onClick={handleRemovePayment}
        >
          <DangerColor>
            <T>Orders:Payments.actions.remove</T>
          </DangerColor>
        </InnocuousButton>
        <InnocuousButton disabled={isProcessing} onClick={handleUpdatePayment}>
          <PrimaryColor>
            <T>common:action.save</T>
          </PrimaryColor>
        </InnocuousButton>
      </>
    ) : (
      <>
        <InnocuousButton onClick={onClose}>
          <T>common:action.cancel</T>
        </InnocuousButton>
        <InnocuousButton
          disabled={isAddingDisabled || isProcessing}
          onClick={handleAddPayment}
        >
          <PrimaryColor>
            <T>Orders:Payments.actions.addOther.PAYMENT</T>
          </PrimaryColor>
        </InnocuousButton>
      </>
    )}
  </InlineModalFooter>
)
