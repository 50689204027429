import { ReactNode, useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'

type ButtonPosition = 'end' | 'start'

type Props = {
  buttonPosition?: ButtonPosition
  disabled?: boolean
  headerPadding?: string
  inline?: boolean
  isExpandedInitially?: boolean
  hideExpandButton?: boolean
  glowAnimation?: boolean
  renderContent?: () => ReactNode
  renderHeader: (isExpanded: boolean) => ReactNode

  // Use these props to allow external control
  isExpanded?: boolean
  setIsExpanded?: (newValue: boolean) => void
}

export const ExpansionPanel = ({
  buttonPosition = 'end',
  disabled,
  headerPadding,
  inline,
  isExpanded: isExpandedExternal,
  isExpandedInitially,
  hideExpandButton,
  glowAnimation,
  renderContent,
  renderHeader,
  setIsExpanded: setIsExpandedExternal,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(
    isExpandedInitially || false
  )

  useEffect(() => {
    setIsExpanded(isExpandedExternal || isExpandedInitially || false)
  }, [isExpandedExternal, isExpandedInitially])

  const handleExpansion = (value: boolean) =>
    setIsExpandedExternal ? setIsExpandedExternal(value) : setIsExpanded(value)

  return (
    <ExpansionPanelWrapper
      disabled={disabled}
      glowAnimation={glowAnimation}
      flex={1}
      inline={inline}
      isExpanded={isExpanded}
    >
      <ExpansionPanelHeaderWrapper
        buttonPosition={buttonPosition}
        customPadding={headerPadding}
        isExpanded={isExpanded}
      >
        {renderHeader(isExpanded)}
        {renderContent && !hideExpandButton && (
          <Button
            disabled={disabled}
            onClick={() => handleExpansion(!isExpanded)}
          >
            <ExpansionIcon
              size="lg"
              icon={isExpanded ? 'angle-up' : 'angle-down'}
            />
          </Button>
        )}
      </ExpansionPanelHeaderWrapper>
      {renderContent && isExpanded && (
        <ExpansionPanelContentWrapper>
          {renderContent()}
        </ExpansionPanelContentWrapper>
      )}
    </ExpansionPanelWrapper>
  )
}

////////////

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  outline: none;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.lighter};
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1.5)}rem;
  `}
`

const glow = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

const ExpansionPanelWrapper = styled(FlexColumn)<{
  disabled?: boolean
  glowAnimation?: boolean
  inline?: boolean
  isExpanded: boolean
}>`
  padding: 0;
  border: 1px solid
    ${({ glowAnimation, theme }) =>
      glowAnimation ? theme.palette.primary.main : theme.palette.smoke.main};

  ${({ theme }) => css`
    background: ${theme.palette.white};
  `}

  box-shadow: none;
  position: relative;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 1;

  ${({ inline }) => inline && `display: inline-flex;`}

  ${FlexRow} ${Button} {
    ${({ disabled }) =>
      disabled &&
      `
        cursor: not-allowed;
        opacity: 0.5;
      `}
  }

  &:hover > ${FlexRow} ${Button} {
    ${({ disabled, theme }) =>
      !disabled &&
      css`
        color: ${theme.palette.primary.main};
        border-color: ${theme.palette.primary.main};
      `}
  }

  &:first-of-type {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
  }

  &:last-of-type {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  ${({ glowAnimation }) =>
    glowAnimation &&
    css`
      z-index: 1;
      animation: ${glow} 2s ease-in-out infinite;
    `}

  ${({ isExpanded, theme }) =>
    isExpanded &&
    css`
      margin-bottom: ${theme.spacing.gutterBig};

      &:not(:first-of-type) {
        margin-top: ${theme.spacing.gutterBig};
      }

      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.09),
        0 1px 18px 0 rgba(0, 0, 0, 0.08), 0 3px 5px -1px rgba(0, 0, 0, 0.14);
    `}

  ${() =>
    [...Array(50).keys()]
      .map(
        (x) => `
          &:nth-of-type(${x + 2}) {
            top: -${x + 1}px;
          }
        `
      )
      .join('')}
`

const ExpansionPanelContentWrapper = styled(FlexColumn)`
  padding: 0;
  margin: 0;
`

const ExpansionPanelHeaderWrapper = styled(FlexRow)<{
  buttonPosition: ButtonPosition
  customPadding?: string
  isExpanded: boolean
}>`
  flex-direction: ${({ buttonPosition }) =>
    buttonPosition === 'start' ? 'row-reverse' : 'row'};
  padding: ${({ customPadding, theme }) =>
    customPadding
      ? customPadding
      : `${theme.spacing.gu(1.5)}rem 0 ${theme.spacing.gu(1.5)}rem ${
          theme.spacing.gutter
        }`};

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutter};
  `}

  align-items: center;

  ${({ isExpanded }) =>
    isExpanded
      ? `
        border-bottom-width: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      `
      : ''}
`

const ExpansionIcon = styled(FontAwesomeIcon)`
  align-self: center;
`
