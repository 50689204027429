import { useState } from 'react'

import { translate, useLanguageContext } from '@/modules/Language'
import { FetchedParticipant } from '@/modules/ParticipantsList'

import { isSameOption } from '../../utils'
import { LanguageSelector } from '../../../../../ParticipantPersonalInfo/modules/components/LanguageSelector'

type Props = {
  handleUpdateParticipan: (data: Partial<FetchedParticipant>) => Promise<void>
  selectedParticipants: FetchedParticipant[]
  readOnly: boolean
}

export const LanguageSelectorWrapper = ({
  handleUpdateParticipan,
  selectedParticipants,
  readOnly,
}: Props) => {
  const { language } = useLanguageContext()

  const [isLoading, setLoading] = useState<boolean>(false)

  const participantLanguage = isSameOption(selectedParticipants, 'language')
    ? selectedParticipants[0]?.language || null
    : null

  const handleUpdateLanguage = async (value: string | null) => {
    setLoading(true)
    handleUpdateParticipan({ language: value }).then(() => setLoading(false))
  }

  return (
    <LanguageSelector
      isDisabled={readOnly}
      isLoading={isLoading}
      title={translate('ParticipantsList:PersonalFields.language', language)}
      participantLanguage={participantLanguage || ''}
      handleSetLanguage={handleUpdateLanguage}
    />
  )
}
