import { gql } from '@apollo/client'

import { EventEnrollmentFragment } from './EventEnrollments.fragments'

export default Object.freeze({
  CREATE_ENROLLMENT: gql`
    ${EventEnrollmentFragment}

    mutation CreateEventEnrollment(
      $input: SalesCreateEnrollmentInput!
      $enrollmentsInput: SalesEnrollmentsInput
    ) {
      salesCreateEnrollment(input: $input) {
        ...EventEnrollment
        event {
          enrollments(input: $enrollmentsInput) {
            totalPages
            totalElements
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  `,
})
