import styled, { css } from 'styled-components/macro'
import React from 'react'

import RowSpacer from './ListingTableRowSpacer'

import { PrintSize } from '../types'

interface HeaderSection {
  content: React.ReactNode
  size: number
  width?: string
}

interface StickyProps {
  top: string
}

interface Props {
  children?: React.ReactNode
  colSpan: number
  hideFromPrint?: boolean
  noSpacer?: boolean
  parts?: HeaderSection[]
  printSize: PrintSize
  stickyProps?: StickyProps
}

export const ListingTableSubheader = ({
  children,
  colSpan,
  hideFromPrint,
  noSpacer,
  parts,
  printSize,
  stickyProps,
}: Props) => (
  <>
    {!noSpacer && <RowSpacer colSpan={colSpan} hideFromPrint={hideFromPrint} />}
    <TR hideFromPrint={hideFromPrint} stickyProps={stickyProps}>
      {parts ? (
        parts.map(({ content, size, width }, idx) => (
          <TD
            colSpan={size}
            key={`header-part-${idx}`}
            printSize={printSize}
            style={width ? { width } : {}}
          >
            {content}
          </TD>
        ))
      ) : (
        <TD colSpan={colSpan} printSize={printSize}>
          {children}
        </TD>
      )}
    </TR>
  </>
)

////////////

const TR = styled.tr<{ hideFromPrint?: boolean; stickyProps?: StickyProps }>`
  page-break-after: avoid;

  ${({ stickyProps }) =>
    stickyProps
      ? css`
          position: sticky;
          top: ${stickyProps.top};

          @media print {
            position: relative;
            top: 0;
          }
        `
      : ''}

  ${({ hideFromPrint }) =>
    hideFromPrint
      ? css`
          @media print {
            display: none;
          }
        `
      : ''}
`

const TD = styled.td<{ printSize: PrintSize }>`
  && {
    font-variant-caps: all-small-caps;

    ${({ theme }) => css`
      background: ${theme.palette.smoke.light};
      color: ${theme.palette.text.light};
    `}

    @media print {
      font-weight: 500;
      -webkit-print-color-adjust: exact;

      ${({ printSize }) => {
        let size = '9pt'

        if (printSize === PrintSize.Compact) {
          size = '8pt'
        }
        if (printSize === PrintSize.Large) {
          size = '10pt'
        }

        return css`
          font-size: ${size};
        `
      }}

      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
        color: ${theme.palette.text.main};
      `}
    }
  }
`
