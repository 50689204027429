import { gql, useMutation } from '@apollo/client'

// @ts-ignore - remove after typegen
import { CloseSaleMutation, CloseSaleMutationVariables } from '~generated-types'

export type { CloseSaleMutation, CloseSaleMutationVariables }

export const CLOSE_SALE_MUTATION = gql`
  mutation CloseSale($input: SalesCloseInput!) {
    salesClose(input: $input) {
      id
      locked
      lifecycle {
        state
      }
    }
  }
`

export function useCloseSaleMutation() {
  return useMutation<CloseSaleMutation, CloseSaleMutationVariables>(
    CLOSE_SALE_MUTATION
  )
}

export default useCloseSaleMutation
