import { ApolloError, gql, useQuery } from '@apollo/client'
import { Moment } from 'moment'
import uniqBy from 'lodash.uniqby'
import { useMemo } from 'react'

import {
  RoomReservationsForDateQuery as QueryData,
  RoomReservationsForDateQueryVariables as QueryVariables,
} from '~generated-types'

import { Participant, RoomReservation, Sales } from '../types'
import { RoomFragment, RoomReservationFragment } from '../fragments'

const QUERY = gql`
  ${RoomFragment}
  ${RoomReservationFragment}

  query RoomReservationsForDate($input: ReservationSearchInput!) {
    registry {
      rooms {
        ...RoomReservationsForDateRoom
      }
    }
    accommodationRoomReservations(input: $input) {
      reservations(capacityMode: false) {
        ...RoomReservationsForDateRoomReservation
      }
    }
  }
`

interface Props {
  date: Moment
}

interface Hook {
  availableParticipants: Participant[]
  availableSales: Sales[]
  error?: ApolloError
  loading: boolean
  roomReservations: RoomReservation[]
}

export const useRoomReservationsForDate = ({ date }: Props): Hook => {
  const { data, error, loading } = useQuery<QueryData, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      input: {
        date: date.format('YYYY-MM-DD'),
      },
    },
  })

  const roomReservations: RoomReservation[] = useMemo(
    () =>
      data?.registry.rooms.map(
        (room): RoomReservation => ({
          reservations: data.accommodationRoomReservations.reservations.filter(
            ({ request }) => request.room.id === room.id
          ),
          room,
        })
      ) ?? [],
    [data]
  )

  const availableParticipants: Participant[] = useMemo(
    () =>
      data?.accommodationRoomReservations.reservations.reduce(
        (acc: Participant[], { participantRooms }) => {
          const participants = participantRooms.map(
            ({ participant }) => participant
          )
          return uniqBy([...acc, ...participants], 'id')
        },
        []
      ) ?? [],
    [data]
  )

  const availableSales: Sales[] = useMemo(
    () =>
      data?.accommodationRoomReservations.reservations.reduce(
        (acc: Sales[], { sales }) =>
          !acc.find(({ id }) => id === sales.id) ? [...acc, sales] : acc,
        []
      ) ?? [],
    [data]
  )

  return {
    availableParticipants,
    availableSales,
    error,
    loading,
    roomReservations,
  }
}
