import moment from 'moment'
import { useState } from 'react'

import { Confirmation, ConfirmationInfo } from '@/modules/Order/types'
import { Palette, useTheme } from '@/theme'
import { formatCurrency } from '@/utils/currency'
import { OrderConfirmationState as OCS } from '~generated-types'
import { T } from '@/modules/Language'

import {
  ChevronIcon,
  HeaderLabel,
  HeaderRow,
  PriceLabel,
  Spacer,
} from '../../common'

type Props = {
  confirmation: Confirmation | ConfirmationInfo
  isOpen: boolean
  isLoading: boolean
  openContent: () => void
}

export const Header = ({
  confirmation: {
    auditLog: { createdAt },
    lifecycle: { state },
    totals: { amountVatIncluded: totalAmount },
    type,
  },
  isOpen,
  isLoading,
  openContent,
}: Props) => {
  const { palette, spacing } = useTheme()

  const [isHover, setHover] = useState<boolean>(false)

  return (
    <HeaderRow
      alignItems="center"
      onClick={openContent}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ChevronIcon
        icon="chevron-right"
        hover={isHover ? 1 : undefined}
        loading={isLoading ? 1 : undefined}
        open={isOpen}
      />

      <HeaderLabel width={`${spacing.gu(26)}rem`}>
        <T>{`Orders:Header.title.${type}`}</T>
      </HeaderLabel>

      <HeaderLabel width={`${spacing.gu(24)}rem`}>
        {moment(createdAt).format('dd, D.M.YYYY, HH:mm')}
      </HeaderLabel>

      <HeaderLabel
        width={`${spacing.gu(20)}rem`}
        color={getStateColor(state, palette)}
      >
        <T>{`Orders:State.${state}`}</T>
      </HeaderLabel>

      <Spacer />

      <PriceLabel width={`${spacing.gu(16)}rem`}>
        {formatCurrency(totalAmount)} €
      </PriceLabel>

      <HeaderLabel width={`${spacing.gu(6.5)}rem`} />
    </HeaderRow>
  )
}

//////

const getStateColor = (state: OCS, palette: Palette) => {
  switch (state) {
    case OCS.Accepted:
      return palette.success.dark
    case OCS.Cancelled:
      return palette.danger.dark
    case OCS.Draft:
    default:
      return palette.primary.dark
  }
}
