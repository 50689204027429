import { gql } from '@apollo/client'

import { orderFragments } from '@/modules/Order'

export default gql`
  ${orderFragments.offer}

  query OfferById($id: ID!) {
    offer(id: $id) {
      ...Offer
    }
  }
`
