import { FlexRow } from '@/components/Layout'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { Section, VerticalDivider } from '../../common'

export const PublishedRoomsHeader = () => {
  const { spacing } = useTheme()

  return (
    <FlexRow>
      <Section flex={1} noLeftMargin>
        <Label>
          <T>Publish:Rooms.rooms</T>
        </Label>
      </Section>

      <VerticalDivider />

      <Section flex={1}>
        <Label>
          <T>Publish:Rooms.reserved.title</T>
        </Label>
      </Section>

      <VerticalDivider />

      <Section flex={1.75}>
        <Label>
          <T>Publish:Rooms.product.title</T>
        </Label>
      </Section>

      <VerticalDivider />

      <Section flex={1.75}>
        <Label>
          <T>Publish:Rooms.webshopRoom.title</T>
        </Label>
      </Section>

      <VerticalDivider />

      <Section style={{ width: `${spacing.gu(14)}rem` }}>
        <Label>
          <T>Publish:Rooms.minGuests</T>
        </Label>
      </Section>

      <VerticalDivider />

      <Section style={{ width: `${spacing.gu(14)}rem` }}>
        <Label>
          <T>Publish:Rooms.maxGuests</T>
        </Label>
      </Section>

      <VerticalDivider />

      <Section flex={1.5}>
        <Label>
          <T>Publish:Rooms.publish</T>
        </Label>
      </Section>

      <VerticalDivider />

      <Section noRightMargin style={{ width: `${spacing.gu(4)}rem` }} />
    </FlexRow>
  )
}
