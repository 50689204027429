import { gql, useQuery } from '@apollo/client'

import {
  UseVoucherProvidersQuery as QueryData,
  UseVoucherProvidersQueryVariables as QueryVariables,
} from '~generated-types'

export type VoucherProvider = QueryData['registry']['voucherProviders'][0]

export const QUERY = gql`
  query UseVoucherProviders {
    registry {
      voucherProviders {
        id
        name
      }
    }
  }
`

type Params = {
  forceRefetch?: boolean
}

export default function useVoucherProviders(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
    }
  )

  const voucherProviders: VoucherProvider[] = data
    ? data.registry.voucherProviders
    : []

  return {
    error,
    loading,
    refetch,
    voucherProviders,
  }
}
