import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { Canvas } from '@/components/Canvas'

type Props = {
  children: ReactNode
}
export const SideDrawerBottomControls = ({ children }: Props) => (
  <Wrapper>
    <CanvasBorderTop>{children}</CanvasBorderTop>
  </Wrapper>
)

const Wrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
`

const CanvasBorderTop = styled(Canvas)`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.smoke.light};
  `}
`
