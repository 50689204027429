import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { T, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'

const HEADER_TEMPLATE_STRING = {
  en: 'First name	Last name	Age	Gender	Birthday	Nationality	Country	City	Postcode	Address 1	Address 2	Allergy description	Drug allergy description	Info	Group 	Room number	Education	Life stage	Language',
  fi: 'Etunimi	Sukunimi	Ikä	Sukupuoli	Syntymäaika	Kansalaisuus	Katuosoite 1	Katuosoite 2	Maa	Postinumero	Postitoimipaikka	Allergian kuvaus	Lääkeaineallergiat	Lisätiedot	Ryhmä	Huonenumero	Koulutus	Elämänvaihe	Kieli',
}

export const CopyTemplateButton = () => {
  const { language } = useLanguageContext()

  const [copied, setCopied] = useState<boolean>(false)

  useEffect(() => {
    setTimeout(() => setCopied(false), 4000)
  }, [copied])

  const handleCopyTemplate = () => {
    const lang = language === 'en' ? 'en' : 'fi'

    navigator.clipboard.writeText(HEADER_TEMPLATE_STRING[lang])

    setCopied(true)
  }

  return (
    <FlexRow alignItems="center">
      <CopiedMessage active={copied}>
        <FontWeight bold>
          <CheckIcon icon="check" />
          <T>ParticipantsList:Import.copied</T>
        </FontWeight>
      </CopiedMessage>

      <CopyButton onClick={handleCopyTemplate}>
        <PrimaryColor>
          <CopyIcon icon={['far', 'file-excel']} />
          <T>ParticipantsList:Import.copyTemplate</T>
        </PrimaryColor>
      </CopyButton>
    </FlexRow>
  )
}

/////////

const CheckIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}}
`

const CopiedMessage = styled.div<{ active?: boolean }>`
  ${({ theme, active }) => css`
    color: ${theme.palette.success.main};
    opacity: ${active ? 1 : 0};
    transition: ${active ? 0 : 3}s;
  `}}
`

const CopyButton = styled(InnocuousButton)`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gu(2)}rem;
  `}}
`

const CopyIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}}
`
