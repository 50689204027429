import { gql, useMutation } from '@apollo/client'

import {
  CancelSaleMutation,
  CancelSaleMutationVariables,
} from '~generated-types'

export type { CancelSaleMutation, CancelSaleMutationVariables }

export const CANCEL_SALE_MUTATION = gql`
  mutation CancelSale($input: SalesCancelInput!) {
    salesCancel(input: $input) {
      id
      locked
      lifecycle {
        state
      }
    }
  }
`

export function useCancelSaleMutation() {
  return useMutation<CancelSaleMutation, CancelSaleMutationVariables>(
    CANCEL_SALE_MUTATION
  )
}

export default useCancelSaleMutation
