import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import {
  ElasticRoom,
  useRoomLayoutContext,
} from '@/modules/Reservations/components/RoomLayout'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { CheckboxInput } from '@/components/FormControls'
import { FontWeight } from '@/components/Typography'
import { generateCompareFn } from '@/utils/arrays'
import { Tooltip } from '@/components/Tooltip'
import { useGlobalIconsContext } from '@/theme'

import { PlusButton } from './PlusButton'
import { RoomNote } from './RoomNote'

type Props = {
  isProcessing: boolean
  onPlay: (...args: Array<any>) => any
  room: ElasticRoom
}

export const HeaderLabelWrapper = ({ isProcessing, onPlay, room }: Props) => {
  const {
    isCleaningMode,
    onSetSelectedRoomIds,
    roomsBySelectedRoomType,
    selectedRoomIds,
    selectedRoomType,
  } = useRoomLayoutContext()
  const { checkIconValidity } = useGlobalIconsContext()

  const isRoomSelected = !!selectedRoomIds.find((id: string) => id === room.id)
  const isRoomTypeSelected =
    selectedRoomType.id && selectedRoomType.reservationId
  const isSuitable = !!roomsBySelectedRoomType.find(
    (r: ElasticRoom) => r.id === room.id
  )

  const handleSetSelectedRooms = () =>
    onSetSelectedRoomIds(
      isRoomSelected
        ? selectedRoomIds.filter((id: string) => id !== room.id)
        : [...selectedRoomIds, room.id]
    )

  return (
    <>
      {isCleaningMode ? (
        <CheckboxWrapper>
          <CheckboxInput
            checked={isRoomSelected}
            noMargin
            onChange={() => handleSetSelectedRooms()}
          />
        </CheckboxWrapper>
      ) : (
        <RoomNote
          buildingId={room.building.id}
          id={room.id}
          note={room.internalInfo}
        />
      )}

      <Tooltip
        content={
          <FlexColumn noPadding>
            <FlexRow>
              {room.number}
              <Divider>|</Divider>
              {room.accommodationLevel.name}
            </FlexRow>
            {!!room.features.length && (
              <FeaturesColumn>
                {[...room.features]
                  .sort(generateCompareFn('name'))
                  .map((feature) => (
                    <FlexRow alignItems="center" key={feature.id}>
                      {checkIconValidity(feature.icon) ? (
                        <FeatureIcon
                          icon={feature.icon as IconProp}
                          size="sm"
                        />
                      ) : (
                        <FeatureIconPlaceholder />
                      )}
                      <FeatureName>{feature.name}</FeatureName>
                    </FlexRow>
                  ))}
              </FeaturesColumn>
            )}
          </FlexColumn>
        }
        delay={300}
        placement="top"
        trigger={(triggerProps) => (
          <Wrapper isSuitable={isSuitable} {...triggerProps}>
            <RoomNumber>{room.number}</RoomNumber>
            <Divider>|</Divider>
            <RoomSize>
              {room.beds}+{room.extraBeds}
            </RoomSize>
            {!!room.features.length && (
              <>
                <Divider>|</Divider>
                <FeatureRow>
                  {[...room.features]
                    .sort(generateCompareFn('name'))
                    .map((feature) => (
                      <Feature key={feature.id}>
                        {checkIconValidity(feature.icon) ? (
                          <FontAwesomeIcon
                            fixedWidth
                            icon={feature.icon as IconProp}
                            size="sm"
                          />
                        ) : (
                          <FontWeight>{feature.shortName}</FontWeight>
                        )}
                      </Feature>
                    ))}
                </FeatureRow>
              </>
            )}
          </Wrapper>
        )}
      />

      {isRoomTypeSelected && !isCleaningMode && (
        <PlusButton disabled={isProcessing} onClick={onPlay} />
      )}
    </>
  )
}

///////

const CheckboxWrapper = styled.span`
  text-align: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(6)}rem;
  `}
`

const Divider = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const FeatureIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
  `}
`

const FeatureIconPlaceholder = styled.span`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(2)}rem;
  `}
`

const FeatureName = styled.span`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(0.25)}rem ${theme.spacing.gu(1)}rem;
  `}
`

const FeatureRow = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Feature = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeSmall};
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const RoomNumber = styled.span`
  font-weight: 500;
  text-align: end;
  white-space: nowrap;
`

const RoomSize = styled.span`
  text-align: center;
  white-space: nowrap;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmall};
    width: ${theme.spacing.gutterBig};
  `}
`

const FeaturesColumn = styled(FlexColumn)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled.div<{ isSuitable: boolean }>`
  display: flex;
  flex: 1;
  width: calc(100% - 6rem);
  align-items: center;
  background: ${({ isSuitable, theme }) =>
    isSuitable ? '#e8f7e2' : theme.palette.white};
  height: 26px;
  border-radius: 4px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(1)}rem;
    margin-left: -${theme.spacing.gu(1)}rem;
  `}
`
