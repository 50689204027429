import styled, { css } from 'styled-components/macro'
import { ApolloQueryResult } from '@apollo/client'
import moment from 'moment'
import { useState } from 'react'

import { Attributes } from '@/modules/Order/components'
import { Document } from '@/modules/Document'
import { DocumentUserAttribute } from '@/modules/Order/types'
import { FlexColumn } from '@/components/Layout'
import { orderMutations } from '@/modules/Order'
import { T } from '@/modules/Language'
import { UseOwnerDocumentsQuery } from '~generated-types'

type Props = {
  document: Document
  refetch: () => Promise<ApolloQueryResult<UseOwnerDocumentsQuery>>
  refetchPdf: () => Promise<void>
  showFreeText: boolean
}

export const Sections = ({
  document,
  refetch,
  refetchPdf,
  showFreeText,
}: Props) => {
  const { downloadedAt, id, userAttributes } = document

  const [attributes, setAttributes] =
    useState<DocumentUserAttribute[]>(userAttributes)

  const [updateUserAttribute] =
    orderMutations.useUpdateDocumentUserAttributeMutation()

  const onToggleAttribute = ({
    active,
    editorValue,
    key,
    templateValue,
  }: DocumentUserAttribute) => {
    if (!templateValue) {
      setAttributes(
        attributes.map((a) => (a.key === key ? { ...a, active: !active } : a))
      )
    } else {
      updateUserAttribute({
        variables: {
          input: {
            active: !active,
            documentId: id,
            editorValue,
            key,
            templateValue,
          },
        },
      })
        .then(({ data }) => {
          const userAttribute = data?.documentUserAttributeUpdate.userAttribute

          if (userAttribute) {
            setAttributes(
              attributes.map((a) => (a.key === key ? userAttribute : a))
            )
          }
        })
        .then(refetch)
        .then(refetchPdf)
        .catch(() => undefined)
    }
  }

  const onUpdateAttributes = ({
    active,
    editorValue,
    key,
    templateValue,
  }: DocumentUserAttribute) => {
    updateUserAttribute({
      variables: {
        input: { active, documentId: id, editorValue, key, templateValue },
      },
    })
      .then(({ data }) => {
        const userAttribute = data?.documentUserAttributeUpdate.userAttribute

        if (userAttribute) {
          setAttributes(
            attributes.map((a) => (a.key === key ? userAttribute : a))
          )
        }
      })
      .then(refetch)
      .then(refetchPdf)
      .catch(() => undefined)
  }

  return (
    <>
      <Section>
        <Title>
          <T>SalesDetails:VST.printedAt</T>
        </Title>

        {downloadedAt ? (
          <Date>{moment(downloadedAt).format('dd. DD.MM.YY, HH:mm')}</Date>
        ) : (
          <Placeholder>
            <T>SalesDetails:VST.printedAtPlaceholder</T>
          </Placeholder>
        )}
      </Section>

      <HorizontalLine />

      {showFreeText && !!attributes.length && (
        <>
          <Attributes
            attributes={attributes}
            onToggle={onToggleAttribute}
            onUpdate={onUpdateAttributes}
          />

          <HorizontalLine />
        </>
      )}
    </>
  )
}

///////

const Date = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`

const HorizontalLine = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.smoke.light};
  `}
`

const Placeholder = styled.span`
  font-style: italic;

  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Section = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter} ${theme.spacing.gutterBig};
  `}
`

const Title = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    margin-bottom: ${theme.spacing.gu(1)}rem;
  `}
`
