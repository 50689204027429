import { ReactNode, useState } from 'react'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { ElasticCustomer } from '@/modules/Registry'
import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { SortOption } from '@/components/Reactivesearch'

import ListControls from './components/ListControls'
import ListFilters from './components/ListFilters'
import ListHeader from './components/ListHeader'
import ListItem from './components/ListItem'

const ComponentIds = Object.freeze({
  LABEL: 'label',
  LIST: 'page',
  SEARCH: 'search',
  TYPE: 'type',
})

const CUSTOMER_INDEX = 'customer.customer'

export type CustomerTarget = {
  customerNumber: string
  id?: string
}

type Props = {
  highlightTarget?: CustomerTarget | null | undefined
  onAdd?: () => void
  onOpen: (target: CustomerTarget) => void
  renderItemControls?: (args: CustomerTarget) => ReactNode
  URLParams?: boolean
}

export const CustomerList = ({
  highlightTarget,
  onAdd,
  onOpen,
  renderItemControls,
  URLParams = true,
}: Props) => {
  const { language } = useLanguageContext()

  const sortOptions: SortOption[] = [
    {
      dataField: 'customerNumber.numeric',
      direction: 'asc',
      label: translate(
        'Customers:CustomerList.sortOptions.customerNumberAsc',
        language
      ),
    },
    {
      dataField: 'customerNumber.numeric',
      direction: 'desc',
      label: translate(
        'Customers:CustomerList.sortOptions.customerNumberDesc',
        language
      ),
    },
    {
      dataField: 'searchOptimization.name.keyword',
      direction: 'asc',
      label: translate(
        'Customers:CustomerList.sortOptions.searchOptimizationNameAsc',
        language
      ),
    },
  ]

  const [sortProperty, setSortProperty] = useState<SortOption | null>(
    sortOptions[0]
  )

  const sort = sortProperty
    ? [
        { _score: 'desc' },
        { [sortProperty.dataField]: sortProperty.direction },
        { 'customerNumber.numeric': 'desc' },
      ]
    : [{ _score: 'desc' }, { 'customerNumber.numeric': 'desc' }]

  const defaultQueryFn = () =>
    highlightTarget
      ? {
          query: {
            bool: {
              should: [
                {
                  match_all: {},
                },
                {
                  bool: {
                    boost: 2,
                    must: [
                      {
                        term: {
                          customerNumber: highlightTarget.customerNumber,
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        }
      : {}

  return (
    <ElasticFilterSearchList
      columnCount={renderItemControls ? 6 : 5}
      indexName={CUSTOMER_INDEX}
      placeholders={{
        empty: <T>Customers:CustomerList.empty</T>,
        error: <T>Customers:CustomerList.error</T>,
      }}
      reactiveListProps={{
        componentId: ComponentIds.LIST,
        dataField: 'customerNumber.text',
        defaultQuery: () => ({
          ...defaultQueryFn(),
          sort,
        }),
        pagination: true,
        react: {
          and: [ComponentIds.LABEL, ComponentIds.SEARCH, ComponentIds.TYPE],
        },
        size: 10,
      }}
      renderListControls={() => <ListControls handleAdd={onAdd} />}
      renderListFilters={() => (
        <ListFilters
          componentIds={ComponentIds}
          getDefaultQuery={defaultQueryFn}
          URLParams={URLParams}
        />
      )}
      renderListHeader={() => (
        <ListHeader hasItemControls={!!renderItemControls} />
      )}
      renderListItem={(i: ElasticCustomer) => (
        <ListItem
          key={i.id}
          data={i}
          isHighlighted={
            `${i.customerNumber}` === highlightTarget?.customerNumber
          }
          itemControls={
            renderItemControls
              ? renderItemControls({
                  customerNumber: `${i.customerNumber}`,
                  id: i.id,
                })
              : null
          }
          onOpen={() =>
            onOpen({ customerNumber: `${i.customerNumber}`, id: i.id })
          }
        />
      )}
      selectedFiltersProps={{
        l10nPrefixes: { type: 'Customers:CustomerList.field.types' },
      }}
      sortProps={{
        options: sortOptions,
        setValue: setSortProperty,
        value: sortProperty,
      }}
      title={<T>Customers:CustomerList.title</T>}
    />
  )
}
