import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { CheckBox } from '@/modules/ParticipantsList/components/common'
import { useTheme } from '@/theme'

type Props = {
  rootRoomId?: string | undefined
  hideAdditional?: boolean
  children?: React.ReactChild
  firstName?: string
  lastName?: string
}

const MainInfoPlaceholder = ({
  rootRoomId,
  hideAdditional,
  children,
  firstName,
  lastName,
}: Props) => {
  const { palette, spacing } = useTheme()

  return (
    <Wrapper noPadding>
      <HeaderWrapper>
        <FlexRow
          alignItems={'stretch'}
          justifyContent={'flex-start'}
          flex={1}
          style={{ minHeight: '35px' }}
        >
          <FlexRow style={{ maxHeight: '35px' }} alignItems="center">
            {!rootRoomId && <CheckBox isDisabled />}

            <NameWrapper>{lastName || <BoxPlaceholder noMargin />}</NameWrapper>
            <NameWrapper>
              {firstName || <BoxPlaceholder noMargin />}
            </NameWrapper>

            <IconWrapper>
              <FontAwesomeIcon
                icon="circle"
                color={palette.smoke.dark}
                size={'lg'}
              />
            </IconWrapper>

            <BoxPlaceholder widthGu={12} />

            <BoxPlaceholder widthGu={5} height={'100%'} noMargin />
          </FlexRow>

          {children || <span style={{ flex: 1 }} />}

          <FlexRow style={{ marginLeft: `${spacing.gu(1)}rem` }}>
            {!hideAdditional && (
              <IconWrapper widthGu={3.5}>
                <FontAwesomeIcon
                  icon="circle"
                  color={palette.smoke.dark}
                  size={'lg'}
                />
              </IconWrapper>
            )}
            <IconWrapper widthGu={3.5}>
              <FontAwesomeIcon
                icon="circle"
                color={palette.danger.light}
                size={'lg'}
              />
            </IconWrapper>
          </FlexRow>
        </FlexRow>
      </HeaderWrapper>
    </Wrapper>
  )
}

export default MainInfoPlaceholder

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    background-color: ${theme.palette.white};
    margin-top: -1px;
  `}
`

const HeaderWrapper = styled.div`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gu(1)}rem;
    padding-left: 0;
  `}
`

const NameWrapper = styled.div`
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(10)}rem;
    margin: 0 ${theme.spacing.gu(1)}rem;
  `}
`

const BoxPlaceholder = styled.div<{
  widthGu?: number
  height?: string
  noMargin?: boolean
}>`
  ${({ theme, widthGu, height, noMargin }) => css`
    background-color: ${theme.palette.smoke.dark};
    width: ${theme.spacing.gu(widthGu || 10)}rem;
    height: ${height || `${theme.spacing.gu(2)}rem`};
    ${!noMargin && `margin: 0 ${theme.spacing.gu(1)}rem`};
  `}
`

const IconWrapper = styled.div<{
  widthGu?: number
}>`
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme, widthGu }) => css`
    width: ${theme.spacing.gu(widthGu || 5)}rem;
    padding: ${theme.spacing.gu(1)}rem;
  `}
`
