import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { TaskCustomer, TaskSales } from '@/modules/Task'
import { Chip } from '@/components/Chip'
import { InlineModalIconSection } from '@/components/InlineModal'
import { SalesState } from '~generated-types'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { EditButton } from './common'

type Props = {
  owner: TaskSales
}

export const Owner = ({
  owner: {
    customer,
    id,
    lifecycle: { state },
    name,
    orderNumber,
  },
}: Props) => {
  const theme = useTheme()

  const openSalesInNewTab = () => {
    const url = `/sales/details/${id}`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  return (
    <InlineModalIconSection
      icon="euro-sign"
      style={{ paddingTop: theme.spacing.gutterSmall }}
    >
      <EditButton onClick={openSalesInNewTab}>
        <FlexRow justifyContent="space-between">
          <FlexColumn alignItems="flex-start">
            <SalesWrapper alignItems="center">
              {name && <SalesName>{name}</SalesName>}

              {`#${orderNumber}`}

              <Chip
                color={getStateColor(state)}
                size="xs"
                style={{ marginLeft: `${theme.spacing.gu(1)}rem` }}
                variant="outlined"
              >
                <T>{`SalesDetails:Lifecycle.state.${state}`}</T>
              </Chip>
            </SalesWrapper>

            {customer?.customer && (
              <CustomerWrapper alignItems="center">
                <FontAwesomeIcon
                  size={
                    customer.customer.__typename === 'CustomerPerson'
                      ? 'xs'
                      : 'sm'
                  }
                  color={theme.palette.text.lighter}
                  icon={
                    customer.customer.__typename === 'CustomerPerson'
                      ? 'user'
                      : ['far', 'building']
                  }
                  style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
                />
                {getCustomerName(customer.customer)}
              </CustomerWrapper>
            )}
          </FlexColumn>
        </FlexRow>

        <ExternalLinkIcon
          color={theme.palette.primary.main}
          icon="up-right-from-square"
        />
      </EditButton>
    </InlineModalIconSection>
  )
}

////////////

const getCustomerName = (customer: TaskCustomer) => {
  if (customer.__typename === 'CustomerOrganization') {
    return customer.organization.name ?? ''
  }

  if (customer.__typename === 'CustomerPerson') {
    return `${customer.person.lastName ?? ''}${
      customer.person.firstName && customer.person.lastName ? ', ' : ''
    }${customer.person.firstName ?? ''}`
  }
}

const getStateColor = (state: SalesState) => {
  switch (state) {
    case 'CANCELLED':
      return 'danger'
    case 'DRAFT':
    case 'TEMPORAL':
      return 'default'
    case 'CLOSED':
      return 'success'
    default:
      return 'primary'
  }
}

const CustomerWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.guPx(1) - 3}px;
  `}
`

const ExternalLinkIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const SalesName = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    max-width: ${theme.spacing.gu(32)}rem;
  `}
`

const SalesWrapper = styled(FlexRow)`
  font-weight: 500;
  cursor: pointer;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase2};
  `}
`
