import moment, { Moment } from 'moment'
import { ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components/macro'

import { DateRangePicker } from '@/components/TimeControls'
import { InnocuousButton } from '@/components/ExtraButtons'
import { T } from '@/modules/Language'

import { CollapsedFilterValue } from '../CollapsedFilterValue'
import { getQuery } from './utils'
import { titleCss } from '../styles'

type DateRangeType = {
  end: Moment
  start: Moment
}

type Props = {
  componentId: string
  dataField: string | { start: string; end: string }
  defaultValue?: DateRangeType
  hideClearButton?: boolean
  isCollapsed?: boolean
  setQuery: (...args: Array<any>) => any
  title?: ReactNode
  value: string | null | undefined
}

const DATE_SEPARATOR = '–' // Use an endash to allow splitting

export const DateRange = ({
  componentId,
  dataField,
  defaultValue,
  hideClearButton,
  isCollapsed,
  setQuery,
  title,
  value,
}: Props) => {
  const isInitialised = useRef<boolean>(false)

  const [propsDefaultStart, propsDefaultEnd] = value
    ? value.split(DATE_SEPARATOR)
    : defaultValue
    ? [defaultValue.start, defaultValue.end]
    : []

  const [end, setEnd] = useState<Moment | null | undefined>(
    propsDefaultEnd
      ? moment(propsDefaultEnd)
      : propsDefaultStart
      ? moment(propsDefaultStart)
      : null
  )
  const [start, setStart] = useState<Moment | null | undefined>(
    propsDefaultStart ? moment(propsDefaultStart) : null
  )
  const [range, setRange] = useState<DateRangeType | null | undefined>(
    end && start ? { end, start } : null
  )

  useEffect(() => {
    if (!isInitialised.current) {
      setQuery(getQuery(dataField, range))

      isInitialised.current = true
    } else {
      const [propsStart, propsEnd] = value ? value.split(DATE_SEPARATOR) : []

      const targetRange = propsStart
        ? { end: moment(propsEnd || propsStart), start: moment(propsStart) }
        : null

      if (!targetRange) {
        setEnd(null)
        setRange(null)
        setStart(null)

        setQuery(getQuery(dataField, targetRange))
      }
    }
  }, [value])

  const handleChangeDates = (dates: {
    startDate: Moment | null | undefined
    endDate: Moment | null | undefined
  }) => {
    const { endDate, startDate } = dates

    setEnd(endDate)
    setStart(startDate)

    const range: DateRangeType | null | undefined = !!startDate
      ? {
          end: (endDate || startDate).clone(),
          start: startDate.clone(),
        }
      : null

    setRange(range)
    setQuery(getQuery(dataField, range))
  }

  return isCollapsed ? (
    <CollapsedFilterValue
      componentId={componentId}
      placeholder={<T>Reactivesearch:unfiltered</T>}
      transformaValue={(value) => {
        const [startStr, endStr] = value.split('–')

        const end = !!endStr ? moment(endStr) : null
        const start = !!startStr ? moment(startStr) : null

        let transformed = ''

        if (start && start.isValid()) {
          transformed += start.format('ll')
        }

        if (end && end.isValid()) {
          transformed += ` – ${end.format('ll')}`
        }

        return transformed || value
      }}
    />
  ) : (
    <>
      {title && <Title>{title}</Title>}
      <DateRangePicker
        setValue={(range) =>
          handleChangeDates({ endDate: range?.to, startDate: range?.from })
        }
        value={end && start && { from: start, to: end }}
      />
      {!hideClearButton && range && (
        <InnocuousButton
          compact
          onClick={() => {
            handleChangeDates({ endDate: null, startDate: null })
          }}
        >
          <T>Reactivesearch:clearSelection</T>
        </InnocuousButton>
      )}
    </>
  )
}

////////////

const Title = styled.h2`
  ${titleCss}
`
