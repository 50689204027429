import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { Input, InputProps } from '@/components/FormControls'

type Props = {
  background?: string
  endAdornment?: ReactNode
  isProcessing?: boolean
  showBorder?: boolean
  startAdornment?: ReactNode
} & InputProps

const getAdornmentSpace = (endAdornment?: ReactNode) => {
  if (typeof endAdornment === 'string') {
    switch (endAdornment.length) {
      case 1:
        return 3
      case 2:
        return 3.5
      case 3:
      default:
        return 4
    }
  }

  return 4
}

export const DataTableInputBase = styled(Input)<Props>`
  &:not([type='checkbox']):not([type='radio']) {
    ${({ theme }) => css`
      padding: 0 ${theme.spacing.gu(1)}rem;
    `}

    height: 1.75rem;

    text-align: inherit;

    border-radius: 4px;

    /* Only hide the border on non-default states */
    ${(props) =>
      !props.state && !props.showBorder && 'border-color: transparent;'}

    ${(props) =>
      props.background &&
      css<any>`
        background: ${props.background};
      `}

    ${(props) =>
      props.isProcessing &&
      css`
        ${({ theme }) => css`
          padding-right: ${theme.spacing.gu(3)}rem;
        `}
      `}

    ${(props) =>
      props.endAdornment &&
      css<any>`
        padding-right: ${getAdornmentSpace(props.endAdornment) *
        props.theme.spacing.gu(1)}rem;
      `}

    ${(props) =>
      props.startAdornment &&
      css`
        padding-left: ${props.theme.spacing.gu(3)}rem;
      `}

    ${({ borderless, disabled, state, theme }) =>
      css`
        border-color: ${disabled
          ? theme.palette.smoke.main
          : state
          ? theme.palette.findUtilityColor(state)
          : borderless
          ? 'transparent'
          : theme.palette.smoke.dark};
      `}
  }
`
