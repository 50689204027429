import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { ValidationIssue } from '@/modules/Order/types'

type Props = {
  issues: ValidationIssue[]
}

export const Issues = ({ issues }: Props) => (
  <Tooltip
    content={
      <FlexColumn noPadding alignItems="flex-start">
        {issues.map(({ code }) => (
          <span key={`issue-code-${code}`}>
            <T>{`IssueCodes:${code}`}</T>
          </span>
        ))}
      </FlexColumn>
    }
    delay={300}
    maxWidth={150}
    placement="top"
    trigger={(triggerProps) => (
      <Wrapper {...triggerProps}>
        {!!issues[0] && (
          <Issue key={issues[0].code}>
            <Icon icon="triangle-exclamation" />

            <IssueText bold>
              <T>{`IssueCodes:${issues[0].code}`}</T>
            </IssueText>

            {issues.length > 1 && (
              <LightText normal>
                + {issues.length - 1} <T>common:helpers.more</T>
              </LightText>
            )}
          </Issue>
        )}
      </Wrapper>
    )}
  />
)

////////////

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.warning.dark};
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Issue = styled(FlexRow)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const IssueText = styled(FontWeight)`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterSmall};
  `}
`

const LightText = styled(FontWeight)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
  `}
`

const Wrapper = styled(FlexColumn)`
  justify-content: center;
  align-items: flex-start;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gutter};
  `}
`
