import styled, { css } from 'styled-components/macro'

import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'
import { DataField } from '@/components/DataField'

type Props = {
  color: string | null
  readOnly: boolean
  reservationId: string
  updateReservations: (reservations: Reservation[]) => void
}

export const ColorPicker = ({
  color,
  readOnly,
  reservationId,
  updateReservations,
}: Props) => {
  const { setColor } = resourceReservationHooks.useResourceReservationMutations(
    {
      updateReservations,
    }
  )

  return (
    <Wrapper>
      <DataField
        disabled={readOnly}
        forcedWidth="2rem"
        handleSubmit={(value) => setColor(reservationId, value)}
        hideEditIcon
        inputType="color"
        value={color}
      />
    </Wrapper>
  )
}

/////////

const Wrapper = styled.div`
  ${({ theme }) => css`
    margin: 0 ${theme.spacing.guPx(1) + 4}px 0 ${theme.spacing.guPx(1) + 3}px;
  `}
`
