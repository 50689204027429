import React from 'react'

import { ListingTable, ListingTableSubheader } from '@/modules/Listing/common'
import { generateCompareFn } from '@/utils/arrays'
import { T } from '@/modules/Language'

import { AccommodationGuest } from '../useAccommodationGuests'
import AccommodationGuestRow from './AccommodationGuestRow'
import { PrintSize } from '../../types'
import { SortOrder } from '../types'

interface Props {
  data: AccommodationGuest[]
  printSize: PrintSize
  sortOrder: SortOrder
}

export const AccommodationGuestsTable = ({
  data,
  printSize,
  sortOrder,
}: Props) => {
  const renderTableHeader = () => {
    switch (sortOrder) {
      case SortOrder.PERSON:
        return (
          <thead>
            <tr>
              <th>
                <T>Listings:AccommodationGuestListing.field.name</T>
              </th>
              <th>
                <T>Listings:AccommodationGuestListing.field.stay</T>
              </th>
              <th>
                <T>Listings:AccommodationGuestListing.field.room</T>
              </th>
              <th>
                <T>Listings:AccommodationGuestListing.field.customer</T>
              </th>
              <th colSpan={2}>
                <T>Listings:AccommodationGuestListing.field.sales</T>
              </th>
            </tr>
          </thead>
        )
      case SortOrder.ROOM:
        return (
          <thead>
            <tr>
              <th>
                <T>Listings:AccommodationGuestListing.field.room</T>
              </th>
              <th>
                <T>Listings:AccommodationGuestListing.field.name</T>
              </th>
              <th>
                <T>Listings:AccommodationGuestListing.field.stay</T>
              </th>
              <th>
                <T>Listings:AccommodationGuestListing.field.customer</T>
              </th>
              <th colSpan={2}>
                <T>Listings:AccommodationGuestListing.field.sales</T>
              </th>
            </tr>
          </thead>
        )
    }
  }

  const sorterFn =
    sortOrder === SortOrder.PERSON
      ? generateCompareFn([
          'guest.participant.lastName',
          'guest.participant.firstName',
        ])
      : generateCompareFn([
          'room.number',
          'guest.participant.lastName',
          'guest.participant.firstName',
        ])

  const dataByBuilding = data.reduce(
    (
      acc: Record<string, { building: any; guests: AccommodationGuest[] }>,
      val: AccommodationGuest
    ) => {
      const building = val.room.building

      acc[building.id] = acc[building.id] || {
        building,
        guests: [],
      }
      acc[building.id].guests.push(val)

      return acc
    },
    {}
  )
  const dataByBuildingArr = Object.values(dataByBuilding).sort(
    generateCompareFn('building.name')
  )

  return (
    <ListingTable printSize={printSize}>
      {renderTableHeader()}
      <tbody>
        {dataByBuildingArr.map(({ building, guests }) => (
          <React.Fragment key={`guests-building-${building.id}`}>
            <ListingTableSubheader colSpan={6} printSize={printSize}>
              {building.name}
            </ListingTableSubheader>
            {[...guests].sort(sorterFn).map((x) => (
              <AccommodationGuestRow
                data={x}
                key={`accommodation-guest-${x.guest.id}`}
                sortOrder={sortOrder}
              />
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </ListingTable>
  )
}

export default AccommodationGuestsTable
