import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useLocation } from 'react-router-dom'

import {
  Document,
  DocumentUserAttribute,
  InvoiceConfig as InvoiceConfigType,
} from '@/modules/Order/types'
import { DocumentPreview, DocumentsService } from '@/modules/Document'
import { FetchState, FetchStates } from '@/common/types'
import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  SideDrawer,
  SideDrawerBottomControls,
  SideDrawerShadow,
} from '@/components/SideDrawer'
import { DocumentFileStatus } from '~generated-types'
import { Gutter } from '@/components/Layout/Gutter'
import { InnocuousButton } from '@/components/ExtraButtons'
import { orderMutations } from '@/modules/Order'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import {
  Attributes,
  FreeTextInput,
  InvoiceConfig,
  LanguageSelector,
  Section,
  TemplateSelector,
  Title,
} from './components'

type Props = {
  config?: InvoiceConfigType
  document: Document
  freeText?: string | null
  onClose: () => void
  onUpdateDocument: (document: Document) => void
  orderId: string
  ownerId: string
  readOnly: boolean
}

export const DocumentEditor = ({
  config,
  document: doc,
  freeText,
  onClose,
  onUpdateDocument,
  orderId,
  ownerId,
  readOnly,
}: Props) => {
  const { spacing } = useTheme()

  const { id, fileStatus, name, userAttributes } = doc
  const { pathname, search } = useLocation()

  const [attributes, setAttributes] =
    useState<DocumentUserAttribute[]>(userAttributes)
  const [PDFUrl, setPDFUrl] = useState<string>('')
  const [PDFState, setPDFState] = useState<FetchState>(FetchStates.IDLE)

  const [updateUserAttribute] =
    orderMutations.useUpdateDocumentUserAttributeMutation()

  const isFinal = fileStatus === DocumentFileStatus.Final

  const buildPdf = async () => {
    setPDFState(FetchStates.LOADING)

    try {
      const url = await DocumentsService.buildPdf(id)
      setPDFUrl(url)
      setPDFState(FetchStates.IDLE)
    } catch (err) {
      setPDFState(FetchStates.ERROR)
      console.error('Failed to download', err)
    }
  }

  useEffect(() => {
    buildPdf()
  }, [id])

  const handleDownload = () => {
    const link = document.createElement('a')
    link.download = `${name}.pdf`
    link.href = PDFUrl
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const handlePrint = () => {
    const iframe = document.getElementById(
      'iframe-pdf-document'
    ) as HTMLIFrameElement | null
    const iframeWindow = iframe?.contentWindow

    iframe && iframe?.focus()
    iframeWindow && iframeWindow.print()
  }

  const onToggleAttribute = ({
    active,
    editorValue,
    key,
    templateValue,
  }: DocumentUserAttribute) => {
    // Don't send a query if "templateValue" is empty
    if (!templateValue) {
      setAttributes(
        attributes.map((a) => (a.key === key ? { ...a, active: !active } : a))
      )
    } else {
      updateUserAttribute({
        variables: {
          input: {
            active: !active,
            documentId: id,
            editorValue,
            key,
            templateValue,
          },
        },
      })
        .then(({ data }) => {
          const userAttribute = data?.documentUserAttributeUpdate.userAttribute

          if (userAttribute) {
            setAttributes(
              attributes.map((a) => (a.key === key ? userAttribute : a))
            )
          }
        })
        .then(buildPdf)
        .catch(() => undefined)
    }
  }

  const onUpdateAttributes = ({
    active,
    editorValue,
    key,
    templateValue,
  }: DocumentUserAttribute) => {
    updateUserAttribute({
      variables: {
        input: { active, documentId: id, editorValue, key, templateValue },
      },
    })
      .then(({ data }) => {
        const userAttribute = data?.documentUserAttributeUpdate.userAttribute

        if (userAttribute) {
          setAttributes(
            attributes.map((a) => (a.key === key ? userAttribute : a))
          )
        }
      })
      .then(buildPdf)
      .catch(() => undefined)
  }

  const onUpdate = (document: Document) => {
    buildPdf()
    return onUpdateDocument(document)
  }

  return createPortal(
    <>
      <SideDrawer width={`${spacing.gu(isFinal ? 110 : 170)}rem`}>
        <EditorContainer>
          {!isFinal && (
            <>
              <SectionContainer noPadding style={{ width: '35%' }}>
                <TitleSection>
                  <Title style={{ marginBottom: spacing.gutter }}>
                    <T>Orders:Documents.settings</T>
                  </Title>
                </TitleSection>

                <HorizontalLine />

                <SectionWrapper flex={1} noPadding>
                  <TemplateSelector
                    document={doc}
                    onUpdateDocument={onUpdate}
                  />

                  <HorizontalLine />

                  <LanguageSelector
                    document={doc}
                    onUpdateDocument={onUpdate}
                  />

                  <HorizontalLine />

                  {!!attributes.length && (
                    <>
                      <Attributes
                        attributes={attributes}
                        onToggle={onToggleAttribute}
                        onUpdate={onUpdateAttributes}
                      />

                      <HorizontalLine />
                    </>
                  )}

                  {config && (
                    <>
                      <InvoiceConfig
                        config={config}
                        orderId={orderId}
                        ownerId={ownerId}
                        readOnly={readOnly}
                        refreshPdf={buildPdf}
                      />

                      <HorizontalLine />
                    </>
                  )}

                  {freeText !== undefined && (
                    <FreeTextInput
                      freeText={freeText}
                      orderId={orderId}
                      ownerId={ownerId}
                      readOnly={readOnly}
                      refreshPdf={buildPdf}
                    />
                  )}
                </SectionWrapper>
              </SectionContainer>

              <VerticalLine />
            </>
          )}

          <SectionContainer style={{ width: `${isFinal ? '100%' : '65%'}` }}>
            <TitleWrapper alignItems="center">
              <Title>
                <T>Orders:Documents.documentEditor.preview</T>
              </Title>

              <Spacer />

              <InnocuousButton
                compact
                disabled={PDFState !== FetchStates.IDLE}
                onClick={handlePrint}
              >
                <PrimaryColor>
                  <Icon icon="print" />
                  <T>common:action.print</T>
                </PrimaryColor>
              </InnocuousButton>

              <InnocuousButton
                compact
                disabled={PDFState !== FetchStates.IDLE}
                onClick={handleDownload}
              >
                <PrimaryColor>
                  <Icon icon="download" />
                  <T>Orders:Documents.action.download</T>
                </PrimaryColor>
              </InnocuousButton>
            </TitleWrapper>

            <DocumentPreview url={PDFUrl} fetchingState={PDFState} />
          </SectionContainer>
        </EditorContainer>

        <SideDrawerBottomControls>
          <Gutter type={['gutter', 'gutterBig']}>
            <InnocuousButton noNudge onClick={onClose}>
              <T>common:action.close</T>
            </InnocuousButton>
          </Gutter>
        </SideDrawerBottomControls>
      </SideDrawer>

      <SideDrawerShadow to={`${pathname}${search}`} onClick={onClose} />
    </>,
    document.getElementById('modal-root') as any
  )
}

///////

const EditorContainer = styled.div`
  display: flex;
  height: 100%;
`

const Icon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const SectionWrapper = styled(FlexColumn)`
  overflow: auto;
`

const SectionContainer = styled(FlexColumn)<{ noPadding?: boolean }>`
  width: 100%;

  ${({ noPadding, theme }) => css`
    line-height: ${theme.spacing.gu(2.5)}rem;
    padding: ${noPadding ? 0 : theme.spacing.gutterBig};
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const TitleSection = styled(Section)`
  ${({ theme }) => css`
    padding-bottom: ${theme.spacing.gutter};
    padding-top: ${theme.spacing.gutterBig};
  `}
`

const TitleWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(2)}rem;
  `}
`

const HorizontalLine = styled.div`
  ${({ theme }) => css`
    border-top: 2px solid ${theme.palette.smoke.light};
  `}
`

const VerticalLine = styled.div`
  ${({ theme }) => css`
    border-right: 2px solid ${theme.palette.smoke.light};
  `}
`
