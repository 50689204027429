import { CSSProperties } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { FontWeight } from '../Typography'
import { useTheme } from '@/theme'

import { PageSizeSelector, PaginationButton } from './'

export type PaginationType = {
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  totalElements: number
  totalPages: number
}

type Props = {
  pagination: PaginationType
  goToPage: (page: number, pageSize: number) => void
  pageSize: number
  setPageSize: (pageSize: number) => void
  style?: CSSProperties
}

export const Pagination = ({
  pagination,
  goToPage,
  pageSize,
  setPageSize,
  style,
}: Props) => {
  const theme = useTheme()

  return (
    <FlexRow
      alignItems="flex-start"
      style={{ marginTop: theme.spacing.gutterBig, ...style }}
      flex={1}
    >
      <PageSizeSelector
        goToPage={goToPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      <FlexRow
        flex={1}
        justifyContent="center"
        style={{ marginRight: `${theme.spacing.gu(20)}rem` }}
      >
        <PaginationButton
          disabled={!pagination.hasPreviousPage}
          onClick={() => goToPage(pagination.currentPage - 1, pageSize)}
        >
          <FontAwesomeIcon icon="arrow-left" />
        </PaginationButton>

        {[...Array(pagination.totalPages)].map((_, index) => (
          <PaginationButton
            key={`page-${index}`}
            onClick={() => goToPage(index, pageSize)}
            active={pagination.currentPage === index}
          >
            <FontWeight bold>{index + 1}</FontWeight>
          </PaginationButton>
        ))}

        <PaginationButton
          disabled={!pagination.hasNextPage}
          onClick={() => goToPage(pagination.currentPage + 1, pageSize)}
        >
          <FontAwesomeIcon icon="arrow-right" />
        </PaginationButton>
      </FlexRow>
    </FlexRow>
  )
}
