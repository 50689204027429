import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactLoading from 'react-loading'
import { ReactNode } from 'react'

import { Button } from '../Button'
import { FlexRow } from '../Layout'

type Props = {
  actionLabel?: ReactNode
  indicationMessage?: ReactNode
  infoMessage: ReactNode
  onClick: () => void
  loading?: boolean
  readOnly?: boolean
}

export const WarningBar = ({
  onClick,
  infoMessage,
  indicationMessage,
  actionLabel,
  readOnly,
  loading,
}: Props) => (
  <Wrapper>
    <ContentWrapper alignItems="center" justifyContent="space-between">
      <FlexRow>
        <WarningIcon fixedWidth icon="triangle-exclamation" size="2x" />
        <Text>{infoMessage}</Text>
      </FlexRow>

      <FlexRow>
        {indicationMessage && <Text>{indicationMessage}</Text>}

        {actionLabel && (
          <Button color="primary" disabled={readOnly} onClick={onClick}>
            {loading && (
              <Loading>
                <ReactLoading height={12} type="spin" width={12} />
              </Loading>
            )}

            {actionLabel}
          </Button>
        )}
      </FlexRow>
    </ContentWrapper>
  </Wrapper>
)

/////////

const Wrapper = styled(FlexRow)`
  background: #fff7e6;

  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    color: ${theme.palette.text.light};
    padding: ${theme.spacing.gu(2)}rem ${theme.spacing.gu(3)}rem;
  `};
`

const ContentWrapper = styled(FlexRow)`
  width: 100%;
  margin: 0 auto;
  max-width: 1500px;
  min-width: 1100px;
`

const WarningIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.warning.main};
  `};
`

const Text = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin: 0 ${theme.spacing.gu(2)}rem;
  `};
`

const Loading = styled.span`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1.5)}rem;
  `};
`
