import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { EventEnrollment } from '@/API/EventEnrollments'
import { Input } from '@/components/FormControls'

type Props = {
  enrollment: EventEnrollment
  readOnly?: boolean
  rename: (enrollmentId: string, newName: string | null) => Promise<any>
}

export default function EnrollmentName({
  enrollment,
  readOnly,
  rename,
}: Props) {
  const { id, name, orderNumber } = enrollment
  const [nameIsEditing, setNameEditing] = useState(false)
  const [nameValue, setNameValue] = useState(name || '')

  const handleChangeName = (name: string | null) => {
    rename(id, name).then(() => setNameEditing(false))
  }

  return (
    <SaleNameWrapper>
      {nameIsEditing ? (
        <StyledInput
          autoFocus
          type="text"
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              handleChangeName(nameValue)
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNameValue(e.target.value)
          }
          onBlur={() => handleChangeName(nameValue)}
          value={nameValue}
        />
      ) : (
        <HeaderButton
          disabled={readOnly}
          onClick={() => !readOnly && setNameEditing(true)}
          style={{ height: '100%' }}
        >
          <SaleName>
            <Label>
              <span>{`#${orderNumber}${name ? ` – ${name}` : ''}`}</span>
            </Label>

            <IconWrapper>
              {!readOnly && <FontAwesomeIcon icon="pen" />}
            </IconWrapper>
          </SaleName>
        </HeaderButton>
      )}
    </SaleNameWrapper>
  )
}

////////////

const Label = styled.div`
  flex: 1;
  min-width: 240px;
  width: 240px;
`

const SaleNameWrapper = styled.div`
  align-self: stretch;
  flex: 1;
  min-width: 300px;
`

const SaleName = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  & span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${({ theme }) => css`
      margin-right: ${theme.spacing.gutterSmall};
    `}
  }
`

const StyledInput = styled(Input)`
  &&& {
    border: 0;
    border-radius: 0;
    height: 100%;
    flex: 1;
    width: 100%;
    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1.5)}rem;
    `}
  }
`

const HeaderButton = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem;
  `}

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: 0.2s;
  height: 100%;
  width: auto;

  & > div svg {
    display: none;
  }

  &:hover {
    ${({ disabled, theme }) =>
      !disabled &&
      `
        span,
        svg {
          color: ${theme.palette.primary.main};
        }
      `}

    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.light};
    `}

    & > div svg {
      display: block;
    }
  }
`

const IconWrapper = styled.span`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(2.5)}rem;
  `}
`
