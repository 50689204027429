import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'

import { ApolloError } from '@apollo/client'
import moment from 'moment'

import {
  Reservation,
  resourceReservationHooks,
} from '@/modules/Reservations/ResourceReservation'

// Contexts
import { useCompactViewContext } from '../../../SaleCompactView/CompactViewContainer'
import { useSalesDetailsContext } from '../../'

type OpenCalendarProps = {
  date?: string
  target: 'DATE' | 'MAIN'
}

type ContextType = {
  error?: ApolloError
  isLoading: boolean
  reservations: Reservation[]
  showCalendar: boolean
  targetDate: string | null

  // Calendar methods
  closeCalendar: () => void
  openCalendar: (props: OpenCalendarProps) => void

  // Reservation list methods
  setReservations: (reservations: Reservation[]) => void
}

const ResourceReservationListContext = createContext<ContextType>({
  closeCalendar: () => undefined,
  error: undefined,
  isLoading: false,
  openCalendar: () => undefined,
  reservations: [],
  setReservations: () => Promise.reject(),
  showCalendar: false,
  targetDate: null,
})

type Props = {
  children: ReactNode
}

export const ResourceReservationListContextProvider = ({ children }: Props) => {
  const contextValueRef = useRef<ContextType | null>(null)

  const {
    data: { estimatedDates, id, reservationDates },
  } = useSalesDetailsContext()
  const { salesId } = useCompactViewContext()

  const [isLoading, setLoading] = useState<boolean>(false)
  const [targetDate, setTargetDate] = useState<string | null>(null)
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const [reservations, setReservations] = useState<Reservation[]>([])
  const [reservationsStartDate, setReservationsStartDate] = useState<string>('')

  const {
    error,
    loading,
    refetch: refetchReservationList,
    reservations: reservationsArr,
  } = resourceReservationHooks.useSalesResourceReservations({
    salesId: salesId || id,
  })

  useEffect(() => {
    if (reservationsArr.length) {
      setReservations(reservationsArr)

      setReservationsStartDate(
        [...reservationsArr].sort(
          (a, b) => moment(a.start).valueOf() - moment(b.start).valueOf()
        )[0].start
      )
    }
  }, [JSON.stringify(reservationsArr)])

  useEffect(() => {
    setLoading(loading)
  }, [loading])

  const closeCalendar = () => {
    refetchReservationList()
    setShowCalendar(false)
  }

  const openCalendar = ({ date, target }: OpenCalendarProps) => {
    switch (target) {
      case 'DATE':
        setShowCalendar(true)
        setTargetDate(date || null)
        return
      case 'MAIN':
      default:
        const currentTargetDate =
          reservationsStartDate ||
          estimatedDates?.start ||
          reservationDates?.start

        setShowCalendar(true)
        setTargetDate(currentTargetDate || null)
        return
    }
  }

  contextValueRef.current = {
    closeCalendar,
    error,
    isLoading,
    openCalendar,
    reservations,
    setReservations,
    showCalendar,
    targetDate,
  }

  return (
    <ResourceReservationListContext.Provider value={contextValueRef.current}>
      {children}
    </ResourceReservationListContext.Provider>
  )
}

export const useResourceReservationListContext = () =>
  useContext(ResourceReservationListContext)
