import { useObservablePayment } from './ObservablePaymentContext'
import { useOrderView } from './OrderViewContext'

export default Object.freeze({
  useObservablePayment,
  useOrderView,
})

export { ObservablePaymentProvider } from './ObservablePaymentContext'
export { OrderViewProvider } from './OrderViewContext'
