import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { CheckboxInput } from '@/components/FormControls'
import { FlexRow } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { Reservation as ReservationType } from '@/modules/Reservations/ResourceReservation'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { GroupManagerModal } from './GroupManagerModal'
import { Reservation } from './Reservation'
import { ResourceReservationGroup } from '../types'

type Props = {
  group: ResourceReservationGroup
  isFirst: boolean
  isModalOpen: boolean
  readOnly: boolean
  reservationModalId: string | null
  isMovingProcess?: boolean
  idsToMove: string[]
  setIdsToMove: (ids: string[]) => void
  setGroupModalId: (id: string | null) => void
  setReservationModalId: (id: string | null) => void
  setReservations: (reservations: ReservationType[]) => void
}

export const ReservationGroup = ({
  group,
  isFirst,
  isModalOpen,
  readOnly,
  reservationModalId,
  isMovingProcess,
  idsToMove,
  setIdsToMove,
  setGroupModalId,
  setReservationModalId,
  setReservations,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { end, id, name, number, reservations, start } = group

  const [isHover, setHover] = useState<boolean>(false)
  const isGroupSelected = group.reservations.every((r) =>
    idsToMove.find((id) => r.id === id)
  )
  const isSomeReservationsSelected = group.reservations.some((r) =>
    idsToMove.find((id) => r.id === id)
  )
  const selectGroup = () => {
    const reservationIds = group.reservations.map(({ id }) => id)
    const idsFiltered = idsToMove.filter((id) => !reservationIds.includes(id))
    setIdsToMove(
      isGroupSelected ? idsFiltered : [...idsFiltered, ...reservationIds]
    )
  }

  return (
    <GroupWrapper isFirst={isFirst} isOpen={isModalOpen}>
      <ModalContainer
        isOpen={isModalOpen}
        modal={
          <GroupManagerModal
            context="SALES"
            group={group}
            onClose={() => setGroupModalId(null)}
            readOnly={readOnly}
            updateReservations={setReservations}
          />
        }
        onClose={() => setGroupModalId(null)}
        placement="bottom"
        referenceElement={({ ref }) => (
          <GroupButtonWrapper justifyContent="center">
            {isMovingProcess && (
              <CheckBoxWrapper>
                <CheckboxInput
                  checked={isGroupSelected}
                  indeterminate={isSomeReservationsSelected && !isGroupSelected}
                  noMargin
                  onChange={selectGroup}
                />
              </CheckBoxWrapper>
            )}
            <GroupButton
              onClick={() => setGroupModalId(id)}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              ref={ref as Ref<HTMLButtonElement> | undefined}
            >
              <div>
                <FontAwesomeIcon icon="user-group" size="sm" />
                <GroupNumber>#{number} </GroupNumber>
                <GroupName>
                  {name || <T>ReservationModal:group.title</T>}
                </GroupName>
              </div>
              <div>
                <GroupDates>{getGroupDateRange(end, start)}</GroupDates>
                {!readOnly && (
                  <FontAwesomeIcon
                    color={isHover ? palette.primary.main : palette.smoke.dark}
                    icon="pen"
                    size="sm"
                    style={{ marginRight: `${spacing.gu(1)}rem` }}
                  />
                )}
              </div>
            </GroupButton>
          </GroupButtonWrapper>
        )}
        styleOverrides={{
          left: 'unset',
          right: 0,
          transform: 'none',
        }}
      />

      {reservations
        .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())
        .map((r) => (
          <Reservation
            group={group}
            key={r.id}
            reservation={r}
            readOnly={readOnly}
            isMovingProcess={isMovingProcess}
            idsToMove={idsToMove}
            setIdsToMove={setIdsToMove}
            setReservations={setReservations}
            isModalOpen={!isModalOpen && reservationModalId === r.id}
            setReservationModalId={setReservationModalId}
          />
        ))}
    </GroupWrapper>
  )
}

///////

const getGroupDateRange = (end: string, start: string) => {
  const isSameDate = moment(start).isSame(end, 'day')

  const toDate = (date: string) => moment(date).format('dd D.M.YY')
  const toTime = (date: string) => moment(date).format('HH:mm')

  return isSameDate
    ? `${toDate(start)}, ${toTime(start)} – ${toTime(end)}`
    : `${toDate(start)}, ${toTime(start)} – ${toDate(end)}, ${toTime(end)}`
}

const GroupWrapper = styled.div<{ isFirst: boolean; isOpen: boolean }>`
  background: #f0f7fe;
  border-radius: 6px;

  ${({ theme }) => css`
    border: 1px solid ${theme.palette.smoke.main};
    margin-bottom: ${theme.spacing.gutter};
    padding-bottom: ${theme.spacing.gu(1)}rem;
  `}

  ${({ isFirst, theme }) =>
    !isFirst && `margin-top: ${theme.spacing.gu(1)}rem;`}

  transition: 0.2s;
  ${({ isOpen, theme }) =>
    isOpen &&
    css`
      border-color: ${theme.palette.primary.dark};
      box-shadow: 0 0 2px 0 ${theme.palette.primary.dark};
    `}
`

const GroupButtonWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.smoke.main};
    margin-bottom: ${theme.spacing.gutter};
  `}

  &:not(:disabled):hover {
    ${({ theme }) => css`
      border-bottom: 1px solid ${theme.palette.smoke.main};
    `}
  }
`

const GroupButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: transparent;
  border: none;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;

  ${({ theme }) => css`
    color: ${theme.palette.primary.darker};
    font-size: ${theme.typography.fontSizeBase2};
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gutter};
  `}

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(:disabled):hover {
    background: rgb(230, 240, 249);
  }
`

const GroupNumber = styled.span`
  font-weight: 600;

  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
  `}
`

const GroupName = styled.span`
  font-weight: 500;
`

const GroupDates = styled.span`
  font-weight: 500;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gutterBig};
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const CheckBoxWrapper = styled(FlexRow)`
  justify-content: center;

  ${({ theme }) => css`
    padding-left: ${theme.spacing.gutter};
    padding-right: ${theme.spacing.gutter};
  `}
`
