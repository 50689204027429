import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import moment from 'moment'
import ReactLoading from 'react-loading'

import {
  InlineModalIconSection,
  InlineModalLine,
} from '@/components/InlineModal'
import {
  ResourceReservationGroup as NullableResourceReservationGroup,
  Reservation as ReservationType,
  resourceReservationHooks,
  SalesResourceReservationForGroup,
} from '@/modules/Reservations/ResourceReservation'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import { getDateRange } from './utils'
import { Name } from './Name'
import { Reservation } from './Reservation'
import { ReservationSelector } from './ReservationSelector'

type ResourceReservationGroup = Exclude<NullableResourceReservationGroup, null>

type Props = {
  currentReservationId: string | null
  group: ResourceReservationGroup
  readOnly: boolean
  updateReservations: (reservations: ReservationType[]) => void
}

export const GroupManager = ({
  currentReservationId,
  group,
  readOnly,
  updateReservations,
}: Props) => {
  const { palette, spacing } = useTheme()

  const { end, id, start } = group

  const { data } = useSalesDetailsContext()

  const [groupReservations, setGroupReservations] = useState<
    SalesResourceReservationForGroup[]
  >([])
  const [singleReservations, setSingleReservations] = useState<
    SalesResourceReservationForGroup[]
  >([])

  const { loading, reservations } =
    resourceReservationHooks.useSalesResourceReservationsForGroup({
      salesId: data?.id,
    })

  useEffect(() => {
    if (reservations) {
      setGroupReservations(reservations.filter((r) => r.group?.id === id))
      setSingleReservations(reservations.filter((r) => !r.group))
    }
  }, [JSON.stringify(reservations), id])

  useEffect(() => {
    return () => {
      setGroupReservations([])
      setSingleReservations([])
    }
  }, [])

  const updateStateAfterAdding = (reservationId: string) => {
    const newReservation = reservations.find((r) => r.id === reservationId)

    newReservation &&
      setGroupReservations((current) => [
        ...current,
        { ...newReservation, group },
      ])

    setSingleReservations((current) =>
      current.filter((r) => r.id !== reservationId)
    )
  }

  const updateStateAfterRemoving = (reservationId: string) => {
    const newReservation = reservations.find((r) => r.id === reservationId)

    newReservation &&
      setSingleReservations((current) => [...current, newReservation])

    setGroupReservations((current) =>
      current.filter((r) => r.id !== reservationId)
    )
  }

  return (
    <>
      <Name
        group={group}
        readOnly={readOnly}
        updateReservations={updateReservations}
      />

      <InlineModalIconSection icon={['far', 'calendar']}>
        <DateRange>{getDateRange(end, start)}</DateRange>
      </InlineModalIconSection>

      <InlineModalLine style={{ marginTop: spacing.gutter }} />

      {!reservations.length && loading ? (
        <LoaderWrapper>
          <ReactLoading
            color={palette.smoke.dark}
            height={19}
            type="spin"
            width={19}
          />
        </LoaderWrapper>
      ) : (
        groupReservations
          .sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf())
          .map((reservation) => (
            <Reservation
              key={reservation.id}
              readOnly={
                readOnly ||
                groupReservations.length === 1 ||
                currentReservationId === reservation.id
              }
              reservation={reservation}
              updateReservations={updateReservations}
              updateStateAfterRemoving={updateStateAfterRemoving}
            />
          ))
      )}

      <InlineModalLine
        dashed
        style={{
          margin: `${spacing.gu(1)}rem 0 ${spacing.gu(1)}rem 0`,
        }}
      />

      <ReservationSelector
        groupId={id}
        readOnly={readOnly}
        singleReservations={singleReservations}
        updateReservations={updateReservations}
        updateStateAfterAdding={updateStateAfterAdding}
      />
    </>
  )
}

////////

const DateRange = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} 0;
  `}
`

const LoaderWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1.5)}rem ${theme.spacing.gu(1)}rem
      ${theme.spacing.gu(0.5)}rem ${theme.spacing.gu(3)}rem;
  `}
`
