import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { DangerColor, SuccessColor } from '@/components/Colors'
import { EditButton, InnocuousButton } from '@/components/ExtraButtons'
import { FlexColumn, FlexRow } from '@/components/Layout'
import { Input, Label } from '@/components/FormControls'
import { DateRangePicker } from '@/components/TimeControls'
import { InlineModal } from '@/components/InlineModal'
import { T } from '@/modules/Language'

type DateRange = {
  from: Moment
  to: Moment
}

type DateRangeNullable = {
  from: Moment | null
  to: Moment | null
}

type Props = {
  publishedFrom: string | null
  publishedTo: string | null
  handlePublish: (range: DateRange) => void
  handleUnpublish: () => void
}

export const PublishDatesModal = ({
  publishedFrom,
  publishedTo,
  handlePublish,
  handleUnpublish,
}: Props) => {
  const [isFromValid, setFromValid] = useState<boolean>(true)
  const [isToValid, setToValid] = useState<boolean>(true)
  const [isPickerOpen, setPickerOpen] = useState<boolean>(false)
  const [fromValue, setFromValue] = useState<string>(publishedFrom ?? '')
  const [toValue, setToValue] = useState<string>(publishedTo ?? '')
  const [range, setRange] = useState<DateRangeNullable>({
    from: publishedFrom ? moment(publishedFrom) : null,
    to: publishedTo ? moment(publishedTo) : null,
  })

  const dateRangePickerValue =
    range.from && range.to ? { from: range.from, to: range.to } : null

  const onDateRangePickerSelect = (range: DateRange) => {
    setRange(range)
    setFromValue(range.from.format('YYYY-MM-DD'))
    setToValue(range.to.format('YYYY-MM-DD'))
  }

  const onPublish = () =>
    range.from && range.to && handlePublish({ from: range.from, to: range.to })

  const validateDates = () => {
    const isFromAfterToday =
      !fromValue || moment(fromValue).isSameOrAfter(moment(), 'day')
    const isToAfterToday =
      !toValue || moment(toValue).isSameOrAfter(moment(), 'day')

    const isFromBeforeTo =
      !fromValue ||
      !toValue ||
      moment(fromValue).isSameOrBefore(moment(toValue), 'day')

    setFromValid(isFromAfterToday && isFromBeforeTo)
    setToValid(isToAfterToday && isFromBeforeTo)
  }

  const onSetFromDate = () => {
    validateDates()
    setRange({ from: moment(fromValue), to: range.to })
  }

  const onSetToDate = () => {
    validateDates()
    setRange({ from: range.from, to: moment(toValue) })
  }

  return (
    <Modal>
      <FlexColumn>
        <InputsWrapper justifyContent="space-between" alignItems="flex-end">
          <FlexColumn alignItems="flex-start" noPadding>
            <Label>
              <T>Publish:Rooms.date.from</T>
            </Label>

            <DateInput
              onBlur={onSetFromDate}
              onChange={(e) => setFromValue(e.target.value)}
              type="date"
              valid={isFromValid}
              value={fromValue}
            />
          </FlexColumn>

          <FlexColumn alignItems="flex-start" noPadding>
            <Label>
              <T>Publish:Rooms.date.to</T>
            </Label>

            <DateInput
              onBlur={onSetToDate}
              onChange={(e) => setToValue(e.target.value)}
              type="date"
              valid={isToValid}
              value={toValue}
            />
          </FlexColumn>

          <CalendarButton noIcon onClick={() => setPickerOpen(!isPickerOpen)}>
            <CalendarIcon icon={['far', 'calendar']} />
          </CalendarButton>
        </InputsWrapper>

        {isPickerOpen && (
          <DateRangePickerWrapper justifyContent="center">
            <DateRangePicker
              disablePastDays
              setValue={(range) => range && onDateRangePickerSelect(range)}
              value={dateRangePickerValue}
            />
          </DateRangePickerWrapper>
        )}

        <ActionsWrapper justifyContent="space-between">
          {publishedFrom || publishedTo ? (
            <InnocuousButton compact onClick={handleUnpublish}>
              <DangerColor>
                <T>Publish:Rooms.unpublish</T>
              </DangerColor>
            </InnocuousButton>
          ) : (
            <div />
          )}

          <InnocuousButton
            disabled={!range.from || !range.to || !isFromValid || !isToValid}
            compact
            onClick={onPublish}
          >
            <SuccessColor>
              <T>Publish:Rooms.publish</T>
            </SuccessColor>
          </InnocuousButton>
        </ActionsWrapper>
      </FlexColumn>
    </Modal>
  )
}

///////

const ActionsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gu(1)}rem;
  `}
`

const DateRangePickerWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    border-top: 1px solid ${theme.palette.smoke.main};
  `}
`

const CalendarButton = styled(EditButton)`
  flex: unset;
  justify-content: center;
  margin: 0;
  width: 30px;
`

const CalendarIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.primary.main};
  `}
`

const InputsWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(2)}rem;
  `}
`

const Modal = styled(InlineModal)`
  ${({ theme }) => css`
    width: ${theme.spacing.gu(42)}rem;
  `}
`

const DateInput = styled(Input)<{ valid: boolean }>`
  &:not([type='checkbox']):not([type='radio']) {
    height: 30px;
    text-align: center;
    border-radius: 4px;
    cursor: text;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(0.5)}rem;
      width: ${theme.spacing.gu(16)}rem;
    `}

    ${({ theme, valid }) =>
      !valid &&
      css`
        border-color: ${theme.palette.danger.main};
      `}

    &:hover, &:focus {
      ${({ theme }) => css`
        background-color: ${theme.palette.smoke.lighter};
      `}
    }
  }

  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
`
