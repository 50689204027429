import { useEffect, useState } from 'react'

import { ElasticTask, TaskManager } from '@/modules/Task'
import { T, translate, useLanguageContext } from '@/modules/Language'

import { ElasticFilterSearchList } from '@/components/ElasticFilterSearchList'
import { Gutter } from '@/components/Layout'
import { ModalContainer } from '@/components/Modal'
import { SortOption } from '@/components/Reactivesearch'

import ListControls from './components/ListControls'
import ListFilters from './components/ListFilters'
import ListHeader from './components/ListHeader'
import ListItem from './components/ListItem'

const TASK_INDEX = 'task.task'

const ComponentIds = Object.freeze({
  ASSIGNEE: 'assignee',
  DATES: 'dates',
  EXPIRED: 'expired',
  LIST: 'page',
  SEARCH: 'search',
  STATUS: 'status',
})

export type TargetTask = {
  number: number
  readOnly: boolean
}

export const TaskSearchList = () => {
  const { language } = useLanguageContext()

  const [targetTask, setTargetTask] = useState<TargetTask | null>(null)
  const [refreshTicker, setRefreshTicker] = useState<number>(0)

  const sortOptions: SortOption[] = [
    {
      dataField: 'dueDate',
      direction: 'asc',
      label: translate('Tasks:ListControls.sortOptions.dueDateAsc', language),
    },
    {
      dataField: 'dueDate',
      direction: 'desc',
      label: translate('Tasks:ListControls.sortOptions.dueDateDesc', language),
    },
    {
      dataField: 'number',
      direction: 'asc',
      label: translate('Tasks:ListControls.sortOptions.numberAsc', language),
    },
    {
      dataField: 'number',
      direction: 'desc',
      label: translate('Tasks:ListControls.sortOptions.numberDesc', language),
    },
  ]

  const [sortProperty, setSortProperty] = useState<SortOption | null>(
    sortOptions[0]
  )

  useEffect(() => {
    if (!targetTask) {
      setRefreshTicker((current) => current + 1)
    }
  }, [targetTask])

  const versionAddition = refreshTicker
    ? {
        must_not: [
          {
            match: { queryVersion: refreshTicker },
          },
        ],
      }
    : {}

  const sort = sortProperty
    ? [
        sortProperty.dataField === 'dueDate'
          ? {
              [sortProperty.dataField]: sortProperty.direction,
              dueTime: sortProperty.direction,
            }
          : { [sortProperty.dataField]: sortProperty.direction },
      ]
    : { number: 'desc' }

  return (
    <Gutter type={[0, 5, 10]}>
      <ElasticFilterSearchList
        columnCount={6}
        indexName={TASK_INDEX}
        placeholders={{
          empty: <T>Tasks:List.empty</T>,
          error: <T>Tasks:List.error</T>,
        }}
        reactiveListProps={{
          componentId: ComponentIds.LIST,
          dataField: 'name',
          defaultQuery: () => ({
            query: {
              bool: {
                ...versionAddition,
              },
            },
            sort,
          }),
          react: {
            and: [
              ComponentIds.ASSIGNEE,
              ComponentIds.EXPIRED,
              ComponentIds.DATES,
              ComponentIds.SEARCH,
              ComponentIds.STATUS,
            ],
          },
          size: 50,
        }}
        renderListControls={() => (
          <ListControls
            refreshList={() => setRefreshTicker((current) => current + 1)}
            setTargetTask={setTargetTask}
          />
        )}
        renderListFilters={() => <ListFilters componentIds={ComponentIds} />}
        renderListHeader={() => <ListHeader />}
        renderListItem={(i: ElasticTask) => (
          <ListItem data={i} key={i.id} setTargetTask={setTargetTask} />
        )}
        sortProps={{
          options: sortOptions,
          setValue: setSortProperty,
          value: sortProperty,
        }}
        title={<T>Tasks:TaskSearchList:title</T>}
      />

      {targetTask && (
        <ModalContainer
          isOpen={!!targetTask}
          modal={
            <TaskManager
              context="GLOBAL"
              onClose={() => setTargetTask(null)}
              readOnly={targetTask.readOnly}
              taskNumber={targetTask.number}
            />
          }
          onClose={() => setTargetTask(null)}
          placement="bottom"
          referenceElement={() => null}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />
      )}
    </Gutter>
  )
}
