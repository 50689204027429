import { useEffect, useState } from 'react'

import { FlexRow } from '@/components/Layout'
import { Gutter } from '@/components/Layout'
import { useTheme } from '@/theme'

import { AdditionalInfo } from './AdditionalInfo'
import { Beds } from './Beds'
import { Features } from './Features'
import { Icon } from './Icon'
import { NestedRoomReservation } from './NestedRoomReservation'
import { NumberInput } from './NumberInput'
import { RangePicker } from './RangePicker'
import { ReservationCard } from './ReservationCard'
import { ReservationDataRow } from './ReservationDataRow'
import { Title } from './Title'

import {
  PendingRoomReservation,
  SalesRoomReservation,
  SalesRoomTypeReservation,
} from '../SalesReservationManager.types'

import { useReservationListContext } from '../../SalesReservationList/ReservationListState'

type RoomTypeReservationType = SalesRoomTypeReservation & {
  roomReservations: SalesRoomReservation[]
}

type Props = {
  data: RoomTypeReservationType
  pendingReservations?: PendingRoomReservation[]
  focusedRoomId?: string
  setOpenSections: (arg0: { [sectionId: string]: boolean }) => void
}

export const RoomTypeReservation = ({
  data,
  pendingReservations,
  focusedRoomId,
  setOpenSections,
}: Props) => {
  const theme = useTheme()

  const {
    id,
    request: {
      beds,
      checkIn,
      checkOut,
      extraBeds,
      features,
      info,
      rooms,
      roomType,
    },
    roomReservations,
  } = data
  const { handleUpdateRoomQuantity, removeReservation } =
    useReservationListContext()

  const [roomCount, setRoomCount] = useState<number>(rooms)

  useEffect(() => {
    setRoomCount(rooms)
  }, [rooms])

  return (
    <>
      <ReservationCard
        confirmRemoval
        onRemove={() => removeReservation(id)}
        setOpenSections={() =>
          setOpenSections({
            [`accommodationLevel-${roomType.accommodationLevel.id}`]: true,
            [`category-${roomType.category.id}`]: true,
          })
        }
        title={
          <ReservationDataRow
            centerAlignContent
            header={
              <FlexRow alignItems="center">
                <NumberInput
                  min={0}
                  onBlur={() =>
                    handleUpdateRoomQuantity({
                      reservationId: data.id,
                      rooms: roomCount,
                    })
                  }
                  onChange={(e) => setRoomCount(Number(e.target.value))}
                  onFocus={(e) => e.currentTarget.select()}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUpdateRoomQuantity({
                        reservationId: data.id,
                        rooms: roomCount,
                      })
                    }
                  }}
                  value={`${roomCount}`}
                />
                <span style={{ marginRight: theme.spacing.gutterSmall }}>
                  {' '}
                  ✕{' '}
                </span>
              </FlexRow>
            }
          >
            <Title>{roomType.name}</Title>
          </ReservationDataRow>
        }
        type="ROOM_TYPE_RESERVATION"
      >
        <Gutter type={1}>
          <ReservationDataRow
            header={<Icon icon={['far', 'calendar']} large />}
          >
            <RangePicker
              checkIn={checkIn}
              checkOut={checkOut}
              reservationId={id}
            />
          </ReservationDataRow>
          <ReservationDataRow header={<Icon icon="bed" />}>
            <Beds
              beds={beds}
              totalBeds={(roomType.beds || 0) * rooms}
              extraBeds={extraBeds}
              totalExtraBeds={(roomType.extraBeds || 0) * rooms}
              reservationId={id}
            />
          </ReservationDataRow>
          <ReservationDataRow
            header={<Icon icon={['far', 'clipboard']} large />}
          >
            <Features
              note={info || ''}
              reservationId={id}
              selectedFeatures={features}
            />
          </ReservationDataRow>
          <ReservationDataRow header={<Icon icon="pen" />}>
            <AdditionalInfo
              features={features}
              note={info || ''}
              reservationId={id}
            />
          </ReservationDataRow>
        </Gutter>
      </ReservationCard>

      {roomReservations.map(
        (reservation: SalesRoomReservation, index: number) => (
          <NestedRoomReservation
            data={reservation}
            pendingReservations={pendingReservations}
            isLast={index === roomReservations.length - 1}
            focusedRoomId={focusedRoomId}
            key={reservation.id}
            setOpenSections={setOpenSections}
          />
        )
      )}
    </>
  )
}

export default RoomTypeReservation
