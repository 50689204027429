import { CSSProperties, ReactNode, Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { ModalContainer } from '@/components/Modal'
import { OutsideClick } from '@/components/OutsideClick'
import { useTheme } from '@/theme'

import { DataFieldButton } from './DataFieldButton'
import { DataFieldColorCircle } from './DataFieldColorCircle'
import { DataFieldColorPickerModal } from './DataFieldColorPickerModal'
import { DataFieldInput } from './DataFieldInput'
import { DataFieldTextareaModal } from './DataFieldTextareaModal'
import { PrimaryLabel as Primary } from './PrimaryLabel'
import { SecondaryLabel as Secondary } from './SecondaryLabel'
import { LabelWrapper as ValueWrapper } from './LabelWrapper'

type Props = {
  customValueRenderer?: (valueLabel: ReactNode) => ReactNode
  disabled?: boolean
  forcedWidth?: string
  handleSubmit?: (newValue: string | null) => Promise<any>
  hideEditIcon?: boolean
  inputType?: 'color' | 'text' | 'textarea'
  lineHeight?: string
  max?: number
  maxLength?: number
  min?: number
  placeholder?: ReactNode
  size?: string
  style?: CSSProperties
  type?: string
  value: string | null
  valueColor?: string
  valueStyle?: CSSProperties
  variant?: 'primary' | 'secondary'
}

export const DataField = ({
  customValueRenderer,
  disabled,
  forcedWidth,
  handleSubmit,
  hideEditIcon,
  inputType,
  lineHeight,
  max,
  maxLength,
  min,
  placeholder,
  size,
  style,
  type = 'text',
  value,
  valueColor,
  valueStyle,
  variant,
}: Props) => {
  const theme = useTheme()

  const [isEditing, setIsEditing] = useState<boolean>(false)

  const renderValue = () => {
    const valueLabel = value?.trim() || placeholder || '—'

    if (!!customValueRenderer) {
      return customValueRenderer(valueLabel)
    }

    if (inputType === 'color') {
      return <DataFieldColorCircle color={value || ''} />
    }

    switch (variant) {
      case 'primary':
        return (
          <Primary
            light={!value}
            lineHeight={lineHeight || size || theme.typography.fontSizeBase}
            size={size || theme.typography.fontSizeBase}
            style={{ color: valueColor || theme.palette.text.main }}
          >
            {valueLabel}
          </Primary>
        )
      case 'secondary':
      default:
        return <Secondary>{valueLabel}</Secondary>
    }
  }

  switch (inputType) {
    case 'color':
      return (
        <ModalContainer
          isOpen={isEditing}
          modal={
            <DataFieldColorPickerModal
              handleSubmit={(newValue: string | null) => {
                !!handleSubmit &&
                  handleSubmit(newValue).then(() => setIsEditing(false))
              }}
              value={value}
            />
          }
          onClose={() => setIsEditing(false)}
          placement="bottom-start"
          referenceElement={({ ref }) => (
            <HoverHelper
              style={
                forcedWidth ? { flex: '0 0 auto', width: forcedWidth } : {}
              }
            >
              <DataFieldButton
                className={isEditing ? 'active' : ''}
                disabled={disabled}
                onClick={() => setIsEditing(true)}
                ref={ref as Ref<HTMLButtonElement> | undefined}
              >
                <ValueWrapper>{renderValue()}</ValueWrapper>
                {!hideEditIcon && (
                  <FontAwesomeIcon
                    className="ifb-icon"
                    size="sm"
                    color={theme.palette.text.lighter}
                    icon="pen"
                    style={{ marginRight: `${theme.spacing.gu(1.5)}rem` }}
                  />
                )}
              </DataFieldButton>
            </HoverHelper>
          )}
          zIndex={10003}
        />
      )
    case 'text':
      return isEditing ? (
        <OutsideClick onOutsideClick={() => setIsEditing(false)} style={style}>
          <DataFieldInput
            handleSubmit={(newValue: string | null) =>
              !!handleSubmit
                ? handleSubmit(newValue).then(() => setIsEditing(false))
                : Promise.reject()
            }
            max={max}
            maxLength={maxLength}
            min={min}
            type={type}
            value={value}
          />
        </OutsideClick>
      ) : (
        <HoverHelper style={style}>
          <DataFieldButton
            disabled={disabled}
            onClick={() => setIsEditing(true)}
          >
            <ValueWrapper style={valueStyle}>{renderValue()}</ValueWrapper>
            {!hideEditIcon && !disabled && (
              <FontAwesomeIcon
                className="ifb-icon"
                size="sm"
                color={theme.palette.text.lighter}
                icon="pen"
                style={{ marginRight: `${theme.spacing.gu(1.5)}rem` }}
              />
            )}
          </DataFieldButton>
        </HoverHelper>
      )
    case 'textarea':
      return (
        <ModalContainer
          isOpen={isEditing}
          modal={
            <DataFieldTextareaModal
              close={() => setIsEditing(false)}
              handleSubmit={(newValue: string | null) => {
                setIsEditing(false)
                return !!handleSubmit
                  ? handleSubmit(newValue)
                  : Promise.reject()
              }}
              value={value}
            />
          }
          onClose={() => setIsEditing(false)}
          placement="bottom"
          referenceElement={({ ref }) => (
            <HoverHelper style={style}>
              <DataFieldButton
                className={isEditing ? 'active' : ''}
                disabled={disabled}
                onClick={() => setIsEditing(true)}
                ref={ref as Ref<HTMLButtonElement> | undefined}
              >
                <ValueWrapper style={valueStyle}>{renderValue()}</ValueWrapper>
                <FontAwesomeIcon
                  className="ifb-icon"
                  size="sm"
                  color={theme.palette.text.lighter}
                  icon="pen"
                  style={{ marginRight: `${theme.spacing.gu(1.5)}rem` }}
                />
              </DataFieldButton>
            </HoverHelper>
          )}
        />
      )
    default:
      return <ValueWrapper style={style}>{renderValue()}</ValueWrapper>
  }
}

////////////

const HoverHelper = styled.div`
  flex: 0 1 auto;
  display: inline-flex;
  align-items: center;
  min-width: 0;

  & .ifb-icon {
    opacity: 0;

    ${({ theme }) => css`
      margin: 0 ${theme.spacing.gutterSmall};
    `}
  }
  &:hover .ifb-icon {
    opacity: 1;
  }
`
