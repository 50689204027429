import { Redirect, Switch } from 'react-router-dom'

import { PageRoute } from '@/components/Page'
import { SalesList } from '@/modules/Mobile/SalesList'

export const AppRoutesMobile = () => (
  <Switch>
    <PageRoute component={SalesList} path={'/mobile/sales'} />

    <Redirect from="/" to="/mobile/sales" />
  </Switch>
)
