import { Placeholder as PlaceholderBase } from '@/components/Placeholder'
import { ReactNode } from 'react'
import styled from 'styled-components/macro'

type Props = {
  children: ReactNode
}

const GridContentPlaceholder = ({ children }: Props) => (
  <Wrapper>
    <Placeholder>{children}</Placeholder>
  </Wrapper>
)

export default GridContentPlaceholder

////////////

const Wrapper = styled.div`
  max-width: calc(100vw - 280px);
  min-width: calc(100vw - 280px);
  position: sticky;
  top: 0;
  left: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Placeholder = styled(PlaceholderBase.withComponent('span'))<any>`
  min-height: 25vh;
`
