import { gql, useMutation } from '@apollo/client'

import {
  SetSalesCustomerMutation,
  SetSalesCustomerMutationVariables,
} from '~generated-types'

export type { SetSalesCustomerMutation, SetSalesCustomerMutationVariables }

export const SET_SALES_CUSTOMER_MUTATION = gql`
  mutation SetSalesCustomer($input: SalesSetCustomerInput!) {
    salesSetCustomer(input: $input) {
      sales {
        id
      }
    }
  }
`

export function useSetSalesCustomerMutation() {
  return useMutation<
    SetSalesCustomerMutation,
    SetSalesCustomerMutationVariables
  >(SET_SALES_CUSTOMER_MUTATION)
}

export default useSetSalesCustomerMutation
