import {
  formatDate,
  formatRoom,
  getFullName,
} from '@/modules/Mobile/SalesList/utils'
import {
  MobileSalesParticipant,
  MobileSalesParticipantServiceBed,
} from '@/modules/Mobile/SalesList'
import { typeGuardFactory } from '@/utils/types'

import { Content, ContentWrapper } from '../../../components'

type Props = {
  isNested?: boolean
  participant: MobileSalesParticipant
}

export const ParticipantRow = ({
  isNested,
  participant: { firstName, lastName, services },
}: Props) => {
  const servicesBed = services.filter(
    typeGuardFactory('__typename', 'ServiceParticipantBed')
  )

  const renderServiceData = (
    service: MobileSalesParticipantServiceBed,
    isNested?: boolean
  ) => {
    const end = service.dates?.checkOut.date
    const start = service.dates?.checkIn.date
    const room = service.participantRoom?.roomReservation.request.room.number

    return (
      <>
        <Content width="32.5%">
          {end && start && formatDate({ end, start })}
        </Content>

        {!isNested && (
          <Content width="27.5%">{room && formatRoom(room)}</Content>
        )}
      </>
    )
  }

  return (
    <>
      <ContentWrapper>
        <Content
          bold
          width={isNested ? '60%' : servicesBed[0] ? '40%' : '100%'}
        >
          {getFullName(firstName, lastName) || '–'}
        </Content>

        {servicesBed[0] && renderServiceData(servicesBed[0], isNested)}
      </ContentWrapper>

      {servicesBed.slice(1).map((service) => (
        <ContentWrapper key={service.id}>
          <Content width="40%" />

          {renderServiceData(service, isNested)}
        </ContentWrapper>
      ))}
    </>
  )
}
