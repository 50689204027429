import { useQuery } from '@apollo/client'

import type {
  CatalogProductByIdQuery as QueryData,
  CatalogProductByIdQueryVariables as QueryVariables,
} from '~generated-types'

import { CatalogProduct } from '../types'
import { productQueries } from '../queries'

type Params = {
  id: string
}

export const useCatalogProductById = ({ id }: Params) => {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    productQueries.GET_CATALOG_PRODUCT_BY_ID,
    {
      fetchPolicy: 'cache-and-network',
      variables: { id },
    }
  )

  const product: CatalogProduct | null = data?.catalogProduct || null

  return {
    error,
    loading,
    product,
    refetch,
  }
}
