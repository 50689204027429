import styled, { css } from 'styled-components/macro'

import { DateRange } from '@/components/TimeControls'
import { FlexRow } from '@/components/Layout'

import { DateRangeSelector } from './DateRangeSelector'
import { Mode } from '../../AvailabilityCalendar.types'
import { ModeSelector } from './ModeSelector'

type Props = {
  displayRange: DateRange
  mode: Mode
  setDisplayRange: (range: DateRange) => void
  setMode: (mode: Mode) => void
}

export const ViewControls = ({
  displayRange,
  mode,
  setDisplayRange,
  setMode,
}: Props) => (
  <Wrapper>
    <DateRangeSelector
      displayRange={displayRange}
      setDisplayRange={setDisplayRange}
    />

    <Spacer />

    <ModeSelector mode={mode} setMode={setMode} />
  </Wrapper>
)

////////////

const Spacer = styled.span`
  flex: 1;
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    background: ${theme.palette.smoke.extraLight};
    border-bottom: 1px solid ${theme.palette.smoke.main};
    padding: ${theme.spacing.gutter};
    padding-left: ${theme.spacing.gutterBig};
  `}
`
