import { HOCProps, withPriceInputLogic } from '@/utils/hocs/withPriceInputLogic'
import styled, { css } from 'styled-components/macro'

import { ActionRuleType } from '~generated-types'
import { DataTableInput } from '@/components/DataTable'
import { useCatalogProductManagerContext } from '@/modules/Products/hooks'

type Props = {
  amount: number
  id: string
}

type InternalProps = HOCProps & {
  updateValue: (amount: number) => void
}

export const AmountInput = ({ amount, id }: Props) => {
  const { updateProductItemRule } = useCatalogProductManagerContext()

  const handleUpdateAmount = (amount: number) =>
    updateProductItemRule({
      action: {
        actionType: ActionRuleType.Amount,
        amount,
      },
      productRuleId: id,
    })

  return (
    <InputWithPriceLogic updateValue={handleUpdateAmount} value={`${amount}`} />
  )
}

const InputBase = ({ updateValue, value, ...props }: InternalProps) => (
  <InputWrapper>
    <Input
      {...props}
      endAdornment="€"
      flex="none"
      onBlur={() => updateValue(Number(value || 0))}
      onFocus={(e) => e.currentTarget.select()}
      showBorder
      type="text"
      value={value}
    />
  </InputWrapper>
)

const InputWithPriceLogic = withPriceInputLogic(InputBase)

///////

const Input = styled(DataTableInput)`
  &:not([type='checkbox']):not([type='radio']) {
    text-align: end;

    ${({ theme }) => css`
      border-color: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(4)}rem;
      padding: 0px ${theme.spacing.gu(3)}rem 0px ${theme.spacing.gu(1)}rem;
      width: ${theme.spacing.gu(12)}rem;
    `}

    &:disabled {
      ${({ theme }) => css`
        background: ${theme.palette.smoke.lighter};
      `}
    }
  }
`

const InputWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: calc(${theme.spacing.gu(1)}rem + 1px);
  `}
`
