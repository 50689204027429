import styled, { css } from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import { ReservationsForDateQuery } from '~generated-types'

import { BedsAmount } from './BedsAmount'
import { RoomsAmount } from './RoomsAmount'

type Reservation =
  ReservationsForDateQuery['accommodationRoomReservations']['reservations'][0]

type Props = {
  reservations: Reservation[]
}

export const RoomReservations = ({ reservations }: Props) => {
  const bedsCalculated = reservations.reduce(
    (a, b) => ({
      beds: a.beds + b.request.beds,
      extraBeds: a.extraBeds + b.request.extraBeds,
      room: {
        beds: a.room.beds + b.request.room.beds,
        extraBeds: a.room.extraBeds + b.request.room.extraBeds,
      },
    }),
    {
      beds: 0,
      extraBeds: 0,
      room: {
        beds: 0,
        extraBeds: 0,
      },
    }
  )

  return (
    <ReservationWrapper>
      <DateWrapper />

      <RoomsAmount rooms={reservations.length} />

      <BedsAmount
        usedBeds={bedsCalculated.beds}
        totalBeds={bedsCalculated.room.beds}
        usedExtraBeds={bedsCalculated.extraBeds}
        totalExtraBeds={bedsCalculated.room.extraBeds}
      />
    </ReservationWrapper>
  )
}

/////////

const DateWrapper = styled.div`
  width: 15rem;
`

const ReservationWrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutterSmall} ${theme.spacing.gu(1)}rem;
  `}

  width: 100%;
  transition: 0.2s;
  height: 100%;
  align-items: center;

  &:hover {
    ${({ theme }) => css`
      background-color: ${theme.palette.smoke.lighter};
    `}
  }
`
