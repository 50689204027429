import { gql, useMutation } from '@apollo/client'

import ResourceReservationFragment from '../fragments/resourceReservation'

import {
  CreateResourceReservationMutation,
  CreateResourceReservationMutationVariables,
} from '~generated-types'

export type {
  CreateResourceReservationMutation,
  CreateResourceReservationMutationVariables,
}

export const CREATE_RESOURCE_RESERVATION_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation CreateResourceReservation($input: ResourceReservationCreateInput!) {
    resourceReservationCreate(input: $input) {
      ... on ReservationPayloadOk {
        reservation {
          ...ResourceReservation
        }
      }
      ... on ReservationAvailabilityError {
        message
      }
      ... on ReservationAvailabilityWarning {
        message
      }
    }
  }
`

export function useCreateResourceReservationMutation() {
  return useMutation<
    CreateResourceReservationMutation,
    CreateResourceReservationMutationVariables
  >(CREATE_RESOURCE_RESERVATION_MUTATION)
}

export default useCreateResourceReservationMutation
