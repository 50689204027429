import styled from 'styled-components'
import { useQuery } from '@apollo/client'
import { useState } from 'react'

import { WebshopContactsQuery as WebshopContactsType } from '~generated-types'

import { Option, ThemedMultiSelect } from '@/components/ThemedSelect'
import { EditButton } from '@/components/ExtraButtons'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { ContentPlaceholder, TruncateContent } from '../../common'
import { publishQueries } from '../../../queries'
import { usePublishContext } from '../../../PublishState'

export const Contacts = () => {
  const { spacing } = useTheme()
  const {
    readOnly,
    salesWebshopSettings,
    salesId,
    addWebshopContacts,
    removeWebshopContacts,
  } = usePublishContext()

  const [isEditMode, setEditMode] = useState<boolean>(false)

  // Contacts to show in the edit button
  const contactsToShow =
    salesWebshopSettings?.contacts?.map(({ name }) => name) ?? []

  // Contacts to show in the select component
  const savedContacts =
    salesWebshopSettings?.contacts?.map(({ id }) => id) ?? []

  // Contacts to show in the select list
  const { data, loading } = useQuery<WebshopContactsType>(
    publishQueries.webshopContacts,
    {
      fetchPolicy: 'cache-first',
      skip: !isEditMode,
    }
  )
  const webshopContacts = data?.registry.webshopContacts
  const options: Option[] =
    webshopContacts?.map(({ id, name }) => ({
      label: name,
      value: id,
    })) ?? []

  // Add and remove contacts from webshop settings
  const handleSelectContacts = async (values: string[]) => {
    const addContactsIDs = values.filter((v) => !savedContacts.includes(v))
    const removeContactsIDs = savedContacts.filter((c) => !values.includes(c))

    if (addContactsIDs.length > 0 || removeContactsIDs.length > 0) {
      await addWebshopContacts({
        contactIds: addContactsIDs,
        salesId,
      })

      await removeWebshopContacts({
        contactIds: removeContactsIDs,
        salesId,
      })
    }
  }

  return (
    <>
      <Label>
        <T>Publish:Basic.contacts.title</T>
      </Label>

      {isEditMode ? (
        <ThemedMultiSelect
          controlStyle={{ marginLeft: `-${spacing.gu(1)}rem` }}
          isCompact
          loading={loading}
          noOptionsPlaceholder={
            <T>Publish:Basic.contacts.noContactsAvailable</T>
          }
          modalWidth={`${spacing.gu(40)}rem`}
          onModalClose={() => setEditMode(false)}
          openModal={isEditMode}
          options={options}
          placeholder={<T>Publish:Basic.contacts.noContactsSelected</T>}
          selectedValues={savedContacts}
          setSelectedValues={handleSelectContacts}
        />
      ) : (
        <StyledEditButton disabled={readOnly} onClick={() => setEditMode(true)}>
          <TruncateContent
            content={
              contactsToShow.length ? (
                contactsToShow.join(', ')
              ) : (
                <ContentPlaceholder>
                  <T>Publish:Basic.contacts.noContactsSelected</T>
                </ContentPlaceholder>
              )
            }
          />
        </StyledEditButton>
      )}
    </>
  )
}

const StyledEditButton = styled(EditButton)`
  flex: unset;
  width: 100%;
`
