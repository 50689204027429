import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexRow } from '@/components/Layout'
import { HeaderSectionSeparator } from '@/components/ExpansionPanel'
import { T } from '@/modules/Language'

import {
  ShareToSalesSelector,
  TargetName,
  TargetRemoveButton,
  TargetStatus,
} from './components'
import { SalesAccommodationGroup } from '../../SalesReservationList.types'
import { useReservationListContext } from '../../../SalesReservationList/ReservationListState'

type Props = {
  target: SalesAccommodationGroup['targets'][0]
  defaultConsumption: number
  isManagerDisabled: boolean
  readOnly?: boolean
  setManagerOpen: (targetId: string) => void
}

export const TargetHeader = ({
  target,
  defaultConsumption,
  isManagerDisabled,
  readOnly,
  setManagerOpen,
}: Props) => {
  const {
    saleType,
    handleRemoveTarget,
    handleUpdateTarget,
    handleTargetAddSales,
    handleTargetRemoveSales,
  } = useReservationListContext()

  const [name, setName] = useState(target.name)
  const targetName = target.name

  useEffect(() => {
    targetName !== name && setName(targetName)
  }, [targetName])

  const handleSetUsingDefault = (usingDefault: boolean) =>
    handleUpdateTarget({
      floating: usingDefault,
      id: target.id,
      name: target.name,
    })

  const handleUpdateName = (name: string) =>
    handleUpdateTarget({
      floating: target.floating,
      id: target.id,
      name,
    })

  return (
    <Wrapper alignItems="center" flex={1}>
      <TargetName
        targetName={target.name}
        targetOrderNumber={target.sortOrder}
        isDefaultTarget={target.default}
        readOnly={readOnly}
        onNameUpdate={handleUpdateName}
      />

      <HeaderSectionSeparator />

      {target.nonBlockingCapacity && (
        <>
          <InfoIcon size="sm" icon="triangle-exclamation" />
          <Placeholder>
            <T>Accommodation:SalesReservationList.nonBlockingCapacity</T>
          </Placeholder>
        </>
      )}

      {(saleType === 'EVENT' || saleType === 'ENROLLMENT') &&
        !target.default && (
          <>
            <ShareToSalesSelector
              target={target}
              readOnly={readOnly}
              handleTargetAddSales={handleTargetAddSales}
              handleTargetRemoveSales={handleTargetRemoveSales}
            />

            <HeaderSectionSeparator />
          </>
        )}

      <span style={{ flex: 1 }} />

      <TargetStatus
        id={target.id}
        readOnly={readOnly}
        status={target.status.status}
        isDefault={target.default}
        usingDefault={target.floating}
        defaultConsumption={defaultConsumption}
        isManagerDisabled={isManagerDisabled}
        handleUpdateTarget={handleSetUsingDefault}
        setManagerOpen={setManagerOpen}
      />

      <TargetRemoveButton
        targetId={target.id}
        isDefaultTarget={target.default}
        readOnly={readOnly}
        handleRemoveTarget={handleRemoveTarget}
      />
    </Wrapper>
  )
}

//////

const InfoIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(2)}rem;
  `}
`

const Placeholder = styled.div`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    margin-left: ${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(2)}rem;
  `}
`
