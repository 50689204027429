import { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import ReactLoading from 'react-loading'

import { APIBaseUrl, apiCall } from '@/utils/api'
import { FetchState, FetchStates } from '@/common/types'
import {
  SpreadsheetTable as Table,
  SpreadsheetTBody as TBody,
  SpreadsheetTD as TD,
  SpreadsheetTR as TR,
} from '@/components/Spreadsheet'
import { translate, useLanguageContext } from '@/modules/Language'
import { Attachment } from '@/modules/Attachments'
import { FilePreview } from '@/components/FilePreview'
import { notify } from '@/components/NotificationService'
import { Placeholder } from '@/components/Placeholder'
import { Right } from '@/components/Layout'
import { salesHooks } from '@/modules/Sales/'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { AttachmentListHeader, AttachmentListItem } from './components'

export const AttachmentList = () => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()

  const {
    data: { attachments, id },
    saleReadOnly,
    refresh,
  } = salesHooks.useSalesDetailsContext()

  const [fileState, setFileState] = useState<FetchState>(FetchStates.IDLE)
  const [fileUrl, setFileUrl] = useState<string>('')
  const [isFilePreviewOpen, setFilePreviewOpen] = useState<boolean>(false)
  const [isUploading, setUploading] = useState<boolean>(false)

  const notifyError = (path: string) =>
    notify({
      content: translate(`Attachments:${path}`, language),
      type: 'ERROR',
    })

  const handleUploadAttachment = ({
    target: { validity, files },
  }: ChangeEvent<HTMLInputElement>) => {
    if (!files?.length) {
      return
    }

    const [file] = files

    if (attachments.map((a: Attachment) => a.fileName).includes(file.name)) {
      return notifyError('uploadDuplicatedError')
    }

    if (validity.valid) {
      setUploading(true)

      const data = new FormData()
      data.append('file', file)

      apiCall(`${APIBaseUrl}/sales/${id}/files/attachments`, {
        body: data,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
        method: 'POST',
      })
        .then(({ data, ok }) => {
          if (!ok || !data) {
            return notifyError('uploadError')
          }

          refresh()
        })
        .catch(() => notifyError('uploadError'))
        .finally(() => setUploading(false))
    }
  }

  return (
    <>
      <ActionsWrapper>
        <input
          disabled={saleReadOnly}
          accept=".pdf, .jpeg, .jpg, .gif, .tiff, .png"
          hidden
          id="upload"
          onChange={handleUploadAttachment}
          required
          type="file"
        />
        {isUploading ? (
          <LoadingWrapper>
            <ReactLoading
              color={palette.smoke.dark}
              height={21}
              type="spin"
              width={21}
            />
          </LoadingWrapper>
        ) : (
          <UploadButton
            disabled={saleReadOnly}
            htmlFor="upload"
            style={{ cursor: saleReadOnly ? 'not-allowed' : 'pointer' }}
          >
            <T>Attachments:uploadAttachment</T>
          </UploadButton>
        )}
      </ActionsWrapper>

      <Table style={{ margin: 'auto', maxWidth: 1500 }}>
        <AttachmentListHeader />

        <TBody>
          {attachments.length ? (
            attachments.map((a: Attachment, index: number) => (
              <AttachmentListItem
                attachment={a}
                key={a.id}
                number={index + 1}
                ownerId={id}
                readOnly={saleReadOnly}
                refresh={refresh}
                setFilePreviewOpen={setFilePreviewOpen}
                setFileState={setFileState}
                setFileUrl={setFileUrl}
              />
            ))
          ) : (
            <TR>
              <TD colSpan={6}>
                <Placeholder>
                  <T>{`common:state.${
                    attachments.length ? 'error' : 'empty'
                  }`}</T>
                </Placeholder>
              </TD>
            </TR>
          )}
        </TBody>
      </Table>

      {isFilePreviewOpen && (
        <FilePreview
          fileState={fileState}
          fileUrl={fileUrl}
          onClose={() => setFilePreviewOpen(false)}
        />
      )}
    </>
  )
}

////////

const ActionsWrapper = styled(Right)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gutter} auto;
  `}
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(22)}rem;
    height: ${theme.spacing.guPx(5) + 2}px;
  `}
`

const UploadButton = styled.label<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  text-decoration: none;

  ${({ theme }) => css`
    height: ${theme.spacing.guPx(5) + 2}px;
    width: ${theme.spacing.gu(22)}rem;
    color: ${theme.palette.primary.dark};
    font-size: ${theme.typography.fontSizeBase};
    font-family: ${theme.typography.fontFamily};
  `}

  background: transparent;
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;

  ${({ disabled }) => disabled && `opacity: 0.5;`}

  &:hover {
    transform: none;

    ${({ theme }) => css`
      background: ${theme.palette.primary.extraLight};
    `}
  }
`
