import { Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { ApolloQueryResult } from '@apollo/client'
import ReactLoading from 'react-loading'

import {
  Feature,
  PurchaseProductAction as PA,
  UseTaskQuery,
} from '~generated-types'
import { Task, taskHooks } from '@/modules/Task'
import { DangerColor } from '@/components/Colors'
import { FlexRow } from '@/components/Layout'
import { FontWeight } from '@/components/Typography'
import { formatCurrency } from '@/utils/currency'
import { InlineModalIconSection } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { ProductPurchase } from '@/modules/Products/types'
import { SalesProductManager } from '@/modules/Products'
import { T } from '@/modules/Language'
import { Tooltip } from '@/components/Tooltip'
import { useDialogService } from '@/components/DialogService'
import { useTheme } from '@/theme'
import { ValidationIndicator } from '@/components/ValidationIndicator'

import {
  ActionButton,
  ActionButtonWrapper,
  EditButton,
  LoaderWrapper,
} from './common'
import { Context } from '../types'

type Props = {
  context: Context
  onUpdateTasks: (task: Task[]) => void
  purchase: ProductPurchase
  readOnly: boolean
  refetchTask: () => Promise<ApolloQueryResult<UseTaskQuery>>
  sales: Task['sales']
  taskId: string
}

// TODO Purchase quantity<SalesProductManager

export const Purchase = ({
  context,
  onUpdateTasks,
  purchase: {
    id,
    product: { id: productId, name },
    status: { issues, validatedActions },
    totalPrice: { amountVatIncluded },
  },
  readOnly,
  refetchTask,
  sales: { commissionRate, facet, id: salesId, seller, type: salesType },
  taskId,
}: Props) => {
  const { palette, spacing } = useTheme()
  const { confirm } = useDialogService()

  const { removeTaskPurchase } = taskHooks.useTaskMutations({ onUpdateTasks })

  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [isModalOpen, setModalOpen] = useState(false)

  const isCommissionEnabled = !!facet.features.find(
    ({ feature }) => feature === Feature.Commission
  )

  const commission = isCommissionEnabled ? commissionRate : null

  const deleteAction = validatedActions.find((a) => a.action === PA.Delete)

  const handleDeletePurchase = () => {
    setProcessing(true)

    const handleDelete = () =>
      removeTaskPurchase({ purchaseId: id, taskId }).then(() =>
        setProcessing(false)
      )

    return confirm({
      cancelLabel: <T>common:action.cancel</T>,
      confirmLabel: (
        <DangerColor>
          <T>common:action.remove</T>
        </DangerColor>
      ),
      description: <T>ReservationModal:removalConfirmation.product</T>,
    })
      .then(handleDelete)
      .catch(() => setProcessing(false))
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    refetchTask()
  }

  return (
    <InlineModalIconSection icon="cube">
      <FlexRow flex={1}>
        <ModalContainer
          isOpen={isModalOpen}
          modal={
            <SalesProductManager
              commission={commission}
              context={context}
              onClose={handleCloseModal}
              productId={productId}
              readOnly={readOnly}
              salesId={salesId}
              salesType={salesType}
              sellerId={seller?.id}
              targetPurchaseId={id}
            />
          }
          onClose={handleCloseModal}
          placement="bottom"
          referenceElement={({ ref }) => (
            <EditButton
              onClick={() => setModalOpen(true)}
              ref={ref as Ref<HTMLButtonElement>}
              style={{ justifyContent: 'flex-start' }}
            >
              <PurchaseName>
                {issues.map(({ code, key, level }) => (
                  <ValidationIndicatorWrapper key={`${key}-${code}`}>
                    <ValidationIndicator
                      code={code}
                      level={level}
                      style={{ margin: `0 ${spacing.gutterSmall}` }}
                    />
                  </ValidationIndicatorWrapper>
                ))}

                <Tooltip
                  content={name}
                  delay={300}
                  trigger={(triggerProps) => (
                    <span {...triggerProps}>{name}</span>
                  )}
                />
              </PurchaseName>
            </EditButton>
          )}
          styleOverrides={{
            left: 'unset',
            right: 0,
            transform: 'none',
          }}
        />

        <PurchaseTotal semiBold>
          {formatCurrency(amountVatIncluded || 0)} €
        </PurchaseTotal>

        {isProcessing ? (
          <LoaderWrapper>
            <ReactLoading
              color={palette.smoke.dark}
              height={18}
              type="spin"
              width={18}
            />
          </LoaderWrapper>
        ) : (
          <ActionButtonWrapper>
            {deleteAction && deleteAction.valid && (
              <ActionButton
                content={<T>Products:ProductManager.action.deletePurchase</T>}
                icon="trash"
                iconColor={palette.danger.main}
                onClick={handleDeletePurchase}
              />
            )}
          </ActionButtonWrapper>
        )}
      </FlexRow>
    </InlineModalIconSection>
  )
}

//////

const PurchaseTotal = styled(FontWeight)`
  align-self: center;
  text-align: end;

  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
    width: ${theme.spacing.gu(14)}rem;
  `}
`

const PurchaseName = styled.span`
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(32)}rem;
  `}
`

const ValidationIndicatorWrapper = styled.span`
  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(0.5)}rem;
    margin-right: ${theme.spacing.gu(0.5)}rem;
  `}
`
