import { ControlProps, OptionTypeBase } from 'react-select'
import { darken, lighten } from 'polished'
import { CSSProperties } from 'react'

import type { Theme as StyledComponentsTheme } from '@/theme'

export const getCustomStyles = (theme: StyledComponentsTheme) => ({
  container: (styles: CSSProperties) => ({
    ...styles,
    flex: 1,
  }),
  control: (
    styles: CSSProperties,
    { isDisabled }: ControlProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    backgroundColor: isDisabled
      ? theme.palette.smoke.lighter
      : styles.backgroundColor,
    borderColor: isDisabled ? theme.palette.smoke.dark : styles.borderColor,
    cursor: 'pointer',
  }),
  indicatorSeparator: (
    styles: CSSProperties,
    { isDisabled }: ControlProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    backgroundColor: isDisabled
      ? theme.palette.smoke.dark
      : styles.backgroundColor,
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    zIndex: 10005,
  }),
  menuPortal: (styles: CSSProperties) => ({
    ...styles,
    zIndex: 10005,
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor:
      styles.backgroundColor === 'transparent' ? 'not-allowed' : 'pointer',
  }),
  placeholder: (styles: CSSProperties) => ({
    ...styles,
    color: theme.palette.text.lighter,
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    width: '100%',
  }),
})

export const getCustomCompactStyles = (theme: StyledComponentsTheme) => ({
  ...getCustomStyles(theme),
  clearIndicator: (styles: CSSProperties) => ({
    ...styles,
    padding: '0 2px',
  }),
  control: (
    styles: CSSProperties,
    { isDisabled }: ControlProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    backgroundColor: isDisabled
      ? theme.palette.smoke.lighter
      : styles.backgroundColor,
    borderColor: isDisabled ? theme.palette.smoke.dark : styles.borderColor,
    cursor: 'pointer',
    height: '30px',
    minHeight: '30px',
  }),
  dropdownIndicator: (styles: CSSProperties) => ({
    ...styles,
    padding: '0 2px',
  }),
  indicatorSeparator: (
    styles: CSSProperties,
    { isDisabled }: ControlProps<OptionTypeBase, false>
  ) => ({
    ...styles,
    backgroundColor: isDisabled
      ? theme.palette.smoke.dark
      : styles.backgroundColor,
    margin: '4px 0px',
  }),
  option: (styles: CSSProperties) => ({
    ...styles,
    cursor:
      styles.backgroundColor === 'transparent' ? 'not-allowed' : 'pointer',
    lineHeight: '24px',
    padding: '4px 12px',
  }),
  singleValue: (styles: CSSProperties) => ({
    ...styles,
    width: '100%',
  }),
  valueContainer: (styles: CSSProperties) => ({
    ...styles,
    maxHeight: '30px',
    padding: '0px 4px',
  }),
})

export const getTheme =
  (theme: StyledComponentsTheme) => (libTheme: { [key: string]: any }) => ({
    ...libTheme,
    colors: {
      ...libTheme.colors,
      danger: theme.palette.danger.main, // Remove tag icon (multi only)
      dangerLight: lighten(0.4, theme.palette.danger.main), // Remove tag bg (multi only)
      neutral10: theme.palette.primary.extraLight, // Tag bg (multi only)
      neutral20: theme.palette.smoke.dark, // Borders & icons
      neutral80: theme.palette.text.main, // Font
      primary: theme.palette.primary.main, // Selected option
      primary25: theme.palette.primary.extraLight, // Hover option
      primary50: darken(0.05, theme.palette.primary.extraLight), // Active option
    },
  })
