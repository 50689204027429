import styled, { css } from 'styled-components/macro'
import { ReactNode } from 'react'

import { Header } from './Header'
import { ScrollTableProps } from './types'

type Props = ScrollTableProps & {
  children?: ReactNode
}

export const ScrollTable = ({ children, ...props }: Props) => (
  <TableWrapper>
    <TableContainer>
      <ScrollContainer>
        <TableContentWrapper>
          <Header {...props} />
          {children}
        </TableContentWrapper>
      </ScrollContainer>
    </TableContainer>
  </TableWrapper>
)

////////////

const TableWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
`

const ScrollContainer = styled.div`
  overflow: auto;
  flex-grow: 1;
  position: relative;
`

const TableContentWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  min-width: 100%;

  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(10)}rem;
  `}
`
