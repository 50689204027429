import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Moment } from 'moment'

import { T, translate, useLanguageContext } from '@/modules/Language'
import { Button } from '@/components/Button'
import { FlexRow } from '@/components/Layout'
import { formatCurrency } from '@/utils/currency'
import { H4 } from '@/components/Typography'
import { ListControls } from '@/components/List'
import { OpenAdvancesQuery } from '~generated-types'

import { generateOpenAdvanceExcel } from '../utils'

type Props = {
  date: Moment
  isDateSelected: boolean
  loading: boolean
  openAdvances: OpenAdvancesQuery['openAdvances']
  sidebarWidth: string
}

export const OpenAdvancesHeader = ({
  date,
  isDateSelected,
  loading,
  openAdvances,
  sidebarWidth,
}: Props) => {
  const { language } = useLanguageContext()

  const total =
    openAdvances.reduce((acc, row) => {
      return acc + Math.round((row.amountWithoutServiceFeeOrVat ?? 0) * 100)
    }, 0) / 100

  const downloadExcel = () =>
    generateOpenAdvanceExcel({
      data: openAdvances,
      targetDate: date,
      translateFn: (keys) =>
        translate(`Reports:OpenAdvances.field.${keys}`, language),
    })

  return (
    <Wrapper sidebarWidth={sidebarWidth}>
      <FlexRow alignItems="center" justifyContent="space-between">
        <Title>
          <T>Reports:OpenAdvances.title</T>
          {isDateSelected && ` – ${date.format('DD.MM.YYYY')}`}
        </Title>

        <Button
          disabled={loading || !isDateSelected}
          onClick={downloadExcel}
          size="small"
        >
          <ExcelIcon icon={['far', 'file-excel']} />
          <span>Excel</span>
        </Button>
      </FlexRow>

      <TotalRow alignItems="center" justifyContent="flex-start">
        {!!openAdvances.length && (
          <>
            <T>Reports:OpenAdvances.total</T>
            <span>{`: ${formatCurrency(total)} €`}</span>
          </>
        )}
      </TotalRow>
    </Wrapper>
  )
}

//////

const ExcelIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => css`
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const TotalRow = styled(FlexRow)`
  font-weight: 500;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.gu(1)}rem;
    height: ${theme.spacing.gu(4)}rem;
  `}
`

const Title = styled(H4)`
  font-weight: 500;
  margin-bottom: 0;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBigger};
  `}
`

const Wrapper = styled(ListControls)<{ sidebarWidth: string }>`
  flex: 0 0 auto;

  ${({ sidebarWidth, theme }) => css`
    margin-top: ${theme.spacing.gu(2)}rem;
    margin-bottom: ${theme.spacing.gu(1)}rem;
    margin-left: ${sidebarWidth};
  `}
`
