import { ColumnDetails, usePresentationState } from '../PresentationState'
import { DragLayer } from 'react-dnd'
import { ensureInteger } from '../helpers'
import React from 'react'
import { useGridContext } from '../GridContext'

const layerStyles = {
  height: '100%',
  left: 0,
  pointerEvents: 'none',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: 2001,
}
function getItemPosition(
  props: any,
  snapToGridCellCenter: any,
  rowHeight: any,
  columnWidth: any
) {
  const { item } = props
  const { initialOffset, currentOffset } = props

  if (!initialOffset || !currentOffset || !item) {
    return { columnIndexes: [], rowIndexes: [], x: null, y: null }
  }

  const {
    y: verticalOffsetDuringDragOperationStart,
    x: horizontalOffsetDuringDragOperationStart,
  } = initialOffset
  const { y: currentVerticalOffset, x: currentHorizontalOffset } = currentOffset
  const horizontalMovementRelativeToRoot =
    currentHorizontalOffset - horizontalOffsetDuringDragOperationStart
  const verticalMovementRelativeToRoot =
    currentVerticalOffset - verticalOffsetDuringDragOperationStart
  const [horizontalMovementOnGrid, verticalMovementOnGrid] =
    snapToGridCellCenter(
      horizontalMovementRelativeToRoot,
      verticalMovementRelativeToRoot
    )
  const columnDelta = ensureInteger(horizontalMovementOnGrid / columnWidth)
  const rowDelta = ensureInteger(verticalMovementOnGrid / rowHeight)

  const nextFirstColumnIndex = item.columnIndexes[0] + columnDelta
  const nextColumnCount = item.columnIndexes.length
  const nextColumnIndexes = Array.from(
    { length: nextColumnCount },
    (content, i) => nextFirstColumnIndex + i
  )
  const nextFirstRowIndex = item.rowIndexes[0] + rowDelta
  const nextRowCount = item.rowIndexes.length
  const nextRowIndexes = Array.from(
    { length: nextRowCount },
    (content, i) => nextFirstRowIndex + i
  )

  return {
    columnIndexes: nextColumnIndexes,
    rowIndexes: nextRowIndexes,
    x:
      horizontalOffsetDuringDragOperationStart +
      nextFirstColumnIndex * columnWidth,
    y: verticalOffsetDuringDragOperationStart + nextFirstRowIndex * rowHeight,
  }
}
export const CustomDragLayer = DragLayer((monitor) => ({
  currentOffset: monitor.getSourceClientOffset(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
  itemType: monitor.getItemType(),
}))<any>((props: any) => {
  const { item, isDragging, itemType, gridId } = props
  const {
    columnWidth,
    leftOffset,
    renderItem,
    rowHeight,
    snapToGridCellCenter,
  } = usePresentationState()
  const { getAllItemData, getColumnDataByIndex } = useGridContext()

  if (itemType !== 'ITEM') {
    return null
  }

  if (!item) {
    return null
  }

  if (!isDragging) {
    return null
  }

  // React-dnd invokes all custom drag layers on drag. Here we are telling React
  // to not render anything from this custom drag layer if the item being
  // dragged does not have the same gridId as this drag layer has.
  if (item.gridId !== gridId) {
    return null
  }

  const allItemData = getAllItemData(item.id)

  if (!allItemData) {
    return null
  }

  // Offset sets the reservation block to the correct location. It is different for resource and room reservations.
  const getLeftOffset = () =>
    allItemData.data.accommodationId ? leftOffset : 0

  const { x, y, columnIndexes } = getItemPosition(
    props,
    snapToGridCellCenter,
    rowHeight,
    columnWidth
  )

  if (x === null || y === null) {
    return null
  }

  // @ts-ignore
  const columns: ColumnDetails[] = columnIndexes
    .map((columnIndex) => getColumnDataByIndex(columnIndex))
    .filter(Boolean)

  return (
    // @ts-ignore
    <div style={layerStyles}>
      <div
        style={{
          height: `${item.rowIndexes.length * rowHeight}px`,
          left: 0,
          position: 'absolute',
          top: 0,
          transform: `translate(${x + getLeftOffset()}px, ${y}px)`,
          width: `${item.columnIndexes.length * columnWidth}px`,
        }}
      >
        {renderItem({
          columns,
          gridId,
          isDragging,
          isDragPreview: true,
          itemData: allItemData.data,
          leftOffset,
          // Row data is stored per <GridSection />, which share the same
          // <CustomDragLayer />. If we move an item from one section to another
          // the rowsData we want to pass on is not accessible.
          rows: [],
        })}
      </div>
    </div>
  )
})
