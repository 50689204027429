import { useMemo, useState } from 'react'
import styled from 'styled-components/macro'

import {
  MealQuantity,
  MealRow,
  useSetMealQuantityMutation,
} from '@/modules/Meals'
import { SalesType, ScheduledMealStatus as Status } from '~generated-types'
import { DataTableInput } from '@/components/DataTable'
import { useSalesDetailsContext } from '@/modules/Sales/components/SalesDetails'
import { useTheme } from '@/theme'

import { Row, Section, ShortSeparator } from '../../../common'
import { useMealsContext } from '../../../../MealsState'

type Props = {
  meal: MealRow
}

export const PersonRow = ({ meal: { ids, quantities, statuses } }: Props) => {
  const { spacing } = useTheme()
  const { data } = useSalesDetailsContext()
  const { isEditMode } = useMealsContext()

  const [setQuantity] = useSetMealQuantityMutation()

  const [quantitiesValue, setQuantitiesValue] =
    useState<MealQuantity[]>(quantities)

  const isRemoved = statuses.includes(Status.ManuallyRemoved)

  const mealQuantities = useMemo(
    () => [...quantitiesValue].sort(compareFn),
    [quantitiesValue]
  )

  const handleChangeQuantity = (key: string, quantity: number) =>
    setQuantitiesValue((current) =>
      current.map((q) => (q.ageCategory?.key === key ? { ...q, quantity } : q))
    )

  const handleUpdateQuantity = (ageCategoryKey: string, quantity: number) => {
    const currentQuantity = quantities.find(
      ({ ageCategory }) => ageCategory?.key === ageCategoryKey
    )?.quantity

    if (currentQuantity !== quantity) {
      setQuantity({
        variables: { id: ids[0], input: { ageCategoryKey, quantity } },
      }).catch(() => undefined)
    }
  }

  const renderViewRow = () => (
    <Row>
      {mealQuantities.map(({ ageCategory, quantity }, idx: number) => (
        <Row key={`view-section-${ageCategory?.key ?? 'missed'}-${idx}`}>
          <Section width={`${spacing.gu(6)}rem`}>{quantity}</Section>
          <ShortSeparator />
        </Row>
      ))}

      <Section width={`${spacing.gu(6)}rem`}>
        {getTotal(mealQuantities)}
      </Section>
    </Row>
  )

  const renderRemovedRow = () => (
    <Row style={{ textDecoration: 'line-through' }}>
      {mealQuantities.map(({ ageCategory, quantity }, idx: number) => (
        <Row key={`removed-section-${ageCategory?.key ?? 'missed'}-${idx}`}>
          <Section width={`${spacing.gu(6)}rem`}>{quantity}</Section>
          <ShortSeparator />
        </Row>
      ))}

      <Section width={`${spacing.gu(6)}rem`}>
        {getTotal(mealQuantities)}
      </Section>
    </Row>
  )

  const renderEditRow = () => (
    <Row>
      {mealQuantities.map(({ ageCategory, quantity }, idx: number) => (
        <Row key={`edit-section-${ageCategory?.key ?? 'missed'}-${idx}`}>
          <Section width={`${spacing.gu(6)}rem`}>
            {ageCategory && data.type !== SalesType.Event ? (
              <Input
                onBlur={() => handleUpdateQuantity(ageCategory.key, quantity)}
                onChange={(e) =>
                  handleChangeQuantity(ageCategory.key, Number(e.target.value))
                }
                onFocus={(e) => e.currentTarget.select()}
                showBorder
                type="number"
                value={`${quantity}`}
              />
            ) : (
              quantity
            )}
          </Section>

          <ShortSeparator />
        </Row>
      ))}

      <Section width={`${spacing.gu(6)}rem`}>
        {getTotal(mealQuantities)}
      </Section>
    </Row>
  )

  return (
    <>
      {isEditMode && isRemoved && renderRemovedRow()}
      {isEditMode && !isRemoved && renderEditRow()}
      {!isEditMode && !isRemoved && renderViewRow()}
    </>
  )
}

/////

const compareFn = (a: MealQuantity, b: MealQuantity) => {
  if (!a.ageCategory || !b.ageCategory) {
    return 1
  }

  return a.ageCategory.sortOrder - b.ageCategory.sortOrder
}

const getTotal = (quantities: MealQuantity[]) =>
  quantities.reduce((a: number, c) => a + c.quantity, 0)

const Input = styled(DataTableInput)`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`
