import { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { translate, useLanguageContext } from '@/modules/Language'
import { FlexRow } from '@/components/Layout'
import { Input } from '@/components/FormControls'
import { useTheme } from '@/theme'

type Props = {
  description: string | null
  readOnly: boolean
  updateDescription: (description: string) => Promise<void>
}

export const Description = ({
  description,
  readOnly,
  updateDescription,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [editedDescription, setEditedDescription] = useState<string>(
    description || ''
  )

  return (
    <>
      <Wrapper>
        <IconWrapper>
          <FontAwesomeIcon
            color={theme.palette.text.light}
            icon="align-left"
            size="sm"
          />
        </IconWrapper>
        <TextareaWrapper>
          <Textarea
            height={66} // 3 rows
            onBlur={() => updateDescription(editedDescription || '')}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setEditedDescription(e.target.value)
            }
            placeholder={translate(
              'ReservationModal:action.addDescription',
              language
            )}
            disabled={readOnly}
            value={editedDescription}
          />
        </TextareaWrapper>
      </Wrapper>
    </>
  )
}

/////////

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;

  ${({ theme }) => css`
    margin-top: ${theme.spacing.guPx(1) + 2}px;
    width: ${theme.spacing.gu(7)}rem;
  `}
`

const Textarea = styled(Input).attrs(() => ({
  as: 'textarea',
}))<any>`
  &&& {
    min-height: ${({ height }) => height}px;
    line-height: 1.2;
    resize: vertical;

    ${({ theme }) => css`
      padding: ${theme.spacing.gu(1)}rem;
      border-color: ${theme.palette.smoke.light};
    `}

    ::placeholder {
      font-style: italic;

      ${({ theme }) => css`
        color: ${theme.palette.text.lighter};
      `}
    }

    &:disabled {
      cursor: not-allowed;

      ${({ theme }) => css`
        background: ${theme.palette.smoke.extraLight};
      `}
    }
  }
`

const TextareaWrapper = styled.div`
  flex: 1;

  ${({ theme }) => css`
    margin-left: -${theme.spacing.gu(1)}rem;
  `}
`

const Wrapper = styled(FlexRow)`
  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(3)}rem 0
      ${theme.spacing.gu(1)}rem;
  `}
`
