import { DropTarget } from 'react-dnd'
import { v4 as uuidv4 } from 'uuid'

import { DraggableItem, Item } from '../Item'
import { DragEmpty } from '../DragEmpty'
import { useGridContext } from '../GridContext'
import { usePresentationState } from '../PresentationState'

const itemTarget = {
  drop: (props: any, monitor: any) => {
    if (!props.gridRef.current) {
      return
    }

    const draggedItem = monitor.getItem()
    const draggedItemGridOffset =
      draggedItem.gridRef.current.getBoundingClientRect()
    const targetGridOffset = props.gridRef.current.getBoundingClientRect()

    const initialDraggedItemPositionRelativeToViewport = {
      x:
        draggedItem.columnIndexes[0] * props.columnWidth +
        draggedItemGridOffset.x,
      y: draggedItem.rowIndexes[0] * props.rowHeight + draggedItemGridOffset.y,
    }

    const delta = monitor.getDifferenceFromInitialOffset()

    const draggedItemPositionRelativeToViewport = {
      x: initialDraggedItemPositionRelativeToViewport.x + delta.x,
      y: initialDraggedItemPositionRelativeToViewport.y + delta.y,
    }

    const draggedItemPositionRelativeToTargetGrid = {
      x: draggedItemPositionRelativeToViewport.x - targetGridOffset.x,
      y: draggedItemPositionRelativeToViewport.y - targetGridOffset.y,
    }

    const [leftOffset, topOffset] = props.snapToGrid(
      draggedItemPositionRelativeToTargetGrid.x,
      draggedItemPositionRelativeToTargetGrid.y
    )

    props.onDrop(draggedItem, topOffset, leftOffset)
  },
}

export const DropContainer = DropTarget('ITEM', itemTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  ({
    connectDropTarget,
    rows,
    columns,
    positionItems,
    gridRef,
    gridId,
  }: any) => {
    const {
      columnWidth,
      onEmptyDragEnd,
      renderCell,
      renderItemPreview,
      resourceCalendarConfig: config,
      rowHeight,
    } = usePresentationState()
    const { getColumnDataByIndex, getRowDataByIndex } = useGridContext()

    return connectDropTarget(
      <div
        ref={gridRef}
        style={{
          display: 'inline-flex',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
        }}
      >
        {rows.map((row: any, rowIndex: any) => (
          <div
            key={row.id}
            style={{
              display: 'inline-flex',
              height: `${rowHeight}px`,
              width: '100%',
            }}
          >
            {columns.map((column: any, columnIndex: any) => (
              <div
                key={column.id}
                style={{ flex: '0 0 auto', width: `${columnWidth}px` }}
              >
                <div style={{ height: '100%' }}>
                  {renderCell({
                    column,
                    coordinates: { x: columnIndex, y: rowIndex },
                    row,
                  })}
                </div>
              </div>
            ))}
          </div>
        ))}
        <DragEmpty
          onDragEnd={({ rowIndexes, columnIndexes }) => {
            const columns = columnIndexes
              .map((columnIndex: any) => getColumnDataByIndex(columnIndex))
              .filter(Boolean)
            const rows = rowIndexes
              .map((rowIndex: any) => getRowDataByIndex(gridId, rowIndex))
              .filter(Boolean)

            const positionData = { columns, gridId, rows }

            onEmptyDragEnd(positionData)
          }}
        >
          {renderItemPreview()}
        </DragEmpty>
        <div
          style={{
            left: 0,
            position: 'absolute',
            top: 0,
            zIndex: 2000,
          }}
        >
          {positionItems.map((item: any) => {
            const { columnIndexes, gridId, id, rowIndexes } = item
            const notInView = columnIndexes.reduce(
              (acc: any, columnIndex: any) =>
                acc || columnIndex === null || columnIndex === undefined,
              false
            )

            if (notInView) {
              return null
            }

            const isImmutable = item?.config?.immutable

            // Only for resource calendar
            //
            // Compare the reservation owner id and the context owner id to
            // disable drag-n-drop for "external" reservations
            const isMovingDisabled =
              (config?.isMovingDisabled &&
                item?.data?.sales?.id !== config?.ownerId) ||
              // Disable drag-n-drop for reservation "shadow"
              (config && item.gridId.split('.')[0] !== item?.data?.resource?.id)

            if (isImmutable || isMovingDisabled) {
              return (
                <div
                  key={id || `${gridId}.${uuidv4()}`}
                  style={{ height: 0, width: 0 }}
                >
                  <Item
                    columnIndexes={columnIndexes}
                    gridId={gridId}
                    id={id}
                    isDragging={false}
                    key={id || `${gridId}.${uuidv4()}`}
                    rowIndexes={rowIndexes}
                  />
                </div>
              )
            }

            return (
              <DraggableItem
                // @ts-ignore
                columnIndexes={columnIndexes}
                gridId={gridId}
                gridRef={gridRef}
                id={id}
                key={id || `${gridId}.${uuidv4()}`}
                rowIndexes={rowIndexes}
              />
            )
          })}
        </div>
      </div>
    )
  }
)
