import { FlexRow } from '@/components/Layout'

import { FontColor } from '@/components/Typography'
import { PaymentGiftCardDetails as GiftCardDetailsType } from '@/modules/Order/types'
import { T } from '@/modules/Language'

import { Cell, Section, SectionWithTooltip } from '../../components'

type Props = {
  details: GiftCardDetailsType
}

export const GiftCardDetails = ({
  details: { code, notes, pointOfSale },
}: Props) => (
  <>
    <FlexRow flex={4}>
      <FlexRow flex={1}>
        <SectionWithTooltip
          content={pointOfSale?.name || <FontColor lighter>–</FontColor>}
          flex={1}
          icon="location-dot"
          title={<T>Orders:Payments.field.pointOfSale</T>}
        />
      </FlexRow>

      <FlexRow flex={1}>
        <SectionWithTooltip
          content={code || <FontColor lighter>–</FontColor>}
          flex={1}
          title={<T>Orders:Payments.field.giftCardCode</T>}
          sign="#"
        />
      </FlexRow>
    </FlexRow>

    <FlexRow flex={3}>
      <Cell flex={1}>
        <Section icon={['far', 'note-sticky']}>
          {notes || <FontColor lighter>–</FontColor>}
        </Section>
      </Cell>
    </FlexRow>
  </>
)
