import styled, { css } from 'styled-components/macro'
import { CSSProperties } from 'react'

import { InlineLabel, Input } from '@/components/FormControls'

type SProps = {
  disabled?: boolean
}
type TProps = {
  disabled?: boolean
  style?: CSSProperties
}

const StyledToggleInput = styled(Input)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
`

const Slider = styled.span<SProps>`
  display: inline-flex;
  align-items: center;
  padding: 3px;

  ${({ theme }) => css`
    width: ${theme.spacing.gu(6)}rem;
    height: ${theme.spacing.gu(2.5)}rem;
  `}

  background: ${(props) =>
    props.disabled
      ? props.theme.palette.smoke.main
      : props.theme.palette.white};
  border: 2px solid transparent;
  border-color: ${(props) =>
    props.disabled
      ? props.theme.palette.smoke.main
      : props.theme.palette.smoke.dark};
  border-radius: 30px;

  cursor: ${(props: SProps) => (props.disabled ? 'not-allowed' : 'pointer')};

  &::after {
    content: '';
    display: block;

    ${({ theme }) => css`
      width: ${theme.spacing.gu(1.3)}rem;
      height: ${theme.spacing.gu(1.3)}rem;
    `}

    background: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.dark
        : props.theme.palette.primary.main};
    border-radius: 30px;
    transition: transform 0.1s ease-out, background 0.05s ease-out,
      border-color 0.05s ease-out;
  }

  input[type='checkbox']:checked ~ & {
    background: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.main
        : props.theme.palette.primary.main};
    border-color: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.main
        : props.theme.palette.primary.main};
  }

  input[type='checkbox']:active ~ & {
    ${({ theme }) => css`
      border-color: ${theme.palette.primary.main};
    `}
  }

  input[type='checkbox']:checked ~ &::after {
    background: ${(props) =>
      props.disabled
        ? props.theme.palette.smoke.dark
        : props.theme.palette.white};

    ${({ theme }) => css`
      transform: translateX(${theme.spacing.guPx(3) + 3}px);
    `}
  }
`

const ToggleWrapper = styled(InlineLabel)`
  height: 1.75rem;
  justify-content: center;
  margin-bottom: 0;
`

export const DataTableToggle = ({ disabled, style, ...props }: TProps) => (
  <ToggleWrapper disabled={disabled} style={style}>
    <StyledToggleInput {...props} disabled={disabled} type="checkbox" />
    <Slider disabled={disabled} />
  </ToggleWrapper>
)
