import { gql } from '@apollo/client'

import orderFragments from '../fragments'

export default gql`
  ${orderFragments.payment}

  subscription Payment($input: PaymentInputFilter!) {
    payment(input: $input) {
      event {
        ... on PaymentEvent {
          data {
            ...Payment
          }
          id
          type
        }
      }
      invoiceId
      orderId
      paymentId
    }
  }
`
