import {
  InfoCell,
  ReservationCell,
  RoomCell,
  SalesCell,
  SpacerCell,
} from './Cells'
import { Reservation, Room } from '../types'

interface Props {
  arrivingReservation: Reservation | null
  departingReservation: Reservation | null
  room: Room
}

export const RoomReservationsTableRow = ({
  arrivingReservation,
  departingReservation,
  room,
}: Props) => (
  <tr>
    <RoomCell room={room} />
    <SpacerCell />
    <ReservationCell reservation={departingReservation} type="DEPARTING" />
    <SalesCell data={departingReservation?.participantRooms[0] ?? null} />
    <InfoCell data={departingReservation?.request ?? null} />
    <SpacerCell showArrow={!!arrivingReservation && !!departingReservation} />
    <ReservationCell reservation={arrivingReservation} type="ARRIVING" />
    <SalesCell data={arrivingReservation?.participantRooms[0] ?? null} />
    <InfoCell data={arrivingReservation?.request ?? null} />
  </tr>
)
