import { gql } from '@apollo/client'

export default gql`
  query SaleLastUsed($id: ID!) {
    sales(id: $id) {
      id
      type
      name
      orderNumber
      estimatedDates {
        start
        end
      }
      reservationDates {
        start
        end
      }
      facet {
        id
        name
        abbreviation
        color
        dimensionTarget
        features {
          feature
          defaultVisibility
        }
      }
      lifecycle {
        state
      }
      customer {
        customer {
          customerNumber
          ... on CustomerPerson {
            person {
              firstName
              lastName
            }
          }
          ... on CustomerOrganization {
            organization {
              name
            }
          }
        }
      }
    }
  }
`
