import styled, { css } from 'styled-components/macro'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

import { IconButton as IconButtonBase } from '@/components/ExtraButtons'

type Props = {
  icon: IconProp
  onClick: () => void
}

export const IconButton = ({ icon, onClick }: Props) => (
  <Button color="transparent" icon={icon} noNudge onClick={onClick} />
)

////////////

const Button = styled(IconButtonBase)`
  justify-content: center;
  height: 32px;
  width: 32px;
  border: none;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
    font-size: ${theme.typography.fontSizeBig};
  `}

  &&:hover, &&:focus, &&:active {
    background: transparent;
  }
`
