import moment from 'moment'

import { translate } from '@/modules/Language'

import {
  ListingGroupReservation,
  ListingReservation,
  ListingReservationResource,
  ListingReservationTask,
} from './useReservationList'
import { ListingTask } from './useTaskList'
import { SortOption } from '../CalendarResourceListingContainer'

export const formatDueTime = (input: string) => {
  const parts = input.split(':')

  return parts.length === 3 ? `${parts[0]}:${parts[1]}` : input
}

export const formatDueDate = (input: string) => moment(input).format('L')

export const getFullResourceName = (
  resource: ListingReservationResource,
  language: string
) => {
  if (!resource) {
    return translate('ResourceReservations:resource.program', language)
  }

  if (resource.__typename !== 'ResourcePropertyNested') {
    return resource.name ?? ''
  }

  return `${resource.parentResource.name} – ${resource.name}`
}

export const getResourceName = (
  resource: ListingReservationResource | null,
  sortBy: SortOption,
  language: string
) => {
  if (!resource) {
    return translate('ResourceReservations:resource.program', language)
  }

  if (
    sortBy === 'main_resource' &&
    resource.__typename === 'ResourcePropertyNested'
  ) {
    return resource.parentResource.name ?? ''
  }

  return resource.name ?? ''
}

export const resourceCompareFn =
  (sortBy: SortOption, language: string) =>
  (
    a: ListingReservation | ListingGroupReservation,
    b: ListingReservation | ListingGroupReservation
  ) => {
    const resourceNameA = getResourceName(a.resource, sortBy, language)
    const resourceNameB = getResourceName(b.resource, sortBy, language)
    const resourceDateA = moment(a.start)
    const resourceDateB = moment(b.start)

    const compareName = resourceNameA.localeCompare(resourceNameB, language)
    const compareDate = resourceDateA.diff(resourceDateB)

    switch (sortBy) {
      case 'time':
        if (compareDate !== 0) {
          return compareDate
        }
        return compareName

      case 'resource':
        if (compareName !== 0) {
          return compareName
        }
        return compareDate

      case 'main_resource':
        if (compareName !== 0) {
          return compareName
        }
        return compareDate

      default:
        return 0
    }
  }

export const taskCompareFn = (
  a: ListingReservationTask | ListingTask,
  b: ListingReservationTask | ListingTask
) => {
  const toDate = (date: string, time: string | null) =>
    new Date(time ? `${date}T${time}` : date)

  if (a.dueDate && b.dueDate) {
    return (
      moment(toDate(a.dueDate, a.dueTime)).valueOf() -
      moment(toDate(b.dueDate, b.dueTime)).valueOf()
    )
  }

  return -1
}

type CustomerProps = ListingTask['sales'] | ListingReservation['sales']

export const parseCustomerName = (sales: CustomerProps) => {
  const customer = sales.customer?.customer
  const customerName = !customer
    ? '–'
    : customer.__typename === 'CustomerPerson'
    ? `${customer.person.firstName} ${customer.person.lastName}`
    : customer?.organization.name || '–'

  return customerName
}
