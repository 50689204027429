import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { RoomCleaningStatus as Status } from '~generated-types'
import { useTheme } from '@/theme'

import { Room } from '../../types'

interface Props {
  hideCleaningStatus?: boolean
  room: Room
}

export const RoomCell = ({
  hideCleaningStatus,
  room: {
    beds,
    extraBeds,
    number,
    status: { cleaningStatus },
  },
}: Props) => {
  const theme = useTheme()
  return (
    <td>
      <FlexRow alignItems="center" justifyContent="space-between">
        <FlexColumn alignItems="flex-end" noPadding>
          <strong style={{ whiteSpace: 'nowrap' }}>#{number}</strong>
          <small>
            {beds} + {extraBeds}
          </small>
        </FlexColumn>

        {!hideCleaningStatus && (
          <FlexRow>
            <FontAwesomeIcon
              color={
                cleaningStatus === Status.Clean
                  ? theme.palette.success.main
                  : theme.palette.danger.main
              }
              icon={
                cleaningStatus === Status.Clean
                  ? 'check'
                  : 'triangle-exclamation'
              }
              size={cleaningStatus === Status.Clean ? 'lg' : '1x'}
              style={{ marginLeft: theme.spacing.gutter }}
            />
          </FlexRow>
        )}
      </FlexRow>
    </td>
  )
}
