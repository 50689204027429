import styled, { css } from 'styled-components/macro'
import { Moment } from 'moment'

interface DateHeaderCellProps {
  date: Moment
}

export const DateHeaderCell = ({ date }: DateHeaderCellProps) => (
  <th colSpan={2}>
    <Wrapper>
      <DateLabel>{date.format('DD.MM')}</DateLabel>
      <WeekDayLabel>{date.format('dd')}</WeekDayLabel>
    </Wrapper>
  </th>
)

export default DateHeaderCell

////////////

const DateLabel = styled.span`
  font-weight: 500;

  @media print {
    ${({ theme }) => css`
      font-size: ${theme.typography.fontSizeSmall};
    `}
  }
`

const WeekDayLabel = styled.span`
  ${({ theme }) => css`
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeSmaller};
  `}

  @media print {
    ${({ theme }) => css`
      color: ${theme.palette.text.light};
    `}
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}

  @media print {
    ${({ theme }) => css`
      color: ${theme.palette.text.main};
    `}
  }
`
