import moment, { Moment } from 'moment'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'

import { InlineModal, InlineModalFooter } from '@/components/InlineModal'
import { Option, ThemedSelect } from '@/components/ThemedSelect'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { DatePicker } from '@/components/TimeControls'
import { FlexRow } from '@/components/Layout'
import { FontColor } from '@/components/Typography'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { useTheme } from '@/theme'

type Props = {
  date: string | null
  duration: number | null
  durationOptions: number[]
  initialDate: Moment
  onClose: () => void
  setDate:
    | ((
        dueDate: string | null,
        dueDateDuration: number | null
      ) => Promise<void | null | undefined>)
    | ((
        validTo: string | null,
        validToDuration: number | null
      ) => Promise<void | null | undefined>)
}

export const DateDurationModal = ({
  date,
  duration,
  durationOptions,
  initialDate,
  onClose,
  setDate,
}: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const [dateValue, setDateValue] = useState<string | null>(date)
  const [durationValue, setDurationValue] = useState<number | null>(duration)
  const [isProcessing, setProcessing] = useState<boolean>(false)
  const [isDurationMode, setDurationMode] = useState<boolean>(
    !!duration || (!date && !duration)
  )

  const options = durationOptions.map((option: number) => ({
    label: (
      <DateLabel>
        {translate('Orders:DateDurationManager.day', language, {
          count: option,
        })}
        {'  '}–{'  '}
        {option === durationValue ? (
          <FontColor white>
            {initialDate.clone().add(option, 'd').format('dd, D.M.YYYY')}
          </FontColor>
        ) : (
          <FontColor lighter>
            {initialDate.clone().add(option, 'd').format('dd, D.M.YYYY')}
          </FontColor>
        )}
      </DateLabel>
    ),
    value: option.toString(),
  }))

  const handleSave = () => {
    setProcessing(true)
    setDate(dateValue, durationValue).then(onClose)
  }

  const getDurationOption = () =>
    durationValue
      ? {
          label: (
            <DateLabel>
              {translate('Orders:DateDurationManager.day', language, {
                count: durationValue,
              })}
              {'  '}–{'  '}
              <FontColor lighter>
                {initialDate
                  .clone()
                  .add(durationValue, 'd')
                  .format('dd, D.M.YYYY')}
              </FontColor>
            </DateLabel>
          ),
          value: durationValue.toString(),
        }
      : null

  return (
    <Modal>
      <FlexRow>
        <ToggleButton
          onClick={() => setDurationMode(true)}
          isDuration
          isSelected={isDurationMode}
        >
          <FontAwesomeIcon
            color={
              isDurationMode
                ? theme.palette.primary.main
                : theme.palette.text.lighter
            }
            icon={['far', 'hourglass']}
            size="sm"
            style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
          />
          <T>Orders:DateDurationManager.duration</T>
        </ToggleButton>

        <ToggleButtonDivider />

        <ToggleButton
          onClick={() => setDurationMode(false)}
          isDuration={false}
          isSelected={!isDurationMode}
        >
          <FontAwesomeIcon
            color={
              isDurationMode
                ? theme.palette.text.lighter
                : theme.palette.primary.main
            }
            icon={['far', 'calendar']}
            size="sm"
            style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
          />
          <T>Orders:DateDurationManager.date</T>
        </ToggleButton>
      </FlexRow>

      {isDurationMode ? (
        <DurationSelectorWrapper>
          <ThemedSelect
            isCompact
            name="duration-selector"
            onChange={(selected: Option | null | undefined) => {
              setDurationValue(selected ? Number(selected.value) : null)
              setDateValue(null)
            }}
            options={options}
            placeholder={translate(
              'Orders:DateDurationManager.setDuration',
              language
            )}
            value={getDurationOption()}
          />
        </DurationSelectorWrapper>
      ) : (
        <DatePicker
          setValue={(date) => {
            setDateValue(date ? date.format('YYYY-MM-DD') : null)
            setDurationValue(null)
          }}
          value={dateValue ? moment(dateValue) : null}
        />
      )}

      <Spacer />

      <InlineModalFooter justifyContent="space-between">
        <InnocuousButton compact onClick={onClose}>
          <T>common:action.cancel</T>
        </InnocuousButton>
        <InnocuousButton disabled={isProcessing} compact onClick={handleSave}>
          <PrimaryColor>
            <T>common:action.save</T>
          </PrimaryColor>
        </InnocuousButton>
      </InlineModalFooter>
    </Modal>
  )
}

////////////

const DateLabel = styled.span`
  white-space: pre;
`

const DurationSelectorWrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.spacing.gutter};
  `}
`

const Modal = styled(InlineModal)`
  overflow: unset;

  ${({ theme }) => css`
    height: ${theme.spacing.gu(51)}rem;
    width: ${theme.spacing.gu(38)}rem;
    margin-right: ${theme.spacing.gu(1)}rem;
  `}
`

const Spacer = styled.span`
  flex: 1;
`

const ToggleButton = styled.button<{
  isDuration: boolean
  isSelected: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  background: transparent;
  border: none;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;

  ${({ isDuration }) =>
    isDuration
      ? 'border-top-left-radius: 6px'
      : 'border-top-right-radius: 6px'};

  color: ${({ isSelected, theme }) =>
    isSelected ? theme.palette.primary.main : theme.palette.text.lighter};
  border-bottom: 1px solid
    ${({ isSelected, theme }) =>
      isSelected ? 'transparent' : theme.palette.smoke.main};

  ${({ theme }) => `height: ${theme.spacing.gu(6)}rem`};

  ${({ isSelected, theme }) =>
    !isSelected &&
    `
    &:hover {
      background: ${theme.palette.smoke.light};
    }

    &:active {
      background: ${theme.palette.smoke.main};
    }
  `}
`

const ToggleButtonDivider = styled.div`
  width: 1px;

  ${({ theme }) =>
    css`
      background: ${theme.palette.smoke.main};
      height: ${theme.spacing.gu(6)}rem;
    `}
`
