import styled, { css } from 'styled-components/macro'
import { useEffect, useState } from 'react'

import { Input, Label } from '@/components/FormControls'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { AgeField } from '~generated-types'
import { AgeReference } from '@/modules/ParticipantsList'
import { useTheme } from '@/theme'

import { ToggleEditableValue } from './CalendarDatePicker/ToggleEditableValue'

type Props = {
  age: number | null
  ageSource: AgeField | null
  handleSubmit: (value: AgeReference) => Promise<any>
}

export const AgeInput = ({ age, ageSource, handleSubmit }: Props) => {
  const { language } = useLanguageContext()
  const { spacing } = useTheme()

  const [ageValue, setAgeValue] = useState<number | null>(age)

  useEffect(() => {
    setAgeValue(age)
  }, [age])

  const onAgeUpdate = (onClose: () => void) => {
    if (ageValue !== age) {
      handleSubmit({
        age: ageValue || null,
        ageCategoryKey: null,
        birthday: null,
      }).finally(() => onClose())
    }
  }

  const disabled =
    !!ageSource &&
    ageSource !== AgeField.Age &&
    ageSource !== AgeField.AgeCategory

  return (
    <>
      <Label>
        <T>ParticipantsList:ParticipantFormFields.age</T>
      </Label>

      <ToggleEditableValue
        disabled={disabled}
        mode="input"
        placeholder="–"
        renderEditor={(onClose) => (
          <StyledNumberFieldInput
            autoFocus
            onBlur={() => onAgeUpdate(onClose)}
            onChange={(e) =>
              setAgeValue(e.target.value ? parseInt(e.target.value, 10) : null)
            }
            onFocus={(e) => e.currentTarget.select()}
            placeholder=""
            type="number"
            value={ageValue || ''}
          />
        )}
        style={{
          height: '30px',
          margin: `${spacing.gu(1)}rem 0 ${spacing.gu(2)}rem 0`,
        }}
        value={
          age
            ? `${age} ${translate(
                'ParticipantsList:ParticipantFormFields.years',
                language
              )}`
            : ''
        }
      />
    </>
  )
}

///////

const StyledNumberFieldInput = styled(Input)`
  &:not([type='checkbox']):not([type='radio']) {
    height: 30px;
    text-align: center;
    border-radius: 4px;
    flex-grow: inherit;

    ${({ theme }) => css`
      margin: ${theme.spacing.gu(1)}rem 0 ${theme.spacing.gu(2)}rem 0;
      padding: 0 ${theme.spacing.gutterSmall};
    `}

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`
