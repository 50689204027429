import { alignItems, justifyContent, wrap } from './sharedTypes'
import styled, { css } from 'styled-components/macro'

type Props = {
  alignItems?: alignItems
  flex?: number | string
  hideFromPrint?: boolean
  justifyContent?: justifyContent
  wrap?: wrap
}

export const FlexRow = styled.div<Props>`
  display: flex;
  flex-wrap: ${(props: Props) => (props.wrap ? props.wrap : 'nowrap')};
  justify-content: ${(props: Props) => props.justifyContent || 'inherit'};
  align-items: ${(props: Props) => props.alignItems || 'inherit'};

  ${({ flex }) => (!!flex || flex === 0) && `flex: ${flex};`}

  ${({ hideFromPrint }) =>
    hideFromPrint
      ? css`
          @media print {
            display: none;
          }
        `
      : ''}
`

export default FlexRow
