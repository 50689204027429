import React from 'react'
import { ReactiveComponent } from '@appbaseio/reactivesearch'

import { DateRange } from './DateRange'

export const DateRangeContainer = (filterProps: { [key: string]: any }) => (
  // @ts-ignore
  <ReactiveComponent
    render={({ setQuery, value }) => (
      // @ts-ignore
      <DateRange setQuery={setQuery} value={value} {...filterProps} />
    )}
    {...filterProps} // This component does not need to query any data as it is
    // not dependent on the index data at the moment
    defaultQuery={() => undefined}
  />
)
