import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { WebshopSettingsToken } from '../../../types'

type Props = {
  token: WebshopSettingsToken
}

export const LinkIssueMessage = ({ token: { issues, completedAt } }: Props) => {
  const { palette } = useTheme()

  if (completedAt) {
    return (
      <CompletedLabel>
        <T>Publish:Permissions.completedAt</T>
        {`: ${moment(completedAt).format('dd D.M.YYYY HH:mm')}`}
      </CompletedLabel>
    )
  }

  const groupedIssueMessages = groupIssueMessages(issues)

  return (
    <Wrapper>
      <FontAwesomeIcon
        icon={issues.length ? 'triangle-exclamation' : 'check-circle'}
        color={
          groupedIssueMessages.errorIssues.length
            ? palette.danger.main
            : groupedIssueMessages.warningIssues.length
            ? palette.warning.dark
            : palette.success.dark
        }
      />

      <IssueMessageWrapper>
        {groupedIssueMessages.errorIssues.length ? (
          groupedIssueMessages.errorIssues.map((issue, idx) => (
            <IssueMessage
              key={`${issue.code}-${idx}`}
              color={palette.danger.main}
            >
              <T>{`IssueCodes:${issue.code}`} </T>
            </IssueMessage>
          ))
        ) : groupedIssueMessages.warningIssues.length ? (
          groupedIssueMessages.warningIssues.map((issue, idx) => (
            <IssueMessage key={idx} color={palette.warning.dark}>
              <T>{`IssueCodes:${issue.code}`} </T>
            </IssueMessage>
          ))
        ) : (
          <IssueMessage color={palette.success.dark}>
            <T>Publish:Permissions.link.ready</T>
          </IssueMessage>
        )}
      </IssueMessageWrapper>
    </Wrapper>
  )
}

/////////////

type GroupedIssueMessages = {
  errorIssues: WebshopSettingsToken['issues']
  warningIssues: WebshopSettingsToken['issues']
}

const groupIssueMessages = (issues: WebshopSettingsToken['issues']) =>
  issues.reduce(
    (acc: GroupedIssueMessages, issue) => {
      const category = issue.level === 'ERROR' ? 'errorIssues' : 'warningIssues'
      acc[category].push(issue)
      return acc
    },
    {
      errorIssues: [],
      warningIssues: [],
    }
  )

////////////

const CompletedLabel = styled.p`
  font-weight: 600;

  ${({ theme }) => css`
    font-family: ${theme.typography.fontFamily};
    color: ${theme.palette.success.dark};
  `}
`

const IssueMessage = styled.p`
  margin: 0;
  padding: 0;

  ${({ color }) => css`
    color: ${color};
  `}
`

const IssueMessageWrapper = styled.div`
  margin-left: 10px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    color: ${theme.palette.warning.main};
  `};
`
