import { Redirect, Switch, useRouteMatch } from 'react-router-dom'

import { globalRoutes, useWillbaConfigContext } from '@/config'
import { NavItemType, NavInternalViewType as Type } from '~generated-types'
import { NotFound } from '@/components/NotFound'
import { PageRoute } from '@/components/Page'

import { DailyCustomerList, DailyRoomReservations } from './components'

export const FrontDeskRoutes = () => {
  const { path } = useRouteMatch()
  const { allNavDropdownInternalTypes } = useWillbaConfigContext()

  return (
    <Switch>
      <Redirect exact from={path} to={globalRoutes[Type.FrontDeskSales]} />

      {allNavDropdownInternalTypes.includes(Type.FrontDeskSales) && (
        <PageRoute
          component={DailyCustomerList}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.FrontDesk}`,
            `Navigation:navDropdownItem.${Type.FrontDeskSales}`,
          ]}
          path={globalRoutes[Type.FrontDeskSales]}
        />
      )}

      {allNavDropdownInternalTypes.includes(Type.FrontDeskRoom) && (
        <PageRoute
          component={DailyRoomReservations}
          exact
          pageTitleKey={[
            `Navigation:navItem.${NavItemType.FrontDesk}`,
            `Navigation:navDropdownItem.${Type.FrontDeskRoom}`,
          ]}
          path={globalRoutes[Type.FrontDeskRoom]}
        />
      )}

      <PageRoute component={NotFound} pageTitle="404" />
    </Switch>
  )
}
