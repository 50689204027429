import styled, { css } from 'styled-components/macro'
import { Moment } from 'moment'
import ReactLoading from 'react-loading'

import { FormikAutoSave } from '@/components/FormControls'
import { FormikRenderProps } from '@/utils/forms'
import { useTheme } from '@/theme'

type Props = {
  formikRenderProps: FormikRenderProps
  lastSaved?: Moment | null | undefined
}

export default function AutoSaveIndicator({
  formikRenderProps,
  lastSaved,
}: Props) {
  const { palette } = useTheme()

  return (
    <AutoSaveLabel>
      {/* @ts-ignore */}
      <FormikAutoSave
        debounceMs={1000}
        formikRenderProps={formikRenderProps}
        lastSaved={lastSaved}
        savingPlaceholder={
          <ReactLoading
            color={palette.smoke.dark}
            height={14}
            type="spin"
            width={14}
          />
        }
      />
    </AutoSaveLabel>
  )
}

////////////

const AutoSaveLabel = styled.span`
  ${({ theme }) => css`
    margin-left: ${theme.spacing.gutter};
    color: ${theme.palette.text.lighter};
    font-size: ${theme.typography.fontSizeBase};
  `}
`
