import ReactLoading from 'react-loading'

import { T, translate, useLanguageContext } from '@/modules/Language'
import moment from 'moment'
import { useTheme } from '@/theme'

import { AccommodationTarget, PublicRoom } from '../../types'
import {
  AddPublicRoomButton,
  BlockingCapacitySelector,
  GuestAmountInput,
  ProductSelector,
  PublishDates,
  PublishedRoomsHeader,
  RemovePublicRoomButton,
  WebshopRoomSelector,
} from './components'
import {
  ListContainerColumn,
  ListPlaceholder,
  ListRow,
  RowWrapper,
  Section,
  VerticalDivider,
} from '../common'
import { ContentSection } from '../../../../components'
import { usePublishContext } from '../../PublishState'

export const PublishedRooms = () => {
  const { language } = useLanguageContext()
  const { palette } = useTheme()
  const {
    accommodationTargets,
    accommodationTargetsError,
    accommodationTargetsLoading,
  } = usePublishContext()

  const renderPublicRoomSections = (
    publicRoom: PublicRoom | null,
    targetId: string
  ) => (
    <>
      <VerticalDivider />

      <ProductSelector publicRoom={publicRoom} targetId={targetId} />

      <VerticalDivider />

      <WebshopRoomSelector publicRoom={publicRoom} targetId={targetId} />

      <VerticalDivider />

      <GuestAmountInput
        field="minGuests"
        publicRoom={publicRoom}
        targetId={targetId}
      />

      <VerticalDivider />

      <GuestAmountInput
        field="maxGuests"
        publicRoom={publicRoom}
        targetId={targetId}
      />

      <VerticalDivider />

      <PublishDates publicRoom={publicRoom} targetId={targetId} />
    </>
  )

  return (
    <RowWrapper>
      <ContentSection title={<T>Publish:Rooms.title</T>}>
        {accommodationTargetsLoading ? (
          <ReactLoading
            color={palette.smoke.main}
            height={24}
            type="spin"
            width={24}
          />
        ) : accommodationTargetsError ? (
          <ListPlaceholder>
            – <T>Publish:Rooms.error</T> –
          </ListPlaceholder>
        ) : !accommodationTargets.length ? (
          <ListPlaceholder>
            – <T>Publish:Rooms.empty</T> –
          </ListPlaceholder>
        ) : (
          <ListContainerColumn>
            <PublishedRoomsHeader />

            {accommodationTargets.map((target) => {
              const { id, nonBlockingCapacity, publicRooms } = target

              const sortedPublicRooms = [...publicRooms].sort(
                (a, b) =>
                  moment(a.auditLog.createdAt).valueOf() -
                  moment(b.auditLog.createdAt).valueOf()
              )

              const [firstPublicRoom, ...nextPublicRooms] = sortedPublicRooms

              return (
                <>
                  <ListRow key={`published-product-${id}`}>
                    <Section noPadding flex={1} noLeftMargin>
                      {getTargetName(target, language)}
                    </Section>

                    <VerticalDivider />

                    <BlockingCapacitySelector
                      blockingCapacity={!nonBlockingCapacity}
                      targetId={id}
                    />

                    {renderPublicRoomSections(firstPublicRoom, id)}

                    <VerticalDivider />

                    <AddPublicRoomButton targetId={id} />
                  </ListRow>

                  {nextPublicRooms.map((room) => (
                    <ListRow noBorder>
                      <Section noPadding flex={1} noLeftMargin />

                      <VerticalDivider isTransparent />

                      <Section noPadding flex={1} />

                      {renderPublicRoomSections(room, id)}

                      <VerticalDivider />

                      <RemovePublicRoomButton id={room.id} />
                    </ListRow>
                  ))}
                </>
              )
            })}
          </ListContainerColumn>
        )}
      </ContentSection>
    </RowWrapper>
  )
}

////////////

const getTargetName = (target: AccommodationTarget, language: string) =>
  target.default
    ? translate('Accommodation:TargetGroup.default', language)
    : target.name ||
      `${translate(`Accommodation:TargetGroup.target`, language)} #${
        target.sortOrder
      }`
