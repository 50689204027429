import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

import { ListingTable, ListingTableSubheader } from '@/modules/Listing/common'
import { T, translate, useLanguageContext } from '@/modules/Language'
import { generateCompareFn } from '@/utils/arrays'
import { useTheme } from '@/theme'

import { ReservationType, RoomReservation } from '../types'
import ArrivingRoomReservationsTableRow from './ArrivingRoomReservationsTableRow'
import DepartingRoomReservationsTableRow from './DepartingRoomReservationsTableRow'
import { PrintSize } from '../../types'

interface Props {
  data: RoomReservation[]
  printSize: PrintSize
  type: ReservationType
}

export const RoomReservationsTable = ({ data, printSize, type }: Props) => {
  const { language } = useLanguageContext()
  const theme = useTheme()

  const header =
    type === ReservationType.ARRIVING ? (
      <tr>
        <th>
          <T>Listings:RoomReservationsListing.field.room</T>
        </th>
        <th style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon="broom" />
        </th>
        <th style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon="bed" />
        </th>
        <th>
          <T>Listings:RoomReservationsListing.field.features</T>
        </th>
        <th>
          <T>Listings:RoomReservationsListing.field.info</T>
        </th>
        <th style={{ width: 120 }}>
          <T>Listings:RoomReservationsListing.field.duration</T>
        </th>
        <th style={{ width: 160 }}>
          <T>Listings:RoomReservationsListing.field.guests</T>
        </th>
        <th>
          <T>Listings:RoomReservationsListing.field.customer</T>
        </th>
        <th>
          <T>Listings:RoomReservationsListing.field.sales</T>
        </th>
      </tr>
    ) : (
      <tr>
        <th>
          <T>Listings:RoomReservationsListing.field.room</T>
        </th>
        <th style={{ textAlign: 'center' }}>
          <FontAwesomeIcon icon="broom" />
        </th>
        <th style={{ width: 110 }}>
          <T>Listings:RoomReservationsListing.field.duration</T>
        </th>
        <th style={{ width: 160 }}>
          <T>Listings:RoomReservationsListing.field.guests</T>
        </th>
        <th>
          <T>Listings:RoomReservationsListing.field.customer</T>
        </th>
        <th>
          <T>Listings:RoomReservationsListing.field.sales</T>
        </th>

        <th className="horizontal-spacer" style={{ minWidth: 30 }} />

        <th style={{ width: 250 }}>
          <T>Listings:RoomReservationsListing.field.nextReservation</T>
        </th>
      </tr>
    )

  const RowComponent =
    type === ReservationType.ARRIVING
      ? ArrivingRoomReservationsTableRow
      : DepartingRoomReservationsTableRow

  const colSpan = type === ReservationType.ARRIVING ? 9 : 8

  const dataByBuilding = data.reduce(
    (
      acc: Record<string, { building: any; reservations: RoomReservation[] }>,
      val: RoomReservation
    ) => {
      const building = val.request.room.building

      acc[building.id] = acc[building.id] || {
        building,
        reservations: [],
      }
      acc[building.id].reservations.push(val)

      return acc
    },
    {}
  )
  const dataByBuildingArr = Object.values(dataByBuilding).sort(
    generateCompareFn('building.name')
  )

  return (
    <ListingTable printSize={printSize}>
      <thead>{header}</thead>
      <tbody>
        {dataByBuildingArr.map(({ building, reservations }) => (
          <React.Fragment key={`reservations-building-${building.id}`}>
            <ListingTableSubheader colSpan={colSpan} printSize={printSize}>
              {building.name}{' '}
              <small style={{ marginLeft: theme.spacing.gutterBig }}>
                {translate('Listings:RoomReservationsListing.rooms', language, {
                  count: reservations.length,
                })}
              </small>
            </ListingTableSubheader>
            {reservations
              .sort(generateCompareFn(['request.room.number', 'id']))
              .map((x) => (
                <RowComponent
                  data={x}
                  key={`${type}-room-reservation-row-${x.id}`}
                />
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </ListingTable>
  )
}

export default RoomReservationsTable
