import styled, { css } from 'styled-components/macro'

import { FlexColumn, FlexRow } from '@/components/Layout'
import { SalesCopyComponent as C } from '~generated-types'
import { Label } from '@/components/FormControls'
import { T } from '@/modules/Language'

import { Checkbox } from '../components'

const RESERVATION_COMPONENTS = [
  C.Reservations,
  C.ReservationsGroups,
  C.ReservationTasks,
]

type Props = {
  components: C[]
  setComponents: (components: C[]) => void
}

export const SpacesAndResourcesSection = ({
  components,
  setComponents,
}: Props) => {
  const toggleComponent = (target: C) =>
    setComponents(
      components.includes(target)
        ? target === C.Reservations
          ? components.filter((c) => !RESERVATION_COMPONENTS.includes(c))
          : components.filter((c) => c !== target)
        : [...components, target]
    )

  return (
    <Wrapper>
      <Title>
        <T>SalesDetails:CopySale.label.SPACES_AND_RESOURCES</T>
      </Title>

      <FlexRow wrap="wrap">
        <Checkbox
          checked={components.includes(C.Reservations)}
          label={C.Reservations}
          onChange={() => toggleComponent(C.Reservations)}
        />

        <Checkbox
          checked={components.includes(C.ReservationTasks)}
          disabled={!components.includes(C.Reservations)}
          label={C.ReservationTasks}
          onChange={() => toggleComponent(C.ReservationTasks)}
        />

        <Checkbox
          checked={components.includes(C.ReservationsGroups)}
          disabled={!components.includes(C.Reservations)}
          label={C.ReservationsGroups}
          onChange={() => toggleComponent(C.ReservationsGroups)}
        />
      </FlexRow>
    </Wrapper>
  )
}

/////

const Title = styled(Label)`
  ${({ theme }) => css`
    margin: ${theme.spacing.gu(1)}rem 0;
  `}
`

const Wrapper = styled(FlexColumn)`
  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(3)}rem;
  `}
`
