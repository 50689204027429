import styled, { css } from 'styled-components/macro'

import {
  InlineModal,
  InlineModalContent,
  InlineModalFooter,
  InlineModalHeader,
  InlineModalLine,
} from '@/components/InlineModal'
import { InnocuousButton } from '@/components/ExtraButtons'
import { PrimaryColor } from '@/components/Colors'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

import { GroupLabelSection } from './GroupLabelSection'
import { GroupType } from '../Group'
import { GroupValuesSection } from './GroupValuesSection'

type Props = {
  group: GroupType
  onClose: () => void
  setGroup: (groupFields: Partial<GroupType>) => Promise<any>
}

export const GroupManager = ({
  group: { label, values },
  onClose,
  setGroup,
}: Props) => {
  const { spacing } = useTheme()

  return (
    <Modal>
      <InlineModalHeader
        onClose={onClose}
        title={<T>SalesDetails:Settings.group.title</T>}
        style={{ paddingTop: `${spacing.gu(1.5)}rem` }}
      />

      <InlineModalLine />

      <InlineModalContent>
        <GroupLabelSection label={label} setGroup={setGroup} />

        <InlineModalDahedLine dashed />

        <GroupValuesSection values={values} setGroup={setGroup} />
      </InlineModalContent>

      <InlineModalFooter justifyContent="flex-end">
        <InnocuousButton onClick={onClose}>
          <PrimaryColor>
            <T>common:action.ready</T>
          </PrimaryColor>
        </InnocuousButton>
      </InlineModalFooter>
    </Modal>
  )
}

/////

const Modal = styled(InlineModal)`
  max-height: 100vh;
  width: 450px;
  margin-bottom: 10px;
  margin-right: 10px;

  ${({ theme }) => css`
    height: calc(100vh - ${theme.spacing.guPx(19) + 3}px - 10px);
    margin-top: ${theme.spacing.guPx(19) + 3}px;
  `}
`

const InlineModalDahedLine = styled(InlineModalLine)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.gu(0.5)}rem;
    margin-top: ${theme.spacing.gu(1.5)}rem;
  `}
`
