import styled from 'styled-components/macro'

import { FlexRow } from '@/components/Layout'
import type { ProductPurchaseDuration } from '@/modules/Products/types'
import { QuantityUnit } from '~generated-types'

import { DatesSelector } from '../DatesSelector'

type Props = {
  duration: ProductPurchaseDuration
  id: string
  quantityUnit: QuantityUnit
  readOnly: boolean
}

export const InvoiceSection = ({
  duration,
  id,
  quantityUnit,
  readOnly,
}: Props) => (
  <FlexRow flex={1}>
    <Spacer />

    {quantityUnit !== QuantityUnit.Piece && (
      <DatesSelector
        id={id}
        duration={duration}
        quantityUnit={quantityUnit}
        readOnly={readOnly}
      />
    )}
  </FlexRow>
)

/////////

const Spacer = styled.div`
  flex: 1;
`
