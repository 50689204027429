import { gql, useQuery } from '@apollo/client'

import {
  LanguagesQuery as QueryData,
  LanguagesQueryVariables as QueryVariables,
} from '~generated-types'
import { Language } from './types'

const QUERY = gql`
  query Languages {
    registry {
      languages
    }
  }
`

type Params = {
  forceRefetch?: boolean
  skip?: boolean
}

export default function useLanguages(params?: Params) {
  const { data, error, loading, refetch } = useQuery<QueryData, QueryVariables>(
    QUERY,
    {
      fetchPolicy: params?.forceRefetch ? 'cache-and-network' : 'cache-first',
      skip: params?.skip ?? false,
    }
  )

  const languages: Language[] = data ? [...data.registry.languages] : []

  return {
    error,
    languages,
    loading,
    refetch,
  }
}
