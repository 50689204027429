import { SalesRoute } from '../types'

import {
  Accommodation,
  Attachments,
  Enrollments,
  Meals,
  Orders,
  Overview,
  Participants,
  Products,
  Publish,
  Reservations,
  Tasks,
} from '../routes'

export const getSalesRoutes = (): SalesRoute[] => [
  Overview,
  Publish,
  Orders,
  Products,
  Accommodation,
  Participants,
  Meals,
  Reservations,
  Enrollments,
  Tasks,
  Attachments,
]
