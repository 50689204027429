import { AccommodationReservations } from '@/modules/Accommodation'

import { SectionContainer } from '../components'
import { SectionFeatures } from '../types'
import { useCompactViewContext } from '../../SaleCompactView/CompactViewContainer'
import { useSalesDetailsContext } from '../'

const SECTION: SectionFeatures = {
  icon: 'bed',
  key: 'accommodation',
}

const AccommodationComponent = () => {
  const { data, saleReadOnly, getEstimatedEndDate, getEstimatedStartDate } =
    useSalesDetailsContext()

  const { salesId } = useCompactViewContext()

  const estimatedEnd = getEstimatedEndDate()
  const estimatedStart = getEstimatedStartDate()
  const defaultRange =
    estimatedEnd && estimatedStart
      ? {
          from: estimatedStart.clone(),
          to: estimatedEnd.clone(),
        }
      : null

  return (
    <SectionContainer sectionIcon={SECTION.icon} keyName={SECTION.key}>
      <AccommodationReservations
        defaultRange={defaultRange}
        salesId={salesId || data.id}
        eventId={data.event?.id || null}
        readOnly={saleReadOnly}
      />
    </SectionContainer>
  )
}

export const Accommodation = Object.freeze({
  component: AccommodationComponent,
  icon: SECTION.icon,
  key: SECTION.key,
})
