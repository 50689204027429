import { gql, useMutation } from '@apollo/client'

import {
  DeleteSaleMutation,
  DeleteSaleMutationVariables,
} from '~generated-types'

export type { DeleteSaleMutation, DeleteSaleMutationVariables }

export const DELETE_SALE_MUTATION = gql`
  mutation DeleteSale($input: SalesDeleteInput!) {
    salesDelete(input: $input) {
      id
      deleted
    }
  }
`

export function useDeleteSaleMutation() {
  return useMutation<DeleteSaleMutation, DeleteSaleMutationVariables>(
    DELETE_SALE_MUTATION
  )
}

export default useDeleteSaleMutation
