import {
  ListHeaderSection as Section,
  ListHeader as Wrapper,
} from '@/components/List'
import { T } from '@/modules/Language'
import { useTheme } from '@/theme'

export const ListHeader = () => {
  const theme = useTheme()

  return (
    <Wrapper>
      <Section width={`${theme.spacing.gu(10)}rem`}>
        <T>Room:field.id</T>
      </Section>
      <Section>
        <T>Room:field.number</T>
      </Section>
      <Section>
        <T>Room:field.beds</T>
      </Section>
      <Section>
        <T>Room:field.floor</T>
      </Section>
      <Section>
        <T>Room:field.features</T>
      </Section>
      <Section>
        <T>Room:field.building</T>
      </Section>
      <Section>
        <T>Room:field.roomType</T>
      </Section>
    </Wrapper>
  )
}

export default ListHeader
