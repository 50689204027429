import { gql, useMutation } from '@apollo/client'

import ResourceReservationFragment from '../fragments/resourceReservation'

import {
  SetResourceReservationTimesMutation,
  SetResourceReservationTimesMutationVariables,
} from '~generated-types'

export type {
  SetResourceReservationTimesMutation,
  SetResourceReservationTimesMutationVariables,
}

export const SET_RESOURCE_RESERVATION_TIMES_MUTATION = gql`
  ${ResourceReservationFragment}

  mutation SetResourceReservationTimes(
    $input: ResourceReservationSetTimesInput!
  ) {
    resourceReservationSetTimes(input: $input) {
      ... on ReservationPayloadOk {
        reservation {
          ...ResourceReservation
        }
      }
      ... on ReservationAvailabilityError {
        message
      }
      ... on ReservationAvailabilityWarning {
        message
      }
    }
  }
`

export function useSetResourceReservationTimesMutation() {
  return useMutation<
    SetResourceReservationTimesMutation,
    SetResourceReservationTimesMutationVariables
  >(SET_RESOURCE_RESERVATION_TIMES_MUTATION)
}

export default useSetResourceReservationTimesMutation
