import { gql, useMutation } from '@apollo/client'

import { OpenSaleMutation, OpenSaleMutationVariables } from '~generated-types'

export type { OpenSaleMutation, OpenSaleMutationVariables }

export const OPEN_SALE_MUTATION = gql`
  mutation OpenSale($input: SalesOpenInput!) {
    salesOpen(input: $input) {
      id
      locked
      lifecycle {
        state
      }
    }
  }
`

export function useOpenSaleMutation() {
  return useMutation<OpenSaleMutation, OpenSaleMutationVariables>(
    OPEN_SALE_MUTATION
  )
}

export default useOpenSaleMutation
