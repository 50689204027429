import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import { FlexColumn, FlexRow } from '@/components/Layout'
import {
  ListItemSection as Section,
  ListItem as Wrapper,
} from '@/components/List'
import { Theme, useTheme } from '@/theme'
import { FontWeight } from '@/components/Typography'
import { InvoicesQuery } from '~generated-types'
import { T } from '@/modules/Language'

import { ElasticSearchInvoice } from '../Invoices.types'
import SalesLink from './SalesLink'
import { Tooltip } from '@/components/Tooltip'

type GQLInvoiceData = InvoicesQuery['invoicesByIds']['invoices'][0]

type ListItemProps = {
  data: ElasticSearchInvoice
  gqlData: GQLInvoiceData | null
  onClick: (id: string) => void
}

const ListItem = ({
  data: {
    bookkeepingTransferred,
    sales,
    rootEvent,
    paymentStatus,
    paymentType,
    invoiceDate,
    state,
    type,
  },
  gqlData,
  onClick,
}: ListItemProps) => {
  const theme = useTheme()

  const handleOpenSales = (id: string) => {
    const url = `/sales/details/${id}?view[0]=orders`
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

    if (newWindow) {
      newWindow.opener = null
    }
  }

  const renderTotalPrice = ({ paymentInfo }: GQLInvoiceData) => {
    const { payableAmount, totalPrice } = paymentInfo

    const totalAmount = totalPrice.amountVatIncluded
    const paidAmount = payableAmount === 0 ? null : totalAmount - payableAmount

    return (
      <TotalPrice color={getColorForState(paymentStatus, theme)}>
        {paidAmount !== null ? `${paidAmount} / ` : ''}
        {totalAmount} €
      </TotalPrice>
    )
  }

  return (
    <Wrapper onClick={() => onClick(sales.id)}>
      <Section style={{ height: 50 }}>
        <FlexColumn noPadding>
          <small style={{ whiteSpace: 'nowrap' }}>
            <T l10n={`Orders:State.${state}`} />
          </small>
          {gqlData?.invoiceNumber && <Title># {gqlData.invoiceNumber}</Title>}
        </FlexColumn>
      </Section>

      <Section style={{ padding: `0 ${theme.spacing.gu(1)}rem` }}>
        <FlexColumn noPadding alignItems="center">
          <small>{type && <T>{`Orders:Header.title.${type}`}</T>}</small>
          <FontWeight bold>
            <T>{`Orders:PaymentStatus.${paymentStatus}`}</T>
            {gqlData?.order.sales.paymentAgreement && (
              <small>
                <Tooltip
                  content={gqlData.order.sales.paymentAgreement.name}
                  delay={300}
                  trigger={(triggerProps) => (
                    <>
                      {' – '}
                      <span
                        {...triggerProps}
                        style={{ color: theme.palette.warning.darker }}
                      >
                        {gqlData.order.sales.paymentAgreement?.code}
                      </span>
                    </>
                  )}
                />
              </small>
            )}
          </FontWeight>
        </FlexColumn>
      </Section>

      <Section>
        <FlexColumn noPadding alignItems="center">
          {invoiceDate && moment(invoiceDate).format('DD.MM.YY')}
          {bookkeepingTransferred && (
            <FlexRow>
              <FontAwesomeIcon
                size="sm"
                icon="file-invoice-dollar"
                color={theme.palette.text.lighter}
                style={{ marginRight: `${theme.spacing.gu(1)}rem` }}
              />
              <small>
                {moment(
                  new Date(0).setUTCMilliseconds(bookkeepingTransferred || 0)
                ).format('DD.MM.YY, HH:mm')}
              </small>
            </FlexRow>
          )}
        </FlexColumn>
      </Section>

      <Section style={{ height: 50 }}>
        <FlexColumn noPadding>
          <small>
            <T>{`Orders:PaymentType.${paymentType}`}</T>
          </small>
          {gqlData && renderTotalPrice(gqlData)}
        </FlexColumn>
      </Section>

      <Section>
        {sales.customerName ? (
          <CustomerName>
            <FontAwesomeIcon
              icon={
                sales.customerType === 'PERSON' ? 'user' : ['far', 'building']
              }
              size="xs"
              color={theme.palette.text.light}
              style={{
                marginBottom: 1,
                marginRight: `${theme.spacing.gu(1)}rem`,
              }}
            />
            {sales.customerName}
          </CustomerName>
        ) : null}
      </Section>

      {sales?.number ? (
        <SalesLink sales={sales} onClick={handleOpenSales} />
      ) : (
        <Section />
      )}

      {rootEvent?.number ? (
        <SalesLink sales={rootEvent} onClick={handleOpenSales} />
      ) : (
        <Section />
      )}
    </Wrapper>
  )
}

export default ListItem

const getColorForState = (state: string, theme: Theme) => {
  switch (state) {
    case 'UNPAID':
      return theme.palette.danger.main
    case 'PARTIALLY_PAID':
    case 'OVERPAID':
      return theme.palette.warning.darker
    case 'PAID':
      return theme.palette.success.darker
    default:
      return theme.palette.primary.darker
  }
}

////////////

const TotalPrice = styled.span<{ color?: string }>`
  font-weight: 600;
  white-space: nowrap;

  ${({ theme, color }) => css`
    font-size: ${theme.typography.fontSizeBase2};
    ${color && `color: ${color}`}
  `}
`

const Title = styled.span`
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => css`
    font-size: ${theme.typography.fontSizeBase};
  `}
`

const CustomerName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 130px;

  ${({ theme }) => css`
    color: ${theme.palette.text.light};
  `}
`
