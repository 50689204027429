import { ReactNode, Ref, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Placement } from 'popper.js'

import { Button } from '@/components/Button'
import { FlexRow } from '@/components/Layout'
import { InlineModal } from '@/components/InlineModal'
import { ModalContainer } from '@/components/Modal'
import { T } from '@/modules/Language'

type DropdownOptionBase = {
  disabled?: boolean
  keepOpen?: boolean
  label: ReactNode
  onClick: () => void
  sortValue?: number | string
}

export type DropdownOption = DropdownOptionBase & {
  optional?: DropdownOptionBase
}

type CustomButtonArgs = {
  onClick: () => void
}

export type DropdownButtonProps = {
  children?: ReactNode
  disabled?: boolean
  onClose?: () => void
  options: DropdownOption[]
  dropdownPlacement?: Placement
  renderCustomButton?: ({ onClick }: CustomButtonArgs) => ReactNode
  renderCustomEmptyLabel?: () => ReactNode
  renderCustomTitle?: () => ReactNode
  zIndex?: number
}

export const DropdownButton = ({
  children,
  disabled,
  onClose,
  options,
  dropdownPlacement,
  renderCustomButton,
  renderCustomEmptyLabel,
  renderCustomTitle,
  zIndex,
}: DropdownButtonProps) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const renderReferenceElement = (ref?: Ref<HTMLDivElement>) => (
    <div ref={ref}>
      {renderCustomButton ? (
        renderCustomButton({
          onClick: () => setShowDropdown(true),
        })
      ) : (
        <StyledDropdownButton
          disabled={disabled}
          onClick={() => setShowDropdown(true)}
        >
          {children}
          <Spacer />
          <FontAwesomeIcon
            fixedWidth
            icon={showDropdown ? 'angle-down' : 'angle-left'}
          />
        </StyledDropdownButton>
      )}
    </div>
  )

  return (
    <ModalContainer
      isOpen={showDropdown}
      modal={
        <InlineModal style={{ minWidth: '192px' }}>
          {renderCustomTitle && renderCustomTitle()}
          {!options.length && (
            <DropdownOptionButton disabled onClick={() => undefined}>
              <div style={{ textAlign: 'center' }}>
                –{' '}
                <span>
                  {renderCustomEmptyLabel ? (
                    renderCustomEmptyLabel()
                  ) : (
                    <T>common:empty</T>
                  )}
                </span>{' '}
                –
              </div>
            </DropdownOptionButton>
          )}
          {options.map(
            ({ disabled, keepOpen, label, onClick, optional }, idx) => (
              <FlexRow key={`dropdown-option-${idx}`}>
                <DropdownOptionButton
                  disabled={disabled}
                  onClick={() => {
                    onClick()
                    !keepOpen && setShowDropdown(false)
                  }}
                >
                  {label}
                </DropdownOptionButton>
                {optional && (
                  <DropdownOptionButton
                    disabled={optional.disabled}
                    onClick={() => {
                      optional.onClick()
                      !keepOpen && setShowDropdown(false)
                    }}
                    style={{ width: 'fit-content' }}
                  >
                    {optional.label}
                  </DropdownOptionButton>
                )}
              </FlexRow>
            )
          )}
        </InlineModal>
      }
      onClose={() => {
        setShowDropdown(false)
        onClose && onClose()
      }}
      placement={dropdownPlacement || 'bottom-end'}
      referenceElement={({ ref }) => renderReferenceElement(ref)}
      zIndex={zIndex}
    />
  )
}

export default DropdownButton

////////////

const DropdownOptionButton = styled.button`
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: block;
  margin: 2px;
  outline: none;
  white-space: nowrap;
  width: 100%;

  background-color: unset;
  text-align: left;
  text-decoration: none;

  ${({ theme }) => css`
    padding: ${theme.spacing.gu(1)}rem ${theme.spacing.gu(1.5)}rem;
    color: ${theme.palette.text.light};

    &:hover,
    &.is-active {
      color: ${theme.palette.primary.dark};
      background: ${theme.palette.primary.extraLight};
    }

    &:disabled {
      cursor: not-allowed;
      background: ${theme.palette.smoke.extraLight};
      color: ${theme.palette.text.lighter};
    }
  `}
`

const Spacer = styled.span`
  height: 100%;
  width: 2px;

  ${({ theme }) => css`
    background: ${theme.palette.primary.main};
    margin: 0 ${theme.spacing.gu(0.25)}rem 0 ${theme.spacing.gu(1)}rem;
  `}
`

const StyledDropdownButton = styled(Button).attrs(() => ({
  color: 'primary',
  size: 'small',
  variant: 'outlined',
}))`
  border-radius: 4px;

  ${({ theme }) => css`
    padding: 0 ${theme.spacing.gu(0.5)}rem 0 ${theme.spacing.gu(1)}rem;

    &:hover:not(:disabled) ${Spacer} {
      background: ${theme.palette.white};
    }
  `}
`
